import Axios from 'axios';

export function callArtistProfilesSearch(queryParams = {}) {
    return Axios.get(`/api/v1/artist_profiles/search`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callArtistProfilesShow(artistProfile, queryParams = {}) {
    return Axios.get(`/api/v1/artist_profiles/${artistProfile}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callArtistProfilesUpdate(artistProfile, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/artist_profiles/${artistProfile}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
