<template>
  <div>
    <div v-if="playlistsLoading">LOADING</div>
    <atmo-music-playlists-grid :playlists="playlists" />
  </div>
</template>

<script>
// import AtmoMusicItems from '@/components/atmo-music-items';
import PaginationMixin from '@/mixins/pagination';
import AtmoMusicPlaylistsGrid from '@/components/music/atmo-music-playlists-grid.vue';
import {
  callPlaylistsNewReleases
} from '@/helpers/axiosCalls';

export default {
  components: { AtmoMusicPlaylistsGrid },
  // components: { AtmoMusicItems },
  mixins: [PaginationMixin],
  props: { showNewReleases: { type: Boolean, default: false } },
  data() {
    return {
      playlists: null,
      playlistsLoading: true,
    };
  },
  created() {
    this.getPlaylists();
  },
  methods: {
    getPlaylists() {
      callPlaylistsNewReleases()
        .then((response) => {
          this.playlists = response;
          console.log("this.playlists: ", this.playlists);
          this.playlistsLoading = false;
        }).catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>