import Axios from 'axios';

export function callProductsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/products`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductsShow(productId, queryParams = {}) {
    return Axios.get(`/api/v1/products/${productId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductsCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/products`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductsUpdate(productId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/products/${productId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductsDelete(productId, queryParams = {}) {
    return Axios.delete(`/api/v1/products/${productId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}