<template>
  <div :class="`atmo-popover ${arrow ? '' : 'hide-arrow'}`">
    <div
      :id="triggerId"
      class="atmo-popover__trigger"
      tabindex="0"
    >
      <slot name="trigger" />
    </div>

    <b-popover
      :target="triggerId"
      :container="triggerId"
      :placement="placement"
      :triggers="triggers"
      boundary="viewport"
    >
      <div class="atmo-popover__content">
        <slot name="content" />
      </div>
    </b-popover>
  </div>
</template>

<script>

  import { v4 } from 'uuid';
  import { BPopover } from 'bootstrap-vue';


  export default {
    components: { BPopover },
    props: {
      placement: {
        type: String,
        default: 'right'
      },
      triggers: {
        type: String,
        default: 'click blur'
      },
      arrow: {
        type: Boolean,
        default: true
      }
    },
    data: () => ({
      _uid: v4(),
    }),
    computed: {
      triggerId() {
        return `atmo-popover__trigger--${this._uid}`;
      }
    }
  }
</script>

<style lang="scss">
  @import 'node_modules/bootstrap/scss/mixins';
  @import 'node_modules/bootstrap/scss/functions';
  @import 'node_modules/bootstrap/scss/variables';
  @import 'node_modules/bootstrap/scss/popover';
  @import '~@/assets/stylesheets/_variables.scss';

.hide-arrow .arrow {
  visibility: hidden;
}
  .atmo-popover {
    &__trigger {
      outline: none;
      cursor: pointer;
    }

    .popover {
      display: flex;
      flex-direction: column;
      font-size: 1.2em;
      font-weight: 300;
      background-color: $atmo-purple--medium-dark;
      border-radius: 5px;
      border: none;
      box-shadow: 1px 1px 8px 0px rgba(black, 0.3);
    }

    .popover-body {
      padding: 5px;
    }

    .bs-popover-top .arrow:after {
      border-top-color: $atmo-purple--medium-dark;
    }

    .bs-popover-right .arrow:after {
      border-right-color: $atmo-purple--medium-dark;
    }

    .bs-popover-bottom .arrow:after {
      border-bottom-color: $atmo-purple--medium-dark;
    }

    .bs-popover-left .arrow:after {
      border-left-color: $atmo-purple--medium-dark;
    }
  }
</style>
