<template>
    <div class="photo-comments-container">
        <!-- Safely check if caption exists -->
        <div v-if="$store.state.videoPlayer.userMediaModalObj && $store.state.videoPlayer.userMediaModalObj.caption"
            class="comments-wrap__caption-wrap">
            {{ $store.state.videoPlayer.userMediaModalObj.caption }}
        </div>
        <div v-else class="comments-wrap__caption-wrap">No caption available</div>

        <!-- Safely check if comments is an array and has a length -->
        <perfect-scrollbar class="caption-wrap__scroll-container" v-if="Array.isArray(comments) && comments.length > 0">
            <div class="comments-wrap__comment-wrap" v-for="(comment, index) in comments" :key="index">
                <div class="comment-wrap__image-wrap">
                    <img class="image-wrap__image" :src="getFeaturedImage(comment.user.images, 'thumb')">
                </div>
                <div class="comment-wrap__comment">
                    <div>
                        <div v-if="!comment.editMode" class="comment__message-content">{{ comment.content }}</div>
                        <input v-else v-model="commentContent" autofocus placeholder="Edit comment" type="text"
                            class="comment-list-component__input"
                            @keyup.enter="submitNewCommentContent(comment, commentContent)">
                        <div class="comment__message-timestamp">{{ comment.created_at }}</div>
                    </div>
                    <div>
                        <atmo-popover v-if="$store.state.currentUserId === comment.user_id" placement="bottom">
                            <img slot="trigger" class="atmo-post__post-dropdown-trigger"
                                src="@/assets/images/icons/arrow_down.png" alt="Post Actions">
                            <div slot="content">
                                <a class="atmo-popover__dropdown-action" @click="deleteComment(comment)">
                                    <div class="atmo-popover__dropdown-action-image-container">
                                        <img class="atmo-popover__dropdown-action-image"
                                            src="@/assets/images/icons/delete.png" alt="Delete post">
                                    </div>
                                    Delete Comment
                                </a>
                                <a class="atmo-popover__dropdown-action" @click="openCommentEditor(comment)">
                                    <div class="atmo-popover__dropdown-action-image-container">
                                        <img class="atmo-popover__dropdown-action-image"
                                            src="@/assets/images/icons/edit.png" alt="Edit post">
                                    </div>
                                    Edit Comment
                                </a>
                            </div>
                        </atmo-popover>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
        <div v-else class="caption-wrap__scroll-container empty">No comments</div>
        <div class="comments-wrap__comment-input">
            <input class="comment-field-rounded" type="text" v-model="newCommentContent" autofocus
                placeholder="Type your message here" @keyup.enter="createComment()" />
        </div>
    </div>
</template>

<script>
import {
    callCommentsIndex,
    callCommentsCreate,
    callCommentsUpdate,
    callCommentsDelete
} from '@/helpers/axiosCalls';
import AtmoPopover from '@/components/atmo-popover';
import { getFeaturedImage } from '@/helpers/utilityFunctions';
import { mapActions } from 'vuex';

export default {
    components: { AtmoPopover },
    data() {
        return {
            newCommentContent: null,
            commentIsBeingEdited: false,
            commentContent: ''
        }
    },
    mounted() {
    },
    computed: {
        comments() {
            return this.$store.state.videoPlayer.userMediaModalComments;
        }
    },
    methods: {
        ...mapActions('accomplishments', ['createUserAccomplishments']),
        getFeaturedImage(userImages, type) {
            return getFeaturedImage(userImages, type);
        },
        editComment(comment, commentContent) {
            callCommentsUpdate(comment.id, {
                content: commentContent
            })
                .then(() => {
                    this.$store.dispatch("videoPlayer/fetchUserMediaModalComments",
                        {
                            type: this.$store.state.videoPlayer.userMediaModalObj.type,
                            caption: this.$store.state.videoPlayer.userMediaModalObj.caption,
                            id: this.$store.state.videoPlayer.userMediaModalObj.id,
                            url: this.$store.state.videoPlayer.userMediaModalObj.url
                        });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        openCommentEditor(comment) {
            this.commentContent = comment.content;
            comment.editMode = true;
        },
        submitNewCommentContent(comment, commentContent) {
            comment.editMode = false;
            this.editComment(comment, commentContent);
        },
        deleteComment(comment) {
            callCommentsDelete(comment.id)
                .then(() => {
                    this.$store.dispatch("videoPlayer/fetchUserMediaModalComments",
                        {
                            type: this.$store.state.videoPlayer.userMediaModalObj.type,
                            caption: this.$store.state.videoPlayer.userMediaModalObj.caption,
                            id: this.$store.state.videoPlayer.userMediaModalObj.id,
                            url: this.$store.state.videoPlayer.userMediaModalObj.url
                        });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        getComments() {
            callCommentsIndex({
                commentable_id: this.$store.state.videoPlayer.userMediaModalObj.id,
                commentable_type: this.$store.state.videoPlayer.userMediaModalObj.type,
            })
                .then((response) => {
                    this.$store.commit("videoPlayer/setUserMediaModalComments", response || []); // Set an empty array if no comments
                })
                .catch((error) => {
                    console.error(error);
                    this.$store.commit("videoPlayer/setUserMediaModalComments", []); // Fallback to an empty array on error
                });
        },
        createComment() {
            callCommentsCreate({
                content: this.newCommentContent,
                user_id: this.$store.state.currentUserId,
                commentable_id: this.$store.state.videoPlayer.userMediaModalObj.id,
                commentable_type: this.$store.state.videoPlayer.userMediaModalObj.type,
            })
                .then((response) => {
                    this.newCommentContent = '';
                    this.getComments();

                    this.createUserAccomplishments({
                        userId: this.$store.state.currentUserId,
                        accomplishmentData: {
                            payloads: [
                                {
                                    user_id: this.$store.state.currentUserId,
                                    category: 'comments',
                                    sub_category: 'commentNumber'
                                }
                            ]
                        }
                    });

                    this.$store.dispatch('userActivities/createUserActivity',
                        {
                            user_activity_type_name: "Post Comment",
                            user_id: this.$store.state.currentUserId,
                            activityable_type: "Post",
                            activityable_id: response.commentable.id
                        }
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}
</script>


<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';
@import 'video.js/dist/video-js.css';
//https://videojs.com/guides/vue/
//https://videojs.com/guides/options/

.photo-comments-container {
    .comments-wrap__caption-wrap {
        font-size: 1rem;
        margin-bottom: 1rem;
        font-weight: 500;
        // margin-left: 2.8rem;
        // border: 1px solid white;
    }

    .caption-wrap__scroll-container {
        min-height: 15rem;
        padding: 0rem .5rem 0rem 0rem;

        &.empty {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .comment-field-rounded {
        background: transparent;
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, .4);
        padding: .5rem .5rem .5rem 0.5rem;
        color: white;
        width: 100%;
        position: relative;
        background-size: 1rem;
        background-position: .8rem .5rem;
    }

    .comments-wrap__comment-wrap {
        display: flex;
        // border: 1px solid white;
    }

    .comment-wrap__image-wrap {
        margin-right: 0.5rem;

        img {
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
        }
    }

    .comment-wrap__comment {
        margin-bottom: 0.5rem;
        padding: 1rem;
        padding: 7px 6px 3px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 2px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .comment-list-component__input {
        background: transparent;
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, .4);
        padding: .2rem .2rem .2rem 0.4rem;
        color: white;
        width: 100%;
        position: relative;
        background-size: 1rem;
        background-position: .8rem .5rem;
        margin-bottom: .5rem;
    }

    .comment__message-content {
        color: white;
        font-size: .8rem;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .comment__message-timestamp {
        color: white;
        font-size: .7rem;
        font-weight: 300;
    }

    .comments-wrap__caption-wrap {
        font-size: 1rem;
        margin-bottom: 1rem;
        font-weight: 500;
        // margin-left: 2.8rem;
        // border: 1px solid white;
    }

    .comment-field-rounded {
        background: transparent;
        border-radius: 2px;
        border: 1px solid rgba(255, 255, 255, .4);
        padding: .5rem .5rem .5rem 0.5rem;
        color: white;
        width: 100%;
        position: relative;
        background-size: 1rem;
        background-position: .8rem .5rem;
    }

    .comments-wrap__comment-wrap {
        display: flex;
        // border: 1px solid white;
    }

    .comment-wrap__image-wrap {
        margin-right: 0.5rem;

        img {
            height: 2rem;
            width: 2rem;
            border-radius: 50%;
        }
    }

    .comment-wrap__comment {
        margin-bottom: 0.5rem;
        padding: 1rem;
        padding: 7px 6px 3px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 2px;
        width: 100%;
    }

    .comment__message-content {
        color: white;
        font-size: .8rem;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .comment__message-timestamp {
        color: white;
        font-size: .7rem;
        font-weight: 300;
    }
}
</style>