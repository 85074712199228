<template>
  <div
    class="atmo-badge"
    :class="{
      'atmo-badge--inverted': inverted,
      'atmo-badge--medium': color === 'medium'
    }"
  >
    <img
      v-if="icon"
      class="atmo-badge__icon"
      :src="require(`@/assets/images/icons/${icon}`)"
    >
    <span class="atmo-badge__label">
      {{ label }}
    </span>
    <div
      v-if="removable"
      class="atmo-badge__remove-icon"
      @click="onRemove"
    >
      &times;
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: [String, Number],
        default: ''
      },
      icon: {
        type: String,
        default: null
      },
      color: {
        type: String,
        default: 'dark'
      },
      removable: {
        type: Boolean,
        default: false
      },
      onRemove: {
        type: Function,
        default() {}
      },
      inverted: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .atmo-badge {
    display: inline-flex;
    align-items: center;
    background-color: $atmo-purple--medium-dark;
    color: white;
    border-radius: 10px;
    padding: 3px 10px;
    font-size: 0.9em;
    font-weight: 400;
    position: relative;

    &--medium {
      background-color: $atmo-purple--medium;
    }

    &--inverted {
      background-color: transparent;
      border: 1px solid $atmo-purple--light;
    }

    &__icon {
      height: 10px;
      margin-right: 3px;
    }

    &__remove-icon {
      position: absolute;
      right: -3px;
      top: -3px;
      font-size: 1.1em;
      color: $atmo-purple--dark;
      background-color: white;
      border-radius: 100%;
      padding: 0px 2px;
      cursor: pointer;
    }
  }
</style>
