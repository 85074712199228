const artistImageArray = [
  '2chainz_feature.png',
  'adele.png',
  'audioslave.png',
  'boardsofcanada.png',
  'daftpunk.png',
  'davematthewsband.png',
  'davidguetta.png',
  'deadmau5.png',
  'falloutboy.png',
  'fleetwoodmac.png',
  'gnarlsbarkley.png',
  'iconapop.png',
  'imaginedragons.png',
  'jayz.png',
  'justintimberlake.png',
  'kanyewest.png',
  'kingsofleon.png',
  'lanadelray.png',
  'majorlazer.png',
  'mfdoom.png',
  'passionpit.png',
  'phoenix.png',
  'queensofthestoneage.png',
  'radiohead.png',
  'taylorswift.png',
  'theflaminglips.png',
  'thelumineers.png',
  'thenational.png',
  'theroots.png',
  'vampireweekend.png'
];

const highResImageArray = [
  "ag.webp",
  "aw.webp",
  "b.webp",
  "b182.webp",
  "bar.webp",
  "blhc.webp",
  "cb.webp",
  "db.webp",
  "fka.webp",
  "jc.webp",
  "jd.webp",
  "jj.webp",
  "jm.webp",
  "jp.webp",
  "kw.webp",
  "lg.webp",
  "lz.webp",
  "m.webp",
  "mb.webp",
  "me.webp",
  "nbig.webp",
  "nm.webp",
  "p.webp",
  "pf.webp",
  "rs.webp",
  "ts.webp",
  "wh.webp",
  "yt.webp",
];

const friendNameArray = [
  "Emily Johnson",
  "Michael Smith",
  "Jessica Williams",
  "David Davis",
  "Ashley Brown",
  "Christopher Lee",
  "Amanda Jones",
  "Daniel Wilson",
  "Brittany Taylor",
  "Matthew Clark",
  "Stephanie Baker",
  "Joseph Green",
  "Lauren Anderson",
  "Joshua Carter",
  "Elizabeth Wright",
  "Andrew Mitchell",
  "Rachel Perez",
  "Nicholas Jackson",
  "Sarah White",
  "Tyler Moore",
  "Olivia Martin",
  "Brandon Hall",
  "Megan Garcia",
  "William Nelson",
  "Hannah Robinson",
  "Jacob Martinez",
  "Grace Collins",
  "Ryan Wright",
  "Molly Bailey",
  "Ethan Cooper",
  "Natalie Wright",
  "Benjamin Allen",
  "Victoria King",
  "Jonathan Wright",
  "Samantha Scott",
  "Alex Rodriguez",
  "Kaitlyn Phillips",
  "Christian Johnson",
  "Madison Campbell",
  "Elijah Brown",
  "Isabella Flores",
  "Cameron Parker",
  "Avery Turner",
  "Gabriel Adams",
  "Chloe Ramirez",
  "Luke Torres",
  "Savannah Hill",
  "Anthony Flores",
  "Alyssa Collins",
  "Isaac Reyes",
  "Hailey Mitchell",
  "Jackson Sanchez"
];

const artistNameArray = [
  "The Beatles",
  "Michael Jackson",
  "Elvis Presley",
  "Queen",
  "Bob Dylan",
  "David Bowie",
  "Prince",
  "Led Zeppelin",
  "Pink Floyd",
  "The Rolling Stones",
  "Jimi Hendrix",
  "Bruce Springsteen",
  "The Who",
  "Stevie Wonder",
  "Bob Marley",
  "Madonna",
  "U2",
  "Nirvana",
  "Radiohead",
  "Eminem",
  "Beyonce",
  "Jay-Z",
  "Kanye West",
  "Drake",
  "Taylor Swift",
  "Adele",
  "Ed Sheeran",
  "Coldplay",
  "Rihanna",
  "Justin Bieber",
  "Lady Gaga",
  "Bruno Mars",
  "Whitney Houston",
  "Celine Dion",
  "Mariah Carey",
  "Elton John",
  "The Beach Boys",
  "Johnny Cash",
  "Dolly Parton",
  "Willie Nelson",
  "Kenny Rogers",
  "Guns N' Roses",
  "Metallica",
  "AC/DC",
  "Black Sabbath",
  "Ozzy Osbourne",
  "Iron Maiden",
  "Bon Jovi",
  "Van Halen",
  "Journey",
  "The Eagles",
  "Fleetwood Mac"
];

const playlistNameArray = [
  "Chill Vibes",
  "Party Hits",
  "Throwback Jams",
  "Workout Mix",
  "Study Music",
  "Road Trip Tunes",
  "Country Classics",
  "Rock Anthems",
  "R&B Grooves",
  "Hip Hop Heat",
  "Acoustic Sessions",
  "Indie Favorites",
  "EDM Energy",
  "Feel Good Pop",
  "Golden Oldies",
  "Piano Classics",
  "Jazz Essentials",
  "Soulful Sounds",
  "Blues & Roots",
  "Reggae Rhythms",
  "Latin Fiesta",
  "K-Pop Faves",
  "90s Nostalgia",
  "80s Flashback",
  "70s Throwback",
  "60s Hits",
  "Classical Gems",
  "Instrumental Focus",
  "Concentration Mix",
  "Mellow Moods",
  "Love Songs",
  "Breakup Anthems",
  "Sad Songs",
  "Feel Good Favourites",
  "Summer Hits",
  "Winter Wonderland",
  "Pump Up Playlist",
  "Dance Party Mix",
  "Cocktail Hour",
  "Karaoke Classics",
  "Sing-Along Songs",
  "Guitar Heroes",
  "Bass Boosted",
  "Drum & Bass",
  "Electronic Escapades",
  "New Music",
  "Discover Weekly",
  "Artist Spotlight",
  "Best of the Year",
  "Top Charts",
  "Hot New Releases"
];

const songTitleArray = [
  "Bohemian Rhapsody",
  "Stairway to Heaven",
  "Hotel California",
  "Imagine",
  "Smells Like Teen Spirit",
  "Sweet Child O' Mine",
  "Billie Jean",
  "Like a Rolling Stone",
  "I Will Always Love You",
  "Hey Jude",
  "Thriller",
  "Yesterday",
  "What's Going On",
  "Let It Be",
  "Hallelujah",
  "Born to Run",
  "God Save the Queen",
  "Purple Rain",
  "A Change Is Gonna Come",
  "Every Breath You Take",
  "London Calling",
  "I Want to Hold Your Hand",
  "Good Vibrations",
  "Superstition",
  "Beat It",
  "My Generation",
  "Sweet Home Alabama",
  "Sweet Caroline",
  "Satisfaction",
  "Losing My Religion",
  "Bridge Over Troubled Water",
  "Purple Haze",
  "The Weight",
  "Landslide",
  "Suspicious Minds",
  "All Along the Watchtower",
  "Stand by Me",
  "Respect",
  "Dancing Queen",
  "The Twist",
  "Brown Eyed Girl",
  "Bennie and the Jets",
  "Don't Stop Believin'",
  "Livin' on a Prayer",
  "Wonderwall",
  "Free Fallin'",
  "I Will Survive",
  "Take on Me",
  "Girls Just Want to Have Fun",
  "Eye of the Tiger"
];

const broadcastContentArray = [
  "Just had the best pizza ever! 🍕😋",
  "Feeling grateful for my family and friends on this beautiful day. ❤️",
  "Can't wait for my vacation next week! ✈️🌴",
  "Excited to announce my new job at XYZ company. 🎉",
  "Why is it so hard to wake up early on Mondays? 😴☕️",
  "Had an amazing workout at the gym this morning. 💪🏋️‍♀️",
  "I can't stop listening to this new album. 🎧🎶",
  "Just finished reading an incredible book. 📚",
  "So happy to see my favorite band in concert last night. 🎤🎸",
  "Who wants to join me for a movie night tonight? 🍿🎥",
  "Finally finished that project I've been working on for weeks! 💻🤓",
  "Wishing everyone a happy and safe weekend! 🎉🥳",
  "Just adopted a new puppy! 🐶❤️",
  "Missing my vacation already. 😔🌊",
  "Can't believe it's already been a year since my wedding day. 💍❤️",
  "So excited for the first day of spring tomorrow! 🌸🌼",
  "Had a great time catching up with an old friend over lunch today. 🍔🍟",
  "Just tried this amazing new restaurant. Highly recommend! 🍝🍷",
  "Feeling inspired after attending a motivational seminar. 💪👍",
  "I can't believe I just ran my first 5K! 🏃‍♀️🏅",
  "Thankful for my job and the amazing team I work with. 🙏💼",
  "This sunset is breathtaking. 🌅😍",
  "Excited to start learning a new language. 📚🗣️",
  "Had an incredible time at the music festival this weekend. 🎶🎉",
  "Enjoying some much-needed self-care today. 🧖‍♀️💆‍♀️",
  "So proud of my little sister for graduating college today. 🎓👩‍🎓",
  "Can't wait to try this new recipe I found on Pinterest. 🍽️👩‍🍳",
  "Just saw the most amazing movie. You have to check it out! 🎬🍿",
  "Grateful for the little things in life, like a warm cup of tea on a chilly day. ☕️🌬️",
  "I can't believe I just finished writing my first novel. 📝🎉",
  "Excited to start planning my next adventure. ✈️🌍",
  "Just started a new hobby and I'm loving it. 🎨🧶",
  "Had a fun night out with friends. 🍻🍸",
  "Happy birthday to my best friend in the whole world! 🎂🎉",
  "Feeling inspired after attending a TED talk. 🤔💭",
  "Can't wait to see what the future holds. 🤞🌅",
  "Enjoying a lazy Sunday with my loved ones. ❤️🥰",
  "So happy to be back home after a long trip.",
  "Liked a photo",
  "Commented on a post",
  "Shared a video",
  "Posted a photo",
  "Checked in at a restaurant",
  "Attending an event",
  "Wrote a status update",
  "Shared an article",
  "Liked a status update",
  "Tagged a friend in a post",
  "Watched a live stream",
  "Shared a blog post",
  "Wrote a review",
  "Liked a comment",
  "Followed a new account",
  "Created a poll",
  "Shared a meme",
  "Updated their profile picture",
  "Shared a news article",
  "Started a fundraiser",
  "Joined a group",
  "Added a new friend",
  "Checked in at the gym",
  "Shared a recipe",
  "Liked a tweet",
  "Retweeted a post",
  "Sent a direct message",
  "Started a live video",
  "Answered a question",
  "Shared a podcast",
  "Shared a quote",
  "Posted a throwback photo",
  "Shared a workout plan",
  "Liked a photo album",
  "Added a new skill to their profile",
  "Commented on a news article",
  "Joined a charity challenge",
  "Shared a tutorial video",
  "Shared a motivational quote",
  "Created an event",
  "Shared a product review",
  "Liked a video",
  "Asked a question",
  "Shared a personal story",
  "Tagged a location in a post",
  "Shared a playlist",
  "Created a photo album",
  "Shared a career milestone"
];

const albumNameArray = [
  "City Dreams",
  "Echoes in the Wind",
  "Neon Nights",
  "Lost in the Woods",
  "Broken Hearts and Broken Dreams",
  "Cosmic Voyage",
  "Melancholy Memories",
  "Electric Dreams",
  "Midnight Magic",
  "Solar Flares",
  "Ghosts of the Past",
  "Summer Breeze",
  "Starry Night",
  "Dreamland",
  "Silver Linings",
  "Oceanic Symphony",
  "Infinite Horizons",
  "Wildfire",
  "Island Paradise",
  "Autumn Leaves",
  "Twisted Dreams",
  "Reflections in Time",
  "Electric Nights",
  "The Last Goodbye",
  "A New Dawn",
  "Road to Redemption",
  "Heartbreak Hotel",
  "Blue Skies",
  "From the Ashes",
  "Endless Love",
  "Lost and Found",
  "New Beginnings",
  "Retro Vibes",
  "The Edge of Forever",
  "A World Apart",
  "Uncharted Waters",
  "Beyond the Horizon",
  "Neon Dreams",
  "Solitude",
  "Chasing Rainbows",
  "The Journey Home",
  "Winter Wonderland",
  "Cosmic Dreams",
  "Under the Stars",
  "Echoes of Eternity",
  "Phoenix Rising",
  "In the Eye of the Storm",
  "Dancing in the Moonlight",
  "Memories of You",
  "Red Skies at Night"
];

const genresArray = [
  "Alternative Rock",
  "Blues",
  "Classical",
  "Country",
  "Electronic",
  "Folk",
  "Hip Hop",
  "Indie",
  "Jazz",
  "Metal",
  "New Age",
  "Opera",
  "Pop",
  "R&B",
  "Reggae",
  "Rock",
  "Soul",
  "World Music",
  "Acid Jazz",
  "Ambient",
  "Bebop",
  "Bluegrass",
  "Christian",
  "Classic Rock",
  "Dance",
  "Disco",
  "Dubstep",
  "Easy Listening",
  "Funk",
  "Gospel",
  "Grindcore",
  "Hard Rock",
  "Heavy Metal",
  "House",
  "Industrial",
  "Latin",
  "Musical Theater",
  "Punk",
  "Rap",
  "Rockabilly",
  "Ska",
  "Smooth Jazz",
  "Swing",
  "Techno",
  "Trance",
  "Trip Hop",
  "World Fusion"
];

const songCommentsArray = [
  "This song is so catchy!",
  "The lyrics really speak to me.",
  "I love the beat of this song.",
  "This song always puts me in a good mood.",
  "The vocals on this track are amazing.",
  "I can't stop listening to this song!",
  "This is my favorite song right now.",
  "The melody is so beautiful.",
  "I love the energy of this song.",
  "This song gives me goosebumps.",
  "I've been playing this on repeat for days.",
  "The guitar solo in this song is insane!",
  "This song is perfect for dancing.",
  "I love the message behind this song.",
  "The chorus is so catchy and memorable.",
  "This song always makes me smile.",
  "The production on this track is top-notch.",
  "I can't get enough of this song.",
  "This song is so uplifting and empowering.",
  "The lyrics are so relatable.",
  "I feel like this song was written just for me.",
  "This song is a masterpiece.",
  "The vocals are so emotional and powerful.",
  "I love the instrumentation on this track.",
  "This song is a classic.",
  "The lyrics are so deep and thought-provoking.",
  "This song is so nostalgic for me.",
  "I love the way this song builds up.",
  "This song is so romantic and beautiful.",
  "The harmonies on this track are amazing.",
  "I can't help but dance to this song.",
  "This song is a work of art.",
  "The melody is so haunting and beautiful.",
  "I love the way this song tells a story.",
  "This song is so unique and original.",
  "The lyrics are so clever and witty.",
  "This song is so calming and relaxing.",
  "I love the way this song makes me feel.",
  "This song is perfect for a road trip.",
  "The production on this track is so creative.",
  "I can't believe how talented the artist is.",
  "This song is so powerful and moving.",
  "The beat is so infectious and addictive.",
  "This song is a true gem.",
  "I love the way this song transitions between sections.",
  "This song is perfect for a workout.",
  "The arrangement on this track is so well-done.",
  "I can't wait to see this artist perform live!",
  "This song is a true masterpiece of its genre."
];

const timesHHMMAMPMArray = [
  "03:00pm", "03:05pm", "03:10pm", "03:15pm", "03:20pm", "03:25pm", "03:30pm", "03:35pm", "03:40pm", "03:45pm",
  "03:50pm", "03:55pm", "04:00pm", "04:05pm", "04:10pm", "04:15pm", "04:20pm", "04:25pm", "04:30pm", "04:35pm",
  "04:40pm", "04:45pm", "04:50pm", "04:55pm", "05:00pm", "05:05pm", "05:10pm", "05:15pm", "05:20pm", "05:25pm",
  "05:30pm", "05:35pm", "05:40pm", "05:45pm", "05:50pm", "05:55pm", "06:00pm", "06:05pm", "06:10pm", "06:15pm",
  "06:20pm", "06:25pm", "06:30pm", "06:35pm", "06:40pm", "06:45pm", "06:50pm", "06:55pm", "07:00pm", "07:05pm"
];

const venueNameArray = [
  "The Troubadour",
  "The Fillmore",
  "Red Rocks Amphitheatre",
  "The Tabernacle",
  "The Bowery Ballroom",
  "The Ryman Auditorium",
  "The 9:30 Club",
  "The Bluebird Cafe",
  "The Roxy Theatre",
  "The Apollo Theater",
  "The House of Blues",
  "The Whisky a Go Go",
  "The Greek Theatre",
  "The Wiltern",
  "The Palladium",
  "The Bitter End",
  "The Beacon Theatre",
  "The Orpheum Theatre",
  "The Regent Theater",
  "The Fox Theater",
  "The Gothic Theatre",
  "The Gorge Amphitheatre",
  "The Terminal 5",
  "The Hollywood Bowl",
  "The Fonda Theatre",
  "The Metro",
  "The Crocodile",
  "The Blue Note",
  "The Knitting Factory",
  "The Great American Music Hall",
  "The Masquerade"
]

const cityStateArray = [
  "Atlanta, GA",
  "Austin, TX",
  "Baltimore, MD",
  "Boston, MA",
  "Charlotte, NC",
  "Chicago, IL",
  "Cleveland, OH",
  "Dallas, TX",
  "Denver, CO",
  "Detroit, MI",
  "Houston, TX",
  "Indianapolis, IN",
  "Jacksonville, FL",
  "Kansas City, MO",
  "Las Vegas, NV",
  "Los Angeles, CA",
  "Louisville, KY",
  "Memphis, TN",
  "Miami, FL",
  "Milwaukee, WI",
  "Minneapolis, MN",
  "Nashville, TN",
  "New Orleans, LA",
  "New York, NY",
  "Oklahoma City, OK",
  "Omaha, NE",
  "Orlando, FL",
  "Philadelphia, PA",
  "Phoenix, AZ",
  "Pittsburgh, PA",
  "Portland, OR",
  "Providence, RI",
  "Raleigh, NC",
  "Richmond, VA",
  "Sacramento, CA",
  "Salt Lake City, UT",
  "San Antonio, TX",
  "San Diego, CA",
  "San Francisco, CA",
  "San Jose, CA",
  "Seattle, WA",
  "St. Louis, MO",
  "Tampa, FL",
  "Virginia Beach, VA",
  "Washington, DC",
  "Wichita, KS",
  "Albuquerque, NM",
  "Honolulu, HI",
  "Anchorage, AK",
  "Billings, MT"
];

const artistMerchArray = [
  "Tour T-Shirt",
  "Signed Poster",
  "Limited Edition Vinyl",
  "Hoodie",
  "Enamel Pin",
  "Backpack",
  "Beanie",
  "Embroidered Patch",
  "Snapback Hat",
  "Collectible Figurine",
  "Guitar Picks",
  "Stickers",
  "Keychain",
  "Tote Bag",
  "Bandana",
  "Wristband",
  "Phone Case",
  "Pop Socket",
  "Water Bottle",
  "Mouse Pad",
  "Throw Pillow",
  "Lanyard",
  "Poster Flag",
  "Sweatpants",
  "Crewneck Sweatshirt",
  "Sunglasses",
  "Socks",
  "Temporary Tattoos",
  "Dad Hat",
  "Pint Glass",
  "Shot Glass",
  "Mug",
  "Koozie",
  "Guitar Strap",
  "Necklace",
  "Bracelet",
  "Watch",
  "Sweater",
  "Fanny Pack",
  "Laptop Sleeve",
  "License Plate Frame",
  "Car Decal",
  "Bumper Sticker",
  "Lapel Pin",
  "Embroidered Beanie",
  "Polo Shirt",
  "Fleece Jacket",
  "Windbreaker Jacket",
  "Hiking Boots",
  "Band Tote",
];

const abbreviatedStatesArray = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
];

const areaChartSeries =
{
  "monthDataSeries1": {
    "prices": [
      8107.85,
      8128.0,
      8122.9,
      8165.5,
      8340.7,
      8423.7,
      8423.5,
      8514.3,
      8481.85,
      8487.7,
      8506.9,
      8626.2,
      8668.95,
      8602.3,
      8607.55,
      8512.9,
      8496.25,
      8600.65,
      8881.1,
      9340.85
    ],
    "dates": [
      "13 Nov 2017",
      "14 Nov 2017",
      "15 Nov 2017",
      "16 Nov 2017",
      "17 Nov 2017",
      "20 Nov 2017",
      "21 Nov 2017",
      "22 Nov 2017",
      "23 Nov 2017",
      "24 Nov 2017",
      "27 Nov 2017",
      "28 Nov 2017",
      "29 Nov 2017",
      "30 Nov 2017",
      "01 Dec 2017",
      "04 Dec 2017",
      "05 Dec 2017",
      "06 Dec 2017",
      "07 Dec 2017",
      "08 Dec 2017"
    ]
  },
  "monthDataSeries2": {
    "prices": [
      8423.7,
      8423.5,
      8514.3,
      8481.85,
      8487.7,
      8506.9,
      8626.2,
      8668.95,
      8602.3,
      8607.55,
      8512.9,
      8496.25,
      8600.65,
      8881.1,
      9040.85,
      8340.7,
      8165.5,
      8122.9,
      8107.85,
      8128.0
    ],
    "dates": [
      "13 Nov 2017",
      "14 Nov 2017",
      "15 Nov 2017",
      "16 Nov 2017",
      "17 Nov 2017",
      "20 Nov 2017",
      "21 Nov 2017",
      "22 Nov 2017",
      "23 Nov 2017",
      "24 Nov 2017",
      "27 Nov 2017",
      "28 Nov 2017",
      "29 Nov 2017",
      "30 Nov 2017",
      "01 Dec 2017",
      "04 Dec 2017",
      "05 Dec 2017",
      "06 Dec 2017",
      "07 Dec 2017",
      "08 Dec 2017"
    ]
  },
  "monthDataSeries3": {
    "prices": [
      7114.25,
      7126.6,
      7116.95,
      7203.7,
      7233.75,
      7451.0,
      7381.15,
      7348.95,
      7347.75,
      7311.25,
      7266.4,
      7253.25,
      7215.45,
      7266.35,
      7315.25,
      7237.2,
      7191.4,
      7238.95,
      7222.6,
      7217.9,
      7359.3,
      7371.55,
      7371.15,
      7469.2,
      7429.25,
      7434.65,
      7451.1,
      7475.25,
      7566.25,
      7556.8,
      7525.55,
      7555.45,
      7560.9,
      7490.7,
      7527.6,
      7551.9,
      7514.85,
      7577.95,
      7592.3,
      7621.95,
      7707.95,
      7859.1,
      7815.7,
      7739.0,
      7778.7,
      7839.45,
      7756.45,
      7669.2,
      7580.45,
      7452.85,
      7617.25,
      7701.6,
      7606.8,
      7620.05,
      7513.85,
      7498.45,
      7575.45,
      7601.95,
      7589.1,
      7525.85,
      7569.5,
      7702.5,
      7812.7,
      7803.75,
      7816.3,
      7851.15,
      7912.2,
      7972.8,
      8145.0,
      8161.1,
      8121.05,
      8071.25,
      8088.2,
      8154.45,
      8148.3,
      8122.05,
      8132.65,
      8074.55,
      7952.8,
      7885.55,
      7733.9,
      7897.15,
      7973.15,
      7888.5,
      7842.8,
      7838.4,
      7909.85,
      7892.75,
      7897.75,
      7820.05,
      7904.4,
      7872.2,
      7847.5,
      7849.55,
      7789.6,
      7736.35,
      7819.4,
      7875.35,
      7871.8,
      8076.5,
      8114.8,
      8193.55,
      8217.1,
      8235.05,
      8215.3,
      8216.4,
      8301.55,
      8235.25,
      8229.75,
      8201.95,
      8164.95,
      8107.85,
      8128.0,
      8122.9,
      8165.5,
      8340.7,
      8423.7,
      8423.5,
      8514.3,
      8481.85,
      8487.7,
      8506.9,
      8626.2
    ],
    "dates": [
      "02 Jun 2017",
      "05 Jun 2017",
      "06 Jun 2017",
      "07 Jun 2017",
      "08 Jun 2017",
      "09 Jun 2017",
      "12 Jun 2017",
      "13 Jun 2017",
      "14 Jun 2017",
      "15 Jun 2017",
      "16 Jun 2017",
      "19 Jun 2017",
      "20 Jun 2017",
      "21 Jun 2017",
      "22 Jun 2017",
      "23 Jun 2017",
      "27 Jun 2017",
      "28 Jun 2017",
      "29 Jun 2017",
      "30 Jun 2017",
      "03 Jul 2017",
      "04 Jul 2017",
      "05 Jul 2017",
      "06 Jul 2017",
      "07 Jul 2017",
      "10 Jul 2017",
      "11 Jul 2017",
      "12 Jul 2017",
      "13 Jul 2017",
      "14 Jul 2017",
      "17 Jul 2017",
      "18 Jul 2017",
      "19 Jul 2017",
      "20 Jul 2017",
      "21 Jul 2017",
      "24 Jul 2017",
      "25 Jul 2017",
      "26 Jul 2017",
      "27 Jul 2017",
      "28 Jul 2017",
      "31 Jul 2017",
      "01 Aug 2017",
      "02 Aug 2017",
      "03 Aug 2017",
      "04 Aug 2017",
      "07 Aug 2017",
      "08 Aug 2017",
      "09 Aug 2017",
      "10 Aug 2017",
      "11 Aug 2017",
      "14 Aug 2017",
      "16 Aug 2017",
      "17 Aug 2017",
      "18 Aug 2017",
      "21 Aug 2017",
      "22 Aug 2017",
      "23 Aug 2017",
      "24 Aug 2017",
      "28 Aug 2017",
      "29 Aug 2017",
      "30 Aug 2017",
      "31 Aug 2017",
      "01 Sep 2017",
      "04 Sep 2017",
      "05 Sep 2017",
      "06 Sep 2017",
      "07 Sep 2017",
      "08 Sep 2017",
      "11 Sep 2017",
      "12 Sep 2017",
      "13 Sep 2017",
      "14 Sep 2017",
      "15 Sep 2017",
      "18 Sep 2017",
      "19 Sep 2017",
      "20 Sep 2017",
      "21 Sep 2017",
      "22 Sep 2017",
      "25 Sep 2017",
      "26 Sep 2017",
      "27 Sep 2017",
      "28 Sep 2017",
      "29 Sep 2017",
      "03 Oct 2017",
      "04 Oct 2017",
      "05 Oct 2017",
      "06 Oct 2017",
      "09 Oct 2017",
      "10 Oct 2017",
      "11 Oct 2017",
      "12 Oct 2017",
      "13 Oct 2017",
      "16 Oct 2017",
      "17 Oct 2017",
      "18 Oct 2017",
      "19 Oct 2017",
      "23 Oct 2017",
      "24 Oct 2017",
      "25 Oct 2017",
      "26 Oct 2017",
      "27 Oct 2017",
      "30 Oct 2017",
      "31 Oct 2017",
      "01 Nov 2017",
      "02 Nov 2017",
      "03 Nov 2017",
      "06 Nov 2017",
      "07 Nov 2017",
      "08 Nov 2017",
      "09 Nov 2017",
      "10 Nov 2017",
      "13 Nov 2017",
      "14 Nov 2017",
      "15 Nov 2017",
      "16 Nov 2017",
      "17 Nov 2017",
      "20 Nov 2017",
      "21 Nov 2017",
      "22 Nov 2017",
      "23 Nov 2017",
      "24 Nov 2017",
      "27 Nov 2017",
      "28 Nov 2017"
    ]
  }
}

const adHeadlineArray = ["Unleash Sound Power", "Elevate Your Music", "Experience True Bass", "Find Your Rhythm", "Play With Precision", "Create Sonic Magic", "Feel the Beat", "Transform Your Sound", "Unleash Your Creativity", "Revolutionize Your Music", "Master the Mix", "Elevate Your Performance", "Experience Pro Sound", "Unleash Your Potential", "Create Epic Beats", "Feel the Energy", "Elevate Your Style", "Experience Pure Sound", "Transform Your Studio", "Get the Perfect Sound", "Create Next-Level Music", "Explore New Horizons", "Play With Perfection", "Experience the Future", "Take Your Sound Higher", "Unleash Your Passion", "Create Legendary Tracks", "Elevate Your Skills", "Experience the Magic", "Get the Ultimate Sound", "Play Like a Pro", "Transform Your Workflow", "Unleash Your Imagination", "Create a Masterpiece", "Feel the Power", "Elevate Your Game", "Experience Immersive Sound", "Get the Best Gear", "Play With Confidence", "Transform Your Music", "Unleash Your Potential", "Create Epic Tracks", "Feel the Vibes", "Elevate Your Performance", "Experience Unmatched Quality", "Get the Perfect Mix", "Play With Passion", "Transform Your Soundscapes", "Unleash Your Artistry", "Create Timeless Music", "Feel the Emotion"];

const adDescriptionArray = [
  "Unleash Your Inner Rockstar with Our Electric Guitars!",
  "Experience Unmatched Sound Quality with Our High-End Headphones",
  "Get Your Groove On with Our Bluetooth Speakers",
  "Upgrade Your DJ Game with Our Professional Mixers",
  "Bring Your Music to Life with Our Hi-Fi Home Audio Systems",
  "Take Your Beats to the Next Level with Our Studio Headphones",
  "Create Your Own Masterpiece with Our Digital Audio Workstations",
  "Experience the Power of Live Music with Our PA Systems",
  "Bring Your Music Anywhere with Our Portable Bluetooth Speakers",
  "Get Lost in Your Music with Our Noise-Canceling Headphones",
  "Take Center Stage with Our Microphone and Stand Sets",
  "Elevate Your Sound System with Our Premium Amplifiers",
  "Unleash Your Creativity with Our MIDI Keyboards",
  "Revolutionize Your Music Production with Our Sample Libraries",
  "Find Your Rhythm with Our Drum Machines",
  "Experience High-Quality Sound with Our In-Ear Monitors",
  "Make Your Music Stand Out with Our Vocal Processors",
  "Upgrade Your Music Setup with Our Audio Interfaces",
  "Get the Party Started with Our DJ Controllers",
];

const genreImageArray = [
  "chill.png",
  "decades.png",
  "focus.png",
  "hip-hop.png",
  "latin.png",
  "mood.png",
  "pop.png",
  "rap.png",
];

const photoCaptionArray = [
  "The crowd goes wild at Coachella",
  "A sea of fans at Lollapalooza",
  "Bassnectar bringing the beats at Bonnaroo",
  "Good vibes at Electric Forest",
  "The sun sets on Firefly",
  "Mosh pit madness at Warped Tour",
  "Jamming out at Outside Lands",
  "The stage is set at Glastonbury",
  "Sweat and smiles at Ultra Music Festival",
  "Dancing under the stars at Tomorrowland",
  "Feeling the rhythm at Afropunk",
  "A beautiful day for Hangout Fest",
  "Vibing to the music at Essence Fest",
  "Rocking out at Download Festival",
  "Crowd surfing at Reading and Leeds",
  "Hip hop legends at Rolling Loud",
  "Chilling in the grass at Sasquatch!",
  "A dreamy night at Dreamstate",
  "Celebrating pride at NYC WorldPride",
  "Getting lost in the music at Electric Zoo",
  "Raving in the desert at Burning Man",
  "Smiling faces at Voodoo Fest",
  "A magical moment at Enchanted Forest",
  "Pumping fists at Mayhem Fest",
  "The party never stops at EDC",
  "Folk music under the sun at Newport Folk Festival",
  "Getting groovy at Shambhala",
  "Headbanging at Metallica's Orion Fest",
  "Soaring spirits at Summerfest",
  "Singing along at The Governors Ball",
  "A funky time at Funk Fest",
  "Dancing in the rain at Rock Werchter",
  "A beautiful sight at SnowGlobe",
  "Unforgettable performances at ACL Fest",
  "Jazzing it up at Monterey Jazz Festival",
  "Chilling by the river at Riverfest",
  "Country music heaven at Stagecoach",
  "Reggae vibes at Cali Roots Fest",
  "A peaceful moment at Wanderlust",
  "Indie rock bliss at Pitchfork Music Festival",
  "A colorful crowd at Life is Beautiful",
  "An epic show at Panorama",
  "A fiery performance at Warped Tour",
  "Punk rock energy at Riot Fest",
  "Hip hop royalty at Made in America",
  "Eclectic sounds at Desert Daze",
  "A wild time at Mardi Gras",
  "Rocking out at Louder Than Life",
  "Festival fun at the Minnesota State Fair",
  "Feeling grateful today 🙏",
  "Life is better with friends ❤️",
  "Making memories with my favorite people 😍",
  "Sunsets and good vibes 🌅✌️",
  "The world is a beautiful place 🌍❤️",
  "Wanderlust and adventure 🌴✈️",
  "Smiling through the struggles 😊💪",
  "Happiness is a choice 😃💛",
  "Living my best life 💁‍♀️💕",
  "Appreciating the little things 🌸🌼",
  "Stay positive and keep moving forward 🚶‍♀️✨",
  "You are enough 💖👑",
  "Life is too short to not chase your dreams 🌟💭",
  "Be yourself, everyone else is already taken 🌈🌟",
  "Surrounding myself with good energy and positive people 🤗🙌",
  "Never stop learning and growing 📚🌱",
  "I am grateful for this moment 🌟🙏",
  "Life is a journey, not a destination 🚀🌌",
  "The best is yet to come 🌅🌠",
  "Chasing sunsets and good vibes 🌄✨",
  "Living life to the fullest 🌞🌈",
  "Appreciating the beauty around me 🌺🍃",
  "Enjoying the simple things in life 🍂🍁",
  "Spreading love and positivity ❤️✨",
  "Life is an adventure, let's explore 🗺️🌍",
  "Dreaming big and working hard 💭💪",
  "Taking a moment to appreciate the present 🌟🙏",
  "Never give up on your dreams 💫💖",
  "Embracing the journey 🛣️💕",
  "Life is too short to not be happy 😃💛",
  "Chasing the sun ☀️🌅",
  "Striving for progress, not perfection 💪🌟",
  "Life is what you make it 💁‍♀️✨",
  "Grateful for the people who make life better ❤️👫",
  "Happiness is a choice, choose it every day 😊💕",
  "The best things in life are the people we love ❤️",
  "A little bit of sunshine on a cloudy day ☀️🌥️",
  "Grateful for the small things that make life special 🌸🌷",
  "Every day is a new opportunity 💫🌟",
  "The world is full of possibilities 🌍✨",
  "Life is a beautiful journey 🌺🌴",
  "Chasing my dreams, one step at a time 🏃‍♀️💫",
  "Life is better with a smile 😊💖",
  "Taking a moment to appreciate the beauty around me 🌸🍃",
  "Believe in yourself and all that you are 💫💕",
  "Grateful for the people who make my life better every day ❤️🌟",
  "Keep moving forward, even if it's just one step at a time 🚶‍♀️💫",
  "Embracing the journey and enjoying the ride 🌈✨",
];

const photoCommentsArray = [
  "Gorgeous photo! 😍",
  "You look amazing in this picture! 😊",
  "Wow, this is stunning! 🔥",
  "Absolutely beautiful! ❤️",
  "Love the colors in this photo! 🎨",
  "This photo belongs in a magazine! 👌",
  "You have an eye for capturing the perfect shot! 📷",
  "What a lovely moment captured! 💕",
  "The lighting in this photo is everything! ✨",
  "This photo is giving me all the feels! 😭❤️",
  "Amazing photo, you truly have a talent for photography! 🙌",
  "Stunning scenery captured in this photo! 🌅",
  "This photo is a work of art! 🎨",
  "You are such a natural in front of the camera! 😍",
  "The composition in this photo is spot on! 👌",
  "I love how this photo tells a story! 📖",
  "What a beautiful way to capture a moment in time! ⏰",
  "This photo is simply breathtaking! 😱",
  "I can't stop looking at this photo, it's that good! 😍",
  "You have a true talent for capturing emotion in your photos! ❤️",
  "This photo is giving me serious travel envy! 🌴✈️",
  "The colors in this photo are so vibrant and eye-catching! 🌈",
  "What a beautiful photo of a beautiful person! 😍",
  "This photo is so dreamy and romantic! 💭💕",
  "The details in this photo are incredible! 👀",
  "I love how this photo captures the essence of the moment! 💫",
  "This photo is making me feel all warm and fuzzy inside! 🥰",
  "You have a real talent for capturing the beauty in everyday life! 🌟",
  "What a stunning photo of a stunning location! 🌅🌴",
  "This photo is a true masterpiece! 🎨",
  "The depth of field in this photo is amazing! 👌",
  "You are a true artist with a camera! 📷",
  "This photo is pure magic! ✨",
  "You are a true natural in front of the camera! 😍👌",
  "The lighting in this photo is perfection! 🌟",
  "This photo is giving me all the wanderlust feels! 🌍",
  "I love how this photo captures the joy in the moment! 😊",
  "This photo is so full of life and energy! 🌟💥",
  "You truly have a talent for capturing the essence of a moment! 💫",
  "This photo is a true work of art! 🎨",
  "The colors in this photo are so vibrant and alive! 🌈",
  "This photo is pure happiness captured in a frame! 😍😊",
  "I love the perspective in this photo, it's so unique! 👀",
  "This photo is so full of personality and character! 😃",
  "The mood in this photo is so dreamy and ethereal! 💭✨",
  "This photo is simply stunning! 🔥",
  "You have a real talent for capturing the beauty in nature! 🌿🌸",
  "This photo is a true testament to your creativity and skill! 🙌",
  "You look amazing in this pic! 😘",
  "Wow, what a stunning shot! 🤩",
  "This photo is everything! 😍🔥",
  "I can't get enough of your feed! 😍",
  "You always have the best content! 🔥👌",
  "This is such a beautiful moment captured! 😍",
  "Your style is always on point! 👌",
  "Love your aesthetic! 😍",
  "This photo is giving me all the feels! ❤️",
  "You are a natural in front of the camera! 😍",
  "Beautiful photo, beautiful person! 😘❤️",
  "You have such a great eye for photography! 👌📷",
  "Your creativity never ceases to amaze me! 🔥",
  "I am in awe of your talent! 😍",
  "This is a work of art! 🎨👌",
  "You are a true inspiration! 💪🌟",
  "You are glowing in this pic! ✨😍",
  "This photo is so dreamy! 😍",
  "You make everything look effortlessly beautiful! 🔥",
  "Your photos always brighten up my day! ☀️",
  "This is such a fun and vibrant photo! 🌈😍",
  "You have such a great energy in all your photos! 🙌",
  "Love this photo, love your vibe! 😍👌",
  "I am constantly blown away by your talent! 🔥👏",
  "You are a true queen/king of Instagram! 👑😍",
  "This photo is pure magic! ✨😍",
  "You are killing it on the gram! 🔥🙌",
  "This photo is so serene and peaceful! 😍🌊",
  "Your feed is always so inspiring! 💪🌟",
  "You are a true artist! 🎨👌",
  "This photo captures your essence perfectly! 😘❤️",
  "You have such a beautiful soul, and it shines through in your photos! 😍",
  "This photo is a masterpiece! 🙌👏",
  "You are a natural beauty! 😍✨",
  "This photo is a true work of art! 🎨",
  "You have such an eye for detail! 👌👀",
  "This photo is so powerful! 💪😍",
  "Your photos always make me smile! 😊",
  "This photo is so dreamy and romantic! 😍💕",
  "You are a true inspiration to me! 🔥💪",
  "This photo is simply stunning! 😍",
  "You have a way of capturing the beauty in everything! 👌📷",
  "This photo is so elegant and sophisticated! 😍👌",
  "You are the definition of beauty! 😘❤️",
  "This photo is so full of life and energy! 🌈🔥",
  "You are a natural beauty! 😍🌸",
  "This photo is so powerful and empowering! 💪👏",
  "You are a true artist and visionary! 🎨👌",
  "This photo captures your spirit perfectly! 😍🌟",
  "You have such a unique and beautiful perspective!",
];

const eventTitleArray = [
  "Club Quarantine",
  "Verzuz",
  "Boiler Room",
  "Digital Mirage",
  "Roblox Listening Parties",
  "The Social Club",
  "Secret Sky",
  "Twitch Music",
  "Clubhouse Sessions",
  "NPR Music Live Sessions",
  "Mixcloud Live",
  "Beatport Presents: ReConnect",
  "Global Dance Digital Festival",
  "Couch Lands",
  "Insomniac TV",
  "United We Stream",
  "Defected Virtual Festival",
  "Factory 93 Experience",
  "Anjunabeats Worldwide",
  "DGTL",
  "Digital Dreams Festival",
  "Future Stream",
  "Escape Psycho Circus Virtual Rave-A-Thon",
  "Lost Horizon Festival",
  "Room Service Festival",
  "Isol-Aid Festival",
  "Live from Out There",
  "Swedish House Mafia: Live from One World Radio",
  "New York Philharmonic Virtual Concerts",
  "Utopia Music Festival",
  "Erykah Badu: Apocalypse One",
  "Newport Folk Festival's Folk On Revival Weekend",
  "Nyege Nyege Festival",
  "Consequence's Protect Live Music Series",
  "Diplo Presents: Higher Ground",
  "Awesome Soundwave",
  "Quarantini Virtual Music Festival",
  "Glastonbury Experience 2021",
  "Rock in Rio USA",
  "Splash House Home Edition",
  "DGTL x Mosaic Livestream",
  "Rave Family Block Fest",
  "Movement At Home",
  "Together At Home",
  "Summerfest 2020",
  "Loud Kult Virtual Music Festival",
  "Discogs Virtual Crate Diggers",
  "KISS Haunted House Party",
  "Virtually Nowadays",
  "PlayOn Fest",
  "Bud Light Seltzer Sessions",
  "Rockin' the Classics: A Tribute to the Greatest Hits of the 70s and 80s",
  "Country Crossroads: A Festival of Up-and-Coming and Established Artists",
  "Jazzin' Up the Night: A Mix of Traditional and Modern Jazz",
  "Classical Masterpieces: A Live Orchestra Performance of Mozart, Beethoven, and Brahms",
  "Alternative Avenue: A Diverse Lineup of Alternative and Indie Rock Bands",
  "Hip-Hop Hooray: A Concert with Up-and-Coming Artists and Established Stars",
  "Pop Party: A Concert with Chart-Topping Hits by Popular Artists",
  "Reggae Rhythms: A Festival with Some of the Biggest Names in the Genre",
  "Blues Blast: A Showcase of Traditional and Contemporary Blues Music",
  "World Beat: A Concert with Traditional Music from Around the Globe",
  "Punk Power: A High-Energy Concert with Underground Bands",
  "Folksy Feels: A Festival of Acoustic Singer-Songwriters and Bands",
  "Classical Crescendo: A Concert with Solo and Ensemble Performances by Virtuoso Musicians",
  "Gospel Glory: A Concert with Powerful Vocal Performances and Uplifting Messages",
  "Metal Madness: A Festival with the Best in Heavy Metal and Hard Rock Music",
  "Remembering the Legends: A Tribute Concert to a Legendary Musician or Band",
  "Bluegrass Bonanza: A Festival with Some of the Top Names in the Genre",
  "Techno Thrills: A Concert with Electronic Beats and Futuristic Visuals",
  "Soul Sensations: A Concert with Up-and-Coming Artists and Established Stars",
  "Chamber Classics: An Intimate Concert with Small Ensembles",
  "Broadway Bound: A Musical Production with Catchy Tunes and Elaborate Stage Designs",
  "Contemporary Crescendo: A Symphony Orchestra Performance of Contemporary Classical Works",
  "Mariachi Magic: A Concert with Traditional Mexican Music and Dance",
  "Flamenco Frenzy: A Concert with Passionate Guitar Playing and Intricate Footwork",
  "Choral Celebrations: A Concert with Soaring Vocal Harmonies and Lush Arrangements",
  "Pop-Up Performances: Surprise Music Events in Unexpected Locations",
  "Arts & Amps: A Music and Arts Festival with a Diverse Lineup of Musicians and Artists",
  "Baroque Brilliance: A Concert with Ornate Compositions and Period Instruments",
  "Hip-Hop Happenings: A Festival with Up-and-Coming Artists and Established Stars",
  "Fusion Frenzy: A Concert with a Blend of Different Musical Genres and Styles",
  "Chamber Connections: A Festival with Performances by Small Ensembles from Around the World",
  "Music Mania: A Multi-Day Festival with Camping, Food Vendors, and a Variety of Music Genres",
  "Musical Marvels: A Theatrical Production with Talented Actors and Singers Performing Beloved Songs",
  "New Music Now: A Festival Showcasing the Latest Trends in Experimental and Avant-Garde Music",
  "A Cappella Craze: A Concert with Vocal Performances without Instrumental Accompaniment",
  "Classical Crossover: A Concert with Classical Musicians Playing Popular Songs",
  "Music & Munchies: A Food Festival with Gourmet Cuisine and Live Music Performances",
  "Wine & Jazz: A Wine Festival with Tastings of Fine Wines and Performances by Jazz musicians and Vocalists",
];

const eventDescriptionArray = [
  "A virtual dance party that gained popularity during the COVID-19 pandemic.",
  "A webcast series where two prominent musicians or producers compete against each other by playing hits from their catalog.",
  "A global online music broadcasting platform showcasing DJ sets and live music performances from around the world.",
  "A virtual music festival featuring live sets from popular electronic music artists.",
  "An immersive listening experience within the virtual world of Roblox, where users can listen to and share music with friends.",
  "A digital music platform that features a variety of live performances, DJ sets, and interviews with musicians.",
  "A virtual music festival organized by Porter Robinson that features performances from a wide range of electronic music artists.",
  "A music streaming service that allows musicians to perform live and interact with their fans in real-time.",
  "Live DJ sets and musical performances on the Clubhouse app, a social media platform based on audio conversations.",
  "A series of live concerts, performances, and interviews with musicians that are broadcasted online by NPR Music.",
  "A platform for DJs, radio presenters, and podcasters to share their mixes and radio shows with a global audience.",
  "A virtual event series that features performances and DJ sets from a variety of electronic music artists.",
  "A virtual music festival featuring performances from a wide range of electronic music artists.",
  "A virtual music platform that features live performances, DJ sets, and other music-related content.",
  "A digital music platform that features live performances, DJ sets, and other music-related content.",
  "A virtual festival featuring performances from some of the biggest names in electronic dance music.",
  "A virtual music festival that showcases a diverse range of music genres and styles.",
  "A virtual event series that features live sets from some of the biggest names in electronic dance music.",
  "A virtual music festival that features performances from a wide range of electronic music artists.",
  "A virtual music festival that features performances from a diverse range of music genres and styles.",
  "A virtual concert series that features performances by the New York Philharmonic orchestra.",
  "A virtual music festival that features performances from a diverse range of music genres and styles.",
  "A virtual concert experience featuring a performance by Erykah Badu.",
  "A virtual music festival featuring performances from a variety of folk music artists.",
  "A virtual music festival featuring performances from a wide range of African music artists.",
  "A virtual music event series that features live performances and interviews with musicians.",
  "A virtual event series that features live DJ sets and performances from a variety of electronic music artists.",
  "A virtual music festival that features performances from a variety of electronic music artists.",
  "A virtual music festival that showcases a diverse range of music genres and styles.",
  "A virtual music festival that features performances from some of the biggest names in electronic dance music.",
  "A virtual music festival that features performances from a variety of electronic music artists.",
  "A rock concert featuring classic hits from the 70s and 80s.",
  "A country music festival showcasing up-and-coming artists as well as established performers.",
  "A jazz ensemble playing a mix of traditional jazz standards and modern compositions.",
  "A live orchestra performing classical works by Mozart, Beethoven, and Brahms.",
  "A music festival featuring a diverse lineup of alternative and indie rock bands.",
  "A hip-hop concert featuring performances by up-and-coming artists as well as established stars.",
  "A pop music concert featuring chart-topping hits by popular artists.",
  "A reggae music festival featuring performances by some of the biggest names in the genre.",
  "A blues festival showcasing the best in traditional and contemporary blues music.",
  "A world music concert featuring traditional music from around the globe.",
  "A punk rock concert featuring high-energy performances by underground bands.",
  "A folk music festival featuring acoustic performances by singer-songwriters and bands.",
  "A classical music concert featuring solo and ensemble performances by virtuoso musicians.",
  "A gospel music concert featuring powerful vocal performances and uplifting messages.",
  "A metal music festival showcasing the best in heavy metal and hard rock music.",
  "A tribute concert honoring the music and legacy of a legendary musician or band.",
  "A bluegrass music festival featuring performances by some of the top names in the genre.",
  "A techno music concert featuring electronic beats and futuristic visuals.",
  "A soul music concert featuring performances by up-and-coming artists as well as established stars.",
  "A chamber music concert featuring intimate performances by small ensembles.",
  "A Broadway musical production featuring catchy tunes and elaborate stage designs.",
  "A symphony orchestra performing a program of contemporary classical works.",
  "A mariachi music concert featuring traditional Mexican music and dance.",
  "A flamenco music concert featuring passionate guitar playing and intricate footwork.",
  "A choral music concert featuring soaring vocal harmonies and lush arrangements.",
  "A pop-up music event featuring surprise performances in unexpected locations.",
  "A music and arts festival featuring a diverse lineup of musicians, artists, and performers.",
  "A baroque music concert featuring ornate compositions and period instruments.",
  "A hip-hop festival featuring performances by up-and-coming artists and established stars.",
  "A fusion music concert featuring a blend of different musical genres and styles.",
  "A chamber music festival featuring performances by small ensembles from around the world.",
  "A multi-day music festival featuring camping, food vendors, and a variety of music genres.",
  "A musical theater production featuring talented actors and singers performing beloved songs.",
  "A new music festival showcasing the latest trends in experimental and avant-garde music.",
  "A cappella music concert featuring vocal performances without instrumental accompaniment.",
  "A classical crossover concert featuring classical musicians playing popular songs.",
  "A music and food festival featuring gourmet cuisine and live music performances.",
  "A music and wine festival featuring tastings of fine wines and performances by jazz musicians.",
  "A rock opera production featuring epic storytelling and soaring musical numbers.",
  "A rap music concert featuring hard-hitting beats and powerful lyrics.",
  "A festival of sacred music featuring performances by choirs and ensembles from different faiths.",
  "A world percussion festival featuring drumming traditions from different cultures and regions.",
  "A holiday music concert featuring festive songs and classic carols.",
  "A classical guitar concert featuring virtuoso performances by top guitarists from around the world.",
  "A music and beer festival featuring tastings of craft beers and performances by indie rock bands.",
];

const opsPageTitleArray = [
  "A Debut Album by Rising Star",
  "A Tour Across the United States",
  "Help Us Record Our Second Album",
  "Support Our Indie Music Festival",
  "Bring Our Music to Vinyl",
  "Creating a Music Video for Our Hit Single",
  "Reviving Classic Rock with a New Album",
  "Help Fund Our World Tour",
  "Preserving Traditional Music with a New Album",
  "A Collaborative Album with Artists Around the World",
  "A Music Education Program for Kids",
  "Creating a Documentary About Our Band",
  "Recording a Live Album in an Unusual Venue",
  "A New Album Fusing Jazz and Hip-Hop",
  "Building a Recording Studio for Independent Artists",
  "A Tour of College Campuses to Promote Our Music",
  "Supporting Local Musicians with a Showcase Series",
  "A New Album Celebrating Latinx Culture",
  "Reviving a Forgotten Genre with a New Album",
  "A Benefit Concert for Music Education",
  "Creating a Musical About Social Justice",
  "An Album of Original Songs Inspired by Nature",
  "A Concert Series Featuring Female Artists",
  "Creating a Hip-Hop Album with a Positive Message",
  "A Tour of Europe with Our Jazz Band",
  "Recording a New Album with a Grammy-Winning Producer",
  "A Music Therapy Program for Veterans",
  "Producing a Music Video for Emerging Artists",
  "A New Album Combining Folk and Electronic Music",
  "A Concert Series Featuring BIPOC Musicians",
  "A New Album Inspired by Science Fiction",
  "Creating a Collaborative Album with Local Musicians",
  "A Tour of Music Festivals Across the US",
  "Recording a New Album with a Classical Twist",
  "A Music Education Program for Incarcerated Youth",
  "Supporting Indie Musicians with a Recording Grant",
  "A New Album Celebrating LGBTQ+ Artists",
  "A Benefit Concert for Mental Health Awareness",
  "Creating a Music Video Featuring Animations",
  "A New Album Inspired by Traditional African Music",
  "A Tour of Latin America with Our Band",
  "Recording a New Album with a Popular Producer",
  "A Music Program for Children with Disabilities",
  "Creating a New Album with Experimental Soundscapes",
  "A Concert Series Featuring Indigenous Musicians",
  "A New Album Fusing Pop and Country Music",
  "A Benefit Concert for Climate Change Activism",
  "A Tour of Asia with Our World Music Ensemble",
  "Recording a New Album with a World-Renowned Engineer",
  "A Music Therapy Program for Cancer Patients"
];

const opsPageDescriptionArray = [
  "We're a rising star in the music industry and we need your help to produce our debut album!",
  "We're hitting the road for a tour across the United States and we need your support to make it happen.",
  "We've written some amazing new songs for our second album, but we need your help to record them.",
  "We're putting on an indie music festival in our hometown and we need your support to make it a success.",
  "We want to bring our music to vinyl and we need your help to cover the costs of pressing and production.",
  "Our hit single deserves a music video and we need your support to bring our vision to life.",
  "We're reviving classic rock with a new album and we need your help to cover the costs of recording and production.",
  "We're taking our music on a world tour and we need your support to make it a reality.",
  "We're passionate about preserving traditional music and we need your help to produce our new album.",
  "We're collaborating with artists from around the world on a new album and we need your support to cover the costs of production.",
  "We're launching a music education program for kids and we need your support to get it off the ground.",
  "We want to create a documentary about our band and we need your support to cover the costs of filming and production.",
  "We're recording a live album in an unusual venue and we need your support to make it happen.",
  "We're fusing jazz and hip-hop on our new album and we need your support to cover the costs of recording and production.",
  "We're building a recording studio for independent artists and we need your support to cover the costs of equipment and construction.",
  "We're touring college campuses to promote our music and we need your support to cover the costs of travel and lodging.",
  "We're showcasing local musicians with a concert series and we need your support to cover the costs of venue rental and production.",
  "We're celebrating Latinx culture with a new album and we need your support to cover the costs of recording and production.",
  "We're reviving a forgotten genre with a new album and we need your support to cover the costs of recording and production.",
  "We're putting on a benefit concert for music education and we need your support to cover the costs of venue rental and production.",
  "We're creating a musical about social justice and we need your support to cover the costs of production and theater rental.",
  "Our album of original songs inspired by nature needs your support to cover the costs of recording and production.",
  "We're hosting a concert series featuring female artists and we need your support to cover the costs of venue rental and production.",
  "We're creating a hip-hop album with a positive message and we need your support to cover the costs of recording and production.",
  "We're taking our jazz band on a tour of Europe and we need your support to cover the costs of travel and lodging.",
  "We're recording a new album with a Grammy-winning producer and we need your support to cover the costs of studio time and production.",
  "We're launching a music therapy program for veterans and we need your support to cover the costs of hiring therapists and buying equipment.",
  "We're producing a music video for emerging artists and we need your support to cover the costs of filming and production.",
  "We're fusing folk and electronic music on our new album and we need your support to cover the costs of recording and production.",
  "Help fund our debut album and support the next rising star in the music industry.",
  "We're embarking on a cross-country tour to bring our music to new audiences. Help us make it happen!",
  "Our first album was a huge success, and now we need your help to record our highly anticipated second album.",
  "We're organizing an indie music festival and need your support to make it the best it can be.",
  "Vinyl is making a comeback, and we want to bring our music to this timeless medium. Help us make it happen!",
  "Our hit single needs a music video to take it to the next level. Help us create a stunning visual experience for our fans.",
  "We're reviving classic rock with a new album that pays homage to the greats of the genre. Join us on this musical journey!",
  "We've been invited to perform at festivals around the world, but we need your help to make it happen. Help us fund our world tour!",
  "We're preserving traditional music with a new album that celebrates the rich cultural heritage of our ancestors.",
  "We're collaborating with artists from around the world to create a truly unique and diverse album. Join us on this musical adventure!",
  "Music education is essential, and we're creating a program that teaches kids the joy of music. Help us inspire the next generation of musicians!",
  "Our band has a fascinating story to tell, and we're creating a documentary to share it with the world. Help us make it happen!",
  "We're recording a live album in an unusual venue, and we want you to be a part of this unforgettable musical experience.",
  "Jazz and hip-hop are two of the most innovative genres of our time, and we're fusing them together in a new album that breaks boundaries.",
  "We're building a recording studio that caters to independent artists and provides a professional space for them to create their music.",
  "We're touring college campuses to promote our music and connect with students. Help us spread the joy of music!",
  "We're supporting local musicians by organizing a showcase series that provides them with a platform to share their music with the world.",
  "Our new album celebrates the vibrant culture of Latinx communities and features a diverse array of musicians and styles.",
  "We're reviving a forgotten genre with a new album that brings it into the 21st century. Join us on this musical journey!",
  "Music education is essential, and we're organizing a benefit concert to raise awareness and funds for this important cause.",
  "We're creating a musical that tackles social justice issues and inspires audiences to take action. Help us bring this important message to the stage!",
  "Our album is inspired by the beauty and wonder of nature, and we want to share this experience with our fans through our music.",
  "We're promoting female artists by organizing a concert series that features some of the most talented musicians in the industry.",
  "Hip-hop has the power to change the world, and we're creating an album that promotes positivity and social change.",
  "We're taking our jazz band on a tour of Europe, and we want you to be a part of this unforgettable musical journey.",
  "We're recording a new album with a Grammy-winning producer, and we need your help to make it happen.",
  "Music therapy is a powerful tool for healing, and we're creating a program that helps veterans find comfort and solace through music.",
  "We're producing a music video for emerging artists that showcases their talent and provides them with a platform to launch their careers.",
];

const opsPageDonationDescriptionArray = [
  "Donate $10 and receive a digital download of our latest single.",
  "Donate $25 and receive a signed copy of our latest album.",
  "Donate $50 and receive a VIP ticket to our next concert.",
  "Donate $100 and receive a personalized thank-you video from the band.",
  "Donate $250 and receive a limited edition vinyl pressing of our latest album.",
  "Donate $500 and receive a private concert from the band at a location of your choice.",
  "Donate $1,000 and receive an exclusive behind-the-scenes tour of our recording studio.",
  "Donate $2,500 and receive an executive producer credit on our next album.",
  "Donate $5,000 and receive a private songwriting session with the band.",
  "Donate $10,000 and receive a personalized song written and recorded by the band just for you.",
  "Donate $15 and receive a signed poster from our latest tour.",
  "Donate $30 and receive a t-shirt featuring our band's logo.",
  "Donate $75 and receive a personalized, autographed drumhead from the band.",
  "Donate $150 and receive a private meet-and-greet with the band before our next concert.",
  "Donate $300 and receive a limited edition, hand-painted guitar signed by the band.",
  "Donate $750 and receive a private acoustic concert from the band at a location of your choice.",
  "Donate $1,500 and receive a personalized lyric sheet from our latest album, signed by the band.",
  "Donate $3,000 and receive a private recording session with the band at our studio.",
  "Donate $7,500 and receive a full-day songwriting and recording session with the band.",
  "Donate $12,500 and receive a personalized, hand-crafted guitar from a master luthier, signed by the band.",
  "Donate $20 and receive a sticker pack featuring our band's logo.",
  "Donate $40 and receive a hoodie featuring our band's logo.",
  "Donate $100 and receive a personalized, autographed setlist from our next concert.",
  "Donate $200 and receive a limited edition, signed and numbered screenprint of our band's artwork.",
  "Donate $500 and receive a private Q&A session with the band, hosted via video chat.",
  "Donate $1,000 and receive a personalized, one-of-a-kind piece of art inspired by our music.",
  "Donate $2,500 and receive a personalized, custom-made drum kit inspired by our music.",
  "Donate $5,000 and receive a personalized, hand-crafted guitar from a master luthier, featuring artwork inspired by our music.",
  "Donate $10,000 and receive a private, all-expenses-paid concert from the band at a location of your choice.",
  "Donate $25 and receive a digital download of our discography.",
  "Donate $50 and receive a signed CD copy of our latest album.",
  "Donate $75 and receive a limited edition poster featuring our band's artwork.",
  "Donate $150 and receive a personalized, autographed drumstick from the band.",
  "Donate $300 and receive a limited edition, signed and numbered lithograph of our band's artwork.",
  "Donate $500 and receive a private songwriting session with our lead songwriter.",
  "Donate $1,000 and receive a personalized, acoustic performance of our music at a location of your choice.",
  "Donate $2,500 and receive a personalized, one-of-a-kind piece of art inspired by our music, created by a local artist.",
];

const directoryAdCopyArray = [
  "Professional guitarist seeking gig opportunities",
  "Experienced bassist looking for new bandmates",
  "Singer/songwriter seeking backup musicians",
  "Drummer available for studio sessions and live shows",
  "Classically trained violinist looking to join an orchestra",
  "Pianist available for weddings and events",
  "Guitarist and vocalist seeking acoustic duo partner",
  "Bassist looking for cover band or original project",
  "Trumpet player available for jazz gigs and recording",
  "Versatile keyboardist seeking band or session work",
  "Lead vocalist with wide range seeking new band",
  "Bilingual singer available for Spanish-language projects",
  "Experienced drummer seeking established band",
  "Guitarist available for session work and touring",
  "Keyboardist looking for synth-pop or new wave band",
  "Versatile percussionist seeking world music or fusion project",
  "Bassist with funk and R&B experience seeking new gig",
  "Classically trained cellist available for gigs and events",
  "Pianist with jazz background seeking new projects",
  "Vocalist with experience in rock, pop, and country",
  "Trombonist seeking big band or brass ensemble",
  "Experienced drummer with jazz and Latin influences",
  "Guitarist with rock and metal experience seeking band",
  "Keyboardist with classical and electronic training",
  "Bassist with punk and indie rock background",
  "Violinist available for weddings and special events",
  "Lead vocalist with experience in blues and soul",
  "Drummer seeking rock or heavy metal band",
  "Versatile guitarist with pop and folk influences",
  "Keyboardist with gospel and R&B experience",
  "Bassist with reggae and world music influences",
  "Experienced saxophonist seeking jazz or funk band",
  "Guitarist with blues and classic rock influences",
  "Pianist with classical and contemporary training",
  "Lead vocalist with theater and cabaret experience",
  "Drummer with hip hop and electronic influences",
  "Bassist with metal and punk influences seeking new project",
  "Trumpet player with brass band and marching experience",
  "Violinist with folk and Americana background",
  "Singer seeking acoustic guitarist for gigs and recording",
  "Experienced percussionist with Latin and African influences",
  "Guitarist with jazz and fusion experience",
  "Keyboardist with electronic and ambient influences",
  "Bassist with funk and soul experience seeking new band",
  "Classically trained flutist available for chamber ensembles",
  "Vocalist with experience in pop punk and emo",
  "Trombonist with ska and reggae influences seeking band",
  "Drummer with rockabilly and surf rock experience",
  "Guitarist with flamenco and world music influences",
  "Keyboardist with 80s synth-pop and new wave influences",
  "Music industry professional with 10 years of experience seeking new opportunity",
  "Experienced artist manager seeking talented musicians to represent",
  "Record label executive seeking A&R manager for new projects",
  "Marketing professional with music industry experience seeking new role",
  "Tour manager with extensive experience in international touring",
  "Music attorney with expertise in contracts and negotiations seeking new clients",
  "Experienced publicist seeking new artists to promote",
  "Digital marketing specialist with focus on music industry seeking new role",
  "Music publisher seeking new songwriters to work with",
  "Experienced music journalist seeking new writing opportunities",
  "Event coordinator with experience in music festivals and concerts",
  "Music licensing expert seeking new projects to work on",
  "Tour accountant with experience in managing finances for major tours",
  "Artist development professional seeking new talent to mentor",
  "Experienced music producer seeking new projects to work on",
  "Music business consultant with expertise in artist branding and marketing",
  "Label operations manager with experience in distribution and logistics",
  "Booking agent with extensive contacts seeking new talent to book",
  "Digital content creator with music industry expertise seeking new role",
  "Music supervisor with experience in film and TV seeking new projects",
  "Artist relations specialist with experience in brand partnerships",
  "Record label administrator with expertise in royalties and licensing",
  "Music education professional with experience in curriculum development",
  "Live sound engineer with experience in major venues and festivals",
  "Merchandise manager with experience in tour merchandising",
  "Experienced music video director seeking new projects to work on",
  "Music industry accountant with expertise in royalties and tax compliance",
  "Talent buyer with experience in booking major tours",
  "Music tech startup seeking experienced product manager",
  "Musician liaison with experience in concert promotion",
  "Music festival producer with experience in booking and logistics",
  "Record label marketing manager seeking new talent to promote",
  "Digital music distribution specialist seeking new opportunities",
  "Music licensing administrator with experience in sync placements",
  "Music journalist with expertise in interviews and profiles",
  "Tour merchandiser with experience in designing and selling merchandise",
  "Music industry analyst with expertise in market research",
  "Radio promoter with experience in major radio networks",
  "Artist booking coordinator with experience in live events",
  "Music app developer seeking experienced software engineer",
  "Music venue manager with experience in operations and logistics",
  "Music industry copywriter with expertise in marketing materials",
  "Concert promoter with experience in booking and marketing",
  "Music industry executive assistant seeking new role",
  "Digital music marketing specialist seeking new opportunities",
  "Music data analyst with expertise in data management and analysis",
  "Talent scout with experience in discovering new artists",
  "Music industry social media manager with expertise in content creation",
  "Music industry graphic designer with experience in branding and merchandising",
  "Music industry recruiter with experience in talent acquisition"
];

const directoryProfessionArray = [
  'Musician',
  'Singer',
  'Songwriter',
  'Composer',
  'Producer',
  'Engineer',
  'Mixing Engineer',
  'Mastering Engineer',
  'Arranger',
  'Conductor',
  'Music Director',
  'Music Therapist',
  'Music Educator',
  'A&R Representative',
  'Booking Agent',
  'Talent Manager',
  'Publicist',
  'Marketing Manager',
  'Music Attorney',
  'Music Journalist',
  'Music Blogger',
  'Radio DJ',
  'Podcast Host',
  'Music Venue Owner',
  'Music Festival Organizer',
  'Sound Designer',
  'Music Supervisor',
  'Film/TV Music Composer',
  'Video Game Music Composer',
  'Music Technology Developer',
  'Music Merchandise Designer'
];

const directoryLocationArray = [
  "Austin, TX",
  "Los Angeles, CA",
  "New York, NY",
  "Nashville, TN",
  "Atlanta, GA",
  "Chicago, IL",
  "San Francisco, CA",
  "Seattle, WA",
  "Miami, FL",
  "Boston, MA",
  "Denver, CO",
  "Portland, OR",
  "Houston, TX",
  "San Diego, CA",
  "Philadelphia, PA",
  "Washington, DC",
  "Dallas, TX",
  "Phoenix, AZ",
  "Minneapolis, MN",
  "Charlotte, NC",
  "Las Vegas, NV",
  "New Orleans, LA",
  "San Antonio, TX",
  "Kansas City, MO",
  "Detroit, MI",
  "Raleigh, NC",
  "Salt Lake City, UT",
  "St. Louis, MO",
  "Pittsburgh, PA",
  "Indianapolis, IN",
  "Cleveland, OH",
  "Columbus, OH",
  "Orlando, FL",
  "Tampa, FL",
  "Baltimore, MD",
  "Austin, TX",
  "Memphis, TN",
  "Asheville, NC",
  "Birmingham, AL",
  "Boise, ID",
  "Charleston, SC",
  "Colorado Springs, CO",
  "Honolulu, HI",
  "Jacksonville, FL",
  "Louisville, KY",
  "Milwaukee, WI",
  "Omaha, NE",
  "Reno, NV",
  "Richmond, VA",
  "Sacramento, CA",
  "Tucson, AZ",
  "Wichita, KS"
];

const directoryEmailArray = [
  "john.doe@example.com",
  "jane.doe@example.com",
  "musicpro123@gmail.com",
  "musicpro456@yahoo.com",
  "johndoe.music@gmail.com",
  "janedoe.music@yahoo.com",
  "musicproducer123@hotmail.com",
  "musicproducer456@outlook.com",
  "johnsongwriter@example.com",
  "janesongwriter@example.com",
  "composer123@gmail.com",
  "composer456@yahoo.com",
  "john.engineer@hotmail.com",
  "jane.engineer@gmail.com",
  "mixingpro123@outlook.com",
  "mixingpro456@yahoo.com",
  "masteringpro123@gmail.com",
  "masteringpro456@hotmail.com",
  "arranger123@example.com",
  "arranger456@yahoo.com",
  "conductormusic@hotmail.com",
  "musicdirector456@gmail.com",
  "musictherapist123@yahoo.com",
  "musiceducator456@gmail.com",
  "bookingagent123@outlook.com",
  "bookingagent456@gmail.com",
  "talentmanager123@yahoo.com",
  "talentmanager456@hotmail.com",
  "musicpublicist123@gmail.com",
  "musicpublicist456@hotmail.com",
  "marketingmanager123@yahoo.com",
  "marketingmanager456@outlook.com",
  "musicattorney123@gmail.com",
  "musicattorney456@hotmail.com",
  "musicjournalist123@yahoo.com",
  "musicjournalist456@gmail.com",
  "musicblogger123@hotmail.com",
  "musicblogger456@yahoo.com",
  "radiodj123@outlook.com",
  "radiodj456@gmail.com",
  "podcasthost123@yahoo.com",
  "podcasthost456@hotmail.com",
  "musicvenueowner123@gmail.com",
  "musicvenueowner456@yahoo.com",
  "festivalfounder123@outlook.com",
  "festivalfounder456@gmail.com",
  "sounddesigner123@yahoo.com",
  "sounddesigner456@hotmail.com",
  "musicsupervisor123@gmail.com",
  "musicsupervisor456@outlook.com"
];

const directoryWebsiteArray = [
  "http://www.johndoemusic.com",
  "http://www.janedoemusic.com",
  "http://www.musicpro123.com",
  "http://www.musicpro456.com",
  "http://www.johndoemusicproducer.com",
  "http://www.janedoemusicproducer.com",
  "http://www.musicproducer123.com",
  "http://www.musicproducer456.com",
  "http://www.johnsongwriter.com",
  "http://www.janesongwriter.com",
  "http://www.composer123.com",
  "http://www.composer456.com",
  "http://www.johnengineer.com",
  "http://www.janeengineer.com",
  "http://www.mixingpro123.com",
  "http://www.mixingpro456.com",
  "http://www.masteringpro123.com",
  "http://www.masteringpro456.com",
  "http://www.arranger123.com",
  "http://www.arranger456.com",
  "http://www.conductormusic.com",
  "http://www.musicdirector456.com",
  "http://www.musictherapist123.com",
  "http://www.musiceducator456.com",
  "http://www.bookingagent123.com",
  "http://www.bookingagent456.com",
  "http://www.talentmanager123.com",
  "http://www.talentmanager456.com",
  "http://www.musicpublicist123.com",
  "http://www.musicpublicist456.com",
  "http://www.marketingmanager123.com",
  "http://www.marketingmanager456.com",
  "http://www.musicattorney123.com",
  "http://www.musicattorney456.com",
  "http://www.musicjournalist123.com",
  "http://www.musicjournalist456.com",
  "http://www.musicblogger123.com",
  "http://www.musicblogger456.com",
  "http://www.radiodj123.com",
  "http://www.radiodj456.com",
  "http://www.podcasthost123.com",
  "http://www.podcasthost456.com",
  "http://www.musicvenueowner123.com",
  "http://www.musicvenueowner456.com",
  "http://www.festivalfounder123.com",
  "http://www.festivalfounder456.com",
  "http://www.sounddesigner123.com",
  "http://www.sounddesigner456.com",
  "http://www.musicsupervisor123.com",
  "http://www.musicsupervisor456.com"
];

const sampleSong = {
  id: 4,
  url: "https://s3.amazonaws.com/atmosphere-development/Timecop1983+-+One+Night+(feat.+Josh+Dally).mp3",
  name: "One Night (feat. Josh Dally)",
  created_at: "2023-04-03T01:38:08.656Z",
  updated_at: "2023-04-03T01:38:08.656Z",
  album_id: 2,
  s3_key: null,
  rank: null,
  lyrics: null,
  genre_id: 1,
  artist_profile_id: 3,
  genre: {
    id: 1,
    name: "Jazz",
    created_at: "2023-04-03T01:38:08.418Z",
    updated_at: "2023-04-03T01:38:08.418Z"
  },
  artist_profile: {
    id: 3,
    facebook: null,
    twitter: null,
    name: null,
    origin: null,
    decades: null,
    bio: null,
    label: null,
    created_at: "2023-04-03T01:37:22.811Z",
    updated_at: "2023-04-03T01:37:22.811Z",
    artist_id: null,
    user: {
      id: 6,
      name: "Timecop1983",
      email: "timecop1983@email.com",
      encrypted_password: "",
      reset_password_token: null,
      reset_password_sent_at: null,
      remember_created_at: null,
      sign_in_count: 0,
      current_sign_in_at: null,
      last_sign_in_at: null,
      current_sign_in_ip: null,
      last_sign_in_ip: null,
      created_at: "2023-04-03T01:37:22.811Z",
      updated_at: "2023-04-03T01:37:22.811Z",
      role: null,
      profile_id: 3,
      profile_type: "ArtistProfile",
      is_artist: true,
      is_fan: false,
      profile_completed: false,
      address: null,
      phone: null,
      postal_code: null,
      country: null,
      city: null,
      state: null,
      gender: null,
      age: null,
      customer_id: null,
      is_paying: false,
      points: null
    }
  },
  album: {
    id: 2,
    name: "One Night (single)",
    artist_profile_id: 3,
    release_date: 2020,
    image: {
      url: "default_pic_blue.jpg",
      thumb: {
        url: "default_pic_blue.jpg"
      }
    },
    length: 123,
    created_at: "2023-04-03T01:38:08.652Z",
    updated_at: "2023-04-03T01:38:08.652Z",
    genre_id: 1
  }
};

const contestTitleArray = [
  "Battle of the Bands",
  "Rising Stars",
  "The Next Big Thing",
  "Cover Song Challenge",
  "Original Song Contest",
  "Beat Making Competition",
  "Acoustic Showdown",
  "Rock Out",
  "Pop Sensations",
  "Country Music Challenge",
  "Rap Battle Royale",
  "DJ Spin-Off",
  "Soulful Serenade",
  "Blues & Jazz Jam",
  "Classical Competition",
  "Folk & Indie Fest",
  "Guitar Hero Showdown",
  "Piano Mania",
  "Sing Your Heart Out",
  "Music Trivia Challenge",
  "Instrumental Genius",
  "Songwriting Slam",
  "Electronic Dance-Off",
  "Karaoke Championship",
  "Band Name Battle",
  "Music Video Contest",
  "Concert Photography Competition",
  "Music Journalism Prize",
  "Live Sound Engineer Challenge",
  "Stage Design Competition",
  "Music Business Plan Competition",
  "Crowd Favorite Award",
  "Fan Art Competition",
  "Merchandise Design Contest",
  "Music App Development Contest",
  "Podcast Contest",
  "Music Education Grant",
  "Music Therapy Challenge",
  "Community Music Initiative",
  "Musical Instrument Drive",
  "Music Festival Startup Contest",
  "Beyoncé's Beat Battle",
  "Taylor Swift's Songwriting Challenge",
  "Drake's Freestyle Face-Off",
  "Ariana Grande's High Note Competition",
  "Ed Sheeran's Guitar Greatness",
  "Rihanna's Vocal Virtuoso Contest",
  "Kanye West's Beat Making Battle",
  "Lady Gaga's Piano Performance Showdown",
  "Jay-Z's Rap Royalty Challenge",
  "Katy Perry's Pop Sensation Search",
  "Bruno Mars' Dance-Off",
  "Lizzo's Flute Frenzy",
  "Justin Bieber's Vocal Showdown",
  "Post Malone's Music Video Contest",
  "Dua Lipa's Disco Dance-Off",
  "Cardi B's Rhyme Challenge",
  "The Weeknd's Production Prize",
  "Harry Styles' Song Cover Contest",
  "Billie Eilish's Creative Challenge",
  "Shawn Mendes' Acoustic Showdown",
  "Megan Thee Stallion's Flow Frenzy",
  "J. Cole's Lyricism Challenge",
  "Doja Cat's Music Video Competition",
  "H.E.R.'s Guitar Greatness Challenge",
  "DaBaby's Beat Battle",
  "Adele's Vocal Competition",
  "Lil Nas X's Music Video Showdown",
  "SZA's Songwriting Prize",
  "Bad Bunny's Reggaeton Rumble",
  "Miley Cyrus' Cover Song Contest",
  "Travis Scott's Production Prize",
  "Lana Del Rey's Song Cover Showdown",
  "Alicia Keys' Piano Performance Challenge",
  "Chance The Rapper's Lyricism Competition",
  "Bebe Rexha's Songwriting Showdown",
  "The Kid LAROI's Vocal Showdown",
  "Olivia Rodrigo's Songwriting Battle",
  "Troye Sivan's Music Video Challenge",
  "Doja Cat's Disco Dance-Off"
];

const contestDescriptionArray = [
  "Battle of the Bands: A competition where bands face off to see who can rock the hardest.",
  "Rising Stars: A contest for up-and-coming musicians to showcase their talent and potentially launch their career.",
  "The Next Big Thing: A competition to find the next breakout music artist.",
  "Cover Song Challenge: A contest where musicians cover popular songs and compete for the best rendition.",
  "Original Song Contest: A competition for songwriters to showcase their original material.",
  "Beat Making Competition: A contest for beat makers to showcase their skills and compete for the best beat.",
  "Acoustic Showdown: A competition for acoustic musicians to showcase their talent and compete for the best performance.",
  "Rock Out: A competition for rock bands to showcase their talent and compete for the best performance.",
  "Pop Sensations: A contest to find the next big pop star.",
  "Country Music Challenge: A competition for country musicians to showcase their talent and compete for the best performance.",
  "Rap Battle Royale: A competition for rappers to showcase their skills and compete for the best performance.",
  "DJ Spin-Off: A contest for DJs to showcase their skills and compete for the best mix.",
  "Soulful Serenade: A competition for soulful musicians to showcase their talent and compete for the best performance.",
  "Blues & Jazz Jam: A contest for blues and jazz musicians to showcase their skills and compete for the best performance.",
  "Classical Competition: A competition for classical musicians to showcase their talent and compete for the best performance.",
  "Folk & Indie Fest: A contest for folk and indie musicians to showcase their talent and compete for the best performance.",
  "Guitar Hero Showdown: A competition for guitar players to showcase their skills and compete for the best performance.",
  "Piano Mania: A contest for pianists to showcase their skills and compete for the best performance.",
  "Sing Your Heart Out: A competition for singers to showcase their talent and compete for the best performance.",
  "Music Trivia Challenge: A contest for music buffs to showcase their knowledge and compete for the top prize.",
  "Instrumental Genius: A competition for instrumental musicians to showcase their talent and compete for the best performance.",
  "Songwriting Slam: A contest for songwriters to showcase their skills and compete for the best original song.",
  "Electronic Dance-Off: A competition for electronic musicians to showcase their talent and compete for the best performance.",
  "Karaoke Championship: A contest for karaoke enthusiasts to showcase their skills and compete for the top prize.",
  "Band Name Battle: A competition for bands to come up with the best and most creative band name.",
  "Music Video Contest: A contest for music video directors to showcase their talent and compete for the best music video.",
  "Concert Photography Competition: A competition for concert photographers to showcase their talent and compete for the best concert photo.",
  "Music Journalism Prize: A contest for music journalists to showcase their writing skills and compete for the top prize.",
  "Live Sound Engineer Challenge: A competition for sound engineers to showcase their talent and compete for the best live sound mix.",
  "Stage Design Competition: A contest for stage designers to showcase their creativity and compete for the best stage design.",
  "Music Business Plan Competition: A competition for music entrepreneurs to showcase their business plans and compete for the top prize.",
  "Crowd Favorite Award: A contest for musicians to showcase their talent and compete for the audience's favorite performer.",
  "Fan Art Competition: A competition for fans to showcase their artistic skills and compete for the best music-related artwork.",
];

const contestPrizesArray = [
  "Recording session at a professional studio",
  "Music video production by a renowned director",
  "Performance slot at a major music festival",
  "Mentorship sessions with a successful music artist",
  "Signed record deal with a major label",
  "Feature in a prominent music publication",
  "Cash prize",
  "Musical instrument of choice",
  "Trip to a music-related destination",
  "Professional photo shoot",
  "VIP concert tickets",
  "Custom merchandising package",
  "Social media promotion by a major music brand",
  "Collaboration with a popular music artist",
  "Studio time with a famous producer",
  "Interview with a prominent music journalist",
  "Professional music equipment",
  "Songwriting and production software",
  "Brand new music gear",
  "Access to exclusive industry events",
  "Featured playlist placement on a popular streaming platform",
  "Music marketing and promotion package",
  "Exclusive fan meet-and-greet experience",
  "Awards and recognition for winners and finalists",
  "Mentorship sessions with music industry executives",
  "Custom-designed album artwork",
  "Professional music video editing and post-production services",
  "Custom-made music merchandise",
  "Brand partnership and sponsorship opportunities",
  "Music licensing and sync placement opportunities",
  "Consultation sessions with music attorneys and agents",
  "Personalized feedback and critique from music experts",
  "Digital distribution and publishing services",
  "Online music lessons with a famous music teacher",
  "Paid live performance opportunities",
  "Free music streaming and download subscriptions",
  "Recording equipment rental",
  "Music production and mixing services",
  "Opportunities for radio and TV appearances"
];

const contestSubmissionsArray = [
  "A heartfelt ballad about love and loss.",
  "An upbeat pop song with catchy hooks and a danceable beat.",
  "A soulful R&B track that showcases powerful vocals.",
  "A hauntingly beautiful acoustic guitar instrumental.",
  "A high-energy rock anthem with electrifying guitar riffs.",
  "An experimental electronic track that pushes boundaries.",
  "A romantic duet about two lovers separated by distance.",
  "A touching tribute to a loved one who has passed away.",
  "A thought-provoking hip-hop track with socially conscious lyrics.",
  "A cinematic orchestral piece that builds to a dramatic crescendo.",
  "A stripped-down, raw and emotional performance on piano and vocals.",
  "A Latin-inspired dance track with infectious rhythms.",
  "A hauntingly beautiful lullaby that soothes the soul.",
  "A dynamic and explosive fusion of jazz and funk.",
  "A soulful, bluesy track that oozes with emotion.",
  "A nostalgic trip down memory lane with a catchy melody.",
  "A powerful and uplifting gospel choir performance.",
  "A dreamy, atmospheric soundscape that transports the listener.",
  "A soulful, acoustic folk ballad that tugs at the heartstrings.",
  "A powerful protest song that speaks truth to power.",
  "A dark and brooding industrial rock track with heavy guitars.",
  "A playful and fun children's song with silly lyrics.",
  "A touching tribute to a parent who has passed away.",
  "A catchy, feel-good pop track with a positive message.",
  "An ambient and ethereal instrumental that soothes the soul.",
  "A jazzy, laid-back track perfect for lazy afternoons.",
  "A driving, energetic EDM banger that gets the party started.",
  "A wistful and melancholy ballad about unrequited love.",
  "A funky and groovy track with infectious basslines.",
  "A soaring power ballad with epic guitars and vocals.",
  "A lighthearted country-pop tune with sing-along chorus.",
  "An atmospheric and moody trip-hop track with haunting vocals.",
  "A bright and cheerful indie-pop track with jangly guitars.",
  "A sultry and seductive R&B slow jam.",
  "A funky, brass-heavy instrumental with a retro vibe.",
  "A powerful and anthemic rock ballad about standing up for yourself.",
  "A melodic and upbeat acoustic pop song about new beginnings.",
  "A funky and groovy jazz fusion track with virtuosic solos.",
  "A dark and menacing hip-hop track with eerie production.",
  "A dynamic and explosive metalcore track with crushing breakdowns.",
  "A smooth and mellow jazz standard performed with soulful finesse.",
  "A cinematic music video with stunning visuals and a gripping storyline.",
  "A colorful and vibrant video with lively choreography and catchy music.",
  "A heartwarming video that captures the beauty of human connection and love.",
  "A visually stunning video that takes the viewer on an emotional journey.",
  "A high-energy dance video with impressive choreography and a killer beat.",
  "A beautifully shot video that showcases the beauty of nature and the world around us.",
  "A creative and unique video that pushes the boundaries of what's possible.",
  "A visually striking video that captures the essence of the song and its message.",
  "A captivating video that tells a story through powerful imagery and symbolism.",
  "A fun and lighthearted video that's sure to make you smile and dance along.",
  "An emotional and powerful video that speaks to the heart and soul.",
  "A stunningly beautiful video that captures the magic and wonder of the world.",
  "A thought-provoking video that challenges the viewer to think deeper and feel more.",
  "A visually stunning and captivating video that's impossible to look away from.",
  "A dynamic and explosive video that's full of energy and excitement.",
  "A breathtakingly beautiful video that transports the viewer to another world.",
  "A fun and upbeat video with infectious energy and catchy hooks.",
  "A visually stunning and artistic video that's a true work of art.",
  "A powerful and moving video that's sure to leave a lasting impression.",
  "A visually stunning video that captures the essence of the song and its meaning.",
  "A creative and imaginative video that's full of surprises and unexpected twists.",
  "A beautifully shot and edited video that's a pleasure to watch.",
  "An emotional and heartfelt video that tugs at the heartstrings.",
  "A visually stunning video that showcases the beauty and power of dance.",
  "A fun and playful video with an infectious sense of humor.",
  "A powerful and thought-provoking video that inspires and motivates.",
  "A visually stunning and immersive video that's like nothing you've ever seen before.",
  "A beautiful and touching video that captures the essence of the song's message.",
  "A fun and upbeat video with catchy hooks and infectious energy.",
  "A visually stunning video with breathtaking scenery and landscapes.",
  "A creative and innovative video that's full of surprises and unexpected moments.",
  "A powerful and moving video that's sure to leave a lasting impression.",
  "A visually stunning and artistic video that's a true masterpiece.",
  "A fun and lighthearted video that's impossible not to smile and dance along to.",
  "A beautiful and heartwarming video that captures the magic of love and connection.",
  "A visually stunning video that transports the viewer to another time and place.",
  "A creative and imaginative video that pushes the boundaries of what's possible.",
  "A powerful and emotional video that speaks to the heart and soul.",
  "A visually stunning video that's like a work of art in motion.",
  "A fun and energetic video with infectious energy and a catchy beat.",
  "A beautiful and touching video that captures the essence of the song's message and meaning."

];

const contestSubmissionCommentsArray = [
  "Great job! Your music is really impressive.",
  "I love your unique sound and style.",
  "This is fantastic! Keep up the good work.",
  "You have a real talent for music.",
  "Wow, this is amazing! You deserve to win.",
  "I can't stop listening to this. So good!",
  "Your music is really catchy and memorable.",
  "I'm blown away by your talent. Incredible!",
  "This is definitely one of the best submissions I've heard.",
  "You've got a bright future ahead of you in music.",
  "I can tell you put a lot of heart and soul into this.",
  "Your music is inspiring. Thank you for sharing it.",
  "This is so beautiful. You have a gift.",
  "I'm loving the vibes of this song. Great work!",
  "You have a real gift for writing and composing music.",
  "This is top-notch quality. Keep up the good work.",
  "I'm impressed by your skills and talent.",
  "This song has been stuck in my head all day. Love it!",
  "You've got a real talent for producing music.",
  "This is exactly the kind of music I've been looking for.",
  "Your music is so soulful and heartfelt. Amazing!",
  "I can't wait to hear more from you. Keep it up!",
  "Your music is truly moving. Keep doing what you're doing.",
  "I'm blown away by the emotion in this song. Beautiful.",
  "This is a masterpiece. Well done!",
  "You have a real ear for music. Keep honing your skills.",
  "I can't believe how talented you are. Keep going!",
  "Your music has the power to uplift and inspire. Thank you.",
  "This is truly exceptional. You're destined for greatness.",
  "I'm really impressed by the depth of this song.",
  "You have a unique voice and style. Love it!",
  "This is so good, I'm adding it to my playlist.",
  "I'm really digging the beat and rhythm of this song.",
  "Your music is so soulful and authentic. Love it!",
  "This is truly a work of art. Thank you for sharing it.",
  "I'm blown away by your vocal range and talent.",
  "You have a gift for creating memorable melodies.",
  "This is definitely one of the best submissions I've heard so far.",
  "I love the energy and passion in this song.",
  "You've got a real knack for songwriting. Keep it up!",
  "This song is pure magic. Love it!",
  "Your music has a way of touching people's hearts. Amazing!",
  "I'm really impressed by your musicality and skill.",
  "A bold and eye-catching design that demands attention.",
  "A playful and whimsical design that's sure to make people smile.",
  "A minimalist design that's both stylish and versatile.",
  "A retro-inspired design that's both nostalgic and modern.",
  "A vibrant and colorful design that's perfect for summer.",
  "A geometric design that's both striking and contemporary.",
  "A hand-drawn design that captures the beauty of imperfection.",
  "A typographic design that makes a bold statement with words.",
  "A nature-inspired design that celebrates the beauty of the outdoors.",
  "A pop art-inspired design that's fun and playful.",
  "A sports-inspired design that's perfect for athletes and fans alike.",
  "A music-inspired design that's perfect for music lovers.",
  "A food-inspired design that's both fun and delicious.",
  "A graphic design that's both bold and timeless.",
  "A space-themed design that's out of this world.",
  "A vintage-inspired design that's both classic and cool.",
  "A streetwear-inspired design that's perfect for urban fashion.",
  "A cultural-inspired design that celebrates diversity and heritage.",
  "A comic book-inspired design that's fun and nostalgic.",
  "A science-inspired design that's both educational and cool.",
  "A whimsical design that's perfect for kids and kids at heart.",
  "A feminist-inspired design that's empowering and bold.",
  "A technology-inspired design that's futuristic and innovative.",
  "A travel-inspired design that celebrates adventure and exploration.",
  "A seasonal design that's perfect for the holidays.",
  "A superhero-inspired design that's fun and powerful.",
  "A punk rock-inspired design that's rebellious and cool.",
  "A hand-lettered design that's both personal and unique.",
  "A tattoo-inspired design that's edgy and bold.",
  "A floral design that's both elegant and feminine.",
  "A vintage sports-inspired design that's perfect for retro enthusiasts.",
  "A motivational design that inspires and uplifts.",
  "A fantasy-inspired design that's magical and enchanting.",
  "A animal-inspired design that celebrates the beauty of nature.",
  "A technology-inspired design that's futuristic and cutting-edge.",
  "A psychedelic design that's trippy and mind-bending.",
  "A socially-conscious design that raises awareness for a cause.",
  "A movie-inspired design that's fun and nostalgic.",
  "A beach-inspired design that's perfect for summer fun.",
  "A horror-inspired design that's creepy and cool."
];

const chatRoomNameArray = [
  "The Lounge",
  "Tech Talk",
  "Movie Mania",
  "Fitness Friends",
  "Book Club",
  "Travel Tales",
  "Game Zone",
  "Music Lovers",
  "Art Appreciation",
  "Foodies Unite",
  "Politics & Current Events",
  "Nature Nuts",
  "Pet Pals",
  "Fashion Frenzy",
  "DIY & Crafts",
  "Language Exchange",
  "Study Buddies",
  "Job Seekers",
  "Entrepreneurship",
  "Sports Fans",
  "Photography",
  "Science & Technology",
  "Writing Workshop",
  "Creative Corner",
  "Health & Wellness",
  "Gardening Group",
  "Investment Club",
  "Marketing Magic",
  "Gaming Guild",
  "Theater Talk",
  "Hiking Club",
  "Environmentalists",
  "Spiritual Seekers",
  "Charity Chat",
  "Moms & Dads",
  "Volunteer Village",
  "Lizard Squad",
  "Music Makers",
  "Film Fanatics",
  "Tea Time",
  "Cooking Club",
  "History Buffs",
  "Comic Convo",
  "Anime Addicts",
  "Sci-Fi Squad",
  "Fantasy Fans",
  "Mystery Mania",
  "Romance Readers",
  "Horror House",
  "Action Adventure",
  "Classical Connection"
];

const artistProductsArray = [
  "Concert T-Shirt",
  "Limited Edition Vinyl Record",
  "Artist Signature Guitar Pick",
  "Tour Poster",
  "VIP Backstage Pass",
  "Band Logo Hoodie",
  "Album Art Mousepad",
  "Autographed Drumsticks",
  "Collectible Band Pin",
  "Tour DVD Box Set",
  "Embroidered Patch Set",
  "Exclusive Artist Beanie",
  "Band Logo Backpack",
  "Album Cover Art Puzzle",
  "Tour Ticket Stub Frame",
  "Musician Biography Book",
  "Limited Edition Cassette Tape",
  "Artist Logo Keychain",
  "Band Sticker Pack",
  "Concert Photo Book",
  "Vintage Band Poster",
  "Guitar Strap with Artist Logo",
  "Custom Band Drum Head",
  "Artist Logo Phone Case",
  "Official Fan Club Membership",
  "Limited Edition Artist Print",
  "Artist Signature Microphone",
  "Concert Ticket Lanyard",
  "Artist Logo Water Bottle",
  "Band Logo Enamel Mug",
  "Tour Photo Calendar",
  "VIP Meet and Greet Package",
  "Collectible Guitar Wall Clock",
  "Exclusive Artist Socks",
  "Tour Playlist Vinyl Sticker",
  "Band Logo Guitar Stand",
  "Limited Edition Lyric Book",
  "Artist Signature Sunglasses",
  "Concert Poster Puzzle",
  "Band Logo Plectrum Holder",
  "Tour Drum Kit Miniature",
  "Artist Logo Wristband",
  "Official Fan Club Tote Bag",
  "Limited Edition Bandana",
  "Concert Ticket Keepsake Box",
  "Artist Logo Wall Decal",
  "Vintage Band Magazine",
  "Tour Map Bandana",
  "Guitar Pick Necklace",
  "Artist Signature Hat",
  "VIP Access Badge"
];

const labelNameArray = [
  "SonicWave Records",
  "EchoGroove Entertainment",
  "VinylVibe Productions",
  "AuroraSound Records",
  "MelodyMakers Collective",
  "Starstruck Records",
  "NebulaTunes",
  "HarmonyHarbor Music",
  "MysticMelodies",
  "VividVoice Records",
  "LunarBeats Music",
  "RhythmRoots Studios",
  "SoundSpectrum Records",
  "CelestialSoundwave",
  "DreamyNotes Productions",
  "GrooveGarden Records",
  "EclipseEcho Music",
  "PulsePioneer Studios",
  "SerendipitySounds",
  "OrbitSound Collective",
  "SilverScreen Records",
  "EternalGroove Productions",
  "CosmicCrescendo",
  "MelancholyMelodies",
  "PixelPulse Music",
  "SolsticeSonics",
  "NovaNote Records",
  "ZenithZone Entertainment",
  "AstralAcoustics",
  "Echoes of Elysium",
  "VelvetVibrations",
  "InfiniteHarmony Records",
  "GravityGrooves",
  "SoundSculptor Studios",
  "NebulousNocturne Music",
  "RadiantRhythms",
  "VortexVibes",
  "EnigmaEcho Records",
  "SymphonySpectrum",
  "EuphoriaEntertainment",
  "ArcaneAudio Collective",
  "CrescendoCove Records",
  "LuminousLyricist",
  "TimelessTunes Productions",
  "SolarSoundwaves",
  "StellarSerenade",
  "FusionFrontier Music",
  "AetherAudioworks",
  "VibrantVerse Records",
  "Atmosphere"
];

export default {
  artistImageArray,
  highResImageArray,
  friendNameArray,
  artistNameArray,
  playlistNameArray,
  songTitleArray,
  broadcastContentArray,
  albumNameArray,
  genresArray,
  songCommentsArray,
  timesHHMMAMPMArray,
  venueNameArray,
  cityStateArray,
  artistMerchArray,
  abbreviatedStatesArray,
  areaChartSeries,
  adHeadlineArray,
  adDescriptionArray,
  genreImageArray,
  photoCaptionArray,
  photoCommentsArray,
  eventTitleArray,
  eventDescriptionArray,
  opsPageTitleArray,
  opsPageDescriptionArray,
  opsPageDonationDescriptionArray,
  directoryAdCopyArray,
  directoryProfessionArray,
  directoryLocationArray,
  directoryEmailArray,
  directoryWebsiteArray,
  sampleSong,
  contestTitleArray,
  contestDescriptionArray,
  contestPrizesArray,
  contestSubmissionsArray,
  contestSubmissionCommentsArray,
  chatRoomNameArray,
  artistProductsArray,
  labelNameArray,
};