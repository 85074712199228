import Axios from 'axios';

export function callSongReferencesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/song_references`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongReferencesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/song_references`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongReferencesDelete(songReferenceId, queryParams = {}) {
    return Axios.delete(`/api/v1/song_references/${songReferenceId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
