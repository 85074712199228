import Axios from 'axios';

export function callUserMusicProfilesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/user_music_profiles`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserMusicProfilesShow(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/user_music_profile`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}