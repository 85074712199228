<template>
    <div class="info-container__button-wrap">
        <div v-if="isMyProfile" class="button-wrap__current-user">
            <router-link class="profile-button"
                :to="{ name: 'user_profiles.edit', params: { userProfileId: userProfileId } }">
                <img class="profile-button__image edit" src="@/assets/images/icons/profile/edit.png" />
                Edit Profile
            </router-link>
        </div>
        <div v-else class="button-wrap__other-user">
            <button class="profile-button" v-if="friendRequestPending" data-cy="cancel-friend-request"
                @click="handleCancelPendingFriendRequest">
                <img class="profile-button__image unfriend" src="@/assets/images/icons/profile/delete.png" />
                Cancel Request
            </button>
            <button class="profile-button" v-else-if="canAcceptFriendRequest"
                @click="handleAcceptFriendRequest">
                <img class="profile-button__image add" src="@/assets/images/icons/profile/add_friend.png" />
                Accept Friend Request
            </button>
            <button class="profile-button"
                v-else-if="currentUserCanFriendRequest && currentUser.profile_type === 'UserProfile'"
                data-cy="send-friend-request" @click="handleSendFriendRequest">
                <img class="profile-button__image add" src="@/assets/images/icons/profile/add_friend.png" />
                Add Friend
            </button>
            <button class="profile-button" v-else-if="isFriend && currentUser.profile_type === 'UserProfile'"
                @click="handleUnfriendUser">
                <img class="profile-button__image unfriend" src="@/assets/images/icons/profile/delete.png" />
                Unfriend
            </button>
            <button class="profile-button"
                v-if="(currentUser.profile_type === 'ArtistProfile' || currentUser.profile_type === 'LabelProfile') && !currentUserIsFollowing"
                @click="handleFollowUser">
                <img class="profile-button__image add" src="@/assets/images/icons/profile/follow.png" />
                Follow
            </button>
            <button class="profile-button"
                v-if="(currentUser.profile_type === 'ArtistProfile' || currentUser.profile_type === 'LabelProfile') && currentUserIsFollowing"
                @click="handleUnfollowUser">
                <img class="profile-button__image unfriend" src="@/assets/images/icons/profile/delete.png" />
                Unfollow
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        user: Object
    },
    computed: {
        ...mapGetters('userFriendships', ['getUserFriendships']),
        ...mapGetters('userRelationships', ['getCurrentUserIsFollowing']),
        currentUser() {
            return this.$store.state.user;
        },
        isMyProfile() {
            return this.$store.state.currentUserId == this.user.id;
        },
        userProfileId() {
            return Number(this.$route.params.userProfileId);
        },
        currentFriendship() {
            if (!this.user.id || !this.$store.state.currentUserId) {
                return undefined;
            }

            return this.getUserFriendships.find(friendship => 
                friendship.friend_id == this.user.id && friendship.user_id == this.$store.state.currentUserId
            );
        },
        currentUserCanFriendRequest() {
            return !this.isMyProfile && !this.currentFriendship;
        },
        friendRequestPending() {
            return this.currentFriendship && this.currentFriendship.state === 'requested';
        },
        canAcceptFriendRequest() {
            return this.getUserFriendships.some(friendship => 
                friendship.friend_id == this.user.id && friendship.state == 'pending'
            );
        },
        isFriend() {
            return this.getUserFriendships.some(friendship => 
                friendship.friend_id == this.user.id && friendship.state == 'accepted'
            );
        },
        currentUserIsFollowing() {
            return this.getCurrentUserIsFollowing;
        }
    },
    methods: {
        ...mapActions('userFriendships', ['sendFriendRequest', 'cancelPendingFriendRequest', 'unfriendUser', 'acceptFriendRequest']),
        ...mapActions('userRelationships', ['followUser', 'unfollowUser']),
        async handleSendFriendRequest() {
            try {
                await this.sendFriendRequest(this.user.id);
                this.$notify({ group: 'vue-app', title: 'Friend request sent!' });
            } catch (error) {
                console.error(error);
                this.$notify({ type: 'error', group: 'vue-app', title: 'Error sending friend request' });
            }
        },
        async handleCancelPendingFriendRequest() {
            try {
                await this.cancelPendingFriendRequest(this.currentFriendship.id);
                this.$notify({ type: 'warn', group: 'vue-app', title: 'Friend request canceled' });
            } catch (error) {
                console.error(error);
                this.$notify({ type: 'error', group: 'vue-app', title: 'Error canceling friend request' });
            }
        },
        async handleUnfriendUser() {
            try {
                await this.unfriendUser(this.currentFriendship.id);
                this.$notify({ type: 'warn', group: 'vue-app', title: 'Unfriended' });
            } catch (error) {
                console.error(error);
                this.$notify({ type: 'error', group: 'vue-app', title: 'Error unfriending user' });
            }
        },
        async handleFollowUser() {
            try {
                await this.followUser(this.user.id);
                this.$notify({ group: 'vue-app', title: 'Followed user!' });
            } catch (error) {
                console.error(error);
                this.$notify({ type: 'error', group: 'vue-app', title: 'Error following user' });
            }
        },
        async handleUnfollowUser() {
            try {
                await this.unfollowUser(this.user.id);
                this.$notify({ type: 'warn', group: 'vue-app', title: 'Unfollowed' });
            } catch (error) {
                console.error(error);
                this.$notify({ type: 'error', group: 'vue-app', title: 'Error unfollowing user' });
            }
        },
        async handleAcceptFriendRequest() {
            try {
                await this.acceptFriendRequest(this.currentFriendship.id);
                this.$notify({ group: 'vue-app', title: 'Friend request accepted!' });
            } catch (error) {
                console.error("Error accepting friend request:", error);
                this.$notify({ type: 'error', group: 'vue-app', title: 'Error accepting friend request' });
            }
        }
    }
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.info-container__button-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .profile-button {
        background: $atmo-purple--medium-dark;
        padding: .5rem 1.4rem .5rem 1.4rem;
        color: white;
        font-size: .8rem;
        font-weight: 600;
        border-radius: 30px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .profile-button__image {
        width: auto;
        margin-right: .5rem;

        &.edit {
            height: 1rem;
        }

        &.add {
            height: .7rem;
        }

        &.unfriend {
            height: .7rem;
        }
    }
}
</style>
