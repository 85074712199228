<template>
  <ArtistSubscriptionList />
</template>

<script>
import { mapState } from 'vuex';
import ArtistSubscriptionList from '@/components/profiles/artist-subscription-list';

export default {
  components: { ArtistSubscriptionList },
  computed: {
    ...mapState('profiles', ['user']),
  }
};
</script>
