<template>
  <div class="my-purchases">
    <h2 class="atmo-page-header my-purchases__header">
      ATMO Purchases
    </h2>
    <section class="my-purchases__body">
      <div class="my-purchases__main-content">
        <table class="atmo-table">
          <thead class="atmo-table__thead purchases">
            <tr>
              <th class="atmo-table__th">
                Order no.
              </th>
              <th class="atmo-table__th">
                Date
              </th>
              <th class="atmo-table__th">
                Points
              </th>
              <th class="atmo-table__th">
                Total amount
              </th>
              <th class="atmo-table__th">
                More
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="purchase in purchases"
              :key="purchase.id"
              class="atmo-table__tr--purchases"
              component-type="router-link"
              :to="{ name: 'purchaseinfo', params: { userId: $route.params.userProfileId, purchaseId: purchase.id }}"
            >
              <td class="atmo-table__td">
                {{ purchase.id }}
              </td>
              <td class="atmo-table__td">
                {{ formatdate(purchase.created_at) }}
              </td>
              <td class="atmo-table__td">
                {{ purchase.points }}
              </td>
              <td class="atmo-table__td">
                {{ purchase.amount | currency }}
              </td>
              <td class="atmo-table__td">
                <router-link
                  :to="`purchases/${purchase.id}`"
                  class="atmo-button atmo-button--primary"
                  tag="button"
                >
                  See more
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <template v-if="purchases.length == 0">
      <p>No purchases at this time.</p>
    </template>
  </div>
</template>

<script>
  import Axios from "axios";
  import Moment from "moment";

  export default {
    data: function() {
      return {
        purchases: [],
        error:false
      };
    },
    created() {
      Axios.get(
        "/api/v1/users/" + this.$route.params.userProfileId + "/transactions"
      )
        .then(response => {
          this.purchases = response.data;
        })
        // handle error if there is one, show that for some reason we couldn't load the data
        .catch(error => {
          console.log(error);
          this.error = true;
        })
    },
    methods: {
      formatdate: function(value) {
        return Moment(value).format("ddd, MMM DD. h:mm a");
      }
    }
  };
</script>
<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.my-purchases {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  &__header {
    margin: 35px 0;
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .atmo-table__th {
    text-align: center;
  }

  &__controls {
    display: flex;
    align-items: center;
  }
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  &__main-content {
    min-height: 300px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>

