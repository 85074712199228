<template>
    <div class="atmo-products-new">
        <h2 class="atmo-page-header">
            New Product
        </h2>
        <div class="atmo-products-new__container">
            <div class="back-container" @click="goBack()">
                <div class="back-container__icon-container">
                    <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
                </div>
                <div class="back-container__text-container">Back to previous</div>
            </div>

            <div class="container__container-wrap">
                <atmo-loading v-if="isLoading" />
                <form v-else @submit.prevent="storeProductInformation" class="container-wrap__form">
                    <div class="form__product-details-wrap">
                        <div class="product-details-wrap__product-details">
                            <div class="field-wrap">
                                <label for="productTitle">Title</label>
                                <input class="text-field" id="productTitle" v-model="newProductTitle"
                                    :aria-invalid="submitAttempted && !newProductTitle" type="text"
                                    placeholder="Atmo Shirt" data-cy="product-title" />
                            </div>
                            <div class="field-wrap">
                                <label for="productDescription">Description</label>
                                <textarea class="text-field" id="productDescription" v-model="newProductDescription"
                                    rows="4" no-resize :aria-invalid="submitAttempted && !newProductDescription"
                                    placeholder="If you're trying to get album sales, rock the Atmo A. It's science."
                                    data-cy="product-description"></textarea>
                            </div>
                            <div class="flex-columns">
                                <div class="field-wrap">
                                    <label for="productPrice">Price ($USD)</label>
                                    <input class="text-field" id="productPrice" v-model="newProductPrice"
                                        :aria-invalid="submitAttempted && !newProductPrice" type="text"
                                        placeholder="20" />
                                </div>
                                <div class="field-wrap">
                                    <label for="country">Category</label>
                                    <select v-model="newProductCategory" class="dropdown-group" id="category"
                                        name="category" @change="filterSizeSystems">
                                        <option v-for="(category, index) in categories" :key="index"
                                            :value="category.id">
                                            {{ category.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="field-wrap">
                                    <label for="country">Size System</label>
                                    <select v-model="newProductSizeSystem" class="dropdown-group" id="sizeSystem"
                                        name="sizeSystem">
                                        <option v-for="(sizeSystem, index)  in filteredSizeSystems" :key="index"
                                            :value="sizeSystem">
                                            {{ sizeSystem.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="available-colors-container">
                                <label for="color">Available Colors</label>
                                <perfect-scrollbar class="available-colors-scroll">
                                    <div class="field-wrap">
                                        <fieldset class="checkbox-group" id="color">
                                            <div v-for="(color, index) in colors" :key="index"
                                                class="checkbox-field-wrap">
                                                <input class="checkbox-field" type="checkbox" :id="'color_' + index"
                                                    :value="color" v-model="selectedColors">
                                                <label class="checkbox-field-label" :for="'color_' + index">
                                                    {{ color.name }}
                                                </label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </perfect-scrollbar>
                                <!-- <p>Selected Colors: {{ selectedColors }}</p> -->
                            </div>
                            <div class="shipping-info-heading">
                                <p>Shipping Information</p>
                            </div>
                            <div class="field-wrap">
                                <label for="productWeight">Measurement System</label>
                                <select v-model="newProductMeasurementSystem" class="dropdown-group" id="sizeSystem"
                                    name="sizeSystem" @change="filterMeasurementUnits">
                                    <option v-for="(sizeSystem, index)  in measurementSystems" :key="index"
                                        :value="sizeSystem.id">
                                        {{ sizeSystem.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="flex-columns">
                                <div class="field-wrap">
                                    <label for="productWeight">Weight</label>
                                    <input class="text-field" id="productWeight" v-model="newProductWeight"
                                        :aria-invalid="submitAttempted && !newProductWeight" type="text"
                                        placeholder="10" />
                                </div>
                                <div class="field-wrap">
                                    <label for="productWeightUnit">Weight Unit</label>
                                    <select v-model="newProductWeightUnit" class="dropdown-group" id="productWeightUnit"
                                        name="productWeightUnit">
                                        <option v-for="(weightUnit, index)  in weightUnits" :key="index"
                                            :value="weightUnit">
                                            {{ weightUnit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex-columns">
                                <div class="field-wrap">
                                    <label for="productLength">Length</label>
                                    <input class="text-field" id="productLength" v-model="newProductLength"
                                        :aria-invalid="submitAttempted && !newProductLength" type="text"
                                        placeholder="10" />
                                </div>
                                <div class="field-wrap">
                                    <label for="productWidth">Width</label>
                                    <input class="text-field" id="productWidth" v-model="newProductWidth"
                                        :aria-invalid="submitAttempted && !newProductWidth" type="text"
                                        placeholder="20" />
                                </div>
                                <div class="field-wrap">
                                    <label for="productHeight">Height</label>
                                    <input class="text-field" id="productHeight" v-model="newProductHeight"
                                        :aria-invalid="submitAttempted && !newProductHeight" type="text"
                                        placeholder="15" />
                                </div>
                                <div class="field-wrap">
                                    <label for="productDimensionUnit">Dimensions Unit</label>
                                    <select v-model="newProductDimensionUnit" class="dropdown-group"
                                        id="productDimensionUnit" name="productDimensionUnit">
                                        <option v-for="(dimensionUnit, index) in dimensionUnits" :key="index"
                                            :value="dimensionUnit">
                                            {{ dimensionUnit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-wrap__footer">
                        <button type="submit" class="main-wrap__submit-button" @click="storeProductData()"
                            v-if="formCompleted">
                            Next
                        </button>
                        <button type="submit" class="main-wrap__submit-button-inactive" v-else>
                            Next
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import AtmoLoading from '@/components/atmo-loading';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { objIsEmpty } from '@/helpers/utilityFunctions';

export default {
    components: {
        AtmoLoading,
    },

    data: function () {
        return {
            isLoading: false,
            newProductTitle: null,
            newProductDescription: null,
            newProductCategory: null,
            newProductSizeSystem: null,
            newProductMeasurementSystem: null,
            newProductDimensionUnit: null,
            newProductWeightUnit: null,
            newProductWeight: null,
            newProductLength: null,
            newProductWidth: null,
            newProductHeight: null,
            newProductPrice: null,
            submitAttempted: false,
            selectedColors: [],
        }
    },

    computed: {
        ...mapState('products', ['categories', 'sizeSystems', 'measurementSystems', 'measurementUnits', 'colors', 'newProductData']),
        ...mapGetters('products', ['getCategoryById', 'getSizeSystemById', 'getSizeSystemById', 'getMeasurementSystemById', 'getMeasurementUnitById', 'getColorById']),
        artistProfileId() {
            return this.$store.state.currentUserProfileId;
        },
        weightUnits() {
            return this.measurementUnits.filter(unit => unit.unit_type === 'weight' && unit.measurement_system_id === this.newProductMeasurementSystem);
        },
        dimensionUnits() {
            return this.measurementUnits.filter(unit => unit.unit_type === 'dimension' && unit.measurement_system_id === this.newProductMeasurementSystem);
        },
        formCompleted() {
            return this.formIsCompleted();
        }
    },
    created() {
        this.fetchCategories();
        this.fetchSizeSystems();
        this.fetchMeasurementSystems();
        this.fetchMeasurementUnits();
        this.fetchColors();
        this.checkForExistingProductData();
    },
    methods: {
        ...mapActions('products', ['fetchCategories', 'fetchSizeSystems', 'fetchMeasurementSystems', 'fetchMeasurementUnits', 'fetchColors']),
        ...mapMutations('products', ['setNewProductData']),
        goBack() {
            const userId = this.$store.state.currentUserId;
            this.$router.push({ name: 'products.user_index', params: { userId } });
        },
        objIsEmpty(obj) {
            return objIsEmpty(obj);
        },
        formIsCompleted() {
            const {
                newProductTitle,
                newProductDescription,
                newProductCategory,
                newProductSizeSystem,
                newProductMeasurementSystem,
                newProductDimensionUnit,
                newProductWeightUnit,
                newProductWeight,
                newProductLength,
                newProductWidth,
                newProductHeight,
                newProductPrice,
                selectedColors
            } = this;

            return newProductTitle &&
                newProductDescription &&
                newProductCategory &&
                newProductSizeSystem &&
                newProductMeasurementSystem &&
                newProductDimensionUnit &&
                newProductWeightUnit &&
                newProductWeight &&
                newProductLength &&
                newProductWidth &&
                newProductHeight &&
                newProductPrice &&
                selectedColors.length > 0;
        },
        checkForExistingProductData() {
            const {
                newProductTitle,
                newProductDescription,
                newProductCategory,
                newProductSizeSystem,
                newProductMeasurementSystem,
                newProductDimensionUnit,
                newProductWeightUnit,
                newProductWeight,
                newProductLength,
                newProductWidth,
                newProductHeight,
                selectedColors,
                newProductPrice,
            } = this.newProductData;

            if (!this.objIsEmpty(this.newProductData)) {
                console.log("this.newProductData", this.newProductData);
                this.newProductTitle = newProductTitle;
                this.newProductDescription = newProductDescription;
                this.newProductCategory = newProductCategory;
                this.newProductSizeSystem = newProductSizeSystem;
                this.newProductMeasurementSystem = newProductMeasurementSystem;
                this.newProductDimensionUnit = newProductDimensionUnit;
                this.newProductWeightUnit = newProductWeightUnit;
                this.newProductWeight = newProductWeight;
                this.newProductLength = newProductLength;
                this.newProductWidth = newProductWidth;
                this.newProductHeight = newProductHeight;
                this.selectedColors = selectedColors;
                this.newProductPrice = newProductPrice;
            }
        },
        async storeProductInformation() {
            this.setNewProductData({
                ...this.newProductData,
                newProductTitle: this.newProductTitle,
                newProductDescription: this.newProductDescription,
                newProductCategory: this.newProductCategory,
                newProductSizeSystem: this.newProductSizeSystem,
                newProductMeasurementSystem: this.newProductMeasurementSystem,
                newProductDimensionUnit: this.newProductDimensionUnit,
                newProductWeightUnit: this.newProductWeightUnit,
                newProductWeight: this.newProductWeight,
                newProductLength: this.newProductLength,
                newProductWidth: this.newProductWidth,
                newProductHeight: this.newProductHeight,
                selectedColors: this.selectedColors,
                newProductPrice: this.newProductPrice,
            })
        },
        storeProductData() {
            if (this.formIsCompleted()) {
                this.storeProductInformation();
                this.$router.push({ name: 'product_images.new' });
            }
        },
        getCategoryName(categoryId) {
            const category = this.getCategoryById(categoryId);
            return category ? category.name : '';
        },
        getSizeSystemName(sizeSystemId) {
            const sizeSystem = this.getSizeSystemById(sizeSystemId);
            return sizeSystem ? sizeSystem.name : '';
        },
        getMeasurementSystemName(measurementSystemId) {
            const measurementSystem = this.getMeasurementSystemById(measurementSystemId);
            return measurementSystem ? measurementSystem.name : '';
        },
        getMeasurementUnitName(measurementUnitId) {
            const measurementUnit = this.getMeasurementUnitById(measurementUnitId);
            return measurementUnit ? measurementUnit.name : '';
        },
        getColorName(colorId) {
            const color = this.getColorById(colorId);
            return color ? color.name : '';
        },
        filterSizeSystems() {
            if (this.newProductCategory) {
                this.filteredSizeSystems = this.sizeSystems.filter(sizeSystem => sizeSystem.product_category_id === this.newProductCategory);
            } else {
                this.filteredSizeSystems = [];
            }
        },
        filterMeasurementUnits() {
            if (this.newProductMeasurementSystem) {
                this.filteredMeasurementUnits = this.measurementUnits.filter(measurementUnit => measurementUnit.measurement_system_id === this.newProductMeasurementSystem);
            } else {
                this.filteredMeasurementUnits = [];
            }
        }
    },
    watch: {
        newProductCategory() {
            this.filterSizeSystems();
        },
        newProductMeasurementSystem() {
            this.filterMeasurementUnits();
        }
    },
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-products-new {
    display: flex;
    flex-direction: column;
    // max-width: 50rem;

    //https://github.com/mercs600/vue2-perfect-scrollbar
    .ps {
        max-height: 20rem;
    }

    .ps.available-colors-scroll {
        max-height: 5rem;
        padding-top: .2rem;
    }

    textarea,
    textarea::placeholder {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        color: white;
    }

    input::placeholder {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        color: rgba(255, 255, 255, .4) !important;
    }

    .field-wrap {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

        label {
            text-transform: uppercase;
            margin-bottom: .5rem;
            font-size: .9rem;
            font-weight: 500;
        }

        .text-field {
            background: transparent;
            border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, .4);
            padding: .5rem .5rem .5rem .5rem;
            color: white;
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(255, 255, 255, .4);
            opacity: 1;
            /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(255, 255, 255, .4);
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(255, 255, 255, .4);
        }

        .dropdown-group {
            background: $atmo-purple--medium-dark;
            color: white;
            text-transform: uppercase;
            border: none;
            border-radius: 5px;
            padding: .5rem;
            font-weight: 500rem;
            cursor: pointer;
        }

        .dropdown-field {}

        .radio-group {
            display: block !important;
            padding: .6rem 0rem .6rem 0rem;
        }

        .radio-field-wrap {
            margin-right: 1rem;
            display: flex;
            justify-content: start !important;
            align-items: center;
            margin-bottom: .1rem;
        }

        .radio-field {
            margin-right: .5rem;
            cursor: pointer;
        }

        .radio-field-label {
            margin: 2px 0px 0px 0px;
        }
    }

    .file-type-label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $atmo-purple--light;
        cursor: pointer;
        text-transform: unset !important;
        font-weight: 400 !important;
        padding: .2rem .4rem .2rem .4rem;
    }

    .field-label {
        font-weight: 500;
        margin-bottom: .8rem;
    }

    .atmo-products-new {}

    .atmo-products-new__container {
        margin: auto;
        max-width: 1000px;
    }

    .container__container-wrap {}

    .container-wrap__form {}

    .form__product-details-wrap {
        display: flex;
        // gap: 1rem;
    }

    .product-details-wrap__image-upload {}

    .product-details-wrap__product-details {
        width: 100%;

        .flex-columns {
            display: flex;
            gap: 1rem;

            .text-field {
                min-width: unset;
            }
        }

    }

    .available-colors-container {
        label {
            text-transform: uppercase;
            margin-bottom: .5rem;
            font-size: .9rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }
    }

    .shipping-info-heading {
        margin-top: .5rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 500;
    }

    .main-wrap__submit-button {
        margin-left: auto;
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
    }

    .main-wrap__submit-button-inactive {
        margin-left: auto;
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.3) 0%, rgba(68, 174, 220, 0.3) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
    }

    //https://www.sliderrevolution.com/resources/css-checkbox/
    input[type="checkbox"] {
        position: relative;
        width: 1.2em;
        height: 1.2em;
        color: #363839;
        border-radius: 4px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        background: $atmo-purple--dark;

        &::before {
            position: absolute;
            content: '';
            display: block;
            top: 2px;
            left: 6px;
            width: 4px;
            height: 10px;
            border-style: solid;
            border-color: $atmo-purple--dark;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
        }

        &:checked {
            color: $atmo-purple--dark;
            border-color: $atmo-blue--medium;
            background: $atmo-blue--medium;

            &::before {
                opacity: 1;
            }

            ~label::before {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }
    }
}
</style>