<template>
  <b-modal
    id="ami-options"
    ref="amiOptionsRef"
    v-model="$store.state.amiOptionsOpen"
    title="Ami Options"
    modal-class="atmo-modal atmo-queue"
    hide-header
    hide-footer
    centered
  >
    <header class="atmo-queue__header atmo-modal__header">
      <img
        class="atmo-queue__hide-queue"
        src="@/assets/images/icons/close_popup.png"
        alt="close"
        title="Hide Queue"
        @click="$refs.amiOptionsRef.hide()"
      >
      <h2>AMI Options</h2>
      <span title="Queue Actions" />
    </header>
    <template v-for="option in $store.state.amiOptions">
      <p :key="option">
        {{ option }}
      </p>
    </template>
  </b-modal>
</template>

<script>
  export default {
    components: {

    },

    data() {
      return {
        isOpen: true
      }
    },

    created() {

    },

    methods: {
      hideModal() {
        this.$store.commit('hideModal');
      },
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .atmo-library {
    .modal .modal-lg.modal-dialog {
      max-width: 1000px;
    }

    &__body {
      display: flex;
    }

    &__main-column {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__sidebar {
      display: flex;
      flex-direction: column;
      width: 200px;
      margin: 0 20px 10px 30px;
    }

    &__sidebar-section {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
    }

    &__back-to-library {
      display: flex;
      align-items: flex-end;
      font-size: 1.1em;
      font-weight: 300;
      cursor: pointer;

      img {
        height: 12px;
        margin-right: 3px;
      }
    }
  }
</style>
