<template>
  <perfect-scrollbar>
    <div class="albums__albums-grid">
      <router-link
        v-for="(album, index) in albums"
        :key="index"
        :to="{ name: 'albums.show', params: { artistProfileId: album.primary_artist.id, albumId: album.id } }"
        class="albums-grid__album-card"
        v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${album.image.url})` }"
      >
        <div class="album-card__title-wrap">{{ album.name }}</div>
      </router-link>
    </div>
  </perfect-scrollbar>
</template>

<script>
import PaginationMixin from "@/mixins/pagination";

export default {
  mixins: [PaginationMixin],
  props: { showNewReleases: { type: Boolean, default: false }, albums: { type: Array, required: true } },

  data() {
    return {};
  },

  computed: {
    fullCollection() {
      if (this.showNewReleases) {
        return this.genres.filter((genre) => genre.isNew);
      } else {
        return this.genres;
      }
    },
  },
};
</script>

<style lang="scss">
.albums__albums-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  grid-gap: 1rem;
  margin-top: 10px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 0rem 1rem 0rem 0rem;

  .albums-grid__album-card {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 1rem;
    color: white;
    min-height: 8rem;
    min-width: 8rem;
  }

  .album-card__title-wrap {
    font-weight: 800;
    font-size: 1.1rem;
    text-align: center;
  }
}
</style>
