import Axios from 'axios';

export function callChatRoomsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/chat_rooms`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callChatRoomsCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/chat_rooms/`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}