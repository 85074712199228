<template>
  <div class="atmo-concerts">
    <div class="atmo-concerts__filters">
      <label class="atmo-concerts__near-you-toggle" @click="toggleNearYouFilter">
        <span>
          Concerts Near You
        </span>
        <atmo-toggle :on="nearYouActive" />
      </label>

      <atmo-filter-controls v-if="!nearYouActive" :selected-filter-value="selectedFilterValue"
        :selected-filter-type="selectedFilterType" :on-filter-button-click="showFilterModal" :on-reset="resetFilter" />
    </div>
    <div v-if="isLoading">
      Loading…
    </div>
    <perfect-scrollbar v-else>
      <table class="atmo-table">
        <tbody>
          <tr v-for="concert in concerts" :key="concert.id" class="atmo-table__tr">
            <td class="atmo-table__td atmo-table__td--tight atmo-concerts__thumb-image-cell">
              <img class="atmo-concerts__thumb-image" :src="concert.image.url" />
            </td>
            <td class="atmo-table__td atmo-table__td--tight concert-name">
              <router-link :to="`concerts/${concert.id}`" class="atmo-concerts__table-cell-link">
                {{ concert.name }}
              </router-link>
            </td>
            <td class="atmo-table__td">
              {{ concert.user.name }}
            </td>
            <td class="atmo-table__td">
              {{ getPriceRange(concert.atmo_event_tickets) }}
            </td>
            <td class="atmo-table__td">
              {{ formatAddress(concert.address) }}
            </td>
            <td class="atmo-table__td">
              {{ formatDate(concert.datetime) }}
            </td>
          </tr>
        </tbody>
      </table>
    </perfect-scrollbar>
    <filter-modal :selected-filter-type="selectedFilterType" :states-and-cities="statesAndCities" :artists="artists"
      :selected-state="selectedState" :selected-city="selectedCity" :selected-artist="selectedArtist"
      :selected-start-date="selectedStartDate" :selected-end-date="selectedEndDate" :format-dates="formatDates"
      :on-submit="onSubmitFilterModal" />
  </div>
</template>

<script>
// import AtmoPopover from '@/components/atmo-popover';
import AtmoToggle from '@/components/atmo-toggle';
import AtmoFilterControls from '@/components/atmo-filter-controls';
import FilterModal from './concerts/filter-modal';
import format from 'date-fns/format';
import { statesAndCitiesStub, artistsStub } from './data';
import { utilsGetImgUrl, 
  formatDateToMonthDayYearHour, 
  formatAddress, 
  getTicketPriceRange 
} from '@/helpers/utilityFunctions';
import {
  callEventsIndex
} from '@/helpers/axiosCalls';

export default {
  components: {
    // AtmoPopover,
    AtmoToggle,
    AtmoFilterControls,
    FilterModal
  },

  data() {
    return {
      isLoading: false,
      concerts: null,
      nearYouActive: false,
      dateFormat: 'MMM D',
      selectedFilterType: 'location',
      selectedState: null,
      selectedCity: null,
      selectedArtist: null,
      selectedStartDate: null,
      selectedEndDate: null
    };
  },

  computed: {
    selectedFilterValue() {
      // will be null if there is no filter selected
      switch (this.selectedFilterType) {
        case 'location':
          return this.selectedState;
        case 'artist':
          return this.selectedArtist.name;
        case 'date':
          return this.formatDates(this.selectedStartDate, this.selectedEndDate);
        default:
          return null;
      }
    }
  },

  created() {
    this.getConcerts();
    this.getStatesAndCities();
    this.getArtists();
  },

  methods: {
    formatDate(dateString) {
      return formatDateToMonthDayYearHour(dateString);
    },
    formatAddress(address) {
      return formatAddress(address);
    },
    getPriceRange(tickets) {
      return getTicketPriceRange(tickets);
    },
    getImgUrl(image) {
      return utilsGetImgUrl(image);
    },
    getStatesAndCities() {
      // TODO: Use real states and cities
      this.statesAndCities = statesAndCitiesStub;
    },
    getArtists() {
      // TODO: Use real artists
      this.artists = artistsStub;
    },
    getConcerts() {
      this.isLoading = true;
      callEventsIndex({
        event_type: 'concert',
      })
        .then(response => {
          this.isLoading = false;
          this.concerts = response;
        })
        .catch(error => {
          console.error(error);
        });
    },
    formatDates(startDate, endDate) {
      let formattedDates = ''
      if (startDate) {
        formattedDates = format(startDate, this.dateFormat)
      }
      if (endDate) {
        formattedDates += ' - ' + format(endDate, this.dateFormat)
      }
      return formattedDates
    },
    showFilterModal() {
      this.$root.$emit('bv::show::modal', 'concerts-filter')
    },
    toggleNearYouFilter() {
      this.nearYouActive = !this.nearYouActive;
      this.getConcerts();
    },
    onSubmitFilterModal(newProps) {
      this.selectedFilterType = newProps.filterType;
      this.selectedState = newProps.state;
      this.selectedCity = newProps.city;
      this.selectedArtist = newProps.artist;
      this.selectedStartDate = newProps.startDate;
      this.selectedEndDate = newProps.endDate;
      this.getConcerts();
    },
    resetFilter() {
      this.selectedFilterType = 'location'
      this.selectedState = null;
      this.selectedCity = null;
      this.selectedArtist = null;
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      this.getConcerts();
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-concerts {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-weight: 500;

  &__filters {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__near-you-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 7px 10px;
    background-color: rgba($atmo-purple--medium, 0.3);
    border-radius: 3px;

    span {
      margin-right: 6px;
    }
  }

  &__thumb-image {
    $thumb-image-size: 2.5rem;

    width: $thumb-image-size;
    height: $thumb-image-size;
    border-radius: 100%;
  }

  &__table-cell-link {
    color: white;
    padding: 15px 10px;

    &:hover {
      color: $atmo-gray--light;
    }
  }

  td {
    padding: .8rem;
  }

  .concert-name {
    max-width: 5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tr:nth-child(odd) {
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  }
}
</style>
