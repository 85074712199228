<template>
  <div class="dots">
    <div
      v-for="index in [4,3,2,0]"
      :id="`strength-${index}`"
      :key="index"
      class="dot"
      :class="{ 'active': password.length ? passwordStrengthCalculation.score >= index : false }"
    />
    <b-popover
      :disabled="!password.length"
      placement="right"
      triggers="hover"
      :offset="10"
      custom-class="passwordmeter"
      :target="`strength-${passwordStrengthCalculation.score}`"
    >
      <span slot="title" :class="`title-${passwordStrengthCalculation.score}`">
        {{ popOverTitleBasedOnPasswordStrength }}
      </span>
      <span>
        {{ passwordStrengthCalculation.feedback.warning || 'Your password is strong.' }}
        {{ passwordStrengthCalculation.feedback.suggestions.join('\n') }}
      </span>
    </b-popover>
  </div>
</template>
<script>
  import zxcvbn from 'zxcvbn';
  import { BPopover } from 'bootstrap-vue';
  export default {
    components: {
      BPopover
    },
    props: {
      password: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      passwordStrengthTitle: {
        '0': 'Very low security',
        '1': 'Low security',
        '2': 'Medium security',
        '3': 'Strong security',
        '4': 'Very strong security'
      },
    }),
    computed: {
      passwordStrengthCalculation() {
        return zxcvbn(this.password)
      },
      popOverTitleBasedOnPasswordStrength() {
        return this.passwordStrengthTitle[this.passwordStrengthCalculation.score]
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';
.passwordmeter {
  .popover-header {
    border: unset;
    font-family: Roboto, sans-serif;
    background-color: unset;
    .title-0, .title-1 {
      color: $atmo-invalid;
    }
    .title-2 {
      color: $atmo-warning;
    }
    .title-3 {
      color: $atmo-light-green;
    }
    .title-4 {
      color: $atmo-green;
    }
  }
  .popover-body {
    padding: 0 .75rem 0.5rem 0.75rem;
  }
}
  .dots {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dot {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: $atmo-gray--light;
    &:not(:first-child) {
      margin-top: 3.5px;
    }
    &.active {
      &#strength-0, .strength-0 {
        background-color: $atmo-invalid;
      }
      &#strength-2, .strength-2{
        background-color: orange;
      }
      &#strength-3, .strength-3 {
        background-color: greenyellow
      }
      &#strength-4, .strength-4{
        background-color: green;
      }
    }
  }

</style>
