import {
    callProductsCreate,
    callProductVariantsCreate,
    callProductVariantImagesCreateVariantImages,
    callShippingCarrierProductsCreate,
    callImagesCreate
} from '@/helpers/axiosCalls';

export async function createProductAndVariants(componentData) {
    const {
        currentUserId,
        newProductData,
        productVariantGroups
    } = componentData;

    const {
        newProductTitle,
        newProductDescription,
        newProductImages,
        newProductCategory,
        newProductSizeSystem,
        newProductMeasurementSystem,
        newProductLength,
        newProductWidth,
        newProductHeight,
        newProductWeight,
        newProductDimensionUnit,
        newProductWeightUnit,
    } = newProductData;

    console.log("COMPONENT DATA==================", componentData);

    // Step 1: Create the product
    console.log('Creating product...');
    const createdProduct = await callProductsCreate({
        title: newProductTitle,
        description: newProductDescription,
        user_id: currentUserId,
        product_category_id: newProductCategory,
        product_size_system_id: newProductSizeSystem.id,
    });
    console.log('Product created successfully with ID:', createdProduct.id);

    // Step 2: Create shipping carrier products
    console.log('Creating shipping carrier product...', newProductMeasurementSystem);
    await callShippingCarrierProductsCreate({
        product_id: createdProduct.id,
        measurement_system_id: newProductMeasurementSystem,
        length: newProductLength,
        width: newProductWidth,
        height: newProductHeight,
        weight: newProductWeight,
        dimension_unit_id: newProductDimensionUnit.id,
        weight_unit_id: newProductWeightUnit.id,
    });
    console.log('Shipping carrier product created successfully.');

    // Step 3: Flatten and transform variants
    console.log('Flattening and transforming variants...');
    function flattenAndTransformVariants(productVariantGroups, transformFunction) {
        return productVariantGroups.flatMap(group =>
            group.variants.map(variant => transformFunction(variant))
        );
    }

    const updateAttributes = variant => ({
        product_id: createdProduct.id,
        product_size_id: variant.product_size.id,
        product_color_id: variant.product_color.id,
        quantity: variant.quantity,
        price: variant.price,
    });

    const flattenedAndTransformedVariants = flattenAndTransformVariants(productVariantGroups, updateAttributes);
    console.log('Variants flattened and transformed:', flattenedAndTransformedVariants);

    // Step 4: Create product variants
    console.log('Creating product variants...');
    const createdVariants = await callProductVariantsCreate({ product_variants: flattenedAndTransformedVariants }, { bulk: true });
    console.log('Product variants created successfully.');

    // Step 5: Upload images in bulk
    if (newProductImages.length > 0) {
        console.log('Uploading new images...');
        const bulkUploadImages = (images, createdProductId, currentUserId) => {
            const formData = new FormData();

            images.forEach((image, index) => {
                formData.append(`images[${index}][image]`, image.imageFile);
                formData.append(`images[${index}][name]`, image.imageName);
                formData.append(`images[${index}][imageable_id]`, createdProductId);
                formData.append(`images[${index}][imageable_type]`, "Product");
                formData.append(`images[${index}][is_featured_image]`, false);
                formData.append(`images[${index}][user_id]`, currentUserId);
            });

            // Append the bulk flag
            formData.append('bulk', true)
            // Make the API call
            return callImagesCreate(formData);
        }

        await bulkUploadImages(newProductImages, createdProduct.id, currentUserId);
        console.log('New images uploaded successfully.');
    } else {
        console.log('No new images to upload.');
    }

    // Step 6: Create product variant images
    console.log('Creating product variant images...');
    productVariantGroups.forEach(async (group, index) => {
        await callProductVariantImagesCreateVariantImages({
            product_id: createdProduct.id,
            color_id: group.color.id,
            image_name: group.variantImage.imageName
        });
        console.log(`Product variant image for group ${group.color.name} created successfully.`);
    });

    console.log('Product, variants, images, and associations created successfully.');
}
