<template>
  <div class="playlist-collaborators">
    <div class="toggle-is-public-container">
      <div class="toggle-is-public-container__public-wrap">
        <div class="public-wrap__text">Public</div>
        <div class="public-wrap__toggle">
          <atmo-toggle :on="playlistIsPublic" :on-change="toggleIsPublic" size="large" />
        </div>
      </div>
    </div>
    <header class="playlist-collaborators__header" v-if="!playlistIsPublic">
      <h3 class="playlist-collaborators__h3">
        Collaborators
      </h3>
      <button class="atmo-icon-button" @click="toggleNewCollaboratorForm">
        <img v-if="newCollaboratorFormActive" src="@/assets/images/icons/atmo_library/minus.png" alt="add collaborator"
          class="atmo-library__section-header-icon playlist-collaborators__add-icon">
        <img v-else src="@/assets/images/icons/add.png" alt="add collaborator"
          class="atmo-library__section-header-icon playlist-collaborators__add-icon">
      </button>
    </header>
    <div v-if="newCollaboratorFormActive" class="playlist-collaborators__new-collaborator-form">
      <input autofocus placeholder="Type your Friend's name" class="atmo-input atmo-input--search" v-model="searchQuery"
        @click="populateFriendsDropdown()">
      <div v-if="collaboratorListIsOpen" class="dropdown-list">
        <perfect-scrollbar class="scroll-wrap">
          <div v-for="(friendship, index) in filteredFriendList" :key="index" @click="selectFriend(friendship)"
            class="list-item">
            <div class="list-item__image-wrap">
              <img class="image-wrap__img" :src="getFeaturedImage(friendship.friend.images, 'full')" />
            </div>
            <div>{{ friendship.friend.name }}</div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="playlist-collaborators__badges" v-if="!playlistIsPublic && newCollaboratorFormActive">
      <atmo-badge v-for="(collaborator, index) in visibleCollaborators" :key="index" class="playlist-collaborators__badge"
        :label="collaborator.user.name" removable :on-remove="() => removeCollaborator(collaborator.id)" />
      <button v-if="shouldTruncate" class="
          atmo-button
          playlist-collaborators__badge
          atmo-badge
          atmo-badge--inverted
        ">
        <span v-if="isTruncated" @click="isTruncated = false">
          See More
        </span>
        <span v-else @click="isTruncated = true">
          See Less
        </span>
      </button>
      <!-- <button class="atmo-button playlist-collaborators__badge atmo-badge atmo-badge--inverted">
        See More
      </button> -->
    </div>
  </div>
</template>

<script>
import AtmoBadge from '@/components/atmo-badge';
import AtmoToggle from '@/components/atmo-toggle';
import {
  callPlaylistsUpdate,
  callPlaylistCollaboratorsIndex,
  callPlaylistCollaboratorsCreate,
  callPlaylistCollaboratorsDelete
} from '@/helpers/axiosCalls';
import { getFeaturedImage } from '@/helpers/utilityFunctions';

export default {
  components: { AtmoBadge, AtmoToggle },
  props: {
    playlist: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      newCollaboratorFormActive: false,
      newCollaboratorName: '',
      isTruncated: true,
      playlistIsPublic: this.playlist.is_public,
      playlistCollaborators: [],
      collaboratorListIsOpen: false,
      searchQuery: '',
      selectedFriend: {}
    }
  },
  created() {
    this.getPlaylistCollaborators();
  },
  computed: {
    acceptedFriendships() {
      return this.$store.state.chatRooms.chatUserFriendships.filter(friendship =>
        friendship.state === 'accepted'
      );
    },
    filteredFriendList() {
      return this.acceptedFriendships.filter(friendship =>
        friendship.friend.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    truncatedCollaborators: function () {
      return this.playlistCollaborators.slice(0, 5)
    },
    visibleCollaborators: function () {
      return this.isTruncated ?
        this.truncatedCollaborators :
        this.playlistCollaborators;
    },
    shouldTruncate: function () {
      return this.playlistCollaborators.length > 6;
    }
  },
  methods: {
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    selectFriend(friendship) {
      this.collaboratorListIsOpen = false;
      this.selectedFriend = friendship.friend;
      this.addCollaborator(friendship.friend);
      this.searchQuery = '';
    },
    populateFriendsDropdown() {
      this.collaboratorListIsOpen = !this.collaboratorListIsOpen;
    },
    addCollaborator(friend) {
      callPlaylistCollaboratorsCreate(this.$store.state.currentUserId, this.playlist.id, {
        playlist_id: this.playlist.id,
        user_id: friend.id
      })
        .then(response => {
          console.log(response);
          this.getPlaylistCollaborators();
          this.$emit('update-playlists');
        })
        .catch(error => {
          console.log(error);
        });
    },
    getPlaylistCollaborators() {
      callPlaylistCollaboratorsIndex(this.$store.state.currentUserId, this.playlist.id, {})
        .then(response => {
          console.log(response);
          this.playlistCollaborators = response;
        })
        .catch(error => {
          console.log(error);
        });
    },
    toggleIsPublic() {
      this.playlistIsPublic = !this.playlistIsPublic;

      callPlaylistsUpdate(this.$store.state.currentUserId, this.playlist.id,
        {
          is_public: this.playlistIsPublic,
        })
        .then(response => {
          console.log(response);
          this.$emit('update-playlists');
        })
        .catch(error => {
          console.log(error);
        });
    },
    toggleNewCollaboratorForm() {
      this.newCollaboratorFormActive = !this.newCollaboratorFormActive;
    },
    removeCollaborator(collaboratorId) {
      callPlaylistCollaboratorsDelete(this.$store.state.currentUserId, this.playlist.id, collaboratorId)
        .then(response => {
          console.log(response);
          this.getPlaylistCollaborators();
          this.$emit('update-playlists');
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.playlist-collaborators {
  display: flex;
  flex-direction: column;
  margin: 10px 12px;

  .toggle-is-public-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .toggle-is-public-container__public-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 500;
      font-size: 1.2rem;
      background-color: rgba(190, 167, 221, .3);
      padding: .5rem;
      border-radius: 5px;
      max-width: 20rem;
    }

    .public-wrap__text {
      margin-right: 1rem;
    }
  }

  &__header {
    display: flex;
  }

  &__h3 {
    text-transform: uppercase;
    font-size: 1.1em;
  }

  &__add-icon {
    margin-left: 5px;
  }

  &__new-collaborator-form {
    display: flex;
    margin-top: 10px;
    position: relative;

    .atmo-input {
      width: 180px;
      margin-bottom: .5rem;
    }

    .dropdown-list {
      position: absolute;
      top: 2rem;
      padding: 0;
      background-color: rgba(96, 72, 117, 0.9);
      backdrop-filter: blur(5px);
      height: 10rem;
      width: 12rem;
      border-radius: 5px;
      z-index: 100;
    }

    .scroll-wrap {
      height: 10rem;
    }

    .list-item {
      padding: .5rem 1rem .5rem 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .list-item__image-wrap {
      height: 1.2rem;
      width: 1.2rem;
      margin-right: .5rem;

      img {
        height: 1.2rem;
        width: 1.2rem;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    // button {
    //   text-transform: uppercase;
    //   background: none;
    //   font-size: 1em;
    //   padding: 0 10px;
    // }
  }

  &__badges {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, 94px);
    // /* grid-auto-rows: 160px; */
    // max-width: 900px;
    // grid-gap: 10px;
    // margin-top: 15px;

    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  &__badge {
    outline: none;
    margin: 6px 5px;
    font-size: 0.7rem;

    &.atmo-button {
      font-size: 0.7rem;
      text-transform: uppercase;
      padding: 0rem .5rem 0rem .5rem;
    }
  }
}
</style>
