import Axios from 'axios';

export function callProductVariantImagesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/product_sizes`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductVariantImagesShow(productVariantId, queryParams = {}) {
    return Axios.get(`/api/v1/product_variant_images/${productVariantId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductVariantImagesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/product_variant_images`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductVariantImagesCreateVariantImages(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/product_variant_images/create_variant_images`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductVariantImagesUpdateVariantImages(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/product_variant_images/update_variant_images`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductVariantImagesDeleteVariantImages(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/product_variant_images/delete_variant_images`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductVariantImagesUpdate(productVariantId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/product_variant_images/${productVariantId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductVariantImagesDelete(productId, queryParams = {}) {
    return Axios.delete(`/api/v1/product_variant_images/${productId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}