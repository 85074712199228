import Axios from 'axios';

export function callAddressesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/addresses`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAddressesShow(addressId, queryParams = {}) {
    return Axios.get(`/api/v1/addresses/${addressId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAddressesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/addresses`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAddressesUpdate(addressId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/addresses/${addressId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAddressesDelete(addressId, queryParams = {}) {
    return Axios.delete(`/api/v1/addresses/${addressId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}