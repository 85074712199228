<template>
  <div>
    <h1>Default Card</h1>
    <p>Card ID: {{ customer.default_source }}</p>
    <p>Type: {{ customer.sources.data[0].brand }}</p>
    <p>Last 4: {{ customer.sources.data[0].last4 }}</p>
    <p>Expiration Month: {{ customer.sources.data[0].exp_month }}</p>
    <p>Expiration Year: {{ customer.sources.data[0].exp_year }}</p>
    <!-- <h1>Change Card To:</h1>
      <form stripe-form="stripeCallback" name="checkoutForm">
      <div style="height:30px; width:80%; margin:0 auto; margin-bottom:10px;">
        <input style="display:block; margin:0 auto; font-size:20px; text-align: center; vertical-align: middle; border-radius:25px; width:63%; height:30px; background-color:rgba(255,255,255,0.1);" ng-model="number" placeholder="Card Number"
                 payments-format="card" payments-validate="card" name="card">
      </div>
      <div style="height:30px; width:80%; margin:0 auto; margin-bottom:10px;">
        <input style="display:block; margin:0 auto; font-size:20px; text-align: center; vertical-align: middle; border-radius:25px; width:63%; height:30px; background-color:rgba(255,255,255,0.1);" ng-model="expiry" placeholder="Expiration"
                 payments-format="expiry" payments-validate="expiry"
                 name="expiry">
      </div>
      <div style="height:30px; width:80%; margin:0 auto; margin-bottom:10px;">
        <input style="display:block; margin:0 auto; font-size:20px; text-align: center; vertical-align: middle; border-radius:25px; width:63%; height:30px; background-color:rgba(255,255,255,0.1);" ng-model="cvc" placeholder="CVC" payments-format="cvc" payments-validate="cvc" name="cvc">
      </div>
      <div style="height: 100px; width:80%; margin:0 auto;">
        <input style="display:block; margin:0 auto; font-size:15px; text-align: center; vertical-align: middle; cursor:pointer; border:solid white 1px; border-radius:25px; width:50%; height:30px; margin-bottom:20px;" type="submit" value="Add Card">
      </div>
      </form>
      <div ng-if="checkoutForm.card.$invalid">
          Error: invalid card number!
      </div>
  </div> -->

    <h3>Please enter your card details:</h3>
    <!-- <label for="email">Email</label>
    <input id="email" type="email" v-model="stripeEmail" placeholder="name@example.com"/> -->
    <label for="card">
      Credit Card
    </label>
    <card
      id="card"
      class="stripe-card"
      :class="{ complete }"
      stripe="pk_test_UkAcQat2PhllsZTtEnv5Zvqw"
      :options="stripeOptions"
      @change="complete = $event.complete"
    />
    <button
      class="pay-with-stripe"
      @click="addCard"
    >
      Add Card
    </button>
    <!-- <button class='pay-with-stripe' @click='addCard' :disabled='!complete || !stripeEmail'>Add Card</button> -->
  </div>
</template>

<script>
  import Axios from 'axios'
  import storeTest from '@/store'
  // import { stripeKey, stripeOptions } from './stripeConfig.json'
  import { Card, createToken } from 'vue-stripe-elements-plus'
  import { 
    callCardsCreate,
    callStripeCustomersGet,
    callStripeCustomersPost,
    callUserProfilesShow,
    callUsersShow
} from '@/helpers/axiosCalls';

  export default {
    components: { Card },
    props: {
      total: {
        type: [Number, String],
        default: '50.00'
      },
      success: {
        type: Boolean,
        default: false
      }
    },
    data: function(){
      return {
        user: {},
        userProfile: {},
        customerId: "",
        customer: {},
        sources: [],
        authorizationToken: "Bearer sk_test_R87ol4y4QdQVp354Dwyy45pi",
        // submitted: false,
        // complete: false,
        status: '',
        response: '',
        stripeOptions: {
        // you can configure that cc element. I liked the default, but you can
        // see https://stripe.com/docs/stripe.js#element-options for details
        },
        stripeEmail: ''
      }
    },
    created(){
      callUsersShow(storeTest.state.currentUserId)
        .then(response => {
          this.user = response.data;
          this.customerId = this.user.customer_id;
          callUserProfilesShow(this.user.profile_id).then(response2 => (this.userProfile = response2));
          callStripeCustomersGet(this.customerId)
            .then(response => {
              this.customer = response;
            });
          Axios.get(`https://api.stripe.com/v1/customers/${this.customerId}/sources`, {
            headers: {
              Authorization: this.authorizationToken
            }
          })
            .then(response3 => {
              this.sources = response3.data.data;
            })
        });
    },

    methods: {

      // Axios({
      //   method: 'post',
      //   url: '/api/v1/users/' + storeTest.state.currentUserId + '/cards',
      //   card_token: data.token,
      //   user_id: this.user.id,
      //   customer_id: this.user.customer_id
      // })

      addCard () {
        createToken().then(data => {
          this.submitted = true; // we'll change the flag to let ourselves know it was submitted
          console.log(data.token); // this is a token we would use for the stripeToken below
          callCardsCreate(storeTest.state.currentUserId, {
            card_token: data.token.id,
            user_id: this.user.id,
            customer_id: this.customerId
          })
            .then(response => {
              this.status = 'success';
              // this.$emit('successSubmit');
              // this.$store.commit('clearCartCount');
              // console logs for you :)
              this.response = JSON.stringify(response, null, 2);
              console.log(this.response);
            })
            .catch(error => {
              this.status = 'failure';
              // console logs for you :)
              this.response = 'Error: ' + JSON.stringify(error, null, 2);
              console.log(this.response);
            });
        });
      },

      getStripeCustomer (userCustomerId) {
        callStripeCustomersGet(userCustomerId)
          .then(response => {
            this.customer = response;
          });
      },

      updateDefaultCard (cardId) {
        callStripeCustomersPost(this.customerId, {
          default_source: cardId
        })
          .then((response) => {
            console.log(response);
            this.getStripeCustomer(this.customerId);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }
</script>

<style>
.stripe-card {
  width: 300px;
  border: 1px solid grey;
}
.stripe-card.complete {
  border-color: green;
}
</style>
