<template>
  <div class="dashboard-venue-requests" data-cy="dashboard-venue-requests">
    <div class="header-container">
      <h2 class="atmo-page-header">
        Venue Requests
      </h2>
    </div>
    <router-link class="back-container" :to="{ name: 'artist_dashboards.show', params: { artistProfileId: artistProfileId } }">
      <div class="back-container__icon-container">
        <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
      </div>
      <div class="back-container__text-container">Back To Dashboard</div>
    </router-link>

    <div class="dashboard-venue-requests-grid-container">
      <div class="dashboard-venue-requests-grid-container__graph-container">
        <template>
          <div id="chart">
            <apexchart type="pie" width="380" :options="venueOptions" :series="venueSeries"></apexchart>
          </div>
        </template>
      </div>
      <div class="dashboard-venue-requests-grid-container__venues-container">
        <div class="venues-container__venues-table-wrap">
          <div class="venues-table-wrap__headings-container">
            <div class="headings-container__heading">Venue</div>
            <div class="headings-container__heading">Location</div>
            <div class="headings-container__heading">Votes</div>
          </div>
          <perfect-scrollbar>
            <div v-for="(venue, index) in artistDashboardData.venue_counts_and_data" :key="index"
              :class="index % 2 == 0 ? 'venues-table-wrap__row --dark' : 'venues-table-wrap__row --light'">
              <div class="row__venue-name">{{ venue.name }}</div>
              <div class="row__venue-location">{{ venue.city }}, {{ venue.state }}</div>
              <div class="row__venue-requests">{{ venue.count }}</div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Axios from 'axios'
import devMockData from '@/devUtils/mockData.js';
import VueApexCharts from "vue-apexcharts";
import User from '@/models/user';
import { callUsersShow } from '@/helpers/axiosCalls';

//https://apexcharts.com/vue-chart-demos/polar-area-charts/monochrome/

export default {
  components: {
    apexchart: VueApexCharts
  },
  data: function () {
    return {
      currentUserId: this.$store.state.currentUserId,
      artistProfile: {},
      user: {},
      artistProfileId: this.$route.params.artistProfileId,
      artistDashboardData: {},
      topLocations: [],
      venueNameArray: devMockData.venueNameArray,
      cityStateArray: devMockData.cityStateArray,
      artistImageArray: devMockData.artistImageArray,
      highResImageArray: devMockData.highResImageArray,
      songTitleArray: devMockData.songTitleArray,
      artistNameArray: devMockData.artistNameArray,
      artistMerchArray: devMockData.artistMerchArray,
      abbreviateStatesArray: devMockData.abbreviatedStatesArray,
      venueOptions: {
        // theme: {
        //   monochrome: {
        //     enabled: true,
        //     color: '#44AEDC',
        //     shadeTo: 'light',
        //     shadeIntensity: 0.65
        //   }
        // },
        legend: {
          // show: true,
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        fill: {
          // type: 'gradient',
          colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#d4c6e6', '#44AEDC'],
        },
        colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#d4c6e6', '#44AEDC'],
        // dataLabels: { style: { colors: ['#F44336', '#E91E63', '#9C27B0'] } },
        markers: { colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#d4c6e6', '#44AEDC'] },
        stroke: {
          width: 1,
          colors: ['#D8D8D8']
        },
        chart: {
          type: "donut"
        },
        labels: [
          "A", "B", "C", "D", "E", "F", "G"
        ]
      },
      venueSeries: [44, 55, 13, 43, 22]
    }
  },
  created() {
    this.getArtistProfile();
    this.getDashboardData();
  },
  computed: {
    currentUser() {
      return this.$store.state.user;
    },
    isMyProfile() {
      return this.$store.state.currentUserProfileId === this.userProfileId;
    },
    userProfileId() {
      return Number(this.$route.params.artistProfileId);
    }
  },
  methods: {
    getUser(userId) {
      callUsersShow(userId)
        .then((response) => {
          this.user = new User(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getArtistProfile() {
      Axios.get(`/api/v1/artist_profiles/${this.$route.params.artistProfileId}`, {
      })
        .then((response) => {
          this.artistProfile = response.data;
          this.getUser(this.artistProfile.user.id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDashboardData() {
      Axios.get(`/api/v1/artist_profiles/${this.$route.params.artistProfileId}/artist_dashboards`, {
      })
        .then((response) => {
          this.artistDashboardData = response.data;
          this.getTopLocations(this.artistDashboardData.top_three_states);
          
          const venueRequestChartLabels = [];
          for (let i = 0; i < this.artistDashboardData.venue_counts_and_data.length; i++) {
            venueRequestChartLabels.push(this.artistDashboardData.venue_counts_and_data[i].name);
          }

          const venueRequestChartSeries = [];
          for (let i = 0; i < this.artistDashboardData.venue_counts_and_data.length; i++) {
            venueRequestChartSeries.push(this.artistDashboardData.venue_counts_and_data[i].count);
          }

          this.venueOptions = { labels: venueRequestChartLabels };
          this.venueSeries = venueRequestChartSeries;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTopLocations(locationObj) {
      const keys = Object.keys(locationObj);
      for (let i = 0; i < keys.length; i++) {
        this.topLocations.push(keys[i]);
      }
    },
  }
}
</script>
  
<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.dashboard-venue-requests {
  margin: 0 75px;

  //https://github.com/mercs600/vue2-perfect-scrollbar
  .ps {
    max-height: 40vh;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
    color: white;
    // border: 1px solid white;
  }

  .back-container__icon-container {
    margin-right: .5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .dashboard-venue-requests-grid-container {
    // border: 1px solid white;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
  }

  .dashboard-venue-requests-grid-container__graph-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .venues-table-wrap__headings-container {
    display: flex;
  }

  .headings-container__heading {
    text-transform: uppercase;
    color: $atmo-gray--light--faded;
    font-weight: 600;
    font-size: 1rem;
    flex: 1;
    flex-basis: 0;
    padding: 1rem .5rem 1rem .5rem;
  }

  .dashboard-venue-requests-grid-container__venues-container {}

  .venues-container__venues-table-wrap {
    margin-bottom: 1rem;
  }

  .--light {
    background: linear-gradient(to right, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0))
  }

  .venues-table-wrap__row {
    display: flex;
    font-weight: 500;
    padding: .8rem 1rem .8rem 1rem;
    border-radius: 25px 0px 0px 25px;

    .row__venue-name {
      flex: 1;
      flex-basis: 0;
      margin: 0rem 1rem 0rem 0rem;
    }

    .row__venue-location {
      flex: 1;
      flex-basis: 0;
    }

    .row__venue-requests {
      flex: 1;
      flex-basis: 0;
    }
  }
}
</style>