<template>
  <div class="atmo-steps__divider">
    <div
      class="atmo-steps__divider-fill"
      :class="{ 'atmo-steps__divider-fill--active': active }"
    />
  </div>
</template>

<script>
  export default {
    props: {
      active: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
