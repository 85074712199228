<template>
  <div class="atmo-music-charts">
    <!-- <div class="atmo-music-charts__chart-selects--featured">
      <atmo-card v-for="featuredChart in featuredCharts" :key="featuredChart.id" component-type="router-link"
        :to="`charts/${featuredChart.id}`" class="atmo-music-charts__chart-select--featured"
        :with-background-gradient="true" :background-image-url="getImgUrl(featuredChart.imageName)">
        <span class="atmo-music-items__card-label">
          {{ featuredChart.label }}
        </span>
      </atmo-card>
    </div> -->
    <div class="atmo-music-charts__charts-grid">
      <router-link :to="`charts/${chart.sanitizedChartType}`" class="charts-grid__chart-card"
        v-for="(chart, index) in nonGenreCharts" :key="index"
        :style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' }">
        <div class="chart-card__title-wrap">{{ chart.chart_type }}</div>
        <div class="chart-card__icon"><img :src="require(`@/assets/images/icons/charts/${returnNonGenreChartIcon(chart.chart_type)}`)" /></div>
      </router-link>
    </div>
    <atmo-music-charts-grid :charts="genreCharts" type="genre" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PaginationMixin from '@/mixins/pagination';
import atmoMusicChartsGrid from '@/components/music/atmo-music-charts-grid.vue';

export default {
  components: { atmoMusicChartsGrid },

  mixins: [PaginationMixin],

  data() {
    return {
      pageSize: 10,
    }
  },
  computed: {
    ...mapState('charts', ['nonGenreCharts', 'genreCharts']),
    yearForRecent() {
      return new Date().getFullYear();
    }
  },
  created() {
    this.getCharts();
  },
  methods: {
    ...mapActions('charts', [
      'fetchTopVotedChart',
      'fetchTopYearChart',
      'fetchMostPlayedChart',
      'fetchMostPlayedGenreCharts',
      'fetchTopVotedMoodCharts'
    ]),
    returnNonGenreChartIcon(chartName) {
      if (chartName === 'Most Played') {
        return 'played.png';
      } else if (chartName === 'Top Voted') {
        return 'voted.png';
      } else if (chartName === `Top Year ${this.yearForRecent}`) {
        return 'recent.png';
      }
    },
    async getCharts() {
      try {
        await this.fetchTopVotedChart();
        await this.fetchTopYearChart(this.yearForRecent);
        await this.fetchMostPlayedChart();
        await this.fetchMostPlayedGenreCharts();
        // await this.fetchTopVotedMoodCharts();
      } catch (error) {
        console.error('Failed to fetch charts:', error);
      }
    },

    getImgUrl(image) {
      return require(`@/assets/images/${image}`);
    },
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-music-charts {
  display: flex;
  flex-direction: column;

  $button-gap: 5px;

  &__chart-selects--featured {
    display: flex;
    margin: 0 -$button-gap;
  }

  &__chart-select--featured {
    flex: 1;
    margin: 0 $button-gap;
    height: 76px;

    .atmo-card__background-image {
      background-color: transparent;
      background-size: 60px;
      background-position: left bottom;
    }
  }

  .atmo-card {
    &:hover {
      box-shadow: 1px 1px 1px 0px rgba(black, 0.5);

      .atmo-card__background-image {
        opacity: 1;
      }
    }

    &:active {
      box-shadow: none;
    }
  }

  .atmo-music-charts__charts-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin-top: 10px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 1rem;

    .charts-grid__chart-card {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 8rem;
      border-radius: 5px;
      background-color: black;
      position: relative;
    }

    .chart-card__title-wrap {
      font-weight: 800;
      font-size: 1.3rem;
      text-transform: uppercase;
    }

    .chart-card__icon {
      position: absolute;
      bottom: -3px;
      left: 0;

      img {
        height: 5rem;
        width: auto;
        opacity: 0.3;
      }
    }
  }

  .atmo-music-charts__genres-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1rem;
    margin-top: 10px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 1rem;

    .genres-grid__genre-card {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 10rem;
      border-radius: 5px;
    }

    .genre-card__title-wrap {
      font-weight: 800;
      font-size: 1.3rem;
    }
  }
}
</style>
