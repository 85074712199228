<template>
  <component
    :is="componentType"
    :to="to"
    class="atmo-breadcrumbs"
    @click="onClick()"
  >
    <img src="@/assets/images/icons/back.png">
    <span
      v-for="breadcrumb in breadcrumbs"
      :key="breadcrumb"
    >
      {{ breadcrumb }}
    </span>
  </component>
</template>

<script>
  export default {
    props: {
      breadcrumbs: {
        type: Array,
        required: true
      },
      onClick: {
        type: Function,
        default: null
      },
      componentType: {
        type: String,
        default: 'div'
      },
      // Must pass in "to" if using as a router-link
      to: {
        type: [String, Object],
        default: null
      }
    }
  }
</script>

<style lang="scss">
  .atmo-breadcrumbs {
    display: flex;
    color: white;
    font-size: 1.1em;
    font-weight: 500;
    cursor: pointer;
    align-items: center;

    img {
      height: 17px;
      margin-right: 10px;
    }
    span {
      line-height: 2;
    }
  }
</style>
