<template>
  <div
    class="atmo-steps__step"
    :class="{
      'atmo-steps__step--active': active,
      'atmo-steps__step--complete': complete
    }"
  >
    <div class="atmo-steps__step-circle">
      <span class="atmo-steps__step-checkmark">
        ✔
      </span>
    </div>
    <div class="atmo-steps__step-label">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      active: {
        type: Boolean,
        default: false
      },
      complete: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
