<template>
    <div class="new-advertisement" data-cy="new-advertisements">
        <div class="header-container">
            <h2 class="atmo-page-header">
                Edit Ad
            </h2>
        </div>

        <div class="new-advertisement-grid-container">
            <div class="new-advertisement-grid-container__campaign-info-container">
                <div class="field-group">
                    <div class="field-wrap">
                        <div class="column-title">Ads Preview</div>
                        <div class="upload-image-video-container">
                            <div class="upload-image-video-container__text-wrap">
                                Upload Image/Video
                            </div>
                        </div>
                    </div>
                    <div class="field-wrap">
                        <label for="button-label">Duration</label>
                        <div class="light-background">
                            <fieldset class="radio-group" id="ad-duration">
                                <div class="radio-field-wrap">
                                    <input class="radio-field" type="radio" name="continuous" value="option2">
                                    <label class="radio-field-label" for="continuous">Run ads continuously until
                                        paused</label>
                                </div>
                                <div class="radio-field-wrap">
                                    <input class="radio-field" type="radio" name="specific-duration" value="option3">
                                    <label class="radio-field-label" for="specific-duration">Choose a specific duration
                                        (From - To)</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <div class="field-wrap">
                        <div class="column-title">Budget</div>
                        <div class="light-background">
                            <fieldset class="radio-group" id="ad-duration">
                                <div class="radio-field-wrap">
                                    <input class="radio-field" type="radio" name="daily" value="option2">
                                    <label class="radio-field-label" for="daily">Daily budget</label>
                                </div>
                                <div class="radio-field-wrap">
                                    <input class="radio-field" type="radio" name="total" value="option3">
                                    <label class="radio-field-label" for="total">Total budget</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
            <div class="new-advertisement-grid-container__ad-info-container">
                <div class="field-group">
                    <div class="field-wrap">
                        <label for="ad-title">Ad Title</label>
                        <input v-model="adTitle" class="text-field" type="text" id="ad-title" name="ad-title"
                            placeholder="Ad Title">
                    </div>
                    <div class="field-wrap">
                        <label for="button-label">Button Label</label>
                        <input v-model="adButtonLabel" class="text-field" type="text" id="button-label" name="button-label"
                            placeholder="See details">
                    </div>
                    <div class="field-wrap">
                        <label for="ad-link">Ad Link</label>
                        <input v-model="adLink" class="text-field" type="text" id="ad-link" name="ad-link"
                            placeholder="Ad link here">
                    </div>
                    <div class="field-wrap">
                        <label for="ad-description">Ad description</label>
                        <textarea v-model="adDescription" class="textarea-field" id="ad-description" name="ad-description"
                            rows="4" cols="50" placeholder="Ad description text here"></textarea>
                    </div>
                </div>

                <div class="ad-info-container__button-wrap">
                    <button class="button-wrap__save-button">Promote Now</button>
                </div>
            </div>
            <div class="new-advertisement-grid-container__ad-preview-container">
                <div class="ad-preview-container__ad-wrap">
                    <div class="ad-wrap__status-wrap">
                        <div class="field-wrap">
                        <label for="ad-status">Ad Status</label>
                        <select class="dropdown-group" id="ad-status" name="ad-status">
                            <option class="dropdown-field" value="paused">Paused</option>
                            <option class="dropdown-field" value="active">Active</option>
                            <option class="dropdown-field" value="completed">Completed</option>
                            <option class="dropdown-field" value="scheduled">Scheduled</option>
                        </select>
                    </div>
                    </div>
                    <div class="column-title">Ads Preview</div>
                    <div class="ad-wrap__card">
                        <div class="card__image-wrap"
                            v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require(`@/assets/images/artists/high-res/${generateRandomString(highResImageArray)}`) + ')' }">
                        </div>
                        <div class="card__text-wrap">
                            <div class="text-wrap__title-wrap">{{ adTitle }}</div>
                            <div class="text-wrap__body-wrap">{{ adDescription }}</div>
                        </div>
                        <div class="text-wrap__button-popover-wrap">
                            <div class="button-popover-wrap__button-wrap">
                                <a class="button-wrap__button" :href="adLink" target="_blank">
                                    {{ adButtonLabel || 'See details' }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ad-preview-container__budget-info-container">
                    <div class="budget-info-container__text-wrap">
                        <div class="text-wrap__text">Ads will run from Nov 12, 2020 to Dec 12, 2020</div>
                    </div>
                    <div class="budget-info-container__budget-wrap">
                        <div class="budget-button-container__budget-wrap">$100 USD</div>
                        <div class="budget-button-container__budget-type-wrap">Daily Budget</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Axios from 'axios'
import devMockData from '@/devUtils/mockData.js';

export default {
    data: function () {
        return {
            advertisements: [],
            highResImageArray: devMockData.highResImageArray,
            adDescriptionArray: devMockData.adDescriptionArray,
            adHeadlineArray: devMockData.adHeadlineArray,
            adTitle: "",
            adButtonLabel: "",
            adLink: "",
            adDescription: "",
        }
    },
    created() {
        this.getAdvertisements();
    },
    methods: {
        getAdvertisements() {
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}/advertisements`)
                .then(response => (this.advertisements = response.data));
        }
    }
}
</script>
  
<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.new-advertisement {
    margin: 0 75px;

    input[type="radio"] {
        position: relative;
        width: 1.2em;
        height: 1.2em;
        color: #363839;
        border-radius: 100%;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        background: $atmo-purple--dark;

        &::before {
            position: absolute;
            content: '';
            display: block;
            transform: rotate(45deg);
            opacity: 0;
        }

        &:checked {
            color: $atmo-purple--dark;
            border: 2px solid $atmo-purple--dark;
            background: $atmo-pink--medium;

            &::before {
                opacity: 1;
            }

            ~label::before {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }
    }

    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .new-advertisement-grid-container {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(3, 1fr);
    }

    .column-title {
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .new-advertisement-grid-container__campaign-info-container {}

    .new-advertisement-grid-container__ad-info-container {}

    .field-group {
        margin-bottom: 1rem;
    }

    .field-wrap+.field-wrap {
        margin-top: 1rem;
    }

    .field-wrap {
        display: flex;
        flex-direction: column;

        label {
            text-transform: uppercase;
            margin-bottom: 1rem;
            font-size: .9rem;
            font-weight: 500;
        }

        .text-field {
            background: transparent;
            border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, .4);
            padding: .5rem .5rem .5rem .5rem;
            color: white;
        }

        .textarea-field {
            background: transparent;
            border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, .4);
            padding: .5rem .5rem .5rem .5rem;
            color: white;
            font-family: 'Roboto', sans-serif;
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(255, 255, 255, .4);
            opacity: 1;
            /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(255, 255, 255, .4);
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(255, 255, 255, .4);
        }

        .dropdown-group {
            background: $atmo-purple--medium-dark;
            color: white;
            border: none;
            border-radius: 5px;
            padding: .5rem;
            font-weight: 500rem;
            cursor: pointer;
        }

        .dropdown-field {}

        .radio-group {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: .6rem 0rem .6rem 0rem;
        }

        .radio-field-wrap {
            margin-right: 1rem;
            display: flex;
            align-items: center;
        }

        .radio-field-wrap+.radio-field-wrap {
            margin-top: 1rem;
        }

        .radio-field {
            margin-right: .5rem;
            cursor: pointer;
        }

        .radio-field-label {
            margin: 2px 0px 0px 0px;
            text-transform: none;
        }
    }

    .upload-image-video-container {
        border: 1px dashed rgba(255, 255, 255, .4);
        border-radius: 5px;
        padding: 1rem;
        cursor: pointer;
    }

    .upload-image-video-container__text-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 500;
        color: rgba(255, 255, 255, .4);
    }

    .button-wrap__save-button {
        font-size: 1rem;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
    }

    .new-advertisement-grid-container__ad-preview-container {
        // border: 1px solid white;
    }

    .ad-wrap__card {
        background: rgba(255, 255, 255, 0.1);
        min-height: 15rem;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 1rem;
        border-radius: 5px;
    }

    .card__image-wrap {
        height: 7rem;
        margin-bottom: 1rem;
        position: relative;
    }

    .card__text-wrap {
        // display: flex;
        // flex-direction: column;
        // flex: 1;
        // justify-content: space-between;
        // margin-top: 1rem;
    }

    .text-wrap__title-wrap {
        font-weight: 600;
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }

    .text-wrap__body-wrap {
        margin-bottom: 1rem;
    }

    .button-wrap__button {
        display: inline-block;
        color: white;
        padding: .5rem .5rem .5rem .5rem;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        border: 1px solid white;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
    }

    .ad-preview-container__ad-wrap {
        margin-bottom: 1rem;
    }

    .ad-preview-container__budget-info-container {
        background: rgba(255, 255, 255, 0.1);
        padding: 1rem;
        border-radius: 5px;
    }

    .text-wrap__text {
        font-size: .9rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .budget-info-container__budget-wrap {
        display: flex;
        gap: 1rem;
    }

    .budget-button-container__budget-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        font-weight: 500;
    }

    .budget-button-container__budget-type-wrap {
        margin-left: auto;
        background-color: rgba(96, 72, 117, 0.4);
        border-radius: 5px;
        padding: .5rem 1rem .5rem 1rem;
        text-transform: uppercase;
        font-weight: 500;
    }

    .light-background {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 5px;
        padding: .5rem;
    }

    .ad-wrap__status-wrap {
        margin-bottom: 1rem;
    }
}
</style>