<template>
    <div class="user-profile-grid__user-details-container">
        <div v-if="user.images[0]" class="user-details-container__background-image-container"
            :style="backgroundImageStyle">
        </div>
        <div class="user-details-container__info-container">
            <div class="info-container__profile-pic-container">
                <img v-if="user.images[0]" class="profile-pic-container__pic"
                    :src="getFeaturedImage(user.images, 'full')" @click="openUserMediaModal({
            type: 'Image',
            caption: user.images[0].name,
            url: user.images[0].image.url,
            id: user.images[0].id
        })" />
            </div>
            <div class="info-container__user-info-wrap">
                <div v-if="user.name" class="user-info-wrap__name-wrap">{{ user.name }}</div>
                <div v-if="user.user_address" class="user-info-wrap__location-wrap">
                    <img class="info-icon" src="@/assets/images/icons/profile/pin.png" />
                    {{ user.user_address.city }}, {{ user.user_address.state }}, {{ user.user_address.country }}
                </div>
                <div v-if="profile.birthday" class="user-info-wrap__age-wrap">
                    <img class="info-icon" src="@/assets/images/icons/profile/birthday.png" />
                    {{ calculateAge(new Date(profile.birthday)) }} years old
                </div>
                <div v-if="profile.gender" class="user-info-wrap__gender-wrap">
                    <img class="info-icon" src="@/assets/images/icons/profile/gender.png" />
                    {{ profile.gender }}
                </div>
            </div>
            <FriendButton :user="user" />
        </div>
    </div>
</template>

<script>
import { getFeaturedImage } from '@/helpers/utilityFunctions';
import FriendButton from '@/components/profiles/friend-button.vue'; // Adjust the path as necessary

export default {
    props: {
        user: Object,
        profile: Object,
    },
    components: {
        FriendButton
    },
    computed: {
        currentUser() {
            return this.$store.state.user;
        },
        isMyProfile() {
            return this.$store.state.currentUserId == this.user.id;
        },
        userProfileId() {
            return Number(this.$route.params.userProfileId);
        },
        backgroundImageStyle() {
            return {
                'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' +
                    ',' + `url(${this.getFeaturedImage(this.user.images, 'full')})`
            };
        }
    },
    methods: {
        getFeaturedImage,
        openUserMediaModal(userMediaObj) {
            this.$emit('openUserMediaModal', userMediaObj);
        },
        calculateAge(birthdate) {
            const now = new Date();
            const diff = now.getTime() - birthdate.getTime();
            const ageDate = new Date(diff);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        }
    }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.user-profile-grid__user-details-container {
    background-image: linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%);
    border-radius: 10px;
}

.user-details-container__background-image-container {
    height: 10rem;
    border-radius: 10px 10px 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.user-details-container__info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 1rem;
}

.info-container__profile-pic-container {
    height: 10rem;
    width: 10rem;
    border-radius: 100%;
    position: absolute;
    top: -5rem;
}

.profile-pic-container__pic {
    height: 10rem;
    width: 10rem;
    border-radius: 100%;
    object-fit: cover;
    box-shadow: 0 0 1px 13px rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.info-container__user-info-wrap {
    margin-top: 6rem;
    margin-bottom: 1rem;
}

.user-info-wrap__name-wrap {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: .5rem;
    text-align: center;
}

.user-info-wrap__location-wrap {
    font-weight: 500;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
}

.user-info-wrap__age-wrap {
    font-weight: 500;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
}

.user-info-wrap__gender-wrap {
    font-weight: 500;
    margin-bottom: .5rem;
    display: flex;
    align-items: center;
}

.info-icon {
    height: 1rem;
    width: auto;
    margin-right: .5rem;
}
</style>
