import Axios from 'axios';

// Fetch song plays for a specific song.
// If `user_id` is provided in queryParams, it will show UserSongPlayCount for the user.
export function callSongPlaysIndex(songId, queryParams = {}) {
  return Axios.get(`/api/v1/songs/${songId}/song_plays`, { params: queryParams })
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

// Create a new SongPlay and optionally a UserSongPlay for a given song.
export function callSongPlaysCreate(songId, requestData = {}) {
  return Axios.post(`/api/v1/songs/${songId}/song_plays`, requestData)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}
