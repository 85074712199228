<template>
    <div class="venue-request" data-cy="venue-request">
        <div class="header-container">
            <h2 class="atmo-page-header">
                Venue Request
            </h2>
        </div>
        <atmo-back-link></atmo-back-link>
        <div class="venue-request-container">
            <div class="venue-request-container__image-wrap"
                v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require(`@/assets/images/artists/high-res/${generateRandomString(highResImageArray)}`) + ')' }">
                <div class="image-wrap__text-container">
                    <div class="text-container__text-wrap">
                        <div class="text-wrap__header">Want (artist) to play at a venue near you?</div>
                        <div class="text-container__text">
                            Select a venue near you, if there is enough demand for a show in your area, you will get to see
                            your favorite artist close to home!
                        </div>
                    </div>
                </div>
            </div>
            <div class="venue-request-container__form-wrap">
                <div class="dropdown-fields-container">
                    <div class="field-wrap">
                        <label for="country">State</label>
                        <select class="dropdown-group" id="country" name="country">
                            <option class="dropdown-field" value="united-states">United States</option>
                            <option class="dropdown-field" value="france">France</option>
                            <option class="dropdown-field" value="canada">Canada</option>
                            <option class="dropdown-field" value="mexico">Mexico</option>
                        </select>
                    </div>
                    <div class="field-wrap">
                        <label for="state">City</label>
                        <select class="dropdown-group" id="state" name="state">
                            <option class="dropdown-field" value="texas">Texas</option>
                            <option class="dropdown-field" value="virginia">Virginia</option>
                            <option class="dropdown-field" value="massachusetts">Massachusetts</option>
                            <option class="dropdown-field" value="mexico">Mexico</option>
                        </select>
                    </div>
                    <div class="field-wrap">
                        <label for="state">Venue</label>
                        <select class="dropdown-group" id="state" name="state">
                            <option class="dropdown-field" value="texas">Texas</option>
                            <option class="dropdown-field" value="virginia">Virginia</option>
                            <option class="dropdown-field" value="massachusetts">Massachusetts</option>
                            <option class="dropdown-field" value="mexico">Mexico</option>
                        </select>
                    </div>
                </div>
                <div class="form-wrap__buttons-container">
                    <button class="buttons-container__submit">Submit</button>
                    <router-link class="buttons-container__cancel" :to="{ name: 'coming_soon' }">
                        Cancel
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import devMockData from '@/devUtils/mockData.js';
import { callUserAccomplishmentsIndex } from '@/helpers/axiosCalls';
import AtmoBackLink from '@/components/atmo-back-link';

export default {
    components: {
        AtmoBackLink
    },
    data: function () {
        return {
            artistProfileId: this.$route.params.artistProfileId,
            accomplishments: [],
            venueNameArray: devMockData.venueNameArray,
            cityStateArray: devMockData.cityStateArray,
            artistImageArray: devMockData.artistImageArray,
            highResImageArray: devMockData.highResImageArray,
            songTitleArray: devMockData.songTitleArray,
            artistNameArray: devMockData.artistNameArray,
            artistMerchArray: devMockData.artistMerchArray,
            abbreviateStatesArray: devMockData.abbreviatedStatesArray,
        }
    },
    created() {
        this.getAccomplishments();
    },
    methods: {
        getAccomplishments() {
            callUserAccomplishmentsIndex(this.$store.state.currentUserId)
                .then(response => (this.accomplishments = response));
        }
    }
}
</script>
    
<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.venue-request {
    margin: 0 75px;

    //https://github.com/mercs600/vue2-perfect-scrollbar
    .ps {
        max-height: 40vh;
    }

    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .back-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        max-width: 10rem;
        margin-bottom: 1rem;
        color: white;
        // border: 1px solid white;
    }

    .back-container__icon-container {
        margin-right: .5rem;
    }

    .icon-container__back-icon {
        height: 1rem;
    }

    .venue-request-container {}

    .venue-request-container__image-wrap {
        height: 30vh;
        // display: flex;
        // flex-direction: column;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        margin-bottom: 2rem;
        border-radius: 5px;
    }

    .image-wrap__text-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
        border-radius: 5px;
    }

    .text-container__text-wrap {
        max-width: 40%;
    }

    .text-wrap__header {
        font-size: 1.4rem;
        font-weight: 600;
        color: white;
        margin-bottom: .5rem;
        text-transform: uppercase;
    }

    .text-wrap__text {
        font-size: 1rem;
        color: $atmo-gray--light--faded;
    }

    .venue-request-container__form-wrap {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .dropdown-fields-container {
        display: flex;
        gap: 20px;
    }

    .field-wrap {
        display: flex;
        flex-direction: column;

        label {
            text-transform: uppercase;
            margin-bottom: .5rem;
            font-size: .9rem;
            font-weight: 500;
        }

        .text-field {
            background: transparent;
            border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, .4);
            padding: .5rem .5rem .5rem .5rem;
            color: white;
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(255, 255, 255, .4);
            opacity: 1;
            /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(255, 255, 255, .4);
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(255, 255, 255, .4);
        }

        .dropdown-group {
            background: $atmo-purple--medium-dark;
            color: white;
            text-transform: uppercase;
            border: none;
            border-radius: 5px;
            padding: .5rem;
            font-weight: 500rem;
            cursor: pointer;
        }

        .dropdown-field {}
    }

    .form-wrap__buttons-container {
        display: flex;
        justify-content: center;
        align-items: end;
        margin-left: auto;
    }

    .buttons-container__submit {
        background: white;
        color: $atmo-purple--dark;
        border: none;
        border-radius: 5px;
        padding: .5rem 1rem .5rem 1rem;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
    }

    .buttons-container__cancel {
        background: transparent;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid white;
        padding: .5rem 1rem .5rem 1rem;
        font-weight: 600;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>