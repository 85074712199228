<template>
    <router-link :to="userProfileLink" class="user-link">
        <slot></slot>
    </router-link>
</template>
  
<script>
export default {
    props: {
        profileId: Number,
        profileType: String,
    },
    computed: {
        userProfileLink() {
            if (this.profileType == "UserProfile") {
                return { name: 'user_profiles.show', params: { userProfileId: this.profileId } };
            } else if (this.profileType == "ArtistProfile") {
                return { name: 'artist_profiles.show', params: { artistProfileId: this.profileId } };
            } else if (this.profileType == "LabelProfile") {
                return { name: 'label_profiles.show', params: { labelProfileId: this.profileId } };
            } else {
                return null;
            }
        },
    },
};
</script>