<template>
  <div class="atmo-store__menu">
    <router-link v-for="product in filteredProducts" :key="product.id"
      :to="{ name: 'products.show', params: { productId: product.id } }"
      class="product-card atmo-store__menu-item product-card--clickable" tag="div">
      <div class="product-card__background-image-container product-card__image-container">
        <div class="product-card__link-icon-container">
          <img :src="require('@/assets/images/icons/store/link.png')" alt="Click for details"
            class="product-card__link-icon">
        </div>
        <img :src="getImageUrl(product)" class="product-card__image">
        <div class="image-container__overlay"></div>
      </div>
      <div class="product-card__body"></div>
      <div class="product-card__footer">
        <div class="atmo-store__menu-item-footer">
          <div class="atmo-store__menu-item-footer-main">
            <div class="atmo-store__menu-item-info">
              <div class="atmo-store__menu-item-title">{{ product.title }}</div>
              <div class="atmo-store__menu-item-category">{{ product.product_category.name }}</div>
            </div>
            <div class="atmo-store__menu-item-price">{{ returnCurrencyFormat(product.product_variants[0].price) }}</div>
          </div>
          <div class="atmo-store__menu-item-description-container">
            <div class="atmo-store__menu-item-description">{{ product.description }}</div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { formatPriceAsCurrency } from '@/helpers/utilityFunctions';

export default {
  props: {
    products: { type: Array, default: null },
    isFiltered: { type: Boolean, default: false },
    userId: { type: [String, Number], default: null }
  },
  computed: {
    filteredProducts() {
      if (this.isFiltered && this.userId) {
        const userIdString = this.userId.toString();
        console.log('Filtering products for userId:', userIdString);
        const filtered = this.products.filter(product => {
          console.log(`Product ID: ${product.id}, Product User ID: ${product.user_id}`);
          return product.user_id.toString() === userIdString;
        });
        console.log('Filtered products:', filtered);
        return filtered;
      }
      return this.products;
    }
  },
  methods: {
    returnCurrencyFormat(number) {
      return formatPriceAsCurrency(number);
    },
    getImageUrl(product) {
      return product.images && product.images[0] && product.images[0].image ? product.images[0].image.url : '';
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.product-card {
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px 5px 0px 0px;
  }

  &--clickable {
    cursor: pointer;
  }

  &--with-background-gradient {
    background-image: linear-gradient(-270deg,
        rgba($atmo-blue--medium, 0.5) 0%,
        rgba($atmo-pink--medium, 0.5) 100%);
  }

  &__background-image-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__background-image {
    z-index: -1;
    opacity: 0.7;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($atmo-purple--light, 0.4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 5px 5px 0 0;
    height: 100%;
    margin: auto;
  }

  &:hover .product-card__link-icon-container {
    visibility: visible;
    background-color: rgba($atmo-purple--dark, 0.7);
  }

  &:hover .product-card__link-icon {
    opacity: 1;
  }

  &__image-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($atmo-purple--light, 0.4);
    border-radius: 5px 5px 0 0;
    position: relative;
  }

  .image-container__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    font-weight: bold;
    text-align: right;
    font-size: 30px;
    border-radius: 5px 5px 0px 0px;
    background-image: linear-gradient(-270deg,
        rgba($atmo-blue--medium, 0.5) 0%,
        rgba($atmo-pink--medium, 0.5) 100%);
  }

  &__link-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($atmo-purple--dark, 0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color 0.2s ease-in;
    visibility: hidden;
    border-radius: inherit;
  }

  &__link-icon {
    height: 15%;
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  &__body {
    font-size: 0.9em;
  }

}
</style>
