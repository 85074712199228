var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shared-post-container"},[_c('div',{staticClass:"shared-post-container__post-container"},[_c('div',{staticClass:"shared-post-container__main-content-container"},[_c('div',{staticClass:"shared-post-container__post-header"},[_c('div',{staticClass:"atmo-post__post-identifier"},[_c('span',{staticClass:"atmo-post__post-owner"},[_c('a',[_vm._v(_vm._s(_vm.sharedPost.user.name))])]),_c('span',{staticClass:"atmo-post__post-date"},[_vm._v(" "+_vm._s(_vm.getDate(_vm.sharedPost.created_at))+" ")]),_c('div',{staticClass:"atmo-post__post-public"},[(_vm.sharedPost.is_public)?_c('img',{attrs:{"src":require("@/assets/images/icons/posts/public.png"),"alt":"public playlist"}}):_c('img',{attrs:{"src":require("@/assets/images/icons/posts/private.png"),"alt":"private playlist"}})])])]),_c('div',{staticClass:"shared-post-container__post-content"},[(_vm.sharedPost.images.length > 0)?_c('div',{staticClass:"post-content__post-pics-container"},_vm._l((_vm.sharedPost.images),function(postImage,index){return _c('img',{key:index,staticClass:"post-pics-container__post-pic",attrs:{"src":postImage.image.url,"alt":"Post pic"},on:{"click":function($event){return _vm.openUserMediaModal({
              type: 'Image',
              caption: _vm.sharedPost.content,
              url: postImage.image.url,
              id: postImage.id
            })}}})}),0):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatPost(_vm.sharedPost.content))}})]),(_vm.sharedPost.song_references.length > 0)?_c('div',_vm._l((_vm.sharedPost.song_references),function(songReference,index){return _c('div',{key:index,staticClass:"shared-post-container__post-footer"},[_c('div',{staticClass:"shared-post-container__song-actions-popover-container"},[_c('song-actions-popover',{attrs:{"song":_vm.song,"placement":"left","actions":[
              'add-to-library',
              'add-to-queue',
              'add-to-playlist'
            ]}})],1),_c('span',{staticClass:"shared-post-container__song-link"},[_vm._v(" "+_vm._s(songReference.song.name)+" ")])])}),0):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }