import Axios from 'axios';

export const setDefaultAxiosAuth = (token) => {
  Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

export const setDefaultAxiosHeaders = (token) => {
  if (token) {
    setDefaultAxiosAuth(token);
  }
  Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; // this does nothing :(
  Axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET'; // this does nothing :(
}
