import Axios from 'axios';

export function callChatMessagesIndex(chatRoomId, queryParams = {}) {
    return Axios.get(`/api/v1/chat_rooms/${chatRoomId}/chat_messages`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callChatMessagesCreate(chatRoomId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/chat_rooms/${chatRoomId}/chat_messages`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
