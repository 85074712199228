var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"album-card album-card--clickable"},[_c('div',{staticClass:"album-card__background-image-container album-card__image-container"},[_c('div',{staticClass:"album-card__background-image",style:({ 'background-image': `url(${_vm.album.image.url})` })}),_c('router-link',{attrs:{"to":{
        name: 'albums.show',
        params: {
          artistProfileId: _vm.album.primary_artist.id,
          albumId: _vm.album.id,
        },
      }}},[_c('div',{staticClass:"album-card__link-icon-container"},[_c('img',{staticClass:"album-card__link-icon",attrs:{"src":require('@/assets/images/icons/store/link.png'),"alt":"Click for details"}})])]),_c('div',{staticClass:"album-card__upper-right"},[_c('div',{staticClass:"album-card__album-year"},[_c('span',[_vm._v(_vm._s(_vm.albumReleaseDate))])])])],1),_c('div',{staticClass:"album-card__body"}),_c('div',{staticClass:"album-card__footer"},[_c('div',{staticClass:"album-card__album-footer"},[_c('div',{staticClass:"album-card__album-info"},[_c('div',{staticClass:"album-card__album-name"},[_c('span',[_vm._v(_vm._s(_vm.album.name))])]),_c('div',{staticClass:"album-card__album-genre"},[_c('span',[_vm._v(_vm._s(_vm.album.album_profile.primary_genre && _vm.album.album_profile.primary_genre.name))])])]),_c('div',{staticClass:"album-card__album-length"},[_c('img',{staticClass:"album-card__album-length-icon",attrs:{"src":require('@/assets/images/icons/clock.png')}}),_vm._v(" "+_vm._s(_vm.album.length)+" min")])]),_c('atmo-voting-buttons',{attrs:{"entity":_vm.album,"voteableType":"Album"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }