var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"atmo-contest-list-item"},[_c('router-link',{staticClass:"atmo-contest-list-item__image",style:({ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${_vm.contest.image.url})` }),attrs:{"to":{
        name: 'contests.show',
        params: { contestId: _vm.contest.id }
    }}}),_c('div',{staticClass:"atmo-contest-list-item__content-wrap"},[_c('div',{staticClass:"content-wrap__title-description-wrap"},[_c('div',{staticClass:"title-description-wrap__title-edit-popover"},[_c('router-link',{staticClass:"title-edit-popover__title",attrs:{"to":{
                    name: 'contests.show',
                    params: { contestId: _vm.contest.id }
                }}},[_vm._v(" "+_vm._s(_vm.contest.name)+" ")]),(_vm.$store.state.currentUserId === _vm.contest.user_id)?_c('atmo-popover',{attrs:{"placement":"bottom"}},[_c('img',{staticClass:"contest-trigger",attrs:{"slot":"trigger","src":require("@/assets/images/icons/kebab_menu.png"),"alt":"actions"},slot:"trigger"}),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('router-link',{staticClass:"atmo-popover__dropdown-action",attrs:{"to":{ name: 'contests.edit', params: { contestId: _vm.contest.id } }}},[_c('div',{staticClass:"atmo-popover__dropdown-action-image-container"},[_c('img',{staticClass:"atmo-popover__dropdown-action-image",attrs:{"src":require("@/assets/images/icons/edit.png"),"alt":"Edit contest"}})]),_vm._v(" Edit contest ")]),_c('a',{staticClass:"atmo-popover__dropdown-action",on:{"click":function($event){return _vm.deleteContest(_vm.contest)}}},[_c('div',{staticClass:"atmo-popover__dropdown-action-image-container"},[_c('img',{staticClass:"atmo-popover__dropdown-action-image",attrs:{"src":require("@/assets/images/icons/delete.png"),"alt":"Delete contest"}})]),_vm._v(" Delete contest ")])],1)]):_vm._e()],1),_c('div',{staticClass:"title-description-wrap__description"},[_vm._v(" "+_vm._s(_vm.contest.description)+" ")])]),_c('div',{staticClass:"content-wrap__author-date-wrap"},[_c('div',{staticClass:"author-date-wrap__author-wrap"},[_c('img',{staticClass:"atmo-avatar atmo-avatar--extra-small",style:({ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${_vm.getFeaturedImage(_vm.contest.user.images, 'full')})` })}),_c('div',{},[_vm._v(" "+_vm._s(_vm.artist.name)+" ")])]),_c('div',{staticClass:"author-date-wrap__date-wrap"},[_vm._v(" "+_vm._s(_vm.timeLeft)+" Left ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }