<template>
  <div class="atmo-countdown">
    <div v-for="unit in units" :key="unit.label" class="atmo-countdown__unit">
      <!-- leading zero (not supported by odometer, so we have to fake it) -->
      <IOdometer :value="0" class="atmo-countdown__odometer atmo-countdown__odometer--leading-zero" theme="train-station"
        format="d" />
      <vue-odometer :value="unit.value" class="atmo-countdown__odometer" animation="" :duration="1000"
        theme="train-station" format="dd" />
      <IOdometer :value="unit.value" class="atmo-countdown__odometer" animation="" :duration="1000" theme="train-station"
        format="dd" />
      <label class="atmo-countdown__label">
        {{ unit.label }}
      </label>
    </div>
  </div>
</template>

<script>
import IOdometer from 'vue-odometer';
import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds
  } from 'date-fns';

  export default {
    components: {
      IOdometer
    },

    props: {
      endTime: {
        type: [Date, String],
        required: true
      }
    },

    data() {
      return {
        now: new Date(),
        start: 33
      }
    },

    computed: {
      num() {
        return Number(this.start)
      },
      units() {
        return [
          {
            label: 'Days',
            value: Math.max(differenceInDays(this.endTime, this.now), 0)
          },
          {
            label: 'Hours',
            value: Math.max(differenceInHours(this.endTime, this.now) % 24, 0)
          },
          {
            label: 'Min',
            value: Math.max(differenceInMinutes(this.endTime, this.now) % 60, 0)
          },
          {
            label: 'Sec',
            value: Math.max(differenceInSeconds(this.endTime, this.now) % 60, 0)
          },
        ]
      }
    },

    created () {
      this.interval = setInterval(() => {
        this.now = new Date();
      }, 1000)
    },

    beforeDestroy() {
      clearInterval(this.interval);
    }
  }
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';
@import 'v-odometer/dist/main.css';

.atmo-countdown {
  display: flex;
  justify-content: center;
  gap: .5rem;
  background-color: rgba($atmo-purple--dark, 0.5);
  border-radius: 7px;
  padding: 1rem;

  &__unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 3px;
    // width: 44px;
    position: relative;
  }

  &__odometer.odometer {
    font-family: 'Roboto', sans-serif;
    font-size: 1.8em;
    font-weight: 600;
    align-self: flex-end;

    &.atmo-countdown__odometer--leading-zero {
      position: absolute;
      left: 1px;
    }

    .odometer-inside {
      line-height: 30px;
    }

    .odometer-digit {
      background-image: linear-gradient(to bottom, white 0%, white 35%, $atmo-purple--light 55%, white 55%, white 100%);
      background-color: white;
      color: $atmo-purple--dark;
    }
  }

  &__label {
    text-transform: uppercase;
    font-size: .9rem;
    // font-weight: 300;
    margin-top: .5rem;
  }


  // https://github.com/HubSpot/odometer/issues/91
  .odometer.odometer-animating-up .odometer-ribbon-inner,
  .odometer.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    -webkit-transition-duration: 1s !important;
    -moz-transition-duration: 1s !important;
    -ms-transition-duration: 1s !important;
    -o-transition-duration: 1s !important;
    transition-duration: 1s !important
  }
}
</style>
