import { getFromLocalStorage, saveToLocalStorage } from '@/helpers/utilityFunctions';
import {
    callProductCategoriesIndex,
    callProductSizeSystemsIndex,
    callProductColorsIndex,
    callProductSizesIndex,
    callMeasurementSystemsIndex,
    callMeasurementUnitsIndex,
    callProductsIndex,
} from '@/helpers/axiosCalls';

export default {
    namespaced: true,
    state: {
        debug: false,
        cart: [],
        cartCost: 0,
        categories: [],
        sizeSystems: [],
        colors: [],
        sizes: [],
        measurementSystems: [],
        measurementUnits: [],
        newProductData: {},
        editProductData: {},
        products: [],
        selectedCategoryId: null,
    },
    mutations: {
        setCategories(state, categories) {
            state.categories = categories;
        },
        setSizeSystems(state, sizeSystems) {
            state.sizeSystems = sizeSystems;
        },
        setColors(state, colors) {
            state.colors = colors;
        },
        setSizes(state, sizes) {
            state.sizes = sizes;
        },
        setMeasurementSystems(state, systems) {
            state.measurementSystems = systems;
        },
        setMeasurementUnits(state, units) {
            state.measurementUnits = units;
        },
        setNewProductData(state, data) {
            state.newProductData = data;
        },
        setEditProductData(state, data) {
            state.editProductData = data;
        },
        getCartFromStorage(state) {
            const cartValue = getFromLocalStorage('shoppingCart')

            if (cartValue) {
                state.cart = cartValue;
            } else {
                console.log('No cart found in storage');
            }
        },
        addToCart(state, productVariant) {
            state.cart.push(productVariant);
            saveToLocalStorage('shoppingCart', state.cart, 60);
        },
        removeFromCart(state, variantIndex) {
            state.cart.splice(variantIndex, 1);
            saveToLocalStorage('shoppingCart', state.cart, 60);
        },
        removeGroupFromCart(state, variant) {
            state.cart = state.cart.filter(item => item.id !== variant.id);
            saveToLocalStorage('shoppingCart', state.cart, 60);
        },
        updateCartCost(state) {
            state.cartCost = state.cart.reduce((acc, curr) => acc + parseFloat(curr.price), 0);
        },
        setProducts(state, products) {
            state.products = products;
        },
        setSelectedCategoryId(state, id) {
            state.selectedCategoryId = id;
        },
    },
    actions: {
        async fetchCategories({ commit }) {
            try {
                const response = await callProductCategoriesIndex();
                console.log('Fetched Categories:', response); // Add this line for debugging
                commit('setCategories', response);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async fetchSizeSystems({ commit }) {
            const response = await callProductSizeSystemsIndex();
            commit('setSizeSystems', response);
        },
        async fetchColors({ commit }) {
            const response = await callProductColorsIndex();
            commit('setColors', response);
        },
        async fetchSizes({ commit }) {
            const response = await callProductSizesIndex();
            commit('setSizes', response);
        },
        async fetchMeasurementSystems({ commit }) {
            const response = await callMeasurementSystemsIndex();
            commit('setMeasurementSystems', response);
        },
        async fetchMeasurementUnits({ commit }) {
            const response = await callMeasurementUnitsIndex();
            commit('setMeasurementUnits', response);
        },
        async fetchProducts({ commit }, params = {}) {
            const response = await callProductsIndex(params);
            commit('setProducts', response);
        },
    },
    getters: {
        getCategoryById: (state) => (id) => {
            return state.categories.find((category) => category.id === id);
        },
        getSizeSystemById: (state) => (id) => {
            return state.sizeSystems.find((sizeSystem) => sizeSystem.id === id);
        },
        getColorById: (state) => (id) => {
            return state.colors.find((color) => color.id === id);
        },
        getSizeById: (state) => (id) => {
            return state.sizes.find((size) => size.id === id);
        },
        getMeasurementSystemById: (state) => (id) => {
            return state.measurementSystems.find((system) => system.id === id);
        },
        getMeasurementUnitById: (state) => (id) => {
            return state.measurementUnits.find((unit) => unit.id === id);
        },
        filteredProducts: (state) => {
            return state.products.filter((product) => {
                if (state.selectedCategoryId === null) {
                    return true;
                } else {
                    return product.product_category_id === state.selectedCategoryId;
                }
            });
        },
        cartCount: (state) => {
            return state.cart.length;
        }
    },
};
