import {
    callProductsUpdate,
    callProductVariantsCreate,
    callProductVariantsUpdate,
    callProductVariantImagesCreateVariantImages,
    callProductVariantImagesUpdateVariantImages,
    callShippingCarrierProductsUpdate,
    callImagesCreate,
    callImagesDelete
} from '@/helpers/axiosCalls';

export async function editProductAndVariants(componentData) {
    const {
        currentUserId,
        editProductData,
        productVariantGroups
    } = componentData;

    const {
        originalProduct,
        editProductTitle,
        editProductDescription,
        existingProductImages,
        newProductImages,
        editProductCategory,
        editProductSizeSystem,
        editProductMeasurementSystem,
        editProductLength,
        editProductWidth,
        editProductHeight,
        editProductWeight,
        editProductDimensionUnit,
        editProductWeightUnit,
    } = editProductData;

    const {
        id,
        title,
        description,
        user_id,
        product_category_id,
        product_size_system_id,
        product_category,
        product_size_system,
        shipping_carrier_product,
        images,
        product_variants
    } = originalProduct;

    console.log("COMPONENT DATA==================", componentData);

    // Step 1: Edit the product if there are changes
    if (
        title !== editProductTitle ||
        description !== editProductDescription ||
        user_id !== currentUserId ||
        product_category_id !== editProductCategory ||
        product_size_system_id !== editProductSizeSystem.id
    ) {
        console.log('Updating product...');
        await callProductsUpdate(id, {
            title: editProductTitle,
            description: editProductDescription,
            user_id: currentUserId,
            product_category_id: editProductCategory,
            product_size_system_id: editProductSizeSystem.id,
        });
        console.log('Product updated successfully.');
    } else {
        console.log('No changes detected in product data.');
    }

    // Step 2: Edit shipping carrier product if there are changes
    if (
        shipping_carrier_product.measurement_system_id !== editProductMeasurementSystem.id ||
        shipping_carrier_product.length !== editProductLength ||
        shipping_carrier_product.width !== editProductWidth ||
        shipping_carrier_product.height !== editProductHeight ||
        shipping_carrier_product.weight !== editProductWeight ||
        shipping_carrier_product.dimension_unit_id !== editProductDimensionUnit.id ||
        shipping_carrier_product.weight_unit_id !== editProductWeightUnit.id
    ) {
        console.log('Updating shipping carrier product...');
        await callShippingCarrierProductsUpdate(shipping_carrier_product.id, {
            product_id: id,
            measurement_system_id: editProductMeasurementSystem.id,
            length: editProductLength,
            width: editProductWidth,
            height: editProductHeight,
            weight: editProductWeight,
            dimension_unit_id: editProductDimensionUnit.id,
            weight_unit_id: editProductWeightUnit.id,
        });
        console.log('Shipping carrier product updated successfully.');
    } else {
        console.log('No changes detected in shipping carrier product data.');
    }

    // Step 3: Flatten and transform variants
    function flattenAndTransformVariants(productVariantGroups, transformFunction) {
        return productVariantGroups.flatMap(group =>
            group.variants.map(variant => transformFunction(variant))
        );
    }

    // Check for existing variant ids
    const updateAttributes = variant => {
        const variantId = variant.id ? variant.id : null;
        return {
            id: variantId,
            product_id: id,
            product_size_id: variant.product_size.id,
            product_color_id: variant.product_color.id,
            quantity: variant.quantity,
            price: variant.price,
        };
    };

    const flattenedAndTransformedVariants = flattenAndTransformVariants(productVariantGroups, updateAttributes);

    const splitVariants = (flattenedAndTransformedVariants) => {
        const newVariants = [];
        const existingVariants = [];

        flattenedAndTransformedVariants.forEach(variant => {
            if (variant.id) {
                existingVariants.push(variant);
            } else {
                newVariants.push(variant);
            }
        });

        return { newVariants, existingVariants };
    };

    const { newVariants, existingVariants } = splitVariants(flattenedAndTransformedVariants);

    // Step 4: Create product variants
    if (newVariants.length > 0) {
        console.log('Creating new product variants...');
        await callProductVariantsCreate({ product_variants: newVariants }, { bulk: true });
        console.log('New product variants created successfully.');
    } else {
        console.log('No new product variants to create.');
    }

    // Step 5: Update product variants
    if (existingVariants.length > 0) {
        console.log('Updating existing product variants...');
        await callProductVariantsUpdate({ product_variants: existingVariants }, { bulk: true });
        console.log('Existing product variants updated successfully.');
    } else {
        console.log('No existing product variants to update.');
    }

    // Step 6: Upload images in bulk
    if (newProductImages.length > 0) {
        console.log('Uploading new images...');
        const bulkUploadImages = (images, createdProductId, currentUserId) => {
            const formData = new FormData();

            images.forEach((image, index) => {
                formData.append(`images[${index}][image]`, image.imageFile);
                formData.append(`images[${index}][name]`, image.imageName);
                formData.append(`images[${index}][imageable_id]`, createdProductId);
                formData.append(`images[${index}][imageable_type]`, "Product");
                formData.append(`images[${index}][is_featured_image]`, false);
                formData.append(`images[${index}][user_id]`, currentUserId);
            });

            // Append the bulk flag
            formData.append('bulk', true);
            // Make the API call
            return callImagesCreate(formData);
        };

        await bulkUploadImages(newProductImages, id, currentUserId);
        console.log('New images uploaded successfully.');
    } else {
        console.log('No new images to upload.');
    }

    // Step 7: Handle product variant images
    const handleVariantImages = async () => {
        const updateVariantImage = async (color, imageName) => {
            console.log(`Updating product variant image for group ${color.name}...`);
            await callProductVariantImagesUpdateVariantImages({
                product_id: id,
                color_id: color.id,
                image_name: imageName,
            });
            console.log(`Product variant image for group ${color.name} updated successfully.`);
        };
    
        const createVariantImage = async (color, imageName) => {
            console.log(`Creating product variant image for group ${color.name}...`);
            await callProductVariantImagesCreateVariantImages({
                product_id: id,
                color_id: color.id,
                image_name: imageName,
            });
            console.log(`Product variant image for group ${color.name} created successfully.`);
        };
    
        for (const group of productVariantGroups) {
            if (group.imageSelected) {
                const { color, variantImage } = group;
                const imageName = variantImage.imageName || variantImage.name;
                const imageExists = existingProductImages.some(img => img.name === imageName);
                const variantExists = product_variants.some(variant => variant.product_color.id === color.id);
    
                if (variantExists) {
                    if (imageExists || !variantImage.id) {
                        await updateVariantImage(color, imageName);
                    } else {
                        await createVariantImage(color, imageName);
                    }
                } else {
                    await createVariantImage(color, imageName);
                }
            }
        }
    };
    
    await handleVariantImages();

    // Step 8: Compare existingProductImages with images and delete if necessary
    if (images.length > 0) {
        console.log('Comparing and deleting images...');
        const compareAndDeleteImages = async (images, existingProductImages) => {
            const existingImageIds = existingProductImages.map(img => img.id);

            images.forEach(async image => {
                if (!existingImageIds.includes(image.id)) {
                    console.log(`Deleting image with ID ${image.id}...`);
                    await callImagesDelete(image.id);
                    console.log(`Image with ID ${image.id} deleted successfully.`);
                }
            });
        };

        await compareAndDeleteImages(images, existingProductImages);
    }

    console.log('Product, variants, images, and associations edited successfully.');
}
