<template>
  <img
    :class="`atmo-toggle atmo-toggle--${size}`"
    :src="imageSrc"
    :alt="imageAlt"
    @click="onChange(on ? false : true)"
  >
</template>

<script>
  export default {
    props: {
      size: {
        type: String,
        default: 'medium'
      },
      on: {
        type: Boolean,
        default: false
      },
      onChange: {
        type: Function,
        default: () => {}
      }
    },

    computed: {
      imageSrc() {
        const modifier = this.on ? 'on' : 'off';
        return require(`@/assets/images/icons/toggle--${modifier}.png`);
      },
      imageAlt() {
        return this.on ? "Turn off" : "Turn on";
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .atmo-toggle {
    cursor: pointer;
    height: 17px;

    &--large {
      height: 20px;
    }
  }
</style>
