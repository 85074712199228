<template>
  <div>
    <h1>Invoices</h1>
    <!-- <p>{{ stripeSubscription.invoices }}</p> -->
    <div
      v-for="invoice in invoices"
      :key="invoice.id"
    >
      <!-- <div ng-repeat="invoice in stripeSubscription.invoices | filter:{lines.data[0].id:'sub_CZFU9vthR2IqCl'}"> --> <!-- SOMETHING LIKE THIS -->
      <p>Invoice ID: {{ invoice.invoice_id }} </p>
      <p>Amount Paid: $ {{ invoice.amount }}</p>
      <p>Customer: {{ invoice.customer_id }}</p>
      <br>
      <br>
    </div>
  </div>
</template>

<script>
  import { callInvoicesIndex } from '@/helpers/axiosCalls';

  export default {
    data: function(){
      return {
        user: {},
        userProfile: {},
        invoices: []
      }
    },
    created(){
        callInvoicesIndex(this.$store.state.currentUserId).then(response => (this.invoices = response))
    },
    methods: {

    }
  }
</script>
