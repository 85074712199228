<template>
    <div :id="id">
        <apexchart :type="type" :width="width" :options="chartOptions" :series="chartSeries"></apexchart>
    </div>
</template>

<script>
//https://apexcharts.com/vue-chart-demos/polar-area-charts/monochrome/
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts
    },
    props: {
        id: { type: String, required: true },
        type: { type: String, required: true },
        width: { type: String, required: true },
        options: { type: Object, required: true },
        series: { type: Array, required: true },
        labels: { type: Array, required: true }
    },
    data: function () {
        return {
            chartOptions: {},
            chartSeries: []
        }
    },
    created() {
        this.testingStuff();
    },
    methods: {
        testingStuff() {
            this.chartOptions = this.options;
            this.chartOptions.labels = this.labels;
            this.chartSeries = this.series;
        },
    }
}
</script>
