<template>
    <div class="atmo-post-show-page">
        <div class="header-container">
            <h2 class="atmo-page-header">Post</h2>
        </div>
        <div class="breadcrumbs-container">
            <atmo-back-link></atmo-back-link>
        </div>
        <perfect-scrollbar>
            <atmo-post :key="post.id" type="billboard" :post="post" :base-url="baseUrl" :user-profile="profile"
                :comments-enabled="true" :comments-expanded="true" :toggle-comments="toggleComments"
                :likes-enabled="true" :maximize-post-enabled="false" />
        </perfect-scrollbar>
    </div>
</template>

<script>
import AtmoPost from '@/components/atmo-posts/atmo-post';
import { mapActions, mapState } from 'vuex';
import AtmoBackLink from '@/components/atmo-back-link';

export default {
    components: {
        AtmoPost,
        AtmoBackLink
    },
    async created() {
        await this.fetchPostData();
    },
    data() {
        return {
            postId: null
        }
    },
    methods: {
        ...mapActions('profiles', ['fetchUserAndProfile']),
        ...mapActions('posts', ['fetchPost']),
        async fetchPostData() {
            this.postId = this.$route.params.postId;

            if (this.postId) {
                await this.fetchPost({ postId: this.postId });
                await this.fetchUserAndProfile(this.post.user.id) // for comments-list component in post
            } else {
                console.error('Post ID or User ID is missing from the route params.');
            }
        },
        toggleComments(post) {
            const postId = post.id;
            this.postIdOfExpandedComments = this.postIdOfExpandedComments === postId ? null : postId;
        }
    },
    computed: {
        ...mapState('posts', ['post']),
        ...mapState('profiles', ['profile', 'user']),
        baseUrl() {
            return `/api/v1/users/${this.post.user.id}/billboard_posts`;
        }
    }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-post-show-page {
    padding: 0rem 1rem 0rem 1rem;
    height: 100%;

    .breadcrumbs-container {
        margin-left: 4rem;
    }

    .ps {
        max-height: 65vh;
    }
}
</style>