import Axios from 'axios';

export function callOpsPagesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/ops_pages`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callOpsPagesShow(opsPageId, queryParams = {}) {
    return Axios.get(`/api/v1/ops_pages/${opsPageId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callOpsPagesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/ops_pages`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callOpsPagesUpdate(opsPageId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/ops_pages/${opsPageId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callOpsPagesDelete(opsPageId, queryParams = {}) {
    return Axios.delete(`/api/v1/ops_pages/${opsPageId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}