<template>
    <div class="profile-layout">
        <div class="profile-layout__large-cards">
            <router-link v-for="route in filteredLargeCardRoutes" :key="route.route(user).name"
                class="profile-image-card" :to="route.route(user)" :style="route.style">
                <div class="profile-card-icon-container">
                    <img class="profile-card-icon-container__icon" :src="route.icon" />
                </div>
                <div class="profile-card-header">{{ route.header }}</div>
            </router-link>
        </div>
        <perfect-scrollbar class="profile-layout__small-cards">
            <router-link v-for="route in filteredSmallCardRoutes" :key="route.route(user).name"
                class="profile-gradient-card" :to="route.route(user)" :style="route.style">
                <div class="profile-card-icon-container">
                    <img class="profile-card-icon-container__icon" :src="route.icon" />
                </div>
                <div class="profile-card-header">{{ route.header }}</div>
                <div class="profile-card-subheader" v-if="showSubheader(route.name)">
                    {{ getSubheaderText(route.name) }}
                </div>
            </router-link>
        </perfect-scrollbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        smallCardRoutes: {
            type: Array,
            required: true
        },
        largeCardRoutes: {
            type: Array,
            required: true
        }
    },
    created() {
        this.fetchUserFriendships(this.user.id);
        if (!this.user.isMyProfile) {
            this.fetchMutualFriends({ user1_id: this.user.id, user2_id: this.$store.state.currentUserId });
        }
        this.fetchArtistSubscriptions(this.$store.state.currentUserId);
    },
    computed: {
        ...mapGetters({
            userFriendships: 'userFriendships/getUserFriendships',
            mutualFriends: 'userFriendships/getMutualFriends',
            artistSubscriptions: 'userRelationships/getArtistSubscriptions'
        }),
        filteredLargeCardRoutes() {
            return this.largeCardRoutes.filter(route => this.evaluateConditions(route.conditions));
        },
        filteredSmallCardRoutes() {
            return this.smallCardRoutes.filter(route => this.evaluateConditions(route.conditions));
        }
    },
    methods: {
        ...mapActions('userFriendships', ['fetchUserFriendships', 'fetchMutualFriends']),
        ...mapActions('userRelationships', ['fetchArtistSubscriptions']),
        evaluateConditions(conditions) {
            return conditions.every(condition => {
                switch (condition.type) {
                    case 'always':
                        return true;
                    case 'currentUserProfile':
                        return this.$store.state.currentUserId === this.user.id;
                    case 'notCurrentUserProfile':
                        return this.$store.state.currentUserId !== this.user.id;
                    case 'profileType':
                        return condition.value.includes(this.user.profile_type);
                    default:
                        return false;
                }
            });
        },
        showSubheader(name) {
            return ['friends', 'mutual_friends', 'subscriptions', 'fans'].includes(name);
        },
        getSubheaderText(name) {
            switch (name) {
                case 'friends':
                    return `${this.userFriendships?.length || 0} Friends`;
                case 'mutual_friends':
                    return `${this.mutualFriends?.length || 0} Friends`;
                case 'subscriptions':
                    return `${this.artistSubscriptions?.length || 0} Artists`;
                case 'fans':
                    return `${this.artistSubscriptions?.length || 0} Fans`;
                default:
                    return '';
            }
        }
    }
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.profile-layout {
    .profile-layout__large-cards,
    .profile-layout__small-cards {
        display: grid;
        gap: 20px;
    }

    .profile-layout__large-cards {
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 1rem;
    }

    .profile-layout__small-cards {
        grid-template-columns: repeat(4, 1fr);
        max-height: 22rem;
    }

    .profile-image-card,
    .profile-gradient-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 10px;
        color: white;
        background-image: linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%);
    }

    .profile-card-icon-container {
        background-color: white;
        border-radius: 100%;
        height: 4.5rem;
        width: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        .profile-card-icon-container__icon {
            height: 2rem;
            width: auto;
        }
    }

    .profile-card-header {
        font-weight: 600;
        font-size: 1.2rem;
    }

    .profile-card-subheader {
        margin-top: 0.5rem;
        color: rgba(255, 255, 255, 0.7);
        text-transform: uppercase;
    }
}
</style>
