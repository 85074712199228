<template>
  <div class="atmo-filter-buttons">
    <button
      v-for="filterButton in filterButtons"
      :key="filterButton.name"
      class="atmo-button atmo-filter-buttons__button"
      :class="{
        'atmo-filter-buttons__button--active': activeFilter === filterButton.name,
        'atmo-filter-buttons__button--only-one': filterButtons.length === 1
      }"
      @click="$emit('on-button-click', filterButton.name)"
    >
      {{ filterButton.label }}
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      activeFilter: { type: String, default: null },
      filterButtons: { type: Array, required: true }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .atmo-filter-buttons {
    display: flex;

    &__button {
      background: none;
      color: white;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      padding: 4px 10px;
      border-radius: 3px;
      outline: none;

      &:hover {
        background-color: rgba($atmo-purple--medium, 0.1);
      }

      &--active, &--active:hover {
        background-color: rgba($atmo-purple--medium, 0.4);
      }

      &--only-one {
        &, &:hover, &--active, &--active:hover {
          cursor: default;
          background-color: inherit;
        }
      }
    }

  }
</style>
