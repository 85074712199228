import Axios from 'axios';

export function callArtistProfileReferencesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/artist_profile_references`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callArtistProfileReferencesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/artist_profile_references`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callArtistProfileReferencesDelete(artistProfileReference, queryParams = {}) {
    return Axios.delete(`/api/v1/artist_profile_references/${artistProfileReference}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
