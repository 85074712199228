<template>
  <div>
    <h1>{{ playlist.name }} Settings</h1>
    <div>
      <h3>Collaborators</h3>
      <div
        v-for="collaborator in playlistCollaborators"
        :key="collaborator.id"
      >
        <p>{{ collaborator.user.name }}</p>
        <button @click="deleteCollaborator(collaborator.id);">
          Remove Collaborator
        </button>
        <br>
      </div>
      <br>
      <br>
      <button @click="addCollaborator(1, playlist.id)">
        Add Collaborator
      </button>
      <br>
      <button @click="deletePlaylist(playlist.id)">
        Delete Playlist
      </button>
    </div>
  </div>
</template>

<script>
  import Axios from 'axios'
  import storeTest from '@/store'
  import { callUserProfilesShow, callUsersShow, callSongReferencesIndex } from '@/helpers/axiosCalls';

  export default{
    data(){
      return {
        user: {},
        currentUser: {},
        userProfile: {},
        playlist: {},
        playlistCollaborators: {},
        songs: {}
      }
    },
    created(){
      callUsersShow(storeTest.state.currentUserId).then(response => (this.currentUser = response));
      callUserProfilesShow(this.$route.params.userProfileId)
        .then(response1 => {
          this.userProfile = response1;
          callUsersShow(this.userProfile.user.id)
            .then(response2 => {
              this.user = response2;
              Axios.get(`/api/v1/users/${this.user.id}/playlists`);
              Axios.get(`/api/v1/users/${this.user.id}/playlists/${this.$route.params.playlistId}`).then(response => (this.playlist = response.data));
              callSongReferencesIndex(
              {
                songable_id: this.$route.params.playlistId,
                songable_type: 'Playlist'
              })
              .then(response => {
                this.songs = response.map((songReference) => {
                  return songReference.song;
                });
              });
              Axios.get(`/api/v1/users/${this.user.id}/playlists/${this.$route.params.playlistId}/playlist_collaborators`).then(response => (this.playlistCollaborators = response.data));
            })
        });
    },
    methods: {
      deleteCollaborator (collaboratorId) {
        Axios.delete(`/api/v1/users/${this.user.id}/playlists/${this.$route.params.playlistId}/playlist_collaborators/${collaboratorId}`, {
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.user.id}/playlists/${this.$route.params.playlistId}/playlist_collaborators`).then(response => (this.playlistCollaborators = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },

      addCollaborator (collaboratorId, playlistId) {
        Axios.post(`/api/v1/users/${this.user.id}/playlists/${this.$route.params.playlistId}/playlist_collaborators`, {
          user_id: collaboratorId,
          playlist_id: playlistId
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.user.id}/playlists/${this.$route.params.playlistId}/playlist_collaborators`).then(response => (this.playlistCollaborators = response.data));
          })
          .catch((error) => {
            console.error(error);
          })
      },

      deletePlaylist (playlistId) {
        Axios.delete(`/api/v1/users/${this.user.id}/playlists/${playlistId}`, {
        })
          .then((response) => {
            console.log(response);
            this.$router.push({ name: 'user_profiles.playlists.index', params: {userProfileId: this.userProfile.id} });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }
</script>
