<template>
  <b-modal id="atmo-library" ref="atmoLibraryRef" v-model="$store.state.libraryOpen" title="Library" size="lg"
    modal-class="atmo-modal atmo-library" hide-header hide-footer centered>
    <notifications group="main" position="top right" />
    <header class="atmo-modal__header">
      <span class="atmo-modal__header-side">
        <img class="atmo-modal__close" src="@/assets/images/icons/close--light.png" @click="$refs.atmoLibraryRef.hide()">
      </span>
      <div class="atmo-page-header-container">
        <h2 class="atmo-page-header">
          Atmo Library
        </h2>
      </div>
      <span class="atmo-modal__header-side" />
    </header>
    <atmo-library-body component-location="atmoLibrary"></atmo-library-body>
  </b-modal>
</template>
<script>
import AtmoLibraryBody from '@/components/atmo-library/atmo-library-body';

export default {
  components: {
    AtmoLibraryBody
  },
  data() {
    return {}
  },
  computed: {
  },
  created() {},
  methods: {}
}
</script>

<style lang="scss">
</style>
