var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-details-container"},[(_vm.user.images[0])?_c('div',{staticClass:"user-details-container__background-image-container",style:(_vm.backgroundImageStyle)}):_vm._e(),_c('div',{staticClass:"user-details-container__info-container"},[_c('div',{staticClass:"info-container__profile-pic-container"},[_c('img',{staticClass:"profile-pic-container__pic",attrs:{"src":_vm.getFeaturedImage(_vm.user.images, 'full')},on:{"click":function($event){return _vm.openUserMediaModal({
        type: 'Image',
        caption: _vm.user.images[0].name,
        url: _vm.user.images[0].image.url,
        id: _vm.user.images[0].id
    })}}}),(!_vm.isMyProfile)?_c('FollowButton',{attrs:{"userId":_vm.user.id}}):_vm._e()],1),_c('div',{staticClass:"info-container__user-info-wrap"},[_c('div',{staticClass:"user-info-wrap__name-wrap"},[_vm._v(_vm._s(_vm.user.name))]),_vm._m(0)]),_c('div',{staticClass:"info-container__button-wrap"},[(_vm.isMyProfile)?_c('div',{staticClass:"button-wrap__current-user"},[_c('router-link',{staticClass:"profile-button--light dashboard",attrs:{"to":_vm.dashboardRoute}},[_c('img',{staticClass:"profile-button__image",attrs:{"src":require("@/assets/images/icons/profile/dashboard.png")}}),_vm._v(" Dashboard ")]),_c('router-link',{staticClass:"profile-button--dark",attrs:{"to":_vm.editProfileRoute}},[_c('img',{staticClass:"profile-button__image",attrs:{"src":require("@/assets/images/icons/profile/edit.png")}}),_vm._v(" Edit Profile ")])],1):_c('div',{staticClass:"button-wrap__other-user"},[_c('router-link',{staticClass:"profile-button--pink",attrs:{"to":{ name: 'checkout' }}},[_c('img',{staticClass:"profile-button__image",attrs:{"src":require("@/assets/images/icons/profile/donate.png")}}),_vm._v(" Donate ")]),_c('router-link',{staticClass:"profile-button--light",attrs:{"to":_vm.venueRequestRoute}},[_c('img',{staticClass:"profile-button__image",attrs:{"src":require("@/assets/images/icons/profile/venue.png")}}),_vm._v(" Request a Venue ")])],1)])]),_c('div',{staticClass:"user-details-container__label-container"},[_c('div',{staticClass:"label-container__record-label-wrap"},[_vm._t("label-type",function(){return [_vm._v("Record Label")]})],2),_c('div',{staticClass:"label-container__name-wrap"},[_vm._t("label-name")],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-info-wrap__location-wrap"},[_c('img',{staticClass:"info-icon",attrs:{"src":require("@/assets/images/icons/profile/pin.png")}}),_vm._v(" Austin, TX, USA ")])
}]

export { render, staticRenderFns }