import Axios from 'axios';

export function callUserActivityTypesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/user_activity_types`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserActivityTypesShow(activityTypeId, queryParams = {}) {
    return Axios.get(`/api/v1/user_activity_types/${activityTypeId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserActivityTypesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/user_activity_types`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}