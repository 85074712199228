import Axios from 'axios';

export function callUsersSearch(queryParams = {}) {
    return Axios.get(`/api/v1/users/search`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUsersShow(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}