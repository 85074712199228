export default {
  genre: {
    endpoint: 'genres',
    identifier: 'genre_id',
    placeholder: 'Select Genre',
    label: 'Genre',
    infoText: `What is this song's genre?`
  },
  lyricsTheme: {
    endpoint: 'lyrics_themes',
    identifier: 'lyrics_theme_id',
    placeholder: 'Select Lyrics Theme',
    label: 'Lyrics Theme',
    infoText: `What is the main theme of the song's lyrics?`
  },
  mood: {
    endpoint: 'moods',
    identifier: 'mood_id',
    placeholder: 'Select Mood',
    label: 'Mood',
    infoText: `How does the song make the listener feel?`
  },
  occasion: {
    endpoint: 'occasions',
    identifier: 'occasion_id',
    placeholder: 'Select Occasion',
    label: 'Occasion',
    infoText: `What occasion would you play list song for?`
  },
  timeSignature: {
    endpoint: 'time_signatures',
    identifier: 'time_signature_id',
    placeholder: 'Select Time Signature',
    label: 'Time Signature',
    infoText: `Common time signatures are 4/4 and 3/4`
  },
  songForm: {
    endpoint: 'song_forms',
    identifier: 'song_form_id',
    placeholder: 'Select Song Form',
    label: 'Song Form',
    infoText: `Most modern pop songs are Verse/Chorus/Bridge`
  },
  tempo: {
    type: 'number',
    identifier: 'tempo',
    placeholder: 'Song Tempo',
    label: 'Song Tempo (BPM)',
    infoText: `How many beats occur per minute? (BPM)`
  },
  keySignature: {
    endpoint: 'song_keys',
    identifier: 'song_key_1',
    placeholder: 'Select Key Signature',
    label: 'Key Signature',
    infoText: `If your song has multiple key signatures, list the most prominent one`
  },
  tonality: {
    endpoint: 'song_tonalities',
    identifier: 'song_tonality_id',
    placeholder: 'Select Song Tonality',
    label: 'Tonality',
    infoText: `If your song has multiple tonalities, list the most prominent one`
  },
  instrumentation: {
    endpoint: 'instrumentations',
    identifier: 'instrumentation_id',
    placeholder: 'Select Instrumentation',
    label: 'Instrumentation'
  },
  voxQualities: {
    endpoint: 'vox_qualities',
    label: 'Vocals?'
  }
}
