import Axios from 'axios';

export function callUserFriendshipsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/user_friendships`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserFriendshipsShow(friendshipId, queryParams = {}) {
    return Axios.get(`/api/v1/user_friendships/${friendshipId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserFriendshipsCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/user_friendships`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserFriendshipsUpdate(friendshipId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/user_friendships/${friendshipId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserFriendshipsDelete(friendshipId, queryParams = {}) {
    return Axios.delete(`/api/v1/user_friendships/${friendshipId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserFriendshipsAccept(friendshipId, queryParams = {}) {
    return Axios.patch(`/api/v1/user_friendships/${friendshipId}/accept`, {}, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserFriendshipsDecline(friendshipId, queryParams = {}) {
    return Axios.patch(`/api/v1/user_friendships/${friendshipId}/decline`, {}, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserFriendshipsMutualFriends(queryParams = {}) {
    return Axios.get(`/api/v1/user_friendships/mutual_friends`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}