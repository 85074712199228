import { callUserAccomplishmentsIndex, callUserAccomplishmentsCreate } from '@/helpers/axiosCalls';

export default {
  namespaced: true,
  state: {
    debug: false,
    userAccomplishments: [],
    currentUserAccomplishments: [],
    profileUserAccomplishments: [],
    userAccomplishmentModalOpen: false,
    createdAccomplishments: [],
  },
  mutations: {
    setUserAccomplishments(state, userAccomplishments) {
      state.userAccomplishments = userAccomplishments;
      if (state.debug) console.log('User accomplishments set:', userAccomplishments);
    },
    setCurrentUserAccomplishments(state, currentUserAccomplishments) {
      state.currentUserAccomplishments = currentUserAccomplishments;
      if (state.debug) console.log('Current user accomplishments set:', currentUserAccomplishments);
    },
    setProfileUserAccomplishments(state, profileUserAccomplishments) {
      state.profileUserAccomplishments = profileUserAccomplishments;
      if (state.debug) console.log('Profile user accomplishments set:', profileUserAccomplishments);
    },
    toggleUserAccomplishmentModal(state) {
      state.userAccomplishmentModalOpen = !state.userAccomplishmentModalOpen;
      if (state.debug) console.log('User accomplishment modal toggled:', state.userAccomplishmentModalOpen);
    },
    hideUserAccomplishmentModal(state) {
      state.userAccomplishmentModalOpen = false;
      if (state.debug) console.log('User accomplishment modal hidden');
    },
    setCreatedAccomplishments(state, createdAccomplishments) {
      state.createdAccomplishments = createdAccomplishments;
      if (state.debug) console.log('Created accomplishments set:', createdAccomplishments);
    },
  },
  actions: {
    async fetchUserAccomplishments({ commit, state }, userId) {
      try {
        if (state.debug) console.log(`Fetching accomplishments for user with id: ${userId}`);
        const response = await callUserAccomplishmentsIndex(userId);
        if (state.debug) console.log('User accomplishments response:', response);
        commit('setUserAccomplishments', response);
      } catch (error) {
        console.error('Failed to fetch user accomplishments:', error);
      }
    },
    async fetchCurrentUserAccomplishments({ commit, state, rootState }) {
      try {
        const currentUserId = rootState.currentUserId;
        if (state.debug) console.log(`Fetching current user accomplishments for user id: ${currentUserId}`);
        const response = await callUserAccomplishmentsIndex(currentUserId);
        if (state.debug) console.log('Current user accomplishments response:', response);
        commit('setCurrentUserAccomplishments', response);
      } catch (error) {
        console.error('Failed to fetch current user accomplishments:', error);
      }
    },
    async fetchProfileUserAccomplishments({ commit, state }, userId) {
      try {
        if (state.debug) console.log(`Fetching profile user accomplishments for user id: ${userId}`);
        const response = await callUserAccomplishmentsIndex(userId);
        if (state.debug) console.log('Profile user accomplishments response:', response);
        commit('setProfileUserAccomplishments', response);
      } catch (error) {
        console.error('Failed to fetch profile user accomplishments:', error);
        commit('setProfileUserAccomplishments', []); // Fallback to empty array
      }
    },
    toggleUserAccomplishmentModal({ commit }) {
      commit('toggleUserAccomplishmentModal');
    },
    async createUserAccomplishments({ commit, state }, payload) {
      try {
        if (state.debug) console.log('Creating user accomplishments with payload:', payload);
        const response = await callUserAccomplishmentsCreate(payload.userId, payload.accomplishmentData, { bulk: true });

        if (response.created_accomplishments.length > 0) {
          if (state.debug) console.log("response.created_accomplishments", response.created_accomplishments);
          commit('setCreatedAccomplishments', response.created_accomplishments);
          commit('toggleUserAccomplishmentModal');
        }
      } catch (error) {
        console.error('Failed to create user accomplishments:', error);
      }
    }
  },
  getters: {
    getUserAccomplishments(state) {
      return state.userAccomplishments;
    },
    getCurrentUserAccomplishments(state) {
      return state.currentUserAccomplishments;
    },
    getProfileUserAccomplishments(state) {
      return state.profileUserAccomplishments;
    },
    isUserAccomplishmentModalOpen(state) {
      return state.userAccomplishmentModalOpen;
    },
    getCreatedAccomplishments(state) {
      return state.createdAccomplishments;
    }
  }
};
