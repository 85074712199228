var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ops-page-list-item"},[_c('router-link',{staticClass:"ops-page-list-item__image",style:({ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${_vm.getFeaturedImage(_vm.page.images, 'full')})` }),attrs:{"to":{
        name: 'ops_pages.show',
        params: { opsPageId: _vm.page.id }
    }}},[_c('div',{staticClass:"donation-progress"},[_c('div',{staticClass:"donation-progress__donation-progress-wrap"},[_c('div',{staticClass:"donation-progress-wrap__donation-progress-meter"},[_c('atmo-progress-bar',{attrs:{"percent":_vm.calculatePercent(_vm.page)}})],1),_c('div',{staticClass:"donation-progress-wrap__donation-goal-text"},[_c('div',[_c('p',{staticClass:"donation-progress-header"},[_vm._v("Raised")]),_c('p',[_vm._v("US $8,000")])]),_c('div',[_c('p',{staticClass:"donation-progress-header donation-blue"},[_vm._v("Goal")]),_c('p',[_vm._v("US $"+_vm._s(_vm.page.goal))])])])])])]),_c('div',{staticClass:"ops-page-list-item__content-wrap"},[_c('div',{staticClass:"content-wrap__title-description-wrap"},[_c('div',{staticClass:"title-description-wrap__title-edit-popover"},[_c('router-link',{staticClass:"title-edit-popover__title",attrs:{"to":{
                    name: 'ops_pages.show',
                    params: { opsPageId: _vm.page.id }
                }}},[_vm._v(" "+_vm._s(_vm.page.campaign_name)+" ")]),(_vm.$store.state.currentUserId === _vm.page.user_id)?_c('atmo-popover',{attrs:{"placement":"bottom"}},[_c('img',{staticClass:"campaign-trigger",attrs:{"slot":"trigger","src":require("@/assets/images/icons/kebab_menu.png"),"alt":"actions"},slot:"trigger"}),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('router-link',{staticClass:"atmo-popover__dropdown-action",attrs:{"to":{ name: 'ops_pages.edit', params: { opsPageId: _vm.page.id } }}},[_c('div',{staticClass:"atmo-popover__dropdown-action-image-container"},[_c('img',{staticClass:"atmo-popover__dropdown-action-image",attrs:{"src":require("@/assets/images/icons/edit.png"),"alt":"Edit campaign"}})]),_vm._v(" Edit campaign ")]),_c('a',{staticClass:"atmo-popover__dropdown-action",on:{"click":function($event){return _vm.deleteCampaign(_vm.page.id)}}},[_c('div',{staticClass:"atmo-popover__dropdown-action-image-container"},[_c('img',{staticClass:"atmo-popover__dropdown-action-image",attrs:{"src":require("@/assets/images/icons/delete.png"),"alt":"Delete campaign"}})]),_vm._v(" Delete campaign ")])],1)]):_vm._e()],1),_c('div',{staticClass:"title-description-wrap__description"},[_vm._v(" "+_vm._s(_vm.page.campaign_description)+" ")])]),_c('div',{staticClass:"content-wrap__author-date-wrap"},[_c('div',{staticClass:"author-date-wrap__author-wrap"},[_c('img',{staticClass:"atmo-avatar atmo-avatar--extra-small",style:({ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${_vm.getFeaturedImage(_vm.page.user.images, 'full')})` })}),_c('div',{},[_vm._v(" "+_vm._s(_vm.page.user.name)+" ")])]),_c('div',{staticClass:"author-date-wrap__date-wrap"},[_vm._v(" "+_vm._s(_vm.timeLeft)+" Left ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }