<!-- <template>
  <atmo-music-items
    type="artists"
    :current-page-number="Pagination_currentPageNumber"
    :max-page-number="Pagination_maxPageNumber"
    :items-for-current-page="Pagination_itemsForCurrentPage"
    :on-previous-click="Pagination_onPreviousClick"
    :on-next-click="Pagination_onNextClick"
  />
</template>

<script>
  import AtmoMusicItems from '@/components/atmo-music-items';
  import PaginationMixin from '@/mixins/pagination';
  import { artistsStub } from '../data';

  export default {
    components: { AtmoMusicItems },

    mixins: [PaginationMixin],

    props: { showNewReleases: { type: Boolean, default: false} },

    data() {
      return {
        artists: null
      };
    },

    computed: {
      fullCollection() {
        if (this.showNewReleases) {
          return this.artists.filter(artist => artist.isNew);
        } else {
          return this.artists;
        }
      }
    },

    created() {
      this.getArtists();
    },

    methods: {
      getArtists() {
        // TODO: Use real artists
        this.artists = artistsStub;
      }
    }
  }
</script> -->


<template>
  <atmo-music-artists-grid :artists="artists" />
</template>

<script>
// import AtmoMusicItems from '@/components/atmo-music-items';
import AtmoMusicArtistsGrid from '@/components/music/atmo-music-artists-grid';
import PaginationMixin from '@/mixins/pagination';
import {
  callArtistsExplore
} from '@/helpers/axiosCalls';

export default {
  components: { AtmoMusicArtistsGrid },
  mixins: [PaginationMixin],
  props: { showNewReleases: { type: Boolean, default: false } },
  data() {
    return {
      artists: null,
      isLoading: true,
    };
  },
  computed: {
    fullCollection() {
      if (this.showNewReleases) {
        return this.artists.filter(artist => artist.isNew);
      } else {
        return this.artists;
      }
    }
  },
  created() {
    this.getArtists();
  },
  methods: {
    getArtists() {
      callArtistsExplore()
        .then((response) => {
          this.artists = response;
          console.log("this.artists: ", this.artists);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }
}
</script>
