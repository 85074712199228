<template>
  <div class="user-profile" data-cy="user-profile">
    <div class="header-container">
      <h2 class="atmo-page-header">User Profile</h2>
    </div>
    <atmo-back-link></atmo-back-link>
    <div class="user-profile-grid">
      <UserProfileDetailsContainer
        v-if="user && profile"
        :user="user"
        :profile="profile"
        @openUserMediaModal="openUserMediaModal"
      />
      <ProfileLayout
        v-if="user && profileRoutes"
        :user="user"
        :smallCardRoutes="profileRoutes.smallCards"
        :largeCardRoutes="profileRoutes.largeCards"
      />
    </div>
  </div>
</template>

<script>
import AtmoBackLink from '@/components/atmo-back-link';
import ProfileLayout from '@/components/profiles/profile-layout';
import UserProfileDetailsContainer from '@/components/profiles/user-profile-details-container';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    AtmoBackLink,
    ProfileLayout,
    UserProfileDetailsContainer
  },
  computed: {
    ...mapGetters('profiles', ['getProfile', 'getUser', 'getProfileRoutes']),
    user() {
      return this.getUser;
    },
    profile() {
      return this.getProfile;
    },
    profileRoutes() {
      return this.getProfileRoutes;
    }
  },
  methods: {
    ...mapActions('profiles', ['fetchProfile']),
    openUserMediaModal(userMediaObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/setUserMediaModalObj", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    }
  },
  async created() {
    await this.fetchProfile({ profileType: 'UserProfile', profileId: this.$route.params.userProfileId });
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.user-profile {
  margin: 0 75px;

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-profile-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: 18rem 1fr;
  }
}
</style>
