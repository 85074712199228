import Axios from 'axios';

export function callProductSizesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/product_sizes`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductSizesShow(productVariantId, queryParams = {}) {
    return Axios.get(`/api/v1/product_sizes/${productVariantId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductSizesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/product_sizes`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductSizesUpdate(productVariantId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/product_sizes/${productVariantId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductSizesDelete(productId, queryParams = {}) {
    return Axios.delete(`/api/v1/product_sizes/${productId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
