import Axios from 'axios';

export function callDislikesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/dislikes`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDislikesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/dislikes`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDislikesDelete(dislikeId, queryParams = {}) {
    return Axios.delete(`/api/v1/dislikes/${dislikeId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}