<template>
    <FanList />
</template>

<script>
import { mapState } from 'vuex';
import FanList from '@/components/profiles/fan-list';

export default {
    components: { FanList },
    computed: {
    ...mapState('profiles', ['user']),
  }
};
</script>