<template>
  <div>
    <p class="heading">
      Your Subscriptions
    </p>
    <div
      v-for="subscription in subscriptions"
      :key="subscription.id"
    >
      <router-link :to="{ name: 'subscriptions.show', params: { subscriptionId: subscription.id }}">
        {{ subscription.name }}
      </router-link>
      <p>Status: <a>{{ subscription.status }}</a></p>
      <router-link :to="{ name: 'subscriptions.user_invoices.index', params: { userProfileId: user.profile_id, subscriptionId: subscription.id }}">
        View Invoices
      </router-link>
      <br>
    </div>
    <br>
    <br>
    <br>
    <br>
    <router-link
      v-if="noSubsActive"
      :to="{ name: 'subscriptions.user_subscriptions.new', params: { userProfileId: user.profile_id }}"
    >
      Resubscribe to Plus!
    </router-link>
    <router-link :to="{ name: 'subscriptions.cards.index', params: { userProfileId: user.profile_id }}">
      View Card Information
    </router-link>
  </div>
</template>

<script>
  import Axios from 'axios'
  import storeTest from '@/store'
  import { callSubscriptionsIndex, callUserProfilesShow } from '@/helpers/axiosCalls';

  export default {
    data: function(){
      return {
        user: {},
        userProfile: {},
        subscriptions: {},
        noSubsActive: null
      }
    },
    created(){
      Axios.get('/api/v1/users/' + storeTest.state.currentUserId)
        .then(response => {
          this.user = response.data;
          callUserProfilesShow(this.user.profile_id).then(response => (this.userProfile = response));
          callSubscriptionsIndex(this.user.profile_id).then(response => {
            this.subscriptions = response;
            if (this.subscriptions.every(this.isCanceled) == true) {
              this.noSubsActive = true;
            } else {
              this.noSubsActive = false;
            }
          });
        });
    },
    methods: {
      isCanceled(subscription) {
        return subscription.status == "canceled"; // return true or false
      }
    }
  }
</script>
