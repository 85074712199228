<template>
  <div class="atmo-post">
    <div class="atmo-post__avatar-container" @click="closeBroadcastModal">
      <profile-link :profile-type="post.user.profile_type" :profile-id="post.user.profile_id">
        <div class="atmo-avatar" :style="{ 'background-image': `url(${getFeaturedImage(post.user.images, 'full')})` }" />
      </profile-link>
    </div>
    <div class="atmo-post__post-container">
      <div class="atmo-post__main-content-container">
        <div class="atmo-post__post-header">
          <div class="atmo-post__post-identifier">
            <span class="atmo-post__post-owner" @click="closeBroadcastModal">
              <profile-link :profile-type="post.user.profile_type" :profile-id="post.user.profile_id">
                {{ post.user.name }}
              </profile-link>
            </span>
            <span class="atmo-post__post-date">
              {{ getDate(post.created_at) }}
            </span>
            <div class="atmo-post__post-public user" v-if="$store.state.currentUserId === post.user_id">
              <img v-if="post.is_public" src="@/assets/images/icons/posts/public.png" alt="public playlist" @click="editPost(post, { is_public: false })" />
              <img v-else src="@/assets/images/icons/posts/private.png" alt="private playlist" @click="editPost(post, { is_public: true })" />
            </div>
            <div class="atmo-post__post-public" v-else>
              <img v-if="post.is_public" src="@/assets/images/icons/posts/public.png" alt="public playlist" />
              <img v-else src="@/assets/images/icons/posts/private.png" alt="private playlist" />
            </div>
            <div class="atmo-post__show-post" v-if="type == 'billboard' && maximizePostEnabled">        
              <router-link :to="{ name: 'posts.show', params: { postId: post.id } }">
                <img src="@/assets/images/icons/expand.png"
                alt="show post" class="expand-post">
              </router-link>
            </div>
          </div>
          <div :class="{ 'display-none': !popoverIsEligible(post) }">
            <atmo-popover placement="bottom">
              <img slot="trigger" class="atmo-post__post-dropdown-trigger" src="@/assets/images/icons/arrow_down.png" alt="Post Actions" />
              <div slot="content">
                <a class="atmo-popover__dropdown-action" @click="deletePost(post)" v-if="$store.state.currentUserId === post.user_id">
                  <div class="atmo-popover__dropdown-action-image-container">
                    <img class="atmo-popover__dropdown-action-image" src="@/assets/images/icons/delete.png" alt="Delete post" />
                  </div>
                  Delete post
                </a>
                <a class="atmo-popover__dropdown-action" @click="openPostEditor()" v-if="$store.state.currentUserId === post.user_id">
                  <div class="atmo-popover__dropdown-action-image-container">
                    <img class="atmo-popover__dropdown-action-image" src="@/assets/images/icons/edit.png" alt="Edit post" />
                  </div>
                  Edit post
                </a>
                <a class="atmo-popover__dropdown-action" @click="sharePost(post)" v-if="post.is_public">
                  <div class="atmo-popover__dropdown-action-image-container">
                    <img class="atmo-popover__dropdown-action-image" src="@/assets/images/icons/share.png" alt="Share post" />
                  </div>
                  Share post
                </a>
              </div>
            </atmo-popover>
          </div>
        </div>
        <div class="atmo-post__post-content">
          <div v-if="Array.isArray(post.images) && post.images.length > 0" class="post-content__post-pics-container">
            <img v-for="(postImage, index) in post.images" :key="index" class="post-pics-container__post-pic"
              :src="postImage.image.url" alt="Post pic" @click="openUserMediaModal({
                type: 'Image',
                caption: post.content,
                url: postImage.image.url,
                id: postImage.id
              })" />
          </div>
          <div v-if="Array.isArray(post.videos) && post.videos.length > 0" class="post-content__post-pics-container">
            <img v-for="(postVideo, index) in post.videos" :key="index" class="post-pics-container__post-pic"
              :src="postVideo.preview_image.url" alt="Video preview" @click="openUserMediaModal({
                type: 'Video',
                caption: post.content,
                url: postVideo.video.url,
                id: postVideo.id
              })" />
          </div>
          <div v-if="!postIsBeingEdited" v-html="formatPost(post.content || post.text)"></div>
          <input v-else v-model="postContent" autofocus placeholder="Edit post" type="text"
            class="comment-list-component__input" @keyup.enter="submitNewPostContent(post, postContent)">
        </div>
        <div v-if="Array.isArray(post.song_references) && post.song_references.length > 0">
          <div class="atmo-post__post-footer" v-for="(songReference, index) in post.song_references" :key="index">
            <div class="atmo-post__song-actions-popover-container">
              <song-actions-popover :song="song" placement="left" :actions="[
                'add-to-library',
                'add-to-queue',
                'add-to-playlist'
              ]" />
            </div>
            <span class="atmo-post__song-link">
              {{ songReference.song.name }}
            </span>
          </div>
        </div>
        <shared-atmo-post v-if="sharedPost" :shared-post="sharedPost"></shared-atmo-post>
        <event-atmo-post v-if="post.shareable" :post="post"></event-atmo-post>
      </div>
      <div v-if="commentsEnabled || likesEnabled" class="atmo-post__social-buttons">
        <button v-if="likesEnabled" class="atmo-button atmo-post__social-button" @click="likePost(post)">
          <img class="atmo-button__icon atmo-post__icon--not-hovered" src="@/assets/images/icons/like--purple.png"
            alt="Like">
          <img class="atmo-button__icon atmo-post__icon--hovered" src="@/assets/images/icons/like.png" alt="Like">
          {{ post.likes.length }}
        </button>
        <button v-if="likesEnabled" class="atmo-button atmo-post__social-button" @click="dislikePost(post)">
          <img class="atmo-button__icon atmo-post__icon--not-hovered" src="@/assets/images/icons/dislike--purple.png"
            alt="Dislike">
          <img class="atmo-button__icon atmo-post__icon--hovered" src="@/assets/images/icons/dislike.png" alt="Dislike">
          {{ post.dislikes.length }}
        </button>
        <button v-if="commentsEnabled" class="atmo-button atmo-post__social-button" :class="{
          'atmo-post__social-button--active': commentsExpanded,
        }" @click="toggleComments(post)">
          <img class="atmo-button__icon atmo-post__icon--not-hovered" src="@/assets/images/icons/comment--purple.png"
            alt="Comment">
          <img class="atmo-button__icon atmo-post__icon--hovered" src="@/assets/images/icons/comment.png" alt="Comment">
          {{ post.comments.length }}
        </button>
      </div>
      <div v-if="commentsExpanded" class="atmo-post__post-comments-container">
        <comments-list :type="type" :post="post" :base-url="baseUrl" :user-profile="userProfile" />
      </div>
    </div>
  </div>
</template>

<script>
import SongActionsPopover from '@/components/song-actions-popover';
import AtmoPopover from '@/components/atmo-popover';
import CommentsList from '@/components/atmo-posts/comments-list';
import { utilsGetDate, getFeaturedImage } from '@/helpers/utilityFunctions';
import SharedAtmoPost from '@/components/atmo-posts/shared-atmo-post';
import EventAtmoPost from '@/components/atmo-posts/event-atmo-post';
import { mapActions, mapState } from 'vuex';
import ProfileLink from '@/components/profile-link'

export default {
  components: { 
    SongActionsPopover, 
    AtmoPopover, 
    CommentsList, 
    SharedAtmoPost, 
    EventAtmoPost,
    ProfileLink 
  },
  props: {
    type: {
      type: String,
      default: 'billboard'
    },
    post: {
      type: Object,
      required: true
    },
    baseUrl: {
      type: String,
      default: null
    },
    userProfile: {
      type: Object,
      default: null
    },
    actionsEnabled: {
      type: Boolean,
      default: true
    },
    commentsEnabled: {
      type: Boolean,
      default: false
    },
    likesEnabled: {
      type: Boolean,
      default: true
    },
    commentsExpanded: {
      type: Boolean,
      default: false
    },
    toggleComments: {
      type: Function,
      default() { }
    },
    maximizePostEnabled: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      postIsBeingEdited: false,
      postContent: this.post.content || this.post.text
    }
  },
  methods: {
    ...mapActions('posts', ['likePost', 'dislikePost', 'editPost', 'deletePost']),
    closeBroadcastModal() {
      if (this.type === 'broadcast') {
        this.$store.commit('userActivities/closeBroadcastModal');
      }
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    formatPost(post) {
      // Use a regular expression to find URLs in the post and replace them with clickable links
      const urlPattern = /https?:\/\/[^\s]+/g;
      return post.replace(urlPattern, (url) => {
        return `<a class="chat-link" href="${url}" target="_blank">${url}</a>`;
      });
    },
    popoverIsEligible(post) {
      if (this.$store.state.currentUserId === post.user_id || post.is_public) {
        return true;
      } else {
        return false;
      }
    },
    sharePost(post) {
      this.$store.commit("setSharePostModalPost", post);
      this.$store.commit("openSharePostModal");
    },
    openUserMediaModal(userMediaObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/setUserMediaModalObj", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    },
    openPostEditor() {
      this.postIsBeingEdited = true;
    },
    submitNewPostContent(post, postContent) {
      this.postIsBeingEdited = false;
      this.editPost(post, {
        content: postContent
      });
    },
    getDate(date) {
      return utilsGetDate(date);
    },
  },
  computed: {
    ...mapState('posts', ['posts']),
    sharedPost() {
      return this.post.original_post;
    },
    song() {
      // TODO: use real song (post.song)
      if (!this.commentsEnabled) { return null; }
      return { name: 'fake song', id: 1 }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-post {
  display: flex;

  .display-none {
    display: none;
  }

  &__avatar-container {
    display: flex;
    justify-content: flex-end;
    width: 72px;
    padding: 0 10px;
  }

  &__post-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 12px;
  }

  &__main-content-container {
    display: flex;
    flex-direction: column;
    // background-color: rgba($atmo-purple--light, 0.4);
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    padding: .5rem .5rem .5rem 1rem;
    margin-bottom: 8px;
    border-radius: 5px;
    font-weight: 300;
    // min-height: 6rem;
  }

  &__post-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__post-identifier {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  &__post-owner {
    font-size: .8rem;

    a {
      color: white;
      font-weight: 500;
    }
  }

  &__post-date {
    color: white;
    font-size: .7rem;
    font-weight: 500;
  }

  &__post-public {
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.user {
      cursor: pointer;
    }

    img {
      height: .8rem;
    }
  }

  &__show-post {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .expand-post {
      height: .8rem;
    }
  }

  &__post-dropdown {
    position: relative;
  }

  &__post-dropdown-trigger {
    height: 9px;
    padding: 1px 3px;
  }

  &__post-content {
    padding: 10px 0;
    font-size: .8rem;
    font-weight: 400;
    display: flex;
    gap: .5rem;
  }

  .post-content__post-pics-container {
    display: flex;
    gap: .5rem;
  }

  .post-pics-container__post-pic {
    height: 4rem;
    width: 4rem;
    object-fit: cover;
    border-radius: 2px;
    cursor: pointer;
  }

  &__post-footer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 3px;
    align-items: center;

    .atmo-button {
      margin-right: 5px;
    }
  }

  &__song-actions-popover-container {
    height: 20px;
    width: 20px;
  }

  &__song-link {
    color: white;
    font-size: .8rem;
    font-weight: 500;
    margin-left: .2rem;
  }

  .song-actions-button {
    .atmo-post__button-icon--active {
      display: none;
    }

    &:active,
    &:focus {
      outline: none;
      background-color: $atmo-purple--dark;

      :global(.atmo-button__icon) {
        display: none;
      }

      .atmo-post__button-icon--active {
        display: flex;
        margin-left: 0;
      }
    }
  }

  &__social-buttons {
    display: flex;
  }

  .atmo-button.atmo-post__social-button {
    font-size: 0.8em;
    font-weight: 400;
    padding: 5px 10px 5px 10px;
    margin-right: 5px;

    .atmo-post__icon--hovered {
      display: none;
      margin-left: 0;
    }

    &:hover,
    &--active {
      // .atmo-post__icon--hovered {
      //   display: block;
      // }
      // .atmo-post__icon--not-hovered {
      //   display: none;
      // }
    }

    &--small {
      font-size: 0.7em;
      padding: 3px 7px 2px;

      img.atmo-button__icon {
        height: 11px;
      }
    }

    img {
      height: 14px;
      width: 18px;
      padding-right: 3px;
    }

    &--active {
      outline: none;
      background-color: $atmo-purple--dark;
      color: white;
    }

  }

  &__post-comments-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &__comment {
    display: flex;

    .post-container {
      margin-left: 10px;
    }

    .main-content-container {
      font-size: 0.9em;
      background: inherit;
      padding-left: 0;
      margin-bottom: 0;
    }
  }
}
</style>
