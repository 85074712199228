import {
    callUsersShow,
    callUserProfilesShow,
    callArtistProfilesShow,
    callLabelProfilesShow,
    callImagesIndex,
    callVideosIndex
} from '@/helpers/axiosCalls';

const getProfileParamName = (profileType) => {
    switch (profileType) {
        case 'UserProfile':
            return 'userProfileId';
        case 'ArtistProfile':
            return 'artistProfileId';
        case 'LabelProfile':
            return 'labelProfileId';
        default:
            return '';
    }
};

const profileRoutes = {
    largeCards: [
        {
            route: user => ({ name: 'music.charts' }),
            style: {
                'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require('@/assets/images/profile/music.jpg') + ')'
            },
            icon: require('@/assets/images/icons/profile/music.png'),
            header: 'Music',
            profiles: ['UserProfile'],
            conditions: [
                { type: 'profileType', value: ['UserProfile', 'LabelProfile'] }
            ]
        },
        {
            route: user => ({ name: 'albums.index', params: { artistProfileId: user.id } }),
            style: {
                'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require('@/assets/images/profile/music.jpg') + ')'
            },
            icon: require('@/assets/images/icons/profile/music.png'),
            header: 'Music',
            profiles: ['ArtistProfile'],
            conditions: [
                { type: 'profileType', value: ['ArtistProfile'] }
            ]
        },
        {
            route: user => ({ name: 'billboards.show', params: { userId: user.id } }),
            style: {
                'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require('@/assets/images/profile/billboard.jpg') + ')'
            },
            icon: require('@/assets/images/icons/profile/broadcast.png'),
            header: 'Billboard',
            profiles: ['UserProfile', 'ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'always' }
            ]
        }
    ],
    smallCards: [
        {
            name: 'playlists',
            route: user => ({ name: 'users.playlists.index', params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/friends.png'),
            header: 'Playlists',
            profiles: ['UserProfile', 'ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'always' }
            ]
        },
        {
            name: 'friends',
            route: user => ({ name: `users.friends`, params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/friends.png'),
            header: 'Friends',
            profiles: ['UserProfile', 'ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'always' }
            ]
        },
        {
            name: 'mutual_friends',
            route: user => ({ name: `users.mutual_friends`, params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/friends.png'),
            header: 'Mutual Friends',
            profiles: ['UserProfile', 'ArtistProfile'],
            conditions: [
                { type: 'notCurrentUserProfile' }
            ]
        },
        {
            name: 'subscriptions',
            route: user => ({ name: `users.artist_subscriptions`, params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/sub.png'),
            header: 'Subscriptions',
            profiles: ['UserProfile', 'ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'always' }
            ]
        },
        {
            name: 'accomplishments',
            route: user => ({ name: 'accomplishments.index', params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/accomplish.png'),
            header: 'Accomplishments',
            profiles: ['UserProfile', 'ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'always' }
            ]
        },
        {
            name: 'videos',
            route: user => ({ name: 'videos.index', params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/videos.png'),
            header: 'Videos',
            profiles: ['UserProfile', 'ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'always' }
            ]
        },
        {
            name: 'photos',
            route: user => ({ name: 'photos.index', params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/photos.png'),
            header: 'Photos',
            profiles: ['UserProfile', 'ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'always' }
            ]
        },
        {
            name: 'fans',
            route: user => ({ name: 'users.fans', params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/friends.png'),
            header: 'Fans',
            profiles: ['ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'profileType', value: ['ArtistProfile', 'LabelProfile'] }
            ]
        },
        {
            name: 'contests',
            route: user => ({ name: 'users.contests', params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/contest.png'),
            header: 'Contests',
            profiles: ['ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'profileType', value: ['ArtistProfile', 'LabelProfile'] }
            ]
        },
        {
            name: 'merchandise',
            route: user => ({ name: 'products.user_index', params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/accomplish.png'),
            header: 'Merchandise',
            profiles: ['ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'profileType', value: ['ArtistProfile', 'LabelProfile'] }
            ]
        },
        {
            name: 'ops',
            route: user => ({ name: 'users.ops_pages', params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/ops.png'),
            header: 'Ops',
            profiles: ['ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'profileType', value: ['ArtistProfile', 'LabelProfile'] }
            ]
        },
        {
            name: 'events',
            route: user => ({ name: 'events.index', params: { [getProfileParamName(user.profile_type)]: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/ops.png'),
            header: 'Events',
            profiles: ['ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'profileType', value: ['ArtistProfile', 'LabelProfile'] }
            ]
        },
        {
            name: 'admin_events',
            route: user => ({ name: 'events.admin_index', params: { userId: user.id } }),
            style: {},
            icon: require('@/assets/images/icons/profile/accomplish.png'),
            header: 'Events',
            profiles: ['ArtistProfile', 'LabelProfile'],
            conditions: [
                { type: 'profileType', value: ['ArtistProfile', 'LabelProfile'] },
                { type: 'currentUserProfile' }
            ]
        }
    ]
};

export default {
    namespaced: true,
    state: {
        debug: false,
        profile: null,
        user: null,
        isLoading: false,
        profileType: null,
        profileRoutes,
        userPhotos: [],
        userVideos: []
    },
    mutations: {
        setProfile(state, profile) {
            if (state.debug) console.log('Mutation: setProfile - Profile Data:', profile);
            state.profile = profile;
        },
        setUser(state, user) {
            if (state.debug) console.log('Mutation: setUser - User Data:', user);
            state.user = user;
        },
        setLoading(state, isLoading) {
            if (state.debug) console.log('Mutation: setLoading - Is Loading:', isLoading);
            state.isLoading = isLoading;
        },
        setProfileType(state, profileType) {
            if (state.debug) console.log('Mutation: setProfileType - Profile Type:', profileType);
            state.profileType = profileType;
        },
        setUserPhotos(state, photos) {
            if (state.debug) console.log('Mutation: setUserPhotos - Photos Data:', photos);
            state.userPhotos = photos;
        },
        setUserVideos(state, videos) {
            if (state.debug) console.log('Mutation: setUserVideos - Videos Data:', videos);
            state.userVideos = videos;
        }
    },
    actions: {
        async fetchUserAndProfile({ commit, dispatch }, userId) {
            commit('setLoading', true);
            try {
                // Fetch the user first
                const userResponse = await callUsersShow(userId);
                commit('setUser', userResponse);

                // Fetch the associated profile using profile_id
                await dispatch('fetchProfile', { profileType: userResponse.profile_type, profileId: userResponse.profile_id });
            } catch (error) {
                console.error('Error in fetchUserAndProfile:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async fetchProfile({ commit }, { profileType, profileId }) {
            console.log('fetchProfile', profileType, profileId)
            commit('setLoading', true);
            commit('setProfileType', profileType);
            try {
                let response;
                switch (profileType) {
                    case 'UserProfile':
                        response = await callUserProfilesShow(profileId);
                        break;
                    case 'ArtistProfile':
                        response = await callArtistProfilesShow(profileId);
                        break;
                    case 'LabelProfile':
                        response = await callLabelProfilesShow(profileId);
                        break;
                    default:
                        throw new Error('Invalid profile type');
                }
                commit('setProfile', response);
                commit('setUser', response.user); //THIS NEEDS TO CHANGE
            } catch (error) {
                console.error('Error in fetchProfile:', error);
            } finally {
                commit('setLoading', false);
            }
        },
        async fetchUserPhotos({ state, commit }, userId) {
            if (state.debug) console.log(`Action: fetchUserPhotos - Fetching photos for user ID: ${userId}`);
            try {
                const response = await callImagesIndex({
                    imageable_id: userId,
                    imageable_type: 'User'
                });
                if (state.debug) console.log('Response from callImagesIndex:', response);
                commit('setUserPhotos', response);
            } catch (error) {
                console.error('Error in fetchUserPhotos:', error);
            }
        },
        async fetchUserVideos({ state, commit }, userId) {
            if (state.debug) console.log(`Action: fetchUserVideos - Fetching videos for user ID: ${userId}`);
            try {
                const response = await callVideosIndex({
                    videoable_id: userId,
                    videoable_type: 'User'
                });
                if (state.debug) console.log('Response from callVideosIndex:', response);
                commit('setUserVideos', response);
            } catch (error) {
                console.error('Error in fetchUserVideos:', error);
            }
        }
    },
    getters: {
        getProfile: state => {
            if (state.debug) console.log('Getter: getProfile - Returning profile:', state.profile);
            return state.profile;
        },
        getUser: state => {
            if (state.debug) console.log('Getter: getUser - Returning user:', state.user);
            return state.user;
        },
        isLoading: state => {
            if (state.debug) console.log('Getter: isLoading - Returning loading state:', state.isLoading);
            return state.isLoading;
        },
        getProfileType: state => {
            if (state.debug) console.log('Getter: getProfileType - Returning profile type:', state.profileType);
            return state.profileType;
        },
        getProfileRoutes: state => {
            if (state.debug) console.log('Getter: getProfileRoutes - Returning profile routes:', state.profileRoutes);
            return state.profileRoutes;
        },
        getUserPhotos: state => {
            if (state.debug) console.log('Getter: getUserPhotos - Returning user photos:', state.userPhotos);
            return state.userPhotos;
        },
        getUserVideos: state => {
            if (state.debug) console.log('Getter: getUserVideos - Returning user videos:', state.userVideos);
            return state.userVideos;
        }
    }
};
