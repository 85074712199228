<template>
  <div class="accomplishments" data-cy="accomplishments">
    <div class="header-container">
      <h2 class="atmo-page-header">
        Accomplishments
      </h2>
    </div>

    <atmo-back-link v-if="user && user.profile_type === 'UserProfile'"></atmo-back-link>
    <div v-if="profileUserAccomplishments.length === 0" class="accomplishments-grid-container-empty">No Accomplishments To Display</div>
    <perfect-scrollbar v-else class="accomplishments-grid-container">
      <div class="accomplishments-grid-container__card" v-for="(user_accomplishment, index) in profileUserAccomplishments" :key="index">
        <div v-show="cardHovered === index" class="card__hover-container">
          <div class="hover-container__info-container" @click="closeHover()">
            <img class="info-container__icon" src="@/assets/images/icons/close_popup.png" />
          </div>
          <div class="hover-container__title-container">
            <div class="title-container__number-wrap">{{ user_accomplishment.accomplishment.number }}</div>
            <div class="title-container__text-wrap">{{ user_accomplishment.accomplishment.category }}</div>
          </div>
          <div class="hover-container__content-container">
            {{ user_accomplishment.accomplishment.description }}
          </div>
        </div>
        <div class="card__info-container" @click="openHover(index)">
          <img class="info-container__icon" src="@/assets/images/icons/info.png" />
        </div>
        <div class="card__badge-container">
          <div class="badge-container__icon-container">
            <img class="icon-container__icon" :src="user_accomplishment.accomplishment.image.url" />
          </div>
          <div class="badge-container__text-container">
            <div class="text-container__number-wrap">{{ user_accomplishment.accomplishment.number }}</div>
            <div class="text-container__text-wrap">{{ user_accomplishment.accomplishment.category }}</div>
          </div>
        </div>
        <div class="card__title-container">{{ user_accomplishment.accomplishment.name }}</div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import AtmoBackLink from '@/components/atmo-back-link';
import { callUsersShow } from '@/helpers/axiosCalls';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    AtmoBackLink
  },
  data() {
    return {
      cardClicked: false,
      cardHovered: null,
      user: null
    }
  },
  computed: {
    ...mapGetters('accomplishments', {
      profileUserAccomplishments: 'getProfileUserAccomplishments'
    }),
    userId() {
      return this.$route.params.userId;
    }
  },
  created() {
    console.log('Component created. Fetching user and accomplishments.');
    this.getUser();
    this.fetchProfileUserAccomplishments(this.userId);
  },
  methods: {
    ...mapActions('accomplishments', ['fetchProfileUserAccomplishments']),
    goBack() {
      this.$router.back();
    },
    openHover(index) {
      this.cardHovered = index;
    },
    closeHover() {
      this.cardHovered = null;
    },
    displayCardHover() {
      this.cardClicked = !this.cardClicked;
    },
    getUser() {
      console.log(`Fetching user with id: ${this.userId}`);
      callUsersShow(this.userId)
        .then((response) => {
          console.log('User fetched successfully:', response);
          this.user = response;
        })
        .catch((error) => {
          console.error('Failed to fetch user:', error);
        });
    }
  },
  watch: {
    profileUserAccomplishments(newVal) {
      console.log('Profile user accomplishments updated:', newVal);
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.accomplishments {
  margin: 0 75px;

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
  }

  .back-container__icon-container {
    margin-right: .5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .accomplishments-grid-container-empty {
    min-height: 35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .accomplishments-grid-container {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(5, 1fr);
    max-height: 35rem;
  }

  .accomplishments-grid-container__card {
    background: $atmo-purple--medium-dark;
    min-height: 15rem;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    border-radius: 5px;
  }

  .card__hover-container {
    position: absolute;
    z-index: 3;
    background: rgba(96, 72, 117, 0.9);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 5px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hover-container__info-container {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .info-container__icon {
      height: 1rem;
      opacity: 0.2;
    }
  }

  .hover-container__title-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .title-container__number-wrap {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: .5rem;
  }

  .title-container__text-wrap {
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .hover-container__content-container {
    text-align: center;
  }

  .card__info-container {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 2;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .info-container__icon {
      height: 1rem;
      opacity: 0.2;
    }
  }

  .card__badge-container {
    height: 11rem;
    background-image: url('@/assets/images/icons/accomplishments/badge.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative;
    margin-bottom: 1.5rem;
  }

  .badge-container__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 2.7rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
  }

  .icon-container__icon {
    height: 3rem;
  }

  .badge-container__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 9rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .text-container__number-wrap {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2px;
  }

  .text-container__text-wrap {
    text-transform: uppercase;
    color: $atmo-white--medium-faded;
    text-align: center;
  }

  .card__title-container {
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
</style>
