import Axios from 'axios';

export function callAtmoRadioStationsConfigurationsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/atmo_radio_stations_configurations`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAtmoRadioStationsConfigurationsShow(userId, queryParams = {}) {
    return Axios.get(`/api/v1/atmo_radio_stations_configurations/${userId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}