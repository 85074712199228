import { callCommentsIndex } from '@/helpers/axiosCalls';

export default {
  namespaced: true,
  state: {
    debug: true,
    backgroundVideoMuted: false,
    videoBackgroundUrl: "https://atmosphere-development.s3.amazonaws.com/losing_you.mp4",
    videoBackgroundObject: null,
    videoBackgroundLyrics: null,
    videoBackgroundName: null,
    videoPlayerObject: null,
    backgroundVidPlaying: false,
    mainContentDisplayed: false,
    playPausePushed: false,
    userMediaModalComments: [],
    userMediaModalObj: { type: '', caption: '', id: null, url: '' }, // Default object with expected properties
    modalOrBackgroundVidPlaying: false,
    userMediaModalOpen: false
  },
  mutations: {
    setBackgroundVideoMuted(state, mutedStatus) {
      state.backgroundVideoMuted = mutedStatus;
    },
    toggleUserMediaModal(state) {
      if (state.debug) console.log("Toggling user media modal", state.userMediaModalOpen);
      state.userMediaModalOpen = !state.userMediaModalOpen;
    },
    openUserMediaModal(state) {
      if (state.debug) console.log("Opening user media modal", state.userMediaModalOpen);
      state.userMediaModalOpen = true;
    },
    closeUserMediaModal(state) {
      if (state.debug) console.log("Closing user media modal", state.userMediaModalOpen);
      state.userMediaModalOpen = false;
    },
    setVideoBackgroundUrl(state, videoUrl) {
      if (state.debug) console.log("Setting video background URL:", videoUrl);
      state.videoBackgroundUrl = videoUrl;
    },
    setVideoBackgroundObject(state, value) {
      if (state.debug) console.log("Setting video background object:", value);
      state.videoBackgroundObject = value;
    },
    setVideoBackgroundLyrics(state, value) {
      if (state.debug) console.log("Setting video background lyrics:", value);
      state.videoBackgroundLyrics = value;
    },
    setVideoBackgroundName(state, value) {
      if (state.debug) console.log("Setting video background name:", value);
      state.videoBackgroundName = value;
    },
    setVideoPlayerObject(state, video) {
      if (state.debug) console.log("Setting video player object:", video);
      state.videoPlayerObject = video;
    },
    toggleIsBackgroundVidPlaying(state) {
      if (state.debug) console.log("Toggling background video playing state");
      state.backgroundVidPlaying = !state.backgroundVidPlaying;
    },
    toggleMainContent(state) {
      if (state.debug) console.log("Toggling main content visibility");
      state.mainContentDisplayed = !state.mainContentDisplayed;
    },
    playPauseVideo(state) {
      if (state.debug) console.log("Toggling play/pause on video player");

      if (state.videoPlayerObject && state.videoPlayerObject.player) {
        if (state.playPausePushed) {
          state.videoPlayerObject.player.play();
          state.playPausePushed = false;
        } else {
          state.videoPlayerObject.player.pause();
          state.playPausePushed = true;
        }
      } else {
        console.error("Video player object or player is not defined.");
      }
    },
    playVideo(state) {
      // https://github.com/avidofood/vue-responsive-video-background-player
      if (state.videoPlayerObject && state.videoPlayerObject.player) {
        this.commit("videoPlayer/toggleIsBackgroundVidPlaying");
        this.commit("videoPlayer/toggleMainContent");
        this.commit("videoPlayer/setVideoEndedListener");
      } else {
        console.error("Video player object or player is not defined.");
      }
    },
    hideVideo(state) {
      state.videoPlayerObject.player.pause();
      state.videoPlayerObject.player.hide();
      this.commit("videoPlayer/setModalOrBackgroundVidPlaying", false);
    },
    setUserMediaModalComments(state, comments) {
      if (state.debug) console.log("Setting user media modal comments:", comments);
      for (const comment of comments) {
        comment.editMode = false; // Add the editMode property directly to each comment
      }

      const sortedComments = comments.slice().sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateA - dateB;
      });

      state.userMediaModalComments = sortedComments;
    },
    setUserMediaModalObj(state, obj) {
      if (state.debug) console.log("Setting user media modal object:", obj);
      state.userMediaModalObj = obj;
    },
    setModalOrBackgroundVidPlaying(state, value) {
      if (state.debug) console.log("Setting modal or background video playing state:", value);
      state.modalOrBackgroundVidPlaying = value;
    },
    setVideoEndedListener(state) {
      const video = document.querySelector("video");
      if (state.debug) console.log("Setting video ended listener");
      video.onplay = () => {
        this.commit("videoPlayer/setModalOrBackgroundVidPlaying", true);
      };
      video.onended = () => {
        state.videoPlayerObject.player.hide();
        this.commit("videoPlayer/toggleIsBackgroundVidPlaying");
        this.commit("videoPlayer/setModalOrBackgroundVidPlaying", false);
      };
    },
  },
  actions: {
    fetchUserMediaModalComments(state, obj) {
      if (state.debug) console.log("Fetching comments for media modal:", obj);
      callCommentsIndex({
        commentable_id: obj.id,
        commentable_type: obj.type
      })
        .then((response) => {
          if (state.debug) console.log("Comments fetched successfully:", response);
          const comments = response;
          this.commit("videoPlayer/setUserMediaModalComments", comments);
        })
        .catch((error) => {
          console.error("Error fetching comments:", error);
        });
    },
    // playPauseVideo({ commit, state }) {
    //   if (state.debug) console.log("Action: play/pause video");
    //   commit('playPauseVideo');
    // },
    setVideoEndedListener({ commit, state }) {
      if (state.debug) console.log("Action: set video ended listener");
      commit('setVideoEndedListener');
    },
  },
  getters: {
    isVideoPlaying: (state) => {
      if (state.debug) console.log("Getter: is video playing:", state.backgroundVidPlaying);
      return state.backgroundVidPlaying;
    },
    getUserMediaModalCommentById: (state) => (id) => {
      const comment = state.userMediaModalComments.find((comment) => comment.id === id);
      if (state.debug) console.log(`Getter: comment by id (${id}):`, comment);
      return comment;
    },
  },
};
