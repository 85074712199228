<template>
    <div class="advertisements" data-cy="advertisements">
        <div class="header-container">
            <h2 class="atmo-page-header">
                My Ads
            </h2>
        </div>

        <div class="advertisements__create-filter-search-container">
            <router-link class="create-filter-search-container__create-ads-wrap"
                :to="{ name: 'advertisements.new', params: { userId: this.$route.params.userId } }">
                <div class="create-ads-wrap__icon-wrap">
                    <img class="icon-wrap__icon" src="@/assets/images/icons/add.png" />
                </div>
                <div class="create-ads-wrap__text-wrap">Create Ad</div>
            </router-link>
            <!-- <div class="create-filter-search-container__filter-wrap">
                Image Ads / Video Ads
            </div> -->
            <div class="create-filter-search-container__search-wrap">
                <div class="search-wrap__search-input-wrap">
                    <input class="search-input-wrap__search-field-rounded" type="text" placeholder="Search genres" />
                </div>
            </div>
        </div>

        <div class="advertisements-grid-container">
            <div class="advertisements-grid-container__card">
                <div class="card__image-wrap"
                    v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require(`@/assets/images/artists/high-res/${generateRandomString(highResImageArray)}`) + ')' }">
                    <div class="image-wrap__status-wrap">
                        <div class="status-wrap__status active">Active</div>
                    </div>
                </div>
                <div class="card__text-wrap">
                    <div class="text-wrap__title-wrap">{{ generateRandomString(adHeadlineArray) }}</div>
                    <div class="text-wrap__body-wrap">{{ generateRandomString(adDescriptionArray) }}</div>
                </div>
                <div class="text-wrap__button-popover-wrap">
                    <div class="button-popover-wrap__button-wrap">
                        <router-link class="button-wrap__button" :to="{ name: 'coming_soon' }">
                            See Details
                        </router-link>
                    </div>
                    <div class="button-popover-wrap__popover-wrap">
                        <!-- THIS IS TEMPORARY WHILE WE BUILD POPOVER -->
                        <router-link class="temp-link"
                            :to="{ name: 'advertisements.edit', params: { userId: this.$route.params.userId } }">
                            <div class="popover-wrap__icon-wrap">
                                <img src="@/assets/images/icons/advertisements/more.png" class="icon-wrap__icon" />
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="advertisements-grid-container__card">
                <div class="card__image-wrap"
                    v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require(`@/assets/images/artists/high-res/${generateRandomString(highResImageArray)}`) + ')' }">
                    <div class="image-wrap__status-wrap">
                        <div class="status-wrap__status paused">Paused</div>
                    </div>
                </div>
                <div class="card__text-wrap">
                    <div class="text-wrap__title-wrap">{{ generateRandomString(adHeadlineArray) }}</div>
                    <div class="text-wrap__body-wrap">{{ generateRandomString(adDescriptionArray) }}</div>
                </div>
                <div class="text-wrap__button-popover-wrap">
                    <div class="button-popover-wrap__button-wrap">
                        <router-link class="button-wrap__button" :to="{ name: 'coming_soon' }">
                            See Details
                        </router-link>
                    </div>
                    <div class="button-popover-wrap__popover-wrap">
                        <div class="popover-wrap__icon-wrap">
                            <img src="@/assets/images/icons/advertisements/more.png" class="icon-wrap__icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="advertisements-grid-container__card">
                <div class="card__image-wrap"
                    v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require(`@/assets/images/artists/high-res/${generateRandomString(highResImageArray)}`) + ')' }">
                    <div class="image-wrap__status-wrap">
                        <div class="status-wrap__status active completed">Completed</div>
                    </div>
                </div>
                <div class="card__text-wrap">
                    <div class="text-wrap__title-wrap">{{ generateRandomString(adHeadlineArray) }}</div>
                    <div class="text-wrap__body-wrap">{{ generateRandomString(adDescriptionArray) }}</div>
                </div>
                <div class="text-wrap__button-popover-wrap">
                    <div class="button-popover-wrap__button-wrap">
                        <router-link class="button-wrap__button" :to="{ name: 'coming_soon' }">
                            See Details
                        </router-link>
                    </div>
                    <div class="button-popover-wrap__popover-wrap">
                        <div class="popover-wrap__icon-wrap completed">
                            <img src="@/assets/images/icons/advertisements/more.png" class="icon-wrap__icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="advertisements-grid-container__card">
                <div class="card__image-wrap"
                    v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require(`@/assets/images/artists/high-res/${generateRandomString(highResImageArray)}`) + ')' }">
                    <div class="image-wrap__status-wrap">
                        <div class="status-wrap__status scheduled">Scheduled</div>
                    </div>
                </div>
                <div class="card__text-wrap">
                    <div class="text-wrap__title-wrap">{{ generateRandomString(adHeadlineArray) }}</div>
                    <div class="text-wrap__body-wrap">{{ generateRandomString(adDescriptionArray) }}</div>
                </div>
                <div class="text-wrap__button-popover-wrap">
                    <div class="button-popover-wrap__button-wrap">
                        <router-link class="button-wrap__button" :to="{ name: 'coming_soon' }">
                            See Details
                        </router-link>
                    </div>
                    <div class="button-popover-wrap__popover-wrap">
                        <div class="popover-wrap__icon-wrap">
                            <img src="@/assets/images/icons/advertisements/more.png" class="icon-wrap__icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="advertisements-grid-container__card">
                <div class="card__image-wrap"
                    v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require(`@/assets/images/artists/high-res/${generateRandomString(highResImageArray)}`) + ')' }">
                    <div class="image-wrap__status-wrap">
                        <div class="status-wrap__status active">Active</div>
                    </div>
                </div>
                <div class="card__text-wrap">
                    <div class="text-wrap__title-wrap">{{ generateRandomString(adHeadlineArray) }}</div>
                    <div class="text-wrap__body-wrap">{{ generateRandomString(adDescriptionArray) }}</div>
                </div>
                <div class="text-wrap__button-popover-wrap">
                    <div class="button-popover-wrap__button-wrap">
                        <router-link class="button-wrap__button" :to="{ name: 'coming_soon' }">
                            See Details
                        </router-link>
                    </div>
                    <div class="button-popover-wrap__popover-wrap">
                        <div class="popover-wrap__icon-wrap">
                            <img src="@/assets/images/icons/advertisements/more.png" class="icon-wrap__icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="advertisements-grid-container__card">
                <div class="card__image-wrap"
                    v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require(`@/assets/images/artists/high-res/${generateRandomString(highResImageArray)}`) + ')' }">
                    <div class="image-wrap__status-wrap">
                        <div class="status-wrap__status paused">Paused</div>
                    </div>
                </div>
                <div class="card__text-wrap">
                    <div class="text-wrap__title-wrap">{{ generateRandomString(adHeadlineArray) }}</div>
                    <div class="text-wrap__body-wrap">{{ generateRandomString(adDescriptionArray) }}</div>
                </div>
                <div class="text-wrap__button-popover-wrap">
                    <div class="button-popover-wrap__button-wrap">
                        <router-link class="button-wrap__button" :to="{ name: 'coming_soon' }">
                            See Details
                        </router-link>
                    </div>
                    <div class="button-popover-wrap__popover-wrap">
                        <div class="popover-wrap__icon-wrap">
                            <img src="@/assets/images/icons/advertisements/more.png" class="icon-wrap__icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="advertisements-grid-container__card">
                <div class="card__image-wrap"
                    v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require(`@/assets/images/artists/high-res/${generateRandomString(highResImageArray)}`) + ')' }">
                    <div class="image-wrap__status-wrap">
                        <div class="status-wrap__status completed">Completed</div>
                    </div>
                </div>
                <div class="card__text-wrap">
                    <div class="text-wrap__title-wrap">{{ generateRandomString(adHeadlineArray) }}</div>
                    <div class="text-wrap__body-wrap">{{ generateRandomString(adDescriptionArray) }}</div>
                </div>
                <div class="text-wrap__button-popover-wrap">
                    <div class="button-popover-wrap__button-wrap">
                        <router-link class="button-wrap__button" :to="{ name: 'coming_soon' }">
                            See Details
                        </router-link>
                    </div>
                    <div class="button-popover-wrap__popover-wrap">
                        <div class="popover-wrap__icon-wrap">
                            <img src="@/assets/images/icons/advertisements/more.png" class="icon-wrap__icon" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="advertisements-grid-container__card">
                <div class="card__image-wrap"
                    v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + require(`@/assets/images/artists/high-res/${generateRandomString(highResImageArray)}`) + ')' }">
                    <div class="image-wrap__status-wrap">
                        <div class="status-wrap__status scheduled">Scheduled</div>
                    </div>
                </div>
                <div class="card__text-wrap">
                    <div class="text-wrap__title-wrap">{{ generateRandomString(adHeadlineArray) }}</div>
                    <div class="text-wrap__body-wrap">{{ generateRandomString(adDescriptionArray) }}</div>
                </div>
                <div class="text-wrap__button-popover-wrap">
                    <div class="button-popover-wrap__button-wrap">
                        <router-link class="button-wrap__button" :to="{ name: 'coming_soon' }">
                            See Details
                        </router-link>
                    </div>
                    <div class="button-popover-wrap__popover-wrap">
                        <div class="popover-wrap__icon-wrap">
                            <img src="@/assets/images/icons/advertisements/more.png" class="icon-wrap__icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Axios from 'axios'
import devMockData from '@/devUtils/mockData.js';

export default {
    data: function () {
        return {
            advertisements: [],
            highResImageArray: devMockData.highResImageArray,
            adDescriptionArray: devMockData.adDescriptionArray,
            adHeadlineArray: devMockData.adHeadlineArray,
        }
    },
    created() {
        this.getAdvertisements();
    },
    methods: {
        getAdvertisements() {
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}/advertisements`)
                .then(response => (this.advertisements = response.data));
        }
    }
}
</script>
  
<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.advertisements {
    margin: 0 75px;

    .temp-link {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .advertisements__create-filter-search-container {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(2, 1fr);
        // border: 1px solid white;
        padding: 1rem 0rem 1rem 0rem;
        align-items: center;
    }

    .create-filter-search-container__create-ads-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .create-ads-wrap__icon-wrap {
        width: 5rem;
        width: auto;
        margin-right: .5rem;

        img {
            height: 2rem;
            width: auto;
        }
    }

    .create-ads-wrap__text-wrap {
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 500;
    }

    .create-filter-search-container__search-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(255, 255, 255, .4);
        opacity: 1;
        /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(255, 255, 255, .4);
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(255, 255, 255, .4);
    }

    .search-input-wrap__search-field-rounded {
        background: transparent;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, .4);
        padding: .5rem .5rem .5rem 2.5rem;
        color: white;
        margin-bottom: 1rem;
        width: 100%;
        position: relative;
        background: url('@/assets/images/icons/search.png') no-repeat scroll 7px 7px;
        background-size: 1rem;
        background-position: .8rem .5rem;
    }

    .advertisements-grid-container {
        // border: 1px solid white;
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(4, 1fr);
    }

    .advertisements-grid-container__card {
        background: rgba(255, 255, 255, 0.1);
        min-height: 15rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        padding: 1rem;
        border-radius: 5px;
    }

    .card__image-wrap {
        height: 7rem;
        margin-bottom: 1rem;
        position: relative;
    }

    .image-wrap__status-wrap {
        position: absolute;
        top: .5rem;
        right: .5rem;
    }

    .status-wrap__status {
        border-radius: 15px;
        padding: .5rem 1.5rem .5rem 1.5rem;
        font-size: .8rem;

        &.active {
            background-color: $atmo-blue--medium;
        }

        &.paused {
            background-color: $atmo-pink--medium;
        }

        &.completed {
            background-color: $atmo-purple--medium;
        }

        &.scheduled {
            background-color: $atmo-purple--dark;
        }
    }

    .card__text-wrap {
        // display: flex;
        // flex-direction: column;
        // flex: 1;
        // justify-content: space-between;
        // margin-top: 1rem;
    }

    .text-wrap__title-wrap {
        font-weight: 600;
        font-size: 1.2rem;
        margin-bottom: .5rem;
    }

    .text-wrap__body-wrap {
        margin-bottom: 1rem;
    }

    .text-wrap__button-popover-wrap {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .button-wrap__button {
        display: inline-block;
        color: white;
        padding: .3rem .5rem .3rem .5rem;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
        border: 1px solid white;
        text-transform: uppercase;
    }

    .button-popover-wrap__popover-wrap {
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }

    .popover-wrap__icon-wrap {
        height: 1.5rem;
        width: 1.5rem;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .icon-wrap__icon {
        height: .2rem;
    }

}
</style>