import createChannel from '@/cable';

let callback; // declaring a variable that will hold a function later

let chat = null;

function connectToBroadcastPostsChannel(roomId) {
  chat = createChannel(`BroadcastPostsChannel`, roomId, {
    received(data) {
      console.log("Received data in BroadcastPostsChannel:", data);
      if (callback) callback.call(null, data); // Pass the entire data object to the callback
    }
  });
}

// Getting a message: this callback will be invoked once we receive
// something over BroadcastPostsChannel
function setCallback(fn) {
  callback = fn;
}

export { connectToBroadcastPostsChannel, setCallback };
