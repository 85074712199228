<template>
  <div class="atmo-progress-bar-background">
    <b-progress
      :value="percent"
      class="atmo-progress-bar-meter"
    />
  </div>
</template>

<script>
  import { BProgress } from "bootstrap-vue";

  export default {
    components: {
      BProgress
    },
    props: {
      percent: {
        type: Number,
        required: true
      }
    }
  };
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-progress-bar-background {
  .atmo-progress-bar-meter {
    max-height: 6px;

    .progress-bar {
      background: $atmo-blue--medium; // overrides bootstrap specific colors
    }
  }
}
</style>