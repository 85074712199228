<template>
    <div class="back-container" @click="goBack()">
        <div class="back-container__icon-container">
            <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
        </div>
        <div class="back-container__text-container">Back to previous</div>
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            // Use router.go() to go back one step in the history
            // this.$router.go(-1);

            // Alternatively, you can use router.back() to do the same
            this.$router.back();
        },
    }
}
</script>


<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
    width: 100%;
    // border: 1px solid white;
}

.back-container__icon-container {
    margin-right: .5rem;
}

.icon-container__back-icon {
    height: 1rem;
}
</style>