<template>
    <b-modal id="group-chat-settings-modal" ref="groupChatSettingsModalRef" size="lg"
        v-model="$store.state.chatRooms.groupChatSettingsModalOpen" title="groupChatSettingsModal"
        modal-class="atmo-modal create-group-chat" hide-header hide-footer centered>
        <img class="create-group-chat__hide-modal" src="@/assets/images/icons/close_popup.png" alt="close"
            title="Hide Group Chat Settings Modal" @click="$refs.groupChatSettingsModalRef.hide()">
        <header class="create-group-chat__header atmo-modal__header">
            <div class="atmo-page-header-container">
                <h2 class="atmo-page-header">
                    Group Chat Settings
                </h2>
            </div>
        </header>
        <div class="create-group-chat__view-container">
            <div @click="toggleView('room')" :class="[{ 'view-selected': selectedView == 'room' }, 'settings-view']">
                Room Settings
            </div>
            <div @click="toggleView('users')" :class="[{ 'view-selected': selectedView == 'users' }, 'settings-view']">
                Manage Users
            </div>
        </div>
        <div class="create-group-chat__room-name-container" v-if="selectedView == 'room'">
            <div class="room-name-container__header">Name of room:</div>
            <div class="room-name-container__search-wrap">
                <input class="input-field-semi-rounded" type="text" placeholder="Group Chat Name" v-model="roomName" />
            </div>
        </div>
        <div class="create-group-chat__image-upload-container" v-if="selectedView == 'room'">
            <div class="image-upload-container__header">Update room image:</div>
            <atmo-upload-card :id="`group-chat-upload`" size="small" icon-size="small" alt="Upload Image">
            </atmo-upload-card>
        </div>
        <div class="create-group-chat__members-container" v-if="selectedView == 'users'">
            <div class="members-container__types-container">
                <div @click="selectMemberType('all')"
                    :class="[{ 'member-type-selected': selectedMemberType === 'all' }, 'member-type']">All</div>
                <div @click="selectMemberType('admin')"
                    :class="[{ 'member-type-selected': selectedMemberType === 'admin' }, 'member-type']">Admins</div>
                <div @click="selectMemberType('add-users')"
                    :class="[{ 'member-type-selected': selectedMemberType === 'add-users' }, 'member-type']">Add Users
                </div>
            </div>
            <div>
                <div class="create-group-chat__users-container" v-if="selectedMemberType === 'all'">
                    <perfect-scrollbar>
                        <div :class="[{ 'clicked': clickedItems.includes(index) }, 'users-container__user-wrap']"
                            v-for="(roomUser, index) in roomUsers" :key="roomUser.id">
                            <div class="user-wrap__image-wrap"><img class="image-wrap__image"
                                    :src="getFeaturedImage(roomUser.user.images, 'full')" /></div>
                            <div class="user-wrap__name-wrap">{{ roomUser.user.name }}</div>
                            <div class="user-wrap__manage-wrap">
                                <div @click="removeFromChat(roomUser)">Remove from chat</div>
                                <div @click="addAsAdmin(roomUser)">Add as admin</div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
                <div class="create-group-chat__users-container" v-if="selectedMemberType === 'admin'">
                    <perfect-scrollbar>
                        <div :class="[{ 'clicked': clickedItems.includes(index) }, 'users-container__user-wrap']"
                            v-for="(roomUser, index) in roomUsers" :key="roomUser.id">
                            <div class="user-wrap__image-wrap"><img class="image-wrap__image"
                                    :src="getFeaturedImage(roomUser.user.images, 'full')" /></div>
                            <div class="user-wrap__name-wrap">{{ roomUser.user.name }}</div>
                            <div class="user-wrap__manage-wrap">
                                <div @click="removeFromChat(roomUser)">Remove from chat</div>
                                <div @click="removeAsAdmin(roomUser)">Remove as admin</div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
                <div class="create-group-chat__users-container" v-if="selectedMemberType === 'add-users'">
                    <div class="users-container__search-wrap">
                        <input class="search-field-rounded" type="text" placeholder="Search for a friend"
                            v-model="searchQuery" @input="filterList" />
                    </div>
                    <perfect-scrollbar>
                        <div :class="[{ 'clicked': clickedItems.includes(index) }, 'users-container__user-wrap']"
                            v-for="(friendship, index) in filteredFriendList" :key="friendship.id">
                            <div class="user-wrap__image-wrap"><img class="image-wrap__image"
                                    :src="getFeaturedImage(friendship.friend.images, 'full')" /></div>
                            <div class="user-wrap__name-wrap">{{ friendship.friend.name }}</div>
                            <div class="user-wrap__manage-wrap">
                                <div @click="saveGroupChatUser(friendship.friend.id, false)">Add as member</div>
                                <div @click="saveGroupChatUser(friendship.friend.id, true)">Add as admin</div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                </div>
            </div>
        </div>
        <div class="create-group-chat__icons-container" v-if="selectedView == 'room'">
            <div class="icon-wrap" @click="testingClick()">
                <div class="icon-wrap__icon-container">
                    <img class="icon" src="@/assets/images/icons/sound--muted.png" />
                </div>
                <div class="icon-wrap__text-container">Mute</div>
            </div>
            <div class="icon-wrap" @click="testingClick()">
                <div class="icon-wrap__icon-container">
                    <img class="icon leave-icon" src="@/assets/images/icons/chat/leave_icon.png" />
                </div>
                <div class="icon-wrap__text-container">Leave Chat</div>
            </div>
            <div class="icon-wrap" @click="testingClick()">
                <div class="icon-wrap__icon-container">
                    <img class="icon" src="@/assets/images/icons/delete.png" />
                </div>
                <div class="icon-wrap__text-container">Delete Chat</div>
            </div>
        </div>
        <div class="create-group-chat__buttons-container" v-if="selectedView == 'room'">
            <div class="buttons-container__save-container">
                <button v-if="selectedUsers.length > 0" class="save-button" @click="saveGroupChat()">Save</button>
                <button v-else class="save-button--unusable">Save</button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import AtmoUploadCard from '@/components/atmo-upload-card';
import { getFeaturedImage } from '@/helpers/utilityFunctions';
import {
    callChatRoomUsersIndex,
    callChatRoomUsersCreate,
    callChatRoomUsersDelete,
    callChatRoomUsersUpdate
} from '@/helpers/axiosCalls';

export default {
    components: {
        AtmoUploadCard
    },
    data() {
        return {
            selectedUsers: [],
            clickedItems: [],
            roomName: '',
            selectedMemberType: 'all',
            selectedView: 'room',
            searchQuery: ''
        }
    },
    computed: {
        roomUsers() {
            return this.$store.state.chatRooms.currentChatRoomUsers;
        },
        acceptedFriendships() {
            return this.$store.state.chatRooms.chatUserFriendships.filter(friendship =>
                friendship.state === 'accepted'
            );
        },
        filteredFriendList() {
            if (!this.acceptedFriendships) {
                return [];
            }
            return this.acceptedFriendships.filter(friendship =>
                friendship.friend.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        }
    },
    methods: {
        getFeaturedImage(userImages, type) {
            return getFeaturedImage(userImages, type);
        },
        filterList() {
            // This method is bound to the input event and updates the filtered list
            // based on the search query in real-time.
        },
        toggleView(view) {
            this.selectedView = view;
        },
        removeFromChat(roomUser) {
            callChatRoomUsersDelete(
                this.$store.state.chatRooms.currentChatRoom.id, 
                roomUser.id,
                { type: this.selectedMemberType }
            )
                .then(response => {
                    this.$store.commit('chatRooms/setRoomUsers', response);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        removeAsAdmin(roomUser) {
            callChatRoomUsersUpdate(
                this.$store.state.chatRooms.currentChatRoom.id,
                roomUser.id,
                {
                    user_id: roomUser.user_id,
                    chat_room_id: roomUser.chat_room_id,
                    is_admin: false
                }
            )
                .then(response => {
                    this.$store.commit('chatRooms/setRoomUsers', response);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        addAsAdmin(roomUser) {
            callChatRoomUsersUpdate(
                this.$store.state.chatRooms.currentChatRoom.id,
                roomUser.id,
                {
                    user_id: roomUser.user_id,
                    chat_room_id: roomUser.chat_room_id,
                    is_admin: true
                }
            )
                .then(response => {
                    this.$store.commit('chatRooms/setRoomUsers', response);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        selectMemberType(memberType) {
            this.selectedMemberType = memberType;

            if (memberType === 'all' || memberType === 'admin') {
                callChatRoomUsersIndex(this.$store.state.chatRooms.currentChatRoom.id,
                    { type: this.selectedMemberType }
                )
                    .then(response => {
                        this.$store.commit('chatRooms/setRoomUsers', response);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },
        saveGroupChatUser(userId, isAdmin) {
            callChatRoomUsersCreate(
                this.$store.state.chatRooms.currentChatRoom.id,
                {
                    chat_room_id: this.$store.state.chatRooms.currentChatRoom.id,
                    user_id: userId,
                    is_admin: isAdmin
                }
            )
                .then(() => {
                    console.log(`User ${userId} added to room ${this.$store.state.chatRooms.currentChatRoom.id}`);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        saveGroupChat() {
            // Handle saving the group chat settings here
            console.log("Saving group chat settings...");
        },
        testingClick() {
            window.alert("Action triggered");
        }
    }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';


#group-chat-settings-modal {
    .modal-content {
        border-radius: 5px;
        border: none;
        width: 100vw;
        min-height: 76vh;
    }
}

.modal-lg.modal-dialog {
    max-width: 100vw;
    margin: 1rem;
}

.create-group-chat {

    //https://github.com/mercs600/vue2-perfect-scrollbar
    .ps {
        min-height: 27rem;
        max-height: 28rem;
    }

    .create-group-chat__hide-modal {
        position: absolute;
        top: 1rem;
        left: 1rem;
        height: 2rem;
        width: 2rem;
        opacity: 0.5;
        cursor: pointer;
    }

    .create-group-chat__header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 1rem;
        margin-bottom: 2rem;

        .atmo-page-header {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
        }
    }

    .create-group-chat-subheader {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
    }

    .modal-content {
        padding: 2rem;
    }

    .create-group-chat__view-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-bottom: 2rem;

        .settings-view {
            text-transform: uppercase;
            cursor: pointer;
            font-size: 1rem;
        }

        .view-selected {
            border-bottom: 1px solid rgba(255, 255, 255, .4);
        }
    }

    .create-group-chat__icons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;

        .icon-wrap__icon-container {
            border: 3px solid white;
            height: 3rem;
            width: 3rem;
            border-radius: 100%;
            margin-bottom: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        img {
            height: 1.5rem;
        }

        .icon-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .leave-icon {
            height: 1.8rem;
        }
    }


    .create-group-chat__room-name-container {}

    .room-name-container__header {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .room-name-container__search-wrap {
        margin-bottom: 1rem;
    }

    .create-group-chat__image-upload-container {
        margin-bottom: 1rem;
    }

    .image-upload-container__header {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .image-upload-container__search-wrap {
        margin-bottom: 1rem;
    }

    .members-container__types-container {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;

        .member-type {
            min-width: 5rem;
            font-size: 1rem;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: .4rem;
            border-radius: 5px;
            // background-color: rgba(96, 72, 117, 0.3);
            // backdrop-filter: blur(5px);
            cursor: pointer;
            border: 1px solid rgba(255, 255, 255, .4);
        }

        .member-type-selected {
            background-color: rgba(255, 255, 255, .1);
        }
    }

    .create-group-chat__users-container {
        margin-bottom: 1rem;
    }

    .users-container__header {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .users-container__search-wrap {
        margin-bottom: 1rem;
    }

    .input-field-semi-rounded {
        background: transparent;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, .4);
        padding: .5rem 1rem .5rem 1rem;
        color: white;
        width: 100%;
        position: relative;
    }

    .search-field-rounded {
        background: transparent;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, .4);
        padding: .5rem .5rem .5rem 2.5rem;
        color: white;
        width: 100%;
        position: relative;
        background: url('@/assets/images/icons/search.png') no-repeat scroll 7px 7px;
        background-size: 1rem;
        background-position: .8rem .5rem;
    }

    .users-container__user-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
        border-radius: 5px;
        background-color: rgba(96, 72, 117, 0.3);
        backdrop-filter: blur(5px);
        margin-bottom: 1rem;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0);

        &.clicked {
            border: 1px solid $atmo-blue--medium;
        }

        .image-wrap__image {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 100%;
            margin-right: .5rem;
        }

        .user-wrap__name-wrap {
            font-size: .9rem;
            font-weight: 500;
        }

        &:hover {
            background-color: rgba(190, 167, 221, .3);

            .user-wrap__manage-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: 1rem;
                gap: 1rem;
            }
        }

        .user-wrap__manage-wrap {
            display: none;
        }

        .delete-wrap__icon {
            height: .7rem;
            opacity: .3;
        }
    }

    .create-group-chat__buttons-container {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
    }

    .buttons-container__save-container {
        display: flex;
    }

    .remove-from-chat-button {
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        // background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
    }

    .remove-admin-button {
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        // background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
    }

    .make-admin-button {
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
    }

    .save-button {
        margin-left: auto;
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
    }

    .save-button--unusable {
        margin-left: auto;
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: rgba(0, 0, 0, .2);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
    }
}
</style>