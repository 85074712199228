import Axios from 'axios';

export function callDirectoryProfilesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/directory_profiles`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDirectoryProfilesShow(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/directory_profile`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDirectoryProfilesUpdate(profileId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/directory_profiles/${profileId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}