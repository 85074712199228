<template>
  <div v-if="comments === null">
    Loading Comments…
  </div>
  <div class="comment-list-component" v-else>
    <label class="comment-list-component__input-wrapper">
      <input v-model="newCommentContent" autofocus placeholder="Type a comment" type="text"
        class="comment-list-component__input" @keyup.enter="createPostComment()">
    </label>
    <div class="comment-list-component__comments-list">
      <div v-for="comment in comments" :key="comment.id" class="comment-list-component__comment">
        <profile-link :profile-type="comment.user.profile_type" :profile-id="comment.user.profile_id">
          <div class="atmo-avatar atmo-avatar--small"
            :style="{ 'background-image': 'url(' + getFeaturedImage(comment.user.images, 'full') + ')' }" />
        </profile-link>
        <div class="comment__comment-container">
          <div class="comment__main-content-container">
            <div class="comment__comment-header">
              <div class="comment__comment-identifier">
                <span class="comment__comment-owner">
                  <profile-link :profile-type="comment.user.profile_type" :profile-id="comment.user.profile_id">
                    <span class="comment__comment-owner">
                      {{ comment.user.name }}
                    </span>
                  </profile-link>
                </span>
                <span class="comment__comment-date">
                  {{ getDate(comment.created_at) }}
                </span>
              </div>
            </div>
            <div class="comment__comment-content">
              {{ comment.content }}
            </div>
          </div>
          <div class="comment__social-buttons">
            <button class="
                  atmo-button
                  comment__social-button
                  comment__social-button--small
                " @click="likeComment(comment.id)">
              <img class="atmo-button__icon comment__icon--not-hovered" src="@/assets/images/icons/like--purple.png"
                alt="Like">
              <img class="atmo-button__icon comment__icon--hovered" src="@/assets/images/icons/like.png" alt="Like">
              {{ comment.likes.length }}
            </button>
            <button class="
                  atmo-button
                  comment__social-button
                  comment__social-button--small
                " @click="dislikeComment(comment.id)">
              <img class="atmo-button__icon comment__icon--not-hovered" src="@/assets/images/icons/dislike--purple.png"
                alt="Dislike">
              <img class="atmo-button__icon comment__icon--hovered" src="@/assets/images/icons/dislike.png"
                alt="Dislike">
              {{ comment.dislikes.length }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { utilsGetDate, getFeaturedImage } from '@/helpers/utilityFunctions';
import {
  callCommentsIndex,
  callCommentsCreate,
  callLikesCreate,
  callDislikesCreate,
} from '@/helpers/axiosCalls';
import { mapActions } from 'vuex';
import ProfileLink from '@/components/profile-link'

export default {
  components: { 
    ProfileLink 
  },
  props: {
    type: { type: String, default: 'billboard' }, // billboard or broadcast
    baseUrl: { type: String, required: true },
    post: { type: Object, required: true },
    userProfile: { type: Object, required: true }
  },
  data() {
    return {
      comments: null,
      newCommentContent: ''
    }
  },
  created() {
    this.getComments();
  },
  methods: {
    ...mapActions('accomplishments', ['createUserAccomplishments']),
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    getDate(date) {
      return utilsGetDate(date);
    },
    getComments() {
      callCommentsIndex({
        commentable_id: this.post.id,
        commentable_type: 'Post'
      })
        .then((res) => {
          this.comments = res;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    createPostComment() {
      callCommentsCreate({
        content: this.newCommentContent,
        user_id: this.$store.state.currentUserId,
        commentable_id: this.post.id,
        commentable_type: "Post"
      })
        .then((response) => {
          this.newCommentContent = '';
          this.getComments();

          this.createUserAccomplishments({
            userId: this.$store.state.currentUserId,
            accomplishmentData: {
              payloads: [
                {
                  user_id: this.$store.state.currentUserId,
                  category: 'comments',
                  sub_category: 'commentNumber'
                }
              ]
            }
          });

          if (this.$store.state.currentUserId !== this.post.billboard_id) {
            this.$store.dispatch('notifications/createNotification', {
              userId: this.post.billboard_id,
              payload: {
                sender_id: this.$store.state.currentUserId,
                receiver_id: this.post.billboard_id,
                notification_type_name: "Billboard Comment",
                status: 'unread',
                notifiable_type: 'Post',
                notifiable_id: this.post.id
              }
            });
          }

          this.$store.dispatch('userActivities/createUserActivity',
            {
              user_activity_type_name: "Post Comment",
              user_id: this.$store.state.currentUserId,
              activityable_type: "Post",
              activityable_id: response.commentable.id
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    likeComment(commentId) {
      callLikesCreate(
        {
          user_id: this.$store.state.currentUserId,
          likeable_id: commentId,
          likeable_type: "Comment"
        })
        .then((response) => {
          this.getComments();

          this.$store.dispatch('userActivities/createUserActivity',
            {
              user_activity_type_name: "Post Like",
              user_id: this.$store.state.currentUserId,
              activityable_type: "Post",
              activityable_id: response.likeable.id
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    dislikeComment(commentId) {
      callDislikesCreate(
        {
          user_id: this.$store.state.currentUserId,
          dislikeable_id: commentId,
          dislikeable_type: "Comment"
        })
        .then((response) => {
          this.getComments();
          
          this.$store.dispatch('userActivities/createUserActivity',
            {
              user_activity_type_name: "Post Dislike",
              user_id: this.$store.state.currentUserId,
              activityable_type: "Post",
              activityable_id: response.dislikeable.id
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.comment-list-component {
  .comment-list-component__input {
    flex-grow: 1;
    border: 1px solid white;
    min-width: 400px;
    height: 35px;
    border-radius: 5px;
    background: transparent;
    margin: 0;
    box-shadow: none;
    padding: 0 10px;
    font-size: 1em;
    font-weight: 300;
    color: white;
    margin-bottom: 1rem !important;
    width: 100% !important;
  }

  .comment-list-component__comments-list {
    .comment-list-component__comment {
      display: flex;
      gap: .5rem;
      font-size: .8rem;
      font-weight: 400;
      margin-bottom: 1rem;
    }

    .comment__comment-header {
      margin-bottom: .5rem;
    }

    .comment__comment-owner {
      color: white;
      font-weight: 500;
      font-size: .9rem;
    }

    .comment__comment-date {}

    .comment__comment-content {
      margin-bottom: .5rem;
    }

    .comment__social-buttons {
      display: flex;
      gap: .5rem;
    }

    .comment__social-button {
      // height: .5rem;
      font-size: .8rem;
      font-weight: 600;
      padding: .1rem .3rem .1rem .3rem;
      gap: .1rem;
    }

    .atmo-button__icon {
      height: .6rem;
      margin: 0;
    }

  }
}
</style>
