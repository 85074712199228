<!-- <template>
  <atmo-loading v-if="isLoading" />
  <atmo-music-items
    v-else
    type="artists"
    :items-for-current-page="artists"
    :paginate="false"
    :columns="3"
  />
</template>

<script>
  import AtmoLoading from '@/components/atmo-loading';
  import AtmoMusicItems from '@/components/atmo-music-items';
  import { artistsStub } from '../data';

  export default {
    components: { AtmoLoading, AtmoMusicItems },

    data() {
      return {
        isLoading: true,
        artists: null
      };
    },

    created() {
      this.getArtists();
    },

    methods: {
      getArtists() {
        // TODO: Use real artists
        this.artists = artistsStub.filter(artist => artist.isSuggested);
        this.isLoading = false;
      }
    }
  }
</script> -->

<template>
  <div>
    <atmo-loading v-if="isLoading" />
    <atmo-music-artists-grid :artists="artists"/>
  </div>
</template>

<script>
import AtmoLoading from '@/components/atmo-loading';
import AtmoMusicArtistsGrid from '@/components/music/atmo-music-artists-grid';
import {
  callArtistsSuggested
} from '@/helpers/axiosCalls';

export default {
  components: { AtmoLoading, AtmoMusicArtistsGrid },
  data() {
    return {
      isLoading: true,
      artists: [],
    };
  },
  created() {
    this.getArtists();
  },
  methods: {
    getArtists() {
      callArtistsSuggested()
        .then((response) => {
          this.artists = response;
          console.log("this.artists: ", this.artists);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }
}
</script>
