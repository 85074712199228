import Axios from 'axios';

export function callReactionsCreate(roomId, messageId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/chat_rooms/${roomId}/chat_messages/${messageId}/reactions`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}