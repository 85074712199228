import createChannel from '@/cable';

let callback; // declaring a variable that will hold a function later

let chat = null;

function connectToNotificationsChannel (userId) {
  chat = createChannel(`NotificationsChannel`, userId, {
    received({ message }) {
      if (callback) callback.call(null, message);
    }
  });
}

// Sending a message: "perform" method calls a respective Ruby method
// defined in atmo_chat_channel.rb. That's your bridge between JS and Ruby!
// function sendMessage(senderId, receiverId, notificationTypeId, status, notifiableType, notifiableId) {
//   chat.perform("send_message", { 
//     sender_id: senderId, 
//     receiver_id: receiverId, 
//     notification_type_id: notificationTypeId,
//     status: status,
//     notifiable_type: notifiableType,
//     notifiable_id: notifiableId,
//    });
//   // alert("sendMessage firing");
// }

// Getting a message: this callback will be invoked once we receive
// something over AtmoChatChannel
function setCallback(fn) {
  callback = fn;
}

export { connectToNotificationsChannel, setCallback };
