<template>
  <div class="atmo-contest-submissions-show" :class="{ 'atmo-contest-submissions-show--loading': isLoading }">
    <atmo-loading v-if="isLoading" />
    <div v-else>
      <contest-submission :submission="submission" :is-standalone="true" />
      <div class="atmo-contest-submissions-show__comments-container">
        <div class="atmo-contest-submissions-show__comments-header">
          <h3>Comments</h3>
          <!-- TODO: emit comment length when adding a comment -->
          <!-- <atmo-badge :label="comments.length" /> -->
        </div>
        <perfect-scrollbar>
          <div class="atmo-contest-submissions-show__comments">
            <atmo-post v-for="comment in comments" :key="comment.id" :post="comment" :likes-enabled="false"
              :actions-enabled="false" />
            <contest-submission-comments />
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import AtmoLoading from '@/components/atmo-loading';
// import AtmoBadge from '@/components/atmo-badge';
import AtmoPost from '@/components/atmo-posts/atmo-post';
import ContestSubmission from '../contest-submission';
import ContestSubmissionComments from '../contest-submission-comments';
import {
  callContestSubmissionsShow,
  callCommentsIndex
} from '@/helpers/axiosCalls';

export default {
  components: {
    AtmoLoading,
    // AtmoBadge,
    AtmoPost,
    ContestSubmission,
    ContestSubmissionComments
  },

  data() {
    return {
      isLoading: true,
      comments: [],
      submission: null
    }
  },

  created() {
    this.getSubmission();
    this.getComments();
  },

  methods: {
    async getComments() {
      callCommentsIndex({
        commentable_id: this.$route.params.submissionId,
        commentable_type: 'ContestSubmission',
      })
        .then((response) => {
          this.comments = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getSubmission() {
      this.isLoading = true;
      callContestSubmissionsShow(this.$route.params.contestId, this.$route.params.submissionId)
        .then((response) => {
          this.submission = response;
          console.log("this.submission", this.submission)
        })
        .catch((error) => {
          console.error(error);
        });
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-contest-submissions-show {
  flex: 1;
  display: flex;
  flex-direction: column;

  &--loading {
    align-items: center;
    justify-content: center;
  }

  &__comments-container {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }

  &__comments-header {
    display: flex;
    font-size: .9rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px;

    h3 {
      margin-right: .5rem;
    }

    :global(.atmo-badge) {
      margin-left: 5px;
    }
  }

  &__comments {
    max-height: 800px;
    padding-top: 8px;
  }
}
</style>
