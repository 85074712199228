<template>
  <div class="atmo-wrapper">
    <div class="atmo-items-wrapper">
      <div class="atmo-auth atmo-auth-signup">
        <div class="atmo-auth__header">
          <h1 class="atmo-auth__header-text">
            Welcome to
          </h1>
          <h2 class="atmo-auth__header-text2">
            The
            <span class="atmo-auth__header-a">Atmosphere</span>.
          </h2>
          <h3 class="atmo-auth__header-text3">
            Create your account by filling the form below.
          </h3>
        </div>
        <div class="atmo-auth__checkbox-container">
          <atmo-radio-button
            :checked="type === 'artist'"
            :click="onRadioButtonClick('artist')"
            label="Artist"
          />
          <atmo-radio-button
            :checked="type === 'user'"
            :click="onRadioButtonClick('user')"
            label="User"
          />
          <atmo-radio-button
            :checked="type === 'label'"
            :click="onRadioButtonClick('label')"
            label="Label"
          />
        </div>
        <form id="atmo-auth-form">
          <atmo-input
            v-model="email"
            onlyunder
            type="email"
            name="email"
            :maxlength="50"
            placeholder="theatmosphere@gmail.com"
            label="Email"
          />
          <atmo-input
            v-model="password"
            onlyunder
            type="password"
            name="password"
            :maxlength="50"
            label="Password"
            placeholder="••••••"
          >
            <atmo-password-strength slot="extra" :password="password" />
          </atmo-input>
          <atmo-input
            v-model="confirmPassword"
            onlyunder
            type="password"
            :maxlength="50"
            name="confirmPassword"
            label="Confirm Password"
            placeholder="••••••"
          />
          <div class="atmo-auth__button">
            <input
              type="submit"
              value="Sign Up"
              class="atmo-auth__card-button"
              @click="signUp()"
            >
          </div>
        </form>
      </div>
      <background
        h1="Do you already have an account?"
        h2="Welcome back! You can login by clicking on the button below.You can sign in to your account using your login credentials."
        button-text="Login"
        to-route="login"
        ads="Are you a business looking to advertise your products/services on Atmosphere?"
      />
    </div>
  </div>
</template>


<script>
  import Axios from "axios";
  import AtmoInput from "@/components/atmo-input";
  import AtmoPasswordStrength from '@/components/atmo-password-strength';
  import AtmoRadioButton from "@/components/atmo-radio-button";
  import Background from "./background";
  export default {
    components: {
      Background,
      AtmoInput,
      AtmoPasswordStrength,
      AtmoRadioButton,
    },
    data() {
      return {
        email: '',
        password: '',
        confirmPassword: '',
        name: null,
        type: null,
        user: {},
      };
    },
    created() {
      this.setToken();
      this.getUser();
    },
    methods: {
      parseToken(token= '') {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_","/");
        const id = JSON.parse(window.atob(base64)).sub;
        this.$store.commit("setCurrentUserId", id);
      },
      getToken() {
        const { email, password } = this;
        Axios({
          method: "post",
          url: "/api/v1/user_token",
          data: {
            auth: {
              email,
              password
            }
          }
        })
          .then(response => {
            localStorage.setItem("token", response.data.jwt);
            const token = localStorage.getItem("token");
            this.$store.commit("setToken", token);
            this.getUser();
            location.reload();
          })
          .catch(error => {
            console.error(error);
          });
      },
      signUp() {
        const { email, password, name, is_artist, is_fan } = this;
        Axios({
          method: "post",
          url: "/api/v1/users",
          data: {
            email,
            password,
            name,
            is_artist,
            is_fan,
          }
        })
          .then(() => {
            this.getToken();
          })
          .catch(error => {
            console.error(error);
          });
      },
      setToken() {
        const token = localStorage.getItem("token");
        if (token !== null) {
          this.$store.commit("setToken", token);
        } else {
          console.log("NO TOKEN");
        }
      },
      getUser() {
        if (this.$store.state.token !== null) {
          this.parseToken(this.$store.state.token);
          console.log("TOKEN FOUND; GETTING USER...");
          Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`, {
            headers: {
              Authorization: "Bearer " + this.$store.state.token
            }
          })
            .then(response => {
              this.user = response.data;
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.log("USER NOT LOGGED IN");
        }
      },
      onRadioButtonClick(type) {
        const referenceOfThis = this;
        return function() { referenceOfThis.type = type };
      }
    }
  };
</script>

