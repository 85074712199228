<template>
  <div class="content atmo-single-purchase">
    <h2 class="atmo-page-header">
      ATMO purchase
    </h2>
    <div class="atmo-cart__body">
      <aside class="atmo-cart__sidebar">
        <div class="atmo-charts-show__header">
          <span class="atmo-breadcrumbs router-link-active" @click="() => $router.go(-1)">
            <img src="@/assets/images/icons/back.png">
            <span>Back to Purchases</span>
          </span>
        </div>
        <div class="atmo-cart__attributes">
          <div class="atmo-cart__attribute">
            <div class="atmo-cart__attribute-label">
              Order No.
            </div>
            <div class="atmo-cart__attribute-value">
              {{ purchase.id }}
            </div>
          </div>
          <div class="atmo-cart__attribute">
            <div class="atmo-cart__attribute-label">
              Points
            </div>
            <div class="atmo-cart__attribute-value">
              {{ purchase.points }}
            </div>
          </div>
          <div class="atmo-cart__attribute">
            <div class="atmo-cart__attribute-label">
              Date Of Purchase
            </div>
            <div class="atmo-cart__attribute-value">
              {{ formatdate(purchase.created_at) }}
            </div>
          </div>
          <div class="atmo-cart__attribute atmo-cart__attribute--total">
            <div class="atmo-cart__attribute-label">
              Total
            </div>
            <div class="atmo-cart__attribute-value">
              {{ purchase.amount | currency }}
            </div>
          </div>
        </div>
      </aside>

      <div class="atmo-purchase__overview">
        <div
          v-for="(item, index) in (purchase && purchase.items) || []"
          :key="index"
          class="atmo-purchase__card"
        >
          <div class="atmo-purchase__layout">
            <div class="atmo-purchase__image-container" :style="`backgroundImage: url(${item.thumbnail || '@/assets/images/icons/ops/upload_image.png)'}`" />
            <div class="atmo-purchase__content">
              <h2 class="atmo-purchase__title">
                {{ item.name }}
              </h2>
              <div class="atmo-purchase__items">
                <div
                  v-for="(displayItem, displayItemIndex) in displayItems"
                  :key="displayItem.title"
                  class="atmo-purchase__item-attributes"
                >
                  <div class="atmo-purchase__item-attribute">
                    {{ displayItem.title }}
                  </div>
                  <div class="atmo-purchase__item-attribute">
                    <div
                      v-if="!!item.colorHex && displayItemIndex === 0"
                      class="atmo-purchase__item-attribute__color"
                      :style="`background-color: ${item.colorHex}`"
                    />
                    <span :class="{ total: displayItemIndex === displayItems.length - 1 }">
                      {{ displayItem.formatter }}{{ displayItem.key === 'calculateTotal' ? Number(item.price) * Number(item.quantity) : item[displayItem.key] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Axios from "axios";
  import Moment from "moment";

  const displayItems = [
    {
      title: 'Color',
      key: 'color',
    },
    {
      title: 'Quantity',
      key: 'quantity',
    },
    {
      title: 'Size',
      key: 'size',
    },
    {
      title: 'Price',
      key: 'price',
      formatter: '$',
    },
    {
      title: 'Total Price',
      key: 'calculateTotal',
      formatter: '$',
    },
  ];
  const purchaseItems = [
    {
      name: 'T-shirt',
      color: 'red',
      colorHex: 'red',
      quantity: 200,
      size: 'S',
      price: 300,
    },
    {
      name: 'T-shirt',
      color: 'black',
      colorHex: 'black',
      quantity: 200,
      size: 'S',
      price: 300,
    },{
      name: 'T-shirt',
      color: 'green',
      colorHex: 'green',
      quantity: 200,
      size: 'S',
      price: 300,
    },{
      name: 'T-shirt',
      color: 'blue',
      colorHex: 'blue',
      quantity: 200,
      size: 'S',
      price: 300,
    },{
      name: 'T-shirt',
      color: 'yellow',
      colorHex: 'yellow',
      textColor: '',
      quantity: 200,
      size: 'S',
      price: 300,
    },
  ];
  export default {
    // try to use arrow functions since they're more readable
    data: () => {
      return {
        purchase: {},
        displayItems,
        error:false
      };
    },
    created() {
      Axios.get(
        `/api/v1/users/${this.$route.params.userProfileId}/transactions/${this.$route.params.id}`
      )
        .then(response => {
          console.log(response);
          this.purchase = { ...response.data, items: purchaseItems };
        })
        // handle error
        .catch(error => {
          console.log(error);
          this.error = true;
        } );
    },
    methods: {
      formatdate: (value) => Moment(value).format("L"),
    }
  };
</script>
<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.content.atmo-single-purchase {
  .atmo-cart__sidebar {
    flex: 1 1 40%;
  }
  .atmo-page-header {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .atmo-cart__body {
    padding: 0 40px;
    overflow: hidden;
  }
  .atmo-purchase {
    max-width: 100%;
    min-height: 1px;
    flex: 1 1 calc(60% - 30px);
    margin-left: 30px;
    &__overview {
      flex: 1 1 calc(60% - 30px);
      margin-left: 30px;
      overflow-y: auto;
      height: 100%;
    }
    &__card {
      flex: 1;
      box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, .25);
      background-image: linear-gradient(
          -270deg,
          rgba($atmo-purple--light, 0.2) 0%);
      border-radius: 4px;
      padding: 7px;
      margin-bottom: 20px;
    }
    &__layout {
      display: flex;
    }
    &__image-container {
      flex: 1 1 20%;
      margin-right: 20px;
      box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, .25);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      max-height: 100px;
      height: 100px;
      max-width: 95px;
      border-radius: 8px;
    }
    &__items {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }

    &__content {
      flex: 1;
      padding-right: 10px;
    }
    &__title {
      font-size: 15px;
      font-weight: 500;
      padding: 5px 0;
      border-bottom: 1px solid rgba($atmo-purple--light, 0.4);
      width: 100%;
    }
    &__item-attributes {
      display: flex;
      text-align: center;
      flex-direction: column;
      padding: 15px;
    }
    &__item-attribute {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      text-align: center;
      &:first-child {
        margin-bottom: 15px;
        font-size: 12px;
      }
      &__color {
        height: 8px;
        width: 8px;
        border-radius: 100%;
        border: solid 1px white;
        margin-right: 5px;
      }
      font-weight: 500;
      font-size: 14px;
      .total {
        color: $atmo-gray--dark;
      }
    }
  }
}
</style>
