<template>
    <div class="user-details-container">
        <div v-if="user.images[0]" class="user-details-container__background-image-container"
            :style="backgroundImageStyle">
        </div>
        <div class="user-details-container__info-container">
            <div class="info-container__profile-pic-container">
                <img class="profile-pic-container__pic" :src="getFeaturedImage(user.images, 'full')" @click="openUserMediaModal({
            type: 'Image',
            caption: user.images[0].name,
            url: user.images[0].image.url,
            id: user.images[0].id
        })" />
                <FollowButton v-if="!isMyProfile" :userId="user.id" />
            </div>
            <div class="info-container__user-info-wrap">
                <div class="user-info-wrap__name-wrap">{{ user.name }}</div>
                <div class="user-info-wrap__location-wrap">
                    <img class="info-icon" src="@/assets/images/icons/profile/pin.png" />
                    Austin, TX, USA
                </div>
            </div>
            <div class="info-container__button-wrap">
                <div v-if="isMyProfile" class="button-wrap__current-user">
                    <router-link class="profile-button--light dashboard" :to="dashboardRoute">
                        <img class="profile-button__image" src="@/assets/images/icons/profile/dashboard.png" />
                        Dashboard
                    </router-link>
                    <router-link class="profile-button--dark" :to="editProfileRoute">
                        <img class="profile-button__image" src="@/assets/images/icons/profile/edit.png" />
                        Edit Profile
                    </router-link>
                </div>
                <div v-else class="button-wrap__other-user">
                    <router-link class="profile-button--pink" :to="{ name: 'checkout' }">
                        <img class="profile-button__image" src="@/assets/images/icons/profile/donate.png" />
                        Donate
                    </router-link>
                    <router-link class="profile-button--light" :to="venueRequestRoute">
                        <img class="profile-button__image" src="@/assets/images/icons/profile/venue.png" />
                        Request a Venue
                    </router-link>
                </div>
            </div>
        </div>
        <div class="user-details-container__label-container">
            <div class="label-container__record-label-wrap">
                <slot name="label-type">Record Label</slot>
            </div>
            <div class="label-container__name-wrap">
                <slot name="label-name"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import FollowButton from '@/components/profiles/follow-button';
import { getFeaturedImage } from '@/helpers/utilityFunctions';

export default {
    components: {
        FollowButton
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        isMyProfile: {
            type: Boolean,
            required: true
        },
        dashboardRoute: {
            type: Object,
            required: true
        },
        editProfileRoute: {
            type: Object,
            required: true
        },
        venueRequestRoute: {
            type: Object,
            required: true
        }
    },
    computed: {
        backgroundImageStyle() {
            return {
                'background-image': `linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%), url(${this.getFeaturedImage(this.user.images, 'full')})`
            };
        }
    },
    methods: {
        getFeaturedImage,
        openUserMediaModal(userMediaObj) {
            this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
            this.$store.commit("videoPlayer/setUserMediaModalObj", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
            this.$store.commit("videoPlayer/toggleUserMediaModal");
        }
    }
};
</script>

<style scoped lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.user-details-container {
    background-image: linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%);
    border-radius: 10px;
    position: relative;

    &__background-image-container {
        height: 10rem;
        border-radius: 10px 10px 0px 0px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &__info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem;
    }

    &__profile-pic-container {
        height: 10rem;
        width: 10rem;
        border-radius: 100%;
        position: absolute;
        top: -5rem;
    }

    .profile-pic-container__pic {
        height: 10rem;
        width: 10rem;
        border-radius: 100%;
        object-fit: cover;
        box-shadow: 0 0 1px 13px rgba(255, 255, 255, 0.1);
        cursor: pointer;
    }

    &__user-info-wrap {
        margin-top: 6rem;
        margin-bottom: 1rem;
    }

    .user-info-wrap__name-wrap {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: .5rem;
    }

    .user-info-wrap__location-wrap {
        font-weight: 500;
        margin-bottom: .5rem;
        display: flex;
        align-items: center;
    }

    .info-icon {
        height: 1rem;
        width: auto;
        margin-right: .5rem;
    }


    .info-container__button-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        .profile-button {
            background: $atmo-purple--medium-dark;
            padding: .5rem 1.4rem .5rem 1.4rem;
            color: white;
            font-size: .8rem;
            font-weight: 600;
            border-radius: 30px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .profile-button__image {
            width: auto;
            margin-right: .5rem;

            &.edit {
                height: 1rem;
            }

            &.add {
                height: .7rem;
            }

            &.unfriend {
                height: .7rem;
            }
        }

        .profile-button--dark {
            background: $atmo-purple--medium-dark;
            padding: .5rem 1.4rem .5rem 1.4rem;
            color: white;
            font-size: .8rem;
            font-weight: 600;
            border-radius: 30px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .profile-button--light {
            background: $atmo-purple--medium;
            padding: .5rem 1.4rem .5rem 1.4rem;
            color: white;
            font-size: .8rem;
            font-weight: 600;
            border-radius: 30px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.dashboard {
                margin-bottom: 1rem;
            }
        }

        .profile-button--pink {
            background: $atmo-pink--medium;
            padding: .5rem 1.4rem .5rem 1.4rem;
            color: white;
            font-size: .8rem;
            font-weight: 600;
            border-radius: 30px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }

        .profile-button__image {
            height: 1rem;
            width: auto;
            margin-right: .5rem;
        }
    }

    &__label-container {
        background: rgba(0, 0, 0, .1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: 0px 0px 10px 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
    }

    .label-container__record-label-wrap {
        text-transform: uppercase;
        color: $atmo-blue--light;
        margin-bottom: .2rem;
    }

    .label-container__name-wrap {
        text-transform: uppercase;
        font-size: 1rem;

        .label-profile-link {
            color: white;
        }
    }
}
</style>