import Axios from 'axios';

export function callChatRoomUsersIndex(chatRoomId, queryParams = {}) {
    return Axios.get(`/api/v1/chat_rooms/${chatRoomId}/chat_room_users`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callChatRoomUsersCreate(chatRoomId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/chat_rooms/${chatRoomId}/chat_room_users`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callChatRoomUsersUpdate(chatRoomId, chatRoomUserId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/chat_rooms/${chatRoomId}/chat_room_users/${chatRoomUserId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callChatRoomUsersDelete(chatRoomId, chatRoomUserId, queryParams = {}) {
    return Axios.delete(`/api/v1/chat_rooms/${chatRoomId}/chat_room_users/${chatRoomUserId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}