import createChannel from '@/cable';

let callback; // declaring a variable that will hold a function later
let chat = null; // This will hold the channel instance

function connectToAtmoChatChannel(roomId) {
  chat = createChannel(`AtmoChatChannel`, roomId, {
    received({ message }) {
      if (callback) callback.call(null, message);
    }
  });
  console.log(`Connected to AtmoChatChannel with roomId: ${roomId}`);
}

function sendMessage(messageBody, roomId, userId) {
  if (!chat) {
    console.error('Chat channel not initialized. Did you forget to call connectToAtmoChatChannel?');
    return;
  }
  chat.perform("send_message", { body: messageBody, chat_room_id: roomId, user_id: userId });
  console.log("Message sent:", messageBody);
}

function setCallback(fn) {
  callback = fn;
  console.log("Callback set for receiving messages.");
}

export { connectToAtmoChatChannel, sendMessage, setCallback };
