<template>
  <div style="background-color:purple; height:100%;">
    <p style="color:black">
      TESTING
    </p>
    <p style="color:black">
      {{ text }}
    </p>
    <p style="color:black">
      {{ testingData }}
    </p>
  </div>
</template>

<script>
  export default {
    props: {
      text: { type: String, default: '' }
    },
    data(){
      return {
        testingData: "TESTING"
      }
    }
  }
</script>

<style>
  .modal-style {
    background-color:purple;
    height:100%;
  }
</style>
