<!-- <template>
  <div>
    <modals-container />
    <popover
      name="foo"
      style="background-color:purple;"
    >
      <button
        style="color:black;"
        @click="alertMe()"
      >
        Alert Test
      </button>
    </popover>
    <notifications
      group="foo"
      position="top right"
    />
    <h1>Edit User Pic</h1>
    <div>
      <img :src="user.profile_pic.url">
      <label>
        File
        <input
          id="file"
          ref="file"
          type="file"
          @change="handleFileUpload()"
        >
      </label>
      <button @click="submitFile()">
        Update Profile Pic
      </button>
    </div>
    <h1>Edit Info</h1>
    <div>
      {{ user.name }}
      <input
        v-model="userName"
        type="text"
        placeholder="User Name"
      >
      <button @click="editName(userName)">
        Update
      </button>
    </div>
    <div>
      {{ user.email }}
      <input
        v-model="userEmail"
        type="text"
        placeholder="User Email"
      >
      <button @click="editProfile(userEmail)">
        Update
      </button>
    </div>
    <div>
      {{ user.address }}
      <input
        v-model="userAddress"
        type="text"
        placeholder="User Address"
      >
      <button @click="editAddress(userAddress)">
        Update
      </button>
    </div>
    <div>
      {{ user.phone }}
      <input
        v-model="userPhone"
        type="text"
        placeholder="User Phone"
      >
      <button @click="editPhone(userPhone)">
        Update
      </button>
    </div>
    <div>
      {{ user.postal_code }}
      <input
        v-model="userPostalCode"
        type="text"
        placeholder="User Postal Code"
      >
      <button @click="editPostalCode(userPostalCode)">
        Update
      </button>
    </div>
    <div>
      {{ user.country }}
      <input
        v-model="userCountry"
        type="text"
        placeholder="User Country"
      >
      <button @click="editCountry(userCountry)">
        Update
      </button>
    </div>
    <div>
      {{ user.city }}
      <input
        v-model="userCity"
        type="text"
        placeholder="User City"
      >
      <button @click="editCity(userCity)">
        Update
      </button>
    </div>
    <div>
      {{ user.state }}
      <input
        v-model="userState"
        type="text"
        placeholder="User State"
      >
      <button @click="editState(userState)">
        Update
      </button>
    </div>
    <div>
      {{ user.gender }}
      <input
        v-model="userGender"
        type="text"
        placeholder="User Gender"
      >
      <button @click="editGender(userGender)">
        Update
      </button>
    </div>
    <div>
      {{ user.age }}
      <input
        v-model="userAge"
        type="text"
        placeholder="User Age"
      >
      <button @click="editAge(userAge)">
        Update
      </button>
    </div>
    <div>
      {{ artistProfile.twitter }}
      <input
        v-model="artistTwitter"
        type="text"
        placeholder="Twitter Profile"
      >
      <button @click="editFacebook(artistTwitter)">
        Update
      </button>
    </div>
    <div>
      {{ artistProfile.facebook }}
      <input
        v-model="artistFacebook"
        type="text"
        placeholder="Facebook Profile"
      >
      <button @click="editFacebook(artistFacebook)">
        Update
      </button>
    </div>
    <div>
      {{ artistProfile.orgin }}
      <input
        v-model="artistOrigin"
        type="text"
        placeholder="Origin"
      >
      <button @click="editFacebook(artistOrigin)">
        Update
      </button>
    </div>
    <div>
      {{ artistProfile.decades }}
      <input
        v-model="artistDecades"
        type="text"
        placeholder="Decades"
      >
      <button @click="editFacebook(artistDecades)">
        Update
      </button>
    </div>
    <div>
      {{ artistProfile.bio }}
      <input
        v-model="artistBio"
        type="text"
        placeholder="Bio"
      >
      <button @click="editFacebook(artistBio)">
        Update
      </button>
    </div>
    <div>
      {{ artistProfile.label }}
      <input
        v-model="artistLabel"
        type="text"
        placeholder="Label"
      >
      <button @click="editFacebook(artistLabel)">
        Update
      </button>
    </div>
    <button @click="show()">
      Show
    </button>
    <button v-popover:foo.right>
      Popover
    </button>
    <button @click="testNotification()">
      Notifications
    </button>
  </div>
</template>

<script>
  import Axios from 'axios'
  import TestModal from '@/routes/modals/test_modal'

  export default {
    data(){
      return {
        file: '',
        user: {},
        artistProfile: {},
        userName: '',
        userEmail: '',
        userAddress: '',
        userPhone: '',
        userPostalCode: '',
        userCountry: '',
        userCity: '',
        userState: '',
        userGender: '',
        userAge: '',
        artistTwitter: '',
        artistFacebook: '',
        artistOrigin: '',
        artistDecades: '',
        artistBio: '',
        artistLabel: '',
      }
    },
    created(){
      Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`).then(response => (this.user = response.data));
      Axios.get(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`).then(response => (this.artistProfile = response.data));
    },
    methods: {
      show () {
        this.$modal.show(TestModal, {
          text: 'This text is passed'
        }, {
          resizable: true,
          adaptive: true,
          draggable: true,
          name: 'dynamic-modal'
        })
      },
      alertMe(){
        window.alert("WORKING");
      },
      testNotification(){
        console.log("NOTIFICATION");
        this.$notify({
          group: 'foo',
          title: 'File Upload Successful',
          text: 'You Successfully Updated Your Profile Pic!'
        });
      },
      submitFile(){
        let formData = new FormData();
        formData.append('file', this.file);
        Axios.patch( `/api/v1/users/${this.$store.state.currentUserId}`,
                     formData,
                     {
                       headers: {
                         'Content-Type': 'multipart/form-data'
                       }
                     }
        ).then(function(){
          console.log("SUCCESS");
          this.$notify({
            group: 'foo',
            title: 'File Upload Successful',
            text: 'You Successfully Updated Your Profile Pic!'
          });
        })
          .catch(function(){
            console.log('FAILURE!!');
            this.$notify({
              group: 'foo',
              title: 'File Upload Successful',
              text: 'You Successfully Updated Your Profile Pic!'
            });
          });
      },
      handleFileUpload(){
        this.file = this.$refs.file.files[0];
      },
      editName (uN) {
        Axios.patch(`/api/v1/users/${this.$store.state.currentUserId}`, {
          name: uN
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`).then(response => (this.user = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editEmail (uE) {
        Axios.patch(`/api/v1/users/${this.$store.state.currentUserId}`, {
          email: uE
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`).then(response => (this.user = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editAddress(uA) {
        Axios.patch(`/api/v1/users/${this.$store.state.currentUserId}`, {
          address: uA
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`).then(response => (this.user = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editPhone (uP) {
        Axios.patch(`/api/v1/users/${this.$store.state.currentUserId}`, {
          phone: uP
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`).then(response => (this.user = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editPostalCode (uPC) {
        Axios.patch(`/api/v1/users/${this.$store.state.currentUserId}`, {
          postal_code: uPC
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`).then(response => (this.user = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editCountry (uCo) {
        Axios.patch(`/api/v1/users/${this.$store.state.currentUserId}`, {
          country: uCo
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`).then(response => (this.user = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editState (uCi) {
        Axios.patch(`/api/v1/users/${this.$store.state.currentUserId}`, {
          city: uCi
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`).then(response => (this.user = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editGender (uG) {
        Axios.patch(`/api/v1/users/${this.$store.state.currentUserId}`, {
          gender: uG
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`).then(response => (this.user = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editAge (uAg) {
        Axios.patch(`/api/v1/users/${this.$store.state.currentUserId}`, {
          age: uAg
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`).then(response => (this.user = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editTwitter (aTw) {
        Axios.patch(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`, {
          twitter: aTw
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`).then(response => (this.artistProfile = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editFacebook (aFb) {
        Axios.patch(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`, {
          twitter: aFb
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`).then(response => (this.artistProfile = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editOrigin (aO) {
        Axios.patch(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`, {
          twitter: aO
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`).then(response => (this.artistProfile = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editDecades (aD) {
        Axios.patch(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`, {
          twitter: aD
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`).then(response => (this.artistProfile = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editBio (aB) {
        Axios.patch(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`, {
          twitter: aB
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`).then(response => (this.artistProfile = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      },
      editLabel (aL) {
        Axios.patch(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`, {
          twitter: aL
        })
          .then((response) => {
            console.log(response);
            Axios.get(`/api/v1/artist_profiles/${this.$store.state.currentUserProfileId}`).then(response => (this.artistProfile = response.data));
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }
</script> -->

<!-- <template>
  <div>
    <b-modals-container />
    <b-popover
      name="foo"
      style="background-color:purple;"
    >
      <button
        style="color:black;"
        @click="alertMe()"
      >
        Alert Test
      </button>
    </b-popover>
    <notifications
      group="foo"
      position="top right"
    />
    <h1>Edit User Pic</h1>
    <div>
      <img :src="getFeaturedImage(currentUser.images, 'full')">
      <label>
        File
        <input
          id="file"
          ref="file"
          type="file"
          @change="handleFileUpload()"
        >
      </label>
      <button @click="submitFile()">
        Update Profile Pic
      </button>
    </div>
    <h1>Edit Info</h1>
    <div>
      {{ currentUser.name }}
      <input
        v-model="userName"
        type="text"
        placeholder="User Name"
      >
      <button @click="editName(userName)">
        Update
      </button>
    </div>
    <div>
      {{ currentUser.email }}
      <input
        v-model="userEmail"
        type="text"
        placeholder="User Email"
      >
      <button @click="editProfile(userEmail)">
        Update
      </button>
    </div>
    <div>
      {{ currentUser.address }}
      <input
        v-model="userAddress"
        type="text"
        placeholder="User Address"
      >
      <button @click="editAddress(userAddress)">
        Update
      </button>
    </div>
    <div>
      {{ currentUser.phone }}
      <input
        v-model="userPhone"
        type="text"
        placeholder="User Phone"
      >
      <button @click="editPhone(userPhone)">
        Update
      </button>
    </div>
    <div>
      {{ currentUser.postal_code }}
      <input
        v-model="userPostalCode"
        type="text"
        placeholder="User Postal Code"
      >
      <button @click="editPostalCode(userPostalCode)">
        Update
      </button>
    </div>
    <div>
      {{ currentUser.country }}
      <input
        v-model="userCountry"
        type="text"
        placeholder="User Country"
      >
      <button @click="editCountry(userCountry)">
        Update
      </button>
    </div>
    <div>
      {{ currentUser.city }}
      <input
        v-model="userCity"
        type="text"
        placeholder="User City"
      >
      <button @click="editCity(userCity)">
        Update
      </button>
    </div>
    <div>
      {{ currentUser.state }}
      <input
        v-model="userState"
        type="text"
        placeholder="User State"
      >
      <button @click="editState(userState)">
        Update
      </button>
    </div>
    <div>
      {{ currentUser.gender }}
      <input
        v-model="userGender"
        type="text"
        placeholder="User Gender"
      >
      <button @click="editGender(userGender)">
        Update
      </button>
    </div>
    <div>
      {{ currentUser.age }}
      <input
        v-model="userAge"
        type="text"
        placeholder="User Age"
      >
      <button @click="editAge(userAge)">
        Update
      </button>
    </div>
    <div>
      {{ currentUserProfile.facebook }}
      <input
        v-model="userFacebook"
        type="text"
        placeholder="Facebook Profile"
      >
      <button @click="editFacebook(userFacebook)">
        Update
      </button>
    </div>
    <div>
      {{ currentUserProfile.twitter }}
      <input
        v-model="userTwitter"
        type="text"
        placeholder="Twitter Profile"
      >
      <button @click="editTwitter(userTwitter)">
        Update
      </button>
    </div>
    <button @click="show()">
      Show
    </button>
    <button v-popover:foo.right>
      Popover
    </button>
    <button @click="testNotification()">
      Notifications
    </button>
  </div>
</template> -->

<template>
  <div class="edit-artist-profile" data-cy="edit-artist-profile">
    <div class="header-container">
      <h2 class="atmo-page-header">
        Edit Profile
      </h2>
    </div>
    <div class="edit-artist-profile-grid">
      <div class="edit-artist-profile-grid__image-container">
        <div class="image-container__image-wrap editing-image"
          v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${getFeaturedImage(currentUser.images, 'full')})` }">
          <div class="image-wrap__icon-wrap">
            <img class="icon-wrap__icon" src='@/assets/images/icons/profile/upload_new.png' />
            <div class="icon-wrap__text">Upload New Picture</div>
          </div>
        </div>
        <perfect-scrollbar>
          <div class="image-container__select-image-container">
            <div class="select-image-container__image-wrap" v-for="(image, index) in currentUser.images" :key="index"
              v-bind:style="{ 'background-image': `url(${image.image.url})` }">
              <div class="image-wrap__select-wrap">
                <img class="select-wrap__image" src='@/assets/images/icons/profile/check.png' />
              </div>
              <div class="image-wrap__delete-wrap">
                <img class="delete-wrap__image" src='@/assets/images/icons/profile/delete.png' />
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
      <div class="edit-artist-profile-grid__fields-container">
        <div class="three-column-grid">
          <div class="field-wrap">
            <label for="name">Full Name</label>
            <input class="text-field" type="text" id="name" name="name" placeholder="ex: Seth Jones">
          </div>
          <div class="field-wrap">
            <label for="email-address">Email Address</label>
            <input class="text-field" type="text" id="email-address" name="email-address"
              placeholder="ex: youremail@email.com">
          </div>
          <div class="field-wrap">
            <label for="address">Your Address</label>
            <input class="text-field" type="text" id="address" name="address"
              placeholder="ex: 654 27th Ave N, Texas City, TX">
          </div>
        </div>
        <div class="three-column-grid">
          <div class="field-wrap">
            <label for="country">Country</label>
            <select class="dropdown-group" id="country" name="country">
              <option class="dropdown-field" value="united-states">United States</option>
              <option class="dropdown-field" value="france">France</option>
              <option class="dropdown-field" value="canada">Canada</option>
              <option class="dropdown-field" value="mexico">Mexico</option>
            </select>
          </div>
          <div class="field-wrap">
            <label for="state">State</label>
            <select class="dropdown-group" id="state" name="state">
              <option class="dropdown-field" value="texas">Texas</option>
              <option class="dropdown-field" value="virginia">Virginia</option>
              <option class="dropdown-field" value="massachusetts">Massachusetts</option>
              <option class="dropdown-field" value="mexico">Mexico</option>
            </select>
          </div>
          <div class="field-wrap">
            <label for="city">City</label>
            <input class="text-field" type="text" id="city" name="city" placeholder="ex: Texas City">
          </div>
        </div>
        <div class="two-column-grid">
          <div class="field-wrap">
            <label for="birthday">Birthday</label>
            <input class="text-field" type="text" id="birthday" name="birthday" placeholder="ex: youremail@email.com">
          </div>
          <div class="field-wrap">
            <label for="gender">Gender</label>
            <fieldset class="radio-group" id="gender">
              <div class="radio-field-wrap">
                <input class="radio-field" type="radio" name="male" value="option2">
                <label class="radio-field-label" for="male">Male</label>
              </div>
              <div class="radio-field-wrap">
                <input class="radio-field" type="radio" name="female" value="option3">
                <label class="radio-field-label" for="female">Female</label>
              </div>
              <div class="radio-field-wrap">
                <input class="radio-field" type="radio" name="other" value="option3">
                <label class="radio-field-label" for="other">Other</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="two-column-grid">
          <div class="field-wrap">
            <label for="twitter">Twitter Profile Link</label>
            <input class="text-field" type="text" id="twitter" name="twitter" placeholder="ex: twitter.com/sethjones">
          </div>
          <div class="field-wrap">
            <label for="facebook">Facebook Profile Link</label>
            <input class="text-field" type="text" id="facebook" name="facebook" placeholder="ex: fb.com/sethjones">
          </div>
        </div>
        <div class="fields-container__button-wrap">
          <div class="button-wrap__save-cancel-container">
            <router-link class="save-cancel-container__save-button" :to="{ name: 'coming_soon' }">Save</router-link>
            <router-link class="save-cancel-container__cancel-button"
            :to="{ name: 'artist_profiles.show', params: { artistProfileId: this.$route.params.artistProfileId } }">Cancel</router-link>
          </div>
          <div class="button-wrap__account-settings-container">
            <router-link class="account-settings-container__account-settings-button" :to="{ name: 'settings' }">
              <img class="account-settings-button__image" src="@/assets/images/icons/profile/gear.png" />
              Account Settings
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- https://www.npmjs.com/package/vue-js-modal -->

<script>
import Axios from 'axios'
import TestModal from '@/routes/modals/test_modal';
import devMockData from '@/devUtils/mockData.js';
//Need to import this, but not use it?
import devUtilsFunctions from '@/devUtils/devFunctions';
import { callUserProfilesShow, callUserProfilesUpdate, callUsersShow } from '@/helpers/axiosCalls';
import { getFeaturedImage } from '@/helpers/utilityFunctions';

export default {
  data() {
    return {
      file: '',
      currentUser: {},
      currentUserProfile: {},
      userName: '',
      userEmail: '',
      userAddress: '',
      userPhone: '',
      userPostalCode: '',
      userCountry: '',
      userCity: '',
      userState: '',
      userGender: '',
      userAge: '',
      userFacebook: '',
      userTwitter: '',
      artistImageArray: devMockData.artistImageArray,
    }
  },
  created() {
    this.getCurrentUser();
  },
  methods: {
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    show() {
      this.$modal.show(TestModal, {
        text: 'This text is passed'
      }, {
        resizable: true,
        adaptive: true,
        draggable: true,
        name: 'dynamic-modal'
      })
    },
    alertMe() {
      window.alert("WORKING");
    },
    testNotification() {
      console.log("NOTIFICATION");
      // this.$notify({
      //   group: 'foo',
      //   title: 'File Upload Successful',
      //   text: 'You Successfully Updated Your Profile Pic!'
      // });
    },
    // show2 () {
    //   this.$modal.show(TestModal, {
    //     text: "Testing Props"
    //   }, {
    //     methods: {
    //       closeByName() { this.$modal.hide('dynamic-modal'); },
    //       closeByEvent() { this.$emit('close'); },
    //     }
    //   }, null, {
    //     name: 'dynamic-modal',
    //     resizable: true,
    //     adaptive: true,
    //     draggable: true,
    //   })
    // },
    submitFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      Axios.patch(`/api/v1/users/${this.currentUser.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function () {
        console.log("SUCCESS");
        this.$notify({
          group: 'foo',
          title: 'File Upload Successful',
          text: 'You Successfully Updated Your Profile Pic!'
        });
      })
        .catch(function () {
          console.log('FAILURE!!');
          this.$notify({
            group: 'foo',
            title: 'File Upload Successful',
            text: 'You Successfully Updated Your Profile Pic!'
          });
        });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    editName(uN) {
      Axios.patch(`/api/v1/users/${this.currentUser.id}`, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        name: uN
      })
        .then((response) => {
          console.log(response);
          Axios.get(`/api/v1/users/${this.currentUser.id}`).then(response => (this.currentUser = response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editEmail(uE) {
      Axios.patch(`/api/v1/users/${this.currentUser.id}`, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        email: uE
      })
        .then((response) => {
          console.log(response);
          Axios.get(`/api/v1/users/${this.currentUser.id}`).then(response => (this.currentUser = response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editAddress(uA) {
      Axios.patch(`/api/v1/users/${this.currentUser.id}`, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        address: uA
      })
        .then((response) => {
          console.log(response);
          Axios.get(`/api/v1/users/${this.currentUser.id}`).then(response => (this.currentUser = response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editPhone(uP) {
      Axios.patch(`/api/v1/users/${this.currentUser.id}`, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        phone: uP
      })
        .then((response) => {
          console.log(response);
          Axios.get(`/api/v1/users/${this.currentUser.id}`).then(response => (this.currentUser = response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editPostalCode(uPC) {
      Axios.patch(`/api/v1/users/${this.currentUser.id}`, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        postal_code: uPC
      })
        .then((response) => {
          console.log(response);
          Axios.get(`/api/v1/users/${this.currentUser.id}`).then(response => (this.currentUser = response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editCountry(uCo) {
      Axios.patch(`/api/v1/users/${this.currentUser.id}`, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        country: uCo
      })
        .then((response) => {
          console.log(response);
          Axios.get(`/api/v1/users/${this.currentUser.id}`).then(response => (this.currentUser = response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editState(uCi) {
      Axios.patch(`/api/v1/users/${this.currentUser.id}`, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        city: uCi
      })
        .then((response) => {
          console.log(response);
          Axios.get(`/api/v1/users/${this.currentUser.id}`).then(response => (this.currentUser = response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editGender(uG) {
      Axios.patch(`/api/v1/users/${this.currentUser.id}`, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        gender: uG
      })
        .then((response) => {
          console.log(response);
          Axios.get(`/api/v1/users/${this.currentUser.id}`).then(response => (this.currentUser = response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editAge(uAg) {
      Axios.patch(`/api/v1/users/${this.currentUser.id}`, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        age: uAg
      })
        .then((response) => {
          console.log(response);
          Axios.get(`/api/v1/users/${this.currentUser.id}`).then(response => (this.currentUser = response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editFacebook(uFb) {
      callUserProfilesUpdate(this.currentUserProfile.id, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        facebook: uFb
      })
        .then((response) => {
          console.log(response);
          callUserProfilesShow(this.currentUserProfile.id).then(response => (this.currentUserProfile = response));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    editTwitter(uT) {
      callUserProfilesUpdate(this.currentUserProfile.id, {
        // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
        twitter: uT
      })
        .then((response) => {
          console.log(response);
          callUserProfilesShow(this.currentUserProfile.id).then(response => (this.currentUserProfile = response));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCurrentUserProfile(profileId) {
      callUserProfilesShow(profileId)
        .then((response) => {
          this.currentUserProfile = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCurrentUser() {
      callUsersShow(this.$store.state.currentUserId)
        .then((response) => {
          this.currentUser = response;
          this.getCurrentUserProfile(this.currentUser.profile_id);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.edit-artist-profile {
  margin: 0 75px;

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: '';
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;
      
      &::before {
        opacity: 1;
      }

      ~label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }


  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-artist-profile-grid {
    display: grid;
    gap: 3rem;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: 18rem 1fr;
    // border: 1px solid white;
  }

  .image-container__image-wrap {
    height: 19rem;
    // width: 19rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .image-wrap__icon-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon-wrap__icon {
    height: 9rem;
    margin-bottom: 1rem;
  }

  .icon-wrap__text {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
  }

  .image-wrap__image {
    border-radius: 5px;
    margin-bottom: .8rem;
    box-sizing: border-box;
  }

  .editing-image {
    border: 3px dashed white;
  }

  .image-container__select-image-container {
    max-height: 10rem;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  }

  .select-image-container__image-wrap {
    height: 5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-wrap__select-wrap {
    height: 1.5rem;
    width: 1.5rem;
    background: $atmo-purple--medium-dark--overlay-faded;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .image-wrap__delete-wrap {
    height: 1.5rem;
    width: 1.5rem;
    background: rgba(220, 53, 69, .9);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .image-wrap__select-wrap+.image-wrap__delete-wrap {
    margin-left: .5rem;
  }

  .select-wrap__image {
    height: .8rem;
  }

  .delete-wrap__image {
    height: .8rem;
  }

  .two-column-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1.5rem;
  }

  .three-column-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.5rem;
  }

  .field-wrap {
    display: flex;
    flex-direction: column;

    label {
      text-transform: uppercase;
      margin-bottom: .5rem;
      font-size: .9rem;
      font-weight: 500;
    }

    .text-field {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, .4);
      padding: .5rem .5rem .5rem .5rem;
      color: white;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, .4);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, .4);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, .4);
    }

    .dropdown-group {
      background: $atmo-purple--medium-dark;
      color: white;
      text-transform: uppercase;
      border: none;
      border-radius: 5px;
      padding: .5rem;
      font-weight: 500rem;
      cursor: pointer;
    }

    .dropdown-field {}

    .radio-group {
      display: flex;
      padding: .6rem 0rem .6rem 0rem;
    }

    .radio-field-wrap {
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .radio-field {
      margin-right: .5rem;
      cursor: pointer;
    }

    .radio-field-label {
      margin: 2px 0px 0px 0px;
    }
  }

  .fields-container__button-wrap {
    display: flex;
    margin-top: 1rem;
  }

  .button-wrap__save-cancel-container {
    display: flex;

    .save-cancel-container__save-button {
      font-size: 1rem;
      text-transform: uppercase;
      color: white;
      background-color: transparent;
      background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
      border-radius: 5px;
      padding: .5rem 2rem .5rem 2rem;
      margin-right: 1rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .save-cancel-container__cancel-button {
      border: 1px solid white;
      font-size: 1rem;
      text-transform: uppercase;
      color: white;
      background-color: transparent;
      border-radius: 5px;
      padding: .5rem 1.5rem .5rem 1.5rem;
      margin-right: 1rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .button-wrap__account-settings-container {
    display: flex;
    margin-left: auto;

    .account-settings-container__account-settings-button {
      font-size: 1rem;
      text-transform: uppercase;
      color: white;
      background-color: $atmo-purple--medium;
      border-radius: 5px;
      padding: .5rem 1.5rem .5rem 1.5rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .account-settings-button__image {
      height: 1rem;
      width: auto;
      margin-right: .5rem;
    }
  }
}</style>