<template>
    <div class="notifications-page">
        <div class="header-container">
            <h2 class="atmo-page-header">Notifications</h2>
        </div>

        <div class="notifications-container">
            <atmo-back-link></atmo-back-link>
            <div class="notifications-container__notifications-layout">
                <div class="notifications-layout__menu-container">
                    <div class="menu-container__menu-items-wrap">
                        <div class="menu-items-wrap__menu-item" @click="toggleMenuItem('Friend Request')">Friends</div>
                        <div class="menu-items-wrap__menu-item" @click="toggleMenuItem('Group Chat Request')">Group Chat
                        </div>
                        <div class="menu-items-wrap__menu-item" @click="toggleMenuItem('Live Listening Request')">Live
                            Listening</div>
                        <div class="menu-items-wrap__menu-item" @click="toggleMenuItem('Billboard Post')">Billboard Post</div>
                        <div class="menu-items-wrap__menu-item" @click="toggleMenuItem('Chat Message')">Chat Message</div>
                    </div>
                    <div class="menu-container__reset-button" @click="toggleMenuItem('All')">Reset</div>
                </div>
                <div class="notifications-layout__content-container">
                    <div class="content-container__no-results" v-if="filteredNotifications.length === 0">No results
                    </div>
                    <perfect-scrollbar>
                        <div v-for="(notification, index) in filteredNotifications" :key="index" class="notification">
                            <div class="notification-icon">
                                <profile-link :profile-type="notification.sender.profile_type"
                                    :profile-id="notification.sender.profile_id">
                                    <img class="image" :src="getFeaturedImage(notification.sender.images, 'full')" />
                                </profile-link>
                            </div>
                            <div class="notification-content">
                                <div class="notification-content__message-container">
                                    <notification :notification="notification" />
                                    <div class="notification-time">{{ getDate(notification.created_at) }}</div>
                                </div>
                                <div class="notification-content__actions-container">
                                    <div class="notification-actions"
                                        v-if="notification.notification_type.type_name === 'Friend Request' && notification.status === 'unread'">
                                        <button class="accept-button"
                                            @click="handleAcceptFriendRequest(notification)">Accept</button>
                                        <button class="decline-button"
                                            @click="handleDeclineFriendRequest(notification)">Decline</button>
                                    </div>
                                    <div class="notification-actions"
                                        v-if="notification.notification_type.type_name === 'Live Listening Request' && notification.status === 'unread'">
                                        <button class="accept-button"
                                            @click="acceptChatRoomRequest(notification)">Accept</button>
                                        <button class="decline-button"
                                            @click="declineChatRoomRequest(notification)">Decline</button>
                                    </div>
                                    <div class="notification-actions" v-else>
                                        <button v-if="notification.status === 'unread'"
                                            @click="markAsRead(notification)" class="mark-as-read">
                                            Mark as Read
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </perfect-scrollbar>
                    <div class="content-container__all-read-button" @click="markAllAsRead()">Mark all as read</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AtmoBackLink from '@/components/atmo-back-link';
import ProfileLink from '@/components/profile-link';
import { formatDateToDayTimeYear, getFeaturedImage } from '@/helpers/utilityFunctions';
import { mapActions, mapGetters } from 'vuex';
import { callChatRoomUserRequestsUpdate, callChatRoomUserRequestsDelete } from '@/helpers/axiosCalls';
import Notification from '@/components/notifications/notification.vue';

export default {
    components: {
        AtmoBackLink,
        ProfileLink,
        Notification
    },
    created() {
        this.fetchUserNotifications(this.$store.state.currentUserId);
    },
    data() {
        return {
            showDropdown: false,
            filteredNotifications: [],
            selectedType: 'All',
        };
    },
    computed: {
        ...mapGetters('notifications', ['getUserNotifications']),
        userNotifications() {
            return this.getUserNotifications;
        },
    },
    watch: {
        userNotifications(newValue) {
            this.toggleMenuItem(this.selectedType);
        },
    },
    methods: {
        ...mapActions('notifications', ['fetchUserNotifications', 'deleteNotification']),
        getFeaturedImage(userImages, type) {
            return getFeaturedImage(userImages, type);
        },
        acceptChatRoomRequest(notification) {
            callChatRoomUserRequestsUpdate(notification.notifiable_id, { status: 'accepted' });
        },
        declineChatRoomRequest(notification) {
            callChatRoomUserRequestsDelete(notification.notifiable_id);
        },
        toggleMenuItem(notificationType) {
            this.selectedType = notificationType;
            if (notificationType === 'All') {
                this.filteredNotifications = this.userNotifications;
            } else {
                this.filteredNotifications = this.userNotifications.filter(
                    (notification) => notification.notification_type.type_name === notificationType
                );
            }
        },
        getDate(date) {
            return formatDateToDayTimeYear(date);
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
            if (this.showDropdown) {
                this.markAllAsRead();
            }
        },
        async markAllAsRead() {
            for (const notification of this.filteredNotifications) {
                await this.markAsRead(notification);
            }
        },
        async markAsRead(notification) {
            try {
                await this.deleteNotification(notification.id);
            } catch (error) {
                console.error('Error marking notification as read:', error);
            }
        },
        async handleAcceptFriendRequest(notification) {
            const { notifiable_id } = notification;

            try {
                await this.$store.dispatch('userFriendships/acceptFriendRequest', notifiable_id);
                this.$notify({ group: 'vue-app', title: 'Friend request accepted!' });
            } catch (error) {
                console.error('Error accepting friend request:', error);
                this.$notify({ type: 'error', group: 'vue-app', title: 'Error accepting friend request' });
            }
        },
        async handleDeclineFriendRequest(notification) {
            const { notifiable_id } = notification;

            try {
                await this.$store.dispatch('userFriendships/declineFriendRequest', notifiable_id);
                this.$notify({ group: 'vue-app', title: 'Friend request declined' });
            } catch (error) {
                console.error('Error declining friend request:', error);
                this.$notify({ type: 'error', group: 'vue-app', title: 'Error declining friend request' });
            }
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.notifications-page {
    .user-link {
        color: white;
        text-decoration: underline;
    }

    .notifications-container {
        max-width: 50vw;
        margin: 0 auto;
    }

    .notifications-container__notifications-layout {
        display: grid;
        grid-template-columns: 10rem 1fr;
        gap: 1rem;
    }

    .menu-container__menu-items-wrap {
        background-color: rgba(64, 54, 93, 0.1);
        border-radius: 5px;
        border: 1px solid rgba(190, 167, 221, 0.4);
        align-items: center;
        overflow: hidden;
        margin-bottom: 1rem;
    }

    .menu-items-wrap__menu-item {
        padding: 1.5rem .5rem;
        border-bottom: 1px solid rgba(190, 167, 221, 0.4);
        cursor: pointer;
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 500;
        width: 100%;
        text-align: center;

        &:hover {
            background-color: rgba(165, 139, 197, 0.7);
        }
    }

    .menu-container__reset-button {
        cursor: pointer;
        border: none;
        font-size: .8rem;
        text-transform: uppercase;
        color: white;
        background-color: #a58bc5;
        border-radius: 5px;
        padding: .5rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .notifications-layout__content-container {
        min-height: 45vh;
    }

    .content-container__no-results {
        min-height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content-container__all-read-button {
        cursor: pointer;
        border: 1px solid rgba(190, 167, 221, 0.7);
        border-radius: 5px;
        padding: .5rem;
        display: inline-block;
        text-transform: uppercase;
    }

    .notification {
        margin-bottom: 1rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        background-image: linear-gradient(-270deg, rgba(68, 174, 220, 0.4) 10%, rgba(217, 82, 167, 0.4) 100%);
        border-radius: 5px;
        position: relative;

        &:hover {
            background-image: linear-gradient(-270deg, $atmo-blue--medium 10%, $atmo-pink--medium 100%);
        }
    }

    .notification-icon {
        flex: 0 0 30px;
        margin-right: 1rem;

        img {
            height: 3rem;
            width: 3rem;
            object-fit: cover;
            border-radius: 100%;
        }
    }

    .notification-content {
        flex: 1;
    }

    .notification-message {
        font-weight: bold;
        margin-bottom: .5rem;
    }

    .notification-actions {
        display: flex;
        margin-top: 5px;
        font-size: 14px;
        margin-bottom: .5rem;
    }

    .notification-time {
        font-size: .8rem;
        color: #fff;
        margin-bottom: .5rem;
    }

    .mark-as-read {
        cursor: pointer;
        border: none;
        font-size: .8rem;
        text-transform: uppercase;
        color: white;
        background-color: #a58bc5;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .accept-button {
        cursor: pointer;
        border: none;
        font-size: .8rem;
        text-transform: uppercase;
        color: white;
        background-color: #a58bc5;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .decline-button {
        cursor: pointer;
        border: none;
        font-size: .8rem;
        text-transform: uppercase;
        color: white;
        background-color: #a58bc5;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .accept-button+.decline-button {
        margin-left: 1rem;
    }
}
</style>
