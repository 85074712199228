<template>
    <b-modal id="create-group-chat-modal" ref="createGroupChatModalRef" size="lg"
        v-model="$store.state.chatRooms.createGroupChatModalOpen" title="createGroupChatModal"
        modal-class="atmo-modal create-group-chat" hide-header hide-footer centered>
        <img class="create-group-chat__hide-modal" src="@/assets/images/icons/close_popup.png" alt="close"
            title="Hide Create Group Chat" @click="$refs.createGroupChatModalRef.hide()">
        <header class="create-group-chat__header atmo-modal__header">
            <div class="atmo-page-header-container">
                <h2 class="atmo-page-header">
                    Create Group Chat
                </h2>
            </div>
        </header>
        <div class="create-group-chat__room-name-container">
            <div class="room-name-container__header">Name of room:</div>
            <div class="room-name-container__search-wrap">
                <input class="input-field-semi-rounded" type="text" placeholder="Group Chat Name" v-model="roomName" />
            </div>
        </div>
        <div class="create-group-chat__toggle-container">
            <div class="toggle-container__header">Public room?</div>
            <div class="toggle-container__toggle-wrap">
                <atmo-toggle :on="isPublicRoom" :on-change="toggleRoomPublic" size="large" />
            </div>
        </div>
        <div class="create-group-chat__image-upload-container">
            <div class="image-upload-container__header">Choose room image:</div>
            <label for="chat-image-input" style="display: inline-block; height: 1rem; width: 1rem; cursor: pointer;">
                <img src="@/assets/images/icons/chat/upload_image.png" class="image-upload-icon" alt="Upload Image" />
            </label>
            <input id="chat-image-input" type="file" @change="handleImageUpload" style="display: none" />
            <div v-if="selectedImage">FILE ADDED</div>
        </div>
        <div class="create-group-chat__friends-container">
            <div class="friends-container__header">Select friends to add to room:</div>
            <div class="friends-container__search-wrap">
                <input class="search-field-rounded" type="text" placeholder="Search for a friend" v-model="searchQuery"
                    @input="filterList" />
            </div>
            <perfect-scrollbar>
                <div :class="[{ 'clicked': clickedItems.includes(index) }, 'friends-container__friend-wrap']"
                    v-for="(friendship, index) in filteredFriendList" :key="friendship.id"
                    @click="handleUserClick(friendship.friend, index)">
                    <div class="queue-wrap__image-wrap"><img class="image-wrap__image"
                            :src="getFeaturedImage(friendship.friend.images, 'full')" /></div>
                    <div class="queue-wrap__name-wrap">{{ friendship.friend.name }}</div>
                </div>
            </perfect-scrollbar>
        </div>
        <div class="create-group-chat__save-container">
            <button v-if="requiredFieldsArePresent()" class="save-button" @click="saveGroupChat()">Save</button>
            <button v-else class="save-button--unusable">Save</button>
        </div>
    </b-modal>
</template>

<script>
import Axios from 'axios';
import AtmoToggle from '@/components/atmo-toggle';
import { getFeaturedImage } from '@/helpers/utilityFunctions';
import { callChatRoomUsersCreate } from '@/helpers/axiosCalls';

export default {
    components: {
        AtmoToggle,
    },
    data() {
        return {
            selectedUsers: [],
            clickedItems: [],
            roomName: '',
            searchQuery: '',
            selectedImage: null,
            isPublicRoom: false,
        }
    },
    computed: {
        acceptedFriendships() {
            return this.$store.getters['chatRooms/chatUserFriendships'].filter(friendship =>
                friendship.state === 'accepted'
            );
        },
        filteredFriendList() {
            return this.acceptedFriendships.filter(friendship =>
                friendship.friend.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
    },
    methods: {
        filterList() {},
        getFeaturedImage(userImages, type) {
            return getFeaturedImage(userImages, type);
        },
        toggleRoomPublic() {
            this.isPublicRoom = !this.isPublicRoom;
        },
        requiredFieldsArePresent() {
            return this.selectedUsers.length > 0 && this.roomName !== '' && this.selectedImage !== null;
        },
        handleImageUpload(event) {
            this.selectedImage = event.target.files[0];
        },
        handleUserClick(user, index) {
            this.addUserToSelectedUsers(user);
            this.toggleItem(index);
        },
        toggleItem(index) {
            if (this.clickedItems.includes(index)) {
                this.clickedItems = this.clickedItems.filter((item) => item !== index);
            } else {
                this.clickedItems.push(index);
            }
        },
        addUserToSelectedUsers(user) {
            if (this.selectedUsers.includes(user)) {
                this.selectedUsers = this.selectedUsers.filter((item) => item !== user);
            } else {
                this.selectedUsers.push(user);
            }
        },
        saveGroupChatUser(roomId, userId) {
            callChatRoomUsersCreate(roomId, {
                chat_room_id: roomId,
                user_id: userId,
                is_admin: true
            }).then(() => {
                console.log(`SAVING USER ${userId} TO ROOM ${roomId} SUCCESSFUL`);
            }).catch((error) => {
                console.error(error);
            });
        },
        async saveGroupChat() {
            const formData = new FormData();
            formData.append('image', this.selectedImage);
            formData.append('name', this.roomName);
            formData.append('room_type', "Group");
            formData.append('creator_id', this.$store.state.currentUserId);
            formData.append('is_public', this.isPublicRoom);

            try {
                const response = await Axios.post(`/api/v1/chat_rooms/`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                const room = response.data;
                this.selectedUsers.push({ id: this.$store.state.currentUserId });
                for (let user of this.selectedUsers) {
                    await this.saveGroupChatUser(room.id, user.id);
                }

                const groupChatRooms = this.$store.getters['chatRooms/groupChatRooms'];
                groupChatRooms.push(room);
                this.$store.commit("chatRooms/setGroupChatRooms", groupChatRooms);

                this.$refs.createGroupChatModalRef.hide();
            } catch (error) {
                console.error(error);
            }
        }
    }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

#create-group-chat-index {
    .modal-content {
        border-radius: 5px;
        border: none;
        width: 100vw;
        // height: 97vh;
    }
}

.modal-lg.modal-dialog {
    max-width: 100vw;
    margin: 1rem;
}

.create-group-chat {

    //https://github.com/mercs600/vue2-perfect-scrollbar
    .ps {
        min-height: 27rem;
        max-height: 28rem;
    }

    .create-group-chat__hide-modal {
        position: absolute;
        top: 1rem;
        left: 1rem;
        height: 2rem;
        width: 2rem;
        opacity: 0.5;
        cursor: pointer;
    }

    .create-group-chat__header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 1rem;
        margin-bottom: 2rem;

        .atmo-page-header {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
        }
    }

    .create-group-chat-subheader {
        text-align: center;
        font-weight: 500;
        font-size: 1.2rem;
    }

    .modal-content {
        padding: 2rem;
    }


    .create-group-chat__room-name-container {}

    .room-name-container__header {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .room-name-container__search-wrap {
        margin-bottom: 1rem;
    }

    .create-group-chat__toggle-container {}

    .toggle-container__header {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .toggle-container__toggle-wrap {
        margin-bottom: 1rem;
    }

    .create-group-chat__image-upload-container {
        margin-bottom: 1rem;
    }

    .image-upload-container__header {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .image-upload-container__search-wrap {
        margin-bottom: 1rem;
    }

    .create-group-chat__friends-container {
        margin-bottom: 1rem;
    }

    .friends-container__header {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .friends-container__search-wrap {
        margin-bottom: 1rem;
    }

    .input-field-semi-rounded {
        background: transparent;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, .4);
        padding: .5rem 1rem .5rem 1rem;
        color: white;
        width: 100%;
        position: relative;
    }

    .search-field-rounded {
        background: transparent;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, .4);
        padding: .5rem .5rem .5rem 2.5rem;
        color: white;
        width: 100%;
        position: relative;
        background: url('@/assets/images/icons/search.png') no-repeat scroll 7px 7px;
        background-size: 1rem;
        background-position: .8rem .5rem;
    }

    .friends-container__friend-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
        border-radius: 5px;
        background-color: rgba(96, 72, 117, 0.3);
        backdrop-filter: blur(5px);
        margin-bottom: 1rem;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, 0);

        &.clicked {
            border: 1px solid $atmo-blue--medium;
        }

        .image-wrap__image {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 100%;
            margin-right: .5rem;
        }

        .queue-wrap__name-wrap {
            font-size: .9rem;
            font-weight: 500;
        }
    }

    .create-group-chat__save-container {
        display: flex;
    }

    .save-button {
        margin-left: auto;
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
    }

    .save-button--unusable {
        margin-left: auto;
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: rgba(0, 0, 0, .2);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
    }
}
</style>