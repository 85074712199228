<template>
  <perfect-scrollbar>
    <div class="charts__charts-grid">
      <router-link 
        v-for="(chart, index) in sanitizedCharts" 
        :key="index" 
        :to="`charts/${sanitizeChartName(chart.item.name)}`"
        class="charts-grid__chart-card"
        v-bind:style="getBackgroundStyle(chart.item)"
      >
        <div class="chart-card__title-wrap">{{ chart.item.name }}</div>
      </router-link>
    </div>
  </perfect-scrollbar>
</template>

<script>
import PaginationMixin from '@/mixins/pagination';

export default {
  mixins: [PaginationMixin],
  props: { 
    charts: { type: Array, required: true },
    type: { type: String, required: true } // 'genre' or 'mood'
  },
  computed: {
    sanitizedCharts() {
      return this.charts.map(chart => ({
        item: chart[this.type]
      }));
    }
  },
  methods: {
    sanitizeChartName(name) {
      return name.toLowerCase().replace(/\s+/g, '-');
    },
    getBackgroundStyle(item) {
      const gradientStyle = 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)';
      const backgroundImage = item.image ? `, url(${item.image.url})` : '';
      return { 'background-image': `${gradientStyle}${backgroundImage}` };
    }
  }
}
</script>

<style lang="scss">
.charts__charts-grid {
  display: grid;
  // grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  grid-gap: 1rem;
  margin-top: 10px;
  height: 100%;
  position: relative;
  margin-bottom: 1rem;

  .charts-grid__chart-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    border-radius: 5px;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .chart-card__title-wrap {
    font-weight: 800;
    font-size: 1.3rem;
  }
}
</style>