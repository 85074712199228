import Axios from 'axios';

export function callMoodSongsIndex(moodId, queryParams = {}) {
    return Axios.get(`/api/v1/moods/${moodId}/mood_songs`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}