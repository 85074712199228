export function utilsGetImgUrl(image) {
  if (!image) {
    return image;
  }

  // TODO: I AM ASHAMED OF THIS. WE NEED TO REMOVE MOCK DATA AS SOON AS POSSIBLE
  if (image.includes("http://") || image.includes("https://")) {
    console.log("AWS", image);
    return image;
  } else if (image == "default_pic.jpg" || image == "default_pic_blue.jpg") {
    console.log("DEFAULT", image);
    return require(`@/assets/images/${image}`);
  } else if (image == "arrow_left" || image == "arrow_right" || image == "no_contests" || image == "no-results" || image == "no-directory-profiles") {
    console.log("ICON", image);
    return require(`@/assets/images/icons/${image}.png`);
  } else if (image == "plus.png") {
    console.log("ICON 2", image);
    return require(`@/assets/images/icons/${image}`);
  } else if (image == "played.png" || image == "voted.png" || image == "recent.png") {
    console.log("CHART", image);
    return require(`@/assets/images/icons/charts/${image}`);
  } else if (image == "bg_623_2.jpg") {
    console.log("CONCERT", image);
    return require(`@/assets/images/${image}`);
  } else if (image == "/img/default_pic.0f7828f4.jpg") {
    console.log("WUT RU", image);
    return require(`@/assets/images/default_pic.jpg`);
  }
}

export function utilsGetDate(dateVal) {
  const dateStringOptions = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(dateVal).toLocaleDateString("en-us", dateStringOptions);
}

export function formatDateMMDDYYYY(inputDate) {
  const date = new Date(inputDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}

export function formatDateToDayTimeYear(inputDate) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const date = new Date(inputDate);

  const month = months[date.getMonth()];
  const day = date.getDate();
  const daySuffix = getDaySuffix(day); // Function to get the day suffix (e.g., "th", "st", "nd")
  const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const period = date.getHours() < 12 ? "am" : "pm";
  const year = date.getFullYear();

  return `${hours}:${minutes}${period} ${month} ${day}${daySuffix}, ${year}`;
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return day + "th";
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return day + "st";
    case 2:
      return day + "nd";
    case 3:
      return day + "rd";
    default:
      return day + "th";
  }
}

function formatTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes}${ampm}`;
}

export function formatDateToMonthDayYearHour(inputDate) {
  const date = new Date(inputDate);

  // Ensure the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date");
    return null;
  }

  // Adjust for UTC offset
  const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

  // Get month name abbreviation
  const monthAbbreviation = new Intl.DateTimeFormat("en-US", { month: "short" }).format(localDate);

  // Get day with appropriate suffix
  const dayWithSuffix = getDaySuffix(localDate.getDate());

  // Get formatted time
  const formattedTime = formatTime(localDate);

  // Construct the final formatted date string
  const formattedDate = `${monthAbbreviation} ${dayWithSuffix}, ${localDate.getFullYear()} @ ${formattedTime}`;

  return formattedDate;
}

export function formatDateToAbbrMonth(inputDate) {
  // Create a Date object from the provided string
  const date = new Date(inputDate);

  // Define an array of month abbreviations
  const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get the month from the Date object (0-indexed) and use it to index into the array
  const monthAbbreviation = monthAbbreviations[date.getUTCMonth()];

  return monthAbbreviation;
}

export function formatDateToDayOfMonth(inputDate) {
  // Create a Date object from the provided string
  const date = new Date(inputDate);

  // Get the day of the month from the Date object (1-indexed)
  const dayOfMonth = date.getUTCDate();

  return dayOfMonth.toString();
}

export function formatCurrency(number) {
  console.log("FIRING FROM UTILS");
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}

export function formatDateFromRailsToVue(inputDate) {
  const date = new Date(inputDate);

  // Ensure the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date");
    return null;
  }

  // Format the date
  const formattedDate = date.toISOString().slice(0, 16).replace("T", " ");

  return formattedDate;
}

export function convertToRailsDatetime(dateString) {
  const [year, month, day] = dateString.split("-");
  const date = new Date(year, month - 1, day);
  date.setUTCHours(12, 0, 0, 0);
  const formattedDate = date.toISOString();
  return formattedDate;
}

export function timeAgo(dateString) {
  const now = new Date();
  const postDate = new Date(dateString);
  const diffInSeconds = Math.floor((now - postDate) / 1000);

  let interval = Math.floor(diffInSeconds / 31536000); // years
  if (interval >= 1) return interval === 1 ? `${interval} year ago` : `${interval} years ago`;

  interval = Math.floor(diffInSeconds / 2592000); // months
  if (interval >= 1) return interval === 1 ? `${interval} month ago` : `${interval} months ago`;

  interval = Math.floor(diffInSeconds / 86400); // days
  if (interval >= 1) return interval === 1 ? `${interval} day ago` : `${interval} days ago`;

  interval = Math.floor(diffInSeconds / 3600); // hours
  if (interval >= 1) return interval === 1 ? `${interval} hour ago` : `${interval} hours ago`;

  interval = Math.floor(diffInSeconds / 60); // minutes
  if (interval >= 1) return interval === 1 ? `${interval} minute ago` : `${interval} minutes ago`;

  return diffInSeconds <= 1 ? "just now" : `${diffInSeconds} seconds ago`;
}

export function getFeaturedImage(images, type) {
  if (images === null || images.length === 0) {
    // return null;
    return require(`@/assets/images/default_pic.jpg`);
  }

  // First, check if any image has is_featured_image: true
  for (const image of images) {
    if (image.is_featured_image === true) {
      if (type === "thumb") {
        return image.image.thumb.url;
      } else if (type === "full") {
        return image.image.url;
      }
    }
  }

  // If no image has is_featured_image: true, return the first one
  if (images.length > 0) {
    if (type === "thumb") {
      return images[0].image.thumb.url;
    } else if (type === "full") {
      return images[0].image.url;
    }
  }
}

export function getFeaturedVideo(videos) {
  if (videos === null || videos.length === 0) {
    return null;
  }

  // First, check if any video has is_featured_video: true
  for (const video of videos) {
    if (video.is_featured_video === true) {
      return video;
    }
  }

  // If no video has is_featured_video: true, return the first one
  if (videos.length > 0) {
    return videos[0];
  }
}

export function formatAddress(addressObject) {
  if (!addressObject) {
    return "Invalid address";
  }

  const { line_1, line_2, zip_code, country, state, city } = addressObject;

  let formattedAddress = `${line_1}`;

  if (line_2) {
    formattedAddress += `, ${line_2}`;
  }

  //REMOVING FOR EVENTS:
  // formattedAddress += `, ${city.name}, ${state.abbreviation} ${zip_code}, ${country.name}`;
  formattedAddress += `, ${city.name}, ${state.abbreviation} ${zip_code}`;

  return formattedAddress;
}

export function getTicketPriceRange(ticketList) {
  if (!ticketList || ticketList.length === 0) {
    return "No tickets available";
  }

  const prices = ticketList.map((ticket) => ticket.price);
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  if (minPrice === 0) {
    return `Free - $${maxPrice.toFixed(2)}`;
  } else if (minPrice === maxPrice) {
    return `$${minPrice.toFixed(2)}`;
  } else {
    return `$${minPrice.toFixed(2)} - $${maxPrice.toFixed(2)}`;
  }
}

export function formatPriceAsCurrency(price) {
  if (!price || isNaN(price)) {
    return "Invalid price";
  }

  const formattedPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);

  return formattedPrice;
}

export function isDeepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }

    for (let i = 0; i < obj1.length; i++) {
      if (!isDeepEqual(obj1[i], obj2[i])) {
        return false;
      }
    }
  }

  return true;
}

export function deepCopy(obj, memo = new WeakMap()) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (memo.has(obj)) {
    return memo.get(obj);
  }

  if (Array.isArray(obj)) {
    const newArray = [];
    memo.set(obj, newArray);

    for (let i = 0; i < obj.length; i++) {
      newArray[i] = deepCopy(obj[i], memo);
    }

    return newArray;
  }

  const newObj = {};
  memo.set(obj, newObj);

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] = deepCopy(obj[key], memo);
    }
  }

  return newObj;
}

export function extractUniqueColorNames(data) {
  const uniqueColors = new Set();

  // Iterate through each product variant
  data.forEach((variant) => {
    // Add the color name to the set
    uniqueColors.add(variant.product_color.name);
  });

  // Convert the Set to an array and return
  return Array.from(uniqueColors);
}

export function extractUniqueColors(data) {
  const uniqueColors = {};

  data.forEach((variant) => {
    const color = variant.product_color;
    uniqueColors[color.id] = color;
  });

  return Object.values(uniqueColors);
}

export function getSizesByColor(color, product) {
  return product.product_variants.filter((variant) => variant.product_color.name.toLowerCase() === color.toLowerCase()).map((variant) => variant.product_size.name);
}

export function saveToLocalStorage(key, data, expirationInMinutes) {
  // Store in session storage (optional alternative)
  // sessionStorage.setItem(key, jsonString);

  const now = new Date();

  const item = {
    value: data,
    expiry: now.getTime() + expirationInMinutes * 60 * 1000,
  };

  localStorage.setItem(key, JSON.stringify(item));
}

export function getFromLocalStorage(key) {
  const itemStr = localStorage.getItem(key);

  // If the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  // Compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage and return null
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export function objIsEmpty(obj) {
  return Object.entries(obj).length === 0;
}

export function findOtherUsersInRoom(currentChatRoom, store) {
  const currentUserId = store.state.currentUserId;

  if (!currentChatRoom || !currentChatRoom.chat_room_users) {
    return []; // Handle cases where the data structure is missing by returning an empty array
  }

  return currentChatRoom.chat_room_users.filter((user) => user.user_id !== currentUserId).map((user) => user);
}

export function formatSongPlayCount(number) {
  if (number == null) {
    return "0";
  }
  
  if (number >= 1_000_000_000) {
    return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, "") + "bil";
  } else if (number >= 1_000_000) {
    return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "mil";
  } else if (number >= 1_000) {
    return (number / 1_000).toFixed(1).replace(/\.0$/, "") + "k";
  }

  return number.toString();
}

export function getSongDuration(song) {
  if (!song) {
    return 0;
  }
  
  // Check for null, undefined, or 0 duration and return "0:00" if so
  const seconds = song.song_profile.duration || 0;
  
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);
  const paddedSeconds = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

  return `${minutes}:${paddedSeconds}`;
}
  