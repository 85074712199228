import {
    callUserRelationshipsIndex,
    callUserRelationshipsCreate,
    callUserRelationshipsDelete
} from '@/helpers/axiosCalls';
import { connectToRelationshipsChannel, setCallback as setRelationshipsCallback } from '@/cable-channels/userRelationships';

let relationshipsCallbackSet = false;

export default {
    namespaced: true,
    state: {
        debug: false,
        artistSubscriptions: [],
        relationships: [],
        currentUserIsFollowing: false,
        relationshipId: null
    },
    mutations: {
        setArtistSubscriptions(state, subscriptions) {
            if (state.debug) console.log('Mutation: setArtistSubscriptions', subscriptions);
            state.artistSubscriptions = subscriptions;
        },
        setRelationships(state, relationships) {
            if (state.debug) console.log('Mutation: setRelationships', relationships);
            state.relationships = relationships;
        },
        setCurrentUserIsFollowing(state, isFollowing) {
            if (state.debug) console.log('Mutation: setCurrentUserIsFollowing', isFollowing);
            state.currentUserIsFollowing = isFollowing;
        },
        setRelationshipId(state, relationshipId) {
            if (state.debug) console.log('Mutation: setRelationshipId', relationshipId);
            state.relationshipId = relationshipId;
        }
    },
    actions: {
        async fetchFollowerRelationships({ commit, state }, userId) {
            try {
                if (state.debug) console.log(`Fetching user follower relationships for user ID: ${userId}`);
                const followerResponse = await callUserRelationshipsIndex({ follower_id: userId });
                commit('setArtistSubscriptions', followerResponse);
                if (state.debug) console.log("Follower Response", followerResponse);
                return followerResponse;
            } catch (error) {
                console.error(error);
                return [];
            }
        },
        async fetchFollowedRelationships({ commit, state }, userId) {
            try {
                if (state.debug) console.log(`Fetching user followed relationships for user ID: ${userId}`);
                const followedResponse = await callUserRelationshipsIndex({ followed_id: userId });
                commit('setRelationships', followedResponse);
                if (state.debug) console.log("Followed Response", followedResponse);
                return followedResponse;
            } catch (error) {
                console.error(error);
                return [];
            }
        },
        async followUser({ dispatch, rootState, state }, followedId) {
            try {
                if (state.debug) console.log('Attempting to follow user:', followedId);
                const relationship = await callUserRelationshipsCreate({
                    follower_id: rootState.currentUserId,
                    followed_id: followedId
                });
                await dispatch('updateFollowingState', followedId);
                if (state.debug) console.log('Successfully followed user:', followedId);

                if (relationship) {
                    this.$store.dispatch('userActivities/createUserActivity', {
                        user_activity_type_name: "Artist Follow",
                        user_id: rootState.currentUserId,
                        activityable_type: "Relationship",
                        activityable_id: relationship.id
                    });
                }
            } catch (error) {
                console.error('Error following user:', error);
            }
        },
        async unfollowUser({ dispatch, state, rootState }) {
            try {
                if (state.debug) console.log('Attempting to unfollow user:', rootState.currentUserId);
                await callUserRelationshipsDelete(state.relationshipId);
                await dispatch('updateFollowingState', rootState.currentUserId);
                if (state.debug) console.log('Successfully unfollowed user:', rootState.currentUserId);
            } catch (error) {
                console.error('Error unfollowing user:', error);
            }
        },
        async updateFollowingState({ commit, dispatch, rootState, state }, profileUserId) {
            try {
                if (state.debug) console.log('Fetching follower relationships for user:', profileUserId);
                const followerRelationships = await dispatch('fetchFollowerRelationships', rootState.currentUserId);
                if (state.debug) console.log('Fetched follower relationships:', followerRelationships);
                const followingRelationship = followerRelationships.find((r) => r.followed_id === profileUserId);
                if (state.debug) console.log("Following Relationship", followingRelationship);
                if (followingRelationship) {
                    commit('setCurrentUserIsFollowing', true);
                    commit('setRelationshipId', followingRelationship.id);
                } else {
                    commit('setCurrentUserIsFollowing', false);
                    commit('setRelationshipId', null);
                }
            } catch (error) {
                console.error('Error updating following state:', error);
            }
        },
        connectToUserRelationships({ dispatch, rootState, state }) {
            const userId = rootState.currentUserId;
            if (state.debug) console.log("Current User ID:", userId);
            if (userId) {
                if (state.debug) console.log("Connecting to relationships channel for user ID:", userId);
                connectToRelationshipsChannel(userId);
                if (!relationshipsCallbackSet) {
                    setRelationshipsCallback(() => {
                        if (state.debug) console.log("Relationships callback triggered for user ID:", userId);
                        dispatch('fetchFollowerRelationships', userId);
                        if (state.debug) console.log("DISPATCHING fetchFollowerRelationships for user ID:", userId);
                    });
                    relationshipsCallbackSet = true;
                }
            } else {
                if (state.debug) console.log("No user ID found, not connecting to relationships channel.");
            }
        }
    },
    getters: {
        getArtistSubscriptions: state => state.artistSubscriptions,
        getRelationships: state => state.relationships,
        getCurrentUserIsFollowing: state => state.currentUserIsFollowing,
        getRelationshipId: state => state.relationshipId
    }
};
