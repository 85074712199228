import Axios from 'axios';

export function callContestPrizeWinnersIndex(contestId, queryParams = {}) {
    return Axios.get(`/api/v1/contests/${contestId}/contest_prize_winners`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestPrizeWinnersCreate(contestId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/contests/${contestId}/contest_prize_winners`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestPrizeWinnersUpdate(contestId, prizeWinnerId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/contests/${contestId}/contest_prize_winners/${prizeWinnerId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestPrizeWinnersDelete(contestId, prizeWinnerId, queryParams = {}) {
    return Axios.delete(`/api/v1/contests/${contestId}/contest_prize_winners/${prizeWinnerId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}