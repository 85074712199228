import Axios from 'axios';

export function callCardsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/cards`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callCardsCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/cards`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
