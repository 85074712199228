import { callAtmoChartsIndex } from '@/helpers/axiosCalls';

function sanitizeChartType(chartType) {
  return chartType.toLowerCase().replace(/\s+/g, '-');
}

export default {
  namespaced: true,
  state: {
    debug: false,
    charts: [],
    genreCharts: [],
    nonGenreCharts: [],
    moodCharts: [],
    topVotedChart: {},
    topYearChart: {},
    mostPlayedChart: {},
  },
  mutations: {
    setCharts(state, charts) {
      if (state.debug) console.log('Setting charts:', charts);
      state.charts = charts;
    },
    setGenreCharts(state, charts) {
      if (state.debug) console.log('Setting genre charts:', charts);
      state.genreCharts = charts;
    },
    setMoodCharts(state, charts) {
      if (state.debug) console.log('Setting mood charts:', charts);
      state.moodCharts = charts;
    },
    setTopVotedChart(state, chart) {
      state.topVotedChart = {
        ...chart,
        sanitizedChartType: sanitizeChartType(chart.chart_type),
      };
      if (state.debug) console.log('Setting top voted chart:', state.topVotedChart);
      this.commit('charts/setNonGenreCharts');
    },
    setTopYearChart(state, chart) {
      state.topYearChart = {
        ...chart,
        sanitizedChartType: sanitizeChartType(chart.chart_type),
      };
      if (state.debug) console.log('Setting top year chart:', state.topYearChart);
      this.commit('charts/setNonGenreCharts');
    },
    setMostPlayedChart(state, chart) {
      state.mostPlayedChart = {
        ...chart,
        sanitizedChartType: sanitizeChartType(chart.chart_type),
      };
      if (state.debug) console.log('Setting most played chart:', state.mostPlayedChart);
      this.commit('charts/setNonGenreCharts');
    },
    setNonGenreCharts(state) {
      if (state.debug) console.log('Setting non-genre charts:', [
        state.topVotedChart,
        state.topYearChart,
        state.mostPlayedChart,
      ]);
      state.nonGenreCharts = [
        state.topVotedChart,
        state.topYearChart,
        state.mostPlayedChart,
      ].filter(chart => chart && Object.keys(chart).length > 0); // Only include valid charts
    },
  },
  actions: {
    async fetchTopVotedChart({ commit, state }) {
      try {
        if (state.debug) console.log('Fetching top voted chart...');
        const chart = await callAtmoChartsIndex({ chart_type: 'top_voted' });
        if (state.debug) console.log('Top voted chart fetched:', chart);
        commit('setTopVotedChart', chart);
      } catch (error) {
        console.error('Failed to fetch top voted chart:', error);
      }
    },
    async fetchTopYearChart({ commit, state }, year) {
      try {
        if (state.debug) console.log(`Fetching top songs for the year ${year}...`);
        const chart = await callAtmoChartsIndex({ chart_type: 'top_year', year });
        if (state.debug) console.log(`Top songs for the year ${year} fetched:`, chart);
        commit('setTopYearChart', chart);
      } catch (error) {
        console.error(`Failed to fetch top songs for the year ${year}:`, error);
      }
    },
    async fetchMostPlayedChart({ commit, state }) {
      try {
        if (state.debug) console.log('Fetching most played chart...');
        const chart = await callAtmoChartsIndex({ chart_type: 'most_played' });
        if (state.debug) console.log('Most played chart fetched:', chart);
        commit('setMostPlayedChart', chart);
      } catch (error) {
        console.error('Failed to fetch most played chart:', error);
      }
    },
    async fetchMostPlayedGenreCharts({ commit, state }) {
      try {
        if (state.debug) console.log('Fetching most played genre charts...');
        const charts = await callAtmoChartsIndex({ chart_type: 'most_played', all_genres: true });
        if (state.debug) console.log('Most played genre charts fetched:', charts);
        commit('setGenreCharts', charts);
      } catch (error) {
        console.error('Failed to fetch most played genre charts:', error);
      }
    },
    async fetchTopVotedMoodCharts({ commit, state }) {
      try {
        if (state.debug) console.log('Fetching top voted mood charts...');
        const charts = await callAtmoChartsIndex({ chart_type: 'top_voted', all_moods: true });
        if (state.debug) console.log('Top voted mood charts fetched:', charts);
        commit('setMoodCharts', charts);
      } catch (error) {
        console.error('Failed to fetch top voted mood charts:', error);
      }
    },
  },
  getters: {
    getCharts: (state) => {
      if (state.debug) console.log('Getting all charts:', state.charts);
      return state.charts;
    },
    getTopVotedChart: (state) => {
      if (state.debug) console.log('Getting top voted chart:', state.topVotedChart);
      return state.topVotedChart;
    },
    getTopYearChart: (state) => {
      if (state.debug) console.log('Getting top year chart:', state.topYearChart);
      return state.topYearChart;
    },
    getGenreCharts: (state) => {
      if (state.debug) console.log('Getting genre charts:', state.genreCharts);
      return state.genreCharts;
    },
    getMoodCharts: (state) => {
      if (state.debug) console.log('Getting mood charts:', state.moodCharts);
      return state.moodCharts;
    },
    getNonGenreCharts: (state) => {
      if (state.debug) console.log('Getting non-genre charts:', state.nonGenreCharts);
      return state.nonGenreCharts;
    },
    getMostPlayedChart: (state) => {
      if (state.debug) console.log('Getting most played chart:', state.mostPlayedChart);
      return state.mostPlayedChart;
    },
  },
};
