<template>
  <div class="atmo-artists">
    <h2 class="atmo-page-header atmo-artists__header">
      Atmo Artists
    </h2>
    <section class="atmo-artists__body">
      <form class="atmo-form atmo-artists__controls">
        <input type="text" placeholder="Search for artists"
          class="atmo-artists__artist-search atmo-input atmo-input--search" :value="query" @input="onSearch">
        <button class="atmo-artists__reset-button atmo-button" title="Reset Filters and Sorts" @click.prevent="reset">
          <img src="@/assets/images/icons/reset.png" alt="reset">
        </button>
        <multiselect v-if="genreOptions" id="albumGenre" :value="currentGenreFilter" class="atmo-artists__genre-select"
          :options="genreOptions" label="name" :searchable="true" :show-labels="false" :close-on-select="true"
          placeholder="Filter by Genre" @select="selectGenre" />
        <div class="atmo-artists__sort-by">
          <button class="atmo-artists__control-button atmo-button atmo-button--primary"
            @click.prevent="toggleNewestArtistsSortDirection">
            <img v-if="newestArtistsSortDirection === null" src="@/assets/images/icons/sort.png" alt="sort ascending">
            <img v-if="newestArtistsSortDirection === 1" src="@/assets/images/icons/sort.png" alt="sort ascending">
            <img v-if="newestArtistsSortDirection === -1" src="@/assets/images/icons/sort.png" alt="sort descending">
          </button>
          <span>Newest Artists</span>
        </div>
        <div class="atmo-artists__sort-by">
          <button class="atmo-artists__control-button atmo-button atmo-button--primary"
            @click.prevent="togglePopularitySortDirection">
            <img v-if="popularitySortDirection === null" src="@/assets/images/icons/sort.png" alt="sort ascending">
            <img v-if="popularitySortDirection === 1" src="@/assets/images/icons/sort.png" alt="sort ascending">
            <img v-if="popularitySortDirection === -1" src="@/assets/images/icons/sort.png" alt="sort descending">
          </button>
          <span>Popularity</span>
        </div>
      </form>
      <div class="atmo-artists__main-content">
        <!-- <atmo-music-items
          :is-empty="isEmpty"
          type="artists"
          card-size="large"
          :columns="5"
          :show-page-numbers="true"
          :current-page-number="Pagination_currentPageNumber"
          :max-page-number="Pagination_maxPageNumber"
          :items-for-current-page="Pagination_itemsForCurrentPage"
          :on-previous-click="Pagination_onPreviousClick"
          :on-next-click="Pagination_onNextClick"
        >
          <template v-if="isEmpty" v-slot:empty>
            <atmo-loading v-if="isLoading" />
            <template v-else>
              <img
                class="atmo-empty-state__icon"
                src="@/assets/images/icons/no-artists.png"
              >
              <span
                v-if="artists.length === 0"
                class="atmo-empty-state__label"
              >
                Oops! There are no artists!
              </span>
              <span
                v-else-if="Pagination_itemsForCurrentPage.length === 0"
                class="atmo-empty-state__label"
              >
                No matching artists. Try again
              </span>
            </template>
          </template>
        </atmo-music-items> -->

        <atmo-artists-grid :atmoArtists="atmoArtists" />
      </div>
    </section>
  </div>
</template>

<script>
import Axios from 'axios';
// import Multiselect from 'vue-multiselect';
// import AtmoLoading from '@/components/atmo-loading';
// import AtmoMusicItems from '@/components/atmo-music-items';
import AtmoArtistsGrid from '@/components/atmo-artists/atmo-artists-grid';
import PaginationMixin from '@/mixins/pagination';
import { artistsStub } from '../music/data';
import { firstBy } from "thenby";
import {
  callArtistsIndex
} from '@/helpers/axiosCalls';

const allGenresOption = {
  id: 'all',
  name: 'All Genres'
};

const newestArtestsSortFunction = function (a, b) {
  return new Date(b.user.created_at) - new Date(a.user.created_at);
};

export default {
  // components: { AtmoLoading, Multiselect, AtmoMusicItems, AtmoMusicArtistsGrid },
  components: { AtmoArtistsGrid },
  mixins: [PaginationMixin],
  props: {},
  data() {
    return {
      isLoading: true,
      artists: null,
      query: null,
      pageSize: 10,
      currentGenreFilter: allGenresOption,
      newestArtistsSortDirection: null,
      popularitySortDirection: null,
      atmoArtists: []
    };
  },
  computed: {
    fullCollection() {
      const genreId = this.currentGenreFilter.id;
      const filteredArtists = this.artists.filter((artist) => {
        const matchesQuery = (
          this.query === null
          || artist.name.toLowerCase().includes(this.query.toLowerCase())
        );
        const matchesGenre = ['all', artist.genre_id].includes(genreId);
        return matchesQuery && matchesGenre;
      });

      // no sort
      if (!this.popularitySortDirection && !this.newestArtistsSortDirection) {
        return filteredArtists;
        // both sorts
      } else if (this.popularitySortDirection && this.newestArtistsSortDirection) {
        return filteredArtists.sort(
          firstBy(newestArtestsSortFunction, this.newestArtistsSortDirection)
            .thenBy('follower_count', this.popularitySortDirection)
        )
        // only newests artists sort
      } else if (this.newestArtistsSortDirection) {
        return filteredArtists.sort(
          firstBy(newestArtestsSortFunction, this.newestArtistsSortDirection)
        );
        // only popularity sort
      } else {
        return filteredArtists.sort(
          firstBy('follower_count', this.popularitySortDirection)
        );
      }
    },
    isEmpty() {
      return this.isLoading || this.Pagination_itemsForCurrentPage.length === 0;
    },
    genreOptions() {
      return [allGenresOption].concat(this.$store.state.genreOptions);
    }
  },
  created() {
    // this.getArtists();
    this.getAtmoArtists();
  },
  methods: {
    getAtmoArtists() {
      callArtistsIndex()
        .then((response) => {
          this.atmoArtists = response;
          console.log("this.atmoArtists: ", this.atmoArtists);
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getArtists() {
      // TODO: Use real artists
      this.artists = artistsStub;
      this.isLoading = false;
    },
    onSearch(event) {
      this.Pagination_currentPageNumber = 1;
      this.query = event.target.value;
    },
    selectGenre(genre) {
      this.Pagination_currentPageNumber = 1;
      this.currentGenreFilter = genre;
    },
    toggleNewestArtistsSortDirection() {
      this.Pagination_currentPageNumber = 1;
      // 1 is ascending, -1 is descending
      this.newestArtistsSortDirection = this.newestArtistsSortDirection === 1 ? -1 : 1;
    },
    togglePopularitySortDirection() {
      this.Pagination_currentPageNumber = 1;
      // 1 is ascending, -1 is descending
      // on first toggle, set to descending, as we want most friends at the top
      // only set to ascending if it's already been set to descending
      this.popularitySortDirection = this.popularitySortDirection === -1 ? 1 : -1;
    },
    reset() {
      this.Pagination_currentPageNumber = 1;
      this.query = null;
      this.currentGenreFilter = allGenresOption;
      this.newestArtistsSortDirection = null;
      this.popularitySortDirection = null;
    }
  }
}
</script>


<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-artists {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 75px;

  &__header {
    margin: 35px 0;
  }

  &__body {
    // display: flex;
    // flex-direction: column;
    width: 100%
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__artist-search.atmo-input.atmo-input--search {
    height: 32px;
    min-width: 150px;
  }

  &__reset-button {
    padding: 6px 8px;
    background-color: $atmo-purple--medium-dark;
    margin: 0 5px;

    img {
      height: 15px;
    }

    &:hover {
      background-color: $atmo-purple--dark;
    }
  }

  &__genre-select {
    max-width: 250px;
  }

  &__sort-by {
    display: flex;
    align-items: center;
    margin: 0 5px;
    font-size: 1.1em;
    font-weight: 400;

    span {
      margin-left: 4px;
    }
  }

  &__control-button {
    padding: 4px 6px;

    img {
      height: 18px;
    }
  }

  &__main-content {
    // display: flex;
    // flex-grow: 1;
    width: 100%
  }

  .atmo-music-items__empty {
    flex-direction: column;
  }
}
</style>
