// frontend/client/cable.js
import cable from "actioncable";

let consumer;

function createChannel(channelName, roomId, acCallback) {
  if (!consumer) {
    consumer = cable.createConsumer();
  }
  return consumer.subscriptions.create({channel: channelName, room: roomId}, acCallback);
}

export default createChannel;
