// Vue Libraries
import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router'
import Axios from 'axios'
import Vue2Filters from 'vue2-filters'
import VueI18n from 'vue-i18n';
import lineClamp from 'vue-line-clamp';
import VuePageTitle from 'vue-page-title'
import Notifications from "vue-notification";
import BootstrapVue from "bootstrap-vue";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import datepickerOptions from "@/date-picker-options";
import  '@/assets/stylesheets/style.css';
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import VueCarousel from 'vue-carousel';

Axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(Notifications);
Vue.use(Vue2Filters);
Vue.use(BootstrapVue);
Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(lineClamp);
Vue.use(PerfectScrollbar);
Vue.use(VueCarousel);
Vue.use(VuePageTitle, {
  prefix: 'Atmosphere'
})

new Vue({
  render: h => h(App),
}).$mount('#vue-app');
