<template>
  <div class="atmo-music-suggested">
    <div class="atmo-music-suggested__albums">
      <!-- Since there's only one 'button', this just acts as a header -->
      <atmo-filter-buttons :active-filter="null" :filter-buttons="albumFilterButtons" />
      <atmo-loading v-if="isLoading" />
      <!-- <atmo-music-items
        v-else
        type="albums"
        :paginate="false"
        :items-for-current-page="albums"
        :is-aside="true"
      /> -->
      <atmo-music-albums-grid :albums="albums" />
    </div>
    <div class="atmo-music-suggested__music-items">
      <atmo-filter-buttons :active-filter="currentChildRoute" :filter-buttons="filterButtons"
        @on-button-click="onFilterButtonClick" />

      <router-view />
    </div>
  </div>
</template>

<script>
import AtmoLoading from '@/components/atmo-loading';
import AtmoFilterButtons from '@/components/atmo-filter-buttons';
// import AtmoMusicItems from '@/components/atmo-music-items';
import AtmoMusicAlbumsGrid from '@/components/music/atmo-music-albums-grid';
import {
  callAlbumsSuggested
} from '@/helpers/axiosCalls';


export default {
  components: {
    AtmoLoading,
    AtmoFilterButtons,
    // AtmoMusicItems,
    AtmoMusicAlbumsGrid
  },

  data() {
    return {
      isLoading: true,
      filterButtons: [
        { name: 'songs', label: 'songs' },
        { name: 'artists', label: 'artists' },
        { name: 'playlists', label: 'playlists' }
      ],
      albumFilterButtons: [
        { name: 'albums', label: 'albums' }
      ],
      albums: []
    };
  },

  computed: {
    routeSegments() {
      return this.$route.name.split('.');
    },
    currentChildRoute() {
      // 'songs', or 'artists'
      const routeSegments = this.routeSegments;
      return routeSegments[routeSegments.length - 1];
    }
  },

  created() {
    this.getAlbums();
  },

  methods: {
    onFilterButtonClick(filterName) {
      const newRouteName = `music.suggested.${filterName}`;
      this.$router.push({ name: newRouteName });
    },
    getAlbums() {
      callAlbumsSuggested()
      .then((response) => {
        this.albums = response;
        console.log("this.albums: ", this.albums);
        this.isLoading = false;
      }).catch((error) => {
        console.error(error);
      });
    }
  }
}
</script>

<style lang="scss">
.atmo-music-suggested {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
</style>
