<template>
  <div class="atmo-cart">
    <h2 class="atmo-page-header atmo-cart__header">
      Atmo Cart
    </h2>
    <div class="atmo-cart__body">
      <section class="atmo-cart__item-list">
        <div class="atmo-cart__empty-state" v-if="!cart.length > 0">
          Your cart is empty
        </div>
        <div v-for="(variant, index) in groupedCart" :key="index" class="atmo-cart__item">
          <div class="atmo-cart__item-image"
            :style="`background-image: url(${variant.product.images[0].image.thumb.url});`" />
          <div class="atmo-cart__item-content">
            <header class="atmo-cart__item-header">
              <div class="atmo-cart__item-title">
                {{ variant.product.title }}
              </div>
              <img @click="removeVariant(variant)" src="@/assets/images/icons/close_popup.png" alt="Remove from cart"
                class="atmo-cart__delete-item">
            </header>
            <hr class="atmo-cart__item-hr">
            <div class="atmo-cart__item-attributes">
              <div class="atmo-cart__item-attribute">
                <div id="atmo-cart-item__color" class="atmo-cart__item-attribute-label">
                  Color
                </div>
                <div class="atmo-cart__item-attribute-value atmo-cart__item-attribute-value--color"
                  aria-describedby="atmo-cart-item__color">
                  <div class="atmo-cart__color" :style="'background-color: ' + variant.product_color.name" />
                  <span>{{ variant.product_color.name }}</span>
                </div>
              </div>
              <div class="atmo-cart__item-attribute">
                <div id="atmo-cart-item__quantity" class="atmo-cart__item-attribute-label">
                  Quantity
                </div>
                <div class="atmo-cart__item-attribute-value" aria-describedby="atmo-cart-item__quantity">
                  <div class="atmo-product-details__quantity-controls">
                    <div class="atmo-product-details__quantity-control" title="decrement quantity"
                      @click="decrementQuantity(variant)">
                      &ndash;
                    </div>
                    <div class="atmo-product-details__quantity-display">
                      {{ variant.quantity }}
                    </div>
                    <div class="atmo-product-details__quantity-control" title="increment quantity"
                      @click="incrementQuantity(variant)">
                      +
                    </div>
                  </div>
                </div>
              </div>
              <div class="atmo-cart__item-attribute">
                <div id="atmo-cart-item__size" class="atmo-cart__item-attribute-label">
                  Size
                </div>
                <div class="atmo-cart__item-attribute-value" aria-describedby="atmo-cart-item__size">
                  {{ variant.product_size.name }}
                </div>
              </div>
              <div class="atmo-cart__item-attribute">
                <div id="atmo-cart-item__price" class="atmo-cart__item-attribute-label">
                  Price
                </div>
                <div class="atmo-cart__item-attribute-value" aria-describedby="atmo-cart-item__price">
                  {{ formatPriceAsCurrency(variant.price) }}
                </div>
              </div>
              <div class="atmo-cart__item-attribute">
                <div id="atmo-cart-item__total-price" class="atmo-cart__item-attribute-label">
                  Total Price
                </div>
                <div class="atmo-cart__item-attribute-value atmo-cart__item-attribute-value--total-price"
                  aria-describedby="atmo-cart-item__total-price">
                  {{ formatPriceAsCurrency(variant.total_price) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <aside class="atmo-cart__sidebar">
        <div class="shipping-options-container">
          <h2>Shipping Options</h2>
          <div v-for="option in filteredOptions" :key="option.object_id" class="shipping-option">
            <label :for="option.object_id" class="shipping-label">
              <div class="image-container">
                <input type="radio" :id="option.object_id" name="shippingOption" :value="option"
                  v-model="selectedShippingOption" @change="updateShippingCost(option.amount)" />
                <img :src="option.provider_image_75" alt="Provider Logo" />
              </div>
              <div class="details-container">
                <p>{{ option.servicelevel.display_name || option.servicelevel.name }}
                </p>
                <p><strong>Amount:</strong> ${{ option.amount }}</p>
                <p><strong>Estimated Delivery Time:</strong> {{ option.estimated_days }} days</p>
                <p v-if="option.attributes.includes('CHEAPEST')"><strong>Cheapest Option</strong></p>
                <p v-if="option.attributes.includes('FASTEST')"><strong>Fastest Option</strong></p>
                <p v-if="option.attributes.includes('BESTVALUE')"><strong>Best Value Option</strong></p>
              </div>
            </label>
          </div>
        </div>
        <div class="atmo-cart__attributes">
          <div class="atmo-cart__attribute">
            <div class="atmo-cart__attribute-label">
              Cart Total
            </div>
            <div class="atmo-cart__attribute-value">
              {{ cartCost ? formatPriceAsCurrency(cartCost) : '$0.00' }}
            </div>
          </div>
          <div class="atmo-cart__attribute">
            <div class="atmo-cart__attribute-label">
              Tax ({{ this.cartTaxRate * 100 }}%)
            </div>
            <div class="atmo-cart__attribute-value">
              {{ this.cartTax ? formatPriceAsCurrency(this.cartTax) : "$0.00" }}
            </div>
          </div>
          <div class="atmo-cart__attribute">
            <div class="atmo-cart__attribute-label">
              Shipping
            </div>
            <div class="atmo-cart__attribute-value">
              {{ this.cartShippingCost ? formatPriceAsCurrency(this.cartShippingCost) : "$0.00" }}
            </div>
          </div>
          <div class="atmo-cart__attribute atmo-cart__attribute--total">
            <div class="atmo-cart__attribute-label">
              Total
            </div>
            <div class="atmo-cart__attribute-value">
              {{ totalCost }}
            </div>
          </div>
        </div>
        <div class="atmo-cart__action-buttons">
          <router-link :to="{ name: 'products.index' }"
            class="atmo-cart__action-button atmo-button atmo-button--tertiary">
            <img src="@/assets/images/icons/store/cart.png" class="atmo-button__icon">
            <span>Back to Store</span>
          </router-link>
          <router-link :to="{ name: 'checkout' }" class="atmo-cart__action-button atmo-button atmo-button--primary"
            v-if="cartShippingCost > 0">
            Checkout
          </router-link>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import { formatPriceAsCurrency } from '@/helpers/utilityFunctions';
import { mapState, mapMutations } from 'vuex';
import {
  callShippingEstimateCreate
} from '@/helpers/axiosCalls';


export default {

  data() {
    return {
      cartTaxRate: .075, //TODO
      cartShippingCost: 0,
      redisCart: [],
      fromAddress: { //TODO
        name: 'Shawn Ippotle',
        street1: '215 Clayton St.',
        city: 'San Francisco',
        state: 'CA',
        zip: '94117',
        country: 'US'
      },
      toAddress: { //TODO
        name: 'Mr. Hippo',
        street1: '965 Mission St',
        city: 'San Francisco',
        state: 'CA',
        zip: '94103',
        country: 'US'
      },
      shippingOptions: [],
      selectedShippingOption: null
    }
  },

  computed: {
    ...mapState('products', ['cart', 'cartCost']),
    filteredOptions() {
      const cheapest = this.shippingOptions.find(option => option.attributes.includes('CHEAPEST'));
      const fastest = this.shippingOptions.find(option => option.attributes.includes('FASTEST'));
      const bestValue = this.shippingOptions.find(option => option.attributes.includes('BESTVALUE'));

      return [cheapest, fastest, bestValue].filter(option => option !== undefined);
    },
    totalCost() {
      return formatPriceAsCurrency(this.cartCost + this.cartTax + this.cartShippingCost);
    },
    groupedCart() {
      return this.groupItemsByQuantity(this.cart)
    },
    cartTax() {
      return this.cartCost * this.cartTaxRate;
    }
  },
  created() {
    this.getCartFromStorage();
    this.updateCartCost();
    this.getShippingEstimate();
    // this.getRedisCart();
  },

  methods: {
    ...mapMutations('products', ['addToCart', 'removeFromCart', 'removeGroupFromCart', 'updateCartCost', 'getCartFromStorage']),
    updateShippingCost(amount) {
      this.cartShippingCost = parseFloat(amount);
    },
    parcelsForShippo() {
      const parcels = [];
      this.cart.forEach(item => {
        const { length, width, height, weight, dimension_unit, weight_unit } = item.product.shipping_carrier_product;

        const parcel = {
          length,
          width,
          height,
          weight,
          distance_unit: dimension_unit.name,
          mass_unit: weight_unit.name
        }
        parcels.push(parcel);
      });

      return parcels;
    },
    getShippingEstimate() {
      console.log("CART ITEMS", this.cart);
      callShippingEstimateCreate({
        shipment: {
          from_address: this.fromAddress,
          to_address: this.toAddress,
          parcels: this.parcelsForShippo(),
        }
      })
        .then((response) => {
          this.shippingOptions = response;
        }).catch((error) => {
          console.error(error);
        })
    },
    groupItemsByQuantity(cart) {
      const groupedItemsMap = {};

      cart.forEach(itemObj => {
        const itemKey = itemObj.id;
        if (groupedItemsMap[itemKey]) {
          groupedItemsMap[itemKey].quantity += 1;
          groupedItemsMap[itemKey].total_price = parseFloat(groupedItemsMap[itemKey].total_price) + parseFloat(itemObj.price);
        } else {
          groupedItemsMap[itemKey] = { ...itemObj, quantity: 1, total_price: parseFloat(itemObj.price) };
        }
      });
      const groupedItemsArray = Object.values(groupedItemsMap);
      return groupedItemsArray;
    },
    formatPriceAsCurrency(number) {
      return formatPriceAsCurrency(number);
    },
    removeVariant(variant) {
      this.removeGroupFromCart(variant);
      this.updateCartCost();
      this.getShippingEstimate();
    },
    incrementQuantity(variant) {
      const cartItem = this.cart.find(item => item.id === variant.id);
      if (cartItem) {
        // this.cart.push(cartItem);
        this.addToCart(cartItem);
        this.updateCartCost();
        this.getShippingEstimate();
      }
    },
    decrementQuantity(variant) {
      const index = this.cart.findIndex(item => item.id === variant.id);
      if (index !== -1) {
        this.removeFromCart(index);
        this.updateCartCost();
        this.getShippingEstimate();
      }
    },
    // getRedisCart() {
    //   let userCartKey = `user_${this.$store.state.currentUserId}_atmo_cart`;
    //   Axios({
    //     method: 'get',
    //     url: '/api/v1/get_cart_items',
    //     params: {
    //       key: userCartKey
    //     }
    //   })
    //     .then((response) => {
    //       // let stringifiedCart = response;
    //       this.redisCart = response.data;
    //       console.log(this.redisCart);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-cart {
  .shipping-options-container {
    background-color: rgba(190, 167, 221, 0.4);
    margin: 0px 0px 5px 0px;
    border-radius: 4px;
    padding: 1rem;

    h2 {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }

  .shipping-option {
    margin-bottom: 1rem;
  }

  .shipping-label {
    display: flex;
    align-items: center;
    gap: .5rem;
  }

  .image-container {
    display: flex;
    align-items: center;

    input {
      margin-right: .5rem;
    }
  }

  .details-container {
    p+p {
      margin-top: .1rem;
    }
  }
}
</style>