var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contest-submission",class:{ 'contest-submission--standalone': _vm.isStandalone }},[_c('choose-winners-modal',{attrs:{"submission":_vm.submission},model:{value:(_vm.localModalVisible),callback:function ($$v) {_vm.localModalVisible=$$v},expression:"localModalVisible"}}),_c('div',[_c('div',{staticClass:"contest-submission__main"},[_c('div',{staticClass:"contest-submission__user-winner-container"},[_c('div',{staticClass:"user-winner-container__user-container"},[_c('img',{staticClass:"atmo-avatar atmo-avatar--small",style:({ 'background-image': `url(${_vm.getFeaturedImage(_vm.submission.user.images, 'full')})` })}),_c('div',{staticClass:"contest-submission__name"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")])]),(_vm.submission.contest.user_id == _vm.$store.state.currentUserId)?_c('div',{staticClass:"user-winner-container__winner-container",on:{"click":function($event){return _vm.openChooseWinnerModal()}}},[_vm._v(" Choose Winner ")]):_vm._e()]),_c('div',{staticClass:"contest-submission__description",attrs:{"title":_vm.submission.text}},[_vm._v(" "+_vm._s(_vm.submission.text)+" ")]),_c('div',[(!_vm.isStandalone && _vm.isMediaAttached())?_c('div',{staticClass:"contest-submission__media"},[_vm._v(" (Media attached) ")]):_vm._e(),(_vm.isStandalone)?_c('div',[_c('div',[_c('div',{staticClass:"contest-submission__submission-content"},[(_vm.submission.images.length > 0)?_c('div',{staticClass:"submission-content__submission-pics-container"},_vm._l((_vm.submission.images),function(submissionImage,index){return _c('img',{key:index,staticClass:"submission-pics-container__submission-pic",attrs:{"src":submissionImage.image.url,"alt":"Post pic"},on:{"click":function($event){return _vm.openUserMediaModal({
                    type: 'Image',
                    caption: _vm.submission.content,
                    url: submissionImage.image.url,
                    id: submissionImage.id
                  })}}})}),0):_vm._e(),(_vm.submission.videos.length > 0)?_c('div',{staticClass:"submission-content__submission-pics-container"},_vm._l((_vm.submission.videos),function(submissionVideo,index){return _c('img',{key:index,staticClass:"submission-pics-container__submission-pic",attrs:{"src":submissionVideo.preview_image.url,"alt":"Video preview"},on:{"click":function($event){return _vm.openUserMediaModal({
                    type: 'Video',
                    caption: _vm.submission.content,
                    url: submissionVideo.video.url,
                    id: submissionVideo.id
                  })}}})}),0):_vm._e()]),(_vm.submission.song_references.length > 0)?_c('div',_vm._l((_vm.submission.song_references),function(songReference,index){return _c('div',{key:index,staticClass:"contest-submission__submission-footer"},[_c('div',{staticClass:"contest-submission__song-actions-popover-container"},[_c('song-actions-popover',{attrs:{"song":_vm.song,"placement":"left","actions":[
                      'add-to-library',
                      'add-to-queue',
                      'add-to-playlist'
                    ]}})],1),_c('span',{staticClass:"contest-submission__song-link"},[_vm._v(" "+_vm._s(songReference.song.name)+" ")])])}),0):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"contest-submission__bottom"},[(!_vm.isStandalone)?_c('button',{staticClass:"atmo-button contest-submission__comment-badge",on:{"click":function($event){return _vm.onClick(_vm.submission)}}},[_c('img',{staticClass:"atmo-button__icon contest-submission__comment-badge-icon",attrs:{"src":require("@/assets/images/icons/comment--purple.png"),"alt":"Comment"}}),_vm._v(" "+_vm._s(_vm.submission.comments.length)+" ")]):_vm._e(),_c('contest-submission-votes',{staticStyle:{"margin-left":"auto"},attrs:{"submissionId":_vm.submission.id}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }