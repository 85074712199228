<template>
  <div class="edit-user-profile" data-cy="edit-user-profile">
    <div class="header-container">
      <h2 class="atmo-page-header">
        Edit Profile
      </h2>
    </div>
    <div class="edit-user-profile-grid">
      <div class="edit-user-profile-grid__image-container">
        <div class="image-container__image-wrap editing-image"
          v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${getFeaturedImage(currentUser.images, 'full')})` }">
          <div class="image-wrap__icon-wrap">
            <img class="icon-wrap__icon" src='@/assets/images/icons/profile/upload_new.png' />
            <div class="icon-wrap__text">Upload New Picture</div>
          </div>
        </div>
        <perfect-scrollbar>
          <div class="image-container__select-image-container">
            <div class="select-image-container__image-wrap" v-for="(image, index) in currentUser.images" :key="index"
              v-bind:style="{ 'background-image': `url(${image.image.url})` }">
              <div class="image-wrap__select-wrap">
                <img class="select-wrap__image" src='@/assets/images/icons/profile/check.png' />
              </div>
              <div class="image-wrap__delete-wrap">
                <img class="delete-wrap__image" src='@/assets/images/icons/profile/delete.png' />
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
      <div class="edit-user-profile-grid__fields-container">
        <div class="three-column-grid">
          <div class="field-wrap">
            <label for="name">First Name</label>
            <input class="text-field" type="text" id="name" name="name" placeholder="ex: Seth" v-model="currentUserFirstName">
          </div>
          <div class="field-wrap">
            <label for="name">Last Name</label>
            <input class="text-field" type="text" id="name" name="name" placeholder="ex: Jones" v-model="currentUserLastName">
          </div>
          <div class="field-wrap">
            <label for="email-address">Email Address</label>
            <input class="text-field" type="text" id="email-address" name="email-address"
              placeholder="ex: youremail@email.com" v-model="currentUserEmail">
          </div>
          <div class="field-wrap">
            <label for="address">Address Line 1</label>
            <input class="text-field" type="text" id="address1" name="address1"
              placeholder="ex: 654 27th Ave N" v-model="currentUserAddressLine1">
          </div>
          <div class="field-wrap">
            <label for="address">Address Line 2</label>
            <input class="text-field" type="text" id="address2" name="address2"
              placeholder="ex: 654 27th Ave N" v-model="currentUserAddressLine2">
          </div>
          <div class="field-wrap">
            <label for="address">Phone</label>
            <input class="text-field" type="text" id="phone" name="phone"
              placeholder="(111) 222-3333" v-model="currentUserPhoneNumber">
          </div>
        </div>
        <div class="three-column-grid">
          <div class="field-wrap">
            <label for="country">Country</label>
            <select class="dropdown-group" id="country" name="country" v-model="currentUserAddressCountry">
              <option class="dropdown-field" value="usa">United States</option>
              <option class="dropdown-field" value="france">France</option>
              <option class="dropdown-field" value="canada">Canada</option>
              <option class="dropdown-field" value="mexico">Mexico</option>
            </select>
          </div>
          <div class="field-wrap">
            <label for="state">State</label>
            <select class="dropdown-group" id="state" name="state" v-model="currentUserAddressState">
              <option class="dropdown-field" value="tx">Texas</option>
              <option class="dropdown-field" value="va">Virginia</option>
              <option class="dropdown-field" value="ma">Massachusetts</option>
              <option class="dropdown-field" value="ca">California</option>
            </select>
          </div>
          <div class="field-wrap">
            <label for="city">City</label>
            <input class="text-field" type="text" id="city" name="city" placeholder="ex: Texas City" v-model="currentUserAddressCity">
          </div>
          <div class="field-wrap">
            <label for="city">Zip Code</label>
            <input class="text-field" type="text" id="city" name="city" placeholder="ex: Texas City" v-model="currentUserAddressZipCode">
          </div>
        </div>
        <div class="two-column-grid">
          <div class="field-wrap">
            <label for="birthday">Birthday</label>
            <input class="text-field" type="text" id="birthday" name="birthday" placeholder="12/22/1990" v-model="currentUserBirthday">
          </div>
          <div class="field-wrap">
            <label for="gender">Gender</label>
            <fieldset class="radio-group" id="gender">
              <div class="radio-field-wrap">
                <input class="radio-field" type="radio" name="male" value="male" v-model="currentUserGender">
                <label class="radio-field-label" for="male">Male</label>
              </div>
              <div class="radio-field-wrap">
                <input class="radio-field" type="radio" name="female" value="female" v-model="currentUserGender">
                <label class="radio-field-label" for="female">Female</label>
              </div>
              <div class="radio-field-wrap">
                <input class="radio-field" type="radio" name="other" value="other" v-model="currentUserGender">
                <label class="radio-field-label" for="other">Other</label>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="two-column-grid">
          <div class="field-wrap">
            <label for="twitter">Twitter Profile Link</label>
            <input class="text-field" type="text" id="twitter" name="twitter" placeholder="ex: twitter.com/sethjones" v-model="currentUserTwitter">
          </div>

          <div class="field-wrap">
            <label for="facebook">Facebook Profile Link</label>
            <input class="text-field" type="text" id="facebook" name="facebook" placeholder="ex: fb.com/sethjones" v-model="currentUserFacebook">
          </div>
        </div>
        <div class="fields-container__button-wrap">
          <div class="button-wrap__save-cancel-container">
            <button class="save-cancel-container__save-button" @click="updateUserAttributes()">Save</button>
            <button class="save-cancel-container__cancel-button" @click="goBack()">Cancel</button>
          </div>
          <div class="button-wrap__account-settings-container">
            <router-link class="account-settings-container__account-settings-button" :to="{ name: 'settings' }">
              <img class="account-settings-button__image" src="@/assets/images/icons/profile/gear.png" />
              Account Settings
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- https://www.npmjs.com/package/vue-js-modal -->

<script>
import Axios from 'axios'
import { 
  callUserProfilesShow, 
  callUserProfilesUpdate, 
  callUsersShow 
} from '@/helpers/axiosCalls';
import { getFeaturedImage } from '@/helpers/utilityFunctions';

export default {
  data() {
    return {
      file: '',
      currentUser: {},
      currentUserProfile: {},
      firstNameChanged: false,
      lastNameChanged: false,
      newUserAttributes: {
        userProfile: {},
        user: {},
        address: {}
      },
    }
  },
  created() {
    this.getCurrentUser();
  },
  computed: {
    currentUserFirstName: {
      get() {
        return this.currentUserProfile.first_name;
      },
      set(value) {
        this.firstNameChanged = true;
        this.newUserAttributes.userProfile.first_name = value;
      }
    },
    currentUserLastName: {
      get() {
        return this.currentUserProfile.last_name;
      },
      set(value) {
        this.lastNameChanged = true;
        this.newUserAttributes.userProfile.last_name = value;
      }
    },
    currentUserEmail: {
      get() {
        return this.currentUser.email;
      },
      set(value) {
        this.newUserAttributes.user.email = value;
      }
    },
    currentUserGender: {
      get(){
        return this.currentUserProfile.gender ? this.currentUserProfile.gender.toLowerCase() : '';
      },
      set(value) {
        this.newUserAttributes.userProfile.gender = value;
      }
    },
    currentUserAddressLine1: {
      get() {
        return this.currentUser.user_address ? this.currentUser.user_address.line_1 : '';
      },
      set(value) {
        this.newUserAttributes.address.line_1 = value;
      }
    },
    currentUserAddressLine2: {
      get() {
        return this.currentUser.user_address ? this.currentUser.user_address.line_2 : '';
      },
      set(value) {
        this.newUserAttributes.address.line_2 = value;
      }
    },
    currentUserAddressState: {
      get() {
        return this.currentUser.user_address?.state ? this.currentUser.user_address.state.toLowerCase() : '';
      },
      set(value) {
        this.newUserAttributes.address.state = value;
      }
    },
    currentUserAddressCity: {
      get() {
        return this.currentUser.user_address?.city ? this.currentUser.user_address.city.toLowerCase() : '';
      },
      set(value) {
        this.newUserAttributes.address.city = value;
      }
    },
    currentUserAddressCountry: {
      get() {
        return this.currentUser.user_address?.country ? this.currentUser.user_address.country.toLowerCase() : '';
      },
      set(value) {
        this.newUserAttributes.address.country = value;
      }
    },
    currentUserAddressZipCode: {
      get() {
        return this.currentUser.user_address ? this.currentUser.user_address.zip_code : '';
      },
      set(value) {
        this.newUserAttributes.address.zip_code = value;
      }
    },
    currentUserPhoneNumber: {
      get() {
        if (this.currentUser.phone) {
          let cleaned = ('' + this.currentUser.phone).replace(/\D/g, '');
          let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
          } else {
            return '';
          }
        } else {
          return '';
        }
      },
      set(value) {
        this.newUserAttributes.user.phone = value.replace(/[^+\d]+/g, "");
      }
    },
    currentUserTwitter: {
      get() {
        return this.currentUserProfile.twitter;
      },
      set(value) {
        this.newUserAttributes.userProfile.twitter = value;
      }
    },
    currentUserFacebook: {
      get() {
        return this.currentUserProfile.facebook;
      },
      set(value) {
        this.newUserAttributes.userProfile.facebook = value;
      }
    },
    currentUserBirthday: {
      get() {
        return this.currentUserProfile.birthday ? new Date(this.currentUserProfile.birthday).toLocaleDateString() : '';
      },
      set(value) {
        this.newUserAttributes.userProfile.birthday = this.convertMMDDYYToDate(value);
      }
    },
    currentUserFullName: {
      get() {
        if (this.firstNameChanged && this.lastNameChanged) {
          return `${this.currentUserFirstName} ${this.currentUserLastName}`;
        } else if (this.firstNameChanged && !this.lastNameChanged) {
          return `${this.currentUserFirstName} ${this.currentUserProfile.last_name}`;
        } else if (!this.firstNameChanged && this.lastNameChanged) {
          return `${this.currentUserProfile.first_name} ${this.currentUserLastName}`;
        } else if (!this.firstNameChanged && !this.lastNameChanged) {
          return `${this.currentUserProfile.first_name} ${this.currentUserProfile.last_name}`;
        } else {
          return `${this.currentUserProfile.first_name} ${this.currentUserProfile.last_name}`;
        }
      },
    }
  },
  methods: {
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    goBack() {
      // Use router.go() to go back one step in the history
      // this.$router.go(-1);

      // Alternatively, you can use router.back() to do the same
      this.$router.back();
    },
    convertMMDDYYToDate(birthday) {
      if (birthday) {
        let date = birthday.split("/");
        return new Date(date[2], date[0] - 1, date[1]);
      }
    },
    updateUserAttributes() {
      console.log("DATA", this.newUserAttributes);

      callUserProfilesUpdate(this.currentUserProfile.id, this.newUserAttributes.userProfile)
        .then((response) => {
          console.log(response);
          callUserProfilesShow(this.currentUserProfile.id).then(response => (this.currentUserProfile = response));
        })
        .catch((error) => {
          console.error(error);
        });

      let userPayload = this.newUserAttributes.address ? {...this.newUserAttributes.user, name: this.currentUserFullName, address_attributes: this.newUserAttributes.address} : {...this.newUserAttributes.user, name: this.currentUserFullName};
        console.log("userPayload", userPayload);

      Axios.patch(`/api/v1/users/${this.currentUser.id}`, userPayload)
        .then((response) => {
          console.log(response);
          Axios.get(`/api/v1/users/${this.currentUser.id}`).then(response => (this.currentUser = response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    submitFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      Axios.patch(`/api/v1/users/${this.currentUser.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function () {
        console.log("SUCCESS");
        this.$notify({
          group: 'foo',
          title: 'File Upload Successful',
          text: 'You Successfully Updated Your Profile Pic!'
        });
      })
        .catch(function () {
          console.log('FAILURE!!');
          this.$notify({
            group: 'foo',
            title: 'File Upload Successful',
            text: 'You Successfully Updated Your Profile Pic!'
          });
        });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    getCurrentUserProfile(profileId) {
      callUserProfilesShow(profileId)
        .then((response) => {
          this.currentUserProfile = response;
          console.log("this.currentUserProfile", this.currentUserProfile);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCurrentUser() {
      callUsersShow(this.$store.state.currentUserId)
        .then((response) => {
          this.currentUser = response;
          this.getCurrentUserProfile(this.currentUser.profile_id);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.edit-user-profile {
  margin: 0 75px;

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: '';
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;
      
      &::before {
        opacity: 1;
      }

      ~label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }


  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-user-profile-grid {
    display: grid;
    gap: 3rem;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: 18rem 1fr;
    // border: 1px solid white;
  }

  .image-container__image-wrap {
    height: 19rem;
    // width: 19rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .image-wrap__icon-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon-wrap__icon {
    height: 9rem;
    margin-bottom: 1rem;
  }

  .icon-wrap__text {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
  }

  .image-wrap__image {
    border-radius: 5px;
    margin-bottom: .8rem;
    box-sizing: border-box;
  }

  .editing-image {
    border: 3px dashed white;
  }

  .image-container__select-image-container {
    max-height: 12rem;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  }

  .select-image-container__image-wrap {
    height: 5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-wrap__select-wrap {
    height: 1.5rem;
    width: 1.5rem;
    background: $atmo-purple--medium-dark--overlay-faded;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .image-wrap__delete-wrap {
    height: 1.5rem;
    width: 1.5rem;
    background: rgba(220, 53, 69, .9);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .image-wrap__select-wrap+.image-wrap__delete-wrap {
    margin-left: .5rem;
  }

  .select-wrap__image {
    height: .8rem;
  }

  .delete-wrap__image {
    height: .8rem;
  }

  .two-column-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1.5rem;
  }

  .three-column-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.5rem;
  }

  .field-wrap {
    display: flex;
    flex-direction: column;

    label {
      text-transform: uppercase;
      margin-bottom: .5rem;
      font-size: .9rem;
      font-weight: 500;
    }

    .text-field {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, .4);
      padding: .5rem .5rem .5rem .5rem;
      color: white;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, .4);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, .4);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, .4);
    }

    .dropdown-group {
      background: $atmo-purple--medium-dark;
      color: white;
      text-transform: uppercase;
      border: none;
      border-radius: 5px;
      padding: .5rem;
      font-weight: 500rem;
      cursor: pointer;
    }

    .dropdown-field {}

    .radio-group {
      display: flex;
      padding: .6rem 0rem .6rem 0rem;
    }

    .radio-field-wrap {
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .radio-field {
      margin-right: .5rem;
      cursor: pointer;
    }

    .radio-field-label {
      margin: 2px 0px 0px 0px;
    }
  }

  .fields-container__button-wrap {
    display: flex;
    margin-top: 1rem;
  }

  .button-wrap__save-cancel-container {
    display: flex;

    .save-cancel-container__save-button {
      font-size: 1rem;
      text-transform: uppercase;
      color: white;
      background-color: transparent;
      background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
      border-radius: 5px;
      padding: .5rem 2rem .5rem 2rem;
      margin-right: 1rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
    }

    .save-cancel-container__cancel-button {
      border: 1px solid white;
      font-size: 1rem;
      text-transform: uppercase;
      color: white;
      background-color: transparent;
      border-radius: 5px;
      padding: .5rem 1.5rem .5rem 1.5rem;
      margin-right: 1rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .button-wrap__account-settings-container {
    display: flex;
    margin-left: auto;

    .account-settings-container__account-settings-button {
      font-size: 1rem;
      text-transform: uppercase;
      color: white;
      background-color: $atmo-purple--medium;
      border-radius: 5px;
      padding: .5rem 1.5rem .5rem 1.5rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .account-settings-button__image {
      height: 1rem;
      width: auto;
      margin-right: .5rem;
    }
  }
}</style>