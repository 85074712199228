<template>
  <div class="vote-buttons-container">
    <button class="vote-buttons-container__up-button" @click="likeEntity(entity)">
      <span>{{ upVotes }}</span>
      <img src="@/assets/images/icons/upvote.png">
    </button>
    <button class="vote-buttons-container__down-button" @click="dislikeEntity(entity)">
      <span>{{ downVotes }}</span>
      <img src="@/assets/images/icons/downvote.png">
    </button>
  </div>
</template>

<script>
import {
  callLikesCreate,
  callDislikesCreate,
  callSongsShow,
  callAlbumsShow,
  callPlaylistsShow
} from '@/helpers/axiosCalls';

export default {
  props: {
    entity: {
      type: Object,
      required: true
    },
    voteableType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      localEntity: { ...this.entity }
    };
  },
  computed: {
    upVotes() {
      return this.localEntity.likes.length;
    },
    downVotes() {
      return this.localEntity.dislikes.length;
    }
  },
  methods: {
    getEntity(entity) {
      if (this.voteableType === 'Song') {
        callSongsShow(entity.id)
          .then((res) => {
            this.localEntity = res;
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (this.voteableType === 'Album') {
        callAlbumsShow(entity.artist_profile_id, entity.id)
          .then((res) => {
            this.localEntity = res;
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (this.voteableType === 'Playlist') {
        callPlaylistsShow(entity.id)
          .then((res) => {
            this.localEntity = res;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    likeEntity(entity) {
      callLikesCreate({
        user_id: this.$store.state.currentUserId,
        likeable_id: entity.id,
        likeable_type: this.voteableType
      })
        .then((response) => {
          this.getEntity(entity);
          this.$store.dispatch('userActivities/createUserActivity', {
            user_activity_type_name: `${this.voteableType} Like`,
            user_id: this.$store.state.currentUserId,
            activityable_type: this.voteableType,
            activityable_id: response.likeable.id
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    dislikeEntity(entity) {
      callDislikesCreate({
        user_id: this.$store.state.currentUserId,
        dislikeable_id: entity.id,
        dislikeable_type: this.voteableType
      })
        .then((response) => {
          this.getEntity(entity);
          this.$store.dispatch('userActivities/createUserActivity', {
            user_activity_type_name: `${this.voteableType} Dislike`,
            user_id: this.$store.state.currentUserId,
            activityable_type: this.voteableType,
            activityable_id: response.dislikeable.id
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";


.vote-buttons-container {
  align-items: center;
}

.vote-buttons-container__up-button {
  background-color: $atmo-blue--medium;
  padding: .4rem 1rem .4rem 1rem;
  border-radius: 20px;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4rem;
  cursor: pointer;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .7);

  span {
    margin-right: 0.2rem;
    font-weight: 600;
  }

  img {
    height: .8rem;
  }
}

.vote-buttons-container__down-button {
  background-color: $atmo-pink--medium;
  padding: .4rem 1rem .4rem 1rem;
  border-radius: 20px;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4rem;
  cursor: pointer;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .7);

  span {
    margin-right: 0.2rem;
    font-weight: 600;
  }

  img {
    height: .8rem;
  }
}
</style>