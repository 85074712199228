<template>
  <div>
    <div v-if="songsLoading">LOADING</div>
    <atmo-music-songs-grid :songs="songs" />
  </div>
</template>

<script>
// import AtmoMusicItems from '@/components/atmo-music-items';
import PaginationMixin from '@/mixins/pagination';
import atmoMusicSongsGrid from '@/components/music/atmo-music-songs-grid.vue';
import {
  callSongsExplore
} from '@/helpers/axiosCalls';

export default {
  components: { atmoMusicSongsGrid },
  // components: { AtmoMusicItems },
  mixins: [PaginationMixin],
  props: { showNewReleases: { type: Boolean, default: false } },
  data() {
    return {
      songs: null,
      songsLoading: true,
    };
  },
  computed: {
    fullCollection() {
      if (this.showNewReleases) {
        return this.songs.filter(song => song.isNew);
      } else {
        return this.songs;
      }
    }
  },
  created() {
    this.getSongs();
  },
  methods: {
    getSongs() {
      callSongsExplore()
        .then((response) => {
          this.songs = response;
          console.log("this.songs: ", this.songs);
          this.songsLoading = false;
        }).catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>