var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"userMediaModal",attrs:{"id":"user-media-modal","title":"Ami Options","modal-class":"atmo-modal","size":"lg","hide-header":"","hide-footer":"","centered":""},on:{"hide":_vm.hideModal,"shown":_vm.onModalShown},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('header',{staticClass:"user-media-modal__header"},[_c('div',{staticClass:"header__hide-ad-wrap",on:{"click":_vm.hideModal}},[_c('img',{staticClass:"hide-ad-wrap__hide-ad",attrs:{"src":require("@/assets/images/icons/close_popup.png"),"alt":"close","title":"Hide Ad"}})])]),_c('div',{staticClass:"user-media-modal__media-container"},[(_vm.$store.state.videoPlayer.userMediaModalObj.type == 'Video')?_c('div',{staticClass:"media-container__image-wrap"},[(_vm.computedUrl)?_c('video-player',{attrs:{"options":{
                autoplay: false,
                controls: true,
                height: 295,
                width: 420,
                sources: [
                    {
                        src: _vm.computedUrl,
                        type: 'video/mp4'
                    }
                ]
            }}}):_vm._e(),_c('div',{staticClass:"media-container__theatre-icon-wrap",on:{"click":function($event){return _vm.playTheatreVideo(_vm.$store.state.videoPlayer.userMediaModalObj)}}},[_c('img',{attrs:{"src":require('@/assets/images/icons/ops/watch_video.png')}})])],1):_c('div',{staticClass:"media-container__image-wrap",style:({ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${_vm.$store.state.videoPlayer.userMediaModalObj.url})` })}),_c('div',{staticClass:"media-container__comments-wrap"},[_c('photo-comments')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }