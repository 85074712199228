var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-profiles__photos-grid"},_vm._l((_vm.photoArray),function(photo,index){return _c('a',{key:index,staticClass:"profiles-grid__profile-card",style:({ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + `${photo.image.url}` + ')' }),on:{"click":function($event){return _vm.openUserMediaModal(
    {
      type: 'Image',
      caption: '',
      url: photo.image.url,
      id: photo.id
    })}}})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }