<template>
  <div class="contest-submission" :class="{ 'contest-submission--standalone': isStandalone }">
    <!-- Use the modal component with the ref -->
    <choose-winners-modal :submission="submission" v-model="localModalVisible" />
    <div>
      <div class="contest-submission__main">
        <div class="contest-submission__user-winner-container">
          <div class="user-winner-container__user-container">
            <img class="atmo-avatar atmo-avatar--small"
              :style="{ 'background-image': `url(${getFeaturedImage(submission.user.images, 'full')})` }">
            <div class="contest-submission__name">
              {{ user.name }}
            </div>
          </div>
          <div 
            class="user-winner-container__winner-container" 
            v-if="submission.contest.user_id == $store.state.currentUserId"
            @click="openChooseWinnerModal()"
            >
            Choose Winner
          </div>
        </div>
        <div class="contest-submission__description" :title="submission.text">
          {{ submission.text }}
        </div>
        <div>
          <div class="contest-submission__media" v-if="!isStandalone && isMediaAttached()">
            (Media attached)
          </div>
          <div v-if="isStandalone">
            <div>
              <div class="contest-submission__submission-content">
                <div v-if="submission.images.length > 0" class="submission-content__submission-pics-container">
                  <img v-for="(submissionImage, index) in submission.images" :key="index"
                    class="submission-pics-container__submission-pic" :src="submissionImage.image.url" alt="Post pic"
                    @click="openUserMediaModal({
                      type: 'Image',
                      caption: submission.content,
                      url: submissionImage.image.url,
                      id: submissionImage.id
                    })" />
                </div>
                <div v-if="submission.videos.length > 0" class="submission-content__submission-pics-container">
                  <img v-for="(submissionVideo, index) in submission.videos" :key="index"
                    class="submission-pics-container__submission-pic" :src="submissionVideo.preview_image.url"
                    alt="Video preview" @click="openUserMediaModal({
                      type: 'Video',
                      caption: submission.content,
                      url: submissionVideo.video.url,
                      id: submissionVideo.id
                    })" />
                </div>
              </div>
              <div v-if="submission.song_references.length > 0">
                <div class="contest-submission__submission-footer"
                  v-for="(songReference, index) in submission.song_references" :key="index">
                  <div class="contest-submission__song-actions-popover-container">
                    <song-actions-popover :song="song" placement="left" :actions="[
                        'add-to-library',
                        'add-to-queue',
                        'add-to-playlist'
                      ]" />
                  </div>
                  <span class="contest-submission__song-link">
                    {{ songReference.song.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contest-submission__bottom">
          <!-- <atmo-badge class="contest-submission__song-badge" color="medium" label="Hello song.mp3" icon="play.png" /> -->
          <button class="atmo-button contest-submission__comment-badge" @click="onClick(submission)"
            v-if="!isStandalone">
            <img class="atmo-button__icon contest-submission__comment-badge-icon"
              src="@/assets/images/icons/comment--purple.png" alt="Comment">
            {{ submission.comments.length }}
          </button>
          <contest-submission-votes :submissionId="submission.id" style="margin-left: auto" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from '@/models/user';
// import AtmoBadge from '@/components/atmo-badge';
import ContestSubmissionVotes from './contest-submission-votes';
import { getFeaturedImage } from '@/helpers/utilityFunctions';
import SongActionsPopover from '@/components/song-actions-popover';
import ChooseWinnersModal from '@/modals/contests/choose-winners';

export default {
  components: {
    // AtmoBadge,
    ContestSubmissionVotes,
    SongActionsPopover,
    ChooseWinnersModal
  },

  props: {
    submission: {
      type: Object,
      required: true
    },
    isStandalone: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    user() {
      return new User(this.submission.user);
    }
  },

  data: function () {
    return {
      localModalVisible: false
    }
  },

  methods: {
    openChooseWinnerModal(){
      this.localModalVisible = true;
    },
    isMediaAttached() {
      if (
        this.submission.images.length > 0 || 
        this.submission.videos.length > 0 || 
        this.submission.song_references.length > 0
      ) {
        return true;
      }

      return false;
    },
    openUserMediaModal(userMediaObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/setUserMediaModalObj", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    onClick() {
      if (this.isStandalone) {
        return;
      }
      this.$router.push({
        name: 'contests.show.submissions.show',
        params: { submissionId: Number(this.submission.id) }
      });
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.contest-submission {
  // display: flex;
  padding: 9px;
  margin: 0 -9px;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($atmo-purple--light, 0.2);
  }

  &:hover {
    background-color: rgba($atmo-pink--medium, 0.2);
  }

  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    overflow: hidden;
  }

    .contest-submission__user-winner-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      &:hover {
        .user-winner-container__winner-container {
          font-weight: bold;
          display: flex;
        }
      }
    }
  
    .user-winner-container__winner-container {
      font-weight: bold;
      cursor: pointer;
      display: none;
    }

  .user-winner-container__user-container {
    display: flex;
    align-items: center;
    gap: .2rem;
  }

  .atmo-avatar {
    box-shadow: none;
  }

  &__name {
    font-size: .8rem;
    font-weight: 500;
  }

  &__description {
    font-size: .8rem;
    margin: 5px 0px 1rem 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__media {
    font-size: .8rem;
    font-weight: bold;
    font-style: italic;
    margin: 0px 0px 10px 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__bottom {
    display: flex;
    font-size: 0.9em;
  }

  &__comment-badge {
    padding: 3px 5px;
    font-size: 0.9em;
    margin-left: 4px;
    border-radius: 5px;

    &:hover {
      background-color: $atmo-purple--light;
    }
  }

  &__comment-badge-icon {
    height: 12px;
    margin-right: 4px;
  }

  .atmo-badge {
    padding: 0px 7px;
    font-size: .7rem;
  }

  .atmo-badge__icon {
    height: 15px;
  }

  // modifications for standalone version (contests_submissions show route)
  &--standalone {
    align-items: center;
    font-size: 1.1em;
    cursor: inherit;
    border-radius: 5px;
    overflow: visible;

    &,
    &:hover {
      background-color: rgba($atmo-purple--dark, 0.6);
    }

    .contest-submission__main {
      overflow: visible;
    }

    // .contest-submission__bottom {
    //   margin-bottom: -20px;
    // }

    .contest-submission__song-badge {
      font-size: 1em;
      padding: 5px 12px;
    }
  }

  .submission-content__submission-pics-container {
    display: flex;
    gap: .5rem;
    margin-bottom: 1rem;
  }

  .submission-pics-container__submission-pic {
    height: 4rem;
    width: 4rem;
    object-fit: cover;
    border-radius: 2px;
    cursor: pointer;
  }

  .contest-submission__submission-footer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 3px;
    align-items: center;

    .atmo-button {
      margin-right: 5px;
    }
  }

  .contest-submission__song-actions-popover-container {
    height: 20px;
    width: 20px;
  }

  .contest-submission__song-link {
    color: white;
    font-size: .8rem;
    font-weight: 500;
    margin-left: .2rem;
  }

  .song-actions-button {
    .contest-submission__button-icon--active {
      display: none;
    }

    &:active,
    &:focus {
      outline: none;
      background-color: $atmo-purple--dark;

      :global(.atmo-button__icon) {
        display: none;
      }

      .contest-submission__button-icon--active {
        display: flex;
        margin-left: 0;
      }
    }
  }
}
</style>
