<template>
  <label class="atmo-radio">
    <span
      class="atmo-radio__checkmark"
      :class="checked && 'checked'"
      @click="click"
    />
    <span class="atmo-checkbox__label">{{ label }}</span>
  </label>
</template>
<script>
  export default {
    props: {
      label: {
        type: String,
        required: true,
      },
      click: {
        type: Function,
        required: true,
      },
      checked: {
        type: Boolean,
        required: true,
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-radio {
  font-weight: 100;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: space-between;
  &:not(:first-child) {
    margin-left: 1.5rem;
  }
  &__label {
    text-transform: uppercase;
    padding-left: 10px;
  }
  &__checkmark {
    cursor: pointer;
    height: 20px;
    border-radius: 100%;
    width: 20px;
    border: 3px solid $atmo-purple--dark;
    background-color: $atmo-purple--dark;
  }
  &__checkmark.checked {
    background-color: $atmo-pink--medium;
  }
}

</style>
