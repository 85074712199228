<template>
  <div class="suggested-explore-container__suggested-container">
    <div class="suggested-container__title-container">Suggested Playlists</div>
    <div class="suggested-container__cards-container">
      <div v-if="suggestedPlaylistsLoading">LOADING</div>
      <router-link v-for="(playlist, index) in suggestedPlaylists" :key="index" class="cards-container__card" :to="{ name: 'playlists.show', params: { playlistId: playlist.id } }">
        <div class="card__image-wrap" :style="backgroundImageStyle(playlist)"></div>
        <div class="card__content-wrap">
          <p class="content-wrap__playlist-title">{{ playlist.name }}</p>
          <p class="content-wrap__artists">
            <router-link class="artist-blue" :to="{ name: 'artist_profiles.show', params: { artistProfileId: playlist.song_references[0].song.primary_artist.id } }">
              {{ playlist.song_references[0].song.primary_artist.user.name }}
            </router-link>
            and more
          </p>
        </div>
      </router-link>
      <!-- 
              <carousel :autoplay="true" :perPage="3" :loop="true">
                <slide v-for="index in 15" :key="index" class="songs-content-container__song-item">
                  <router-link class="cards-container__card" :to="{ name: 'coming_soon' }">
                    <div class="card__image-wrap" v-bind:style="{'background-image': 'url(' + require('@/assets/images/artists/deadmau5.png') + ')'}"></div>
                    <div class="card__content-wrap">
                      <p class="content-wrap__playlist-title">Playlist Title</p>
                      <p class="content-wrap__artists">Ed Sheeran and more</p>
                    </div>
                  </router-link>
                </slide>
              </carousel> -->
    </div>
  </div>
</template>

<script>
import { getFeaturedImage } from "@/helpers/utilityFunctions";

export default {
  props: {
    suggestedPlaylists: {
      type: Array,
      required: true,
    },
    suggestedPlaylistsLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    backgroundImageStyle(playlist) {
      // Return a fallback style if playlist is undefined or does not contain song references
      if (!playlist || !playlist.song_references || playlist.song_references.length === 0) {
        return {
          backgroundImage: this.generateGradientBackground(),
        };
      }

      return {
        backgroundImage: this.generateGradientBackground() + ", " + this.getPlaylistImage(playlist),
      };
    },
    generateGradientBackground() {
      return "linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)";
    },
    getPlaylistImage(playlist) {
      const randomSongReference = this.pickRandomSongReference(playlist);
      if (!randomSongReference || !randomSongReference.song.primary_artist.user.images) {
        return "url(/path/to/default-image.png)"; // Fallback image if the song reference is invalid
      }
      const imageUrl = this.getFeaturedImage(randomSongReference.song.primary_artist.user.images, "full");
      return `url(${imageUrl})`;
    },
    pickRandomSongReference(playlist) {
      const { song_references } = playlist;
      const randomIndex = Math.floor(Math.random() * song_references.length);
      return song_references[randomIndex];
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.suggested-explore-container__suggested-container {
  .suggested-container__title-container {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .suggested-container__cards-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    height: 13rem;
  }

  .card__image-wrap {
    height: 170px;
    background-color: $atmo-purple--dark;
    border-radius: 5px;
    margin-bottom: 0.7rem;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .content-wrap__playlist-title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.2rem;
    color: white;
  }

  .content-wrap__artists {
    color: rgba(255, 255, 255, 0.7);
  }
}
</style>
