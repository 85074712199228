<template>
  <div>
    <p class="heading">
      Your Subscription
    </p>
    <p>Name: {{ atmoSubscription.name }}</p>
    <!-- <p>Currency: {{ stripeSubscription.user.subscription.currency }}</p> -->
    <!-- <p>Interval: {{ stripeSubscription.user.subscription.interval }}</p> -->
    <p ng-if="user.subscription.interval == 'month'">
      Amount: user.subscription.amount / month
    </p>
    <p ng-if="user.subscription.interval !== 'month'">
      Amount: user.subscription.amount / year
    </p>
    <p>Trial Days: {{ atmoSubscription.trial_period_days }}</p>
    <p>Subscription ID: {{ stripeSubscription.id }}</p>
    <p>Subscription Name: {{ atmoSubscription.name }}</p>
    <p>Subscription Created At: {{ stripeSubscription.created * 1000 }}</p>
    <p>Subscription Period Start: {{ stripeSubscription.current_period_start * 1000 }}</p>
    <p>Subscription Period End: {{ stripeSubscription.current_period_end * 1000 }}</p>
    <p>Billing Cycle Anchor: {{ stripeSubscription.billing_cycle_anchor * 1000 }}</p>
    <p v-if="stripeSubscription.canceled_at == null">
      Subscription Cancelled: No
    </p>
    <p v-if="stripeSubscription.canceled_at !== null">
      Subscription Cancelled: Yes - {{ stripeSubscription.canceled_at }}
    </p>
    <p>Set To Cancel: {{ stripeSubscription.cancel_at_period_end }}</p>
    <p>Status: {{ stripeSubscription.status }}</p>
    <p>Customer Id: {{ stripeSubscription.customer }}</p>
    <p v-if="stripeSubscription.discount == null">
      Discount Applied: None
    </p>
    <p v-if="stripeSubscription.discount !== null">
      Discount Applied: {{ stripeSubscription.discount }}
    </p>
    <router-link
      :to="{ name: 'subscriptions.user_invoices.index', params: { userProfileId: user.profile_id, subscriptionId: atmoSubscription.id } }">
      View Invoices
    </router-link>
    <button v-if="stripeSubscription.status !== 'canceled'" @click="cancelStripeSubscription(stripeSubscription.id);">
      Cancel Subscription
    </button>
    <button v-if="stripeSubscription.status !== 'canceled' && stripeSubscription.cancel_at_period_end == false"
      @click="setStripeSubscriptionToCancel(stripeSubscription.id);">
      Set Subscription to Cancel
    </button>
    <button v-if="stripeSubscription.status !== 'canceled' && stripeSubscription.cancel_at_period_end == true"
      @click="reverseStripeSubscriptionCancellation(stripeSubscription.id);">
      Reverse Cancellation
    </button>
    <router-link v-if="stripeSubscription.status == 'canceled'"
      :to="{ name: 'subscriptions.user_subscriptions.new', params: { userProfileId: userProfile.id } }">
      Resubscribe to Plus!
    </router-link>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <router-link :to="{ name: 'subscriptions.cards.index', params: { userProfileId: user.profile_id } }">
      View Card Information
    </router-link>
  </div>
</template>

<script>
import storeTest from '@/store'
import { 
  callUserProfilesShow, 
  callSubscriptionsShow, 
  callSubscriptionsUpdate, 
  callStripeSubscriptionsDelete, 
  callStripeSubscriptionsPost, 
  callStripeSubscriptionsGet, 
  callUsersShow 
} from '@/helpers/axiosCalls';

export default {
  data: function () {
    return {
      user: {},
      userProfile: {},
      atmoSubscription: {},
      stripeSubscription: {},
      stripeSubscriptionId: {},
    }
  },
  created() {
    callUsersShow(storeTest.state.currentUserId)
      .then(response1 => {
        this.user = response1;
        callUserProfilesShow(storeTest.state.currentUserId).then(response2 => (this.userProfile = response2));
        callSubscriptionsShow(storeTest.state.currentUserId, this.$route.params.subscriptionId).then(response3 => {
          this.atmoSubscription = response3;
          this.stripeSubscriptionId = this.atmoSubscription.stripe_subscription_id;
          callStripeSubscriptionsGet(this.stripeSubscriptionId)
            .then(response4 => {
              this.stripeSubscription = response4;
            });
        });
      });
  },
  methods: {

    updateAtmoSubStatusToCancelled() {
      callSubscriptionsUpdate(storeTest.state.currentUserId, this.$route.params.subscriptionId, {
        status: "canceled"
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updateAtmoSubStatusToSetToCancel() {
      callSubscriptionsUpdate(storeTest.state.currentUserId, this.$route.params.subscriptionId, {
        status: "set to cancel"
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    updateAtmoSubStatusToActive() {
      callSubscriptionsUpdate(storeTest.state.currentUserId, this.$route.params.subscriptionId, {
        status: "active"
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    cancelStripeSubscription(subId) {
      callStripeSubscriptionsDelete(subId)
        .then((response) => {
          console.log(response);
          this.updateAtmoSubStatusToCancelled();
          alert('Subscription Canceled', 'atmo');
          this.$router.push({ name: 'subscriptions.index' });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setStripeSubscriptionToCancel(subId) {
      callStripeSubscriptionsDelete(subId, {
        'at_period_end': true
      })
        .then((response) => {
          console.log(response);
          this.updateAtmoSubStatusToSetToCancel();
          alert('Subscription Set To Cancel', 'atmo');
          this.$router.push({ name: 'subscriptions.index' });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    reverseStripeSubscriptionCancellation(subId) {
      callStripeSubscriptionsPost(subId, {
        'cancel_at_period_end': false
      })
        .then((response) => {
          console.log(response);
          this.updateAtmoSubStatusToActive();
          alert('Subscription Cancellation Reversed', 'atmo');
          this.$router.push({ name: 'subscriptions.index' });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>
