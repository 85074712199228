import Axios from 'axios';

export function callAtmoRadioStationsSearch(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/atmo_radio_stations/search`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAtmoRadioStationsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/atmo_radio_stations`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAtmoRadioStationsShow(userId, stationId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/atmo_radio_stations/${stationId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAtmoRadioStationsCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/atmo_radio_stations`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
