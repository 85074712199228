<template>
  <div class="ops-edit-page">
    <div class="ops-edit-page__wrap">
      <div>
        <div align-self="center">
          <h2 class="atmo-page-header mb-5 text-center">
            Edit Campaign
          </h2>
        </div>
      </div>
      <div>
        <div cols="3" class="ops-edit-page__navigate-back">
          <router-link
            :to="{ name: 'users.ops_pages', params: { userId: this.$store.state.currentUserId } }">
            <img src="@/assets/images/icons/back.png" class="ops-edit-page__go-back-image">
            <p>Back to campaigns</p>
          </router-link>
        </div>
      </div>
      <div class="atmo-ops-pages-campaign-form">
        <div class="campaign-form-wrap">
          <!-- campaigns image and video -->
          <div class="picture-video-container">
            <div>
              <div class="label">
                <label>Campaign Picture</label>
              </div>
            </div>
            <atmo-media-uploader :existingMediaUrl="getFeaturedImage(campaign.images, 'full')"
              componentId="image-upload" mediaType="image" @selected-image-upload="handleUploadedImage" height="11rem" width="13rem"/>
            <div>
              <div class="upload-video-label">
                <label class="video-label">Campaign video</label>
              </div>
              <atmo-media-uploader :existingMediaUrl="featuredVideo.preview_image.url" componentId="video-upload"
                mediaType="video" @selected-video-upload="handleUploadedVideo" height="11rem" width="13rem"/>
            </div>
          </div>
          <!-- goal, title and description -->
          <div class="goal-title-description-container">
            <form>
              <!-- campaign goal -->
              <div>
                <div class="label">
                  <label> Campaign goal ($US) </label>
                </div>
              </div>
              <div>
                <div>
                  <input v-model="campaignToEdit.goal" class="form-control" type="text" placeholder="$100000"
                    name="campaign-goal" />
                </div>
              </div>
              <!-- campaign title -->
              <div>
                <div class="label mt-3">
                  <label>Campaign title</label>
                </div>
              </div>
              <div>
                <div>
                  <input v-model="campaignToEdit.title" class="form-control" type="text"
                    placeholder="Design our next t-shirt and win free tickets" name="campaign-title" />
                </div>
              </div>
              <div>
                <div class="label mt-3">
                  <label>Campaign duration (From - To)</label>
                </div>
              </div>
              <div>
                <div class="datepicker-trigger atmo-datepicker">
                  <input id="datepicker-trigger" class="main-wrap__datepicker-trigger" type="text"
                    placeholder="Select dates"
                    :aria-invalid="submitAttempted && (!formSelectedStartDate || !formSelectedEndDate)"
                    :value="formSelectedDates" />
                  <airbnb-style-datepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'"
                    :fullscreen-mobile="true" :date-one="formSelectedStartDate" :date-two="formSelectedEndDate"
                    :show-shortcuts-menu-trigger="false" data-cy="campaign-duration"
                    @date-one-selected="val => { formSelectedStartDate = val }"
                    @date-two-selected="val => { formSelectedEndDate = val }" />
                </div>
              </div>
              <!-- campaign description -->
              <div>
                <div class="label mt-3">
                  <label>Description</label>
                </div>
              </div>
              <div>
                <div>
                  <textarea v-model="campaignToEdit.description" class="form-control" type="text"
                    placeholder="Enter your description" name="campaign-description"></textarea>
                </div>
              </div>
            </form>
          </div>
          <!-- donation options -->
          <div class="donation-container">
            <form autocomplete="off" @submit.prevent="addDonationOption">
              <div>
                <div class="label">
                  <label>Donation amounts</label>
                </div>
              </div>
              <div class="new-donation-field-container">
                <div class="amount-container">
                  <input v-model="donateAmount" class="form-control text-center" type="text" placeholder="$"
                    data-cy="donation-amount" />
                </div>
                <div class="description-container">
                  <input v-model="donateLabel" class="form-control" type="text" placeholder="Enter prize description"
                    data-cy="donation-description" />
                </div>
                <div class="add-amount-container">
                  <button class="donate-action-create" data-cy="create-donation" type="submit">
                    <img src="@/assets/images/icons/plus--purple.png">
                  </button>
                </div>
              </div>
              <perfect-scrollbar>
                <div class="new-donation-wrap" v-for="(donateOption, index) in donateOptions"
                  :key="donateOption.amount">
                  <div class="donation-amount">
                    <div class="amount-wrap" v-if="!editMode[index]">
                      {{ returnCurrencyFormat(donateOption.amount) }}
                    </div>
                    <input class="form-control" v-else v-model="donateOptions[index].editedAmount"
                      @keydown.enter.prevent="saveEdit(index)" @blur="saveEdit(index)">
                  </div>
                  <div class="donation-description">
                    <div v-if="!editMode[index]">
                      {{ donateOption.label }}
                    </div>
                    <input class="form-control" v-else v-model="donateOptions[index].editedLabel"
                      @keydown.enter.prevent="saveEdit(index)" @blur="saveEdit(index)">
                  </div>
                  <div class="donation-actions">
                    <div class="donate-action-edit" @click="toggleEditMode(index)">
                      <img src="@/assets/images/icons/edit--pencil.png">
                    </div>
                    <div class="donate-action-delete" data-cy="delete-donation" @click="removeDonationOption(index)">
                      <img src="@/assets/images/icons/delete.png">
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
            </form>
          </div>
        </div>
        <div class="ops-edit-page__edit-campaign-container">
          <div>
            <button class="submit-button" data-cy="edit-campaign" @click="editOpsPage()">
              <span>
                Save changes
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format';
// import AtmoUploadCard from '@/components/atmo-upload-card';
// import AtmoOpsCampaignForm from "@/components/atmo-ops-campaign-form.vue";
import AtmoMediaUploader from '@/components/atmo-media-uploader';
import {
  callOpsPagesShow,
  callOpsPagesUpdate,
  callOpsPageDonationsCreate,
  callOpsPageDonationsUpdate,
  callOpsPageDonationsDelete,
  callImagesCreate,
  callVideosCreate,
  callPreviewImageCreate
} from '@/helpers/axiosCalls';
import {
  deepCopy,
  getFeaturedImage,
  getFeaturedVideo
} from '@/helpers/utilityFunctions';

export default {
  components: {
    AtmoMediaUploader
    // AtmoUploadCard
    // AtmoOpsCampaignForm
  },
  data: function () {
    return {
      goal: null,
      title: null,
      description: null,
      donateAmount: null,
      donateLabel: null,
      donateOptions: [],
      editMode: [], // Array to keep track of edit mode for each item
      editedLabel: '', // Store the edited label temporarily
      dateFormat: 'MMM D, YYYY',
      formSelectedStartDate: null,
      formSelectedEndDate: null,
      submitAttempted: false,
      formSelectedImageFile: null,
      formSelectedVideoFile: null,
      campaign: {},
      campaignToEdit: {},
    };
  },
  computed: {
    featuredVideo() {
      return this.getFeaturedVideo(this.campaign.videos);
    },
    formSelectedDates() {
      return this.formatDates(
        this.formSelectedStartDate,
        this.formSelectedEndDate
      );
    }
  },
  created() {
    this.getOpsPage();
  },
  methods: {
    handleUploadedVideo(value) {
      this.formSelectedVideoFile = value;
    },
    handleUploadedImage(value) {
      this.formSelectedImageFile = value;
    },
    getFeaturedImage(images, type) {
      return getFeaturedImage(images, type);
    },
    getFeaturedVideo(videos) {
      return getFeaturedVideo(videos);
    },
    populateDatepickerWithCampaignDates(start_date, end_date) {
      // Format the dates as needed
      const formattedStartDate = new Date(start_date).toISOString().split('T')[0];
      const formattedEndDate = new Date(end_date).toISOString().split('T')[0];

      // Update the formSelectedStartDate and formSelectedEndDate
      this.formSelectedStartDate = formattedStartDate;
      this.formSelectedEndDate = formattedEndDate;
    },
    getOpsPage() {
      callOpsPagesShow(this.$route.params.opsPageId)
        .then(response => {
          this.campaign = deepCopy(response);
          this.populateDatepickerWithCampaignDates(this.campaign.start_date, this.campaign.end_date);

          this.campaignToEdit = {
            goal: this.campaign?.goal,
            title: this.campaign?.campaign_name,
            description: this.campaign?.campaign_description,
            start_date: this.campaign?.start_date,
            end_date: this.campaign?.end_date,
            images: this.campaign?.images,
            videos: this.campaign?.videos,
          };

          this.donateOptions = this.campaign?.ops_page_donations?.map(donation => {
            return { label: donation.description, amount: donation.amount }
          });

          console.log("CAMPAIGN TO EDIT", this.campaignToEdit);
        })
        .catch(error => {
          console.error(error);
        });
    },
    formatDates(startDate, endDate) {
      let formattedDates = ''
      if (startDate) {
        formattedDates = format(startDate, this.dateFormat)
      }
      if (endDate) {
        formattedDates += ' - ' + format(endDate, this.dateFormat)
      }

      return formattedDates
    },
    returnCurrencyFormat(number) {
      // Create number formatter (Javascript - Internationalization API)
      let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        //These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number);
    },
    addDonationOption() {
      this.donateOptions = this.donateOptions.concat({
        amount: this.donateAmount,
        label: this.donateLabel
      });

      this.donateAmount = null;
      this.donateLabel = null;
    },
    toggleEditMode(index) {
      console.log("OPS PAGE DONATIONS", this.donateOptions[index]);
      // Toggle edit mode for the clicked item
      this.$set(this.editMode, index, !this.editMode[index]);
      // If entering edit mode, set the edited amount and label to the current values
      if (this.editMode[index]) {
        this.$set(this.donateOptions[index], 'editedAmount', this.donateOptions[index].amount);
        this.$set(this.donateOptions[index], 'editedLabel', this.donateOptions[index].label);
      }
    },
    saveEdit(index) {
      // Save the edited amount and label and exit edit mode
      this.$set(this.donateOptions[index], 'amount', parseFloat(this.donateOptions[index].editedAmount));
      this.$set(this.donateOptions[index], 'label', this.donateOptions[index].editedLabel);
      this.$set(this.editMode, index, false);
      // Optionally, you can perform additional actions here like updating your backend
    },
    removeDonationOption(index) {
      this.donateOptions.splice(index, 1);
    },
    async synchronizeDonations() {
      const donationsToDelete = this.campaign?.ops_page_donations.filter(oldDonation => {
        return !this.donateOptions.some(newDonation => newDonation.amount === oldDonation.amount && newDonation.label === oldDonation.description);
      });

      const donationsToUpdate = this.donateOptions.filter(newDonation => {
        return this.campaign?.ops_page_donations.some(oldDonation => newDonation.amount === oldDonation.amount && newDonation.label === oldDonation.description);
      });

      const donationsToCreate = this.donateOptions.filter(newDonation => {
        return !this.campaign?.ops_page_donations.some(oldDonation => newDonation.amount === oldDonation.amount && newDonation.label === oldDonation.description);
      });

      // Delete donations that were removed
      const deletePromises = donationsToDelete.map(donation => {
        return callOpsPageDonationsDelete(this.campaign.id, donation.id);
      });

      await Promise.all(deletePromises);

      // Update donations that were modified
      const updatePromises = donationsToUpdate.map(newDonation => {
        const oldDonation = this.campaign?.ops_page_donations.find(oldDonation => newDonation.amount === oldDonation.amount && newDonation.label === oldDonation.description);
        return callOpsPageDonationsUpdate(this.campaign.id, oldDonation.id, {
          description: newDonation.label,
          amount: newDonation.amount,
          ops_page_id: this.campaign.id
        });
      });

      await Promise.all(updatePromises);

      // Create new donations
      const createPromises = donationsToCreate.map(newDonation => {
        return callOpsPageDonationsCreate(this.campaign.id, {
          description: newDonation.label,
          amount: newDonation.amount,
          ops_page_id: this.campaign.id
        });
      });

      await Promise.all(createPromises);
    },
    async editOpsPage() {
      this.$notify({ group: 'vue-app', title: 'Saving campaign...' });

      try {
          // Create the new campaign
          await callOpsPagesUpdate(this.campaign.id, {
            goal: this.campaignToEdit.goal,
            campaign_name: this.campaignToEdit.title,
            campaign_description: this.campaignToEdit.description,
            user_id: this.$store.state.currentUserId,
            start_date: this.formSelectedStartDate,
            end_date: this.formSelectedEndDate
          });

          if (this.formSelectedImageFile) {
            // Upload campaign images
            const imageFormData = new FormData();
            imageFormData.append('image', this.formSelectedImageFile);
            imageFormData.append('imageable_id', this.campaign.id);
            imageFormData.append('imageable_type', "OpsPage");
            imageFormData.append('is_featured_image', true);
            imageFormData.append('user_id', this.$store.state.currentUserId);
            imageFormData.append('name', `${this.campaignToEdit.title} Image`);

            await callImagesCreate(imageFormData);
          }


          if (this.formSelectedVideoFile) {
            // Upload campaign videos
            const videoFormData = new FormData();
            videoFormData.append('video', this.formSelectedVideoFile);
            videoFormData.append('videoable_id', this.campaign.id);
            videoFormData.append('videoable_type', "OpsPage");
            videoFormData.append('user_id', this.$store.state.currentUserId);
            videoFormData.append('name', `${this.campaignToEdit.title} Video`);
            videoFormData.append('is_featured_video', true);

            const newVideo = await callVideosCreate(videoFormData);

            // Create a preview image for the video
            await callPreviewImageCreate({
              video_id: newVideo.id,
            });
          }

        await this.synchronizeDonations();

        console.log("CAMPAIGN DONATION OPTIONS", this.campaign?.ops_page_donations);
        console.log("DONATE OPTIONS", this.donateOptions);

        this.$notify({ group: 'vue-app', title: 'Campaign saved successfully' });
        this.$router.push({ name: 'ops_pages.show', params: { opsPageId: this.campaign.id } });
      } catch (error) {
        console.error(error);
        this.$notify({ group: 'vue-app', title: 'Failed to save campaign', type: 'error' });
      }
    }

  }
};
</script>

<style lang="scss" scoped>
.ops-edit-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__wrap {
    max-width: 1000px;
  }

  &__navigate-back {
    margin-bottom: 1rem;

    img {
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }

    p {
      display: inline;
      font-weight: bold;
      padding-left: 6px;
    }
  }

  &__edit-campaign-container {
    padding: 0.5rem 2.2rem;
    text-transform: none;
    margin-top: 2rem;
    float: right;
  }

  .atmo-ops-pages-campaign-form {
    .campaign-form-wrap {
      display: grid;
      grid-template-columns: auto 1fr 1fr;
      gap: 2rem;
    }

    .ps {
      max-height: 19.5rem;
    }

    label {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .form-control {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, .4);
      padding: .5rem .5rem .5rem .5rem;
      color: white;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, .4);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, .4);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, .4);
    }

    textarea {
      min-height: 7.4rem;
    }

    textarea,
    textarea::placeholder {
      font-family: 'Roboto', sans-serif;
      font-size: 1rem;
      color: rgba(255, 255, 255, .4);
    }

    .picture-video-container {
      border-radius: 5px;
    }


    .campaign-image-container {
      text-align: center;
      border: 2px dashed rgba(255, 255, 255, .4);
      border-radius: 2px;
      min-height: 15rem;
      min-width: 15rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        height: 5rem;
      }
    }

    .upload-video-label {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 1rem 0rem 1rem 0rem;

      .video-label {
        margin-bottom: 0rem;
      }

      img {
        height: 1rem;
      }
    }

    .goal-title-description-container {
      border-radius: 5px;
    }

    #datepicker-trigger {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, .4);
      padding: .5rem .5rem .5rem .5rem;
      color: white;
      text-transform: none;
    }

    .donation-container {
      border-radius: 5px;
    }

    .new-donation-field-container {
      display: flex;
      // justify-content: space-between;
      margin-bottom: 1rem;
    }

    .amount-container {
      max-width: 5rem;
      margin-right: .5rem;
    }

    .description-container {
      margin-right: .5rem;
    }

    .add-amount-container {
      width: auto;
    }

    .donate-action-create {
      background-color: white;
      border-radius: 5px;
      border: none;
      height: 2.3rem;
      width: 2.3rem;
      cursor: pointer;

      img {
        height: 1rem;
      }
    }

    .new-donation-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }

    .donation-actions {
      max-width: 5rem;
      display: flex;
      gap: .5rem;
    }

    .donation-amount {
      font-size: 1.2rem;
      font-weight: bold;
      /* background-color: $atmo-purple--medium-dark--faded; */
      min-width: 6rem;
      display: flex;
      height: 3rem;
      align-items: flex-start;
      margin-right: 1rem;

      .amount-wrap {
        background-color: rgba(96, 72, 117, 0.3);
        border-radius: 5px;
        padding: .5rem;
        min-width: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .donate-action-edit {
      /* background-color: $atmo-purple--medium-dark; */
      background-color: #604875;
      border-radius: 100%;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: .5rem;

      img {
        height: .6rem;
      }
    }

    .donate-action-delete {
      /* background-color: $atmo-invalid; */
      background-color: #dc3545;
      margin-left: 6px;
      border-radius: 100%;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        height: .8rem;
      }
    }

    .submit-button {
      color: white;
      background-color: transparent;
      background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
      border-radius: 5px;
      font-weight: 500;
      font-size: 1rem;
      border: none;
      padding: .6rem 1rem .6rem 1rem;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
</style>
