<template>
  <div class="atmo-steps">
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  $step-width: 150px;

  .atmo-steps {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding-top: 5px;

    &__step {
      display: flex;
      flex-direction: column;
      width: $step-width;
      align-items: center;
    }

    &__step-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      width: 18px;
      border-radius: 100%;
      background-color: $atmo-purple--light;
      border: 2px solid white;
      z-index: 1;
      position: relative;
    }

    &__step-checkmark {
      opacity: 0;
      font-size: 0.8em;
    }

    &__step-label {
      padding: 7px;
      text-transform: uppercase;
    }

    &__step--active, &__step--complete {
      .atmo-steps__step-circle {
        background-color: $atmo-pink--medium;
        transition: background-color 0.2s ease-in;
        transition-delay: 0.3s;
      }
    }

    &__step--complete {
      .atmo-steps__step-checkmark {
        opacity: 1;
        transition: opacity 0.5s ease-in;
        padding-left: 1px;
      }
    }

    &__divider {
      height: 5px;
      background-color: $atmo-purple--light;
      flex: 1;
      max-width: 350px;
      margin: 0 (-($step-width / 2));
      margin-top: 6px;
      position: relative;
    }

    &__divider-fill {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 0;
      transition : width 0.5s ease;
      background-color: $atmo-pink--medium;

      &--active {
        width: 100%;
      }
    }
  }
</style>
