<template>
  <div class="atmo-library__sidebar-section">
    <h3 class="atmo-library__section-header playlists">
      <span>Playlists</span>
      <button class="atmo-icon-button" @click="toggleNewPlaylistForm">
        <img v-if="newPlaylistFormActive" src="@/assets/images/icons/atmo_library/minus.png" alt="create playlist"
          class="atmo-library__section-header-icon minus">
        <img v-else src="@/assets/images/icons/add.png" alt="create playlist" class="atmo-library__section-header-icon">
      </button>
    </h3>
    <form v-if="newPlaylistFormActive" class="atmo-playlists__new-playlist-form"
      @submit.prevent="submitNewPlaylistForm">
      <input v-model="newPlaylistName" autofocus placeholder="New playlist name*" class="atmo-input">
      <button type="submit" class="atmo-button atmo-button--tertiary">
        Add
      </button>
    </form>
    <perfect-scrollbar class="atmo-playlists__list">
      <div v-for="(playlist, index) in playlists" :key="index" class="atmo-playlists__list-item"
        @click="$emit('on-playlist-click', playlist)">
        <span class="atmo-playlists__playlist-name">
          {{ playlist.name }}
        </span>
        <span class="atmo-playlists__playlist-icon-container"
          v-if="!playlist.is_public && playlist.playlist_collaborators && playlist.playlist_collaborators.length > 0">
          <img src="@/assets/images/icons/participants.png" alt="collaborators playlist"
            class="atmo-playlists__playlist-icon collaborators" />
        </span>
        <span class="atmo-playlists__playlist-icon-container" v-if="playlist.is_public">
          <img src="@/assets/images/icons/atmo_library/public.png" alt="public playlist"
            class="atmo-playlists__playlist-icon public" />
        </span>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { callPlaylistsCreate } from '@/helpers/axiosCalls';

export default {
  props: { playlists: { type: Array, required: true } },

  data() {
    return {
      newPlaylistFormActive: false,
      newPlaylistName: ''
    }
  },

  methods: {
    toggleNewPlaylistForm() {
      this.newPlaylistFormActive = !this.newPlaylistFormActive;
    },
    submitNewPlaylistForm() {
      const newPlaylist = {
        name: this.newPlaylistName,
        user_id: this.$store.state.currentUserId
      }
      callPlaylistsCreate(this.$store.state.currentUserId, newPlaylist)
        .then((newPlaylistResponse) => {
          this.playlists.push(newPlaylistResponse);
          this.newPlaylistName = '';

          this.$store.dispatch('userActivities/createUserActivity',
            {
              user_activity_type_name: "Playlist Create",
              user_id: this.$store.state.currentUserId,
              activityable_type: "Playlist",
              activityable_id: newPlaylistResponse.id
            }
          );
        }).catch((err) => {
          // TODO: Use vue notifications
          console.error(err);
        })
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-library__section-header.playlists {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.atmo-playlists {
  &__list {
    list-style-type: none;
    height: 35vh;
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid rgba($atmo-purple--light, 0.3);
    }
  }

  &__playlist-name {
    min-width: 9rem;
  }

  &__playlist-icon-container {
    height: .8rem;
  }

  &__playlist-icon.collaborators {
    height: 9px;
  }

  &__playlist-icon.public {
    height: .8rem;
  }

  &__new-playlist-form {
    display: flex;
    margin-top: .2rem;
    margin-bottom: .5rem;

    .atmo-input {
      width: 100%;
      border-radius: 4px;
    }

    button {
      font-size: 1em;
      padding: 0 10px;
      margin-right: -10px;
    }
  }
}
</style>
