<template>
  <div v-if="isLoading" class="atmo-album-show atmo-album-show--loading">
    <atmo-loading size="large" />
  </div>
  <div v-else class="atmo-album-show">
    <h2 class="atmo-page-header">
      {{ album.name }}
    </h2>
    <div class="atmo-album-show__breadcrumb-container">
      <atmo-back-link class="back-link"></atmo-back-link>
    </div>
    <div class="atmo-album-show__body">
      <div class="atmo-album-show__album-info">
        <album-card :album="album" :is-link="false" class="atmo-album-show__album-card" />
        <router-link v-if="canEditAlbum" class="
            atmo-button
            atmo-button--tertiary
            atmo-album-show__edit-button
          " :to="{ name: 'albums.edit', params: { artistProfileId, albumId } }">
          <img class="atmo-button__icon atmo-button__icon--small" src="@/assets/images/icons/edit.png" alt="edit album">
          <span>Edit Album</span>
        </router-link>
        <button v-if="canEditAlbum" class="
            atmo-button
            atmo-button--tertiary
            atmo-album-show__edit-button
          " @click="deleteAlbum">
          <img class="atmo-button__icon atmo-button__icon--small" src="@/assets/images/icons/delete.png"
            alt="delete album">
          <span>Delete Album</span>
        </button>
      </div>
      <div class="atmo-album-show__songs">
        <div v-if="albumSongs == null">
          Loading…
        </div>
        <perfect-scrollbar v-else>
          <atmo-songs-table :songs="albumSongs" :show-headings="false" :show-comments-links="true" :song-actions="[
            'add-to-queue',
            'add-to-library',
            'add-to-playlist'
          ]" />
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import AlbumCard from './album-card';
// import AtmoBreadcrumbs from '@/components/atmo-breadcrumbs';
import AtmoLoading from '@/components/atmo-loading';
import AtmoSongsTable from '@/components/atmo-songs-table';
import AtmoBackLink from '@/components/atmo-back-link';
import {
  callAlbumSongsIndex
} from '@/helpers/axiosCalls';

export default {
  components: {
    AlbumCard,
    AtmoLoading,
    AtmoSongsTable,
    // AtmoBreadcrumbs,
    AtmoBackLink
  },

  data() {
    return {
      isLoading: true,
      album: null,
      albumSongs: null
    }
  },

  computed: {
    artistProfileId() {
      return Number(this.$route.params.artistProfileId);
    },
    albumId() {
      return Number(this.$route.params.albumId);
    },
    canEditAlbum() {
      const { currentUserProfileType, currentUserProfileId } = this.$store.state;
      return (
        currentUserProfileType === 'ArtistProfile'
        && currentUserProfileId === this.artistProfileId
      );
    }
  },

  created() {
    this.getAlbum();
    this.getAlbumSongs();
  },

  methods: {
    getAlbum() {
      this.isLoading = true;
      Axios.get(
        `/api/v1/artist_profiles/${this.artistProfileId}/albums/${this.albumId}`
      ).then((response) => {
        this.album = response.data;
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    getAlbumSongs() {
      callAlbumSongsIndex(this.artistProfileId, this.albumId)
        .then((response) => {
          this.albumSongs = response;
        }).catch((error) => {
          console.error(error);
        });
    },
    deleteAlbum() {
      if (confirm('Are you sure you want to delete this album?')) {
        Axios.delete(
          `/api/v1/artist_profiles/${this.artistProfileId}/albums/${this.albumId}`
        ).then(() => {
          this.$notify({ group: 'vue-app', title: 'Album Deleted' });
          this.$router.push({
            name: 'albums.index',
            params: { artistProfileId: this.artistProfileId }
          });
        }).catch((error) => {
          console.error(error);
          this.$notify({ type: 'error', group: 'vue-app', title: 'Error Deleting Album' });
        });
      }
    }
  }
}
</script>

<style lang="scss">
.atmo-album-show {
  
  .back-link {
    margin-left: 2rem;
  }

  &__body {
    display: flex;
    margin-top: 20px;
    width: 100%;
  }

  &__album-info {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 1rem;
  }

  &__edit-button {
    margin-top: 15px;
  }

  &__songs {
    flex: 1;
    margin-right: 2rem;
  }
}
</style>
