import Axios from 'axios';

export function callSubscriptionsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/subscriptions`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSubscriptionsShow(userId, subscriptionId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/subscriptions/${subscriptionId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSubscriptionsCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/subscriptions`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSubscriptionsUpdate(userId, subscriptionId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/users/${userId}/subscriptions/${subscriptionId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSubscriptionsDelete(userId, subscriptionId, queryParams = {}) {
    return Axios.delete(`/api/v1/users/${userId}/subscriptions/${subscriptionId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
