<template>
  <div>
    <h2 class="atmo-page-header atmo-store__header">
      AMI Commands Index
    </h2>
    <button style="cursor:pointer;" @click="backToAmiSettings()">
      Back to AMI Settings
    </button>
    <button
      style="cursor:pointer; display:block; margin-bottom: 20px;"
      @click="newCommandPage()"
    >
      New command
    </button>
    <div class="ami-container">
      <div v-if="this.commandsLoading" class="hud-sidebar__loading-container">
        <atmo-loading />
      </div>
      <div
        v-for="command in allCommands"
        :key="command.id"
        style="cursor:pointer"
        @click="getCommandShowPage(command.id)"
      >
        <p>id: {{ command.id }} | command_function: {{ command.command_function }}</p>
        <div
          v-for="speechTrigger in command.ami_command_speech_triggers"
          :key="speechTrigger.id"
        >
          <p
            style="position:relative; margin-left:30px;"
          >
            speech trigger: id: {{ speechTrigger.id }} | speech: {{ speechTrigger.speech }}
          </p>
        </div>
        <br>
        <hr>
        <br>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
.ami-container {
  position: relative;
  height: 400px;
  width: 97%;
  margin: auto;
}
</style>

<script>
  import Axios from "axios";
  import AtmoLoading from "@/components/atmo-loading";

  export default {
    components: { AtmoLoading },

    data() {
      return {
        allCommands: [],
        commandsLoading: true
      };
    },
    created() {
      this.getAmiCommands();
    },
    methods: {
      getAmiCommands() {
        Axios.get(`/api/v1/ami_commands?device=desktop`, {})
          .then(response => {
            this.commandsLoading = false;
            this.allCommands = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      },
      getCommandShowPage(commandId) {
        this.$router.push({
          name: "AMI.commands.show",
          params: { commandId: commandId }
        });
      },
      newCommandPage() {
        this.$router.push({ name: "AMI.commands.new" });
      },
      backToAmiSettings() {
        this.$router.push({ name: "AMI.settings" });
      }
    }
  };
</script>