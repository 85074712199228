<template>
  <perfect-scrollbar>
    <div class="artists-grid">
      <router-link v-for="artist, index in artists" :key="index"
        :to="{ name: 'artist_profiles.show', params: { artistProfileId: artist.id } }" class="artists-grid__artist-card">
        <div class="artist-card__image-wrap"
          v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${getFeaturedImage(artist.user.images, 'full')})` }">
        </div>
        <div class="artist-card__text-wrap">
          <div class="text-wrap__artist-wrap">{{ artist.user.name }}</div>
          <div class="text-wrap__follower-wrap">
            <img class="follower-wrap__icon" src="@/assets/images/icons/music/friends.png" />
            {{ artist.user.followers.length }}
          </div>
        </div>
      </router-link>
    </div>
  </perfect-scrollbar>
</template>
  
<script>
import PaginationMixin from '@/mixins/pagination';
import { getFeaturedImage } from '@/helpers/utilityFunctions';

export default {
  mixins: [PaginationMixin],
  props: { showNewReleases: { type: Boolean, default: false }, artists: { type: Array, required: true } },

  data() {
    return {
      songs: null,
    };
  },

  computed: {
    fullCollection() {
      if (this.showNewReleases) {
        return this.songs.filter(song => song.isNew);
      } else {
        return this.songs;
      }
    }
  },

  created() {
    this.getSongs();
  },

  methods: {
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    getSongs() {
      // TODO: Use real songs
      // this.songs = songsStub;
    }
  }
}
</script>
  
<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";

.artists-grid {
  display: grid;
  // grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  // grid-template-rows: auto auto;
  // grid-auto-rows: 0px;
  // position: relative;
  // overflow: hidden;
  grid-gap: 1rem;
  margin-top: 10px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 1rem;

  .artists-grid__artist-card {
    border-radius: 5px;
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    color: white;
  }

  .artist-card__image-wrap {
    height: 8rem;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 800;
    color: #fff;
    position: relative;
  }

  .artist-card__text-wrap {
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 0 0 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem .5rem 1rem;
  }

  .text-wrap__artist-wrap {
    font-weight: 500;
    color: white;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .text-wrap__follower-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $atmo-blue--medium;
  }

  .follower-wrap__icon {
    height: .8rem;
    margin-right: .2rem;
  }
}
</style>
  
  