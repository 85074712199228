var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$store.state.videoPlayer.backgroundVidPlaying)?_c('div',{staticClass:"mini-library hud-sidebar__module"},[_c('div',{staticClass:"mini-library__header"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"atmo-input atmo-input--search mini-library__search-input",attrs:{"type":"text","placeholder":_vm.searchPlaceholder},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value},_vm.filterList]}}),_c('img',{directives:[{name:"b-modal",rawName:"v-b-modal.atmo-library",modifiers:{"atmo-library":true}}],staticClass:"mini-library__icon",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/icons/max_library.png"),"alt":"Show Max Library"}})]),(_vm.currentView === 'songs')?[(_vm.libraryLoading)?_c('div',{staticClass:"hud-sidebar__loading-container"},[_c('atmo-loading')],1):_c('div',{staticClass:"hud-sidebar__scrollable-content"},[(_vm.library.length > 0)?_c('perfect-scrollbar',[_c('div',[_vm._l((_vm.filteredSongList),function(song,index){return [_c('div',{key:index,staticClass:"mini-library__song"},[_c('div',{staticClass:"mini-library__dropdown-toggle-container"},[_c('song-actions-popover',{attrs:{"song":song,"actions":[
                    'share-song-as-post',
                    'share-song-as-message',
                    'add-to-queue',
                    'remove-from-library'
                  ]}})],1),_c('p',{staticClass:"mini-library__song-name",on:{"click":function($event){return _vm.$store.dispatch('player/prependAndPlaySong', song)}}},[_vm._v(" "+_vm._s(song.name)+" ")])])]})],2)]):_c('div',{staticClass:"mini-library__empty-library-content"},[_c('div',[_vm._v("No library data found.")])])],1),_c('div',{staticClass:"mini-library__toggle-link"},[_c('a',{on:{"click":function($event){return _vm.changeView('playlists')}}},[_vm._v("View Playlists")])])]:_vm._e(),(_vm.currentView === 'playlists')?[_c('div',{staticClass:"hud-sidebar__scrollable-content"},[_c('perfect-scrollbar',[_vm._l((_vm.filteredPlaylistList),function(playlist){return [_c('div',{key:playlist.id,staticClass:"mini-library__song"},[_c('div',{staticClass:"mini-library__dropdown-toggle-container"},[_c('song-actions-popover',{attrs:{"song":_vm.song,"actions":['add-playlist-to-queue']}})],1),_c('p',{staticClass:"mini-library__song-name",on:{"click":function($event){return _vm.setPlaylistAttributes(playlist.id)}}},[_vm._v(" "+_vm._s(playlist.name)+" ")])])]})],2)],1),_c('div',{staticClass:"mini-library__toggle-link"},[_c('a',{on:{"click":function($event){return _vm.changeView('songs')}}},[_vm._v("Back To Songs")])])]:_vm._e(),(_vm.currentView === 'playlist-songs')?[_c('div',{staticClass:"hud-sidebar__scrollable-content"},[_c('perfect-scrollbar',_vm._l((_vm.filteredPlaylistSongList),function(song){return _c('div',{key:song.id,staticClass:"mini-library__song"},[_c('div',{staticClass:"mini-library__dropdown-toggle-container"},[_c('song-actions-popover',{attrs:{"song":song,"actions":[
                'share-song-as-post',
                'share-song-as-message',
                'add-to-queue',
                'remove-from-library'
              ]}})],1),_c('p',{staticClass:"mini-library__song-name",on:{"click":function($event){return _vm.$store.dispatch('player/prependAndPlaySong', song)}}},[_vm._v(" "+_vm._s(song.name)+" ")])])}),0)],1),_c('div',{staticClass:"mini-library__toggle-link"},[_c('a',{on:{"click":function($event){return _vm.changeView('playlists')}}},[_vm._v("Back To Playlists")])])]:_vm._e()],2):_c('div',{staticClass:"video-lyrics-container hud-sidebar__module"},[_c('div',{staticClass:"video-lyrics-container__header"},[_vm._v(_vm._s(_vm.computedVideoName))]),_c('perfect-scrollbar',[_c('p',[_vm._v(_vm._s(_vm.computedVideoLyrics))])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }