import Axios from 'axios';

export function callUserActivitiesIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/user_activities`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserActivitiesShow(userId, activityId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/user_activities/${activityId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserActivitiesCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/user_activities`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}