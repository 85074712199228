<template>
  <div class="atmo-progress">
    <div class="atmo-progress__inner-bar">
      <div
        class="atmo-progress__progress-bar"
        :style="{ 'width': `${progress}%` }"
      >
        <div id="atmo-progress__knob" class="atmo-progress__knob">
          <div class="atmo-progress__tooltip">
            <div class="atmo-progress__tooltip-arrow" />
            <div class="atmo-progress__tooltip-inner">
              {{ progress }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      progress: {
        type: Number,
        required: true
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .atmo-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba($atmo-purple--light, 0.5);
    border-radius: 10px;
    height: 15px;

    &__inner-bar {
      position: relative;
      width: 90%;
      border-radius: 5px;
      background-color: white;
      height: 8px;
    }

    &__progress-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin-bottom: -1px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-radius: 5px;
      min-width: 11px;
      background-image: linear-gradient(
        -270deg,
        rgba(white, 0.8) 0%,
        rgba($atmo-pink--medium, 0.8) 80%
      );
      transition: width 1s ease-in;
    }

    &__knob {
      height: 11px;
      width: 11px;
      border-radius: 100%;
      background-color: $atmo-pink--medium;
      border: 2px solid white;
      box-shadow: 0 0 1px 1px $atmo-pink--medium;
      position: relative;
    }

    &__tooltip {
      position: absolute;
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    $arrow-height: 4px;

    &__tooltip-arrow {
      width: 100%;
      display: flex;
      justify-content: center;

      &::before {
        position: absolute;
        content: "";
        border-color: transparent;
        border-style: solid;
        border-bottom-color: white;
        border-width: 0 3px $arrow-height;
      }
    }

    &__tooltip-inner {
      margin-top: $arrow-height;
      font-size: 0.7em;
      padding: 2px 4px;
      background-color: white;
      color: $atmo-purple--dark;
      border-radius: 4px;
    }
  }
</style>
