import VueRouter from 'vue-router';

import Login from './routes/auth/login';
import Signup from './routes/auth/signup';
import ForgotPassword from './routes/auth/forgot_password';
import ForgotPasswordEmailRequest from './routes/auth/forgot_password_email_request';
import AtmoDashboard from './routes/atmo_dashboards/show';
import Directory from './routes/directory_profiles/index';
import DirectoryProfile from './routes/directory_profiles/show';
import Settings from './routes/settings/show';
import Checkout from './routes/checkout';
import Contests from './routes/contests/index';
import ContestsNew from './routes/contests/new';
import ContestsEdit from './routes/contests/edit';
import Contest from './routes/contests/show';
import ContestSubmissionsShow from './routes/contests/contest_submissions/show';

// import AtmoEventsWrapper from './routes/atmo_events/index';
import AtmoEventsUser from './routes/atmo_events/user_index';
import AtmoEventsAdmin from './routes/atmo_events/admin_index';
import AtmoEventUser from './routes/atmo_events/show';
import AtmoEventsNew from './routes/atmo_events/new';
import AtmoEventEdit from './routes/atmo_events/edit';
import AtmoEventTickets from './routes/atmo_events/event_tickets';

import Purchases from './routes/purchases/index';
import PurchaseInfo from './routes/purchases/purchase_info';
import AtmoArtists from './routes/atmo_artists/index';
import Friends from './components/profiles/friends';
import MutualFriends from './components/profiles/mutual-friends';
import UserProfile from './routes/user_profiles/show';
import Photos from './routes/photos/index';
import Videos from './routes/videos/index';
import EditUserProfile from './routes/user_profiles/edit';
import EditArtistProfile from './routes/artist_profiles/edit';
import ArtistSubscriptions from './components/profiles/artist-subscriptions';
import Billboard from './routes/billboards/show';
import UserPlaylists from './routes/playlists/index';
import Playlist from './routes/playlists/show';
import PlaylistSettings from './routes/playlists/settings';
import Subscriptions from './routes/subscriptions/index';
import Subscription from './routes/subscriptions/show';
import Cards from './routes/subscriptions/cards/index';
import NewCard from './routes/subscriptions/cards/new';
import UserPlans from './routes/subscriptions/plans/user_plans/index';
import ArtistPlans from './routes/subscriptions/plans/artist_plans/index';
import UserSubCheckout from './routes/subscriptions/user_subscriptions/new_subscription';
import UserSubInvoices from './routes/subscriptions/invoices/index';
import ProductsContainer from './routes/products/products_container';
import Products from './routes/products/index';
import Product from './routes/products/show';
import ProductNew from './routes/products/new/new';
import ProductVariantsNew from './routes/products/new/product_variants';
import ProductImagesNew from './routes/products/new/product_images';
import ProductEdit from './routes/products/edit/edit';
import ProductVariantsEdit from './routes/products/edit/product_variants';
import ProductImagesEdit from './routes/products/edit/product_images';
import Cart from './routes/products/cart';
import ArtistProfile from './routes/artist_profiles/show';
import LabelProfile from './routes/label_profiles/show';
import Albums from './routes/albums/index';
import NewAlbum from './routes/albums/new';
import Album from './routes/albums/show';
import Genre from './routes/genres/show';
import Mood from './routes/moods/show';
import EditAlbum from './routes/albums/edit';
import Song from './routes/albums/songs/show';
import Accomplishments from './routes/accomplishments/index';
import Accomplishment from './routes/accomplishments/show';
import ComingSoon from './routes/coming_soon/show';
import AMISettings from './routes/AMI/settings';
import AMICommandDocs from './routes/AMI/command_docs';
import AMICommandsIndex from './routes/AMI/commands/index';
import AMICommandsShow from './routes/AMI/commands/show';
import AMICommandsNew from './routes/AMI/commands/new';

import Fans from './components/profiles/fans';
import ArtistProfileDonation from './routes/artist_profiles/donate';
import OpsPages from './routes/ops_pages/index';
import OpsPage from './routes/ops_pages/show';
import OpsPagesEdit from './routes/ops_pages/edit';
import OpsPagesNew from './routes/ops_pages/new';
// import OpsPageAdmin from './routes/ops_pages/admin_show';
// import OpsPagesAdmin from './routes/ops_pages/admin_index';
// import OpsPagesUser from './routes/ops_pages/user_index';
// import OpsPageUser from './routes/ops_pages/user_show';
// import OpsPageDonate from './routes/ops_pages/user_donate';
import ArtistDashboard from './routes/artist_dashboards/show';
import ArtistDashboardFanDemographics from './routes/artist_dashboards/fan_demographics/show';
import ArtistDashboardVenueRequests from './routes/artist_dashboards/venue_requests/show';
import ArtistDashboardPurchases from './routes/artist_dashboards/purchases/show';
import ArtistPlaylists from './routes/artist_profiles/playlists/index';
import ArtistPlaylist from './routes/artist_profiles/playlists/show';
import VenueRequest from './routes/venue_requests/new';
import Advertisements from './routes/advertisements/index';
import AdvertisementNew from './routes/advertisements/new';
import AdvertisementEdit from './routes/advertisements/edit';
import AtmoRadio from './routes/atmo_radio/index';
import Notifications from './routes/notifications/index';
import Post from './routes/posts/show';

// Separated route definitions
import musicRoutes from './routes/music/music_routes';

// To identify user
import store from "./store";

store.commit('authenticate');

let routes = [
  { name: 'login', path: '/login', component: Login },
  { name: 'signup', path: '/signup', component: Signup },
  { name: 'forgot_password_email_request', path: '/forgot_password_email_request', component: ForgotPasswordEmailRequest },
  { name: 'forgot_password', path: '/forgot_password', component: ForgotPassword },
  { name: 'contests.index', path: '/contests', component: Contests },
  { name: 'contests.edit', path: '/contests/:contestId/edit', component: ContestsEdit },
  { name: 'contests.new', path: '/contests/new', component: ContestsNew },
  { name: 'contests.show', path: '/contests/:contestId', component: Contest, children: [
    { name: 'contests.show.submissions.show', path: 'submissions/:submissionId', component: ContestSubmissionsShow },
  ]},
  { name: 'ops_pages.index', path: '/ops_pages', component: OpsPages },
  { name: 'ops_pages.show', path: '/ops_pages/:opsPageId', component: OpsPage },
  { name: 'ops_pages.edit', path: '/ops_pages/:opsPageId/edit', component: OpsPagesEdit },
  { name: 'ops_pages.new', path: '/new_ops_page', component: OpsPagesNew }, // TODO: when this is /ops_pages/new, there is an issue with loading
  { name: 'directory_profiles.index', path: '/directory_profiles', component: Directory },
  { name: 'directory_profiles.show', path: '/directory_profiles/:directoryProfileId', component: DirectoryProfile },
  { name: 'settings', path: '/settings', component: Settings },
  { name: 'dash', path: '/', component: AtmoDashboard },
  { name: 'checkout', path: '/checkout', component: Checkout },
  {
    path: '/products',
    component: ProductsContainer,
    children: [
      { name: 'products.index', path: '', component: Products, props: { isFiltered: false } },
      { name: 'products.show', path: ':productId', component: Product },
    ],
  },
  {
    path: '/users/:userId/products',
    component: ProductsContainer,
    children: [
      { name: 'products.user_index', path: '', component: Products, props: route => ({ isFiltered: true, userId: route.params.userId }) },
      { name: 'products.show', path: ':productId', component: Product },
    ],
  },
  { name: 'products.new', path: '/products/new', component: ProductNew },
  { name: 'product_variants.new', path: '/product_variants/new', component: ProductVariantsNew },
  { name: 'product_images.new', path: '/product_images/new', component: ProductImagesNew },
  { name: 'products.edit', path: '/products/:productId/edit', component: ProductEdit },
  { name: 'product_variants.edit', path: '/products/:productId/product_variants', component: ProductVariantsEdit },
  { name: 'product_images.edit', path: '/products/:productId/product_images', component: ProductImagesEdit },

  { name: 'cart', path: '/cart', component: Cart },
  { name: 'purchases', path: '/user_profiles/:userProfileId/purchases', component: Purchases, props:true },
  { name: 'purchaseinfo', path: '/user_profiles/:userProfileId/purchases/:id', component: PurchaseInfo, props:true},
  { name: 'atmo_artists', path: '/atmo_artists', component: AtmoArtists },
  { name: 'user_profiles.show', path: '/user_profiles/:userProfileId', component: UserProfile },
  { name: 'user_profiles.edit', path: '/user_profiles/:userProfileId/edit', component: EditUserProfile },
  { name: 'user_profiles.artist_subscriptions', path: '/user_profiles/:userProfileId/artist_subscriptions', component: ArtistSubscriptions },
  { name: 'subscriptions.index', path: '/subscriptions', component: Subscriptions },
  { name: 'subscriptions.show', path: '/subscriptions/:subscriptionId', component: Subscription },
  { name: 'subscriptions.cards.index', path: '/user_profiles/:userProfileId/cards', component: Cards },
  { name: 'subscriptions.cards.new', path: '/user_profiles/:userProfileId/cards/new', component: NewCard },
  { name: 'subscriptions.user_plans.index', path: '/user_plans', component: UserPlans },
  { name: 'subscriptions.artist_plans.index', path: '/artist_plans', component: ArtistPlans },
  { name: 'subscriptions.user_subscriptions.new', path: '/user_profiles/:userProfileId/user_subscriptions/checkout', component: UserSubCheckout  },
  { name: 'subscriptions.user_invoices.index', path: '/user_profiles/:userProfileId/user_subscriptions/:subscriptionId/invoices', component: UserSubInvoices  },
  { name: 'artist_profiles.show', path: '/artist_profiles/:artistProfileId', component: ArtistProfile },
  { name: 'artist_profiles.donate', path: '/artist_profiles/:artistProfileId/donate', component: ArtistProfileDonation },
  { name: 'artist_profiles.venue_request.new', path: '/artist_profiles/:artistProfileId/venue_requests/new', component: VenueRequest },
  { name: 'advertisements.new', path: '/users/:userId/advertisements/new', component: AdvertisementNew },
  { name: 'advertisements.index', path: '/users/:userId/advertisements', component: Advertisements },
  { name: 'advertisements.edit', path: '/users/:userId/advertisements/edit', component: AdvertisementEdit },
  { name: 'label_profiles.show', path: '/label_profiles/:labelProfileId', component: LabelProfile },
  { name: 'atmo_radio.index', path: '/atmo_radio/', component: AtmoRadio },
  { name: 'notifications.index', path: '/users/:userId/notifications/', component: Notifications },
  
  { name: 'events.index', path: '/events', component: AtmoEventsUser },
  { name: 'events.admin_index', path: '/users/:userId/events', component: AtmoEventsAdmin },
  { name: 'events.show', path: '/events/:eventId', component: AtmoEventUser },
  { name: 'events.new', path: '/events/new', component: AtmoEventsNew },
  { name: 'events.edit', path: '/events/:eventId/edit', component: AtmoEventEdit },
  { name: 'events.tickets.index', path: '/events/:eventId/tickets', component: AtmoEventTickets },

  { name: 'albums.index', path: '/artist_profiles/:artistProfileId/albums', component: Albums  },
  { name: 'albums.new', path: '/artist_profiles/:artistProfileId/albums/new', component: NewAlbum  },
  { name: 'albums.show', path: '/artist_profiles/:artistProfileId/albums/:albumId', component: Album  },
  { name: 'albums.edit', path: '/artist_profiles/:artistProfileId/albums/:albumId/edit', component: EditAlbum  },
  { name: 'songs.show', path: '/songs/:songId', component: Song  },
  { name: 'artist_dashboards.show', path: '/artist_profiles/:artistProfileId/artist_dashboard', component: ArtistDashboard  },
  { name: 'artist_profiles.edit', path: '/artist_profiles/:artistProfileId/edit', component: EditArtistProfile },
  { name: 'artist_dashboards.fan_demographics.show', path: '/artist_profiles/:artistProfileId/artist_dashboard/fan_demographics', component: ArtistDashboardFanDemographics },
  { name: 'artist_dashboards.venue_requests.show', path: '/artist_profiles/:artistProfileId/artist_dashboard/venue_requests', component: ArtistDashboardVenueRequests },
  { name: 'artist_dashboards.purchases.show', path: '/artist_profiles/:artistProfileId/artist_dashboard/purchases', component: ArtistDashboardPurchases },
  { name: 'artist_profiles.playlists.index', path: '/artist_profiles/:artistProfileId/playlists', component: ArtistPlaylists },
  { name: 'artist_profiles.playlists.show', path: '/artist_profiles/:artistProfileId/playlists/:playlistId', component: ArtistPlaylist },
  { name: 'genres.show', path: '/genres/:genreId', component: Genre  },
  { name: 'moods.show', path: '/moods/:moodId', component: Mood  },

  { name: 'photos.index', path: '/users/:userId/photos', component: Photos },
  { name: 'videos.index', path: '/users/:userId/videos', component: Videos },
  { name: 'billboards.show', path: '/users/:userId/billboard', component: Billboard },
  { name: 'users.friends', path: '/users/:userId/friends', component: Friends },
  { name: 'users.mutual_friends', path: '/users/:userId/mutual_friends', component: MutualFriends },
  { name: 'users.fans', path: '/users/:userId/fans', component: Fans },
  { name: 'users.artist_subscriptions', path: '/users/:userId/artist_subscriptions', component: ArtistSubscriptions },

  { name: 'users.ops_pages', path: '/users/:userId/ops_pages', component: OpsPages },
  { name: 'users.contests', path: '/users/:userId/contests', component: Contests },

  { name: 'accomplishments.index', path: '/users/:userId/accomplishments', component: Accomplishments },
  { name: 'accomplishments.show', path: '/users/:userId/accomplishments/:accomplishmentId', component: Accomplishment },

  { name: 'posts.show', path: '/posts/:postId', component: Post },

  { name: 'users.playlists.index', path: '/users/:userId/playlists', component: UserPlaylists },
  { name: 'playlists.show', path: '/playlists/:playlistId', component: Playlist },

  //WE MIGHT WANT TO REMOVE THIS FOR THE TIME BEING BECAUSE IT EXISTS IN LIBRARY
  { name: 'playlists.settings', path: '/playlists/:playlistId/settings', component: PlaylistSettings },

  musicRoutes,
  { name: 'coming_soon', path: '/coming_soon', component: ComingSoon },
  { name: 'AMI.settings', path: '/AMI/settings', component: AMISettings },
  { name: 'AMI.command_docs', path: '/AMI/command_docs', component: AMICommandDocs },
  { name: 'AMI.commands.index', path: '/AMI/commands', component: AMICommandsIndex },
  { name: 'AMI.commands.new', path: '/AMI/commands/new', component: AMICommandsNew },
  { name: 'AMI.commands.show', path: '/AMI/commands/:commandId', component: AMICommandsShow }
];

let router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  const routesThatDontNeedAuth = ['login', 'signup'];
  if (routesThatDontNeedAuth.includes(to.name) || store.state.authenticated) {
    return next();
  }
  store.commit('authenticate');
  const authenticationPromise = store.state.authenticationPromise;

  authenticationPromise
    ? authenticationPromise.then(() => next())
    : next({ name: 'login' });
});

export default router;

