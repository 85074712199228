<template>
  <div class="shared-post-container">
    <div class="shared-post-container__post-container">
      <div class="shared-post-container__main-content-container">
        <div class="shared-post-container__post-header">
          <div class="atmo-post__post-identifier">
            <span class="atmo-post__post-owner">
              <a>{{ sharedPost.user.name }}</a>
            </span>
            <span class="atmo-post__post-date">
              {{ getDate(sharedPost.created_at) }}
            </span>
            <div class="atmo-post__post-public">
              <img v-if="sharedPost.is_public" src="@/assets/images/icons/posts/public.png" alt="public playlist" />
              <img v-else src="@/assets/images/icons/posts/private.png" alt="private playlist" />
            </div>
          </div>
        </div>
        <div class="shared-post-container__post-content">
          <div v-if="sharedPost.images.length > 0" class="post-content__post-pics-container">
            <img v-for="(postImage, index) in sharedPost.images" :key="index" class="post-pics-container__post-pic"
              :src="postImage.image.url" alt="Post pic" @click="openUserMediaModal({
                type: 'Image',
                caption: sharedPost.content,
                url: postImage.image.url,
                id: postImage.id
              })" />
          </div>
          <div v-html="formatPost(sharedPost.content)"></div>
        </div>
        <div v-if="sharedPost.song_references.length > 0">
          <div class="shared-post-container__post-footer" v-for="(songReference, index) in sharedPost.song_references"
            :key="index">
            <div class="shared-post-container__song-actions-popover-container">
              <song-actions-popover :song="song" placement="left" :actions="[
                'add-to-library',
                'add-to-queue',
                'add-to-playlist'
              ]" />
            </div>
            <span class="shared-post-container__song-link">
              {{ songReference.song.name }}
            </span>
          </div>
        </div>
      </div>
      <!-- <div v-if="commentsEnabled || likesEnabled" class="shared-post-container__social-buttons">
          <button v-if="likesEnabled" class="atmo-button shared-post-container__social-button" @click="likePost(sharedPost.id)">
            <img class="atmo-button__icon shared-post-container__icon--not-hovered" src="@/assets/images/icons/like--purple.png"
              alt="Like">
            <img class="atmo-button__icon shared-post-container__icon--hovered" src="@/assets/images/icons/like.png" alt="Like">
            {{ sharedPost.likes.length }}
          </button>
          <button v-if="likesEnabled" class="atmo-button shared-post-container__social-button" @click="dislikePost(sharedPost.id)">
            <img class="atmo-button__icon shared-post-container__icon--not-hovered" src="@/assets/images/icons/dislike--purple.png"
              alt="Dislike">
            <img class="atmo-button__icon shared-post-container__icon--hovered" src="@/assets/images/icons/dislike.png" alt="Dislike">
            {{ sharedPost.dislikes.length }}
          </button>
          <button v-if="commentsEnabled" class="atmo-button shared-post-container__social-button" :class="{
            'shared-post-container__social-button--active': commentsExpanded,
          }" @click="toggleComments(post)">
            <img class="atmo-button__icon shared-post-container__icon--not-hovered" src="@/assets/images/icons/comment--purple.png"
              alt="Comment">
            <img class="atmo-button__icon shared-post-container__icon--hovered" src="@/assets/images/icons/comment.png" alt="Comment">
            {{ sharedPost.comments.length }}
          </button>
        </div>
        <div v-if="commentsExpanded" class="shared-post-container__post-comments-container">
          <comments-list :type="type" :post="post" :base-url="baseUrl" :user-profile="userProfile" />
        </div> -->
    </div>
  </div>
</template>

<script>
import SongActionsPopover from '@/components/song-actions-popover';
// import AtmoPopover from '@/components/atmo-popover';
// import CommentsList from '@/components/shared-post-containers/comments-list';
import { getFeaturedImage, utilsGetDate } from '@/helpers/utilityFunctions';

export default {
  components: { SongActionsPopover },
  props: {
    sharedPost: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
    }
  },
  methods: {
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    formatPost(post) {
      // Use a regular expression to find URLs in the post and replace them with clickable links
      const urlPattern = /https?:\/\/[^\s]+/g;
      return post.replace(urlPattern, (url) => {
        return `<a class="chat-link" href="${url}" target="_blank">${url}</a>`;
      });
    },
    getDate(date) {
      return utilsGetDate(date);
    },
    openUserMediaModal(userMediaModalObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", { 
        type: userMediaModalObj.type, 
        caption: userMediaModalObj.caption, 
        id: userMediaModalObj.id, 
        url: userMediaModalObj.url 
      });
      this.$store.commit("videoPlayer/setUserMediaModalObj", { 
        type: userMediaModalObj.type, 
        caption: userMediaModalObj.caption, 
        id: userMediaModalObj.id, 
        url: userMediaModalObj.url 
      });
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    },
  },
  computed: {
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.shared-post-container {
  display: flex;

  &__avatar-container {
    display: flex;
    justify-content: flex-end;
    width: 72px;
    padding: 0 10px;
  }

  &__post-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 12px;
  }

  &__main-content-container {
    display: flex;
    flex-direction: column;
    // background-color: rgba($atmo-purple--light, 0.4);
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    padding: .5rem .5rem .5rem 1rem;
    margin-bottom: 8px;
    border-radius: 5px;
    font-weight: 300;
    // min-height: 6rem;
  }

  &__post-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__post-content {
    padding: 10px 0;
    font-size: .8rem;
    font-weight: 400;
    display: flex;
    gap: .5rem;
  }

  &__post-identifier {
    display: flex;
    align-items: baseline;
    margin-bottom: .5rem;
  }

  &__post-owner {
    font-size: .8rem;
    margin-right: .5rem;

    a {
      color: white;
      font-weight: 500;
    }
  }

  &__post-date {
    color: white;
    font-size: .7rem;
    font-weight: 500;
    margin-left: 3px;
  }

  &__post-public {
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: .8rem;
    }
  }

  &__post-dropdown {
    position: relative;
  }

  &__post-dropdown-trigger {
    height: 9px;
    padding: 1px 3px;
  }

  &__shared-post-container {
    padding: 10px 0;
    font-size: .8rem;
    font-weight: 400;
    display: flex;
    gap: .5rem;
  }

  .post-content__post-pics-container {
    display: flex;
    gap: .5rem;
  }

  .post-pics-container__post-pic {
    height: 4rem;
    width: 4rem;
    object-fit: cover;
    border-radius: 2px;
    cursor: pointer;
  }

  &__post-footer {
    display: flex;
    align-items: flex-end;
    margin-bottom: 3px;
    align-items: center;

    .atmo-button {
      margin-right: 5px;
    }
  }

  &__song-actions-popover-container {
    height: 20px;
    width: 20px;
  }

  &__song-link {
    color: white;
    font-size: .8rem;
    font-weight: 500;
    margin-left: .2rem;
  }

  .song-actions-button {
    .shared-post-container__button-icon--active {
      display: none;
    }

    &:active,
    &:focus {
      outline: none;
      background-color: $atmo-purple--dark;

      :global(.atmo-button__icon) {
        display: none;
      }

      .shared-post-container__button-icon--active {
        display: flex;
        margin-left: 0;
      }
    }
  }

  &__social-buttons {
    display: flex;
  }

  .atmo-button.shared-post-container__social-button {
    font-size: 0.8em;
    font-weight: 400;
    padding: 5px 10px 5px 10px;
    margin-right: 5px;

    .shared-post-container__icon--hovered {
      display: none;
      margin-left: 0;
    }

    &:hover,
    &--active {
      // .shared-post-container__icon--hovered {
      //   display: block;
      // }
      // .shared-post-container__icon--not-hovered {
      //   display: none;
      // }
    }

    &--small {
      font-size: 0.7em;
      padding: 3px 7px 2px;

      img.atmo-button__icon {
        height: 11px;
      }
    }

    img {
      height: 14px;
      width: 18px;
      padding-right: 3px;
    }

    &--active {
      outline: none;
      background-color: $atmo-purple--dark;
      color: white;
    }

  }

  &__post-comments-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &__comment {
    display: flex;

    .post-container {
      margin-left: 10px;
    }

    .main-content-container {
      font-size: 0.9em;
      background: inherit;
      padding-left: 0;
      margin-bottom: 0;
    }
  }
}
</style>
