<template>
    <div class="atmo-music-explore">
      <atmo-filter-buttons
        :active-filter="currentChildRoute"
        :filter-buttons="filterButtons"
        @on-button-click="onFilterButtonClick"
      />
  
      <router-view
        :show-new-releases="currentParentRoute === 'new_releases'"
      />
    </div>
  </template>
  
  <script>
    import AtmoFilterButtons from '@/components/atmo-filter-buttons';
  
    export default {
      components: {
        AtmoFilterButtons
      },
  
      data() {
        return {
          filterButtons: [
            { name: 'genres_moods', label: 'Genres & Moods' },
            { name: 'songs', label: 'songs' },
            { name: 'artists', label: 'artists' },
            { name: 'playlists', label: 'playlists' }
          ]
        };
      },
  
      computed: {
        routeSegments() {
          return this.$route.name.split('.');
        },
        currentParentRoute() {
          // 'explore' or 'new_releases'
          const routeSegments = this.routeSegments;
          return routeSegments[routeSegments.length - 2];
        },
        currentChildRoute() {
          // 'genres_moods', 'songs', or 'artists'
          const routeSegments = this.routeSegments;
          return routeSegments[routeSegments.length - 1];
        }
      },
  
      methods: {
        onFilterButtonClick(filterName) {
          const newRouteName = `music.${this.currentParentRoute}.${filterName}`;
          this.$router.push({ name: newRouteName });
        }
      }
    }
  </script>
  
  <style lang="scss">
    .atmo-music-explore {
      display: flex;
      flex-direction: column;
    }
  </style>
  