<template>
    <div :class="computedClass" :id="dotId" v-if="!notificationsEmpty()">
        {{ dotSize === 'small' ? '' : notificationsLength }}
    </div>
</template>

<script>
export default {
    props: {
        dotId: String,
        notificationType: String,
        dotSize: String,
        chatUser: Object
    },
    computed: {
        computedClass() {
            return this.dotSize === 'small' ? 'notification-dot-small' : 'notification-dot';
        },
        notifications() {
            return this.$store.getters['notifications/getUserNotifications'];
        },
        notificationsLength() {
            if (this.notificationType) {
                const filteredNotifications = this.notifications.filter(notification =>
                    notification.notification_type.type_name === this.notificationType
                );

                if (this.notificationType === 'Chat Message') {
                    const messageNotificationsForFriend = filteredNotifications.filter(notification =>
                        notification.sender_id === this.chatUser.id
                    );
                    return messageNotificationsForFriend.length;
                } else {
                    return filteredNotifications.length;
                }
            } else {
                return this.notifications.length;
            }
        }
    },
    methods: {
        notificationsEmpty() {
            return this.notificationsLength === 0;
        }
    }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.notification-dot-small {
    background-color: $atmo-pink--medium; ////$atmo-blue--medium?
    height: .4rem;
    width: .4rem;
    border-radius: 100%;
}

.notification-dot {
    // background-color: red;
    background-color: $atmo-pink--medium; //$atmo-blue--medium?
    height: .8rem;
    width: .8rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: .6rem;
}
</style>