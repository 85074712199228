<template>
  <div class="contest-submission-votes" v-if="submission">
    <div class="comment__social-buttons">
      <button class="
                    atmo-button
                    comment__social-button
                    comment__social-button--small
                  " @click="likeSubmission(submission.id)">
        <img class="atmo-button__icon comment__icon--not-hovered" src="@/assets/images/icons/like--purple.png"
          alt="Like">
        <img class="atmo-button__icon comment__icon--hovered" src="@/assets/images/icons/like.png" alt="Like">
        {{ submission.likes.length }}
      </button>
      <button class="
                    atmo-button
                    comment__social-button
                    comment__social-button--small
                  " @click="dislikeSubmission(submission.id)">
        <img class="atmo-button__icon comment__icon--not-hovered" src="@/assets/images/icons/dislike--purple.png"
          alt="Dislike">
        <img class="atmo-button__icon comment__icon--hovered" src="@/assets/images/icons/dislike.png" alt="Dislike">
        {{ submission.dislikes.length }}
      </button>
    </div>
  </div>
</template>

<script>
import {
  callContestSubmissionsShow,
  callLikesCreate,
  callDislikesCreate,
} from '@/helpers/axiosCalls';

export default {
  props: {
    submissionId: { type: Number, required: true },
  },

  data() {
    return {
      submission: null
    }
  },

  created() {
    this.getSubmission();
  },

  methods: {
    getSubmission() {
      callContestSubmissionsShow(this.$route.params.contestId, this.submissionId)
        .then((response) => {
          this.submission = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    likeSubmission(submissionId) {
      callLikesCreate(
        {
          user_id: this.$store.state.currentUserId,
          likeable_id: submissionId,
          likeable_type: "ContestSubmission"
        })
        .then((response) => {
          this.getSubmission();
          this.$store.dispatch('userActivities/createUserActivity',
            {
              user_activity_type_name: "Post Like",
              user_id: this.$store.state.currentUserId,
              activityable_type: "Post",
              activityable_id: response.likeable.id
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    dislikeSubmission(submissionId) {
      callDislikesCreate(
        {
          user_id: this.$store.state.currentUserId,
          dislikeable_id: submissionId,
          dislikeable_type: "ContestSubmission"
        })
        .then((response) => {
          this.getSubmission();
          this.$store.dispatch('userActivities/createUserActivity',
            {
              user_activity_type_name: "Post Dislike",
              user_id: this.$store.state.currentUserId,
              activityable_type: "Post",
              activityable_id: response.dislikeable.id
            }
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>
<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.contest-submission-votes {
  display: flex;
  align-items: center;

  .comment__social-buttons {
    display: flex;
    gap: .5rem;
  }

  .comment__social-button {
    // height: .5rem;
    font-size: .8rem;
    font-weight: 600;
    padding: .1rem .3rem .1rem .3rem;
    gap: .1rem;
  }

  .atmo-button__icon {
    height: .6rem;
    margin: 0;
  }
}
</style>
