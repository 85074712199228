<template>
  <div class="event-page">
    <share-event-modal :event="event"></share-event-modal>
    <div class="header-container">
      <h2 class="atmo-page-header">
        {{ event.name }}
      </h2>
    </div>
    <atmo-back-link></atmo-back-link>
    <div class="event-page__wrapper">
      <div class="event-grid">
        <div class="event-grid__image-container"
          v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${imageUrl})` }">
          <div class="image-container__details-container">
            <div class="details-container__cal-container">
              <div class="cal-container__month-wrap">
                {{ getMonth(event.datetime) }}
              </div>
              <div class="cal-container__day-wrap">
                {{ getDay(event.datetime) }}
              </div>
            </div>
            <div class="details-container__title-container" data-cy="event-name">
              {{ event.name }}
            </div>
          </div>
        </div>
        <div class="event-grid__details-container">
          <div class="details-container__links-wrap">
            <div class="links-wrap__header-wrap">
              <h3 class="pt-2 atmo-subtitle">
                Details
              </h3>
            </div>
            <div class="links-wrap__share-tickets-wrap">
              <img src="@/assets/images/icons/share--gradient.png" class="share-tickets-wrap__share-link float-right"
                v-b-modal.share-event-modal>
              <router-link :to="{ name: 'events.tickets.index', params: { eventId: event.id } }">
                <span data-cy="register-button" class="atmo-button--primary float-right">Register</span>
              </router-link>
            </div>
          </div>
          <div class="details-container__event-info-container">
            <div class="event-info-container__event-details-wrap">
              <div>{{ event.details }}</div>
            </div>
            <div class="event-info-container__event-date-wrap" data-cy="event-date">
              <div class="image-wrap"><img src="@/assets/images/icons/atmo_events/calendar.png"></div>
              <div class="text-wrap">{{ getDate(event.datetime) }}</div>
            </div>
            <div class="event-info-container__event-location-wrap" data-cy="event-address">
              <div class="image-wrap"><img src="@/assets/images/icons/atmo_events/address.png"></div>
              <div class="text-wrap">{{ getAddress(event.address) }}</div>
            </div>
            <div class="event-info-container__event-price-wrap" data-cy="event-price-range">
              <div class="image-wrap"><img src="@/assets/images/icons/atmo_events/tickets.png"></div>
              <div class="text-wrap">{{ getPriceRange(event.atmo_event_tickets) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AtmoBackLink from '@/components/atmo-back-link';
import ShareEventModal from '@/modals/events/share-event-modal';
import {
  formatDateToAbbrMonth,
  formatDateToDayOfMonth,
  formatDateToMonthDayYearHour,
  formatAddress,
  getTicketPriceRange
} from '@/helpers/utilityFunctions';
import {
  callEventsShow
} from '@/helpers/axiosCalls';


export default {
  components: {
    AtmoBackLink,
    ShareEventModal
  },
  data: function () {
    return {
      event: {}
    }
  },
  computed: {
    // ...mapState("events", {
    //   event: state => state.currentEvent,
    // }),
    imageUrl() {
      return this.event.image.url;
    },
  },
  created() {
    // this.setEvent(this.$route.params.eventId);
    this.getEvent();
  },
  methods: {
    // ...mapActions("events", ["setEvent"]),
    getEvent() {
      callEventsShow(this.$route.params.eventId)
        .then(response => {
          // console.log()
          this.event = response;
          // commit("setEvent", response.id);
        })
        .catch(error => {
          console.error(error);
        });
    },
    getMonth(dateInput) {
      return formatDateToAbbrMonth(dateInput);
    },
    getDay(dateInput) {
      return formatDateToDayOfMonth(dateInput);
    },
    getPriceRange(tickets) {
      return getTicketPriceRange(tickets);
    },
    getAddress(address) {
      return formatAddress(address);
    },
    getDate(dateInput) {
      return formatDateToMonthDayYearHour(dateInput);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.event-page {
  margin: 0 75px;
  margin: 0 auto;

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
  }

  .back-container__icon-container {
    margin-right: .5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .event-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    max-width: 1000px;
  }

  .event-grid__image-container {
    width: 100%;
    // max-height: 30rem;
    min-height: 20rem;
    background-size: cover;
    position: relative;
    padding: 1rem;
  }

  .image-container__details-container {
    position: absolute;
    bottom: 1rem;
    display: flex;
    gap: 1rem;
  }

  .details-container__cal-container {
    min-width: 5rem;

    .cal-container__month-wrap {
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: bold;
      color: white;
      background: $atmo-pink--medium;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: .4rem;
      max-height: 2rem;
    }

    .cal-container__day-wrap {
      color: black;
      font-size: 3rem;
      font-weight: bold;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0px 0px 5px 5px;
      width: 100%;
      border: none;
    }
  }

  .details-container__title-container {
    width: 100%;
    font-size: 2.0rem;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .details-container__links-wrap {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .links-wrap__share-tickets-wrap {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-left: auto;

    img {
      height: 2rem;
    }

    span {
      padding: .5rem 1.5rem;
      border-radius: 20px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .share-tickets-wrap__share-link {
      cursor: pointer;
    }
  }

  .details-container__event-info-container {
    &__share-link {
      width: 2.5rem;
    }

    img {
      height: 1.2rem;
      color: white;
    }

    .image-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
    }

    .text-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .event-info-container__event-details-wrap {
      font-size: $atmo-subtitle-size;
      line-height: 1.3;
      margin-bottom: 1rem;
      font-weight: 500;
    }

    .event-info-container__event-date-wrap {
      // color: $atmo-purple--extra-dark;
      font-weight: 500;
      margin-bottom: .5rem;
      display: flex;
      margin-bottom: 1rem;
    }

    .event-info-container__event-location-wrap {
      // color: $atmo-purple--extra-dark;
      font-weight: 500;
      margin-bottom: .5rem;
      display: flex;
      margin-bottom: 1rem;
    }

    .event-info-container__event-price-wrap {
      font-weight: 500;
      display: flex;
    }
  }
}
</style>
