import Axios from 'axios';

export function callEventsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/atmo_events`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callEventsShow(eventId, queryParams = {}) {
    return Axios.get(`/api/v1/atmo_events/${eventId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callEventsUpdate(eventId, requestData, queryParams = {}) {
    return Axios({
        method: 'patch',
        url: `/api/v1/atmo_events/${eventId}`,
        data: requestData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callEventsCreate(requestData) {
    return Axios({
        method: 'post',
        url: `/api/v1/atmo_events`,
        data: requestData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });

}

export function callEventsDelete(eventId, queryParams = {}) {
    return Axios.delete(`/api/v1/atmo_events/${eventId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}