<template>
  <div class="atmo-loading" :class="`atmo-loading--${size}`">
    <img
      class="atmo-loading__center-disc"
      src="@/assets/images/icons/spinner-center.png"
      alt="Loading"
    >
    <img
      class="atmo-loading__outer-circle"
      src="@/assets/images/icons/spinner-circle.png"
      alt="Loading"
    >
  </div>
</template>

<script>
  export default {
    props: {
      size: {
        type: String,
        default: 'medium'
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .atmo-loading-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .atmo-loading {
    position: relative;
    height: 50px;

    &--small {
      height: 30px;
    }

    &--medium {
      height: 50px;
    }

    &--large {
      height: 75px;
    }

    &__center-disc {
      height: 66%;
    }

    &__outer-circle {
      position: absolute;
      top: -18%;
      left: -28%;
      bottom: 0;
      right: 0;
      height: 100%;
      animation-name: spin;
      animation-duration: 500ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
</style>
