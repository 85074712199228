import {
  callSongPlaysIndex,
  callSongPlaysCreate
} from '@/helpers/axiosCalls';

export default {
  async fetchSongPlays(songId) {
    try {
      const response = await callSongPlaysIndex(songId);
      return response.data;
    } catch (error) {
      console.error('Error fetching song play count:', error);
      return null;
    }
  },

  async createSongPlay(songId, userId) {
    try {
      // Call the API to create the song play, passing in the userId if available.
      await callSongPlaysCreate(songId, { user_id: userId });
      console.log('Song play created successfully.');
    } catch (error) {
      console.error('Error creating song play:', error);
    }
  }
};
