<template>
  <div class="hud-header-search">
    <div class="atmo-input-container">
      <input class="atmo-input atmo-input--search hud-header-search__input" type="text" v-model="search"
        placeholder="User Search" @input="onSearchInput" @click="showResults()" />
      <span v-if="resultsVisible" class="hud-header-search__hide-results-icon" @click="hideResults()">
        <img style="cursor:pointer" src="@/assets/images/icons/close--pink.png" />
      </span>
    </div>

    <div v-if="resultsVisible" class="hud-header-search__results">
      <perfect-scrollbar class="hud-search-scroll">
        <p v-if="noResults" class="no-results">Sorry, no results for {{ search }}</p>
        <div class="hud-header-search__result" v-for="(user, index) in results" :key="index">
          <router-link :to="getProfileRoute(user)">
            {{ user.name }}
          </router-link>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { useVueFuse } from 'vue-fuse';
import { callUserProfilesShow, callUsersShow, callUsersSearch } from '@/helpers/axiosCalls';

export default {
  data() {
    return {
      currentUserProfile: {},
      currentUser: {},
      resultsVisible: false,
      search: '',
      results: [],
      noResults: false,
    };
  },
  created() {
    this.getCurrentUser();
  },
  methods: {
    getUsers(query) {
      callUsersSearch({ query })
        .then((response) => {
          this.results = response.length ? response : [];
          this.noResults = !response.length;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCurrentUserProfile(profileId) {
      callUserProfilesShow(profileId)
        .then((response) => {
          this.currentUserProfile = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCurrentUser() {
      callUsersShow(this.$store.state.currentUserId)
        .then((response) => {
          this.currentUser = response;
          this.getCurrentUserProfile(this.currentUser.profile_id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showResults() {
      this.resultsVisible = true;
    },
    hideResults() {
      this.resultsVisible = false;
    },
    onSearchInput() {
      if (this.search) {
        this.getUsers(this.search);
      } else {
        this.results = [];
        this.noResults = true;
      }
    },
    getProfileRoute(user) {
      switch (user.profile_type) {
        case 'UserProfile':
          return { name: 'user_profiles.show', params: { userProfileId: user.profile_id } };
        case 'ArtistProfile':
          return { name: 'artist_profiles.show', params: { artistProfileId: user.profile_id } };
        case 'LabelProfile':
          return { name: 'label_profiles.show', params: { labelProfileId: user.profile_id } };
        default:
          return {};
      }
    }
  },
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.hud-header-search {
  position: relative;
  margin-left: 20px;

  &__input {
    width: 162px;
    cursor: text;
  }

  &__hide-results-icon {
    margin: 5px 0 0 5px;

    img {
      height: 15px;
    }
  }

  &__results {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: -6px;
    margin-top: 6px;
    width: 159px;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    z-index: 100;
    box-shadow: 1px 0 0 0 rgba(64, 54, 93, 0.15), 0 1px 5px 0 $atmo-purple--extra-dark;
    border-radius: 5px;
    padding: 5px 0;
  }

  &__result {
    color: white;
    font-size: 0.9em;
    padding: 5px 10px;

    &:hover {
      background-color: rgba($atmo-purple--light, 0.4);
    }
  }
}

.hud-search-scroll {
  max-height: 10rem;
}

.no-results {
  margin-left: .5rem;
  font-size: .7rem;
}
</style>
