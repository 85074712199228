import { callContestsShow, callContestPrizeWinnersIndex } from '@/helpers/axiosCalls';

export default {
  namespaced: true,
  state: {
    debug: false,
    contest: {},
    contestPrizeWinners: [],
  },
  mutations: {
    setContest(state, contest) {
      state.contest = contest;
    },
    setContestPrizeWinners(state, contestPrizeWinners) {
      state.contestPrizeWinners = contestPrizeWinners;
    },
  },
  actions: {
    async fetchContest({ commit }, contestId) {
      try {
        const contest = await callContestsShow(contestId);
        commit('setContest', contest);
      } catch (error) {
        console.error('Failed to fetch contest:', error);
      }
    },
    async fetchContestPrizeWinners({ commit }, contestId) {
      try {
        const contestPrizeWinners = await callContestPrizeWinnersIndex(contestId);
        commit('setContestPrizeWinners', contestPrizeWinners);
      } catch (error) {
        console.error('Failed to fetch contest prize winners:', error);
      }
    },
  },
  getters: {
    getContestPrizeWinnerById: (state) => (id) => {
      return state.contestPrizeWinners.find((prizeWinner) => prizeWinner.id === id);
    },
  },
};
