import Axios from 'axios';

export function callPlaylistsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/playlists`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPlaylistsSuggested(queryParams = {}) {
    return Axios.get(`/api/v1/playlists_suggested`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPlaylistsExplore(queryParams = {}) {
    return Axios.get(`/api/v1/playlists_explore`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPlaylistsNewReleases(queryParams = {}) {
    return Axios.get(`/api/v1/playlists_new_releases`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPlaylistsNewActivitiesMoods(queryParams = {}) {
    return Axios.get(`/api/v1/playlists_activities_moods`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPlaylistsShow(playlistId, queryParams = {}) {
    return Axios.get(`/api/v1/playlists/${playlistId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

// EXAMPLE PARAMS FOR callPlaylistsNewActivitiesMoods:
// params: {
//     moods: ['relaxing', 'chill'],
//     activities: ['workout'],
//     length: 5
//   }

export function callPlaylistsCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/playlists`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPlaylistsUpdate(userId, playlistId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/users/${userId}/playlists/${playlistId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPlaylistsDelete(userId, playlistId, queryParams = {}) {
    return Axios.delete(`/api/v1/users/${userId}/playlists/${playlistId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}