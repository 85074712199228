import Axios from 'axios';

export function callContestSubmissionsIndex(contestId, queryParams = {}) {
    return Axios.get(`/api/v1/contests/${contestId}/contest_submissions`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestSubmissionsShow(contestId, submissionId, queryParams = {}) {
    return Axios.get(`/api/v1/contests/${contestId}/contest_submissions/${submissionId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestSubmissionsCreate(contestId, requestData) {
    return Axios({
        method: 'post',
        url: `/api/v1/contests/${contestId}/contest_submissions`,
        data: requestData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestSubmissionsUpdate(contestId, submissionId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/contests/${contestId}/contest_submissions/${submissionId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestSubmissionsDelete(contestId, submissionId, queryParams = {}) {
    return Axios.delete(`/api/v1/contests/${contestId}/contest_submissions/${submissionId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
