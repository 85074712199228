import createChannel from '@/cable';

let callback; // declaring a variable that will hold a function later
let userFriendshipChannel = null;

function connectToUserFriendshipsChannel(userId) {
  userFriendshipChannel = createChannel('UserFriendshipsChannel', userId , {
    received(data) {
      console.log("RECEIVED BROADCAST IN FRIENDSHIPS")
      if (callback) callback.call(null, data);
    }
  });
}

// // Sending a message: Adjust the parameters based on what data you need to send
// function sendMessage(userId, friendId) {
//   console.log("sendMessage WORKING", userId, friendId)
//   userFriendshipChannel.perform('send_message', { 
//     user_id: userId, 
//     friend_id: friendId 
//   });
// }

// Getting a message: this callback will be invoked once we receive
// something over UserFriendshipsChannel
function setCallback(fn) {
  callback = fn;
}

export { connectToUserFriendshipsChannel, setCallback };
