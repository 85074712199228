<template>
  <div class="ops-donation">
    <div class="ops-donation__donation-amount text-center">
      {{ returnCurrencyFormat(amount) }}
    </div>
    <div class="ops-donation__donation-description">
      {{ description }}
    </div>
  </div>
</template>

<script>
// import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    // BRow,
    // BCol
  },
  props: {
    amount: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  methods: {
    returnCurrencyFormat(number) {
      // Create number formatter (Javascript - Internationalization API)
      let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        //These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501) // make validation to only make whole numbers
      });

      return formatter.format(number);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.ops-donation {
  &+& {
    margin-top: 1rem;
  }

  &__donation-amount {
    padding: 5px 4px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 5px;
    background-color: $atmo-purple--medium-dark--faded;
    margin-bottom: .5rem;
  }
}
</style>
