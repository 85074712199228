<template>
  <div>
    <h2 class="atmo-page-header atmo-store__header">
      AMI Resources New
    </h2>
    <div class="ami-container">
      <router-link :to="{ name: 'AMI.commands.index' }">
        Commands Index
      </router-link>
      <br>
      <br>

      <button
        @click="createCommand(
          commandType,
          commandFunction,
          parentId,
          sequenceType,
          sequenceOrder,
          amiInit,
          fx,
          nextLinkAcceptsLiteralSpeech,
          dynamicStringType,
          requiresConfirmation,
          expectingAnswer,
          expectedLiteralCommand,
          storeFunction,
          storeArgument,
          routeName,
          paramName,
          paramValue,
          questionId,
          literalQuestionId,
          commandFamily,
          desktop,
          smartSpeaker
        )"
      >
        Create Command
      </button>
      <br>
      <br>
      <label for="commandType">commandType</label>
      <select 
        v-model="commandType" 
        style="display:block; margin-bottom:10px;" 
        name="commandType"
      >
        <option value="static">
          static
        </option>
        <option value="dynamicMultiPart">
          dynamicMultiPart
        </option>
        <option value="dynamic">
          dynamic
        </option>
      </select>

      <label for="commandFunction">commandFunction</label>
      <input 
        v-model="commandFunction" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >

      <label for="parentId">parentId</label>
      <input 
        v-model="parentId" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >

      <label for="sequenceType">sequenceType</label>
      <select 
        v-model="sequenceType" 
        style="display:block; margin-bottom:10px;" 
        name="sequenceType"
      >
        <option value="standalone">
          standalone
        </option>
        <option value="strictSequence">
          strictSequence
        </option>
        <option value="utility">
          utility
        </option>
        <option value="prerequisite">
          prerequisite
        </option>
      </select>

      <label for="sequenceOrder">sequenceOrder</label>
      <select
        v-model="sequenceOrder"
        style="display:block; margin-bottom:10px;"
        name="sequenceOrder"
      >
        <option value="1">
          1
        </option>
        <option value="2">
          2
        </option>
        <option value="3">
          3
        </option>
        <option value="4">
          4
        </option>
        <option value="5">
          5
        </option>
      </select>

      <label for="amiInit">amiInit</label>
      <select 
        v-model="amiInit" 
        style="display:block; margin-bottom:10px;" 
        name="amiInit"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>

      <label for="fx">fx</label>
      <select 
        v-model="fx" 
        style="display:block; margin-bottom:10px;" 
        name="fx"
      >
        <option value="@/assets/sound_files/AMI_FX/AMI_success_short.mp3">
          @/assets/sound_files/AMI_FX/AMI_success_short.mp3
        </option>
        <option value="@/assets/sound_files/AMI_FX/AMI_init_short.mp3">
          @/assets/sound_files/AMI_FX/AMI_init_short.mp3
        </option>
        <option value="@/assets/sound_files/AMI_FX/AMI_deinit_short.mp3">
          @/assets/sound_files/AMI_FX/AMI_deinit_short.mp3
        </option>
        <option value="@/assets/sound_files/AMI_FX/AMI_mute_short.mp3">
          @/assets/sound_files/AMI_FX/AMI_mute_short.mp3
        </option>
        <option value="@/assets/sound_files/AMI_FX/AMI_unmute_short.mp3" />
        <option value="@/assets/sound_files/AMI_FX/AMI_tryagain_short.mp3">
          @/assets/sound_files/AMI_FX/AMI_tryagain_short.mp3
        </option>
      </select>

      <label for="nextLinkAcceptsLiteralSpeech">nextLinkAcceptsLiteralSpeech</label>
      <select
        v-model="nextLinkAcceptsLiteralSpeech"
        style="display:block; margin-bottom:10px;"
        name="nextLinkAcceptsLiteralSpeech"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>

      <label for="dynamicStringType">dynamicStringType</label>
      <select
        v-model="dynamicStringType"
        style="display:block; margin-bottom:10px;"
        name="dynamicStringType"
      >
        <option value="custom">
          custom
        </option>
        <option value="friend">
          friend
        </option>
        <option value="playlist">
          playlist
        </option>
      </select>

      <label for="requiresConfirmation">requiresConfirmation</label>
      <select
        v-model="requiresConfirmation"
        style="display:block; margin-bottom:10px;"
        name="requiresConfirmation"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>

      <label for="expectingAnswer">expectingAnswer</label>
      <select
        v-model="expectingAnswer"
        style="display:block; margin-bottom:10px;"
        name="expectingAnswer"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>

      <label for="expectedLiteralCommand">expectedLiteralCommand</label>
      <select
        v-model="expectedLiteralCommand"
        style="display:block; margin-bottom:10px;"
        name="expectedLiteralCommand"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>

      <label for="storeFunction">storeFunction</label>
      <input 
        v-model="storeFunction" 
        style="display:block; margin-bottom:10px;"
        type="text"
      >

      <label for="storeArgument">storeArgument</label>
      <input 
        v-model="storeArgument"
        style="display:block; margin-bottom:10px;" 
        type="text"
      >

      <label for="routeName">routeName</label>
      <input 
        v-model="routeName" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >

      <label for="paramName">paramName</label>
      <input 
        v-model="paramName" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >

      <label for="paramValue">paramValue</label>
      <input
        v-model="paramValue"
        style="display:block; margin-bottom:10px;" 
        type="text"
      >

      <label for="questionId">questionId</label>
      <input 
        v-model="questionId" 
        style="display:block; margin-bottom:10px;"
        type="text"
      >

      <label for="literalQuestionId">literalQuestionId</label>
      <input 
        v-model="literalQuestionId" 
        style="display:block; margin-bottom:10px;"
        type="text"
      >

      <label for="commandFamily">commandFamily</label>
      <select
        v-model="commandFamily"
        style="display:block; margin-bottom:10px;"
        name="commandFamily"
      >
        <option value="utility">
          utility
        </option>
        <option value="sequence1">
          sequence1
        </option>
        <option value="sequence2">
          sequence2
        </option>
        <option value="sequence3">
          sequence3
        </option>
        <option value="sequence4">
          sequence4
        </option>
        <option value="sequence5">
          sequence5
        </option>
      </select>

      <label for="desktop">desktop</label>
      <select 
        v-model="desktop" 
        style="display:block; margin-bottom:10px;" 
        name="desktop"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>

      <label for="smartSpeaker">smartSpeaker</label>
      <select 
        v-model="smartSpeaker"
        style="display:block; margin-bottom:10px;" 
        name="smartSpeaker"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>
    </div>
  </div>
</template>


<style lang="scss">
.ami-container {
  position: relative;
  height: 400px;
  width: 97%;
  margin: auto;
}
</style>

<script>
  import Axios from "axios";

  export default {
    data() {
      return {
        commandType: "static",
        commandFunction: "",
        parentId: null,
        sequenceType: "standalone",
        sequenceOrder: "1",
        amiInit: false,
        fx: "@/assets/sound_files/AMI_FX/AMI_success_short.mp3",
        nextLinkAcceptsLiteralSpeech: false,
        dynamicStringType: "custom",
        requiresConfirmation: false,
        expectingAnswer: false,
        expectedLiteralCommand: false,
        storeFunction: "",
        storeArgument: "",
        routeName: null,
        paramName: "",
        paramValue: "",
        questionId: null,
        literalQuestionId: null,
        commandFamily: "utility",
        desktop: true,
        smartSpeaker: true
      };
    },
    created() {},
    methods: {
      createCommand(
        commandType,
        commandFunction,
        parentId,
        sequenceType,
        sequenceOrder,
        amiInit,
        fx,
        nextLinkAcceptsLiteralSpeech,
        dynamicStringType,
        requiresConfirmation,
        expectingAnswer,
        expectedLiteralCommand,
        storeFunction,
        storeArgument,
        routeName,
        paramName,
        paramValue,
        questionId,
        literalQuestionId,
        commandFamily,
        desktop,
        smartSpeaker
      ) {
        let postBody = {
          command_type: commandType,
          command_function: commandFunction,
          parent_id: !parentId ? null : parseInt(parentId),
          sequence_type: sequenceType,
          sequence_order: !sequenceOrder ? null : parseInt(sequenceOrder),
          ami_init: Boolean(amiInit),
          fx: fx,
          next_link_accepts_literal_speech: Boolean(nextLinkAcceptsLiteralSpeech),
          dynamic_string_type: !dynamicStringType ? null : dynamicStringType,
          requires_confirmation: Boolean(requiresConfirmation),
          expecting_answer: Boolean(expectingAnswer),
          expected_literal_command: Boolean(expectedLiteralCommand),
          store_function: !storeFunction ? null : storeFunction,
          store_argument: !storeArgument ? null : storeArgument,
          route_name: !routeName ? null : routeName,
          param_name: !paramName ? null : paramName,
          param_value: !paramValue ? null : paramValue,
          question_id: !questionId ? null : parseInt(questionId),
          literal_question_id: !literalQuestionId
            ? null
            : parseInt(literalQuestionId),
          command_family: commandFamily,
          desktop: Boolean(desktop),
          smart_speaker: Boolean(smartSpeaker)
        };

        console.log("postBody", postBody);

        Axios.post(`/api/v1/ami_commands`, postBody)
          .then(response => {
            console.log(response);
            window.alert("Successfully saved");
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  };
</script>