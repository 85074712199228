<template>
    <MutualFriendList :profileType="profileType" :profileId="profileId" />
</template>

<script>
import { mapState } from 'vuex';
import MutualFriendList from '@/components/profiles/mutual-friend-list';

export default {
    components: { MutualFriendList },
    computed: {
    ...mapState('profiles', ['user']),
  },
  created() {
    // Fetch the profile if not already loaded
    if (!this.user || this.user.id !== this.profileId) {
      this.$store.dispatch('profiles/fetchProfile', { profileType: this.profileType, profileId: this.profileId });
    }
  }
};
</script>