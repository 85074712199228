<template>
  <div class="dashboard-purchases" data-cy="dashboard-purchases">
    <div class="header-container">
      <h2 class="atmo-page-header">
        Purchases
      </h2>
    </div>
    <router-link class="back-container"
      :to="{ name: 'artist_dashboards.show', params: { artistProfileId: artistProfileId } }">
      <div class="back-container__icon-container">
        <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
      </div>
      <div class="back-container__text-container">Back To Dashboard</div>
    </router-link>

    <div class="dashboard-purchases-grid-container">
      <div class="dashboard-purchases-grid-container__graph-container">

        <div class="graph-container__revenue-container">
          <div class="revenue-container__title-more-container">
            <div class="title-more-container__title-wrap">Purchase Data</div>
          </div>
          <div class="revenue-container__info-wrap">
            <p class="info-wrap__title">Gross Revenue</p>
            <p class="info-wrap__value">{{ getCurrency(artistDashboardData.revenue.gross_revenue) }}</p>
          </div>
          <div class="revenue-container__info-wrap">
            <p class="info-wrap__title">Net Revenue</p>
            <p class="info-wrap__value">{{ getCurrency(artistDashboardData.revenue.net_revenue) }}</p>
          </div>
          <div class="revenue-container__info-wrap">
            <p class="info-wrap__title"># Of Items Sold</p>
            <p class="info-wrap__value">{{ artistDashboardData.revenue.no_of_items_sold }}</p>
          </div>
          <router-link :to="{ name: 'products.index' }" class="view-store-link">View My Store</router-link>
        </div>
        <div class="graph-container__chart-container">
          <atmo-pie-chart id="productCountChart" type="pie" width="300" :options="purchaseCountOptions"
            :series="purchaseCountSeries" :labels="purchaseCountLabels" />
          <atmo-pie-chart id="purchaseAmountChart" type="pie" width="300" :options="purchaseAmountOptions"
            :series="purchaseAmountSeries" :labels="purchaseAmountLabels" />
        </div>
      </div>
      <div class="dashboard-purchases-grid-container__purchases-container">
        <div class="purchases-container__purchases-table-wrap">
          <div class="purchases-table-wrap__headings-container">
            <div class="headings-container__heading">Product</div>
            <div class="headings-container__heading">Amount</div>
            <div class="headings-container__heading">Date</div>
          </div>
          <perfect-scrollbar>
            <div v-for="(purchase, index) in artistDashboardData.product_sales" :key="index"
              :class="index % 2 == 0 ? 'purchases-table-wrap__row --dark' : 'purchases-table-wrap__row --light'">
              <div class="row__purchase-name">{{ purchase.product_name }}</div>
              <div class="row__purchase-amount">{{ getCurrency(purchase.price) }}</div>
              <div class="row__purchase-date">{{ getDate(purchase.date_of_sale) }}</div>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios'
import devMockData from '@/devUtils/mockData.js';
import AtmoPieChart from '@/components/charts/atmo-pie-chart';
import User from '@/models/user';
import { formatDateMMDDYYYY, formatCurrency } from '@/helpers/utilityFunctions';
import { callUsersShow } from '@/helpers/axiosCalls';

//https://apexcharts.com/vue-chart-demos/polar-area-charts/monochrome/

export default {
  components: {
    AtmoPieChart
  },
  data: function () {
    return {
      currentUserId: this.$store.state.currentUserId,
      artistProfile: {},
      user: {},
      artistProfileId: this.$route.params.artistProfileId,
      artistDashboardData: {},
      topLocations: [],
      venueNameArray: devMockData.venueNameArray,
      cityStateArray: devMockData.cityStateArray,
      artistImageArray: devMockData.artistImageArray,
      highResImageArray: devMockData.highResImageArray,
      songTitleArray: devMockData.songTitleArray,
      artistNameArray: devMockData.artistNameArray,
      artistMerchArray: devMockData.artistMerchArray,
      abbreviateStatesArray: devMockData.abbreviatedStatesArray,
      purchaseCountOptions: {
        legend: {
          // show: true,
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        fill: {
          // type: 'gradient',
          colors: ['#44aedc', '#d952a7'],
        },
        colors: ['#44aedc', '#d952a7'],
        // dataLabels: { style: { colors: ['#F44336', '#E91E63', '#9C27B0'] } },
        markers: { colors: ['#44aedc', '#d952a7',] },
        stroke: {
          width: 1,
          colors: ['#D8D8D8']
        },
        chart: {
          type: "pie",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              let stateObj = this.artistDashboardData.fan_state_data.find(obj => {
                return obj.state_abbreviation == config.w.config.labels[config.dataPointIndex];
              })

              let songPlayObj = this.artistDashboardData.song_plays_by_state.find(obj => {
                return obj.state_abbreviation == config.w.config.labels[config.dataPointIndex];
              })

              this.selectedStateData.state = stateObj.state_name;
              this.selectedStateData.abbreviation = config.w.config.labels[config.dataPointIndex];
              this.selectedStateData.fans = config.w.config.series[config.dataPointIndex];
              this.selectedStateData.fanPercentage = `${stateObj.percentage}`;
              this.selectedStateData.songPlays = songPlayObj?.count ? songPlayObj.count : 0;
              this.selectedStateData.cityName = stateObj.cities[0].city_name;
              this.selectedStateData.cityPercentage = stateObj.cities[0].percentage;
            }
          }
        },
        labels: []
      },
      purchaseCountSeries: [],
      purchaseCountLabels: [],
      purchaseAmountOptions: {
        legend: {
          // show: true,
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0
          },
          onItemClick: {
            toggleDataSeries: true
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        fill: {
          // type: 'gradient',
          colors: ['#44aedc', '#d952a7'],
        },
        colors: ['#44aedc', '#d952a7'],
        // dataLabels: { style: { colors: ['#F44336', '#E91E63', '#9C27B0'] } },
        markers: { colors: ['#44aedc', '#d952a7',] },
        stroke: {
          width: 1,
          colors: ['#D8D8D8']
        },
        chart: {
          type: "pie",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              let stateObj = this.artistDashboardData.fan_state_data.find(obj => {
                return obj.state_abbreviation == config.w.config.labels[config.dataPointIndex];
              })

              let songPlayObj = this.artistDashboardData.song_plays_by_state.find(obj => {
                return obj.state_abbreviation == config.w.config.labels[config.dataPointIndex];
              })

              this.selectedStateData.state = stateObj.state_name;
              this.selectedStateData.abbreviation = config.w.config.labels[config.dataPointIndex];
              this.selectedStateData.fans = config.w.config.series[config.dataPointIndex];
              this.selectedStateData.fanPercentage = `${stateObj.percentage}`;
              this.selectedStateData.songPlays = songPlayObj?.count ? songPlayObj.count : 0;
              this.selectedStateData.cityName = stateObj.cities[0].city_name;
              this.selectedStateData.cityPercentage = stateObj.cities[0].percentage;
            }
          }
        },
        labels: []
      },
      purchaseAmountSeries: [],
      purchaseAmountLabels: [],
      devMockData,
    }
  },
  created() {
    this.getArtistProfile();
    this.getDashboardData();
  },
  computed: {
    currentUser() {
      return this.$store.state.user;
    },
    isMyProfile() {
      return this.$store.state.currentUserProfileId === this.userProfileId;
    },
    userProfileId() {
      return Number(this.$route.params.artistProfileId);
    }
  },
  methods: {
    getCurrency(number) {
      return formatCurrency(number);
    },
    getDate(date) {
      return formatDateMMDDYYYY(date);
    },
    getUser(userId) {
      callUsersShow(userId)
        .then((response) => {
          this.user = new User(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getArtistProfile() {
      Axios.get(`/api/v1/artist_profiles/${this.$route.params.artistProfileId}`, {
      })
        .then((response) => {
          this.artistProfile = response.data;
          this.getUser(this.artistProfile.user.id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getPurchaseCountData() {
      for (let i = 0; i < this.artistDashboardData.counted_product_sales.length; i++) {
        this.purchaseCountLabels.push(this.artistDashboardData.counted_product_sales[i].product_name);
      }

      for (let i = 0; i < this.artistDashboardData.counted_product_sales.length; i++) {
        this.purchaseCountSeries.push(this.artistDashboardData.counted_product_sales[i].count);
      }

      this.purchaseCountOptions.labels = this.purchaseCountLabels;
    },
    getPurchaseAmountData() {
      for (let i = 0; i < this.artistDashboardData.sales_by_product.length; i++) {
        this.purchaseAmountLabels.push(this.artistDashboardData.sales_by_product[i].product_name);
      }

      for (let i = 0; i < this.artistDashboardData.sales_by_product.length; i++) {
        this.purchaseAmountSeries.push(this.artistDashboardData.sales_by_product[i].amount);
      }

      this.purchaseAmountOptions.labels = this.purchaseAmountLabels;
    },
    getDashboardData() {
      Axios.get(`/api/v1/artist_profiles/${this.$route.params.artistProfileId}/artist_dashboards`, {
      })
        .then((response) => {
          this.artistDashboardData = response.data;
          this.getPurchaseCountData();
          this.getPurchaseAmountData();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  }
}
</script>
    
<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.dashboard-purchases {
  margin: 0 75px;

  //https://github.com/mercs600/vue2-perfect-scrollbar
  .ps {
    max-height: 40vh;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
    color: white;
    // border: 1px solid white;
  }

  .back-container__icon-container {
    margin-right: .5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .dashboard-purchases-grid-container {
    // border: 1px solid white;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
  }

  .dashboard-purchases-grid-container__graph-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .revenue-container__title-more-container {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
    // align-items: center;

    .title-more-container__title-wrap {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  .graph-container__revenue-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .revenue-container__info-wrap {
      margin-bottom: 1.5rem;
    }

    .info-wrap__title {
      text-transform: uppercase;
      color: $atmo-gray--light--faded;
      margin-bottom: .5rem;
    }

    .info-wrap__value {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .view-store-link {
    text-transform: uppercase;
    color: rgba(216, 216, 216, 0.8);
    margin-bottom: 0.5rem;
  }

  .graph-container__chart-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .purchases-table-wrap__headings-container {
    display: flex;
  }

  .headings-container__heading {
    text-transform: uppercase;
    color: $atmo-gray--light--faded;
    font-weight: 600;
    font-size: 1rem;
    flex: 1;
    flex-basis: 0;
    padding: 1rem .5rem 1rem .5rem;
  }

  .dashboard-purchases-grid-container__purchases-container {}

  .purchases-container__purchases-table-wrap {
    margin-bottom: 1rem;
  }

  .--light {
    background: linear-gradient(to right, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0))
  }

  .purchases-table-wrap__row {
    display: flex;
    font-weight: 500;
    padding: .8rem 1rem .8rem 1rem;
    border-radius: 25px 0px 0px 25px;

    .row__purchase-name {
      flex: 1;
      flex-basis: 0;
      margin: 0rem 1rem 0rem 0rem;
    }

    .row__purchase-amount {
      flex: 1;
      flex-basis: 0;
    }

    .row__purchase-date {
      flex: 1;
      flex-basis: 0;
    }
  }
}
</style>