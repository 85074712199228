import Axios from 'axios';

export function callLikesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/likes`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callLikesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/likes`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callLikesDelete(likeId, queryParams = {}) {
    return Axios.delete(`/api/v1/likes/${likeId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
