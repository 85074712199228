<!-- <template>
  <atmo-loading v-if="isLoading" />
  <atmo-music-items
    v-else
    type="songs"
    :items-for-current-page="songs"
    :paginate="false"
    :columns="3"
  />
</template>

<script>
  import AtmoLoading from '@/components/atmo-loading';
  import AtmoMusicItems from '@/components/atmo-music-items';
  import { songsStub } from '../data';

  export default {
    components: { AtmoLoading, AtmoMusicItems },

    data() {
      return {
        isLoading: true,
        songs: null
      };
    },

    created() {
      this.getSongs();
    },

    methods: {
      getSongs() {
        // TODO: Use real songs
        this.songs = songsStub.filter(song => song.isSuggested);
        this.isLoading = false;
      }
    }
  }
</script> -->

<template>
  <div>
    <div v-if="songsLoading">LOADING</div>
    <atmo-music-songs-grid :songs="songs" />
  </div>
</template>

<script>
// import AtmoMusicItems from '@/components/atmo-music-items';
import PaginationMixin from '@/mixins/pagination';
import AtmoMusicSongsGrid from '@/components/music/atmo-music-songs-grid.vue';
import {
    callSongsSuggested
} from '@/helpers/axiosCalls';

export default {
  components: { AtmoMusicSongsGrid },
  mixins: [PaginationMixin],
  props: { showNewReleases: { type: Boolean, default: false } },
  data() {
    return {
      songs: null,
      songsLoading: true,
    };
  },
  computed: {
    fullCollection() {
      if (this.showNewReleases) {
        return this.songs.filter(song => song.isNew);
      } else {
        return this.songs;
      }
    }
  },
  created() {
    this.getSongs();
  },
  methods: {
    getSongs() {
      callSongsSuggested()
        .then((response) => {
          this.songs = response;
          console.log("this.songs: ", this.songs);
          this.songsLoading = false;
        }).catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>