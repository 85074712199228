import router from "./../routes";
import { getFeaturedImage } from '@/helpers/utilityFunctions';
import {
  callArtistProfilesShow,
  callOpsPagesIndex,
  callOpsPagesShow,
  callOpsPagesCreate,
  callOpsPagesUpdate,
  callOpsPagesDelete,
  callOpsPageDonationsCreate
} from '@/helpers/axiosCalls';

export default {
  namespaced: true,
  state: {
    debug: false,
    pages: [],
    currentPage: {},
    isLoaded: false,
    artist: {},
    donation: {}
  },
  mutations: {
    setOpsPages(state, pages) {
      state.pages = [...pages];
      state.isLoaded = true;
    },
    updateOpsPage(state, page) {
      console.log(page);
    },
    deleteOpsPage(state, id) {
      let index = state.pages.findIndex(page => page.id === id);
      state.pages.splice(index, 1);
    },
    setOpsPage(state, page) {
      state.currentPage = page;
    },
    setArtist(state, artist) {
      state.artist = {...artist};
    },
    setDonation(state, donation) {
      state.donation = donation;
    },
    setNotification(state, { data, artistId }) {
      //TODO artistId is not right here
      state.pages = state.pages.concat(data);
      router.push(`/artist_profiles/${artistId}/ops_pages`);
    }
  },
  actions: {
    async loadArtist({ commit }, artistId) {
      let response = await callArtistProfilesShow(artistId);

      let profilePicture = getFeaturedImage(response.user.images, 'thumb');
      let name = response.user.name;

      commit("setArtist", { profilePicture, artistId, name });
    },
    async loadOpsPages({ commit }) {
      let response = await callOpsPagesIndex();

      commit("setOpsPages", response);
    },
    async setPage({ state, commit }, pageId) {
      let page = await callOpsPagesShow(pageId);

      commit("setOpsPage", page);
    },
    setDonation({ commit }, donation) {
      commit("setDonation", donation);
    },
    submitPayment(state, payload) {
      // do stripe integration here
      console.log("Should send following to stripe", payload);

      router.push(`/artist_profiles/${payload.artistId}/ops_pages`);
    },
    async editPage({ commit }, { campaign, artistId }) {
      //GET REQUEST DATA
      // let updatedCampaign = await callOpsPagesUpdate(campaign.id, requestData)

      commit("updateOpsPage", { campaign });
      router.push(`/artist_profiles/${artistId}/ops_pages`);
    },
    async deletePage({ commit }, pageId) {
      if (!window.confirm(`Are you sure you want to delete campaign?`)) {
        return false;
      }

      await callOpsPagesDelete(pageId);

      // router.push(`/artist_profiles/${artistId}/ops_pages`);
      commit("deleteOpsPage", { pageId });
    },
    // async createCampaign(
    //   { commit },
    //   { campaign: { description, goal, title, donateOptions }, userId }
    // ) {

    //   let newCampaign = callOpsPagesCreate({
    //     goal,
    //     campaign_name: title,
    //     campaign_description: description,
    //     user_id: userId
    //   })

    //   let promises = donateOptions.map(({ amount, label }) => {
    //     return callOpsPageDonationsCreate(newCampaign.id,           {
    //       ops_page_id: newCampaign.id,
    //       description: label,
    //       amount
    //     })
    //   });

    //   await Promise.all(promises);

    //   // add redirect and notification that campaign has been added (will add in other PR)
    //   commit("setNotification", { userId, newCampaign });
    // }
  },
  getters: {
    currentPage: state => state.currentPage,
    isLoaded: state => state.isLoaded
  }
};
