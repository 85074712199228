<template>
  <div class="ami-command-docs">
    <div class="header-container">
      <h2 class="atmo-page-header">
        AMI Command Docs
      </h2>
    </div>
    <atmo-back-link></atmo-back-link>
    <perfect-scrollbar>
      <div class="ami-container">
        <p class="ami-instructions">
          In order to initialize interface, say "
          <strong>AMI</strong>."
        </p>
        <br>
        <p class="ami-instructions">
          Then say any of the following commands:
        </p>
        <br>
        <ul>
          <li class="ami-li">
            <span class="ami-blue">"stop listening."</span> : de-initializes AMI
          </li>
          <li class="ami-li">
            <span class="ami-blue">"mute yourself."</span> : mutes AMI audio
          </li>
          <li class="ami-li">
            <span class="ami-blue">"unmute yourself."</span> : unmutes AMI audio
          </li>
          <li class="ami-li">
            <span class="ami-blue">"speech mode."</span> : activates AMI speech responses
          </li>
          <li class="ami-li">
            <span class="ami-blue">"FX mode."</span> : activates AMI FX responses
          </li>
          <li class="ami-li">
            <span class="ami-blue">"say hello."</span> : plays AMI speech
          </li>
          <li class="ami-li">
            <span class="ami-blue">"can you hear me?"</span> : plays AMI speech
          </li>
          <li class="ami-li">
            <span class="ami-blue">"tell me about yourself."</span> : navigates user to commands page
          </li>
          <li class="ami-li">
            <span class="ami-blue">"refresh page."</span> : refreshes Atmosphere app (warning: you will lose data)
          </li>
          <li class="ami-li">
            <span class="ami-blue">"play library."</span> : plays Atmo queue
          </li>
          <li class="ami-li">
            <span class="ami-blue">"next song."</span> : skips to next song in Atmo Queue/playlist
          </li>
          <li class="ami-li">
            <span class="ami-blue">"previous song."</span> : skips to previous song in Atmo Queue/playlist
          </li>
          <li class="ami-li">
            <span class="ami-blue">"open library."</span> : opens Atmo library
          </li>
          <li class="ami-li">
            <span class="ami-blue">"close library."</span> : closes Atmo library
          </li>
          <li class="ami-li">
            <span class="ami-blue">"open queue."</span> : opens Atmo queue
          </li>
          <li class="ami-li">
            <span class="ami-blue">"close queue."</span> : closes Atmo queue
          </li>
          <li class="ami-li">
            <span class="ami-blue">"open live listening."</span> : opens live listening center
          </li>
          <li class="ami-li">
            <span class="ami-blue">"close live listening."</span> : closes live listening center
          </li>
          <li class="ami-li">
            <span class="ami-blue">"playlist view."</span> : displays playlist view in mini library
          </li>
          <li class="ami-li">
            <span class="ami-blue">"song view."</span> : displays songview in mini library
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me my profile."</span> : navigates user to profile page
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me store."</span> : navigates user to Atmo store
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me my dashboard."</span> : navigates user to Atmo dashboard
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me Atmosphere events."</span> : navigates user to Atmo events
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me Atmosphere artists."</span> : navigates user to Atmo artists
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me Atmosphere charts."</span> : navigates user to Atmo charts
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me Atmosphere channel."</span> : navigates user to Atmo channel
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me Atmosphere radio'."</span> : navigates user to Atmo radio
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me Atmosphere contests and giveaways'."</span> : navigates user to Atmo contests
            and giveaways
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me Atmosphere news'."</span> : navigates user to Atmo news
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me music."</span> : navigates user to Atmo Music
          </li>
          <li class="ami-li">
            <span class="ami-blue">"upgrade me."</span> : navigates user to Upgrade page
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me billing."</span> : navigates user to Billing page
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me my settings."</span> : navigates user to Settings page
          </li>
          <li class="ami-li">
            <span class="ami-blue">"edit my profile."</span> : navigates user to Edit Profile page
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me my purchases."</span> : navigates user to Purchases page
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me directory."</span> : navigates user to Atmo directory
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me my accomplishments."</span> : navigates user to Accomplishments
          </li>
          <li class="ami-li">
            <span class="ami-blue">"friend view."</span> : displays friend view in Atmo chat
          </li>
          <li class="ami-li">
            <span class="ami-blue">"chat with (friend name)."</span> : opens chat with selected friend
          </li>
          <li class="ami-li">
            <span class="ami-blue">"send chat message: (what you want to send)."</span> : sends spoken message to selected
            friend
          </li>
          <li class="ami-li">
            <span class="ami-blue">"play song: (name of song you want to play)."</span> : plays spoken song
          </li>
          <li class="ami-li">
            <span class="ami-blue">"select playlist: (name of playlist you want to select)."</span> : selects spoken
            playlist
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me my friends."</span> : navigates user to Friends page
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me my artist subscriptions."</span> : navigates user to Artist Subscription page
          </li>
          <!-- <li class="ami-li">
          <span class="ami-blue">
            "show me my billboard."
          </span> : navigates user to Billboard page
        </li>-->
          <li class="ami-li">
            <span class="ami-blue">"show me my broadcast."</span> : navigates user to Broadcast page
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me my playlist page."</span> : navigates user to Playlists page
          </li>
          <li class="ami-li">
            <span class="ami-blue">"save queue as playlist."</span> : saves Atmo queue as playlist
          </li>
          <li class="ami-li">
            <span class="ami-blue">"delete last playlist."</span> : deletes the last playlist created
          </li>
          <li class="ami-li">
            <span class="ami-blue">"add song (song name) to queue."</span> : adds spoken song to Atmo queue
          </li>
          <li class="ami-li">
            <span class="ami-blue">"log me out."</span> : logs user out of Atmosphere
          </li>
          <li class="ami-li">
            <span class="ami-blue">"play song [song_name] by artist [artist_name]"</span> : plays chosen song
          </li>
          <li class="ami-li">
            <span class="ami-blue">"grab song [song_name] by artist [artist_name]"</span> : selects chosen song
            <ul>
              <li class="ami-li-nested">
                <span class="ami-blue">"save song to playlist named [playlist_name]"</span> : saves grabbed song to
                playlist
                chosen
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"remove song from playlist named [playlist_name]"</span> : removes grabbed song
                from
                playlist chosen
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"share to friends billboard"</span> : shares grabbed song to friend's billboard
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"share to friend in message"</span> : shares grabbed song to friend's billboard
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"share song to my billboard"</span> : shares grabbed song to my billboard
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"play song"</span> : plays grabbed song
              </li>
              <li class="ami-li">
                <span class="ami-li-nested">"what are my options?"</span> : displays AMI command page
              </li>
              <li class="ami-li">
                <span class="ami-li-nested">"is this song in my library?"</span> : displays if song is in library or not.
                <ul>
                  <li class="ami-li-nested">
                    AMI: Do you want me to save it your library? (say "yes" or "no")
                  </li>
                </ul>
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"save song to library"</span> : saves grabbed song to library
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"remove song from library"</span> : removes grabbed song from library
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"add song to queue"</span> : adds grabbed song to queue
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"remove song from queue"</span> : removes grabbed song from queue
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"display grabbed song"</span> : displays grabbed song
              </li>
              <li class="ami-li-nested">
                <span class="ami-blue">"share song to friend named"</span> :shares grabbed song to spoken friend.
                <ul>
                  <li class="ami-li-nested">
                    AMI: How would you like to share this song (say, post to friend's billboard OR share to friend in
                    message)
                  </li>
                </ul>
              </li>
              <li class="ami-li">
                <span class="ami-blue">"What genre is this?"</span> : Displays genre of currently playing song
              </li>
              <!-- THESE DON'T EXIST YET -->
              <li class="ami-li">
                <span class="ami-blue">"Create me a playlist like this"</span> : Creates a playlist like the selected song
              </li>
              <li class="ami-li">
                <span class="ami-blue">"Show me this song's artist profile"</span> : Navigates user to artist profile of
                the
                selected song
              </li>
              <li class="ami-li">
                <span class="ami-blue">"Show me songs like this"</span> : Displays songs like the selected song
              </li>
              <li class="ami-li">
                <span class="ami-blue">"Show me artists like this"</span> : Displays artists like the selected song's
                artist
              </li>
              <!-- THESE DON'T EXIST YET -->
            </ul>
          </li>
          <li class="ami-li">
            <span class="ami-blue">"grab friend named"</span> :selects chosen friend
            <ul>
              <li class="ami-li">
                <span class="ami-blue">"post to friends billboard"</span> : creates post on grabbed friend's billboard.
                <ul>
                  <li class="ami-li-nested">
                    AMI: What you would like your post to say? (say, send post: [post_message])
                  </li>
                  <li class="ami-li-nested">
                    AMI: Would you like to include a song? (say, "yes" or "no")
                  </li>
                  <li class="ami-li-nested">
                    AMI: Which song would you like to include? (say, include song: [song_name] by: [artist_name])
                  </li>
                </ul>
              </li>
              <li class="ami-li">
                <span class="ami-blue">"show me friends profile"</span> : navigates to grabbed friend's profile
              </li>
            </ul>
          </li>
          <li class="ami-li">
            <span class="ami-blue">"create playlist named: [new_playlist_name]"</span> : creates playlist with spoken name
          </li>
          <li class="ami-li">
            <span class="ami-blue">"grab playlist named: [playlist_name]"</span> : grabs playlist spoken
            <ul>
              <li class="ami-li">
                <span class="ami-blue">"rename to: [new_playlist_name]"</span> : renames playlist with spoken name
              </li>
              <li class="ami-li">
                <span class="ami-blue">"delete playlist"</span> : deletes grabbed playlist
              </li>
              <li class="ami-li">
                <span class="ami-blue">"add collaborator to playlist"</span> : Adds a collaborator to selected playlist
                <ul>
                  <li class="ami-li-nested">
                    AMI: Which friend would you like to add to playlist as collaborator? (say, add friend named:
                    [friend_name])
                  </li>
                </ul>
              </li>
              <li class="ami-li">
                <span class="ami-blue">"remove collaborator from playlist"</span> : Removes a collaborator from selected
                playlist
                <ul>
                  <li class="ami-li-nested">
                    AMI: Which friend would you like to remove from playlist as collaborator (say, remove friend named:
                    [friend_name])
                  </li>
                </ul>
              </li>
              <li class="ami-li">
                <span class="ami-blue">"make a copy of playlist"</span> : Makes a copy of selected playlist
                <ul>
                  <li class="ami-li-nested">
                    AMI: What would you like to name the copied playlist? (say, name playlist: [playlist_name])
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me posts"</span> : Displays your broadcast by default
            <ul>
              <li class="ami-li">
                <span class="ami-blue">"show me my billboard"</span> : Displays posts on your billboard
              </li>
              <li class="ami-li">
                <span class="ami-blue">"filter posts"</span> : Prompts AMI to ask you how you would like to filter posts
              </li>
              <li class="ami-li">
                <span class="ami-blue">"filter by billboard"</span> : Filters post by selected friend's billboard
                <ul>
                  <li class="ami-li-nested">
                    AMI: Which friend's billboard you would you like to see? (say, billboard of: [friend_name])
                  </li>
                </ul>
              </li>
              <li class="ami-li">
                <span class="ami-blue">"filter by friend named [friend_name]:"</span> : Shows posts only by selected
                friend
              </li>
              <li class="ami-li">
                <span class="ami-blue">"remove filters"</span> : Removes all filters on posts, and displays your broadcast
                posts
              </li>
            </ul>
          </li>
          <li class="ami-li">
            <span class="ami-blue">"create post"</span> : Creates a post on your billboard
            <ul>
              <li class="ami-li-nested">
                AMI: What would you like your post to say? (say, say in post: [content]).
              </li>
              <li class="ami-li-nested">
                AMI: Would you like to include a song with your post? (say, include song: [song_name] by: [artist_name])
              </li>
            </ul>
          </li>
          <li class="ami-li">
            <span class="ami-blue">"grab album [album_name] by artist [artist name]"</span> : Grabs chosen album
            <ul>
              <li class="ami-li">
                <span class="ami-blue">"Add album to library"</span> : Adds songs in album to library
              </li>
              <li class="ami-li">
                <span class="ami-blue">"Add album to playlist named [playlist_name]"</span> : Adds songs in album to
                playlist
              </li>
              <li class="ami-li">
                <span class="ami-blue">"Share album to my billboard"</span> : Posts a link to album on your billboard
              </li>
            </ul>
          </li>
          <li class="ami-li">
            <span class="ami-blue">"Create a shared playlist named [playlist_name]"</span> : Creates a playlist with
            collaborators with spoken name.
            <ul>
              <li class="ami-li-nested">
                AMI: Which friend would you like to add to this playlist? (say friend name)
              </li>
              <li class="ami-li-nested">
                AMI: Would you like to add another (say yes or no)
              </li>
              <li class="ami-li-nested">
                AMI: Would you like me to add recommended songs to this playlist?
              </li>
            </ul>
          </li>
          <li class="ami-li">
            <span class="ami-blue">"Design a shared playlist named [playlist_name]"</span> : Designs a playlist with
            recommended songs based off of mutual favorite songs
            <ul>
              <li class="ami-li-nested">
                AMI: Which friend would you like to add to this playlist? (say friend name)
              </li>
              <li class="ami-li-nested">
                AMI: Would you like to add another (say yes or no)
              </li>
              <li class="ami-li-nested">
                AMI: Is this for a specific occasion?
              </li>
            </ul>
          </li>
          <li class="ami-li">
            <span class="ami-blue">"Design a playlist named [playlist_name]"</span> : Designs a playlist with recommended
            songs based off of answered questions
            <ul>
              <li class="ami-li-nested">
                AMI: have a genre in mind? (say genre or no)
              </li>
              <li class="ami-li-nested">
                AMI: Is this for a specific occasion (say occasion or no)
              </li>
              <li class="ami-li-nested">
                AMI: upbeat or slow or any tempo? (say upbeat, slow, or any)
              </li>
              <li class="ami-li-nested">
                AMI: upbeat or slow or any tempo? (say upbeat, slow, or any)
              </li>
              <!-- would you like to share this playlist? with which friends? > would you like me to make recommendations who would like this? -->
            </ul>
          </li>
          <li class="ami-li">
            <span class="ami-blue">"show me logs"</span> : displays active chain and session command log (administrator
            access only).
          </li>
          <li class="ami-li">
            <span class="ami-blue">"clar active chain"</span> : clears all commands in active chain (administrator access
            only).
          </li>
          <li class="ami-li">
            <span class="ami-blue">"nevermind"</span> : clears all commands in active chain.
          </li>
          <li class="ami-li">
            <span class="ami-blue">"what are my options?"</span> : displays all commands available to user at a certain
            time.
          </li>
          <li class="ami-li">
            <span class="ami-blue">"close options?"</span> : closes modal containing available commands.
          </li>
          <li class="ami-li">
            <span class="ami-blue">"open silent testing modal"</span> : displays a modal containing input for simulated
            speech testing.
          </li>
          <li class="ami-li">
            <span class="ami-blue">"close silent testing modal"</span> : closes a modal containing input for simulated
            speech testing.
          </li>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>
</template>



<script>
import AtmoBackLink from '@/components/atmo-back-link';

export default {
  components: {
    AtmoBackLink
  },

  data() {
    return {
      allCommands: []
    };
  },
  created() { },
  methods: {
  }
};
</script>


<!--- // other things:
// show me my billboard posts
// show me <friend_names>'s billboard -> post on billboard -> what should it say? -> include song? -> song by artist -> DONE
// show me my broadcast
  // filter broadcast by date -> which date?
  // filter broadcast by friend -> which friend?

// create billboard post -> what should it say? -> include song? -> song by artist -> DONE
// create broadcast post -> what should it say? -> include song? -> song by artist -> DONE

// grab other things:
// grab song [X]
// play song [x]
// is this song in my library -> yes/no -> would you like me to save it? [x]
// save song to library [x]
// what are my options? [x]
// add song to queue [x]
// remove song from queue [x]
// remove song from library [x]
// save song to playlist named.. [x]
// share song to friend ->
    //via message [x]
    //via billboard [x]
    //via live listening []
// share song to my billboard [x]

// grab friend [x]
  // display grabbed friend [x]
  // share song to billboard [x]
  // share song in message [x]
  // send chat message <message content> [x]
  // post on billboard [x]
  // go to their profile [x]
  // add as collaborator to playlist named []

// create playlist [x]

// grab playlist [x]
  // share to friend (in message) []
  // on your billboard [] (? need to build this out first)
  // on friend's billboard [] (? need to build this out first)
  // copy playlist [x] can use save as queue functionality
  // delete playlist [x]
  // edit playlist name [x]
  // add a collaborator [x]
  // remove a collaborator [x]

// display all broadcast posts [x]
// filter posts [x]
  // filter posts by friend name [x]
  // remove filters [x]
  // filter posts by friend billboard [x]
  // filter posts by date []
// create a post [x] -> need to ask which billboard it goes on (?)
  // with song [x]
  // without song [x]

// billboard []
  // display artist's billboard []
  // display friend's billboard []
  // post on artist's billboard []
  // post on friend's billboard []

// grab post [] HOW WOULD YOU DO THAT? (last post, or last post from friend name, then skip +1 or -1 beween them?)
  // comment on post []
  // like post []
  // dislike post []
  // share post? []

// artists []
  // navigate to Profile []
  // navigate to album index page []
  // navigate to album song page []
  // navigate to billboard page []
  // navigate to ops pages page []
  // navigate to playlists page []
  // navigate to artist store []
  // fan artist []
  // post on artist's billboard []
  // donate []
  // buy album []


// think about AMI notifying you of things (global event listener?)
// contiguous mode, AMI is always init -->



<!--

if (lowerCaseUserSpeech.includes('grab friend named')) {
  this.amiGrabFriendNamed(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('name playlist') && this.amiPlaylistCopyRequested) {
  this.amiCopyPlaylist(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('say in post') && this.amiCreatePostRequested) {
  this.amiSetPostContentForPosts(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('include song') && lowerCaseUserSpeech.includes('by artist') && this.amiCreatePostRequested && this.amiPostContentRecordedForPost) {
  this.amiSetPostSongForPost(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('filter by friend named') && this.$store.state.broadcastModalOpen) {
  this.amiFilterByFriendName(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('billboard of') && this.$store.state.broadcastModalOpen) {
  this.amiFilterByBillboard(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('remove friend named') && this.amiPlaylistCollaboratorRemovalRequested) {
  this.amiRemoveCollaboratorNamed(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('add friend named') && this.amiPlaylistCollaboratorAdditionRequested) {
  this.amiAddCollaboratorNamed(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('grab playlist named')) {
  this.amiGrabPlaylistNamed(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('rename to') && this.amiPlaylistGrabbed) {
  this.amiRenameGrabbedPlaylist(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('create playlist named')) {
  this.amiCreatePlaylistNamed(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('include song') && lowerCaseUserSpeech.includes('by artist') && this.amiGrabbedFriendPostContentRecorded) {
  this.amiSetPostSong(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('share song to friend named')) {
  this.amiShareSongToFriendNamed(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('send post')) {
  this.amiSetPostContent(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('save song to playlist named')) {
  this.amiSaveSongToPlaylistNamed(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('remove song from playlist named')) {
  this.amiRemoveSongFromPlaylistNamed(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('grab song') && lowerCaseUserSpeech.includes('by artist')) {
  this.amiGrabSongByArtist(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('play song') && lowerCaseUserSpeech.includes('by artist')) {
  this.amiPlaySongByArtist(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('chat with')) {
  this.amiOpenChat(lowerCaseUserSpeech);
}

else if (lowerCaseUserSpeech.includes('send chat message')) {
  this.amiSendChatMessage(lowerCaseUserSpeech);




-->
<style lang="scss">
.ami-command-docs {
  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1.5rem;
    margin-right: auto;
    color: white;
    margin-left: 1.3rem;
    // border: 1px solid white;
  }

  .back-container__icon-container {
    margin-right: .5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }


  .ami-instructions {
    font-size: 15px;
  }

  .ami-container {
    position: relative;
    height: 400px;
    width: 97%;
    margin: auto;
  }

  .ami-blue {
    color: #44aedc;
    font-weight: 500;
  }

  .ami-li {
    background-color: rgba(96,72,117, 0.4);
    backdrop-filter: blur(5px);
    margin-bottom: 2px;
    padding: 5px;
    border-radius: 5px;
  }

  .ami-li-nested {
    background-color: rgba(96,72,117, 0.4);
    backdrop-filter: blur(5px);
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
  }
}
</style>
