import Axios from 'axios';

export function callAlbumsIndex(artistProfileId, queryParams = {}) {
    return Axios.get(`/api/v1/artist_profiles/${artistProfileId}/albums`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAlbumsSuggested(queryParams = {}) {
    return Axios.get(`/api/v1/albums_suggested`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAlbumsShow(artistProfileId, albumId, queryParams = {}) {
    return Axios.get(`/api/v1/artist_profiles/${artistProfileId}/albums/${albumId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}


export function callAlbumsCreate(artistProfileId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/artist_profiles/${artistProfileId}/albums`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAlbumsDelete(artistProfileId, albumId, queryParams = {}) {
    return Axios.delete(`/api/v1/artist_profiles/${artistProfileId}/albums/${albumId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
