<template>
  <table class="atmo-table atmo-songs-table">
    <thead v-if="showHeadings" class="atmo-table__thead">
      <tr>
        <th class="atmo-table__th" />
        <th class="atmo-table__th atmo-table__th--stretch">Title</th>
        <th class="atmo-table__th">Time</th>
        <th class="atmo-table__th">Genre</th>
        <th v-if="showCommentsLinks" class="atmo-table__th">Comments</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(song, index) in songs" :key="index" :class="index % 2 !== 0 ? 'atmo-table__tr' : 'atmo-table__tr--light'" @click="$store.dispatch('player/prependAndPlaySong', song)">
        <td class="atmo-table__td atmo-table__td--action-arrow">
          <song-actions-popover :song="song" :actions="songActions" />
        </td>
        <td data-cy="table-row-song-name" class="atmo-table__td atmo-table__td--stretch">
          {{ song.name }}
        </td>
        <!-- TODO: real song length and genre -->
        <td class="atmo-table__td">
          {{ getSongDuration(song) }}
        </td>
        <td class="atmo-table__td">
          {{ song.song_profile.primary_genre.name }}
        </td>
        <td v-if="showCommentsLinks" class="atmo-table__td atmo-table__td--tight">
          <router-link :to="{ name: 'songs.show', params: { songId: song.id } }">
            <img class="atmo-songs-table__comment-icon" src="@/assets/images/icons/comment.png" alt="comments" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import SongActionsPopover from "@/components/song-actions-popover";
import { getSongDuration } from "@/helpers/utilityFunctions";

export default {
  components: { SongActionsPopover },

  props: {
    songs: {
      type: Array,
      default: null,
    },
    showHeadings: {
      type: Boolean,
      default: true,
    },
    showCommentsLinks: {
      type: Boolean,
      default: false,
    },
    songActions: {
      type: Array,
      default: () => ["share-song-as-post", "share-song-as-message", "add-to-queue", "add-to-playlist"],
    },
  },
  methods: {
    getSongDuration(song) {
      return getSongDuration(song);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-songs-table {
  width: 100%;

  &__comment-icon {
    height: 20px;
  }

  .atmo-table__tr {
    cursor: pointer;
  }

  .atmo-table__tr--light {
    cursor: pointer;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    border-radius: 5px;
  }
}
</style>
