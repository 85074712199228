import Axios from 'axios';

// export function callPostsIndex(userId, queryParams = {}) {
//     return Axios.get(`/api/v1/users/${userId}/posts`, { params: queryParams })
//         .then(response => {
//             return response.data;
//         })
//         .catch(error => {
//             throw error;
//         });
// }

export function callPostsShow(postId, queryParams = {}) {
    return Axios.get(`/api/v1/posts/${postId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPostsCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/posts`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPostsUpdate(userId, postId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/users/${userId}/posts/${postId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPostsDelete(userId, postId, queryParams = {}) {
    return Axios.delete(`/api/v1/users/${userId}/posts/${postId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callBillboardPostsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/billboard_posts`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callBroadcastPostsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/broadcast_posts`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}