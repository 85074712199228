import Axios from 'axios';

export function callOpsPageDonationsIndex(opsPageId, queryParams = {}) {
    return Axios.get(`/api/v1/ops_pages/${opsPageId}/ops_page_donations/`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callOpsPageDonationsShow(opsPageId, opsPageDonationId, queryParams = {}) {
    return Axios.get(`/api/v1/ops_pages/${opsPageId}/ops_page_donations/${opsPageDonationId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callOpsPageDonationsCreate(opsPageId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/ops_pages/${opsPageId}/ops_page_donations`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callOpsPageDonationsUpdate(opsPageId, opsPageDonationId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/ops_pages/${opsPageId}/ops_page_donations/${opsPageDonationId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callOpsPageDonationsDelete(opsPageId, opsPageDonationId, queryParams = {}) {
    return Axios.delete(`/api/v1/ops_pages/${opsPageId}/ops_page_donations/${opsPageDonationId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}