<template>
  <b-modal
    id="song-profile-modal"
    v-model="hasSong"
    title="Song Profile"
    modal-class="atmo-modal song-profile-modal"
    size="lg"
    hide-header
    hide-footer
    centered
    no-close-on-esc
    no-close-on-backdrop
  >
    <header class="atmo-modal__header song-profile-modal__header">
      <img
        class="song-profile-modal__hide-modal"
        src="@/assets/images/icons/close_popup.png"
        alt="close"
        title="Hide"
        @click="closeModal"
      >
      <h2>Song Information</h2>
      <span />
    </header>
    <div v-if="isLoading" class="song-profile-modal__loading-container">
      <atmo-loading />
    </div>
    <b-form
      v-else
      class="atmo-form song-profile-modal__form"
      autocomplete="off"
      @submit.prevent="submitForm"
    >
      <div class="song-profile-modal__form-header">
        <atmo-badge
          class="song-profile-modal__song-name-badge"
          :label="song.name || song.fileName"
        />
      </div>
      <div
        v-for="typeRow in [
          ['lyricsTheme', 'mood', 'occasion'],
          ['genre', 'timeSignature', 'songForm'],
          ['tempo', 'keySignature', 'tonality'],
          ['instrumentation', 'voxQualities']
        ]"
        :key="typeRow.toString()"
        class="atmo-form__wrapping-fields"
      >
        <b-form-group
          v-for="profileType in typeRow"
          :key="profileType"
          :class="{ 'song-profile-modal__vox-qualities-form-group': profileType === 'voxQualities' }"
        >
          <label class="song-profile-modal__label" :for="profileType">
            <span>{{ songProfileTypes[profileType].label }}</span>
            <atmo-popover
              v-if="songProfileTypes[profileType].infoText"
              class="song-profile-modal__popover"
              placement="right"
              triggers="hover"
            >
              <img
                slot="trigger"
                class="song-profile-modal__info-trigger"
                src="@/assets/images/icons/info.png"
                alt="info"
              >
              <div slot="content">
                {{ songProfileTypes[profileType].infoText }}
              </div>
            </atmo-popover>
            <atmo-toggle
              v-if="profileType === 'voxQualities'"
              class="song-profile-modal__toggle-switch"
              size="large"
              :on="currentlySelected.vocalsOn"
              :on-change="toggleVocals"
            />
          </label>
          <b-form-input
            v-if="songProfileTypes[profileType].type === 'number'"
            v-model="currentlySelected[profileType]"
            type="number"
            :placeholder="songProfileTypes[profileType].placeholder"
          />
          <div
            v-else-if="profileType === 'voxQualities'"
            class="song-profile-modal__vox-qualities"
          >
            <template v-if="currentlySelected.vocalsOn">
              <b-form-group
                v-for="(voxQuality, index) in currentlySelected.voxQualities"
                :key="index"
                class="song-profile-modal__vox-quality"
              >
                <multiselect
                  :value="voxQuality"
                  :options="songProfileOptions.voxQualities"
                  :searchable="true"
                  :show-labels="false"
                  label="name"
                  :close-on-select="true"
                  :placeholder="`Quality ${index + 1}`"
                  @select="onSelectVoxQuality($event, index)"
                />
              </b-form-group>
            </template>
          </div>
          <multiselect
            v-else
            :id="profileType"
            :key="profileType"
            v-model="currentlySelected[profileType]"
            :options="songProfileOptions[profileType]"
            :searchable="true"
            :show-labels="false"
            label="name"
            :close-on-select="true"
            :placeholder="songProfileTypes[profileType].placeholder"
          />
        </b-form-group>
      </div>
      <div class="song-profile-modal__control-buttons">
        <button
          type="reset"
          class="atmo-button atmo-button--tertiary"
          @click="cancelAndClose()"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="atmo-button atmo-button--primary"
        >
          Save
        </button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
  import AtmoBadge from '@/components/atmo-badge';
  import AtmoLoading from '@/components/atmo-loading';
  import AtmoPopover from '@/components/atmo-popover';
  import AtmoToggle from '@/components/atmo-toggle';
  import { BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
  import Multiselect from 'vue-multiselect';

  export default {
    components: {
      AtmoLoading,
      AtmoBadge,
      AtmoPopover,
      AtmoToggle,
      BForm,
      BFormGroup,
      BFormInput,
      Multiselect
    },

    props: {
      song: {
        type: Object,
        required: true
      },
      songProfileTypes: {
        type: Object,
        required: true
      },
      songProfileOptions: {
        type: Object,
        required: true
      },
      closeModal: {
        type: Function,
        required: true
      },
      updateSongProfile: {
        type: Function,
        required: true
      }
    },

    data() {
      return {
        isLoading: false,
        currentlySelected: this.initialCurrentlySelected()
      }
    },

    computed: {
      hasSong: {
        get: function () { return Boolean(this.song); },
        set: function (newValue) { return newValue; }
      },
      songProfile() {
        return this.song.songProfile;
      }
    },

    methods: {
      initialCurrentlySelected() {
        const songProfile = this.song.songProfile || {};
        const initialCurrentlySelected = { vocalsOn: true };

        Object.keys(this.songProfileTypes).forEach((typeKey) => {
          const songProfileType = this.songProfileTypes[typeKey];
          let currentlySelectedForType;
          if (typeKey === 'tempo') {
            currentlySelectedForType = songProfile.tempo;
          } else if (typeKey === 'voxQualities') {
            const voxProfile = songProfile.vox_profile;
            if (voxProfile && voxProfile.vox_qualities.length > 0) {
              currentlySelectedForType = [0, 1, 2].map((index) => {
                return voxProfile.vox_qualities[index] || null;
              });
            } else {
              currentlySelectedForType = [null, null, null];
              initialCurrentlySelected.vocalsOn = false;
            }
          } else {
            currentlySelectedForType = this.songProfileOptions[typeKey].find((option) => {
              return option.id === songProfile[songProfileType.identifier];
            })
          }

          initialCurrentlySelected[typeKey] = currentlySelectedForType;
        });
        if (!initialCurrentlySelected.voxQualities) {
          initialCurrentlySelected.voxQualities = {}
        }

        return initialCurrentlySelected;
      },
      onSelect(newValue, type) {
        this[`form_${type}`] = newValue;
      },
      onSelectVoxQuality(newValue, index) {
        this.currentlySelected.voxQualities[index] = newValue;
      },
      cancelAndClose() {
        this.currentlySelected = this.initialCurrentlySelected();
        this.closeModal();
      },
      toggleVocals() {
        this.currentlySelected.vocalsOn = !this.currentlySelected.vocalsOn;
      },
      async submitForm() {
        this.isLoading = true;
        const songProfileProps = {};
        Object.keys(this.currentlySelected).forEach((type) => {
          const currentlySelectedFor = this.currentlySelected[type];
          if (type === 'voxQualities') {
            const { vocalsOn, voxQualities } = this.currentlySelected;
            songProfileProps.vox_profile_attributes = this.serializeVoxProfile(
              { vocalsOn, voxQualities }
            );
          } else if (type !== 'vocalsOn') {
            const identifier = this.songProfileTypes[type].identifier;
            songProfileProps[identifier] = currentlySelectedFor && currentlySelectedFor.id;
          }
        });
        songProfileProps.tempo = Number(this.currentlySelected.tempo);
        await this.updateSongProfile(this.song, songProfileProps);
        this.isLoading = false;
        this.closeModal();
        return;
      },
      serializeVoxProfile({ vocalsOn, voxQualities }) {
        if (vocalsOn) {
          return { vox_quality_ids: voxQualities.map((voxQuality) => {
            return voxQuality ? voxQuality.id : null;
          }) }
        } else {
          return { vox_quality_ids: [] };
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .song-profile-modal {
    &__loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
    }

    .modal-content {
      padding: 20px;
    }

    &__header {
      width: 100%;
      font-size: 0.9em;
      margin: 0;
      padding-bottom: 20px;
    }

    &__hide-modal {
      cursor: pointer;
      height: 15px;
      width: 15px;
      opacity: 0.5;
    }

    &__song-name-badge {
      font-size: 1.2em;
    }

    &__form {
      display: flex;
      flex-direction: column;
    }

    &__label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    &__info-trigger {
      height: 14px;
      margin-left: 5px;
    }

    &__popover {
      .bs-popover-right .arrow:after {
        border-right-color: $atmo-purple--light;
      }

      .bs-popover-left .arrow:after {
        border-left-color: $atmo-purple--light;
      }

      .popover {
        background-color: $atmo-purple--light;
        color: $atmo-purple--extra-dark;
        font-size: 0.9em;
        font-weight: 500;
      }
    }

    &__toggle-switch {
      margin: -7px 8px;
    }

    &__vox-qualities-form-group {
      flex: 1.5;
    }

    $vox-quality-gap: 3px;

    &__vox-qualities {
      display: flex;
      justify-content: space-between;
      margin: 0 (-$vox-quality-gap);

      .multiselect {
        margin-top: 0
      }
    }

    &__vox-quality {
      flex: 1;
      margin: 0 $vox-quality-gap;
    }

    &__control-buttons {
      display: flex;
      margin-left: auto;

      :first-child {
        margin-right: 10px;
      }
    }
  }
</style>
