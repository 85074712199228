import createChannel from '@/cable';

let callback; // declaring a variable that will hold a function later
let relationshipsChannel = null;

function connectToRelationshipsChannel(userId) {
  relationshipsChannel = createChannel('RelationshipsChannel', userId , {
    received(data) {
      console.log("RECEIVED BROADCAST IN RELATIONSHIPS")
      if (callback) callback.call(null, data);
    }
  });
}

// // Sending a message: Adjust the parameters based on what data you need to send
// function sendMessage(userId, friendId) {
//   console.log("sendMessage WORKING", userId, friendId)
//   relationshipsChannel.perform('send_message', { 
//     user_id: userId, 
//     friend_id: friendId 
//   });
// }

// Getting a message: this callback will be invoked once we receive
// something over RelationshipsChannel
function setCallback(fn) {
  callback = fn;
}

export { connectToRelationshipsChannel, setCallback };
