<template>
    <div class="user-profile-videos-container">
        <div class="header-container">
            <h2 class="atmo-page-header">Videos</h2>
        </div>
        <atmo-back-link></atmo-back-link>
        <atmo-profiles-videos-grid :videoArray="userVideos" />
    </div>
</template>

<script>
import AtmoProfilesVideosGrid from '@/components/profiles/atmo-profiles-videos-grid.vue';
import AtmoBackLink from '@/components/atmo-back-link';
import { mapGetters, mapActions } from 'vuex';

export default {
    components: {
        AtmoProfilesVideosGrid,
        AtmoBackLink
    },
    computed: {
        ...mapGetters('profiles', {
            userVideos: 'getUserVideos'
        }),
        userId() {
            return this.$route.params.userId;
        }
    },
    created() {
        this.fetchUserVideos(this.userId);
    },
    methods: {
        ...mapActions('profiles', ['fetchUserVideos'])
    }
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.user-profile-videos-container {
    margin: 0 75px;

    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .back-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        max-width: 10rem;
        margin-bottom: 1rem;
    }

    .back-container__icon-container {
        margin-right: .5rem;
    }

    .icon-container__back-icon {
        height: 1rem;
    }
}
</style>
