import Axios from 'axios';

export function callGenresIndex(queryParams = {}) {
    return Axios.get(`/api/v1/genres`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callGenresShow(genreId, queryParams = {}) {
    return Axios.get(`/api/v1/genres/${genreId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}