import createChannel from '@/cable';

let callback; // declaring a variable that will hold a function later
let chatRoomUserRequests = null;

function connectToChatRoomUserRequestsChannel(roomId) {
  chatRoomUserRequests = createChannel('ChatRoomUserRequestsChannel', roomId , {
    received(data) {
      console.log("RECEIVED BROADCAST IN CHAT ROOM USER REQUESTS")
      if (callback) callback.call(null, data);
    }
  });
}

// Getting a message: this callback will be invoked once we receive
// something over ChatRoomUserRequestsChannel
function setCallback(fn) {
  callback = fn;
}

export { connectToChatRoomUserRequestsChannel, setCallback };
