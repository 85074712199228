<template>
  <b-modal
    id="concerts-filter"
    ref="concertsFilterRef"
    title="Filter"
    modal-class="atmo-modal concerts-filter"
    no-close-on-backdrop
    hide-header
    hide-footer
    centered
    @show="setInitialFormProps"
  >
  <img
        class="atmo-modal__close"
        src="@/assets/images/icons/close_popup.png"
        alt="close"
        title="Hide Filter"
        @click="$refs.concertsFilterRef.hide()"
      >
    <header class="atmo-modal__header">
      <h2 class="concerts-filter__h2">
        Filter By:
      </h2>
    </header>
    <b-form
      class="atmo-form concerts-filter__form"
      @submit="submitForm"
    >
      <b-form-radio-group
        id="radios2"
        v-model="formSelectedFilterType"
        :class="'concerts-filter__radio-group'"
        stacked
        name="radioSubComponent"
      >
        <div
          class="atmo-form__row"
          :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'location' }"
        >
          <div class="atmo-form__radio-container">
            <b-form-radio value="location" />
          </div>
          <multiselect
            v-model="formSelectedState"
            :options="stateOptions"
            :searchable="false"
            :show-labels="false"
            :close-on-select="true"
            placeholder="State"
            @select="onSelectState"
            @open="setFilterType('location')"
          />
          <multiselect
            v-model="formSelectedCity"
            :class="'atmo-form__multiselect--not-first-child'"
            :disabled="!formSelectedState"
            :options="cityOptions"
            :searchable="false"
            :show-labels="false"
            :close-on-select="true"
            placeholder="City"
            @open="setFilterType('location')"
          />
        </div>
        <div class="atmo-form__row">
          <div class="atmo-form__radio-container" />
          <hr class="atmo-form__hr">
        </div>
        <div
          class="atmo-form__row"
          :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'artist' }"
        >
          <div class="atmo-form__radio-container">
            <b-form-radio value="artist" />
          </div>
          <multiselect
            v-model="formSelectedArtist"
            :options="artistOptions"
            label="name"
            :searchable="true"
            :show-labels="false"
            :close-on-select="true"
            placeholder="Type an artist"
            @open="setFilterType('artist')"
          >
            <span slot="noResult">
              No Artists found.
            </span>
          </multiselect>
        </div>
        <div class="atmo-form__row">
          <div class="atmo-form__radio-container" />
          <hr class="atmo-form__hr">
        </div>
        <div
          class="atmo-form__row"
          :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'date' }"
        >
          <div class="atmo-form__radio-container">
            <b-form-radio value="date" />
          </div>
          <div class="datepicker-trigger atmo-datepicker">
            <input
              id="datepicker-trigger"
              type="text"
              placeholder="Select dates"
              :value="formSelectedDates"
              @click="toggleDatepicker"
            >

            <airbnb-style-datepicker
              :trigger-element-id="'datepicker-trigger'"
              :mode="'range'"
              :fullscreen-mobile="true"
              :date-one="formSelectedStartDate"
              :date-two="formSelectedEndDate"
              :show-shortcuts-menu-trigger="false"
              @date-one-selected="val => { formSelectedStartDate = val }"
              @date-two-selected="val => { formSelectedEndDate = val }"
            />
          </div>
        </div>
      </b-form-radio-group>

      <button
        type="submit"
        :disabled="!formSelectedFilterValue"
        class="
          atmo-button
          atmo-button--primary
          atmo-button--stretch
        "
      >
        Search Now
      </button>
    </b-form>
  </b-modal>
</template>

<script>
  import { BForm, BFormRadioGroup, BFormRadio } from 'bootstrap-vue';
  import Multiselect from 'vue-multiselect';

  export default {
    components: {
      BForm,
      BFormRadioGroup,
      BFormRadio,
      Multiselect
    },

    props: {
      selectedFilterType: { type: String, required: true },
      statesAndCities: { type: Object, required: true },
      artists: { type: Array, required: true },
      selectedState: { type: String, default: null },
      selectedCity: { type: String, default: null },
      selectedArtist: { type: Object, default: null },
      selectedStartDate: { type: String, default: null },
      selectedEndDate: { type: String, default: null },
      formatDates: { type: Function, required: true },
      onSubmit: { type: Function, required: true }
    },

    data() {
      return {
        formSelectedStartDate: '',
        formSelectedEndDate: '',
        formSelectedFilterType: 'location',
        formSelectedState: null,
        formSelectedCity: null,
        formSelectedArtist: null
      }
    },

    computed: {
      formSelectedFilterValue() {
        // will be null if there is no filter selected
        switch (this.formSelectedFilterType) {
        case 'location':
          return this.formSelectedState;
        case 'artist':
          return this.formSelectedArtist;
        case 'date':
          return this.formSelectedDates;
        default:
          return null;
        }
      },
      stateOptions() {
        return Object.keys(this.statesAndCities);
      },
      cityOptions() {
        return this.statesAndCities[this.formSelectedState] || [];
      },
      artistOptions() {
        return this.artists;
      },
      formSelectedDates() {
        return this.formatDates(
          this.formSelectedStartDate,
          this.formSelectedEndDate
        );
      }
    },

    methods: {
      setInitialFormProps() {
        // Set the form props to the filter props passed in
        this.formSelectedFilterType = this.selectedFilterType;
        this.formSelectedState = this.selectedState;
        this.formSelectedCity = this.selectedCity;
        this.formSelectedArtist = this.selectedArtist;
        this.formSelectedStartDate = this.selectedStartDate;
        this.formSelectedEndDate = this.selectedEndDate;
      },
      setFilterType(newFilterType) {
        this.formSelectedFilterType = newFilterType;
        this.cancelAndCloseDatepicker();
      },
      cancelAndCloseDatepicker() {
        document.querySelector('.asd__action-buttons button').click()
      },
      onSelectState(newState) {
        const original = this.formSelectedState;
        this.formSelectedState = newState;
        if (original !== newState) {
          this.formSelectedCity = 'All Cities';
        }      // position: absolute;
      },
      toggleDatepicker() {
        this.formSelectedFilterType = 'date';
      },
      submitForm(evt) {
        evt.preventDefault();
        this.onSubmit({
          filterType: this.formSelectedFilterType,
          state: this.formSelectedState,
          city: this.formSelectedCity,
          artist: this.formSelectedArtist,
          startDate: this.formSelectedStartDate,
          endDate: this.formSelectedEndDate
        });
        this.$refs.concertsFilterRef.hide();
      }

    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .concerts-filter {
    // padding: 1rem;

    .modal-content {
      padding: 1rem;
    }

    .atmo-modal__header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
    }

    &__h2 {
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 600;
    }

    &__form {
      margin: 0 20px 25px;
    }

    &__radio-group {
      margin-bottom: 50px;
    }

    .multiselect {
      flex: 1;
    }

    .atmo-modal__close {
      position: absolute;
      cursor: pointer;
      height: 1rem;
    }

    .atmo-form__row--blurred .asd__wrapper {
      display: none;
    }
  }
</style>
