import Axios from 'axios';

export function callChatRoomUserRequestsCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/chat_room_user_requests`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callChatRoomUserRequestsUpdate(requestId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/chat_room_user_requests/${requestId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callChatRoomUserRequestsDelete(requestId, queryParams = {}) {
    return Axios.delete(`/api/v1/chat_room_user_requests/${requestId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}