import Axios from 'axios';

export function callUserAccomplishmentsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/user_accomplishments`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserAccomplishmentsShow(userId, accomplishmentId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/user_accomplishments/${accomplishmentId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserAccomplishmentsCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/user_accomplishments`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}