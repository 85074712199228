import Axios from 'axios';

export function callEventTicketsIndex(eventId, queryParams = {}) {
    return Axios.get(`/api/v1/atmo_events/${eventId}/atmo_event_tickets`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callEventTicketsCreate(eventId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/atmo_events/${eventId}/atmo_event_tickets`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callEventTicketsUpdate(eventId, ticketId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/atmo_events/${eventId}/atmo_event_tickets/${ticketId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callEventTicketsDelete(eventId, ticketId, queryParams = {}) {
    return Axios.delete(`/api/v1/atmo_events/${eventId}/atmo_event_tickets/${ticketId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
