<template>
    <div>
        <video ref="videoPlayer" class="video-js modal-video"></video>
    </div>
</template>

<script>
import videojs from 'video.js';

export default {
    name: 'VideoPlayer',
    props: {
        options: {
            type: Object,
            default() {
                return {
                    sources: [
                        {
                            src: '', // Provide a default value to avoid undefined
                            type: 'video/mp4'
                        }
                    ],
                    autoplay: false,
                    controls: true,
                    height: 295,
                    width: 420
                };
            }
        }
    },
    data() {
        return {
            player: null
        }
    },
    mounted() {
        if (this.options.sources && this.options.sources.length > 0 && this.options.sources[0].src) {
            this.player = videojs(this.$refs.videoPlayer, this.options, () => {
                console.log("VideoJS player initialized.");
                this.player.src(this.options.sources); // Make sure the player source is set here
            });
            this.showHidePlayerControls();
        } else {
            console.error('Video source is missing or invalid.');
        }
    },
    watch: {
        options: {
            deep: true,
            handler(newOptions) {
                console.log("Options changed", newOptions); // Add this to verify
                if (newOptions.sources && newOptions.sources[0].src) {
                    this.player.src(newOptions.sources); // Update the video source dynamically
                    this.player.play(); // Play the video
                }
            }
        }
    },
    methods: {
        showHidePlayerControls() {
            this.player = videojs(this.$refs.videoPlayer, this.options, () => {
                this.player.log('onPlayerReady', this);
            });

            const modalVideo = document.querySelector(".vjs-tech");
            console.log("listening for video end in modal player", modalVideo);

            modalVideo.onplay = (event) => {
                console.log(
                    "Modal video now playing."
                );
                this.$store.commit("videoPlayer/setModalOrBackgroundVidPlaying", true);
            };

            modalVideo.onended = (event) => {
                console.log(
                    "Modal video stopped either because it has finished playing or no further data is available."
                );
                this.$store.commit("videoPlayer/setModalOrBackgroundVidPlaying", false);
            };
        }
    }
    //REMOVING FOR THE MINIMIZED VIDEOS
    // beforeDestroy() {
    //     if (this.player) {
    //         this.player.dispose();
    //     }
    // }
}
</script>