<template>
  <div class="atmo-checkout">
    <h2 class="atmo-page-header atmo-checkout__header">
      Checkout
    </h2>
    <div class="atmo-checkout__body">
      <div class="atmo-checkout__main-column">
        <section class="atmo-checkout__cart-attributes">
          <div class="atmo-checkout__cart-attribute">
            <span class="atmo-checkout__cart-attribute-label">
              Number of Items
            </span>
            <span class="atmo-checkout__cart-attribute-value">
              {{ cartCount }}
            </span>
          </div>
          <div class="atmo-checkout__cart-attribute">
            <span class="atmo-checkout__cart-attribute-label">
              Tax({{ cartTaxRate }}%)
            </span>
            <span class="atmo-checkout__cart-attribute-value">
              {{ returnCurrencyFormat(cartTaxTotal) }}
            </span>
          </div>
          <div class="atmo-checkout__cart-attribute">
            <span class="atmo-checkout__cart-attribute-label">
              Shipping
            </span>
            <span class="atmo-checkout__cart-attribute-value">
              {{ returnCurrencyFormat(cartShipping) }}
            </span>
          </div>
          <div class="atmo-checkout__cart-attribute atmo-checkout__cart-attribute--total">
            <span class="atmo-checkout__cart-attribute-label">
              Total
            </span>
            <span class="atmo-checkout__cart-attribute-value">
              {{ returnCurrencyFormat(cartTotalCost) }}
            </span>
          </div>
          <div class="atmo-checkout__cart-attribute atmo-checkout__cart-attribute--overall-total">
            <span class="atmo-checkout__cart-attribute-label">
              Overall Total
            </span>
            <span class="atmo-checkout__cart-attribute-value">
              {{ returnCurrencyFormat(overallTotal) }}
            </span>
          </div>
        </section>
        <div class="atmo-checkout__atmo-points">
          <div class="atmo-checkout__atmo-points-actions">
            <span>Have Atmo Points?</span>
            <button class="
                atmo-button
                atmo-button--secondary
                atmo-checkout__atmo-points-redemption-button
              " @click="redeemAtmoPoints">
              Redeem Now
            </button>
          </div>
          <div v-if="atmoPointsActive" class="atmo-checkout__atmo-points-redemption">
            <span>(2500 Points) available</span>
            <input v-model="numberOfPoints" type="number" placeholder="# points"
              class="atmo-input atmo-checkout__input atmo-checkout__input--points">
            <span class="atmo-checkout__equals">
              =
            </span>
            <div class="atmo-checkout__point-value">
              <span v-if="numberOfPoints > 0">
                {{ returnCurrencyFormat(computedPointValue) }}
              </span>
              <span v-else>
                $ USD
              </span>
            </div>
            <button class="
                atmo-button
                atmo-button--secondary
                atmo-checkout__apply-points-button
              " @click="applyAtmoPoints">
              Apply
            </button>
          </div>
        </div>
        <div class="atmo-checkout__action-buttons">
          <button class="atmo-button atmo-button--secondary" @click="cancel">
            Cancel
          </button>
          <button class="atmo-button atmo-button--primary" @click="submit">
            Proceed
          </button>
        </div>
      </div>
      <aside class="atmo-checkout__sidebar">
        <div class="credit-card-inputs" :class="{ complete }">
          <div class="atmo-checkout__input-row">
            <card-number ref="cardNumber" class="atmo-input atmo-checkout__input" :stripe="stripeKey"
              :options="cardNumberInputOptions" @change="onCardNumberChange" />
            <div class="atmo-checkout__card-brand">
              <!-- <i class="pf pf-credit-card" id="brand-icon"></i> -->
              {{ brand }}
            </div>
          </div>
          <div class="atmo-checkout__input-row">
            <label for="atmo-checkout__input--expiry" class="atmo-checkout__inline-label">
              Expiry Date
            </label>
            <card-expiry id="atmo-checkout__input atmo-checkout__input--expiry" ref="cardExpiry"
              class="atmo-checkout__input atmo-checkout__input--purple" :stripe="stripeKey" :options="inputOptions"
              @change="expiry = $event.complete" />
            <card-cvc ref="cardCvc" class="atmo-checkout__input card-cvc" :stripe="stripeKey" :options="inputOptions"
              @change="cvc = $event.complete" />
          </div>
        </div>
        <form>
          <div class="atmo-checkout__form-section-header">
            Shipping Address
          </div>
          <div class="atmo-checkout__input-row">
            <input v-model="firstName" placeholder="First Name*" class="atmo-input atmo-checkout__input">
            <input v-model="lastName" placeholder="Last Name*" class="atmo-input atmo-checkout__input">
          </div>
          <div class="atmo-checkout__input-row">
            <input v-model="addressLine1" placeholder="Address Line 1*" class="atmo-input atmo-checkout__input">
          </div>
          <div class="atmo-checkout__input-row">
            <input v-model="addressLine2" placeholder="Address Line 2*" class="atmo-input atmo-checkout__input">
          </div>
          <div class="atmo-checkout__input-row">
            <input v-model="country" placeholder="Country*"
              class="atmo-input atmo-checkout__input atmo-checkout__input--purple">
            <input v-model="state" placeholder="State*" class="atmo-input atmo-checkout__input">
          </div>
          <div class="atmo-checkout__input-row">
            <input v-model="city" placeholder="City*" class="atmo-input atmo-checkout__input">
            <input v-model="zipCode" placeholder="Zip Code*" class="atmo-input atmo-checkout__input">
          </div>
          <div class="atmo-checkout__input-row">
            <input v-model="phoneNumber" placeholder="Phone Number*" class="atmo-input atmo-checkout__input">
          </div>
        </form>
      </aside>
    </div>
  </div>
</template>

<script>
// import { stripeKey, stripeOptions } from './stripeConfig.json'
import { CardNumber, CardExpiry, CardCvc } from 'vue-stripe-elements-plus';
import { mapActions } from 'vuex';

// $atmo-gray--light
const atmoGrayLight = '#D8D8D8';

const inputOptions = {
  style: {
    base: {
      color: 'white',
      fontSize: '13px',
      fontWeight: '300',

      '::placeholder': {
        fontSize: '13px',
        fontWeight: '300',
        color: atmoGrayLight,
      },
    },
    invalid: {
      color: 'red'
    }
  }
};

const cardNumberInputOptions = Object.assign({}, inputOptions, {
  placeholder: 'Card Number',
  iconStyle: 'solid',
  hideIcon: false
});

export default {

  components: { CardNumber, CardExpiry, CardCvc },
  data() {
    // TODO: switch over to a Vue cart
    const angularRootScope = window.angularRootScope;
    return {
      atmoPointsActive: false,
      atmoPointsApplied: false,
      numberOfPoints: null,
      pointValue: 500,
      appliedPointValue: 0,
      stripeKey: 'pk_test_UkAcQat2PhllsZTtEnv5Zvqw',
      cardNumberInputOptions,
      inputOptions,
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      brand: 'unknown',
      firstName: '',
      lastName: '',
      addressLine1: '',
      addressLine2: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      phoneNumber: '',
      cartCount: angularRootScope.cartCount,
      cartTotalCost: angularRootScope.cartTotalCost,
      cartItems: angularRootScope.cartItems,
      cartTaxRate: angularRootScope.cartTaxRate,
      cartTaxTotal: angularRootScope.cartTaxTotal,
      cartShipping: angularRootScope.cartShipping
    }
  },
  computed: {
    computedPointValue: function () {
      return this.numberOfPoints / this.pointValue;
    },
    overallTotal: function () {
      return this.cartTotalCost - this.appliedPointValue;
    }
  },
  watch: {
    number() { this.update() },
    expiry() { this.update() },
    cvc() { this.update() }
  },

  methods: {
    ...mapActions('accomplishments', ['createUserAccomplishments']),
    returnCurrencyFormat(number) {
      // Create number formatter (Javascript - Internationalization API)
      let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        //These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number);
    },
    update() {
      this.complete = this.number && this.expiry && this.cvc

      // field completed, find field to focus next
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus()
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus()
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus()
        } else if (!this.number) {
          this.$refs.cardNumber.focus()
        }
      }
      // no focus magic for the CVC field as it gets complete with three
      // numbers, but can also have four
    },
    onCardNumberChange(evt) {
      this.brand = evt.brand;
      this.number = evt.complete;
    },
    redeemAtmoPoints() {
      this.atmoPointsActive = true;
    },
    applyAtmoPoints() {
      this.appliedPointValue = this.computedPointValue;
    },
    submit() {
      alert('Proceed!');

      this.createUserAccomplishments({
        userId: this.$store.state.currentUserId,
        accomplishmentData: {
          payloads: [
            {
              user_id: this.$store.state.currentUserId,
              category: 'purchases',
              sub_category: 'purchaseNumber'
            },
            {
              user_id: this.$store.state.currentUserId,
              category: 'purchases',
              sub_category: 'amountSpent'
            },
            {
              user_id: this.$store.state.currentUserId,
              category: 'purchases',
              sub_category: 'itemPurchaseNumber'
            },
            {
              user_id: this.$store.state.currentUserId,
              category: 'purchases',
              sub_category: 'brandPurchaseNumber'
            },
            {
              user_id: this.$store.state.currentUserId,
              category: 'purchases',
              sub_category: 'brandPurchaseAmount'
            },
            {
              user_id: this.$store.state.currentUserId,
              category: 'purchases',
              sub_category: 'earlyPurchase'
            }
          ]
        }
      });
    },
    cancel() {
      alert('cancel!');
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  &__header {
    margin: 35px 0;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 750px;
  }

  &__main-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 20px;
  }

  &__cart-attributes {
    display: flex;
    flex-direction: column;
  }

  &__cart-attribute {
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 500;
    text-transform: uppercase;
    padding: 15px 10px;
    border-bottom: 1px solid $atmo-gray--medium;

    &--total {
      color: $atmo-blue--medium;
    }

    &--overall-total {
      background-color: rgba($atmo-gray--light, 0.8);
      color: $atmo-purple--dark;
    }
  }

  &__atmo-points {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
  }

  &__atmo-points-redemption-button {
    padding: 4px 7px;
    border-radius: 4px;
    background-color: $atmo-purple--medium-dark;
    color: white;
    font-size: 0.8em;
    margin: 0 5px;

    &:hover {
      background-color: $atmo-purple--dark;
    }
  }

  &__atmo-points-actions {
    display: flex;
    align-items: center;
    font-weight: 300;
  }

  &__atmo-points-redemption {
    display: flex;
    margin: 10px 0;
    align-items: center;
  }

  &__equals {
    margin: 0 5px;
  }

  &__apply-points-button {
    margin-left: auto;
  }

  &__action-buttons {
    display: flex;
    margin: auto -5px 0 auto;

    .atmo-button {
      margin: 0 5px;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 200px;
    max-width: 300px;
    background-color: rgba($atmo-purple--light, 0.3);
    border-radius: 5px;
    padding: 5px 10px;
  }

  &__input-row {
    display: flex;
    align-items: center;
    margin: 6px -3px;
    position: relative;
  }

  &__input {
    flex-grow: 1;
    border: 1px solid $atmo-gray--light;
    padding: 6px 9px;
    border-radius: 5px;
    font-size: 13px;
    height: 30px;
    margin: 0 3px 3px;
    min-width: 0;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $atmo-gray--light;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $atmo-gray--light;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $atmo-gray--light;
    }

    &--purple {
      background-color: $atmo-purple--dark;
    }

    &--points {
      font-size: 0.8em;
      width: 75px;
      height: auto;
      padding: 5px 7px;
      flex-grow: unset;
    }
  }

  &__form-section-header {
    margin-top: 10px;
  }

  &__inline-label {
    margin-left: 3px;
  }

  &__card-brand {
    position: absolute;
    right: 10px;
  }
}
</style>
