<template>
  <div class="user-profiles__videos-grid">
    <a v-for="(video, index) in videoArray" :key="index" class="videos-grid__video-card">
      <div class="video-card__image-wrap"
        v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + `${video.preview_image.url}` + ')' }">
        <div class="image-wrap__controls-container">
          <div class="controls-container__comments-label" @click="openUserMediaModal({
      type: 'Video',
      caption: video.name,
      url: video.video.url,
      id: video.id
    })">
            <img :src="require('@/assets/images/icons/accomplishments/comment.png')" />
          </div>
          <div class="controls-container__theatre-label" @click="playTheatreVideo(video)">
            <img :src="require('@/assets/images/icons/ops/watch_video.png')" />
          </div>
        </div>
      </div>
      <div class="video-card__text-wrap">
        <div class="text-wrap__video-wrap" @click="playTheatreVideo(video)">{{ video.name }}</div>
      </div>
    </a>
  </div>
</template>

<script>
import PaginationMixin from '@/mixins/pagination';
import devMockData from '@/devUtils/mockData.js';

export default {
  mixins: [PaginationMixin],
  props: {
    showNewReleases: { type: Boolean, default: false },
    videoArray: { type: Array, required: true },
  },

  data() {
    return {
      devMockData
    }
  },
  methods: {
    playTheatreVideo(video) {
      this.$store.dispatch("player/pauseSong");
      this.$store.commit("videoPlayer/setVideoBackgroundUrl", video.video.url);
      this.$store.commit("videoPlayer/setVideoBackgroundObject", video.video);
      this.$store.commit("videoPlayer/setVideoBackgroundLyrics", video.lyrics);
      this.$store.commit("videoPlayer/setVideoBackgroundName", video.name);
      this.$store.commit("videoPlayer/playVideo");
      this.$store.commit("videoPlayer/toggleMainContent");
    },
    openUserMediaModal(userMediaObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", {
        type: userMediaObj.type,
        caption: userMediaObj.caption,
        id: userMediaObj.id,
        url: userMediaObj.url
      });
      this.$store.commit("videoPlayer/setUserMediaModalObj", {
        type: userMediaObj.type,
        caption: userMediaObj.caption,
        id: userMediaObj.id,
        url: userMediaObj.url
      });
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    }
  }
}
</script>

<style lang="scss">
.user-profiles__videos-grid {
  display: grid;
  // grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  grid-gap: 1rem;
  margin-top: 10px;
  position: relative;
  margin-bottom: 1rem;
  width: 100%;

  .videos-grid__video-card {
    border-radius: 5px;
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    // cursor: pointer;
  }

  .video-card__image-wrap {
    min-height: 8rem;
    flex: 1;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 800;
    color: #fff;
    position: relative;
  }

  .image-wrap__controls-container {
    position: absolute;
    top: .4rem;
    left: .4rem;
    z-index: 1;
  }

  .controls-container__comments-label {
    font-size: .8rem;
    font-weight: 400;
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: .2rem;

    img {
      margin: 3px 0px 0px 0px;
      height: .9rem;
    }
  }

  .controls-container__theatre-label {
    font-size: .8rem;
    font-weight: 400;
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      margin-left: 2px;
      height: .6rem;
    }
  }

  .video-card__text-wrap {
    background-color: rgba(96, 72, 117, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 0 0 5px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .5rem 1rem .5rem 1rem;
    margin-top: auto;
    position: relative;
    cursor: pointer;
  }

  .text-wrap__video-wrap {
    font-weight: 500;
    color: white;
    font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 16ch;
  }
}
</style>