<template>
  <div class="album-card album-card--clickable">
    <div class="album-card__background-image-container album-card__image-container">
      <div class="album-card__background-image" :style="{ 'background-image': `url(${album.image.url})` }"></div>
      <router-link
        :to="{
          name: 'albums.show',
          params: {
            artistProfileId: album.primary_artist.id,
            albumId: album.id,
          },
        }"
      >
        <div class="album-card__link-icon-container">
          <img :src="require('@/assets/images/icons/store/link.png')" alt="Click for details" class="album-card__link-icon" />
        </div>
      </router-link>
      <div class="album-card__upper-right">
        <div class="album-card__album-year">
          <span>{{ albumReleaseDate }}</span>
        </div>
      </div>
    </div>
    <div class="album-card__body"></div>
    <div class="album-card__footer">
      <div class="album-card__album-footer">
        <div class="album-card__album-info">
          <div class="album-card__album-name">
            <span>{{ album.name }}</span>
          </div>
          <div class="album-card__album-genre">
            <span>{{ album.album_profile.primary_genre && album.album_profile.primary_genre.name }}</span>
          </div>
        </div>
        <div class="album-card__album-length"><img :src="require('@/assets/images/icons/clock.png')" class="album-card__album-length-icon" /> {{ album.length }} min</div>
      </div>
      <atmo-voting-buttons :entity="album" voteableType="Album" />
    </div>
  </div>
</template>

<script>
import AtmoVotingButtons from "@/components/atmo-voting-buttons";
export default {
  components: { AtmoVotingButtons },

  props: {
    album: {
      type: Object,
      required: true,
    },
    isLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    albumReleaseDate() {
      return this.album.album_profile?.year?.name ?? "Unknown";
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.album-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 250px;
  max-width: 300px;
  position: relative;
  border-radius: 5px 5px 0px 0px;

  &__album-image {
    flex-grow: 1;
  }

  &__album-year {
    background-color: $atmo-purple--medium-dark;
    padding: 8px;
    color: white;
    font-size: 1.2em;
    font-weight: 500;
    border-radius: 0px 5px 0px 5px;
  }

  &__album-footer {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(-269deg, rgba($atmo-blue--medium, 0.39) 1%, rgba($atmo-pink--medium, 0.39) 100%);
    border-radius: 0px 0px 5px 5px;
  }

  &__album-info {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  &__album-name {
    color: white;
    font-size: 1.2em;
    margin-bottom: 7px;
  }

  &__album-genre {
    color: $atmo-gray--medium;
    font-size: 0.8em;
  }

  &__album-length {
    color: white;
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 11px;
    border-top: 1px solid rgba(white, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__album-length-icon {
    height: 18px;
    margin-right: 2px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &--clickable {
    cursor: pointer;
  }

  &--with-background-gradient {
    background-image: linear-gradient(-270deg, rgba($atmo-blue--medium, 0.5) 0%, rgba($atmo-pink--medium, 0.5) 100%);
  }

  &__background-image {
    background-color: rgba($atmo-purple--light, 0.4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 5px 5px 0 0;
    margin: auto;
    height: 12rem;
  }

  &:hover .album-card__link-icon-container {
    visibility: visible;
    background-color: rgba($atmo-purple--dark, 0.7);
    border-radius: 5px;
  }

  &:hover .album-card__link-icon {
    opacity: 1;
  }

  &__upper-right {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__link-icon-container {
    display: flex;
    // align-items: center;
    justify-content: center;
    background-color: rgba($atmo-purple--dark, 0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color 0.2s ease-in;
    visibility: hidden;
    border-radius: inherit;
  }

  &__link-icon {
    height: 2rem;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    margin-top: 5rem;
  }

  &__body {
    font-size: 0.9em;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .vote-buttons-container {
    position: absolute;
    bottom: 0.2rem;
    right: -1rem;
  }
}
</style>
