<template>
  <div>
    <div class="event-ticket">
      <div class="event-ticket__decoration"></div>
      <div class="event-ticket__content">
        <div class="content__description-increment-container">
          <div>
            <div class="description-increment-container__title-wrap">
              <h3 class="atmo-subtitle">
                {{ ticket.title }}
              </h3>
            </div>
            <div>
              {{ ticket.description }}
            </div>
          </div>
          <div class="description-increment-container__price-increment-wrap">
            <div class="price-increment-wrap__price-wrap">
              <div class="event-ticket__price">
                {{ returnCurrencyFormat(ticket.price) }}
              </div>
            </div>
            <div class="price-increment-wrap__increment-wrap">
              <b-button-group class="event-ticket__incrementor">
                <b-button size="sm" class="event-ticket__incrementor__left-button" @click="handleQuantityChange('subtract')">
                  <img src="@/assets/images/icons/minus.png">
                </b-button>
                <b-form-input v-model="ticket.quantity" size="sm" class="text-center" />
                <b-button size="sm" class="event-ticket__incrementor__right-button" @click="handleQuantityChange('add')">
                  <img src="@/assets/images/icons/plus--white.png">
                </b-button>
              </b-button-group>
            </div>
          </div>
        </div>
        <div class="content__date-title-container">
          <div class="event-ticket__date">
            <img src="@/assets/images/icons/calender-simple.png">
            {{ formatDate(event.datetime) }}
          </div>
          <div class="event-ticket__date">
            <p class="float-right">
              {{ event.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButtonGroup, BButton, BFormInput } from "bootstrap-vue";
import { formatDateToMonthDayYearHour, formatPriceAsCurrency } from '@/helpers/utilityFunctions';

export default {
  components: {
    BButtonGroup,
    BButton,
    BFormInput
  },
  props: {
    ticket: { type: Object, required: true },
    event: { type: Object, required: true }
  },
  data: function () {
    return {
      ticketAmount: 0,
    }
  },
  methods: {
    returnCurrencyFormat(price) {
      return formatPriceAsCurrency(price)
    },
    formatDate(dateString) {
      return formatDateToMonthDayYearHour(dateString)
    },
    handleQuantityChange(type) {
      if (type == 'add') {
        this.ticket.quantity++;
      } else {
        if (this.ticket.quantity === 0) {
          return;
        }

        this.ticket.quantity--;
      }
      // Emit an event with the updated ticket information
      this.$emit('ticket-quantity-change', this.ticket);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.event-ticket {
  background-color: rgba($atmo-purple--extra-dark, 0.3);
  backdrop-filter: blur(5px);
  min-height: 100px;
  font-size: $atmo-base-size;
  border-radius: 5px;
  position: relative;
  margin-bottom: 1rem;

  &:hover {
    .event-ticket__decoration {
      background-color: $atmo-purple--medium;
    }
  }

  .event-ticket__decoration {
    background-color: white;
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 1rem;
    position: absolute;
    top: 0rem;
    bottom: 0rem;
    left: 0rem;
  }

  .event-ticket__content {
    padding: 1rem 1rem 1rem 1rem;
    margin-left: 1rem;
    font-size: .8rem;
  }

  .content__description-increment-container {
    padding: 1rem 0rem 1rem 0rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    border-bottom: 1px solid white;
  }

  .description-increment-container__title-wrap {
    margin-bottom: 1rem;
  }

  .description-increment-container__price-increment-wrap {
    max-width: 8rem;
  }

  .price-increment-wrap__price-wrap {
    margin-bottom: 1rem;
  }

  .content__date-title-container {
    display: flex;
    justify-content: space-between;
  }

  &__price {
    font-size: $atmo-subtitle-size;
    font-weight: bold;
    display: flex;
    flex-direction: row-reverse;
  }

  &__incrementor {
    img {
      max-width: 12px;
    }

    button {
      background: $atmo-purple--dark;
      border-color: $atmo-purple--dark;
    }

    &__left-button {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;

      img {
        padding-bottom: 3px;
      }
    }

    &__right-button {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }
  }

  &__date {
    border-color: white;

    img {
      vertical-align: middle;
      max-width: 12px;
      margin-right: 4px;
    }
  }
}
</style>