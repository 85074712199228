import { callNotificationsIndex, callNotificationsCreate, callNotificationsDelete } from '@/helpers/axiosCalls';
import { setCallback, connectToNotificationsChannel } from '@/cable-channels/notifications';

export default {
    namespaced: true,
    state: {
        debug: false,
        userNotifications: []
    },
    mutations: {
        setUserNotifications(state, notifications) {
            if (state.debug) console.log('Mutation: setUserNotifications', notifications);
            state.userNotifications = notifications;
        }
    },
    actions: {
        async fetchUserNotifications({ commit, state }, userId) {
            try {
                if (state.debug) console.log('Action: fetchUserNotifications - userId:', userId);
                const response = await callNotificationsIndex(userId);
                if (state.debug) console.log('API Response: fetchUserNotifications', response);
                commit('setUserNotifications', response);
            } catch (error) {
                console.error('Error in fetchUserNotifications:', error);
            }
        },
        connectToNotifications({ dispatch, rootState, state }) {
            const userId = rootState.currentUserId;
            if (userId) {
                if (state.debug) console.log(`Connecting to user notifications channel for user ID: ${userId}`);
                connectToNotificationsChannel(userId);
                setCallback(() => {
                    if (state.debug) console.log("Callback triggered: fetching user notifications");
                    dispatch('fetchUserNotifications', userId);
                });
            }
        },
        async createNotification({ dispatch, rootState, state }, notificationData) {
            try {
                if (state.debug) console.log('Action: createNotification - Payload:', notificationData.payload);
                await callNotificationsCreate(notificationData.userId, notificationData.payload);
                dispatch('fetchUserNotifications', rootState.currentUserId);
            } catch (error) {
                console.error('Error in createNotification:', error);
            }
        },
        async deleteNotification({ dispatch, rootState, state }, notificationId) {
            try {
                if (state.debug) console.log('Action: deleteNotification - notificationId:', notificationId);
                await callNotificationsDelete(notificationId);
                dispatch('fetchUserNotifications', rootState.currentUserId);
            } catch (error) {
                console.error('Error in deleteNotification:', error);
            }
        }
    },
    getters: {
        getUserNotifications: (state) => {
            if (state.debug) console.log('Getter: getUserNotifications', state.userNotifications);
            return state.userNotifications;
        }
    }
};
