import Axios from 'axios';

export function callArtistsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/artists`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callArtistsAtmo(queryParams = {}) {
    return Axios.get(`/api/v1/artists/atmo`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callArtistsExplore(queryParams = {}) {
    return Axios.get(`/api/v1/artists/explore`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callArtistsNewReleases(queryParams = {}) {
    return Axios.get(`/api/v1/artists/new_releases`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callArtistsSuggested(queryParams = {}) {
    return Axios.get(`/api/v1/artists/suggested`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
