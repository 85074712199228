import {
  addSeconds,
  addMinutes,
  addHours,
  addDays,
  addWeeks,
  addMonths
} from 'date-fns';

// TODO: This data is fake, and will be replaced by real data

// export const genres = [
//   { label: 'Chill', imageName: 'default_pic.jpg', isNew: true },
//   { label: 'Hip Hop', imageName: 'default_pic.jpg', isNew: true },
//   { label: 'Country', imageName: 'default_pic.jpg', isSuggested: true },
//   { label: 'Jazz', imageName: 'default_pic.jpg', isSuggested: true },
//   { label: 'Chill', imageName: 'default_pic.jpg', isSuggested: true },
//   { label: 'Hip Hop', imageName: 'default_pic.jpg', isSuggested: true },
//   { label: 'Country', imageName: 'default_pic.jpg', isSuggested: true },
//   { label: 'Jazz', imageName: 'default_pic.jpg', isNew: true, isSuggested: true },
//   { label: 'Chill2', imageName: 'default_pic.jpg' },
//   { label: 'Hip Hop2', imageName: 'default_pic.jpg' },
//   { label: 'Country2', imageName: 'default_pic.jpg', isNew: true },
//   { label: 'Jazz2', imageName: 'default_pic.jpg' },
//   { label: 'Chill2', imageName: 'default_pic.jpg' },
//   { label: 'Hip Hop2', imageName: 'default_pic.jpg' },
//   { label: 'Country2', imageName: 'default_pic.jpg' },
//   { label: 'Jazz2', imageName: 'default_pic.jpg' },
//   { label: 'Chill3', imageName: 'default_pic.jpg' },
//   { label: 'Hip Hop3', imageName: 'default_pic.jpg' },
//   { label: 'Country3', imageName: 'default_pic.jpg' },
// ];

export const songsStub = [
  { id: 1, name: 'Song Title', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 1, upvotes: 220, downvotes: 110, isSuggested: true },
  { id: 2, name: 'Song Title', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 2, upvotes: 220, downvotes: 110, isNew: true },
  { id: 3, name: 'Song Title', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 3, upvotes: 220, downvotes: 110, isSuggested: true },
  { id: 4, name: 'Song Title', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 4, upvotes: 220, downvotes: 110, isSuggested: true },
  { id: 5, name: 'Song Title', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 5, upvotes: 220, downvotes: 110, isSuggested: true },
  { id: 6, name: 'Song Title', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 6, upvotes: 220, downvotes: 110, isNew: true },
  { id: 7, name: 'Song Title', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 7, upvotes: 220, downvotes: 110, isSuggested: true },
  { id: 8, name: 'Song Title', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 8, upvotes: 220, downvotes: 110, isSuggested: true },
  { id: 9, name: 'Song Title2', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 9, upvotes: 220, downvotes: 110 },
  { id: 10, name: 'Song Title2', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 10, upvotes: 220, downvotes: 110, isNew: true },
  { id: 11, name: 'Song Title2', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 11, upvotes: 220, downvotes: 110 },
  { id: 12, name: 'Song Title2', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 12, upvotes: 220, downvotes: 110 },
  { id: 13, name: 'Song Title2', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 13, upvotes: 220, downvotes: 110 },
  { id: 14, name: 'Song Title2', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 14, upvotes: 220, downvotes: 110 },
  { id: 15, name: 'Song Title2', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 15, upvotes: 220, downvotes: 110 },
  { id: 16, name: 'Song Title2', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 16, upvotes: 220, downvotes: 110, isNew: true },
  { id: 17, name: 'Song Title3', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 17, upvotes: 220, downvotes: 110 },
  { id: 18, name: 'Song Title3', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 18, upvotes: 220, downvotes: 110 },
  { id: 19, name: 'Song Title3', albumName: 'Album Name', artistName: 'Artist Name', imageUrl: 'default_pic.jpg', plays: 220, rank: 19, upvotes: 220, downvotes: 110 },
];

export const artistsStub = [
  { id: 1, name: 'Jazz Artist 1', genre_id: 1, follower_count: 200, imageUrl: 'default_pic.jpg', isSuggested: true, user: { created_at: '2000-04-08T13:27:10.737Z' }, isFollowingMe: true },
  { id: 2, name: 'Rap Artist 1', genre_id: 2, follower_count: 365, imageUrl: 'default_pic.jpg', isSuggested: true, user: { created_at: '2000-04-08T13:27:10.737Z' }, isFollowingMe: false },
  { id: 3, name: 'Blues Artist 1', genre_id: 3, follower_count: 23400, imageUrl: 'default_pic.jpg', isNew: true, user: { created_at: '2000-04-08T13:27:10.737Z' }, isFollowingMe: true },
  { id: 4, name: 'Hip Hop Artist 1', genre_id: 4, follower_count: 434, imageUrl: 'default_pic.jpg', isSuggested: true, user: { created_at: '2000-04-08T13:27:10.737Z' }, isFollowingMe: false },
  { id: 5, name: 'Hip Hop Artist 2', genre_id: 4, follower_count: 6, imageUrl: 'default_pic.jpg', isSuggested: true, user: { created_at: '2000-04-08T13:27:10.737Z' }, isFollowingMe: true },
  { id: 6, name: 'Hip Hop Artist 3', genre_id: 4, follower_count: 346, imageUrl: 'default_pic.jpg', isSuggested: true, isNew: true, user: { created_at: '2000-04-08T13:27:10.737Z' }, isFollowingMe: false },
  { id: 7, name: 'Rock Artist 1', genre_id: 5, follower_count: 3, imageUrl: 'default_pic.jpg', isSuggested: true, user: { created_at: '2000-04-08T13:27:10.737Z' }, isFollowingMe: false },
  { id: 8, name: 'Rock Artist 2', genre_id: 5, follower_count: 46, imageUrl: 'default_pic.jpg', user: { created_at: '2000-04-08T13:27:10.737Z' }, isFollowingMe: true },
  { id: 9, name: 'Jazz Artist 2', genre_id: 1, follower_count: 346, imageUrl: 'default_pic.jpg', isNew: true, user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: false },
  { id: 10, name: 'Rap Artist 2', genre_id: 2, follower_count: 3, imageUrl: 'default_pic.jpg', user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: true },
  { id: 11, name: 'Blues Artist 2', genre_id: 3, follower_count: 4444, imageUrl: 'default_pic.jpg', user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: true },
  { id: 12, name: 'Hip Hop Artist 4', genre_id: 4, follower_count: 363, imageUrl: 'default_pic.jpg', user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: false },
  { id: 13, name: 'Hip Hop Artist 5', genre_id: 4, follower_count: 6363, imageUrl: 'default_pic.jpg', user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: true },
  { id: 14, name: 'Rock Artist 2', genre_id: 5, follower_count: 36, imageUrl: 'default_pic.jpg', user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: false },
  { id: 15, name: 'Rock Artist 3', genre_id: 5, follower_count: 3, imageUrl: 'default_pic.jpg', user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: true },
  { id: 16, name: 'Rock Artist 4', genre_id: 5, follower_count: 3646, imageUrl: 'default_pic.jpg', user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: true },
  { id: 17, name: 'Jazz Artist 3', genre_id: 1, follower_count: 34, imageUrl: 'default_pic.jpg', user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: false },
  { id: 18, name: 'Rap Artist 3', genre_id: 2, follower_count: 346, imageUrl: 'default_pic.jpg', user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: false },
  { id: 19, name: 'Blues Artist 3', genre_id: 3, follower_count: 34636, imageUrl: 'default_pic.jpg', user: { created_at: '2018-04-08T13:27:10.737Z' }, isFollowingMe: true },
];

export const albumsStub = [
  { name: 'Album Title', imageUrl: 'default_pic.jpg' },
  { name: 'Album Title', imageUrl: 'default_pic.jpg' },
  { name: 'Album Title', imageUrl: 'default_pic.jpg' },
  { name: 'Album Title', imageUrl: 'default_pic.jpg' },
  { name: 'Album Title', imageUrl: 'default_pic.jpg' },
  { name: 'Album Title', imageUrl: 'default_pic.jpg' },
];

// export const charts = [
//   { id: 1, label: 'Chill', imageName: 'default_pic.jpg' },
//   { id: 2, label: 'Hip Hop', imageName: 'default_pic.jpg' },
//   { id: 3, label: 'Country', imageName: 'default_pic.jpg'},
//   { id: 4, label: 'Jazz', imageName: 'default_pic.jpg'},
//   { id: 5, label: 'Chill', imageName: 'default_pic.jpg'},
//   { id: 6, label: 'Hip Hop', imageName: 'default_pic.jpg'},
//   { id: 7, label: 'Country', imageName: 'default_pic.jpg'},
//   { id: 8, label: 'Jazz', imageName: 'default_pic.jpg'},
//   { id: 9, label: 'Chill2', imageName: 'default_pic.jpg' },
//   { id: 10, label: 'Hip Hop2', imageName: 'default_pic.jpg' },
//   { id: 11, label: 'Country2', imageName: 'default_pic.jpg' },
//   { id: 12, label: 'Jazz2', imageName: 'default_pic.jpg' },
//   { id: 13, label: 'Chill2', imageName: 'default_pic.jpg' },
//   { id: 14, label: 'Hip Hop2', imageName: 'default_pic.jpg' },
//   { id: 15, label: 'Country2', imageName: 'default_pic.jpg' },
//   { id: 16, label: 'Jazz2', imageName: 'default_pic.jpg' },
//   { id: 17, label: 'Chill3', imageName: 'default_pic.jpg' },
//   { id: 18, label: 'Hip Hop3', imageName: 'default_pic.jpg' },
//   { id: 19, label: 'Country3', imageName: 'default_pic.jpg' },
// ];

// const currentYear = new Date().getFullYear();

// export const featuredCharts = [
//   { id: 'most_played', label: 'Most Played', imageName: 'played.png' },
//   { id: 'top_voted', label: 'Top Voted', imageName: 'voted.png' },
//   { id: 'recent', label: `Top ${currentYear}`, imageName: 'recent.png'}
// ];

export const statesAndCitiesStub = {
  TX: [
    'All Cities',
    'Austin',
    'Dallas',
    'San Antonio'
  ],
  WI: [
    'All Cities',
    'Madison',
    'Milwaukee'
  ]
};

const imagesMock = [{ media: { thumb: {
  url: 'https://atmosphere-development.s3.amazonaws.com/uploads/user_image/media/4/thumb_wolfgang_profile.jpg'
}}}]

const singularityMock = { id: 3, name: 'Singularity', user_images: imagesMock };
const nickelbackMock = { id: 33, name: 'Nickelback', user_images: imagesMock };

const now = new Date();
const secondsFromNow = addSeconds(now, 3);
const minutesFromNow = addMinutes(now, 10);
const hoursFromNow = addHours(now, 12);
const daysFromNow = addDays(now, 19);
const weeksFromNow = addWeeks(now, 3);
const monthsFromNow = addMonths(now, 2);

const mockContestDescription = 'This is the greatest contest man has ever known. '.repeat(10);

const sethStub = {
  id: 1,
  name: "Seth Jones",
  user_images: [{ media: {
    thumb: { url: 'https://atmosphere-development.s3.amazonaws.com/uploads/user_image/media/1/thumb_seth_profile_pic.png'},
    url: "https://atmosphere-development.s3.amazonaws.com/uploads/user_image/media/5/seth_profile_pic.png",
  }}]
};

const contestSubmissionCommentsStubSmall = [
  { created_at: '2019-07-27T19:10:01.944Z', id: 1, user: sethStub, text: 'Man, this is the best submission I have ever seen!' },
  { created_at: '2019-07-27T19:10:01.944Z', id: 2, user: sethStub, text: `Dude, there is just no way.  Sorry.  I'm going to keep writing a really long comment here to see what it looks like` },
]

const contestSubmissionCommentsStubLarge = [
  { created_at: '2019-07-27T19:10:01.944Z', id: 1, user: sethStub, text: 'Man, this is the best submission I have ever seen!' },
  { created_at: '2019-07-27T19:10:01.944Z', id: 2, user: sethStub, text: `Dude, there is just no way.  Sorry.  I'm going to keep writing a really long comment here to see what it looks like` },
  { created_at: '2019-07-27T19:10:01.944Z', id: 3, user: sethStub, text: 'Man, this is the best submission I have ever seen!' },
  { created_at: '2019-07-27T19:10:01.944Z', id: 4, user: sethStub, text: `Dude, there is just no way.  Sorry.  I'm going to keep writing a really long comment here to see what it looks like` },
  { created_at: '2019-07-27T19:10:01.944Z', id: 5, user: sethStub, text: 'Man, this is the best submission I have ever seen!' },
  { created_at: '2019-07-27T19:10:01.944Z', id: 6, user: sethStub, text: `Dude, there is just no way.  Sorry.  I'm going to keep writing a really long comment here to see what it looks like` },
  { created_at: '2019-07-27T19:10:01.944Z', id: 7, user: sethStub, text: 'Man, this is the best submission I have ever seen!' },
  { created_at: '2019-07-27T19:10:01.944Z', id: 8, user: sethStub, text: `Dude, there is just no way.  Sorry.  I'm going to keep writing a really long comment here to see what it looks like` },
  { created_at: '2019-07-27T19:10:01.944Z', id: 9, user: sethStub, text: 'Man, this is the best submission I have ever seen!' },
  { created_at: '2019-07-27T19:10:01.944Z', id: 10, user: sethStub, text: `Dude, there is just no way.  Sorry.  I'm going to keep writing a really long comment here to see what it looks like` },
  { created_at: '2019-07-27T19:10:01.944Z', id: 11, user: sethStub, text: 'Man, this is the best submission I have ever seen!' },
  { created_at: '2019-07-27T19:10:01.944Z', id: 12, user: sethStub, text: `Dude, there is just no way.  Sorry.  I'm going to keep writing a really long comment here to see what it looks like` },
  { created_at: '2019-07-27T19:10:01.944Z', id: 13, user: sethStub, text: 'Man, this is the best submission I have ever seen!' },
  { created_at: '2019-07-27T19:10:01.944Z', id: 14, user: sethStub, text: `Dude, there is just no way.  Sorry.  I'm going to keep writing a really long comment here to see what it looks like` },
  { created_at: '2019-07-27T19:10:01.944Z', id: 15, user: sethStub, text: 'Man, this is the best submission I have ever seen!' },
  { created_at: '2019-07-27T19:10:01.944Z', id: 16, user: sethStub, text: `Dude, there is just no way.  Sorry.  I'm going to keep writing a really long comment here to see what it looks like` },
]

export const contestSubmissionsStub = [
  { id: 1, user: sethStub, description: `Here's my attempt.  Probably not the greatest`, contest_submission_comments: contestSubmissionCommentsStubSmall },
  { id: 2, user: sethStub, description: `Here's my attempt.  Probably not the greatest.  But hey, it might be better than the others, so :shrug:`, contest_submission_comments: contestSubmissionCommentsStubSmall },
  { id: 3, user: sethStub, description: `Here's my attempt.  Probably not the greatest`, contest_submission_comments: contestSubmissionCommentsStubLarge },
  { id: 4, user: sethStub, description: `Here's my attempt.  Probably not the greatest.  But hey, it might be better than the others, so :shrug:`, contest_submission_comments: contestSubmissionCommentsStubLarge },
]

export const contestPrizesStub = [
  { id: 1, contest_submission_id: 1 },
  { id: 2, contest_submission_id: null },
  { id: 3, contest_submission_id: null },
]

export const contestsStub = [
  { id: 1, name: 'Awesome Contest!', user: singularityMock, from: '2019-07-27T19:10:01.944Z', to: secondsFromNow, description: mockContestDescription, image: '@/assets/images/default_pic.jpg' },
  { id: 2, name: 'OK Contest', user: singularityMock, from: '2019-07-27T19:10:01.944Z', to: minutesFromNow, description: mockContestDescription, image: '@/assets/images/default_pic.jpg' },
  { id: 3, name: 'Awesome Contest!', user: nickelbackMock, from: '2019-07-27T19:10:01.944Z', to: hoursFromNow, description: 'This is the greatest contest man has ever known, This is the greatest contest man has ever known, This is the greatest contest man has ever known, This is the greatest contest man has ever known', image: '@/assets/images/default_pic.jpg' },
  { id: 4, name: 'OK Contest', user: singularityMock, from: '2019-07-27T19:10:01.944Z', to: daysFromNow, description: mockContestDescription, image: '@/assets/images/default_pic.jpg' },
  { id: 5, name: 'Awesome Contest!', user: singularityMock, from: '2019-07-27T19:10:01.944Z', to: weeksFromNow, description: mockContestDescription, image: '@/assets/images/default_pic.jpg' },
  { id: 6, name: 'OK Contest', user: nickelbackMock, from: '2019-07-27T19:10:01.944Z', to: monthsFromNow, description: mockContestDescription, image: '@/assets/images/default_pic.jpg' },
  { id: 7, name: 'Awesome Contest!', user: singularityMock, from: '2019-07-27T19:10:01.944Z', to: daysFromNow, description: mockContestDescription, image: '@/assets/images/default_pic.jpg' },
  { id: 8, name: 'OK Contest', user: singularityMock, from: '2019-07-27T19:10:01.944Z', to: daysFromNow, description: mockContestDescription, image: '@/assets/images/default_pic.jpg' },
  { id: 9, name: 'Awesome Contest!', user: nickelbackMock, from: '2019-07-27T19:10:01.944Z', to: daysFromNow, description: mockContestDescription, image: '@/assets/images/default_pic.jpg' },
  { id: 10, name: 'OK Contest', user: singularityMock, from: '2019-07-27T19:10:01.944Z', to: daysFromNow, description: mockContestDescription, image: '@/assets/images/default_pic.jpg' },
  { id: 11, name: 'Awesome Contest!', user: singularityMock, from: '2019-07-27T19:10:01.944Z', to: daysFromNow, description: mockContestDescription, image: '@/assets/images/default_pic.jpg' },
]
