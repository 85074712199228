// import Vue from 'vue';
import {
  callStatesIndex,
  callCountriesIndex,
  callCitiesIndex
} from '@/helpers/axiosCalls';

export default {
  namespaced: true,
  state: {
    debug: false,
    countries: [],
    states: [],
    cities: [],
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries;
    },
    setStates(state, states) {
      state.states = states;
    },
    setCities(state, cities) {
      state.cities = cities;
    },
  },
  actions: {
    async fetchCountries({ commit }) {
      const response = await callCountriesIndex();
      commit('setCountries', response);
    },
    async fetchStates({ commit }) {
      const response = await callStatesIndex();
      commit('setStates', response);
    },
    async fetchCities({ commit }) {
      const response = await callCitiesIndex();
      commit('setCities', response);
    },
  },
  getters: {
    getCountryById: (state) => (id) => {
      return state.countries.find((country) => country.id === id);
    },
    getStateById: (state) => (id) => {
      return state.states.find((state) => state.id === id);
    },
    getCityById: (state) => (id) => {
      return state.cities.find((city) => city.id === id);
    },
  },
};
