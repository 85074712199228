import {
    callPostsCreate,
    callPostsDelete,
    callPostsUpdate,
    callBillboardPostsIndex,
    callLikesCreate,
    callDislikesCreate,
    callPostsShow
} from '@/helpers/axiosCalls';
import { connectToBillboardPostsChannel, setCallback } from '@/cable-channels/billboardPosts'; // Import channel functions

export default {
    namespaced: true,
    state: {
        debug: false,
        posts: [],
        post: null,
        isBillboardConnected: false // Track the connection status for the Billboard posts channel
    },
    mutations: {
        setPosts(state, posts) {
            state.posts = posts;
        },
        setPost(state, post) {
            state.post = post; // Add a mutation to set the single post
        },
        addPost(state, post) {
            state.posts.push(post);
        },
        updatePost(state, updatedPost) {
            const index = state.posts.findIndex(post => post.id === updatedPost.id);
            if (index !== -1) {
                state.posts.splice(index, 1, updatedPost);
            }
            if (state.post?.id === updatedPost.id) {
                state.post = updatedPost; // Update the single post if it's the one currently in state
            }
        },
        removePost(state, postId) {
            state.posts = state.posts.filter(post => post.id !== postId);
            if (state.post?.id === postId) {
                state.post = null; // Clear the single post if it's the one being deleted
            }
        },
        setBillboardConnectionStatus(state, status) {
            state.isBillboardConnected = status;
        }
    },
    
    actions: {
        async fetchPosts({ commit }, userId) {
            try {
                const response = await callBillboardPostsIndex(userId);
                commit('setPosts', response);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        },
        async fetchPost({ commit }, { postId, queryParams = {} }) {
            try {
                const response = await callPostsShow(postId, queryParams);
                commit('setPost', response); // Set the single post in the state
                commit('addPost', response); // Optionally, add it to the posts list
                return response;
            } catch (error) {
                console.error('Error fetching post:', error);
                throw error;
            }
        },
        async createPost({ rootState, commit, dispatch }, { userId, postPayload }) {
            try {
                const response = await callPostsCreate(userId, { post: postPayload });
                commit('addPost', response);
                dispatch('fetchPosts', userId); // Optionally refetch all posts if needed

                // TODO: Is it safe to assume billboard id is always friend user's id?
                if (rootState.currentUserId !== postPayload.billboard_id) {
                    await dispatch('notifications/createNotification', {
                        userId: postPayload.billboard_id,
                        payload: {
                            sender_id: rootState.currentUserId,
                            receiver_id: postPayload.billboard_id,
                            notification_type_name: "Billboard Post",
                            status: 'unread',
                            notifiable_type: 'Post',
                            notifiable_id: response.id
                        }
                    }, { root: true });
                }

                //DO WE WANT ACTIVITY AND POST?
                // await dispatch('userActivities/createUserActivity',
                //     {
                //         user_activity_type_name: "User Post",
                //         user_id: rootState.currentUserId,
                //         activityable_type: "Post",
                //         activityable_id: response.id
                //     },
                //     { root: true }
                // );

                return response;
            } catch (error) {
                console.error('Error creating post:', error);
                throw error;
            }
        },
        async updatePost({ commit }, { userId, postId, postPayload }) {
            try {
                const response = await callPostsUpdate(userId, postId, postPayload);
                commit('updatePost', response);
            } catch (error) {
                console.error('Error updating post:', error);
            }
        },
        async deletePost({ commit }, { userId, postId }) {
            try {
                await callPostsDelete(userId, postId);
                commit('removePost', postId);
            } catch (error) {
                console.error('Error deleting post:', error);
            }
        },
        async likePost({ dispatch, rootState }, post) {
            try {
                const like = await callLikesCreate({
                    user_id: this.state.currentUserId,
                    likeable_id: post.id,
                    likeable_type: 'Post'
                });
                dispatch('fetchPosts', post.billboard_id);

                if (rootState.currentUserId !== like.likeable.billboard_id) {
                    await dispatch('notifications/createNotification', {
                        userId: post.billboard_id,
                        payload: {
                            sender_id: rootState.currentUserId,
                            receiver_id: post.billboard_id,
                            notification_type_name: "Billboard Like",
                            status: 'unread',
                            notifiable_type: 'Post',
                            notifiable_id: like.likeable.id
                        }
                    }, { root: true });
                }

                await dispatch('userActivities/createUserActivity',
                    {
                        user_activity_type_name: "Post Like",
                        user_id: rootState.currentUserId,
                        activityable_type: "Post",
                        activityable_id: like.likeable.id
                    },
                    { root: true }
                );

                return like;
            } catch (error) {
                console.error('Error liking post:', error);
            }
        },
        async dislikePost({ dispatch, rootState }, post) {
            try {
                const dislike = await callDislikesCreate({
                    user_id: this.state.currentUserId,
                    dislikeable_id: post.id,
                    dislikeable_type: 'Post'
                });
                dispatch('fetchPosts', post.billboard_id);

                if (rootState.currentUserId !== dislike.dislikeable.billboard_id) {
                    await dispatch('notifications/createNotification', {
                        userId: post.billboard_id,
                        payload: {
                            sender_id: rootState.currentUserId,
                            receiver_id: post.billboard_id,
                            notification_type_name: "Billboard Dislike",
                            status: 'unread',
                            notifiable_type: 'Post',
                            notifiable_id: dislike.dislikeable.id
                        }
                    }, { root: true });
                }

                await dispatch('userActivities/createUserActivity',
                    {
                        user_activity_type_name: "Post Dislike",
                        user_id: rootState.currentUserId,
                        activityable_type: "Post",
                        activityable_id: dislike.dislikeable.id
                    },
                    { root: true }
                );

                return dislike;
            } catch (error) {
                console.error('Error disliking post:', error);
            }
        },
        connectToBillboardPosts({ commit, dispatch, state }, userId) {
            if (!state.isBillboardConnected) {
                console.log('Connecting to Billboard posts channel');
                connectToBillboardPostsChannel(); // Connect to the Billboard posts channel
                setCallback(async (data) => {
                    console.log('Billboard Posts Callback - New post received:', data);
                    await dispatch('fetchPosts', userId); // Fetch the latest posts when the callback is triggered
                });
                commit('setBillboardConnectionStatus', true); // Update the connection status
            }
        }
    },
    getters: {
        getPosts: state => state.posts,
        getPost: state => state.post // Add a getter to retrieve the single post
    },
};
