<template>
  <div class="billboard" data-cy="billboard">
    <div v-if="isLoading || !profile || !user">
      Loading…
    </div>
    <div v-else class="billboard-container">
      <div class="header-container">
        <h2 class="atmo-page-header">Billboard</h2>
      </div>
      <atmo-back-link></atmo-back-link>
      <perfect-scrollbar class="posts-scroll-container">
        <atmo-posts type="billboard" :posts="posts" :user-profile="profile" />
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AtmoPosts from '@/components/atmo-posts/atmo-posts';
import AtmoBackLink from '@/components/atmo-back-link';

export default {
  components: { AtmoPosts, AtmoBackLink },
  computed: {
    ...mapState('profiles', ['profile', 'user', 'isLoading']),
    ...mapState('posts', ['posts'])
  },
  created() {
    if (this.user) {
      this.fetchProfile({ profileType: this.user.profile_type, profileId: this.$route.params.userId }).then(() => {
        this.fetchPosts(this.user.id);
      });
    } else {
      // // Fetch user and profile based on userId from the route
      this.fetchUserAndProfile(this.$route.params.userId).then(() => {
        this.fetchPosts(this.user.id);
      });
    }

    // // Connect to the Billboard Posts Channel
    this.$store.dispatch('posts/connectToBillboardPosts', this.$route.params.userId);
  },
  methods: {
    ...mapActions('profiles', ['fetchUserAndProfile', 'fetchProfile']),
    ...mapActions('posts', ['fetchPosts'])
  }
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.billboard {
  margin: 0 75px;

  &-container {
    width: 100%;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }

  .back-container__icon-container {
    margin-right: .5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .posts-scroll-container {
    max-height: 40rem;
  }
}
</style>
