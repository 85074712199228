<template>
  <div class="atmo-library__sidebar-section">
    <h3 class="atmo-library__section-header">Lyrics</h3>
    <div v-if="currentSong" class="atmo-lyrics">
      <div class="atmo-lyrics__song-info">
        <span class="atmo-lyrics__album-title">{{ currentSong.album.name }}</span>
        <span class="atmo-lyrics__song-title">{{ currentSong.name }}</span>
      </div>
      <perfect-scrollbar class="atmo-lyrics__lyrics-scroll">
        <div class="atmo-lyrics__lyrics" v-if="currentSong.lyrics">
          <div v-html="formattedLyrics"></div>
        </div>
        <p v-else>No lyrics for this song</p>
      </perfect-scrollbar>
    </div>
    <div class="atmo-lyrics__empty-song-lyrics" v-else>
      No song currently selected
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentSong() {
      return this.$store.state.player.currentSong;
    },
    formattedLyrics() {
      if (this.currentSong && this.currentSong.lyrics) {
        return this.currentSong.lyrics.replace(/\n/g, '<br>');
      }
      return '';
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';
$lyrics-height: 25vh;

.atmo-lyrics {
  height: $lyrics-height;

  .ps {
    height: $lyrics-height;
  }

  &__song-info {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  &__album-title {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  &__song-title {
    font-size: 0.8rem;
    color: $atmo-blue--medium;
  }

  &__lyrics-scroll {
    height: $lyrics-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__lyrics {
    height: $lyrics-height;
    font-size: 0.7rem;
    font-weight: 400;
  }

  &__empty-song-lyrics {
    height: $lyrics-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
