import Axios from 'axios';

export function callCitiesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/cities`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callStatesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/states`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callCountriesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/countries`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}