import Axios from 'axios';

export function callProductVariantsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/product_variants`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductVariantsShow(productVariantId, queryParams = {}) {
    return Axios.get(`/api/v1/product_variants/${productVariantId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callProductVariantsCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/product_variants`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export async function callProductVariantsUpdate(payload, queryParams = {}) {
    if (queryParams.bulk) {
        // Handle bulk update
        return Axios.patch('/api/v1/product_variants/bulk_update', payload, { params: queryParams })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error;
            });
    } else {
        // Handle single update
        const productVariantId = payload.id;
        return Axios.patch(`/api/v1/product_variants/${productVariantId}`, payload, { params: queryParams })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error;
            });
    }
}

export function callProductVariantsDelete(productId, queryParams = {}) {
    return Axios.delete(`/api/v1/product_variants/${productId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}