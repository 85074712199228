<template>
  <div class="profile-pic-container__follow-container">
    <button class="follow-button" v-if="!currentUserIsFollowing" @click="handleFollowUser">
      <img class="follow-button__image add" src="@/assets/images/icons/profile/follow.png" />
      Follow
    </button>
    <button class="follow-button" v-if="currentUserIsFollowing" @click="handleUnfollowUser">
      <img class="follow-button__image unfriend" src="@/assets/images/icons/profile/delete.png" />
      Unfollow
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('userRelationships', ['getCurrentUserIsFollowing']),
    currentUserIsFollowing() {
      const isFollowing = this.getCurrentUserIsFollowing;
      console.log('Computed currentUserIsFollowing:', isFollowing); // Debugging: log the computed value
      return isFollowing;
    }
  },
  methods: {
    ...mapActions('userRelationships', ['followUser', 'unfollowUser']),
    async handleFollowUser() {
      try {
        console.log('Attempting to follow user:', this.userId); // Debugging: log follow attempt
        await this.followUser(this.userId);
        console.log('Successfully followed user:', this.userId); // Debugging: log follow success
      } catch (error) {
        console.error('Error following user:', error); // Debugging: log follow error
      }
    },
    async handleUnfollowUser() {
      try {
        console.log('Attempting to unfollow user:', this.userId); // Debugging: log unfollow attempt
        await this.unfollowUser(this.userId);
        console.log('Successfully unfollowed user:', this.userId); // Debugging: log unfollow success
      } catch (error) {
        console.error('Error unfollowing user:', error); // Debugging: log unfollow error
      }
    }
  },
  async created() {
    try {
      console.log('Component created. Fetching following state for user:', this.userId); // Debugging: log component creation
      await this.$store.dispatch('userRelationships/updateFollowingState', this.userId);
      console.log('Following state fetched for user:', this.userId); // Debugging: log following state fetched
    } catch (error) {
      console.error('Error fetching following state for user:', error); // Debugging: log error in fetching state
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.profile-pic-container__follow-container {
  position: absolute;
  bottom: -1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .follow-button {
    background: rgb(127, 103, 156);
    padding: .5rem 1.4rem .5rem 1.4rem;
    color: white;
    font-size: .8rem;
    font-weight: 600;
    border-radius: 30px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .follow-button__image {
    width: auto;
    margin-right: .5rem;

    &.edit {
      height: 1rem;
    }

    &.add {
      height: .7rem;
    }

    &.unfriend {
      height: .7rem;
    }
  }
}
</style>
