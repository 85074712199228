export default {
  sundayFirst: true,
  daysShort: ['SU', 'M', 'TU', 'W', 'TH', 'F', 'SA'],
  // TODO: use color variables
  colors: {
    selected: '#604875',
    inRange: '#bea7dd',
    inRangeBorder: '#a58bc5',
  }
}
