<template>
  <div class="atmo-filter-controls">
    <div
      class="atmo-filter-controls__filter-button"
      @click="onFilterButtonClick"
    >
      <img
        class="atmo-filter-controls__filter-icon"
        src="@/assets/images/icons/filter.png"
      >
      <span
        v-if="!selectedFilterValue"
        class="atmo-filter-controls__filter-label"
      >
        Filter
      </span>
      <span
        v-else
        class="atmo-filter-controls__active-filter-label"
      >
        Filtered By {{ selectedFilterType }}:
        <span class="atmo-filter-controls__active-filter-label-value">
          {{ selectedFilterValue }}
        </span>
      </span>
    </div>
    <img
      v-if="selectedFilterValue"
      class="atmo-filter-controls__remove-filter-button"
      src="@/assets/images/icons/close_filter.png"
      alt="Remove Filter"
      @click="onReset"
    >
  </div>
</template>

<script>
  export default {
    props: {
      selectedFilterValue: { type: String, default: null },
      selectedFilterType: { type: String, default: null },
      onFilterButtonClick: { type: Function, default() {} },
      onReset: { type: Function, default() {} }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .atmo-filter-controls {
    display: flex;
    align-items: center;

    &__filter-button {
      display: flex;
      align-items: center;
      margin-left: 10px;
      border: 1px dashed rgba($atmo-purple--medium, 0.7);
      padding: 6px 10px;
      cursor: pointer;

      &:hover {
        background-color: rgba($atmo-purple--light, 0.4);
      }

      &:active {
        background-color: rgba($atmo-purple--dark, 0.4);
      }
    }

    &__filter-icon {
      height: 16px;
      margin-right: 8px;
    }

    &__filter-label {
      font-size: 1.1em;
      text-transform: uppercase;
    }

    &__active-filter-label {
      color: rgba($atmo-purple--light, 0.7);
    text-transform: capitalize;
    }

    &__active-filter-label-value {
      color: white;
    }

    &__remove-filter-button {
      cursor: pointer;
      height: 25px;
      margin-left: 7px;

      &:hover {
        opacity: 0.4;
      }
    }
  }
</style>
