<template>
  <div class="user-profiles__photos-grid">
    <a v-for="(photo, index) in photoArray" :key="index" @click="openUserMediaModal(
      {
        type: 'Image',
        caption: '',
        url: photo.image.url,
        id: photo.id
      })" class="profiles-grid__profile-card"
      v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + `${photo.image.url}` + ')' }">
    </a>
  </div>
</template>
  
<script>
import PaginationMixin from '@/mixins/pagination';
import devMockData from '@/devUtils/mockData.js';

export default {
  mixins: [PaginationMixin],
  props: { showNewReleases: { type: Boolean, default: false }, photoArray: { type: Array, required: true }, },

  data() {
    return {
      artistImageArray: devMockData.artistImageArray
    }
  },

  computed: {
    fullCollection() {
      if (this.showNewReleases) {
        return this.genres.filter(genre => genre.isNew);
      } else {
        return this.genres;
      }
    }
  },

  methods: {
    openUserMediaModal(userMediaObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url }); //NEEDS TO BE FIXED
      this.$store.commit("videoPlayer/setUserMediaModalObj", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    }
  }
}
</script>
  
<style lang="scss">
.user-profiles__photos-grid {
  display: grid;
  // grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  grid-gap: 1rem;
  margin-top: 10px;
  position: relative;
  margin-bottom: 1rem;
  width: 100%;

  .profiles-grid__profile-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    cursor: pointer;
  }

  .profile-card__title-wrap {
    font-weight: 800;
    font-size: 1.3rem;
  }
}
</style>