<template>
  <div>
    <h1>{{ playlist.name }}</h1>
    <router-link :to="{ name: 'user_profiles.playlists.settings', params: {userProfileId: this.$route.params.userProfileId, playlistId: playlist.id} }">
      Manage playlist settings
    </router-link>
    <br>
    <div
      v-for="song in songs"
      :key="song.id"
    >
      <p>{{ song.name }}</p>
    </div>
  </div>
</template>

<!-- ng-if="userPlaylist.profileId == user.profile_id" ui-sref="playlist_settings({userPlaylistsId: user.profile_id, playlistId: userPlaylist.playlist.id})" -->

<script>
  import Axios from 'axios'
  import storeTest from '@/store'
  import { callUserProfilesShow, callUsersShow, callSongReferencesIndex } from '@/helpers/axiosCalls';

  export default{
    data(){
      return {
        user: {},
        currentUser: {},
        userProfile: {},
        playlist: {},
        songs: {}
      }
    },
    created(){
      callUsersShow(storeTest.state.currentUserId).then(response => (this.currentUser = response.data));
      callUserProfilesShow(this.$route.params.userProfileId)
        .then(response1 => {
          this.userProfile = response1;
          callUsersShow(this.userProfile.user.id)
            .then(response2 => {
              this.user = response2;
              Axios.get(`/api/v1/users/${this.user.id}/playlists`);
              Axios.get(`/api/v1/users/${this.user.id}/playlists/${this.$route.params.playlistId}`).then(response => (this.playlist = response.data));
              callSongReferencesIndex(
              {
                songable_id: this.$route.params.playlistId,
                songable_type: 'Playlist'
              })
              .then(response => {
                this.songs = response.map((songReference) => {
                  return songReference.song;
                });
              });
            })
        });
    },
    methods: {

    }
  }
</script>
