<template>
    <div class="generic-empty-message">
        <div class="message-container" v-if="message">
            {{ message }}
        </div>
        <div class="message-container" v-else>
            Sorry, no results found
        </div>
        <div class="image-container"><img src="@/assets/images/icons/no-results.png" /></div>
    </div>
</template>

<script>

export default {
    props: {
        message: {
            type: String
        }
    }
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.generic-empty-message {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message-container {
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 2rem;
    }

    .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        
        img {
            height: 7rem;
        }
    }
}
</style>