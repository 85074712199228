import Axios from 'axios';

export function callProductColorsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/product_colors`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
