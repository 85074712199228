<template>
    <div class="atmo-products-edit">
        <h2 class="atmo-page-header">
            Edit Product
        </h2>
        <div class="atmo-products-edit__container">
            <div class="back-container" @click="goBack()">
                <div class="back-container__icon-container">
                    <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
                </div>
                <div class="back-container__text-container">Back to previous</div>
            </div>

            <div class="container__container-wrap">
                <atmo-loading v-if="isLoading" />
                <form v-else @submit.prevent="storeProductInformation" class="container-wrap__form">
                    <div class="form__product-details-wrap">
                        <div class="product-details-wrap__product-details">
                            <div class="field-wrap">
                                <label for="productTitle">Title</label>
                                <input class="text-field" id="productTitle" v-model="editProductTitle"
                                    :aria-invalid="submitAttempted && !editProductTitle" type="text"
                                    placeholder="Atmo Shirt" data-cy="product-title" />
                            </div>
                            <div class="field-wrap">
                                <label for="productDescription">Description</label>
                                <textarea class="text-field" id="productDescription" v-model="editProductDescription"
                                    rows="4" no-resize :aria-invalid="submitAttempted && !editProductDescription"
                                    placeholder="If you're trying to get album sales, rock the Atmo A. It's science."
                                    data-cy="product-description"></textarea>
                            </div>
                            <!-- <div class="flex-columns">
                                <div class="field-wrap">
                                    <label for="country">Category</label>
                                    <select v-model="editProductCategory" class="dropdown-group" id="category"
                                        name="category" @change="filterSizeSystems">
                                        <option v-for="(category, index) in categories" :key="index"
                                            :value="category.id">
                                            {{ category.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="field-wrap">
                                    <label for="country">Size System</label>
                                    <select v-model="editProductSizeSystem" class="dropdown-group" id="sizeSystem"
                                        name="sizeSystem">
                                        <option v-for="(sizeSystem, index)  in filteredSizeSystems" :key="index"
                                            :value="sizeSystem">
                                            {{ sizeSystem.name }}
                                        </option>
                                    </select>
                                </div>
                            </div> -->
                            <div class="available-colors-container">
                                <div>
                                    <label for="color">Existing Colors</label>
                                    <div class="field-wrap">
                                        <div v-for="(color, index) in existingColors" :key="index"
                                            class="existing-color">
                                            {{ color.name }}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label for="color">Available Colors</label>
                                    <perfect-scrollbar class="available-colors-scroll">
                                        <div class="field-wrap">
                                            <fieldset class="checkbox-group" id="color">
                                                <div v-for="(color, index) in availableColors" :key="index"
                                                    class="checkbox-field-wrap">
                                                    <input class="checkbox-field" type="checkbox" :id="'color_' + index"
                                                        :value="color" v-model="selectedColors">
                                                    <label class="checkbox-field-label" :for="'color_' + index">
                                                        {{ color.name }}
                                                    </label>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </perfect-scrollbar>
                                </div>
                                <!-- <p>Selected Colors: {{ selectedColors }}</p> -->
                            </div>
                            <div class="shipping-info-heading">
                                <p>Shipping Information</p>
                            </div>
                            <div class="field-wrap" v-if="editProductMeasurementSystem">
                                <label for="productWeight">Measurement System</label>
                                <select v-model="editProductMeasurementSystem" class="dropdown-group" id="sizeSystem"
                                    name="sizeSystem" @change="onMeasurementSystemChange(editProductMeasurementSystem)">
                                    <option v-for="(sizeSystem, index)  in measurementSystems" :key="index"
                                        :value="sizeSystem">
                                        {{ sizeSystem.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="flex-columns">
                                <div class="field-wrap">
                                    <label for="productWeight">Weight</label>
                                    <input class="text-field" id="productWeight" v-model="editProductWeight"
                                        :aria-invalid="submitAttempted && !editProductWeight" type="text"
                                        placeholder="10" />
                                </div>
                                <div class="field-wrap">
                                    <label for="productWeightUnit">Weight Unit</label>
                                    <select v-model="editProductWeightUnit" class="dropdown-group"
                                        id="productWeightUnit" name="productWeightUnit">
                                        <option v-for="(weightUnit, index) in weightUnits" :key="index"
                                            :value="weightUnit">
                                            {{ weightUnit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex-columns">
                                <div class="field-wrap">
                                    <label for="productLength">Length</label>
                                    <input class="text-field" id="productLength" v-model="editProductLength"
                                        :aria-invalid="submitAttempted && !editProductLength" type="text"
                                        placeholder="10" />
                                </div>
                                <div class="field-wrap">
                                    <label for="productWidth">Width</label>
                                    <input class="text-field" id="productWidth" v-model="editProductWidth"
                                        :aria-invalid="submitAttempted && !editProductWidth" type="text"
                                        placeholder="20" />
                                </div>
                                <div class="field-wrap">
                                    <label for="productHeight">Height</label>
                                    <input class="text-field" id="productHeight" v-model="editProductHeight"
                                        :aria-invalid="submitAttempted && !editProductHeight" type="text"
                                        placeholder="15" />
                                </div>
                                <div class="field-wrap">
                                    <label for="productDimensionUnit">Dimensions Unit</label>
                                    <select v-model="editProductDimensionUnit" class="dropdown-group"
                                        id="productDimensionUnit" name="productDimensionUnit">
                                        <option v-for="(dimensionUnit, index) in dimensionUnits" :key="index"
                                            :value="dimensionUnit">
                                            {{ dimensionUnit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-wrap__footer">
                        <button type="submit" class="main-wrap__submit-button" @click="storeProductData()"
                            v-if="formCompleted">
                            Next
                        </button>
                        <button type="submit" class="main-wrap__submit-button-inactive" v-else>
                            Next
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import AtmoLoading from '@/components/atmo-loading';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { objIsEmpty } from '@/helpers/utilityFunctions';
import {
    callProductsShow
} from '@/helpers/axiosCalls';
import { extractUniqueColors } from '@/helpers/utilityFunctions';

export default {
    components: {
        AtmoLoading,
    },

    data: function () {
        return {
            isLoading: false,
            originalProduct: null,
            editProductTitle: null,
            editProductDescription: null,
            editProductCategory: null,
            editProductSizeSystem: null,
            editProductMeasurementSystem: null,
            editProductDimensionUnit: null,
            editProductWeightUnit: null,
            editProductWeight: null,
            editProductLength: null,
            editProductWidth: null,
            editProductHeight: null,
            editProductPrice: null,
            submitAttempted: false,
            existingColors: [],
            selectedColors: [],
            availableColors: [],
            filteredSizeSystems: []
        }
    },

    computed: {
        ...mapState('products', ['categories', 'sizeSystems', 'measurementSystems', 'measurementUnits', 'colors', 'editProductData']),
        ...mapGetters('products', ['getCategoryById', 'getSizeSystemById', 'getMeasurementSystemById', 'getMeasurementUnitById', 'getColorById']),
        artistProfileId() {
            return this.$store.state.currentUserProfileId;
        },
        weightUnits() {
            return this.measurementUnits.filter(unit => unit.unit_type === 'weight' && unit.measurement_system_id === this.editProductMeasurementSystem.id);
        },
        dimensionUnits() {
            return this.measurementUnits.filter(unit => unit.unit_type === 'dimension' && unit.measurement_system_id === this.editProductMeasurementSystem.id);
        },
        formCompleted() {
            return this.formIsCompleted();
        }
    },
    async created() {
        await this.checkForExistingProductData();
    },
    methods: {
        ...mapActions('products', ['fetchCategories', 'fetchSizeSystems', 'fetchMeasurementSystems', 'fetchMeasurementUnits', 'fetchColors']),
        ...mapMutations('products', ['setEditProductData']),
        async checkForExistingProductData() {
            if (!this.objIsEmpty(this.editProductData)) {
                const {
                    originalProduct,
                    editProductTitle,
                    editProductDescription,
                    editProductCategory,
                    editProductSizeSystem,
                    editProductMeasurementSystem,
                    editProductDimensionUnit,
                    editProductWeightUnit,
                    editProductWeight,
                    editProductLength,
                    editProductWidth,
                    editProductHeight,
                    existingColors,
                    availableColors,
                    selectedColors,
                    editProductPrice,
                    filteredSizeSystems,
                } = this.editProductData;

                this.originalProduct = originalProduct;
                this.editProductTitle = editProductTitle;
                this.editProductDescription = editProductDescription;
                this.editProductCategory = editProductCategory;
                this.editProductSizeSystem = this.getSizeSystemById(editProductSizeSystem.id);
                this.editProductMeasurementSystem = editProductMeasurementSystem;
                this.editProductDimensionUnit = editProductDimensionUnit;
                this.editProductWeightUnit = editProductWeightUnit;
                this.editProductWeight = editProductWeight;
                this.editProductLength = editProductLength;
                this.editProductWidth = editProductWidth;
                this.editProductHeight = editProductHeight;
                this.existingColors = existingColors;
                this.availableColors = availableColors;
                this.selectedColors = selectedColors;
                this.editProductPrice = editProductPrice;
                this.filteredSizeSystems = filteredSizeSystems;
            } else {
                await this.fetchCategories();
                await this.fetchSizeSystems();
                await this.fetchMeasurementSystems();
                await this.fetchMeasurementUnits();
                await this.fetchColors();
                await this.fetchProductData();
            }
        },
        async fetchProductData() {
            this.isLoading = true;
            try {
                const response = await callProductsShow(this.$route.params.productId)

                const {
                    title,
                    description,
                    product_category_id,
                    product_size_system,
                    shipping_carrier_product: {
                        measurement_system,
                        dimension_unit,
                        weight_unit,
                        weight,
                        length,
                        width,
                        height
                    },
                    product_variants
                } = response;

                this.originalProduct = response;
                this.editProductTitle = title;
                this.editProductDescription = description;
                this.editProductCategory = product_category_id;
                this.editProductSizeSystem = this.getSizeSystemById(product_size_system.id);
                this.editProductMeasurementSystem = this.getMeasurementSystemById(measurement_system.id);
                this.editProductDimensionUnit = this.getMeasurementUnitById(dimension_unit.id);
                this.editProductWeightUnit = this.getMeasurementUnitById(weight_unit.id);
                this.editProductWeight = weight;
                this.editProductLength = length;
                this.editProductWidth = width;
                this.editProductHeight = height;
                this.existingColors = this.extractUniqueColors(product_variants);
                this.availableColors = this.removeExistingFromColors(this.existingColors, this.colors);
            } catch (error) {
                console.error('Error fetching product data:', error);
            } finally {
                this.isLoading = false;
            }
        },
        extractUniqueColors(data) {
            return extractUniqueColors(data);
        },
        removeExistingFromColors(existingColors, colors) {
            const existingColorIds = existingColors.map(color => color.id);
            const filteredColors = colors.filter(color => !existingColorIds.includes(color.id));
            return filteredColors;
        },
        goBack() {
            const userId = this.$store.state.currentUserId;
            this.$router.push({ name: 'products.user_index', params: { userId } });
        },
        objIsEmpty(obj) {
            return objIsEmpty(obj);
        },
        formIsCompleted() {
            const {
                editProductTitle,
                editProductDescription,
                editProductCategory,
                editProductSizeSystem,
                editProductMeasurementSystem,
                editProductDimensionUnit,
                editProductWeightUnit,
                editProductWeight,
                editProductLength,
                editProductWidth,
                editProductHeight,
            } = this;

            return editProductTitle &&
                editProductDescription &&
                editProductCategory &&
                editProductSizeSystem &&
                editProductMeasurementSystem &&
                editProductDimensionUnit &&
                editProductWeightUnit &&
                editProductWeight &&
                editProductLength &&
                editProductWidth &&
                editProductHeight
        },
        async storeProductInformation() {
            this.setEditProductData({
                ...this.editProductData,
                originalProduct: this.originalProduct,
                editProductTitle: this.editProductTitle,
                editProductDescription: this.editProductDescription,
                editProductCategory: this.editProductCategory,
                editProductSizeSystem: this.editProductSizeSystem,
                editProductMeasurementSystem: this.editProductMeasurementSystem,
                editProductDimensionUnit: this.editProductDimensionUnit,
                editProductWeightUnit: this.editProductWeightUnit,
                editProductWeight: this.editProductWeight,
                editProductLength: this.editProductLength,
                editProductWidth: this.editProductWidth,
                editProductHeight: this.editProductHeight,
                filteredSizeSystems: this.filteredSizeSystems,
                existingColors: this.existingColors,
                availableColors: this.availableColors,
                selectedColors: this.selectedColors,
                editProductPrice: this.editProductPrice,
            })
        },
        storeProductData() {
            if (this.formIsCompleted()) {
                this.storeProductInformation();
                this.$router.push({ name: 'product_images.edit', params: { productId: this.$route.params.productId } });
            }
        },
        getCategoryName(categoryId) {
            const category = this.getCategoryById(categoryId);
            return category ? category.name : '';
        },
        getSizeSystemName(sizeSystemId) {
            const sizeSystem = this.getSizeSystemById(sizeSystemId);
            return sizeSystem ? sizeSystem.name : '';
        },
        getMeasurementSystemName(measurementSystemId) {
            const measurementSystem = this.getMeasurementSystemById(measurementSystemId);
            return measurementSystem ? measurementSystem.name : '';
        },
        getMeasurementUnitName(measurementUnitId) {
            const measurementUnit = this.getMeasurementUnitById(measurementUnitId);
            return measurementUnit ? measurementUnit.name : '';
        },
        getColorName(colorId) {
            const color = this.getColorById(colorId);
            return color ? color.name : '';
        },
        filterSizeSystems() {
            if (this.editProductCategory) {
                this.filteredSizeSystems = this.sizeSystems.filter(sizeSystem => sizeSystem.product_category_id === this.editProductCategory);
            } else {
                this.filteredSizeSystems = [];
            }
        },
        onMeasurementSystemChange(newMeasurementSystem) {
            if (
                this.editProductWeightUnit &&
                this.editProductDimensionUnit &&
                (
                    this.editProductWeightUnit.measurement_system_id !== newMeasurementSystem.id ||
                    this.editProductDimensionUnit.measurement_system_id !== newMeasurementSystem.id
                )
            ) {
                this.editProductWeight = null;
                this.editProductLength = null;
                this.editProductWidth = null;
                this.editProductHeight = null;
                this.editProductWeightUnit = null;
                this.editProductDimensionUnit = null;
            }
            this.filterMeasurementUnits();
        },
        filterMeasurementUnits() {
            if (this.editProductMeasurementSystem) {
                this.filteredMeasurementUnits = this.measurementUnits.filter(measurementUnit => measurementUnit.measurement_system_id === this.editProductMeasurementSystem.id);
            } else {
                this.filteredMeasurementUnits = [];
            }
        },
    },
    watch: {
        editProductCategory() {
            this.filterSizeSystems();
        },
        editProductMeasurementSystem() {
            this.filterMeasurementUnits();
        }
    },
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-products-edit {
    display: flex;
    flex-direction: column;
    // max-width: 50rem;

    //https://github.com/mercs600/vue2-perfect-scrollbar
    .ps {
        max-height: 20rem;
    }

    .ps.available-colors-scroll {
        max-height: 5rem;
        padding-top: .2rem;
    }

    textarea,
    textarea::placeholder {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        color: white;
    }

    input::placeholder {
        font-family: 'Roboto', sans-serif;
        font-size: 1rem;
        color: rgba(255, 255, 255, .4) !important;
    }

    .field-wrap {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;

        label {
            text-transform: uppercase;
            margin-bottom: .5rem;
            font-size: .9rem;
            font-weight: 500;
        }

        .text-field {
            background: transparent;
            border-radius: 5px;
            border: 1px solid rgba(255, 255, 255, .4);
            padding: .5rem .5rem .5rem .5rem;
            color: white;
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: rgba(255, 255, 255, .4);
            opacity: 1;
            /* Firefox */
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: rgba(255, 255, 255, .4);
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: rgba(255, 255, 255, .4);
        }

        .dropdown-group {
            background: $atmo-purple--medium-dark;
            color: white;
            text-transform: uppercase;
            border: none;
            border-radius: 5px;
            padding: .5rem;
            font-weight: 500rem;
            cursor: pointer;
        }

        .dropdown-field {}

        .radio-group {
            display: block !important;
            padding: .6rem 0rem .6rem 0rem;
        }

        .radio-field-wrap {
            margin-right: 1rem;
            display: flex;
            justify-content: start !important;
            align-items: center;
            margin-bottom: .1rem;
        }

        .radio-field {
            margin-right: .5rem;
            cursor: pointer;
        }

        .radio-field-label {
            margin: 2px 0px 0px 0px;
        }
    }

    .file-type-label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $atmo-purple--light;
        cursor: pointer;
        text-transform: unset !important;
        font-weight: 400 !important;
        padding: .2rem .4rem .2rem .4rem;
    }

    .field-label {
        font-weight: 500;
        margin-bottom: .8rem;
    }

    .atmo-products-edit {}

    .atmo-products-edit__container {
        margin: auto;
        max-width: 1000px;
    }

    .container__container-wrap {}

    .container-wrap__form {}

    .form__product-details-wrap {
        display: flex;
        // gap: 1rem;
    }

    .product-details-wrap__image-upload {}

    .product-details-wrap__product-details {
        width: 100%;

        .flex-columns {
            display: flex;
            gap: 1rem;

            .text-field {
                min-width: unset;
            }
        }

    }

    .available-colors-container {
        display: flex;
        gap: 1rem;

        label {
            text-transform: uppercase;
            margin-bottom: .5rem;
            font-size: .9rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }

        .existing-color {
            text-transform: uppercase;
        }
    }

    .shipping-info-heading {
        margin-top: .5rem;
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 500;
    }

    .main-wrap__submit-button {
        margin-left: auto;
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
    }

    .main-wrap__submit-button-inactive {
        margin-left: auto;
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.3) 0%, rgba(68, 174, 220, 0.3) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
    }

    //https://www.sliderrevolution.com/resources/css-checkbox/
    input[type="checkbox"] {
        position: relative;
        width: 1.2em;
        height: 1.2em;
        color: #363839;
        border-radius: 4px;
        appearance: none;
        outline: 0;
        cursor: pointer;
        transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
        background: $atmo-purple--dark;

        &::before {
            position: absolute;
            content: '';
            display: block;
            top: 2px;
            left: 6px;
            width: 4px;
            height: 10px;
            border-style: solid;
            border-color: $atmo-purple--dark;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            opacity: 0;
        }

        &:checked {
            color: $atmo-purple--dark;
            border-color: $atmo-blue--medium;
            background: $atmo-blue--medium;

            &::before {
                opacity: 1;
            }

            ~label::before {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }
    }
}
</style>