var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.componentType,{tag:"component",staticClass:"atmo-upload-card",attrs:{"to":_vm.to}},[_c(_vm.isButton ? 'button' : 'div',{tag:"component",class:{
      'atmo-button atmo-button--secondary atmo-upload-card__button': _vm.isButton,
      'atmo-upload-card__container': !_vm.isButton,
      'atmo-upload-card__container--small': _vm.size === 'small',
      'atmo-upload-card__container--dragover': _vm.isDraggingOver
    },style:(_vm.componentStyle),attrs:{"type":_vm.isButton ? 'button' : ''},on:{"click":_vm.handleContainerClick,"dragenter":_vm.handleContainerDragenter,"dragover":_vm.handleContainerDragover,"dragleave":_vm.handleContainerDragleave,"drop":_vm.handleContainerDrop}},[_c('input',{ref:_vm.hiddenFileInputRef,staticStyle:{"display":"none"},attrs:{"type":"file","multiple":_vm.allowMultipleFiles,"accept":_vm.accept},on:{"change":_vm.handleFilesInputted}}),(_vm.computedImageSrc)?_c('img',{staticClass:"atmo-upload-card__preview-image",attrs:{"src":_vm.computedImageSrc}}):_c('img',{staticClass:"atmo-upload-card__icon",class:`atmo-upload-card__icon--${_vm.iconSize}`,attrs:{"src":require(`@/assets/images/icons/${_vm.computedIcon}`),"alt":_vm.alt}})]),(_vm.hasLabel)?_c('div',{staticClass:"atmo-upload-card__label",class:{
      'atmo-upload-card__label--small': _vm.size === 'small',
    }},[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }