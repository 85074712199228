var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ops-page"},[_vm._m(0),_c('div',{staticClass:"row",staticStyle:{"justify-content":"center"}},[_c('div',{staticClass:"col-9 ops-page__navigate-back"},[_c('router-link',{attrs:{"to":{ name: 'users.ops_pages', params: { userId: this.campaign.user.id } }}},[_c('img',{staticClass:"ops-page__go-back-image",attrs:{"src":require("@/assets/images/icons/back.png")}}),_c('p',[_vm._v("Back to campaigns")])])],1)]),_c('div',{staticClass:"row",staticStyle:{"justify-content":"center"}},[_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col ops-page__campaign-image"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.getFeaturedImage(_vm.campaign.images, 'full'),"alt":"Campaign image"},on:{"click":function($event){return _vm.openUserMediaModal({
          type: 'Image',
          caption: _vm.campaign.images[0].name,
          url: _vm.campaign.images[0].image.url,
          id: _vm.campaign.images[0].id
        })}}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"ops-page__campaign-name"},[_vm._v(" "+_vm._s(_vm.campaign.campaign_name)+" ")])]),_c('div',{staticClass:"ops-page-dates col-12"},[_c('div',{staticClass:"ops-page-dates__label-wrap"},[_vm._v(" Start Date ")]),_c('div',{staticClass:"ops-page-dates__badge-wrap"},[_c('atmo-badge',{attrs:{"label":_vm.getDate(_vm.campaign.start_date)}})],1),_c('div',{staticClass:"ops-page-dates__label-wrap"},[_vm._v(" End Date ")]),_c('div',{staticClass:"ops-page-dates__badge-wrap"},[_c('atmo-badge',{attrs:{"label":_vm.getDate(_vm.campaign.end_date)}})],1)])]),_c('div',{staticClass:"row",attrs:{"no-gutters":true}},[_c('div',{staticClass:"col-9"},[_c('span',{staticClass:"ops-page__campaign-actions"},[_c('router-link',{attrs:{"to":{ name: 'ops_pages.edit', params: { opsPageId: _vm.campaign.id } }}},[_c('button',{staticClass:"ops-page__edit-campaign"},[_vm._v("Edit")])])],1)]),_c('div',[_c('div',{staticClass:"ops-page__delete-campaign",on:{"click":function($event){return _vm.deletePage(_vm.campaign.id)}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/delete-red.png"),"data-cy":"delete-campaign"}})])])])]),_c('div',{staticClass:"col-5 ops-page__about-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"ops-page__watch-video-container",style:({
          'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)'
            +
            ','
            +
            'url(' + _vm.featuredVideo.preview_image.url + ')'
        }),on:{"click":function($event){return _vm.openUserMediaModal({
          type: 'Video',
          caption: _vm.featuredVideo.name,
          url: _vm.featuredVideo.video.url,
          id: _vm.featuredVideo.id
        })}}},[_c('img',{staticClass:"ops-page__video-icon",attrs:{"src":require("@/assets/images/icons/ops/watch_video.png")}}),_c('p',[_vm._v("Watch video")])])])]),_c('div',{staticClass:"row ops-page__donation-progress"},[_c('div',{staticClass:"col-6"},[_vm._v(" Raised: US $12,000 ")]),_c('div',{staticClass:"col-6 ops-page__donation-goal-text text-right"},[_c('img',{staticClass:"ops-page__goal-icon",attrs:{"src":require("@/assets/images/icons/goal.png"),"alt":"Campaign goal"}}),_c('p',{staticClass:"d-inline"},[_vm._v(" Goal: US $"+_vm._s(_vm.campaign.goal)+" ")])]),_c('div',{staticClass:"col-12 ops-page__donation-progress-meter"},[_c('atmo-progress-bar',{attrs:{"percent":_vm.calculatePercent(_vm.campaign)}})],1)]),_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',[_vm._v(_vm._s(_vm.campaign.campaign_description))])])])]),_c('div',{staticClass:"col-2 ops-page__donations-container"},[_vm._m(2),_c('perfect-scrollbar',_vm._l((_vm.campaign.ops_page_donations),function(donation){return _c('atmo-ops-donation',{key:donation.id,attrs:{"description":donation.description,"amount":donation.amount}})}),1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col",staticStyle:{"text-align":"center"}},[_c('h2',{staticClass:"atmo-page-header mb-5 text-center"},[_vm._v(" Atmo Campaigns ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v("About:")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v("Donation amounts:")])])])
}]

export { render, staticRenderFns }