<template>
  <div class="create-atmo-event" data-cy="create-atmo-event">
    <div class="header-container">
      <h2 class="atmo-page-header">
        Create Event
      </h2>
    </div>
    <atmo-back-link></atmo-back-link>
    <div class="create-atmo-event__form-wrap">
      <div class="create-atmo-event__form-container">
        <div class="create-atmo-event__form-container__image-container">
          <div v-if="!imagePreview" class="image-container__image-wrap editing-image"
            v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' }">
            <div class="image-wrap__icon-wrap">
              <label for="event-file-input" class="event-file-upload">
                <img class="icon-wrap__icon" src='@/assets/images/icons/profile/upload_new.png' />
                <div class="icon-wrap__text">Upload New Picture</div>
              </label>
              <input id="event-file-input" type="file" @change="handleFileUpload" style="display: none" />
            </div>
          </div>
          <div v-else class="image-container__image-wrap editing-image"
            v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${imagePreview})` }">
            <div class="image-wrap__icon-wrap">
              <label for="event-file-input" class="event-file-upload">
                <img class="icon-wrap__icon" src='@/assets/images/icons/profile/upload_new.png' />
                <div class="icon-wrap__text">Upload New Picture</div>
              </label>
              <input id="event-file-input" type="file" @change="handleFileUpload" style="display: none" />
            </div>
          </div>
        </div>
        <div class="create-atmo-event__form-container__fields-container">
          <div class="three-column-grid">
            <div class="field-wrap">
              <label for="name">Event Name</label>
              <input class="text-field" v-model="newEventName" type="text" id="name" name="name" placeholder="New Event">
            </div>
            <div class="field-wrap">
              <label for="event-date">Event Date</label>
              <div class="datepicker-trigger">
                <flat-pickr :config="flatpickrConfig" class="text-field" style="width: 100%"
                  v-model="formSelectedDateTime" modelValue="testingString" @on-change="handleDatePickerChange"
                  @on-close="handleDatePickerClose" />
              </div>
            </div>
            <div class="field-wrap">
              <label for="name">Event Description</label>
              <textarea class="text-field" v-model="newEventDetails" id="description" name="description"
                placeholder="Event Description"></textarea>
            </div>
          </div>
          <div class="three-column-grid">
            <div class="field-wrap">
              <label for="address">Address Line 1</label>
              <input class="text-field" v-model="newAddressLine1" type="text" id="address1" name="address1"
                placeholder="ex: 654 27th Ave N">
            </div>
            <div class="field-wrap">
              <label for="address">Address Line 2</label>
              <input class="text-field" v-model="newAddressLine2" type="text" id="address2" name="address2"
                placeholder="ex: 654 27th Ave N">
            </div>
            <div class="field-wrap">
              <label for="country">Country</label>
              <select v-model="newAddressCountry" class="dropdown-group" id="country" name="country"
                @change="fetchStates">
                <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
              </select>
            </div>
          </div>
          <div class="three-column-grid">
            <div class="field-wrap">
              <label for="state">State</label>
              <select v-model="newAddressState" class="dropdown-group" id="state" name="state" @change="fetchCities">
                <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
              </select>
            </div>
            <div class="field-wrap">
              <label for="state">City</label>
              <select v-model="newAddressCity" class="dropdown-group" id="state" name="state">
                <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
              </select>
            </div>
            <div class="field-wrap">
              <label for="city">Zip Code</label>
              <input class="text-field" v-model="newAddressZipCode" type="text" id="city" name="city"
                placeholder="ex: Texas City">
            </div>
          </div>
          <div class="create-ticket-container">
            <div class="create-ticket-container__header">Create Tickets</div>
            <b-form autocomplete="off" @submit.prevent="addNewTicket">
              <div class="three-column-grid">
                <div class="field-wrap">
                  <label for="address">Ticket Title</label>
                  <input v-model="newTicketTitle" class="text-field" type="text" name="event-ticket-title"
                    placeholder="Ticket title" data-cy="ticket-title" />
                </div>
                <div class="field-wrap">
                  <label for="address">Ticket Price</label>
                  <input v-model="newTicketPrice" class="text-field" type="text" name="event-ticket-price" placeholder="$"
                    data-cy="ticket-price" @input="validateNumericInput"/>
                </div>
                <div class="field-wrap">
                  <button type="button" class="atmo-button atmo-button--secondary" @click="addNewTicket"
                    style="margin-top: 1.3rem">
                    Create ticket
                  </button>
                </div>
              </div>
              <div class="three-column-grid">
                <div class="field-wrap">
                  <label for="address">Ticket Description</label>
                  <b-form-textarea v-model="newTicketDescription" class="text-field" type="text" name="event-ticket-title"
                    placeholder="Ticket description" data-cy="ticket-description" />
                </div>
              </div>
            </b-form>
            <div>
              <slot name="submit" />
            </div>
          </div>
          <div class="fields-container__button-wrap">
            <div class="button-wrap__save-cancel-container">
              <button class="save-cancel-container__cancel-button" @click="goBack()">Cancel</button>
              <button class="save-cancel-container__save-button" @click="saveNewEvent()">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="create-atmo-event__tickets-container">
        <div v-if="event.tickets.length === 0" class="mt-3">
          <div class="tickets-container__header">
            No tickets yet!
          </div>
        </div>
        <div v-else>
          <div class="tickets-container__header">
            Your Tickets
          </div>
        </div>
        <perfect-scrollbar class="tickets-scroll-wrap">
          <div class="event-ticket" v-for="ticket in event.tickets" :key="ticket.title">
            <div class="event-ticket__decoration"></div>
            <div class="event-ticket__content">
              <div class="content__description-container">
                <div>
                  <div class="description-container__title-wrap">
                    <p>
                      {{ ticket.title }}
                    </p>
                  </div>
                  <div>
                    {{ ticket.description }}
                  </div>
                </div>
                <div class="description-container__price-delete-wrap">
                  <div class="price-delete-wrap__price-wrap">
                    <div class="event-ticket__price">
                      {{ returnCurrencyFormat(ticket.price) }}
                    </div>
                  </div>
                  <div class="price-delete-wrap__delete-wrap">
                    <div class="delete-wrap__icon-wrap" @click="removeNewTicket(ticket.id)">
                      <img src="@/assets/images/icons/delete.png">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import {
  callEventsCreate,
  callEventTicketsCreate,
  callAddressesCreate
} from '@/helpers/axiosCalls';
import format from 'date-fns/format';
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {
  BForm,
  BFormTextarea
} from "bootstrap-vue";
import { } from "bootstrap-vue";
import { formatDateToMonthDayYearHour, formatPriceAsCurrency } from '@/helpers/utilityFunctions';
import AtmoBackLink from '@/components/atmo-back-link';
import Moment from "moment";
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    FlatPickr,
    BForm,
    BFormTextarea,
    AtmoBackLink
  },
  data() {
    return {
      file: '',
      isLoading: false,
      formSelectedDateTime: null,
      flatpickrConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
      },
      event: {
        tickets: []
      },
      newTicketTitle: null,
      newTicketDescription: null,
      newTicketPrice: null,
      newEventImage: null,
      newEventName: null,
      newEventDetails: null,
      newAddressLine1: null,
      newAddressLine2: null,
      newAddressCity: null,
      newAddressState: null,
      newAddressZipCode: null,
      newAddressCountry: null,
      imagePreview: null,
      ticketPriceIsValid: true,
    }
  },
  computed: {
    ...mapState('address', ['countries', 'states', 'cities']),
    ...mapGetters('address', ['getCountryById', 'getStateById', 'getCityById']),
  },
  created() {
    this.fetchCountries();
  },
  methods: {
    ...mapActions('address', ['fetchCountries', 'fetchStates', 'fetchCities']),
    validateNumericInput() {
      // Use a regular expression to check if the input only contains numbers
      const numericRegex = /^-?\d*\.?\d+$/;

      // Update ticketPriceIsValid based on the validation result
      this.ticketPriceIsValid = numericRegex.test(this.newTicketPrice);
    },
    getCountryName(countryId) {
      const country = this.getCountryById(countryId);
      return country ? country.name : '';
    },
    getStateName(stateId) {
      const state = this.getStateById(stateId);
      return state ? state.name : '';
    },
    getCityName(cityId) {
      const city = this.getCityById(cityId);
      return city ? city.name : '';
    },
    handleDatePickerClose() {
      console.log("CLOSE");
    },
    handleDatePickerChange(selectedDates, dateStr) {
      // console.log("dateStr", dateStr);
      const formattedDate = Moment(dateStr, 'YYYY-MM-DD HH:mm').utcOffset(0, true).toISOString();
      // console.log("CHANGE", formattedDate);
      this.formSelectedDateTime = formattedDate;
    },
    formatDate(dateString) {
      return formatDateToMonthDayYearHour(dateString)
    },
    returnCurrencyFormat(price) {
      return formatPriceAsCurrency(price)
    },
    formatDates(startDate, endDate) {
      let formattedDates = ''
      if (startDate) {
        formattedDates = format(startDate, this.dateFormat)
      }
      if (endDate) {
        formattedDates += ' - ' + format(endDate, this.dateFormat)
      }

      return formattedDates
    },
    addNewTicket() {
      if (!this.ticketPriceIsValid) {
        this.$notify({ type: 'error', group: 'vue-app', title: 'Please enter valid ticket price.' });
        return;
      }

      if (!this.newTicketTitle || !this.newTicketDescription || !this.newTicketPrice) {
        this.$notify({ type: 'error', group: 'vue-app', title: 'Please fill out all ticket fields.' });
        return;
      }

      this.event.tickets = this.event.tickets.concat({
        title: this.newTicketTitle,
        description: this.newTicketDescription,
        price: this.newTicketPrice
      });

      this.newTicketTitle = null;
      this.newTicketDescription = null;
      this.newTicketPrice = null;
    },
    removeNewTicket(newTicketId) {
      let index = this.event.tickets.findIndex(ticket => ticket.id === newTicketId)
      this.event.tickets.splice(index, 1);
    },
    goBack() {
      this.$router.back();
    },
    async handleFileUpload(event) {
      // Handle file upload logic here
      const inputElement = event.target;
      const selectedFile = inputElement.files[0];
      // Do something with the selected file
      console.log("SELECTED FILE", selectedFile)

      if (!selectedFile) {
        // Handle no file selected error
        return;
      }

      if (inputElement.files && inputElement.files[0]) {
        const reader = new FileReader();

        // Read the contents of the file as a data URL
        reader.readAsDataURL(inputElement.files[0]);

        // Set up the event handler for when the file reading is complete
        reader.onload = () => {
          // Set the image preview using Vue's data property
          this.imagePreview = reader.result;
        };
      }

      const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (imageMimeTypes.includes(selectedFile.type)) {
        // It's an image
        this.newEventImage = selectedFile;

      } else {
        // Invalid file type
        console.error('Invalid file type. Please select a valid image file.');
        // You can reset the file input here to clear the selection
      }
    },
    validateForm() {
      // Check if all required fields are filled out
      // console.log(this.event.tickets,
      //   this.formSelectedDateTime,
      //   this.newEventImage,
      //   this.newEventName,
      //   this.newEventDetails,
      //   this.newAddressLine1,
      //   this.newAddressCity,
      //   this.newAddressState,
      //   this.newAddressZipCode,
      //   this.newAddressCountry)

      return (
        this.event.tickets.length !== 0 &&
        this.formSelectedDateTime.trim() !== '' &&
        this.newEventImage &&
        this.newEventName.trim() !== '' &&
        this.newEventDetails.trim() !== '' &&
        this.newAddressLine1 &&
        this.newAddressCity &&
        this.newAddressState &&
        this.newAddressZipCode &&
        this.newAddressCountry
      );
    },
    saveNewEvent() {
      if (!this.validateForm()) {
        this.$notify({ type: 'error', group: 'vue-app', title: 'Missing required fields.' });
        return;
      }

      const formData = new FormData();
      if (this.newEventImage) formData.append('image', this.newEventImage);
      formData.append('name', this.newEventName);
      formData.append('datetime', this.formSelectedDateTime);
      formData.append('details', this.newEventDetails);
      formData.append('user_id', this.$store.state.currentUserId);
      formData.append('event_type', 'concert');
      formData.append('location_type', 'hybrid');
      formData.append('is_draft', false);

      callEventsCreate(formData)
        .then((response) => {
          const ticketPromises = this.event.tickets.map(async (ticket) => {
            return callEventTicketsCreate(response.id, {
              title: ticket.title,
              description: ticket.description,
              price: ticket.price,
              atmo_event_id: response.id
            })
              .catch((error) => {
                console.error(error);
              });
          });

          const addressPromise = callAddressesCreate({
            line_1: this.newAddressLine1,
            line_2: this.newAddressLine2,
            city_id: this.newAddressCity,
            state_id: this.newAddressState,
            zip_code: this.newAddressZipCode,
            country_id: this.newAddressCountry,
            addressable_id: response.id,
            addressable_type: "AtmoEvent",
          })
            .catch((error) => {
              console.error(error);
            });

          Promise.all([...ticketPromises, addressPromise]).then(() => {
            this.$notify({ group: 'vue-app', title: 'Event Saved' });
            this.$router.push({
              name: 'events.admin_index',
              params: { userId: this.$store.state.currentUserId }
            });
          })
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.create-atmo-event {
  margin: 0 75px;

  textarea,
  textarea::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
  }

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: '';
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;

      &::before {
        opacity: 1;
      }

      ~label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }


  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .create-atmo-event__form-wrap {
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr 17rem;
  }

  .create-atmo-event__form-container {
    display: grid;
    gap: 3rem;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: 10rem 1fr;
    // border: 1px solid white;

    .event-file-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
    }

    .image-container__image-wrap {
      height: 11rem;
      width: 11rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-bottom: 1rem;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .image-wrap__icon-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .icon-wrap__icon {
      height: 4rem;
      margin-bottom: 1rem;
    }

    .icon-wrap__text {
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 600;
    }

    .image-wrap__image {
      border-radius: 5px;
      margin-bottom: .8rem;
      box-sizing: border-box;
    }

    .editing-image {
      border: 3px dashed white;
    }

    .image-container__select-image-container {
      max-height: 12rem;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
    }

    .select-image-container__image-wrap {
      height: 5rem;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-wrap__select-wrap {
      height: 1.5rem;
      width: 1.5rem;
      background: $atmo-purple--medium-dark--overlay-faded;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .two-column-grid {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 1.5rem;
    }

    .three-column-grid {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 1.5rem;
    }

    .four-column-grid {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 1.5rem;
    }

    .field-wrap {
      display: flex;
      flex-direction: column;

      label {
        text-transform: uppercase;
        margin-bottom: .5rem;
        font-size: .9rem;
        font-weight: 500;
      }

      .text-field {
        background: transparent;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, .4);
        padding: .5rem .5rem .5rem .5rem;
        color: white;
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(255, 255, 255, .4);
        opacity: 1;
        /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(255, 255, 255, .4);
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(255, 255, 255, .4);
      }

      .dropdown-group {
        background: $atmo-purple--medium-dark;
        color: white;
        text-transform: uppercase;
        border: none;
        border-radius: 5px;
        padding: .5rem;
        font-weight: 500rem;
        cursor: pointer;
      }

      .dropdown-field {}

      .radio-group {
        display: flex;
        padding: .6rem 0rem .6rem 0rem;
      }

      .radio-field-wrap {
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .radio-field {
        margin-right: .5rem;
        cursor: pointer;
      }

      .radio-field-label {
        margin: 2px 0px 0px 0px;
      }
    }

    .create-ticket-container {
      margin-top: 2rem;
    }

    .create-ticket-container__header {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 1rem;
      color: $atmo-blue--medium;
    }

    .fields-container__button-wrap {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 1rem;
    }

    .button-wrap__save-cancel-container {
      display: flex;

      .save-cancel-container__save-button {
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
      }

      .save-cancel-container__cancel-button {
        border: 1px solid white;
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        border-radius: 5px;
        padding: .5rem 1.5rem .5rem 1.5rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    // .button-wrap__account-settings-container {
    //   display: flex;
    //   margin-left: auto;

    //   .account-settings-container__account-settings-button {
    //     font-size: 1rem;
    //     text-transform: uppercase;
    //     color: white;
    //     background-color: $atmo-purple--medium;
    //     border-radius: 5px;
    //     padding: .5rem 1.5rem .5rem 1.5rem;
    //     font-weight: 500;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //   }

    //   .account-settings-button__image {
    //     height: 1rem;
    //     width: auto;
    //     margin-right: .5rem;
    //   }
    // }
  }

  .create-atmo-event__tickets-container {
    .tickets-container__header {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: .4rem;
      text-transform: uppercase;
    }

    .event-ticket {
      background-color: rgba($atmo-purple--extra-dark, 0.3);
      backdrop-filter: blur(5px);
      min-height: 80px;
      font-size: $atmo-base-size;
      border-radius: 5px;
      position: relative;
      margin-bottom: 1rem;
      font-size: .8rem;

      &:hover {
        .event-ticket__decoration {
          background-color: $atmo-purple--medium;
        }
      }

      .event-ticket__decoration {
        background-color: white;
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: .5rem;
        position: absolute;
        top: 0rem;
        bottom: 0rem;
        left: 0rem;
      }

      .event-ticket__content {
        padding: .5rem .5rem .5rem .5rem;
        margin-left: .5rem;
        font-size: .8rem;
      }

      .content__description-container {
        padding: .5rem 0rem .5rem 0rem;
        display: flex;
        justify-content: space-between;
        // margin-bottom: 1rem;
        // border-bottom: 1px solid white;
      }

      .description-container__title-wrap {
        margin-bottom: 1rem;
        font-size: .8rem;
        color: $atmo-blue--medium;
        font-weight: 600;
      }

      .description-container__price-delete-wrap {
        max-width: 8rem;
      }

      .price-delete-wrap__price-wrap {
        margin-bottom: 1rem;
      }

      .price-delete-wrap__delete-wrap {
        height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 1.2rem;
          cursor: pointer;
        }
      }

      .content__date-title-container {
        display: flex;
        justify-content: space-between;
      }

      &__price {
        // font-size: $atmo-subtitle-size;
        font-weight: bold;
        display: flex;
        flex-direction: row-reverse;
      }

      &__incrementor {
        img {
          max-width: 12px;
        }

        button {
          background: $atmo-purple--dark;
          border-color: $atmo-purple--dark;
        }

        &__left-button {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;

          img {
            padding-bottom: 3px;
          }
        }

        &__right-button {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }

      &__date {
        border-color: white;

        img {
          vertical-align: middle;
          max-width: 12px;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>