import Axios from 'axios';

export function callPlaylistCollaboratorsIndex(userId, playlistId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/playlists/${playlistId}/playlist_collaborators`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPlaylistCollaboratorsCreate(userId, playlistId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/playlists/${playlistId}/playlist_collaborators`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}


export function callPlaylistCollaboratorsDelete(userId, playlistId, collaboratorId, queryParams = {}) {
    return Axios.delete(`/api/v1/users/${userId}/playlists/${playlistId}/playlist_collaborators/${collaboratorId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}