<template>
  <div>
    <h1>ACCOMPLISHMENT</h1>
    <template>
      <p>{{ userAccomplishment.accomplishment.name }}</p>
      <p>{{ userAccomplishment.accomplishment.description }}</p>
      <p>{{ userAccomplishment.accomplishment.points }}</p>
    </template>
  </div>
</template>


<script>
  import { callUserAccomplishmentsShow } from '@/helpers/axiosCalls';

  export default {
    data: function(){
      return {
        userAccomplishment: {}
      }
    },
    created(){
      this.getAccomplishment();
    },
    methods: {
      getAccomplishment () {
        callUserAccomplishmentsShow(this.$store.state.currentUserId, this.$route.params.accomplishmentId)
          .then(response => (this.userAccomplishment = response));
      }
    }
  }
</script>
