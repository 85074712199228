import Axios from 'axios';

export function callStripeSubscriptionsGet(supscriptionId, queryParams = {}) {
    const authorizationToken = "Bearer sk_test_R87ol4y4QdQVp354Dwyy45pi";

    return Axios.get(`https://api.stripe.com/v1/subscriptions/${supscriptionId}`, {
        params: queryParams,
        headers: {
            Authorization: authorizationToken
        }
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        })
}


export function callStripeSubscriptionsPost(supscriptionId, queryParams = {}) {
    const authorizationToken = "Bearer sk_test_R87ol4y4QdQVp354Dwyy45pi";

    return Axios({
        method: 'post',
        url: `https://api.stripe.com/v1/subscriptions/${supscriptionId}`,
        params: queryParams,
        headers: {
            Authorization: authorizationToken
        }
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}

export function callStripeSubscriptionsDelete(subscriptionId, queryParams = {}) {
    const authorizationToken = "Bearer sk_test_R87ol4y4QdQVp354Dwyy45pi";

    return Axios({
        method: 'delete',
        url: `https://api.stripe.com/v1/subscriptions/${subscriptionId}`,
        params: queryParams,
        headers: {
            Authorization: authorizationToken
        }
    })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
}