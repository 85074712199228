export default class atmoMusicExtractor {
  constructor(array) {
    this.songNotesArray = array[0];

    // console.log("-------", this.songNotesArray.length, "arrays detected");
    // console.log("------- SONG NOTES ARRAY", this.songNotesArray);

  }

  findKey() {
    // utility function for sorting scales and chords by chroma value
    let sortScalesAndChords = (arr) => {
      arr.sort(function (a, b) {
        return b.total - a.total;
      });
      //console.log(`ALL ${type} TOTALS SORTED:`, arr);
    }

    // utility function for removing duplicates in an array
    let removeDuplicates = (originalArray) => {
      return originalArray.filter(function(item, index){
        return originalArray.indexOf(item) >= index;
      });
    }

      this.CTotal = [];
      this.CSharpTotal = [];
      this.DTotal = [];
      this.DSharpTotal = [];
      this.ETotal = [];
      this.FTotal = [];
      this.FSharpTotal = [];
      this.GTotal = [];
      this.GSharpTotal = [];
      this.ATotal = [];
      this.ASharpTotal = [];
      this.BTotal = [];

      // if the array passed into class constructor is not empty
      if (this.songNotesArray.length > 0) {

        this.C = this.songNotesArray[0];
        this.CSharp = this.songNotesArray[1];
        this.DFlat = this.songNotesArray[1];
        this.D = this.songNotesArray[2];
        this.DSharp = this.songNotesArray[3];
        this.EFlat = this.songNotesArray[3];
        this.E = this.songNotesArray[4];
        this.ESharp = this.songNotesArray[5];
        this.F = this.songNotesArray[5];
        this.FSharp = this.songNotesArray[6];
        this.GFlat = this.songNotesArray[6];
        this.G = this.songNotesArray[7];
        this.GSharp = this.songNotesArray[8];
        this.AFlat = this.songNotesArray[8];
        this.A = this.songNotesArray[9];
        this.ASharp = this.songNotesArray[10];
        this.BFlat = this.songNotesArray[10];
        this.B = this.songNotesArray[11];

        this.CTotal.push(this.C);
        this.CSharpTotal.push(this.CSharp);
        this.DTotal.push(this.D)
        this.DSharpTotal.push(this.DSharp);
        this.ETotal.push(this.E);
        this.FTotal.push(this.F)
        this.FSharpTotal.push(this.FSharp);
        this.GTotal.push(this.G);
        this.GSharpTotal.push(this.GSharp);
        this.ATotal.push(this.A);
        this.ASharpTotal.push(this.ASharp);
        this.BTotal.push(this.B);

        // construct chromatic scale and corresponding chroma for each array
        this.notesArrayForSorting = [this.C, this.CSharp, this.D, this.DSharp, this.E, this.F, this.FSharp, this.G, this.GSharp, this.A, this.ASharp, this.B];
        // console.log("CHROMA FOUND:", this.notesArrayForSorting);

        // sort each chromatic scale by corresponding chroma (descending)
        this.notesArrayForSorting.sort(function (a, b) {
          return b.total - a.total;
        });

        this.rootFound = this.notesArrayForSorting[0];
        // console.log("ROOT FOUND", this.rootFound);

        // NEED TO FIND COUNTS FOR EACH ROOT FOUND

        // major scales
        this.CMajorScale = [this.C, this.D, this.E, this.F, this.G, this.A, this.B];
        this.DFlatMajorScale = [this.DFlat, this.EFlat, this.F, this.GFlat, this.AFlat, this.BFlat, this.C];
        this.DMajorScale = [this.D, this.E, this.FSharp, this.G, this.A, this.B, this.CSharp];
        this.EFlatMajorScale = [this.EFlat, this.F, this.G, this.A, this.BFlat, this.C, this.D];
        this.EMajorScale = [this.E, this.FSharp, this.GSharp, this.A, this.B, this.CSharp, this.DSharp];
        this.FMajorScale = [this.F, this.G, this.A, this.BFlat, this.C, this.D, this.E];
        this.FSharpMajorScale = [this.FSharp, this.GSharp, this.ASharp, this.B, this.CSharp, this.DSharp, this.ESharp]
        this.GMajorScale = [this.G, this.A, this.B, this.C, this.D, this.E, this.FSharp];
        this.AFlatMajorScale = [this.AFlat, this.BFlat, this.C, this.DFlat, this.EFlat, this.F, this.G];
        this.AMajorScale = [this.A, this.B, this.CSharp, this.D, this.E, this.FSharp, this.GSharp];
        this.BFlatMajorScale = [this.BFlat, this.C, this.D, this.EFlat, this.F, this.G, this.A];
        this.BMajorScale = [this.B, this.CSharp, this.DSharp, this.E, this.FSharp, this.GSharp, this.ASharp];

        this.majorScales = [
          this.CMajorScale,
          this.DFlatMajorScale,
          this.DMajorScale,
          this.EFlatMajorScale,
          this.EMajorScale,
          this.FMajorScale,
          this.FSharpMajorScale,
          this.GMajorScale,
          this.AFlatMajorScale,
          this.AMajorScale,
          this.BFlatMajorScale,
          this.BMajorScale
        ];

        // natural minor scales
        this.CNaturalMinorScale = [this.C, this.D, this.EFlat, this.F, this.G, this.A, this.BFlat];
        this.DFlatNaturalMinorScale = [this.DFlat, this.EFlat, this.FFlat, this.GFlat, this.AFlat, this.BFlat, this.CFlat];
        this.DNaturalMinorScale = [this.D, this.E, this.F, this.G, this.A, this.B, this.C];
        this.EFlatNaturalMinorScale = [this.EFlat, this.F, this.GFlat, this.A, this.BFlat, this.C, this.DFlat];
        this.ENaturalMinorScale = [this.E, this.FSharp, this.G, this.A, this.B, this.CSharp, this.D];
        this.FNaturalMinorScale = [this.F, this.G, this.AFlat, this.BFlat, this.C, this.D, this.EFlat];
        this.FSharpNaturalMinorScale = [this.FSharp, this.GSharp, this.A, this.B, this.CSharp, this.DSharp, this.E]
        this.GNaturalMinorScale = [this.G, this.A, this.BFlat, this.C, this.D, this.E, this.F];
        this.AFlatNaturalMinorScale = [this.AFlat, this.BFlat, this.CFlat, this.DFlat, this.EFlat, this.F, this.GFlat];
        this.ANaturalMinorScale = [this.A, this.B, this.C, this.D, this.E, this.FSharp, this.G];
        this.BFlatNaturalMinorScale = [this.BFlat, this.C, this.DFlat, this.EFlat, this.F, this.G, this.AFlat];
        this.BNaturalMinorScale = [this.B, this.CSharp, this.D, this.E, this.FSharp, this.GSharp, this.A];

        this.naturalMinorScales = [
          this.CNaturalMinorScale,
          this.DFlatNaturalMinorScale,
          this.DNaturalMinorScale,
          this.EFlatNaturalMinorScale,
          this.ENaturalMinorScale,
          this.FNaturalMinorScale,
          this.FSharpNaturalMinorScale,
          this.GNaturalMinorScale,
          this.AFlatNaturalMinorScale,
          this.ANaturalMinorScale,
          this.BFlatNaturalMinorScale,
          this.BNaturalMinorScale
        ];

        // harmonic minor scales
        this.CHarmonicMinorScale = [this.C, this.D, this.EFlat, this.F, this.G, this.AFlat, this.B];
        this.DFlatHarmonicMinorScale = [this.DFlat, this.EFlat, this.FFlat, this.GFlat, this.AFlat, this.A, this.C];
        this.DHarmonicMinorScale = [this.D, this.E, this.F, this.G, this.A, this.BFlat, this.CSharp];
        this.EFlatHarmonicMinorScale = [this.EFlat, this.F, this.GFlat, this.A, this.BFlat, this.B, this.D];
        this.EHarmonicMinorScale = [this.E, this.FSharp, this.G, this.A, this.B, this.C, this.DSharp];
        this.FHarmonicMinorScale = [this.F, this.G, this.AFlat, this.BFlat, this.C, this.DFlat, this.E];
        this.FSharpHarmonicMinorScale = [this.FSharp, this.GSharp, this.A, this.B, this.CSharp, this.D, this.ESharp]
        this.GHarmonicMinorScale = [this.G, this.A, this.BFlat, this.C, this.D, this.EFlat, this.FSharp];
        this.AFlatHarmonicMinorScale = [this.AFlat, this.BFlat, this.CFlat, this.DFlat, this.EFlat, this.E, this.G];
        this.AHarmonicMinorScale = [this.A, this.B, this.C, this.D, this.E, this.F, this.GSharp];
        this.BFlatHarmonicMinorScale = [this.BFlat, this.C, this.DFlat, this.EFlat, this.F, this.GFlat, this.A];
        this.BHarmonicMinorScale = [this.B, this.CSharp, this.D, this.E, this.FSharp, this.G, this.ASharp];

        this.harmonicMinorScales = [
          this.CHarmonicMinorScale,
          this.DFlatHarmonicMinorScale,
          this.DHarmonicMinorScale,
          this.EFlatHarmonicMinorScale,
          this.EHarmonicMinorScale,
          this.FHarmonicMinorScale,
          this.FSharpHarmonicMinorScale,
          this.GHarmonicMinorScale,
          this.AFlatHarmonicMinorScale,
          this.AHarmonicMinorScale,
          this.BFlatHarmonicMinorScale,
          this.BHarmonicMinorScale
        ];

        this.allScales = [this.majorScales, this.naturalMinorScales, this.harmonicMinorScales];

        // define major triads in each array
        this.CMajorTriad = [this.C, this.E, this.G];
        this.CSharpMajorTriad = [this.DFlat, this.F, this.AFlat];
        this.DFlatMajorTriad = [this.DFlat, this.F, this.AFlat];
        this.DMajorTriad = [this.D, this.FSharp, this.A];
        this.DSharpMajorTriad = [this.EFlat, this.G, this.BFlat];
        this.EFlatMajorTriad = [this.EFlat, this.G, this.BFlat];
        this.EMajorTriad = [this.E, this.GSharp, this.B];
        this.ESharpMajorTriad = [this.F, this.A, this.C];
        this.FMajorTriad = [this.F, this.A, this.C];
        this.FSharpMajorTriad = [this.FSharp, this.ASharp, this.CSharp]
        this.GFlatMajorTriad = [this.FSharp, this.ASharp, this.CSharp];
        this.GMajorTriad = [this.G, this.B, this.D];
        this.GSharpMajorTriad = [this.AFlat, this.C, this.EFlat];
        this.AFlatMajorTriad = [this.AFlat, this.C, this.EFlat];
        this.AMajorTriad = [this.A, this.CSharp, this.E];
        this.ASharpMajorTriad = [this.BFlat, this.D, this.F];
        this.BFlatMajorTriad = [this.BFlat, this.D, this.F];
        this.BMajorTriad = [this.B, this.DSharp, this.FSharp];
        this.BSharpMajorTriad = [this.C, this.E, this.G];

        // group major triads in each array
        this.majorTriads = [
          this.CMajorTriad,
          this.DFlatMajorTriad,
          this.DMajorTriad,
          this.EFlatMajorTriad,
          this.EMajorTriad,
          this.FMajorTriad,
          this.FSharpMajorTriad,
          this.GFlatMajorTriad,
          this.GMajorTriad,
          this.AFlatMajorTriad,
          this.AMajorTriad,
          this.BFlatMajorTriad,
          this.BMajorTriad,
          this.CSharpMajorTriad,
          this.DSharpMajorTriad,
          this.ESharpMajorTriad,
          this.GSharpMajorTriad,
          this.ASharpMajorTriad,
          this.BSharpMajorTriad
        ];

        //define minor triads in each array
        this.CMinorTriad = [this.C, this.EFlat, this.G];
        this.CSharpMinorTriad = [this.DFlat, this.E, this.AFlat];
        this.DFlatMinorTriad = [this.DFlat, this.E, this.AFlat];
        this.DMinorTriad = [this.D, this.F, this.A];
        this.DSharpMinorTriad = [this.EFlat, this.GFlat, this.BFlat];
        this.EFlatMinorTriad = [this.EFlat, this.GFlat, this.BFlat];
        this.EMinorTriad = [this.E, this.G, this.B];
        this.ESharpMinorTriad = [this.F, this.AFlat, this.C];
        this.FMinorTriad = [this.F, this.AFlat, this.C];
        this.FSharpMinorTriad = [this.FSharp, this.A, this.CSharp]
        this.GFlatMinorTriad = [this.FSharp, this.A, this.CSharp];
        this.GMinorTriad = [this.G, this.BFlat, this.D];
        this.GSharpMinorTriad = [this.AFlat, this.B, this.EFlat];
        this.AFlatMinorTriad = [this.AFlat, this.B, this.EFlat];
        this.AMinorTriad = [this.A, this.C, this.E];
        this.ASharpMinorTriad = [this.BFlat, this.DFlat, this.F];
        this.BFlatMinorTriad = [this.BFlat, this.DFlat, this.F];
        this.BMinorTriad = [this.B, this.D, this.FSharp];
        this.BSharpMinorTriad = [this.C, this.EFlat, this.G];

        //group minor triads in each array
        this.minorTriads = [
          this.CMinorTriad,
          this.DFlatMinorTriad,
          this.DMinorTriad,
          this.EFlatMinorTriad,
          this.EMinorTriad,
          this.FMinorTriad,
          this.FSharpMinorTriad,
          this.GFlatMinorTriad,
          this.GMinorTriad,
          this.AFlatMinorTriad,
          this.AMinorTriad,
          this.BFlatMinorTriad,
          this.BMinorTriad,
          this.CSharpMinorTriad,
          this.DSharpMinorTriad,
          this.ESharpMinorTriad,
          this.GSharpMinorTriad,
          this.ASharpMinorTriad,
          this.BSharpMinorTriad
        ];

        //define diminished triads in each array
        this.CDiminishedTriad = [this.C, this.EFlat, this.FSharp];
        this.CSharpDiminishedTriad = [this.DFlat, this.E, this.G];
        this.DFlatDiminishedTriad = [this.DFlat, this.E, this.G];
        this.DDiminishedTriad = [this.D, this.F, this.AFlat];
        this.DSharpDiminishedTriad = [this.EFlat, this.FSharp, this.A];
        this.EFlatDiminishedTriad = [this.EFlat, this.FSharp, this.A];
        this.EDiminishedTriad = [this.E, this.G, this.BFlat];
        this.ESharpDiminishedTriad = [this.F, this.AFlat, this.B];
        this.FDiminishedTriad = [this.F, this.AFlat, this.B];
        this.FSharpDiminishedTriad = [this.FSharp, this.A, this.C]
        this.GDiminishedTriad = [this.G, this.BFlat, this.DFlat];
        this.GSharpDiminishedTriad = [this.AFlat, this.B, this.D];
        this.AFlatDiminishedTriad = [this.AFlat, this.B, this.D];
        this.ADiminishedTriad = [this.A, this.C, this.EFlat];
        this.ASharpDiminishedTriad = [this.BFlat, this.DFlat, this.E];
        this.BFlatDiminishedTriad = [this.BFlat, this.DFlat, this.E];
        this.BDiminishedTriad = [this.B, this.D, this.F];
        this.BSharpDiminishedTriad = [this.C, this.EFlat, this.FSharp];

        //group diminished triads in each array
        this.diminishedTriads = [
          this.CDiminishedTriad,
          this.DFlatDiminishedTriad,
          this.DDiminishedTriad,
          this.EFlatDiminishedTriad,
          this.EDiminishedTriad,
          this.FDiminishedTriad,
          this.FSharpDiminishedTriad,
          this.GDiminishedTriad,
          this.AFlatDiminishedTriad,
          this.ADiminishedTriad,
          this.BFlatDiminishedTriad,
          this.BDiminishedTriad,
          this.CSharpDiminishedTriad,
          this.DSharpDiminishedTriad,
          this.ESharpDiminishedTriad,
          this.GDiminishedTriad,
          this.GSharpDiminishedTriad,
          this.ASharpDiminishedTriad,
          this.BSharpDiminishedTriad
        ];

        this.majorTriadMatchArray = [];
        this.minorTriadMatchArray = [];

        // get all major triads
        for (let j=0; j < this.majorTriads.length; j++) {
          this.chord = this.majorTriads[j];
          // loop through each note in a chord
          for (let k=0; k < this.chord.length; k++) {
            // if note is equal to chunk's root, push to triad array
            if (this.chord[k] == this.rootFound) {
              this.majorTriadMatchArray.push(this.chord);
            }
          }
        }

        sortScalesAndChords(this.majorTriadMatchArray);
        removeDuplicates(this.majorTriadMatchArray);

        // get all minor triads
        for (let j=0; j < this.minorTriads.length; j++) {
          this.chord = this.minorTriads[j];
          // loop through each note in a chord
          for (let k=0; k < this.chord.length; k++) {
            // if note is equal to chunk's root, push to triad array
            if (this.chord[k] == this.rootFound) {
              this.minorTriadMatchArray.push(this.chord);
            }
          }
        }

        sortScalesAndChords(this.minorTriadMatchArray);
        removeDuplicates(this.minorTriadMatchArray);
      }

      let majorTriadTotalObject = {};
      let majorTriadTotal = 0;
      let majorTriadTotalObjectArray = [];

      // get all major chords previously matched
      for (let i=0; i < this.majorTriadMatchArray.length; i++) {
        this.chord = this.majorTriadMatchArray[i];
        for (let j=0; j < this.chord.length; j++) {
          majorTriadTotal += this.chord[j].total;
        }
        majorTriadTotalObject = {key: this.chord[0].note, total: majorTriadTotal};
        majorTriadTotalObjectArray.push(majorTriadTotalObject);
        majorTriadTotal = 0;
      }

      sortScalesAndChords(majorTriadTotalObjectArray);

      let minorTriadTotalObject = {};
      let minorTriadTotal = 0;
      let minorTriadTotalObjectArray = [];

      // get all minor chords previously matched
      for (let i=0; i < this.minorTriadMatchArray.length; i++) {
        this.chord = this.minorTriadMatchArray[i];
        for (let j=0; j < this.chord.length; j++) {
          minorTriadTotal += this.chord[j].total;
        }
        minorTriadTotalObject = {key: this.chord[0].note, total: minorTriadTotal};
        minorTriadTotalObjectArray.push(minorTriadTotalObject);
        minorTriadTotal = 0;
      }

      sortScalesAndChords(minorTriadTotalObjectArray);

      // after finding major/minor chord matches, we need to eliminate bad matches and find the true root/tonality somehow

      function findByAttr(array, attr, value) {
          for(let i = 0; i < array.length; i += 1) {
              if(array[i][attr] === value) {
                  return i;
              }
          }
          return -1;
      }

      // console.log("--------ROOT FOUND:", this.rootFound);

      let min3rd = "";
      let maj3rd = "";

      switch (this.rootFound.note) {

        case "B#/C":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'D#/Eb');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'E');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        case "C#/Db":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'G');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'G#/Ab');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        case "D":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'E#/F');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'F#/Gb');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        case "D#/Eb":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'F#/Gb');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'G');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        case "E":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'G');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'G#/Ab');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
            // console.log("------MINOR");
          } else {
            this.tonality = "Major";
            // console.log("------MAJOR");
          }
          break;

        case "E#/F":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'G#/Ab');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'A');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        case "F#/Gb":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'A');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'A#/Bb');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        case "G":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'A#/Bb');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'B');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        case "G#/Ab":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'B');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'B#/C');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        case "A":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'B#/C');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'C#/Db');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        case "A#/Bb":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'C#/Db');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'D');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        case "B":
          min3rd = findByAttr(this.notesArrayForSorting, 'note', 'D');
          maj3rd = findByAttr(this.notesArrayForSorting, 'note', 'D#/Eb');

          if (this.notesArrayForSorting[min3rd].total > this.notesArrayForSorting[maj3rd].total) {
            this.tonality = "Minor";
          } else {
            this.tonality = "Major";
          }
          break;

        default:
          console.log("CAN'T RENDER KEY");
      }
      // console.log("TONALITY:", this.rootFound.note, this.tonality);

      this.songTotals = [
        this.CTotal,
        this.CSharpTotal,
        this.DTotal,
        this.DSharpTotal,
        this.ETotal,
        this.FTotal,
        this.FSharpTotal,
        this.GTotal,
        this.GSharpTotal,
        this.ATotal,
        this.ASharpTotal,
        this.BTotal,
      ]

          // console.log("------ SONG TOTALS:", this.songTotals);

          // this.firstHalf = [];
          // this.secondHalf = [];

      this.songTotalsNotes = [];
      let noteObject = {};
      let item = [];

      for (let i=0; i < this.songTotals.length; i++) {
        item = this.songTotals[i];
        noteObject = {note: item[0].note, 'total': item[0].total};
        this.songTotalsNotes.push(noteObject);
      }

      sortScalesAndChords(this.songTotalsNotes);
      // console.log(this.songTotalsNotes);

      this.majorandMinorScales = this.naturalMinorScales.concat(this.majorScales);

      let matchingScales = [];

      // get all minor triads
      for (let j=0; j < this.majorandMinorScales.length; j++) {
        this.scale = this.majorandMinorScales[j];
        // loop through each note in a chord
        for (let k=0; k < this.scale.length; k++) {
          // if note is equal to chunk's root, push to triad array
          for (let l=0; l < this.songTotalsNotes.length; l++) {
            if (this.scale[k] == this.rootFound) {
                matchingScales.push(this.scale);
            }
          }
        }
      }


    sortScalesAndChords(matchingScales);
    this.scalesMatched = removeDuplicates(matchingScales);
    // console.log(this.scalesMatched);

    let Cmaj_Amin_fam = {
        name: "Cmaj/Amin family",
        notes: ["B#/C", "D", "E", "E#/F", "G", "A", "B"],
        sharps: 0,
        flats: 0,
        major: [this.CMajorTriad, this.DMinorTriad, this.EMinorTriad, this.FMajorTriad, this.GMajorTriad, this.AMinorTriad, this.BDiminishedTriad],
        minor: [this.AMinorTriad, this.BDiminishedTriad, this.CMajorTriad, this.DMinorTriad, this.EMinorTriad, this.FMajorTriad, this.GMajorTriad],
      }

    let Dbmaj_Bbmin_fam = {
        name: "Dbmaj/Bbmin family",
        notes: ["C#/Db", "D#/Eb", "E#/F", "F#/Gb", "G#/Ab", "A#/Bb", "B#/C"],
        sharps: 0,
        flats: 5,
        major: [this.DFlatMajorTriad, this.EFlatMinorTriad, this.FMinorTriad, this.GFlatMajorTriad, this.AFlatMajorTriad, this.BFlatMinorTriad, this.CDiminishedTriad],
        minor: [this.BFlatMinorTriad, this.CDiminishedTriad, this.DFlatMajorTriad, this.EFlatMinorTriad, this.FMinorTriad, this.GFlatMajorTriad, this.AFlatMajorTriad],
      }

    let Dmaj_Bmin_fam = {
      name: "Dmaj/Bmin family",
      notes: ["D", "E", "F#/Gb", "G", "A", "B", "C#/Db"],
      sharps: 2,
      flats: 0,
      major: [this.DMajorTriad, this.EMinorTriad, this.FMinorTriad, this.GMajorTriad, this.AMajorTriad, this.BMinorTriad, this.CSharpDiminishedTriad],
      minor: [this.BMinorTriad, this.CSharpDiminishedTriad, this.DMajorTriad, this.EMinorTriad, this.FSharpMinorTriad, this.GMajorTriad, this.AMajorTriad],
      }


    let Ebmaj_Cmin_fam = {
      name: "Ebmaj/Cmin family",
      notes: ["D#/Eb", "E#/F", "G", "G#/Ab", "A#/Bb", "B#/C", "D"],
      sharps: 0,
      flats: 3,
      major: [this.EFlatMajorTriad, this.FMinorTriad, this.GMinorTriad, this.AFlatMajorTriad, this.BFlatMajorTriad, this.CMinorTriad, this.DDiminishedTriad],
      minor: [this.CMinorTriad, this.DDiminishedTriad, this.EFlatMajorTriad, this.FMinorTriad, this.EGinorTriad, this.AFlatMajorTriad, this.BFlatMajorTriad],
      }

    let Emaj_CSharpmin_fam = {
      name: "Emaj/C#min family",
      notes: ["E", "F#/Gb", "G#/Ab", "A", "B", "C#/Db", "D#/Eb"],
      sharps: 4,
      flats: 0,
      major: [this.EMajorTriad, this.FSharpMinorTriad, this.GSharpMinorTriad, this.AMajorTriad, this.BMajorTriad, this.CSharpMinorTriad, this.DSharpDiminishedTriad],
      minor: [this.CSharpMinorTriad, this.DSharpDiminishedTriad, this.EMajorTriad, this.FSharpMinorTriad, this.GSharpMinorTriad, this.AMajorTriad, this.BGMajorTriad],
      }

    let Fmaj_Dmin_fam = {
      name: "Fmaj/Dmin family",
      notes: ["E#/F", "G", "A", "A#/Bb", "B#/C", "D", "E"],
      sharps: 0,
      flats: 1,
      major: [this.FMajorTriad, this.GMinorTriad, this.AMinorTriad, this.BFlatMajorTriad, this.CMajorTriad, this.DMinorTriad, this.EDiminishedTriad],
      minor: [this.DMinorTriad, this.EDiminishedTriad, this.FMajorTriad, this.GMinorTriad, this.AMinorTriad, this.BFlatMajorTriad, this.CMajorTriad],
      }

    let FSharpmaj_DSharpmin_fam = {
      name: "F#maj/D#min family",
      notes: ["F#/Gb", "G#/Ab", "A#/Bb", "B", "C#/Db", "D#/Eb", "E#/F"],
      sharps: 6,
      flats: 0,
      major: [this.FSharpMajorTriad, this.GSharpMinorTriad, this.ASharpMinorTriad, this.BMajorTriad, this.CSharpMajorTriad, this.DSharpMinorTriad, this.ESharpDiminishedTriad],
      minor: [this.DSharpMinorTriad, this.ESharpDiminishedTriad, this.FSharpMajorTriad, this.GSharpMinorTriad, this.ASharpMinorTriad, this.BMajorTriad, this.CSharpMajorTriad],
      }

    let Gmaj_Emin_fam = {
      name: "Gmaj/Emin family",
      notes: ["G", "A", "B", "B#/C", "D", "E", "F#/Gb"],
      sharps: 1,
      flats: 0,
      major: [this.GMajorTriad, this.AMinorTriad, this.BMinorTriad, this.CMajorTriad, this.DMajorTriad, this.EMinorTriad, this.FSharpDiminishedTriad],
      minor: [this.EminorTriad, this.FSharpDiminishedTriad, this.GMajorTriad, this.AMinorTriad, this.BMinorTriad, this.CMajorTriad, this.DMajorTriad],
      }


    let Abmaj_Fmin_fam = {
      name: "Abmaj/Fmin family",
      notes: ["G#/Ab", "A#/Bb", "B#/C", "C#/Db", "D#/Eb", "E#/F", "G"],
      sharps: 0,
      flats: 4,
      major: [this.AFlatMajorTriad, this.BFlatMinorTriad, this.CMinorTriad, this.DFlatMajorTriad, this.EMajorTriad, this.FMinorTriad, this.GDiminishedTriad],
      minor: [this.FMinorTriad, this.GDiminishedTriad, this.AFlatMajorTriad, this.BFlatMinorTriad, this.CMinorTriad, this.DFlatMajorTriad, this.EMajorTriad],
      }

    let Amaj_FSharpmin_fam = {
      name: "Amaj/F#min family",
      notes: ["A", "B", "C#/Db", "D", "E", "F#/Gb", "G#/Ab"],
      sharps: 3,
      flats: 0,
      major: [this.AMajorTriad, this.BMinorTriad, this.CSharpMinorTriad, this.DMajorTriad, this.EMajorTriad, this.FSharpMinorTriad, this.GSharpDiminishedTriad],
      minor: [this.FSharpMinorTriad, this.GSharpDiminishedTriad, this.AMajorTriad, this.BMinorTriad, this.CSharpMinorTriad, this.DMajorTriad, this.EMajorTriad, this.FSharpMinorTriad],
      }


    let Bbmaj_Gmin_fam = {
      name: "Bbmaj/Gmin family",
      notes: ["A#/Bb", "B#/C", "D", "D#/Eb", "E#/F", "G", "A"],
      sharps: 0,
      flats: 2,
      major: [this.BFlatMajorTriad, this.CMinorTriad, this.DMinorTriad, this.EFlatMajorTriad, this.FMajorTriad, this.GMinorTriad, this.ADiminishedTriad],
      minor: [this.GMinorTriad, this.ADiminishedTriad, this.BFlatMajorTriad, this.CMinorTriad, this.DMinorTriad, this.EFlatMajorTriad, this.FMajorTriad],
      }

    let Bmaj_GSharpmin_fam = {
      name: "Bmaj/G#min family",
      notes: ["B", "C#/Db", "D#/Eb", "E", "F#/Gb", "G#/Ab", "A#/Bb"],
      sharps: 5,
      flats: 0,
      major: [this.BMajorTriad, this.CSharpMinorTriad, this.DSharpMinorTriad, this.EMajorTriad, this.FSharpMajorTriad, this.GSharpMinorTriad, this.ASharpDiminishedTriad],
      minor: [this.GSharpMinorTriad, this.ASharpDiminishedTriad, this.BMajorTriad, this.CSharpMinorTriad, this.DSharpMinorTriad, this.EMajorTriad, this.FSharpMajorTriad],
      }

    let tonalFamilies = [
      Cmaj_Amin_fam,
      Dbmaj_Bbmin_fam,
      Dmaj_Bmin_fam,
      Ebmaj_Cmin_fam,
      Emaj_CSharpmin_fam,
      Fmaj_Dmin_fam,
      FSharpmaj_DSharpmin_fam,
      Gmaj_Emin_fam,
      Abmaj_Fmin_fam,
      Amaj_FSharpmin_fam,
      Bbmaj_Gmin_fam,
      Bmaj_GSharpmin_fam
    ]

    let familyMatches = [];

    for (let i=0; i < this.scalesMatched.length; i++) {
        // go through all scales matched
        let scaleRoot = this.scalesMatched[i][0].note;
        // loop through tonalFamilies
        for (let j=0; j < tonalFamilies.length; j++) {
          let tonalFamilyNotes = tonalFamilies[j].notes
          for (let k=0; k < tonalFamilyNotes.length; k++) {
            if (scaleRoot == tonalFamilyNotes[k]) {
              familyMatches.push(tonalFamilies[j]);
            }
          }
        }
    }
    this.familiesMatched = removeDuplicates(familyMatches);
    // console.log("MATCHING FAMILES", this.familiesMatched);
    // now that we have matching families, we total up notes per scale and find our winner(s)

    // let familyTotals = 0;
    // this.songTotalsNotes;

    let chordNoteTotal0 = 0;
    let chordNoteTotal1 = 0;
    let chordNoteTotal2 = 0;
    let chordNoteTotal3 = 0;
    let chordNoteTotal4 = 0;
    let chordNoteTotal5 = 0;
    let chordNoteTotal6 = 0;

    let familyTotal = 0;
    let familyObject = {};
    let familyTotalsArray = [];

    //families
    for (let i=0; i < this.familiesMatched.length; i++) {

      chordNoteTotal0 = 0;
      chordNoteTotal1 = 0;
      chordNoteTotal2 = 0;
      chordNoteTotal3 = 0;
      chordNoteTotal4 = 0;
      chordNoteTotal5 = 0;
      chordNoteTotal6 = 0;
      familyTotal = 0;

      let familyChords = this.familiesMatched[i].major;

      //TROUBLESHOOTING
      // console.log(`${this.familiesMatched[i].name} FIRST FAMILY CHORDS`, familyChords[0]);
      // console.log(`${this.familiesMatched[i].name} SECOND FAMILY CHORDS`, familyChords[1]);
      // console.log(`${this.familiesMatched[i].name} THIRD FAMILY CHORDS`, familyChords[2]);
      // console.log(`${this.familiesMatched[i].name} FOURTH FAMILY CHORDS`, familyChords[3]);
      // console.log(`${this.familiesMatched[i].name} FIFTH FAMILY CHORDS`, familyChords[4]);
      // console.log(`${this.familiesMatched[i].name} SIXTH FAMILY CHORDS`, familyChords[5]);
      // console.log(`${this.familiesMatched[i].name} SEVENTH FAMILY CHORDS`, familyChords[6]);

      //chords [chord, chord, chord]
      for (let j=0; j < familyChords.length; j++) {
        // console.log(familyChords);
        //chord
        for (let k=0; k < familyChords[0].length; k++) {
          chordNoteTotal0 += familyChords[0][k].total;
        }

        for (let k=0; k < familyChords[1].length; k++) {
          // console.log(familyChords[1]);
          chordNoteTotal1 += familyChords[1][k].total;
        }

        for (let k=0; k < familyChords[2].length; k++) {
          // console.log(familyChords[2]);
          chordNoteTotal2 += familyChords[2][k].total;
        }

        for (let k=0; k < familyChords[3].length; k++) {
          // console.log(familyChords[3]);
          chordNoteTotal3 += familyChords[3][k].total;
        }

        for (let k=0; k < familyChords[4].length; k++) {
          // console.log(familyChords[4]);
          chordNoteTotal4 += familyChords[4][k].total;
        }

        for (let k=0; k < familyChords[5].length; k++) {
          // console.log(familyChords[5]);
          chordNoteTotal5 += familyChords[5][k].total;
        }

        for (let k=0; k < familyChords[6].length; k++) {
          // console.log(familyChords[6]);
          chordNoteTotal6 += familyChords[6][k].total;
        }
      }
      familyTotal = chordNoteTotal0 + chordNoteTotal1 + chordNoteTotal2 + chordNoteTotal3 + chordNoteTotal4 + chordNoteTotal5 + chordNoteTotal6;
      familyObject = {
        family: this.familiesMatched[i].name,
        total: familyTotal,
        chord_totals: [
            {"I": chordNoteTotal0},
            {"ii": chordNoteTotal1},
            {"iii": chordNoteTotal2},
            {"IV": chordNoteTotal3},
            {"V":chordNoteTotal4},
            {"vi": chordNoteTotal5},
            {"vii": chordNoteTotal6}
          ]
        };
      familyTotalsArray.push(familyObject);
    }
    // console.log(familyTotalsArray);
    sortScalesAndChords(familyTotalsArray);
    console.log(familyTotalsArray);

    // console.log("--------TONALITIES FOUND:", this.songTotalsNotes); // totals for each note
    // console.log("--------ROOT FOUND:", this.rootFound); // most prevalent note found
    // console.log("--------MAJOR TRIAD TOTALS", majorTriadTotalObjectArray); // matching major triads for most prevalent note
    // console.log("--------MINOR TRIAD TOTALS", minorTriadTotalObjectArray);// matching minor triads for most prevalent note

    // get totals for all chords, and try to cross reference families pulled - need to scrub and make this analysis better
    // need to discern whether it is minor or major
      // look for prominence of V chord for major
      // look vii (leading tone) for major keys
      //

      let triadTotal = 0;
      let triadTotalObject = {};
      this.triadTotalObjectArray = [];

      for (let i=0; i < this.majorTriads.length; i++) {
        let triad = this.majorTriads[i];
        for (let j=0; j < triad.length; j++) {
          triadTotal += triad[j].total;
        }
        triadTotalObject = {'root': this.majorTriads[i][0].note, 'total': triadTotal};
        this.triadTotalObjectArray.push(triadTotalObject);
        triadTotal = 0;
      }

      let closestFamily = familyTotalsArray[0].family;
      console.log("closestFamily:", closestFamily)

      let majorDominant;
      let minorDominant;

      switch (closestFamily) {

        case "Cmaj/Amin family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'G');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'E');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 1;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 11;
          }
          break;

        case "Dbmaj/Bbmin family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'G#/Ab');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'E#/F');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 18;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 14;
          }
          break;

        case "Dmaj/Bmin family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'A');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'F#/Gb');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 3;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 13;
          }
          break;

        case "Ebmaj/Cmin family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'A#/Bb');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'G');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 17;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 1;
          }
          break;

        case "Emaj/C#min family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'B');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'G#/Ab');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 5;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 2;
          }
          break;

        case "Fmaj/Dmin family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'B#/C');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'A');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 7;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 3;
          }
          break;

        case "F#maj/D#min family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'C#/Db');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'A#/Bb');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 8;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 4;
          }
          break;

        case "Gmaj/Emin family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'D');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'B');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 9;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 5;
          }
          break;

        case "Abmaj/Fmin family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'D#/Eb');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'B#/C');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 15;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 7;
          }
          break;

        case "Amaj/F#min family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'E');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'C#/Db');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 11;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 8;
          }
          break;

        case "Bbmaj/Gmin family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'E#/F');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'E');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 14;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 9;
          }
          break;

        case "Bmaj/G#min family":
          majorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'F#/Gb');
          minorDominant = findByAttr(this.triadTotalObjectArray, 'root', 'D#/Eb');

          if (majorDominant.total > minorDominant.total) {
            this.tonality = "Minor";
            this.tonality_id = 2;
            this.key_id = 13;
          } else {
            this.tonality = "Major";
            this.tonality_id = 1;
            this.key_id = 10;
          }
          break;

        default:
          console.log("CAN'T RENDER KEY");
      }

      let a;
      let b;
      let c;

      if (this.tonality == "Major") {
        a = closestFamily.split("/");
        b = a[0];
        c = b.split("maj");
        this.key = c[0];

      } else {
        a = closestFamily.split("/");
        b = a[1];
        c = b.split("min");
        this.key = c[0];
      }

      // console.log("MAJOR CHORD:", majorDominant);
      // console.log("MINOR CHORD:", minorDominant);
      console.log("TONALITY:", this.tonality);

      return this.keyTonalityObject = {key: this.key, key_id: this.key_id, tonality: this.tonality, tonality_id: this.tonality_id};

    }

    showMeTheKey () {
      return new Promise((resolve, reject) => {
        resolve(this.keyTonalityObject);
        reject("FAILED");
      });
    }
}
