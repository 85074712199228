<template>
  <header class="atmo-library__main-section-header">
    <atmo-breadcrumbs
      class="atmo-flex-child--equal-width"
      :breadcrumbs="['Back to Library']"
      :on-click="backToLibrary"
    >
      <img src="@/assets/images/icons/back.png">
      <span>Back To Library</span>
    </atmo-breadcrumbs>
    <h3
      class="
        atmo-library__section-header
        atmo-library__section-header--main
        atmo-flex-child--equal-width
      "
    >
      <slot />
    </h3>
    <span class="atmo-library__main-section-header-actions atmo-flex-child--equal-width">
      <slot name="actions" />
    </span>
  </header>
</template>

<script>
  import AtmoBreadcrumbs from '@/components/atmo-breadcrumbs';

  export default {
    components: { AtmoBreadcrumbs },

    props: {
      backToLibrary: {
        type: Function,
        required: true
      }
    }
  }
</script>
