import Axios from 'axios';

export function callShippingCarrierProductsCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/shipping_carrier_products`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callShippingCarrierProductsUpdate(shippingCarrierProductId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/shipping_carrier_products/${shippingCarrierProductId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}