import Axios from 'axios';

export function callImagesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/images`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callImagesCreate(requestData, queryParams = {}) {
    return Axios({
        method: 'post',
        url: `/api/v1/images`,
        data: requestData,
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
            queryParams
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });

}

export function callImagesUpdate(imageId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/images/${imageId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callImagesDelete(imageId, queryParams = {}) {
    return Axios.delete(`/api/v1/images/${imageId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}