import Axios from 'axios';

export function callUserTokenCreate(requestData) {
   return Axios({
        method: "post",
        url: "/api/v1/user_token",
        data: requestData
      })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
