import Axios from 'axios';

export function callLiveListeningChatRoomsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/live_listening_chat_rooms?user_id=${userId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callLiveListeningChatRoomsShow(userId, queryParams = {}) {
    return Axios.get(`/api/v1/live_listening_chat_rooms/${userId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}