import Axios from 'axios';

export function callShippingEstimateCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/shippo/estimate`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}