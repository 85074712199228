<template>
    <b-modal id="user-accomplishment-modal" ref="userAccomplishmentModal" v-model="isModalOpen"
        title="User Accomplishment Modal" modal-class="user-modal" size="lg" hide-header hide-footer centered>
        <header class="user-accomplishment-modal__header">
            <div class="header__hide-accomplishment-wrap" @click="hideModal">
                <img class="hide-accomplishment-wrap__hide-accomplishment" src="@/assets/images/icons/close_popup.png"
                    alt="close" title="Hide Accomplishment">
            </div>
        </header>
        <div class="user-accomplishment-modal__accomplishment-container">
            <div v-if="currentAccomplishment" class="accomplishments-grid-container__card">
                <div class="card__new-container">New Accomplishment!</div>
                <div class="card__center-container">
                    <div class="center-container__nav-container">
                        <img src="@/assets/images/icons/arrow_left.png" @click="prevAccomplishment" class="nav-button" v-if="createdAccomplishments.length > 1"/>
                    </div>
                    <div class="center-container__badge-container">
                        <div class="badge-container__icon-container">
                            <img class="icon-container__icon" :src="currentAccomplishment.accomplishment.image.url" />
                        </div>
                        <div class="badge-container__text-container">
                            <div class="text-container__number-wrap">{{ currentAccomplishment.accomplishment.number }}
                            </div>
                            <div class="text-container__text-wrap">{{ currentAccomplishment.accomplishment.category }}
                            </div>
                        </div>
                    </div>
                    <div class="center-container__nav-container">
                        <img src="@/assets/images/icons/arrow_right.png" @click="prevAccomplishment" class="nav-button" v-if="createdAccomplishments.length > 1"/>
                    </div>
                </div>
                <div class="card__title-container">{{ currentAccomplishment.accomplishment.name }}</div>
                <div class="card__description-container">
                    {{ currentAccomplishment.accomplishment.description }}
                </div>
                <div class="card__points-container">
                    Awarded {{ currentAccomplishment.accomplishment.points }} points!
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    data: function () {
        return {
            currentIndex: 0,
        };
    },
    computed: {
        ...mapGetters('accomplishments', ['isUserAccomplishmentModalOpen', 'getCreatedAccomplishments']),
        isModalOpen() {
            return this.isUserAccomplishmentModalOpen;
        },
        createdAccomplishments() {
            return this.getCreatedAccomplishments;
        },
        currentAccomplishment() {
            return this.createdAccomplishments[this.currentIndex];
        }
    },
    methods: {
        ...mapMutations('accomplishments', ['hideUserAccomplishmentModal']),
        hideModal() {
            this.hideUserAccomplishmentModal();
        },
        prevAccomplishment() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            } else {
                this.currentIndex = this.createdAccomplishments.length - 1;
            }
        },
        nextAccomplishment() {
            if (this.currentIndex < this.createdAccomplishments.length - 1) {
                this.currentIndex++;
            } else {
                this.currentIndex = 0;
            }
        }
    },
    watch: {
        createdAccomplishments() {
            this.currentIndex = 0;
        }
    }
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

#user-accomplishment-modal {
    .modal .modal-lg.modal-dialog {
        max-width: 1000px;
    }

    .modal-content {
        backdrop-filter: blur(5px);
        border-radius: 5px;
        border: none;
    }

    .modal-body {
        position: relative;
    }

    .user-accomplishment-modal__header {
        position: absolute;
        right: 0rem;
        height: 1rem;
        z-index: 1;
    }

    .header__hide-accomplishment-wrap {
        height: 2.5rem;
        width: 2.5rem;
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hide-accomplishment-wrap__hide-accomplishment {
        height: 1rem;
        width: auto;
        cursor: pointer;
    }

    .user-accomplishment-modal__accomplishment-container {
        .accomplishment-container__image-wrap {
            height: 25rem;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;
        }

        .accomplishment-container__text-wrap {
            padding: 1rem;
        }

        .text-wrap__title-wrap {
            font-size: 1.2rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
            text-transform: uppercase;
        }

        .text-wrap__body-button-wrap {
            display: flex;
            align-items: center;
        }

        .body-button-wrap__body-wrap {
            display: flex;
            align-items: center;
            margin-right: 1rem;
        }

        .body-button-wrap__button-wrap {
            justify-content: center;
            align-items: flex-end;
            margin-left: auto;
        }

        .button-wrap__button {
            display: inline-block;
            border: 1px solid white;
            border-radius: 5px;
            text-transform: uppercase;
            padding: .5rem 1rem .5rem 1rem;
            color: white;
            font-size: .8rem;
            font-weight: 600;
        }
    }

    .accomplishments-grid-container__card {
        background: $atmo-purple--medium-dark;
        min-height: 15rem;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 1rem;
        border-radius: 5px;
    }

    .title-container__number-wrap {
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: .5rem;
    }

    .title-container__text-wrap {
        text-transform: uppercase;
        text-align: center;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .card__info-container {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 2;
        height: 1.5rem;
        width: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .info-container__icon {
            height: 1rem;
            opacity: 0.2;
        }
    }

    .card__new-container {
        font-size: 2rem;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 2rem;
    }

    .card__center-container {
        display: grid;
        grid-template-columns: 2rem 1fr 2rem;
    }

    .center-container__nav-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-button {
        height: 2rem;
        cursor: pointer;
    }

    .center-container__badge-container {
        height: 11rem;
        background-image: url('@/assets/images/icons/accomplishments/badge.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        position: relative;
        margin-bottom: 1.5rem;
    }

    .badge-container__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 2.7rem;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100px;
    }

    .icon-container__icon {
        height: 3rem;
    }

    .badge-container__text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 9rem;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .text-container__number-wrap {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 2px;
    }

    .text-container__text-wrap {
        text-transform: uppercase;
        color: $atmo-white--medium-faded;
        text-align: center;
    }

    .card__title-container {
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 600;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 1rem;
    }

    .card__description-container {
        font-size: 1rem;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 1rem;
        min-height: 2rem;
    }

    .card__points-container {
        font-size: 1rem;
        display: flex;
        justify-content: center;
        text-align: center;
    }
}
</style>
