<template>
  <div class="ami-container">
    <h2 class="atmo-page-header atmo-store__header">
      AMI COMMAND {{ this.command.id }}
    </h2>
    <button v-if="!updateViewEnabled" @click="enableUpdateView()">
      Update
    </button>
    <button v-if="updateViewEnabled" @click="disableUpdateView()">
      Cancel
    </button>
    <button>Delete</button>
    <button>Show Triggers</button>
    <button>Show Command Flow</button>
    <button 
      style="cursor:pointer;" 
      @click="backToIndex()"
    >
      Back to commands index
    </button>
    <br>
    <br>
    <div 
      style="float:left; margin-left:40px;"
    >
      <div v-if="this.commandLoading" class="hud-sidebar__loading-container">
        <atmo-loading />
      </div>
      <div v-if="!this.updateViewEnabled">
        <h3>Command Attributes</h3>
        <p>
          command id:
          <strong>{{ this.command.id || "N/A" }}</strong>
        </p>
        <p>
          command type:
          <strong>{{ this.command.command_type || "N/A" }}</strong>
        </p>
        <p>
          command function:
          <strong>{{ this.command.command_function || "N/A" }}</strong>
        </p>
        <p>
          command parent id:
          <strong>{{ this.command.parent_id || "N/A" }}</strong>
        </p>
        <p>
          command sequence type:
          <strong>{{ this.command.sequence_type || "N/A" }}</strong>
        </p>
        <p>
          command sequence order:
          <strong>{{ this.command.sequence_order || "N/A" }}</strong>
        </p>
        <p>
          command ami init:
          <strong>{{ this.command.ami_init || "N/A" }}</strong>
        </p>
        <p>
          command fx:
          <strong>{{ this.command.fx || "N/A" }}</strong>
        </p>
        <p>
          command next link acceps literal speech:
          <strong>{{ this.command.next_link_accepts_literal_speech || "N/A" }}</strong>
        </p>
        <p>
          command string type:
          <strong>{{ this.command.dynamic_string_type || "N/A" }}</strong>
        </p>
        <p>
          command requires confirmation:
          <strong>{{ this.command.requires_confirmation || "N/A" }}</strong>
        </p>
        <p>
          command expecting answer:
          <strong>{{ this.command.expecting_answer || "N/A" }}</strong>
        </p>
        <p>
          command expected literal command:
          <strong>{{ this.command.expected_literal_command || "N/A" }}</strong>
        </p>
        <p>
          command store function:
          <strong>{{ this.command.store_function || "N/A" }}</strong>
        </p>
        <p>
          command store argument:
          <strong>{{ this.command.store_argument || "N/A" }}</strong>
        </p>
        <p>
          command created at:
          <strong>{{ this.command.created_at || "N/A" }}</strong>
        </p>
        <p>
          command updated at:
          <strong>{{ this.command.updated_at || "N/A" }}</strong>
        </p>
        <p>
          command route name:
          <strong>{{ this.command.route_name || "N/A" }}</strong>
        </p>
        <p>
          command param name:
          <strong>{{ this.command.param_name || "N/A" }}</strong>
        </p>
        <p>
          command param value:
          <strong>{{ this.command.param_value || "N/A" }}</strong>
        </p>
        <p>
          command question id:
          <strong>{{ this.command.question_id || "N/A" }}</strong>
        </p>
        <p>
          command literal question id:
          <strong>{{ this.command.literal_question_id || "N/A" }}</strong>
        </p>
        <p>
          command command family:
          <strong>{{ this.command.command_family || "N/A" }}</strong>
        </p>
        <p>
          command desktop:
          <strong>{{ this.command.desktop || "N/A" }}</strong>
        </p>
        <p>
          command smart speaker:
          <strong>{{ this.command.smart_speaker || "N/A" }}</strong>
        </p>
      </div>
    </div>
    <div 
      v-if="!this.updateViewEnabled" 
      style="float:right; margin-right:40px"
    >
      <div>
        <h3>Speech Triggers</h3>
        <div
          v-for="speechTrigger in command.ami_command_speech_triggers"
          :key="speechTrigger.id"
          style="cursor:pointer;"
        >
          <p>id: {{ speechTrigger.id }} | speech: {{ speechTrigger.speech }}</p>
        </div>
      </div>
      <br>
      <div>
        <h3>Processing Prompts</h3>
        <div
          v-for="commandPrompt in command.ami_command_processing_prompts"
          :key="commandPrompt.id"
          style="cursor:pointer;"
        >
          <p>id: {{ commandPrompt.id }} | speech: {{ commandPrompt.speech }}</p>
        </div>
      </div>
    </div>
    <div v-if="this.updateViewEnabled">
      <label for="commandType">commandType</label>
      <select 
        v-model="commandType"
        style="display:block; margin-bottom:10px;" 
        name="commandType"
      >
        <option value="static">
          static
        </option>
        <option value="dynamicMultiPart">
          dynamicMultiPart
        </option>
        <option value="dynamic">
          dynamic
        </option>
      </select>
      <button
        style="display:block; margin-bottom:20px;"
        @click="editCommandType(commandType)"
      >
        Update
      </button>

      <label for="commandFunction">commandFunction</label>
      <input 
        v-model="commandFunction" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >
      <button
        style="display:block; margin-bottom:20px;"
        @click="editCommandFunction(commandFunction)"
      >
        Update
      </button>

      <label for="parentId">parentId</label>
      <input
        v-model="parentId" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >
      <button 
        style="display:block; margin-bottom:20px;" 
        @click="editParentId(parentId)"
      >
        Update
      </button>

      <label for="sequenceType">sequenceType</label>
      <select
        v-model="sequenceType" 
        style="display:block; margin-bottom:10px;" 
        name="sequenceType"
      >
        <option value="standalone">
          standalone
        </option>
        <option value="strictSequence">
          strictSequence
        </option>
        <option value="utility">
          utility
        </option>
        <option value="prerequisite">
          prerequisite
        </option>
      </select>
      <button
        style="display:block; margin-bottom:20px;"
        @click="editSequenceType(sequenceType)"
      >
        Update
      </button>

      <label for="sequenceOrder">sequenceOrder</label>
      <select
        v-model="sequenceOrder"
        style="display:block; margin-bottom:10px;"
        name="sequenceOrder"
      >
        <option value="1">
          1
        </option>
        <option value="2">
          2
        </option>
        <option value="3">
          3
        </option>
        <option value="4">
          4
        </option>
        <option value="5">
          5
        </option>
      </select>
      <button
        style="display:block; margin-bottom:20px;"
        @click="editSequenceOrder(sequenceOrder)"
      >
        Update
      </button>

      <label for="amiInit">amiInit</label>
      <select 
        v-model="amiInit" 
        style="display:block; margin-bottom:10px;" 
        name="amiInit"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>
      <button 
        style="display:block; margin-bottom:20px;" 
        @click="editAmiInit(amiInit)"
      >
        Update
      </button>

      <label for="fx">fx</label>
      <select 
        v-model="fx" 
        style="display:block; margin-bottom:10px;" 
        name="fx"
      >
        <option value="@/assets/sound_files/AMI_FX/AMI_success_short.mp3">
          @/assets/sound_files/AMI_FX/AMI_success_short.mp3
        </option>
        <option value="@/assets/sound_files/AMI_FX/AMI_init_short.mp3">
          @/assets/sound_files/AMI_FX/AMI_init_short.mp3
        </option>
        <option value="@/assets/sound_files/AMI_FX/AMI_deinit_short.mp3">
          @/assets/sound_files/AMI_FX/AMI_deinit_short.mp3
        </option>
        <option value="@/assets/sound_files/AMI_FX/AMI_mute_short.mp3">
          @/assets/sound_files/AMI_FX/AMI_mute_short.mp3
        </option>
        <option value="@/assets/sound_files/AMI_FX/AMI_unmute_short.mp3" />
        <option value="@/assets/sound_files/AMI_FX/AMI_tryagain_short.mp3">
          @/assets/sound_files/AMI_FX/AMI_tryagain_short.mp3
        </option>
      </select>
      <button 
        style="display:block; margin-bottom:20px;" 
        @click="editFX(fx)"
      >
        Update
      </button>

      <label for="nextLinkAcceptsLiteralSpeech">nextLinkAcceptsLiteralSpeech</label>
      <select
        v-model="nextLinkAcceptsLiteralSpeech"
        style="display:block; margin-bottom:10px;"
        name="nextLinkAcceptsLiteralSpeech"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>
      <button
        style="display:block; margin-bottom:20px;"
        @click="editNextLinkAcceptsLiteralSpeech(nextLinkAcceptsLiteralSpeech)"
      >
        Update
      </button>

      <label for="dynamicStringType">dynamicStringType</label>
      <select
        v-model="dynamicStringType"
        style="display:block; margin-bottom:10px;"
        name="dynamicStringType"
      >
        <option value="custom">
          custom
        </option>
        <option value="friend">
          friend
        </option>
        <option value="playlist">
          playlist
        </option>
      </select>
      <button
        style="display:block; margin-bottom:20px;"
        @click="editDynamicStringType(dynamicStringType)"
      >
        Update
      </button>

      <label for="requiresConfirmation">requiresConfirmation</label>
      <select
        v-model="requiresConfirmation"
        style="display:block; margin-bottom:10px;"
        name="requiresConfirmation"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>
      <button
        style="display:block; margin-bottom:20px;"
        @click="editRequiresConfirmation(requiresConfirmation)"
      >
        Update
      </button>

      <label for="expectingAnswer">expectingAnswer</label>
      <select
        v-model="expectingAnswer"
        style="display:block; margin-bottom:10px;"
        name="expectingAnswer"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>
      <button
        style="display:block; margin-bottom:20px;"
        @click="editExpectingAnswer(expectingAnswer)"
      >
        Update
      </button>

      <label for="expectedLiteralCommand">expectedLiteralCommand</label>
      <select
        v-model="expectedLiteralCommand"
        style="display:block; margin-bottom:10px;"
        name="expectedLiteralCommand"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>
      <button
        style="display:block; margin-bottom:20px;"
        @click="editExpectedLiteralCommand(expectedLiteralCommand)"
      >
        Update
      </button>

      <label for="storeFunction">storeFunction</label>
      <input 
        v-model="storeFunction" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >
      <button
        style="display:block; margin-bottom:20px;"
        @click="editStoreFunction(storeFunction)"
      >
        Update
      </button>

      <label for="storeArgument">storeArgument</label>
      <input 
        v-model="storeArgument" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >
      <button
        style="display:block; margin-bottom:20px;"
        @click="editStoreArgument(storeArgument)"
      >
        Update
      </button>

      <label for="routeName">routeName</label>
      <input 
        v-model="routeName"  
        style="display:block; margin-bottom:10px;" 
        type="text"
      >
      <button 
        style="display:block; margin-bottom:20px;"
        @click="editRouteName(routeName)"
      >
        Update
      </button>

      <label for="paramName">paramName</label>
      <input 
        v-model="paramName"  
        style="display:block;margin-bottom:10px;" 
        type="text"
      >
      <button 
        style="display:block; 
      margin-bottom:20px;" 
        @click="editParamName(paramName)"
      >
        Update
      </button>

      <label for="paramValue">paramValue</label>
      <input
        v-model="paramValue" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >
      <button 
        style="display:block; margin-bottom:20px;" 
        @click="editParamValue(paramValue)"
      >
        Update
      </button>

      <label for="questionId">questionId</label>
      <input
        v-model="questionId" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >
      <button 
        style="display:block; margin-bottom:20px;" 
        @click="editQuestionId(questionId)"
      >
        Update
      </button>

      <label for="literalQuestionId">literalQuestionId</label>
      <input 
        v-model="literalQuestionId" 
        style="display:block; margin-bottom:10px;" 
        type="text"
      >
      <button
        style="display:block; margin-bottom:20px;"
        @click="editLiteralQuestionId(literalQuestionId)"
      >
        Update
      </button>

      <label for="commandFamily">commandFamily</label>
      <select
        v-model="commandFamily"
        style="display:block; margin-bottom:10px;"
        name="commandFamily"
      >
        <option value="utility">
          utility
        </option>
        <option value="sequence1">
          sequence1
        </option>
        <option value="sequence2">
          sequence2
        </option>
        <option value="sequence3">
          sequence3
        </option>
        <option value="sequence4">
          sequence4
        </option>
        <option value="sequence5">
          sequence5
        </option>
      </select>
      <button
        style="display:block; margin-bottom:20px;"
        @click="editCommandFamily(commandFamily)"
      >
        Update
      </button>

      <label for="desktop">desktop</label>
      <select 
        v-model="desktop" 
        style="display:block; margin-bottom:10px;" 
        name="desktop"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>
      <button 
        style="display:block; margin-bottom:20px;" 
        @click="editDesktop(desktop)"
      >
        Update
      </button>

      <label for="smartSpeaker">smartSpeaker</label>
      <select 
        v-model="smartSpeaker" 
        style="display:block; margin-bottom:10px;" 
        name="smartSpeaker"
      >
        <option value="true">
          true
        </option>
        <option value="false">
          false
        </option>
      </select>
      <button
        style="display:block; margin-bottom:20px;"
        @click="editSmartSpeaker(smartSpeaker)"
      >
        Update
      </button>
    </div>
  </div>
</template>


<style lang="scss">
.ami-container {
  position: relative;
  height: 400px;
  width: 97%;
  margin: auto;
}
</style>

<script>
  import Axios from "axios";
  import AtmoLoading from "@/components/atmo-loading";

  export default {
    components: { AtmoLoading },

    data() {
      return {
        command: {},
        commandLoading: true,
        updateViewEnabled: false,
        commandType: "static",
        commandFunction: "",
        parentId: null,
        sequenceType: "standalone",
        sequenceOrder: "1",
        amiInit: false,
        fx: "@/assets/sound_files/AMI_FX/AMI_success_short.mp3",
        nextLinkAcceptsLiteralSpeech: false,
        dynamicStringType: "custom",
        requiresConfirmation: false,
        expectingAnswer: false,
        expectedLiteralCommand: false,
        storeFunction: "",
        storeArgument: "",
        routeName: null,
        paramName: "",
        paramValue: "",
        questionId: null,
        literalQuestionId: null,
        commandFamily: "utility",
        desktop: true,
        smartSpeaker: true
      };
    },
    created() {
      this.getAmiCommand(this.$route.params.commandId);
    },
    methods: {
      getAmiCommand(commandId) {
        Axios.get(`/api/v1/ami_commands/${commandId}`, {})
          .then(response => {
            this.commandLoading = false;
            this.command = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      },

      backToIndex() {
        this.$router.push({ name: "AMI.commands.index" });
      },

      enableUpdateView() {
        this.updateViewEnabled = true;
      },

      disableUpdateView() {
        this.updateViewEnabled = false;
      },

      retrieveUpdatedCommand() {
        Axios.get(`/api/v1/ami_commands/${this.$route.params.commandId}`).then(
          response => (this.command = response.data)
        );
      },

      editCommandType(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          command_type: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editCommandFunction(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          command_function: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editParentId(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          parent_id: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editSequenceType(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          sequence_type: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editSequenceOrder(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          sequence_order: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editAmiInit(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          ami_init: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editFX(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          fx: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editNextLinkAcceptsLiteralSpeech(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          next_link_accepts_literal_speech: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editDynamicStringStype(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          dynamic_string_type: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editRequiresConfirmation(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          requires_confirmation: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editExpectingAnswer(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          expecting_answer: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editExpectedLiteralCommand(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          expected_literal_command: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editStoreFunction(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          store_function: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editStoreArgument(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          store_argument: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editRouteName(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          route_name: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editParamName(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          param_name: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editParamValue(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          param_value: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editQuestionId(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          question_id: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editLiteralQuestionId(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          literal_question_id: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editCommandFamily(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          command_family: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editDesktop(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          desktop: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      },

      editSmartSpeaker(attribute) {
        Axios.patch(`/api/v1/ami_commands/${this.$route.params.commandId}`, {
          // ONLY SUBMITS WHEN ALL FIELDS ARE FILLED IN
          smart_speaker: attribute
        })
          .then(response => {
            console.log(response);
            this.retrieveUpdatedCommand();
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  };
</script>