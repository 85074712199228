import Axios from 'axios';

export function callContestFileTypesIndex(contestId, queryParams = {}) {
    return Axios.get(`/api/v1/contests/${contestId}/contest_file_types`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestFileTypesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/contest_file_types`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestFileTypesDelete(contestFileTypeId, queryParams = {}) {
    return Axios.delete(`/api/v1/contest_file_types/${contestFileTypeId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}