// store/playlists.js

import {
    callSongReferencesIndex,
    callSongReferencesCreate,
    callSongReferencesDelete
} from '@/helpers/axiosCalls';

export default {
    namespaced: true,
    state: {
        debug: false,
        currentUserPlaylists: [],
        currentPlaylistSongs: [],
        playlistLoaded: false,
    },
    mutations: {
        setCurrentUserPlaylists(state, playlists) {
            state.currentUserPlaylists = playlists;
            state.playlistLoaded = true;
        },
        setCurrentPlaylistSongs(state, playlistSongs) {
            state.currentPlaylistSongs = playlistSongs;
        },
        //NONE OF THIS WAS IN STORE:
        // addPlaylist(state, playlist) {
        //     state.currentUserPlaylists.push(playlist);
        // },
        // updatePlaylist(state, updatedPlaylist) {
        //     const index = state.currentUserPlaylists.findIndex(p => p.id === updatedPlaylist.id);
        //     if (index !== -1) {
        //         state.currentUserPlaylists.splice(index, 1, updatedPlaylist);
        //     }
        // },
        // deletePlaylist(state, playlistId) {
        //     const index = state.currentUserPlaylists.findIndex(p => p.id === playlistId);
        //     if (index !== -1) {
        //         state.currentUserPlaylists.splice(index, 1);
        //     }
        // }
    },
    actions: {
        //NONE OF THIS WAS IN STORE:
        // async loadUserPlaylists({ commit }) {
        //     // API call to fetch user playlists
        //     const response = await callSongReferencesIndex({ songable_type: 'Playlist' });
        //     commit('setCurrentUserPlaylists', response);
        // },
        // async loadPlaylistSongs({ commit }, playlistId) {
        //     // API call to fetch songs in a specific playlist
        //     const response = await callSongReferencesIndex({
        //         songable_id: playlistId,
        //         songable_type: 'Playlist'
        //     });
        //     commit('setCurrentPlaylistSongs', response);
        // },
        // async addToPlaylist({ dispatch }, { playlistId, song }) {
        //     await callSongReferencesCreate({
        //         songable_id: playlistId,
        //         songable_type: "Playlist",
        //         song_id: song.id,
        //     });
        //     dispatch('loadPlaylistSongs', playlistId); // Refresh playlist songs
        // },
        // async removeFromPlaylist({ dispatch }, { playlistId, song }) {
        //     const response = await callSongReferencesDelete(song.id);
        //     if (response) {
        //         dispatch('loadPlaylistSongs', playlistId); // Refresh playlist songs
        //     }
        // },
        // async createPlaylist({ commit }, playlistData) {
        //     // Logic for creating a new playlist
        //     // Simulating a response for the example
        //     const newPlaylist = { id: Date.now(), ...playlistData };
        //     commit('addPlaylist', newPlaylist);
        // },
        // async editPlaylist({ commit }, updatedPlaylist) {
        //     // Logic for updating a playlist
        //     commit('updatePlaylist', updatedPlaylist);
        // },
        // async deletePlaylist({ commit }, playlistId) {
        //     // Logic for deleting a playlist
        //     commit('deletePlaylist', playlistId);
        // }
    },
    getters: {
        currentUserPlaylists: state => state.currentUserPlaylists,
        currentPlaylistSongs: state => state.currentPlaylistSongs,
        playlistLoaded: state => state.playlistLoaded,
    }
};
