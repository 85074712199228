import {
    callUserActivitiesIndex,
    callUserActivitiesCreate,
    callUserActivitiesDelete,
    callUserActivitiesShow,
    callUserActivityTypesIndex,
    callBroadcastPostsIndex
} from '@/helpers/axiosCalls';
import { connectToBroadcastPostsChannel, setCallback } from '@/cable-channels/broadcastPosts';

export default {
    namespaced: true,
    state: {
        debug: false,
        userActivities: [],
        userActivityTypes: [], // Store the available user activity types
        broadcastPosts: [], // Store the broadcast posts and activities
        postsLoading: true, // Loading state for broadcast posts
        isConnected: false, // Track the connection status
        broadcastModalOpen: false,
    },
    mutations: {
        openBroadcastModal(state) {
            if (state.debug) console.log('Mutation: openBroadcastModal');
            state.broadcastModalOpen = true;
        },
        closeBroadcastModal(state) {
            if (state.debug) console.log('Mutation: closeBroadcastModal');
            state.broadcastModalOpen = false;
        },
        setUserActivities(state, activities) {
            if (state.debug) console.log('Mutation: setUserActivities', activities);
            state.userActivities = activities;
        },
        setUserActivityTypes(state, activityTypes) {
            if (state.debug) console.log('Mutation: setUserActivityTypes', activityTypes);
            state.userActivityTypes = activityTypes;
        },
        setBroadcastPosts(state, posts) {
            if (state.debug) console.log('Mutation: setBroadcastPosts', posts);
            state.broadcastPosts = posts;
        },
        setPostsLoading(state, loading) {
            if (state.debug) console.log('Mutation: setPostsLoading', loading);
            state.postsLoading = loading;
        },
        setConnectionStatus(state, status) {
            if (state.debug) console.log('Mutation: setConnectionStatus', status);
            state.isConnected = status;
        }
    },
    actions: {
        async fetchUserActivities({ commit, state }, userId) {
            try {
                if (state.debug) console.info(`Action: fetchUserActivities - Fetching user activities for user ID: ${userId}`);
                const response = await callUserActivitiesIndex(userId);
                if (state.debug) console.log('Response from callUserActivitiesIndex', response);
                commit('setUserActivities', response);
            } catch (error) {
                console.error('Error in fetchUserActivities:', error);
            }
        },
        async fetchUserActivityTypes({ commit, state }) {
            try {
                if (state.debug) console.info('Action: fetchUserActivityTypes - Fetching user activity types');
                const response = await callUserActivityTypesIndex();
                if (state.debug) console.log('Response from callUserActivityTypesIndex', response);
                commit('setUserActivityTypes', response);
            } catch (error) {
                console.error('Error in fetchUserActivityTypes:', error);
            }
        },
        async createUserActivity({ dispatch, rootState, state }, payload) {
            try {
                if (state.debug) console.info(`Action: createUserActivity - Creating a new user activity for user ID: ${rootState.currentUserId}`);
                if (state.debug) console.log('Activity Name:', payload.user_activity_type_name);
                if (state.debug) console.log('Payload for callUserActivitiesCreate:', payload);

                await callUserActivitiesCreate(rootState.currentUserId, payload);
                if (state.debug) console.info('User activity successfully created');

                dispatch('fetchUserActivities', rootState.currentUserId);
            } catch (error) {
                console.error('Error in createUserActivity:', error);
            }
        },
        async deleteUserActivity({ dispatch, rootState, state }, activityId) {
            try {
                if (state.debug) console.info(`Action: deleteUserActivity - Deleting user activity with ID: ${activityId}`);
                await callUserActivitiesDelete(activityId);
                if (state.debug) console.info('User activity successfully deleted');

                dispatch('fetchUserActivities', rootState.currentUserId);
            } catch (error) {
                console.error('Error in deleteUserActivity:', error);
            }
        },
        connectToBroadcastPosts({ commit, dispatch, rootState, state }, { roomId, params }) {
            const userId = rootState.currentUserId;
            if (state.debug) console.log(`Connecting to broadcast posts channel`);
            if (userId && !state.isConnected) {
                connectToBroadcastPostsChannel(roomId);
                setCallback(async (data) => {
                    if (state.debug) console.log('Broadcast Posts Callback - New activity received:', data);
                    await dispatch('getBroadcastPosts', { userId, params });
                });
                commit('setConnectionStatus', true); // Update the connection status
            }
        },

        async getBroadcastPosts({ commit, state }, { userId, params }) {
            commit('setPostsLoading', true);
            try {
                const response = await callBroadcastPostsIndex(userId, params);
                if (state.debug) console.log('API Response: getBroadcastPosts', response);
                commit('setBroadcastPosts', response); // Set the broadcastPosts state with the latest data
                commit('setPostsLoading', false);
            } catch (error) {
                console.error('Error in getBroadcastPosts:', error);
                commit('setPostsLoading', false);
            }
        }
    },
    getters: {
        isBroadcastModalOpen: (state) => {
            if (state.debug) console.log('Getter: isBroadcastModalOpen:', state.broadcastModalOpen);
            return state.broadcastModalOpen;
        },
        getUserActivities: (state) => {
            if (state.debug) console.log('Getter: getUserActivities - Returning user activities', state.userActivities);
            return state.userActivities;
        },
        getUserActivityTypes: (state) => {
            if (state.debug) console.log('Getter: getUserActivityTypes - Returning user activity types', state.userActivityTypes);
            return state.userActivityTypes;
        },
        getBroadcastPosts: (state) => {
            if (state.debug) console.log('Getter: getBroadcastPosts - Returning broadcast posts', state.broadcastPosts);
            return state.broadcastPosts;
        },
        isPostsLoading: (state) => state.postsLoading,
    }
};
