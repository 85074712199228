import {
  callLiveListeningChatRoomsShow,
  callLiveListeningChatRoomsIndex,
  callChatMessagesIndex,
  callChatRoomUsersIndex,
  callSongReferencesIndex,
} from '@/helpers/axiosCalls';
import { connectToChatRoomUserRequestsChannel, setCallback } from '@/cable-channels/chatRoomUserRequests';

export default {
  namespaced: true,
  state: {
    debug: false,
    liveListeningIndexOpen: false,
    liveListeningFriendOpen: false,
    liveListeningUserOpen: false,
    liveListeningAddFriendsOpen: false,
    friendLiveListeningChatRooms: [],
    currentUserLiveListeningChatRoom: {},
    liveListeningChatRoom: {},
    liveListeningChatRoomUsers: [],
    liveListeningChatRoomSongs: [],
    liveListeningChatRoomMessages: [],
    liveListeningChatRoomPendingSongs: [],
  },
  mutations: {
    openLiveListeningIndex(state) {
      if (state.debug) console.log('Mutation: openLiveListeningIndex');
      state.liveListeningIndexOpen = true;
    },
    closeLiveListeningIndex(state) {
      if (state.debug) console.log('Mutation: closeLiveListeningIndex');
      state.liveListeningIndexOpen = false;
    },
    openLiveListeningFriend(state) {
      if (state.debug) console.log('Mutation: openLiveListeningFriend');
      state.liveListeningFriendOpen = true;
    },
    closeLiveListeningFriend(state) {
      if (state.debug) console.log('Mutation: closeLiveListeningFriend');
      state.liveListeningFriendOpen = false;
    },
    openLiveListeningUser(state) {
      if (state.debug) console.log('Mutation: openLiveListeningUser');
      state.liveListeningUserOpen = true;
    },
    closeLiveListeningUser(state) {
      if (state.debug) console.log('Mutation: closeLiveListeningUser');
      state.liveListeningUserOpen = false;
    },
    openLiveListeningAddFriends(state) {
      if (state.debug) console.log('Mutation: openLiveListeningAddFriends');
      state.liveListeningAddFriendsOpen = true;
    },
    closeLiveListeningAddFriends(state) {
      if (state.debug) console.log('Mutation: closeLiveListeningAddFriends');
      state.liveListeningAddFriendsOpen = false;
    },
    setFriendLiveListeningChatRooms(state, value) {
      if (state.debug) console.log('Mutation: setFriendLiveListeningChatRooms', value);
      state.friendLiveListeningChatRooms = value;
    },
    setLiveListeningChatRoom(state, value) {
      if (state.debug) console.log('Mutation: setLiveListeningChatRoom', value);
      state.liveListeningChatRoom = value;
    },
    setCurrentUserLiveListeningChatRoom(state, value) {
      if (state.debug) console.log('Mutation: setCurrentUserLiveListeningChatRoom', value);
      state.currentUserLiveListeningChatRoom = value;
    },
    setLiveListeningChatRoomMessages(state, messages) {
      if (state.debug) console.log('Mutation: setLiveListeningChatRoomMessages', messages);
      state.liveListeningChatRoomMessages = messages;
    },
    setLiveListeningChatRoomUsers(state, value) {
      if (state.debug) console.log('Mutation: setLiveListeningChatRoomUsers', value);
      state.liveListeningChatRoomUsers = value;
    },
    setLiveListeningChatRoomSongs(state, value) {
      if (state.debug) console.log('Mutation: setLiveListeningChatRoomSongs', value);
      state.liveListeningChatRoomSongs = value;
    },
    setLiveListeningChatRoomPendingSongs(state, value) {
      if (state.debug) console.log('Mutation: setLiveListeningChatRoomPendingSongs', value);
      state.liveListeningChatRoomPendingSongs = value;
    },
  },
  actions: {
    getFriendLiveListeningChatRooms({ commit, state }, userId) {
      if (state.debug) console.log('Action: getFriendLiveListeningChatRooms, room:', userId);
      callLiveListeningChatRoomsIndex(userId)
        .then((response) => {
          if (state.debug) console.log('API Response: Friend Live Listening Chat Rooms', response);
          commit('setFriendLiveListeningChatRooms', response);
        })
        .catch((error) => {
          console.error('Error in getFriendLiveListeningChatRooms:', error);
        });
    },
    getLiveListeningChatRoom({ commit, state }, room) {
      if (state.debug) console.log('Action: getLiveListeningChatRoom, room:', room);
      callLiveListeningChatRoomsShow(room)
        .then((response) => {
          if (state.debug) console.log('API Response: Live Listening Chat Room', response);
          commit('setLiveListeningChatRoom', response);
        })
        .catch((error) => {
          console.error('Error in getLiveListeningChatRoom:', error);
        });
    },
    getCurrentUserLiveListeningChatRoom({ commit, state }, room) {
      if (state.debug) console.log('Action: getCurrentUserLiveListeningChatRoom, room:', room);
      callLiveListeningChatRoomsShow(room)
        .then((response) => {
          if (state.debug) console.log('API Response: Current User Live Listening Chat Room', response);
          commit('setCurrentUserLiveListeningChatRoom', response);
        })
        .catch((error) => {
          console.error('Error in getCurrentUserLiveListeningChatRoom:', error);
        });
    },
    getLiveListeningChatRoomMessages({ commit, state }, value) {
      if (state.debug) console.log('Action: getLiveListeningChatRoomMessages, value:', value);
      callChatMessagesIndex(value)
        .then((response) => {
          if (state.debug) console.log('API Response: Live Listening Chat Room Messages', response);
          commit('setLiveListeningChatRoomMessages', response);
        })
        .catch((error) => {
          console.error('Error in getLiveListeningChatRoomMessages:', error);
        });
    },
    getLiveListeningChatRoomUsers({ commit, state }, value) {
      if (state.debug) console.log('Action: getLiveListeningChatRoomUsers, value:', value);
      callChatRoomUsersIndex(value)
        .then((response) => {
          if (state.debug) console.log('API Response: Live Listening Chat Room Users', response);
          commit('setLiveListeningChatRoomUsers', response);
        })
        .catch((error) => {
          console.error('Error in getLiveListeningChatRoomUsers:', error);
        });
    },
    getLiveListeningChatRoomSongs({ commit, state }, value) {
      if (state.debug) console.log('Action: getLiveListeningChatRoomSongs, songable_id:', value);
      callSongReferencesIndex({
        songable_id: value,
        songable_type: 'ChatRoom',
      })
        .then((response) => {
          if (state.debug) console.log('API Response: Live Listening Chat Room Songs', response);
          commit('setLiveListeningChatRoomSongs', response);
        })
        .catch((error) => {
          console.error('Error in getLiveListeningChatRoomSongs:', error);
        });
    },
    connectToChatRoomUserRequests({ dispatch, rootState, state }, roomId) {
      if (state.debug) console.log(`Connecting to chat room user requests channel for room ID: ${roomId}`);
      connectToChatRoomUserRequestsChannel(roomId);
      setCallback((data) => {
        if (state.debug) console.log('Callback triggered: processing data from chat room user requests channel', data);
        const currentUserId = rootState.currentUserId;
        dispatch('getFriendLiveListeningChatRooms', currentUserId);
      });
    },
  },
  getters: {
    isLiveListeningIndexOpen: (state) => {
      if (state.debug) console.log('Getter: isLiveListeningIndexOpen:', state.liveListeningIndexOpen);
      return state.liveListeningIndexOpen;
    },
    isLiveListeningFriendOpen: (state) => {
      if (state.debug) console.log('Getter: isLiveListeningFriendOpen:', state.liveListeningFriendOpen);
      return state.liveListeningFriendOpen;
    },
    isLiveListeningUserOpen: (state) => {
      if (state.debug) console.log('Getter: isLiveListeningUserOpen:', state.liveListeningUserOpen);
      return state.liveListeningUserOpen;
    },
    isLiveListeningAddFriendsOpen: (state) => {
      if (state.debug) console.log('Getter: isLiveListeningAddFriendsOpen:', state.liveListeningAddFriendsOpen);
      return state.liveListeningAddFriendsOpen;
    },
  },
};
