import Axios from 'axios';

export function callNotificationsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/notifications`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callNotificationsUpdate(userId, notificationId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/users/${userId}/notifications/${notificationId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callNotificationsCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/notifications`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callNotificationsDelete(notificationId, queryParams = {}) {
    return Axios.delete(`/api/v1/notifications/${notificationId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callNotificationsDeleteWithParams(queryParams = {}) {
    return Axios.delete(`/api/v1/notifications/destroy_with_params`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}