import Axios from 'axios';

export function callStripeCustomersGet(customerId, queryParams = {}) {
    const authorizationToken = "Bearer sk_test_R87ol4y4QdQVp354Dwyy45pi";

    return Axios.get(`https://api.stripe.com/v1/customers/${customerId}`, {
        params: queryParams,
        headers: {
            Authorization: authorizationToken
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callStripeCustomersPost(customerId, queryParams = {}) {
    const authorizationToken = "Bearer sk_test_R87ol4y4QdQVp354Dwyy45pi";

    Axios({
        method: 'post',
        url: `https://api.stripe.com/v1/customers/${customerId}`,
        params: queryParams,
        headers: {
            Authorization: authorizationToken
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}