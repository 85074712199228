import Axios from 'axios';

export function callMoodsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/moods`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callMoodsShow(moodId, queryParams = {}) {
    return Axios.get(`/api/v1/moods/${moodId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}