<template>
  <div class="user-profiles-playlists-page">
    <div class="header-container">
      <h2 class="atmo-page-header">User Profile</h2>
    </div>
    <div class="playlists-wrap">
      <atmo-back-link></atmo-back-link>
      <atmo-music-playlists-grid :playlists="$store.state.playlists.currentUserPlaylists" />
    </div>
  </div>
</template>

<script>
import AtmoMusicPlaylistsGrid from '@/components/music/atmo-music-playlists-grid.vue';
import AtmoBackLink from '@/components/atmo-back-link';

export default {
  components: {
    AtmoMusicPlaylistsGrid,
    AtmoBackLink
  },
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.user-profiles-playlists-page {
  .playlists-wrap {
    padding: 0rem 3rem 0rem 3rem;
  }
}
</style>
