<template>
  <div>
    <h1>Playlists</h1>
    <div>
      <div>
        <div
          v-for="playlist in orderBy($store.state.playlists.currentUserPlaylists, 'created_at', +1)"
          :key="playlist.id"
        >
          <p>
            <router-link :to="{ name: 'user_profiles.playlists.show', params: {userProfileId: playlist.user_id, playlistId: playlist.id} }">
              {{ playlist.name }}
            </router-link>
          </p>
          <br>
        </div>
      </div>
      <button @click="createPlaylist()">
        Create New Playlist
      </button>
    </div>
  </div>
</template>

<script>
  import Axios from 'axios'

  export default{
    data(){
      return {
        userProfile: {},
        user: {},
        currentUser: {}
      }
    },
    created(){

    },
    methods: {
      createPlaylist () {
        Axios.post(`/api/v1/users/${this.$store.state.currentUserId}/playlists`, {
          user_id: this.$store.state.currentUserId,
          name: "Axios Test",
        })
          .then((response) => {
            console.log(response);
            alert("Playlist Created");
            // Axios.get('/api/v1/contests').then(response => (this.userFriendships = response.data));
            this.$store.state.playlists.currentUserPlaylists.push(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }
</script>
