import Music from './index';
import MusicExplore from './explore';
import MusicExploreGenresMoods from './explore/genres_moods';
import MusicExploreSongs from './explore/songs';
import MusicExploreArtists from './explore/artists';
import MusicExplorePlaylists from './explore/playlists';
import MusicSuggested from './suggested';
import MusicSuggestedSongs from './suggested/songs';
import MusicSuggestedArtists from './suggested/artists';
import MusicSuggestedPlaylists from './suggested/playlists';
import MusicNewReleases from './new_releases';
import MusicNewReleasesSongs from './new_releases/songs';
import MusicNewReleasesArtists from './new_releases/artists';
import MusicNewReleasesPlaylists from './new_releases/playlists';
import MusicCharts from './charts';
import MusicChartsShow from './charts/show';
import MusicConcerts from './concerts';
import MusicConcertsShow from './concerts/show';

export default {
  name: 'music',
  path: '/music',
  component: Music,
  redirect: '/music/explore/genres_moods', // default if only /music is visited
  children: [
    {
      path: 'explore',
      component: MusicExplore,
      redirect: '/music/explore/genres_moods', // default if only /music/explore is visited
      children: [
        {
          name: 'music.explore.genres_moods',
          path: 'genres_moods',
          component: MusicExploreGenresMoods
        },
        {
          name: 'music.explore.songs',
          path: 'songs',
          component: MusicExploreSongs
        },
        {
          name: 'music.explore.artists',
          path: 'artists',
          component: MusicExploreArtists
        },
        {
          name: 'music.explore.playlists',
          path: 'playlists',
          component: MusicExplorePlaylists
        }
      ]
    },
    {
      path: 'new_releases',
      component: MusicNewReleases,
      redirect: '/music/new_releases/genres_moods', // default if only /music/new_releases is visited
      children: [
        {
          name: 'music.new_releases.genres_moods',
          path: 'genres_moods',
          component: MusicExploreGenresMoods
        },
        {
          name: 'music.new_releases.songs',
          path: 'songs',
          component: MusicNewReleasesSongs
        },
        {
          name: 'music.new_releases.artists',
          path: 'artists',
          component: MusicNewReleasesArtists
        },
        {
          name: 'music.new_releases.playlists',
          path: 'playlists',
          component: MusicNewReleasesPlaylists
        }
      ]
    },
    {
      path: 'suggested',
      component: MusicSuggested,
      redirect: '/music/suggested/songs', // default if only /music/suggested is visited
      children: [
        {
          name: 'music.suggested.songs',
          path: 'songs',
          component: MusicSuggestedSongs
        },
        {
          name: 'music.suggested.artists',
          path: 'artists',
          component: MusicSuggestedArtists
        },
        {
          name: 'music.suggested.playlists',
          path: 'playlists',
          component: MusicSuggestedPlaylists
        }
      ]
    },
    {
      name: 'music.charts',
      path: 'charts',
      component: MusicCharts,
      children: [
      ]
    },
    {
      name: 'music.charts.show',
      path: 'charts/:chart_name',
      component: MusicChartsShow,
      props: true, // Enables passing route params as props to the component
    },
    {
      path: 'concerts',
      component: MusicConcerts
    },
    {
      name: 'music.concerts.show',
      path: 'concerts/:concert_id',
      component: MusicConcertsShow
    },
  ]
}
