import Axios from 'axios';

export function callContestsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/contests`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestsShow(contestId, queryParams = {}) {
    return Axios.get(`/api/v1/contests/${contestId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestsCreate(requestData) {
    return Axios({
        method: 'post',
        url: `/api/v1/contests`,
        data: requestData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestsUpdate(contestId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/contests/${contestId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestsDelete(contestId, queryParams = {}) {
    return Axios.delete(`/api/v1/contests/${contestId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}