import Axios from 'axios';

export function callUserProfilesShow(userProfileId, queryParams = {}) {
    return Axios.get(`/api/v1/user_profiles/${userProfileId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserProfilesUpdate(userProfileId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/user_profiles/${userProfileId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}