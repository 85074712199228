import createChannel from '@/cable';

let callback; // declaring a variable that will hold a function later

let chat = null;

function connectToLiveListeningChatChannel(roomId) {
  chat = createChannel(`LiveListeningChatChannel`, roomId, {
    received({ message }) {
      if (callback) callback.call(null, message);
    }
  });
}

// Sending a message: "perform" method calls a respective Ruby method
// defined in atmo_chat_channel.rb. That's your bridge between JS and Ruby!
function sendMessage(messageBody, roomId, userId) {
  chat.perform("send_message", { body: messageBody, chat_room_id: roomId, user_id: userId });
  // alert("sendMessage firing");
}

// Getting a message: this callback will be invoked once we receive
// something over AtmoChatChannel
function setCallback(fn) {
  callback = fn;
}

export { connectToLiveListeningChatChannel, sendMessage, setCallback };
