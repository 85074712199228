<template>
  <div>
    <div style="width:60%; margin:0 auto; background-color:rgba(22,37,54,0.7); border-radius:5px;">
      <div style="height: 100px; width:80%; margin:0 auto;">
        <p style="font-size:30px; text-align: center; vertical-align: middle; line-height: 100px;">
          Forgot Password
        </p>
      </div>
      <div style="height:30px; width:80%; margin:0 auto; margin-bottom:10px;">
        <input
          v-model="password"
          style="display:block; margin:0 auto; font-size:20px; text-align: center; vertical-align: middle; border-radius:25px; width:63%; height:30px; background-color:rgba(255,255,255,0.1);"
          placeholder="new password"
        >
      </div>
      <input
        style="display:block; margin:0 auto; font-size:23px; text-align: center; vertical-align: middle; cursor:pointer; border:solid white 1px; border-radius:25px; width:50%; height:30px;"
        type="submit"
        value="Change Password"
        @click="changePassword(password)"
      >
      <div style="height: 100px; width:80%; margin:0 auto;">
        <p style="font-size:1em; text-align: center; vertical-align: middle; line-height: 100px;">
          Nevermind. <router-link :to="{ name: 'login' }">
            Take me to Login.
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import Axios from 'axios'

  export default {
    data(){
      return {
        password: null
      }
    },
    methods: {
      changePassword(password) {
        Axios({
          method: 'patch',
          url: `/api/v1/users/${this.$route.params.userId}`,
          data: {
            password
          }
        })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      },
    }
  }
</script>
