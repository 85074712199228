<template>
  <div class="ops-page">
    <div class="row">
      <div class="col" style="text-align: center;">
        <h2 class="atmo-page-header mb-5 text-center">
          Atmo Campaigns
        </h2>
      </div>
    </div>
    <div class="row" style="justify-content: center;">
      <div class="col-9 ops-page__navigate-back">
        <router-link
          :to="{ name: 'users.ops_pages', params: { userId: this.campaign.user.id } }">
          <img src="@/assets/images/icons/back.png" class="ops-page__go-back-image">
          <p>Back to campaigns</p>
        </router-link>
      </div>
    </div>

    <div class="row" style="justify-content: center;">
      <!-- campaigns image and actions -->
      <div class="col-2">
        <div class="row">
          <div class="col ops-page__campaign-image">
            <img :src="getFeaturedImage(campaign.images, 'full')" alt="Campaign image" style="width: 100%;" @click="openUserMediaModal({
            type: 'Image',
            caption: campaign.images[0].name,
            url: campaign.images[0].image.url,
            id: campaign.images[0].id
          })">
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h3 class="ops-page__campaign-name">
              {{ campaign.campaign_name }}
            </h3>
          </div>
          <div class="ops-page-dates col-12">
            <div class="ops-page-dates__label-wrap">
              Start Date
            </div>
            <div class="ops-page-dates__badge-wrap">
              <atmo-badge :label="getDate(campaign.start_date)" />
            </div>
            <div class="ops-page-dates__label-wrap">
              End Date
            </div>
            <div class="ops-page-dates__badge-wrap">
              <atmo-badge :label="getDate(campaign.end_date)" />
            </div>
          </div>
        </div>

        <div class="row" :no-gutters="true">
          <div class="col-9">
            <span class="ops-page__campaign-actions">
              <router-link :to="{ name: 'ops_pages.edit', params: { opsPageId: campaign.id } }">
                <button class="ops-page__edit-campaign">Edit</button>
              </router-link>
            </span>
          </div>
          <div>
            <div class="ops-page__delete-campaign" @click="deletePage(campaign.id)">
              <img src="@/assets/images/icons/delete-red.png" data-cy="delete-campaign">
            </div>
          </div>
        </div>
      </div>
      <!-- video, goal and campaign description -->
      <div class="col-5 ops-page__about-container">
        <div class="row">
          <div class="col">
            <!-- TODO: LIMIT TO ONE VIDEO? -->
            <div class="ops-page__watch-video-container" :style="{
            'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)'
              +
              ','
              +
              'url(' + featuredVideo.preview_image.url + ')'
          }" @click="openUserMediaModal({
            type: 'Video',
            caption: featuredVideo.name,
            url: featuredVideo.video.url,
            id: featuredVideo.id
          })">
              <img class="ops-page__video-icon" src="@/assets/images/icons/ops/watch_video.png">
              <p>Watch video</p>
            </div>
          </div>
        </div>
        <!-- donation goal -->
        <div class="row ops-page__donation-progress">
          <div class="col-6">
            Raised: US $12,000
          </div>
          <div class="col-6 ops-page__donation-goal-text text-right">
            <img class="ops-page__goal-icon" src="@/assets/images/icons/goal.png" alt="Campaign goal">
            <p class="d-inline">
              Goal: US ${{ campaign.goal }}
            </p>
          </div>
          <div class="col-12 ops-page__donation-progress-meter">
            <atmo-progress-bar :percent="calculatePercent(campaign)" />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <h3>About:</h3>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>{{ campaign.campaign_description }}</p>
          </div>
        </div>
      </div>
      <!-- donation details -->
      <div class="col-2 ops-page__donations-container">
        <div class="row">
          <div class="col">
            <h3>Donation amounts:</h3>
          </div>
        </div>

        <perfect-scrollbar>
          <atmo-ops-donation v-for="donation in campaign.ops_page_donations" :key="donation.id"
            :description="donation.description" :amount="donation.amount" />
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AtmoProgressBar from "@/components/atmo-progress-bar.vue";
import AtmoOpsDonation from "@/components/atmo-ops-donation.vue";
import AtmoBadge from '@/components/atmo-badge';
import { utilsGetDate, getFeaturedImage, getFeaturedVideo } from '@/helpers/utilityFunctions';

export default {
  components: {
    AtmoBadge,
    AtmoProgressBar,
    AtmoOpsDonation
  },
  computed: {
    ...mapGetters({
      campaign: "ops/currentPage"
    }),
    featuredVideo() {
      return this.getFeaturedVideo(this.campaign.videos);
    }
  },
  created() {
    this.setPage(this.$route.params.opsPageId);
  },
  methods: {
    ...mapActions("ops", ["setPage", "deletePage"]),
    openUserMediaModal(userMediaObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/setUserMediaModalObj", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    },
    getDate(date) {
      return utilsGetDate(date);
    },
    getFeaturedImage(images, type) {
      return getFeaturedImage(images, type);
    },
    getFeaturedVideo(videos) {
      return getFeaturedVideo(videos);
    },
    calculatePercent: function () {
      return 75; // TBA: should be (campaign.goal / aggregated donations) *100
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.ops-page {
  &__navigate-back {
    margin-bottom: 12px;

    img {
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }

    p {
      display: inline;
      font-weight: bold;
      padding-left: 6px;
    }
  }

  &__campaign-image {
    margin-top: 4px;
    cursor: pointer;

    img {
      width: 100%;
      border-radius: 5px;
      // box-shadow: 2px 2px 2px 2px rgba(black, 0.2);
    }
  }

  &__campaign-name {
    font-weight: bold;
    padding: 24px 0px;
  }

  .ops-page-dates {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .ops-page-dates__label-wrap {
    margin-bottom: .5rem;
  }

  // .ops-page-dates__badge-wrap {
  // }

  .atmo-badge {
    border-radius: 5px;
    font-weight: 500;
    background-color: $atmo-purple--medium-dark--overlay-faded;
    padding: 7px;
    margin-bottom: .5rem;
  }

  &__delete-campaign {
    background: white;
    border: 1px solid white;
    border-radius: 5px;
    margin-left: .1rem;
    min-height: 2.6rem;
    min-width: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      height: 1.2rem;
    }
  }

  //TODO: use atmo-button--transparent
  &__edit-campaign {
    width: 100%;
    min-height: 2rem;
    line-height: 40px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    border: 1px solid white;
    border-radius: 5px;
  }

  &__watch-video-container {
    min-height: 100px;
    line-height: 100px;
    background-position: center center;
    background-size: cover;
    border-radius: 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;

    div {
      background-color: $atmo-purple--medium-dark--overlay-faded;
    }

    img {
      max-width: 50px;
      vertical-align: middle;
      padding-bottom: 8px; // to align text and icon
      border-radius: 5px;
    }

    p {
      text-transform: uppercase;
      display: inline;
      font-weight: 450;
      padding-left: 6px;
      font-size: $atmo-subtitle-size;
    }
  }

  &__about-container {
    h3 {
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 24px;
      margin-bottom: 12px;
    }

    p {
      line-height: 1.5;
    }
  }

  &__donation-progress {
    margin-top: 24px;
    font-weight: bold;
    text-transform: uppercase;

    &-meter {
      margin-top: 12px;
    }
  }

  &__donation-goal-text img {
    width: 18px;
    vertical-align: middle;
  }

  &__donations-container {
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 5px;

    h3 {
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 24px;
      margin-bottom: 12px;
    }
  }
}
</style>
