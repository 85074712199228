<template>
  <div class="atmo-wrapper">
    <div class="atmo-items-wrapper">
      <div class="atmo-auth">
        <div class="atmo-auth__header">
          <h1 class="atmo-auth__header-text">
            Welcome Back To
          </h1>
          <h2 class="atmo-auth__header-text2">
            THE
            <span class="atmo-auth__header-a">ATMOSPHERE</span>.
          </h2>
          <h3 class="atmo-auth__header-text3">
            Login to your account below
          </h3>
        </div>
        <form id="atmo-auth-form" @submit.prevent="login">
          <atmo-input v-model="email" label="Email" name="email" type="email" class="atmo-auth__input"
            placeholder="theatmosphere@gmail.com" />
          <atmo-input v-model="password" onlyunder name="password" label="Password" placeholder="••••••" type="password"
            :maxlength="50" />
          <atmo-check-box :value="rememberMe" :click="onRememberMe" label="Remember me." />

          <div class="atmo-auth__button">
            <input class="atmo-auth__card-button" type="submit" value="Sign In">
          </div>
        </form>
      </div>
      <background h1="Are you excited to join the Atmosphere?"
        h2="That's awesome! You can signup by clicking on the button below. Later, you can login here to your account easily."
        button-text="Register" to-route="signup"
        ads="Are you a business looking to advertise your products/services on Atmosphere?" />
    </div>
  </div>
</template>
<script>
import Background from "./background";
import AtmoInput from "@/components/atmo-input";
import AtmoCheckBox from "@/components/atmo-checkbox";
/* eslint-disable no-unused-vars */
import buzz from "buzz";
import { setDefaultAxiosAuth, setDefaultAxiosHeaders } from "@/helpers/axios";
import {
  callUserTokenCreate
} from '@/helpers/axiosCalls';

export default {
  components: {
    Background,
    AtmoInput,
    AtmoCheckBox,
  },
  data() {
    return {
      rememberMe: false,
      email: null,
      password: null,
      amiAudio: ""
    };
  },
  created() {
    // this.setBuzzAudio("Male");
  },
  methods: {
    // setBuzzAudio(gender) {
    //   if (gender == "Male" && this.amiAudio["male welcome"]) {
    //     this.amiAudio = new buzz.sound(
    //       __AMISpeechStore["male welcome"]["speech"]
    //     ); // these should be imported from AMI speech store
    //     this.amiAudio.setVolume(100);
    //   } else if (__AMISpeechStore["female welcome"]) {
    //     this.amiAudio = new buzz.sound(
    //       __AMISpeechStore["female welcome"]["speech"]
    //     ); // these should be imported from AMI speech store
    //     this.amiAudio.setVolume(100);
    //   }
    // },
    login() {
      callUserTokenCreate({
        auth: {
          email: this.email,
          password: this.password
        }
      })
        .then(response => {
          const token = response.jwt;
          localStorage.setItem("token", token);
          setDefaultAxiosAuth(token);
          setDefaultAxiosHeaders(token)
          this.$router.push("/")
            .then(() => {
              window.location.reload();
            });
          
          if (
            !this.$store.state.amiSpeechMuted &&
            this.$store.state.amiSoundType == "speech"
          ) {
            this.amiAudio.play();
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    onRememberMe() {
      console.log('here');
      this.rememberMe = !this.rememberMe;
      console.log(this.rememberMe);
    }
  }
};
</script>
