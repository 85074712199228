// Mostly copy/pasted from https://github.com/SethJones90/theatmosphere/blob/a958ca89a43badd6c1c6f8708477687f7e272f09/app/javascript/routes/albums/show.vue#L158-L424

import Meyda from 'meyda';
// import jsmediatags from "jsmediatags"; // COMMENTED OUT WHEN SETTING UP NEW FRONT END
import MusicTempo from 'music-tempo';
import atmoMusicExtractor from '@/music-extractor';

// TODO: clean up all of the promises in here
export function extractAudioFileData(file) {
  return new Promise((resolve, reject) => {

    console.log(`%cEXTRACTING SONG TEMPO`, 'color: white; background-color: #19c57e; padding: 2px 5px; border-radius: 2px');

    Meyda.bufferSize = 512;

    console.log("FILE:", file)

    // COMMENTED OUT WHEN SETTING UP NEW FRONT END
    // jsmediatags.read(file, {
    //   onSuccess: function(tag) {
    //     console.log(tag);
    //   },
    //   onError: function(error) {
    //     console.error(':(', error.type, error.info);
    //   }
    // });

    const songNotes = [];
    const musicArray = [];

    let determineKey = (c, dFlat, d, eFlat, e, f, fSharp, g, aFlat, a, bFlat, b, tempo) => {
      console.log(`%cDETERMINING KEY`, 'color: white; background-color: #19c57e; padding: 2px 5px; border-radius: 2px');

      let cTotal = 0;
      let dFlatTotal = 0;
      let dTotal = 0;
      let eFlatTotal = 0;
      let eTotal = 0;
      let fTotal = 0;
      let fSharpTotal = 0;
      let gTotal = 0;
      let aFlatTotal = 0;
      let aTotal = 0;
      let bFlatTotal = 0;
      let bTotal = 0;

      let finalTotalObject = {};
      let finalTotals = [];


      for (let a = 0; a < c.length; a++) {
        cTotal += c[a];
        finalTotalObject = {note: 'B#/C', 'total': cTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let i = 0; i < dFlat.length; i++) {
        dFlatTotal += dFlat[i];
        finalTotalObject = {note: 'C#/Db', 'total': dFlatTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let j = 0; j < d.length; j++) {
        dTotal += d[j];
        finalTotalObject = {note: 'D', 'total': dTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let k = 0; k < eFlat.length; k++) {
        eFlatTotal += eFlat[k];
        finalTotalObject = {note: 'D#/Eb', 'total': eFlatTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let l = 0; l < e.length; l++) {
        eTotal += e[l];
        finalTotalObject = {note: 'E', 'total': eTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let m = 0; m < f.length; m++) {
        fTotal += f[m];
        finalTotalObject = {note: 'E#/F', 'total': fTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let n = 0; n < fSharp.length; n++) {
        fSharpTotal += fSharp[n];
        finalTotalObject = {note: 'F#/Gb', 'total': fSharpTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let o = 0; o < g.length; o++) {
        gTotal += g[o];
        finalTotalObject = {note: 'G', 'total': gTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let p = 0; p < aFlat.length; p++) {
        aFlatTotal += aFlat[p];
        finalTotalObject = {note: 'G#/Ab', 'total': aFlatTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let q = 0; q < a.length; q++) {
        aTotal += a[q];
        finalTotalObject = {note: 'A', 'total': aTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let r = 0; r < bFlat.length; r++) {
        bFlatTotal += bFlat[r];
        finalTotalObject = {note: 'A#/Bb', 'total': bFlatTotal};
      }
      finalTotals.push(finalTotalObject);

      for (let s = 0; s < b.length; s++) {
        bTotal += b[s];
        finalTotalObject = {note: 'B', 'total': bTotal};
      }
      finalTotals.push(finalTotalObject);
      musicArray.push(finalTotals);

      const atmoME = new atmoMusicExtractor(musicArray);
      atmoME.findKey();
      atmoME.showMeTheKey().then((songAttributes) => {
        // console.log("FROM EXTRACTOR CLASS", fromResolve);
        // fromResolve = {key: "C", key_id: 1, tonality: "Major", tonality_id: 1};
        songAttributes.tempo = tempo;
        resolve(songAttributes)
      });
    }


    let audioCtx = new AudioContext();
    let offlineCtx = new OfflineAudioContext(2,44100*40,44100);

    let reader = new FileReader();

    reader.onload = (fileEvent) => {

      let audioData = fileEvent.target.result;
      let source = offlineCtx.createBufferSource();

      // decodeAudioData to decode it and OfflineAudioContext to render it
      audioCtx.decodeAudioData(audioData, (buffer) => {

        //TEMPO LOGIC
        // console.log(buffer);
        let audioData = [];
        // Take the average of the two channels
        if (buffer.numberOfChannels == 2) {
          let channel1Data = buffer.getChannelData(0);
          let channel2Data = buffer.getChannelData(1);
          let length = channel1Data.length;
          for (let i = 0; i < length; i++) {
            audioData[i] = (channel1Data[i] + channel2Data[i]) / 2;
          }
        } else {
          audioData = buffer.getChannelData(0);
        }
        // console.log(audioData);
        let mt = new MusicTempo(audioData);
        // console.log(mt);
        const songTempo = Math.round(mt.tempo);
        console.log(`%cTEMPO: ${songTempo}`, 'color: white; background-color: #593F71; padding: 2px 5px; border-radius: 2px');
        //update song updated with this attribute

        //KEY LOGIC
        let myBuffer = buffer;
        source.buffer = myBuffer;
        source.connect(offlineCtx.destination);
        source.start();
        //source.loop = true;
        offlineCtx.startRendering().then((renderedBuffer) => {
          console.log('Rendering completed successfully');
          let song = audioCtx.createBufferSource();
          song.buffer = renderedBuffer;

          //Loop through the output channels
          for (let channel = 0; channel < renderedBuffer.numberOfChannels; channel++) {
            // console.log("channel", channel);
            const outputData = renderedBuffer.getChannelData(channel);
            // console.log("outputData OFFLINE AUDIO CONTEXT:", outputData);

            for (let i = 0; i < outputData.length - 512; i += 512) {
              // get all chromas ([0,1,2,3,4,5,6,7,8,9,10,11])
              const noteArray = Meyda.extract('chroma', outputData.slice(i, i + 512))
              songNotes.push(noteArray);
            }
          }

          let c = [];
          let dFlat = [];
          let d = [];
          let eFlat = [];
          let e = [];
          let f = [];
          let fSharp = [];
          let g = [];
          let aFlat = [];
          let a = [];
          let bFlat = [];
          let b = [];

          for (let i=0; i < songNotes.length; i++) {
            // push proper chroma from each array into respective arrays (find c in each array, then push all values into c array)
            c.push(songNotes[i][0]);
            dFlat.push(songNotes[i][1]);
            d.push(songNotes[i][2]);
            eFlat.push(songNotes[i][3]);
            e.push(songNotes[i][4]);
            f.push(songNotes[i][5]);
            fSharp.push(songNotes[i][6]);
            g.push(songNotes[i][7]);
            aFlat.push(songNotes[i][8]);
            a.push(songNotes[i][9]);
            bFlat.push(songNotes[i][10]);
            b.push(songNotes[i][11]);
          }
          determineKey(c, dFlat, d, eFlat, e, f, fSharp, g, aFlat, a, bFlat, b, songTempo);

        }).catch((error) => {
            console.error('Rendering failed: ' + error);
            reject(error)
            // Note: The promise should reject when startRendering is called a second time on an OfflineAudioContext
        });
      });
    }
    reader.readAsArrayBuffer(file);
  })
}
