import Axios from 'axios';

export function callContestPrizesIndex(contestId, queryParams = {}) {
    return Axios.get(`/api/v1/contests/${contestId}/contest_prizes`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestPrizesCreate(contestId, requestData, queryParams = {}) {
    return Axios({
        method: 'post',
        url: `/api/v1/contests/${contestId}/contest_prizes`,
        data: requestData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestPrizesUpdate(contestId, prizeId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/contests/${contestId}/contest_prizes/${prizeId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callContestPrizesDelete(contestId, prizeId, queryParams = {}) {
    return Axios.delete(`/api/v1/contests/${contestId}/contest_prizes/${prizeId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}