<template>
    <b-modal id="user-media-modal" ref="userMediaModal" v-model="isModalOpen" title="Ami Options"
        modal-class="atmo-modal" size="lg" hide-header hide-footer centered @hide="hideModal" @shown="onModalShown">
        <header class="user-media-modal__header">
            <div class="header__hide-ad-wrap" @click="hideModal">
                <img class="hide-ad-wrap__hide-ad" src="@/assets/images/icons/close_popup.png" alt="close"
                    title="Hide Ad">
            </div>
        </header>
        <div class="user-media-modal__media-container">
            <div v-if="$store.state.videoPlayer.userMediaModalObj.type == 'Video'" class="media-container__image-wrap">
                <video-player v-if="computedUrl" :options="{
                    autoplay: false,
                    controls: true,
                    height: 295,
                    width: 420,
                    sources: [
                        {
                            src: computedUrl,
                            type: 'video/mp4'
                        }
                    ]
                }" />
                <div class="media-container__theatre-icon-wrap"
                    @click="playTheatreVideo($store.state.videoPlayer.userMediaModalObj)">
                    <img :src="require('@/assets/images/icons/ops/watch_video.png')" />
                </div>
            </div>
            <div v-else class="media-container__image-wrap"
                v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${$store.state.videoPlayer.userMediaModalObj.url})` }">
            </div>
            <div class="media-container__comments-wrap">
                <photo-comments></photo-comments>
            </div>
        </div>
    </b-modal>
</template>

<script>
import VideoPlayer from '@/components/video-player.vue';
import PhotoComments from '@/components/user-media-modal-comments.vue';

export default {
    components: {
        VideoPlayer,
        PhotoComments
    },
    computed: {
        computedUrl() {
            return this.$store.state.videoPlayer.userMediaModalObj.url || ''; // Fallback to empty string
        },
        isModalOpen: {
            get() {
                return this.$store.state.videoPlayer.userMediaModalOpen;
            },
            set(value) {
                this.$store.commit('videoPlayer/openUserMediaModal', value);
            }
        }
    },
    methods: {
        playTheatreVideo(video) {
            if (!video.url) {
                console.error('Video URL is missing.');
                return;
            }

            // Hide the modal first
            this.$refs.userMediaModal.hide();

            // Ensure video background data is set properly
            this.$store.commit("videoPlayer/setVideoBackgroundUrl", video.url);
            this.$store.commit("videoPlayer/setVideoBackgroundObject", video);
            this.$store.commit("videoPlayer/setVideoBackgroundLyrics", video.lyrics || '');
            this.$store.commit("videoPlayer/setVideoBackgroundName", video.name || '');

            // Wait for next tick to ensure the changes have been applied
            this.$nextTick(() => {
                // Check if the video player object and options are available
                const videoPlayerRef = this.$refs.videoPlayer;
                if (videoPlayerRef && videoPlayerRef.player) {
                    videoPlayerRef.player.src({ src: video.url, type: 'video/mp4' }); // Set the video source
                    videoPlayerRef.player.play(); // Play the video
                } else {
                    console.error('Video player or options are not available.');
                }

                // Delay playback to ensure the player is ready
                setTimeout(() => {
                    this.$store.commit("videoPlayer/playVideo");
                    this.$store.commit("videoPlayer/toggleMainContent");
                }, 500); // Delay for modal to fully close
            });
        },
        hideModal() {
            // Close the modal
            this.$refs.userMediaModal.hide();

            // Reset the media modal object and close the modal in Vuex store
            if (this.$store.state.videoPlayer.userMediaModalObj.url) {
                this.$store.commit("videoPlayer/setUserMediaModalObj", {});
            }
            // Set the modal state to false
            this.$store.commit('videoPlayer/closeUserMediaModal');
        },
        onModalShown() {
            console.log("User media modal shown");
        }
    }
}
</script>


<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';
@import 'video.js/dist/video-js.css';
//https://videojs.com/guides/vue/
//https://videojs.com/guides/options/

#user-media-modal {
    .modal .modal-lg.modal-dialog {
        max-width: 1000px;
    }

    .modal-content {
        backdrop-filter: blur(5px);
        border-radius: 5px;
        border: none;
    }

    .modal-body {
        position: relative;
    }

    .user-media-modal__header {
        position: absolute;
        right: 0rem;
        height: 1rem;
        z-index: 1;
    }

    .header__hide-ad-wrap {
        height: 2.5rem;
        width: 2.5rem;
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hide-ad-wrap__hide-ad {
        height: 1rem;
        width: auto;
        cursor: pointer;
    }

    .user-media-modal__media-container {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .ps {
            max-height: 15rem;
            margin-bottom: 1rem;
        }

        .media-container__image-wrap {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: relative;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: flex-start;
            padding: .8rem 1rem 0rem 1rem;

            .video-js {
                background-color: rgba(96, 72, 117, 0.0);
            }

            .vjs-control-bar {
                background-color: rgba(96, 72, 117, 0.6);
                // height: 2rem;

                // div {
                //     // background-color: rgba(96,72,117, 0.6);
                // }
            }

            // .video-js .vjs-play-progress {
            //     background-color: linear-gradient(-270deg, $atmo-pink--medium 0%, $atmo-blue--light 100%) !important;
            // }
        }

        .media-container__theatre-icon-wrap {
            cursor: pointer;

            img {
                height: 1rem;
            }
        }

        .media-container__comments-wrap {
            margin-top: 1.5rem;
            padding: 1rem;
            // border: 1px solid white;
        }

        //   .media-container__text-wrap {
        //     padding: 1rem;
        //   }

        //   .text-wrap__title-wrap {
        //     font-size: 1.2rem;
        //     font-weight: 500;
        //     margin-bottom: 0.5rem;
        //     text-transform: uppercase;
        //   }

        //   .text-wrap__body-button-wrap {
        //     display: flex;
        //     display: flex;
        //     align-items: center;
        //   }

        //   .body-button-wrap__body-wrap {
        //     display: flex;
        //     align-items: center;
        //     margin-right: 1rem;
        //   }

        //   .body-button-wrap__button-wrap {
        //     justify-content: center;
        //     align-items: flex-end;
        //     margin-left: auto;
        //   }

        //   .button-wrap__button {
        //     display: inline-block;
        //     border: 1px solid white;
        //     border-radius: 5px;
        //     text-transform: uppercase;
        //     padding: .5rem 1rem .5rem 1rem;
        //     color: white;
        //     font-size: .8rem;
        //     font-weight: 600;
        //   }

    }
}
</style>