<template>
  <div class="atmo-media-uploader">
    <div v-if="!selectedMedia" class="media-container__media-wrap editing-image"
      v-bind:style="{'height': `${height}`, 'width': `${width}`, 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${existingMediaUrl})` }">
      <div class="media-wrap__icon-wrap">
        <label :for="componentId" class="event-file-upload">
          <img class="icon-wrap__icon" src='@/assets/images/icons/profile/upload_new.png' />
          <div class="icon-wrap__text">Upload New {{ mediaType[0].toUpperCase() + mediaType.slice(1) }}</div>
        </label>
        <input :id="componentId" type="file" @change="onFileSelected" style="display: none" />
      </div>
    </div>
    <div v-else class="media-container__media-wrap editing-image"
      v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${previewImageUrl})` }">
      <div class="media-wrap__icon-wrap">
        <label :for="componentId" class="event-file-upload">
          <img class="icon-wrap__icon" src='@/assets/images/icons/profile/upload_new.png' />
          <div class="icon-wrap__text">Upload New {{ mediaType[0].toUpperCase() + mediaType.slice(1) }}</div>
        </label>
        <input :id="componentId" type="file" @change="onFileSelected" style="display: none" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    componentId: {
      type: String,
    },
    mediaType: {
      type: String,
    },
    existingMediaUrl: {
      type: String,
    },
  },
  data: function () {
    return {
      selectedMedia: null,
      previewImageUrl: null,
    };
  },
  methods: {
    onFileSelected(event) {
      // Handle file upload logic here
      const inputElement = event.target;
      const selectedFile = inputElement.files[0];

      this.selectedMedia = inputElement.files[0];
      this.$emit(`selected-${this.componentId}`, this.selectedMedia);

      // Do something with the selected file
      console.log("SELECTED FILE", selectedFile)

      if (!selectedFile) {
        // Handle no file selected error
        return;
      }

      if (this.mediaType == 'image') {
        if (inputElement.files && inputElement.files[0]) {
          const reader = new FileReader();

          // Read the contents of the file as a data URL
          reader.readAsDataURL(inputElement.files[0]);

          // Set up the event handler for when the file reading is complete
          reader.onload = () => {
            // Set the image preview using Vue's data property
            this.previewImageUrl = reader.result;
          };
        }
      } else {
        this.previewImageUrl = require('@/assets/images/default_pic.jpg');
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-media-uploader {
  .media-container__media-wrap {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 1rem;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .media-wrap__icon-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon-wrap__icon {
    height: 4rem;
    margin-bottom: 1rem;
    cursor: pointer
  }

  .icon-wrap__text {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
  }

  .media-wrap__image {
    border-radius: 2px;
    margin-bottom: .8rem;
    box-sizing: border-box;
  }

  .editing-image {
    border: 2px dashed rgba(255, 255, 255, 0.4);
  }

  .media-container__select-media-container {
    max-height: 12rem;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  }

  .select-media-container__media-wrap {
    height: 5rem;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .media-wrap__select-wrap {
    height: 1.5rem;
    width: 1.5rem;
    background: rgba(96, 72, 117, 0.75);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .event-file-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  //TODO: GIVE THIS A HOME
}
</style>