<template>
  <atmo-popover
    class="song-actions-popover"
    :placement="placement"
  >
    <img
      slot="trigger"
      src="@/assets/images/icons/share--blue.png"
      alt="Song Actions"
      class="song-actions-popover__trigger"
    >
    <div slot="content">
      <template v-for="(action, index) in actions">
        <hr
          v-if="index > 0"
          :key="`${_uid}-${action}`"
          class="atmo-popover__hr"
        >
        <a
          :key="action"
          class="atmo-popover__dropdown-action atmo-popover__dropdown-action--extra-padding"
          @click="dispatchStoreActionAndNotify(action)"
        >
          {{ actionTypes[action].label }}
        </a>
      </template>
    </div>
  </atmo-popover>
</template>

<script>
  import AtmoPopover from './atmo-popover';

  export default {

    components: { AtmoPopover },
    props: {
      placement: {
        type: String,
        default: 'right'
      },
      song: {
        type: Object,
        required: true
      },
      actions: {
        type: Array,
        default: () => ['add-to-queue']
      }
    },

    data() {
      return {
        // TODO: Add other method actions here, probably defined in store.js
        actionTypes: {
          'share-song-as-post': {
            label: 'Share Song as Post',
            storeMethodName: 'stubAction',
            successMessage: 'Song Shared',
            errorMessage: 'Error sharing song'
          },
          'share-song-as-message': {
            label: 'Share Song as Message',
            storeMethodName: 'stubAction',
            successMessage: 'Song Shared',
            errorMessage: 'Error sharing song'
          },
          'add-to-queue': {
            label: 'Add to Queue',
            storeMethodName: 'atmoQueues/addToQueueFromPopover',
            successMessage: 'Song Added to Queue',
            errorMessage: 'Error adding song to Queue'
          },
          'add-playlist-to-queue': {
            label: 'Add Playlist to Queue',
            storeMethodName: 'atmoQueues/addPlaylistToQueue',
            successMessage: 'Playlist Added to Queue',
            errorMessage: 'Error adding playlist to Queue'
          },
          'add-to-library': {
            label: 'Add to Library',
            storeMethodName: 'addToLibrary',
            successMessage: 'Song Added to Library',
            errorMessage: 'Error adding song to Library'
          },
          'remove-from-library': {
            label: 'Remove from Library',
            storeMethodName: 'removeFromLibrary',
            successMessage: 'Song Removed from Library',
            errorMessage: 'Error removing song from Library'
          },
          'add-to-playlist': {
            label: 'Add to Playlist',
            storeMethodName: 'stubAction',
            successMessage: 'Song Added to Playlist',
            errorMessage: 'Error adding song to Playlist'
          },
          'create-playlist-from-station': {
            label: 'Create Playlist From Station',
            storeMethodName: 'createPlaylistFromStation',
            successMessage: 'Playlist created',
            errorMessage: 'Error creating playlist'
          },
        }
      }
    },

    methods: {
      dispatchStoreActionAndNotify(action) {
        const actionType = this.actionTypes[action];
        this.$store.dispatch(actionType.storeMethodName, this.song).then(() => {
          this.$notify({ group: 'vue-app', title: actionType.successMessage });
        }).catch(() => {
          this.$notify({ type: 'error', group: 'vue-app', title: actionType.errorMessage });
        });
      }
    }
  }
</script>

<style lang="scss">
  .song-actions-popover {
    &__trigger {
      width: 15px;
      transition: width 0.2s ease-in;
      cursor: pointer;

      &:hover {
        width: 18px;
      }
    }
  }
</style>
