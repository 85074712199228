<template>
  <div class="artist-dashboard" data-cy="artist-dashboard">
    <div class="header-container">
      <h2 class="atmo-page-header">
        Artist Dashboard
      </h2>
    </div>
    <atmo-back-link></atmo-back-link>
    <div class="artist-dashboard-grid-container">
      <div class="artist-dashboard-grid-container__demo-container">
        <div class="demo-container__title-more-container">
          <div class="title-more-container__title-wrap">{{ user.name }}</div>
        </div>
        <div class="demo-container__artist-info-container">
          <div class="artist-info-container__image-wrap"
            v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${user.imageUrl})` }">
          </div>
          <div class="artist-info-container__text-wrap">
            <div class="class-wrap__info-wrap">
              <p class="info-wrap__title">Total Song Plays</p>
              <p class="info-wrap__value">{{ artistDashboardData.song_play_data.total_song_plays }}</p>
            </div>
            <div class="class-wrap__info-wrap">
              <p class="info-wrap__title">Most Played Song</p>
              <p class="info-wrap__value">{{ artistDashboardData.song_play_data.song_play_counts[0].name }}</p>
            </div>
            <!-- <div class="class-wrap__info-wrap">
              <p class="info-wrap__title">Most Liked Song</p>
              <p class="info-wrap__value">{{ generateRandomString(devMockData.songTitleArray) }}</p>
            </div> -->
            <div class="class-wrap__info-wrap">
              <p class="info-wrap__title">Most Purchased Item</p>
              <p class="info-wrap__value">{{ artistDashboardData.counted_product_sales[0].product_name }}</p>
            </div>
            <div class="class-wrap__info-wrap">
              <p class="info-wrap__title">
                Total Revenue
                <router-link :to="{ name: 'artist_dashboards.purchases.show', params: { artistProfileId: artistProfileId} }">(See More)</router-link>
              </p>
              <p class="info-wrap__value">${{ artistDashboardData.revenue.net_revenue }}</p>
            </div>
          </div>
        </div>
        <div class="demo-container__title-more-container">
          <div class="title-more-container__title-wrap">Gender Demographics</div>
          <router-link :to="{ name: 'artist_dashboards.fan_demographics.show', params: { artistProfileId: artistProfileId} }" class="title-more-container__more-wrap">
            <p>More</p>
            <img class="more-wrap__image" src="@/assets/images/icons/arrow_right.png" />
          </router-link>
        </div>
        <div class="demo-container__gender-age-location-container">
          <div class="gender-age-location-container__gender-demo-container">
            <div class="gender-demo-container__gender-demos-wrap">
              <div class="gender-demos-wrap__icon-wrap">
                <img class="icon-wrap__icon" src="@/assets/images/icons/artist_dashboard/male.png" />
              </div>
              <div class="gender-demos-wrap__text-wrap">
                <div class="text-wrap__gender-text">Male</div>
                <div class="text-wrap__gender-percentage">{{ artistDashboardData.gender_data.male_percentage }}%</div>
              </div>
            </div>
            <div class="gender-demo-container__gender-demos-wrap">
              <div class="gender-demos-wrap__icon-wrap">
                <img class="icon-wrap__icon" src="@/assets/images/icons/artist_dashboard/female.png" />
              </div>
              <div class="gender-demos-wrap__text-wrap">
                <div class="text-wrap__gender-text">Female</div>
                <div class="text-wrap__gender-percentage">{{ artistDashboardData.gender_data.female_percentage }}%</div>
              </div>
            </div>
          </div>
          <div class="gender-age-location-container__age-location-demo-container">
            <div class="age-location-demo-container__age-location-wrap">
              <div class="age-location-wrap__image-wrap">
                <img class="image-wrap__image" src="@/assets/images/icons/artist_dashboard/birthday.png" />
              </div>
              <div class="age-location-wrap__text-wrap">
                <div class="text-wrap__top-line">{{ artistDashboardData.fan_average_age }}</div>
                <div class="text-wrap__bottom-line">Average Age (Yrs)</div>
              </div>
            </div>
            <div class="age-location-demo-container__age-location-wrap">
              <div class="age-location-wrap__image-wrap">
                <img class="image-wrap__image" src="@/assets/images/icons/artist_dashboard/pin.png" />
              </div>
              <div class="age-location-wrap__text-wrap">
                <div class="text-wrap__top-line">
                  <span
                    v-for="(location, index) in topLocations"
                    :key="index"
                  >
                  <template v-if="index === topLocations.length - 1">& {{ location }}</template>
                  <template v-else>{{ location }}, </template></span>
                </div>
                <div class="text-wrap__bottom-line">Top Locations</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="artist-dashboard-grid-container__venues-container">
        <div class="venues-container__title-more-container">
          <div class="title-more-container__title-wrap">Top Venue Requests</div>
          <router-link :to="{ name: 'artist_dashboards.venue_requests.show' }" class="title-more-container__more-wrap">
            <p>More</p>
            <img class="more-wrap__image" src="@/assets/images/icons/arrow_right.png" />
          </router-link>
        </div>
        <div class="venues-container__venues-table-wrap">
          <perfect-scrollbar>
            <div v-for="(venue, index) in artistDashboardData.venue_counts_and_data" :key="index"
              :class="index % 2 == 0 ? 'venues-table-wrap__row --dark' : 'venues-table-wrap__row --light'">
              <div class="row__venue-name">{{ venue.name }}</div>
              <div class="row__venue-location">{{ venue.city }}, {{ venue.state }}</div>
              <div class="row__venue-requests">{{ venue.count }}</div>
            </div>
          </perfect-scrollbar>
        </div>
        <div class="venues-container__record-deals-wrap">
          <div class="record-deals-wrap__title-wrap">Record Deals</div>
          <div class="record-deals-wrap__label-wrap">
            <div class="label-wrap__label-name">{{ artistProfile.label_profile.user.name }}</div>
            <img :src="getFeaturedImage(artistProfile.label_profile.user.images, 'full')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import User from '@/models/user';
import devMockData from '@/devUtils/mockData.js';
import { callUsersShow } from '@/helpers/axiosCalls';
import AtmoBackLink from '@/components/atmo-back-link';
import { getFeaturedImage } from '@/helpers/utilityFunctions';

export default {
  components: {
    AtmoBackLink
  },
  data: function () {
    return {
      currentUserId: this.$store.state.currentUserId,
      artistProfile: {},
      user: {},
      artistProfileId: this.$route.params.artistProfileId,
      artistDashboardData: {},
      topLocations: [],
      devMockData,
    }
  },
  created() {
    this.getArtistProfile();
    this.getDashboardData();
  },
  computed: {
    currentUser() {
      return this.$store.state.user;
    },
    isMyProfile() {
      return this.$store.state.currentUserProfileId === this.userProfileId;
    },
    userProfileId() {
      return Number(this.$route.params.artistProfileId);
    }
  },
  methods: {
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    getUser(userId) {
      callUsersShow(userId)
        .then((response) => {
          this.user = new User(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getArtistProfile() {
      Axios.get(`/api/v1/artist_profiles/${this.$route.params.artistProfileId}`, {
      })
        .then((response) => {
          this.artistProfile = response.data;
          console.log("ARTIST PROFILESSSSSSSSSSSZZZZ", this.artistProfile)
          this.getUser(this.artistProfile.user.id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getDashboardData() {
      Axios.get(`/api/v1/artist_profiles/${this.$route.params.artistProfileId}/artist_dashboards`, {
      })
        .then((response) => {
          this.artistDashboardData = response.data;
          this.getTopLocations(this.artistDashboardData.top_three_states);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTopLocations(locationObj) {
      const keys = Object.keys(locationObj);
      for (let i = 0; i < keys.length; i++) {
        this.topLocations.push(keys[i]);
      }
    },
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.artist-dashboard {
  margin: 0 75px;

  //https://github.com/mercs600/vue2-perfect-scrollbar
  .ps {
    max-height: 40vh;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
    color: white;
    // border: 1px solid white;
  }

  .back-container__icon-container {
    margin-right: .5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .artist-dashboard-grid-container {
    // border: 1px solid white;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
  }

  .artist-dashboard-grid-container__demo-container {}

  .demo-container__artist-info-container {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;
  }

  .artist-info-container__image-wrap {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 1rem;
    border-radius: 10px;
    height: 16rem;
  }

  .artist-info-container__text-wrap {
    .class-wrap__info-wrap {
      margin-bottom: 1.5rem;
    }

    .info-wrap__title {
      text-transform: uppercase;
      color: $atmo-gray--light--faded;
      margin-bottom: .5rem;
    }

    .info-wrap__value {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .demo-container__title-more-container,
  .venues-container__title-more-container {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;

    .title-more-container__title-wrap {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 700;
    }

    .title-more-container__more-wrap {
      text-transform: uppercase;
      padding: .4rem 1.5rem .4rem 1.5rem;
      border-radius: 20px;
      border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      margin-left: auto;

      &:hover {
        background: $atmo-purple--light;
        border: 1px solid $atmo-purple--light;
      }

      p {
        margin-top: 2px;
      }
    }

    .more-wrap__image {
      height: 1rem;
      margin-left: .4rem;
    }
  }

  .demo-container__gender-age-location-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .gender-age-location-container__gender-demo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .gender-demo-container__gender-demos-wrap {
    display: flex;
    min-width: 10rem;
  }

  .gender-demo-container__gender-demos-wrap+.gender-demo-container__gender-demos-wrap {
    margin-top: 2rem;
  }

  .gender-demos-wrap__icon-wrap {
    margin-right: 1rem;

    .icon-wrap__icon {
      height: 3.2rem;
    }
  }

  .gender-demos-wrap__text-wrap {
    .text-wrap__gender-text {
      font-weight: 600;
      margin-bottom: .2rem;
      color: $atmo-white--medium-faded;
    }

    .text-wrap__gender-percentage {
      font-size: 2.2rem;
      font-weight: 600;
    }
  }

  .gender-age-location-container__age-location-demo-container {
    padding: 1rem;
  }

  .age-location-demo-container__age-location-wrap {
    background: rgba(161, 125, 187, 0.6);
    margin-bottom: 1rem;
    padding: .7rem;
    border-radius: 10px;
    display: flex;

    .age-location-wrap__image-wrap {
      margin-right: 1rem;
      background: rgba(255, 255, 255, .2);
      border-radius: 100%;
      height: 2.5rem;
      width: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-wrap__image {
      height: 2.5rem;
    }

    .text-wrap__top-line {
      font-size: 1.1rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: .5rem;
    }

    .text-wrap__bottom-line {
      text-transform: uppercase;
      color: $atmo-gray--light--faded;
    }
  }

  .artist-dashboard-grid-container__venues-container {}

  .venues-container__venues-table-wrap {
    margin-bottom: 1rem;
  }

  .--light {
    background: linear-gradient(to right, rgba(255, 255, 255, .2), rgba(255, 255, 255, 0))
  }

  .venues-table-wrap__row {
    display: flex;
    font-weight: 500;
    padding: .8rem 1rem .8rem 1rem;
    border-radius: 25px 0px 0px 25px;

    .row__venue-name {
      flex: 1;
      flex-basis: 0;
      margin: 0rem 1rem 0rem 0rem;
    }

    .row__venue-location {
      flex: 1;
      flex-basis: 0;
    }

    .row__venue-requests {
      flex: 1;
      flex-basis: 0;
    }
  }

  .venues-container__record-deals-wrap {
    img {
      height: 6rem;
      object-fit: cover;
    }
  }

  .record-deals-wrap__title-wrap {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .record-deals-wrap__label-wrap {
    font-weight: 500;

    .label-wrap__label-name {
      margin-bottom: .5rem;
    }
  }
}
</style>