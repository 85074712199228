import Axios from 'axios';

export function callAtmoChartsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/charts`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAtmoChartsShow(chartId, queryParams = {}) {
    return Axios.get(`/api/v1/charts/${chartId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

// Here are example API calls for each chart type in the Api::V1::ChartsController.

// 1. Most Played Chart:
// GET /api/v1/charts?chart_type=most_played

// Optional filters:
// GET /api/v1/charts?chart_type=most_played&mood_ids[]=1&mood_ids[]=2&genre_ids[]=3&limit=10
// This request returns the most played songs filtered by the specified moods and genres, limited to 10 results.

// 2. Top Voted Chart:
// GET /api/v1/charts?chart_type=top_voted

// Optional filters:
// GET /api/v1/charts?chart_type=top_voted&genre_ids[]=3&limit=5
// This request returns the top voted songs filtered by genre, limited to 5 results.

// 3. Top Songs by Year:
// GET /api/v1/charts?chart_type=top_year&year=2024

// Optional filters:
// GET /api/v1/charts?chart_type=top_year&year=2024&mood_ids[]=1&limit=10
// This request returns the top songs for the year 2024, filtered by mood and limited to 10 results.

// 4. Charts for All Genres:
// GET /api/v1/charts?chart_type=most_played&all_genres=true

// Optional filters:
// GET /api/v1/charts?chart_type=most_played&all_genres=true&limit=5
// This request generates the most played charts for each genre, limited to 5 results per genre.

// 5. Charts for All Moods:
// GET /api/v1/charts?chart_type=top_voted&all_moods=true

// Optional filters:
// GET /api/v1/charts?chart_type=top_voted&all_moods=true&limit=10
// This request generates the top voted charts for each mood, limited to 10 results per mood.

// Key Notes:
// mood_ids[] and genre_ids[]: You can pass multiple mood and genre IDs as array parameters.
// limit: Controls the number of songs returned for each chart.

// These API examples cover both specific and comprehensive chart queries based on the provided filters.