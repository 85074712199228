export default {
  data() {
    return {
      Pagination_currentPageNumber: 1,
      pageSize: 8
    };
  },

  created() {
    if (!this.pageSize) {
      throw(new Error('Must have pageSize prop when using Pagination mixin'));
    }
  },

  computed: {
    Pagination_maxPageNumber() {
      return Math.ceil(this.fullCollection.length / this.pageSize);
    },
    Pagination_atFirstPage() {
      return this.Pagination_currentPageNumber <= 1;
    },
    Pagination_atLastPage() {
      return this.Pagination_currentPageNumber >= this.Pagination_maxPageNumber;
    },
    Pagination_itemsForCurrentPage() {
      const currentPageIndex = this.Pagination_currentPageNumber - 1;
      
      return this.fullCollection.slice(
        currentPageIndex * this.pageSize,
        (currentPageIndex + 1) * this.pageSize
      );
    }
  },

  methods: {
    Pagination_onPreviousClick() {
      if (!this.Pagination_atFirstPage) {
        this.Pagination_currentPageNumber = this.Pagination_currentPageNumber - 1;
      }
    },
    Pagination_onNextClick() {
      if (!this.Pagination_atLastPage) {
        this.Pagination_currentPageNumber = this.Pagination_currentPageNumber + 1;
      }
    },
  }
}
