<template>
  <div class="atmo-form-prize">
    <div class="atmo-form-prize__header" @click="onHeaderClick">
      <span>{{ formSelectedName ? formSelectedName : 'New Prize' }}</span>
      <span>{{ expanded ? '-' : '+' }}</span>
    </div>
    <div v-if="expanded">
      <multiselect id="prizeType" v-model="formSelectedPrizeType" :loading="!prizeTypes" :options="prizeTypes"
        label="name" :searchable="false" :show-labels="false" :close-on-select="true" placeholder="Select Prize Type" />
      <div v-if="formSelectedPrizeType && formSelectedPrizeType.name === 'Merch'"
        class="atmo-form-prize__body atmo-form-prize__body--merch">
        <input :id="`prize-name-${index}`" v-model="formSelectedName" type="text" class="text-field name"
          :aria-invalid="submitAttempted && !formSelectedName" placeholder="Type prize name here"
          @input="onPrizeChange" />
        <atmo-upload-card :id="`prize-image-${index}`" size="small" icon-size="small" :image-src="formSelectedImage"
          :is-dropzone="true" :has-label="true" :on-files-selected="onImageSelected" alt="Upload Image"
          componentStyle="width:5rem; height:2rem;">
          <p>Upload Image</p>
        </atmo-upload-card>
        <textarea class="text-field" :id="`prize-description-${index}`" v-model="formSelectedDescription" rows="3"
          placeholder="Type a description here" :aria-invalid="submitAttempted && !formSelectedDescription"
          @input="onPrizeChange"></textarea>
      </div>
      <div v-else-if="formSelectedPrizeType && formSelectedPrizeType.name === 'Credits'"
        class="atmo-form-prize__body atmo-form-prize__body--credits">
        <input :id="`prize-name-${index}`" v-model="formSelectedName" type="text" class="text-field name"
          :aria-invalid="submitAttempted && !formSelectedName" placeholder="Type prize name here"
          @input="onPrizeChange" />
        <input :id="`prize-credits-${index}`" v-model="formSelectedCredits" type="number" min="0"
          class="atmo-form-prize__credits text-field" :aria-invalid="submitAttempted && !formSelectedCredits"
          placeholder="Number of credits" @input="onPrizeChange" />
      </div>
      <div v-else class="atmo-form-prize__body atmo-form-prize__body--other">
        <input :id="`prize-name-${index}`" v-model="formSelectedName" type="text" class="text-field name"
          :aria-invalid="submitAttempted && !formSelectedName" placeholder="Type prize name here"
          @input="onPrizeChange" />
        <textarea class="text-field" :id="`prize-description-${index}`" v-model="formSelectedDescription" rows="3"
          placeholder="Type a description here" :aria-invalid="submitAttempted && !formSelectedDescription"
          @input="onPrizeChange"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'; 5
import AtmoUploadCard from '@/components/atmo-upload-card';

export default {
  components: {
    Multiselect,
    AtmoUploadCard,
  },

  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    prize: {
      type: Object,
      required: true
    },
    prizeTypes: {
      type: Array,
      default: null
    },
    selectedPrizeType: {
      type: Object,
      required: true
    },
    submitAttempted: {
      type: Boolean,
      required: true
    },
    expanded: {
      type: Boolean,
      required: true
    },
    onHeaderClick: {
      type: Function,
      required: true
    },
    onChange: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      formSelectedPrizeType: null,
      formSelectedImage: null,
      formSelectedImageFile: null,
      formSelectedDescription: null,
      formSelectedCredits: null,
      formSelectedName: null,
    }
  },

  computed: {
    rank() {
      return { 0: 'First', 1: 'Second', 2: 'Third' }[this.index];
    }
  },

  created() {
    this.setInitialFormProps();
  },

  methods: {
    setInitialFormProps() {
      if (this.editMode) {
        this.formSelectedPrizeType = this.prize.contest_prize_type;
        this.formSelectedImage = this.prize.merch_image;
        this.formSelectedImageFile = this.prize.merch_image;
        this.formSelectedDescription = this.prize.other_description || this.prize.merch_description;
        this.formSelectedCredits = this.prize.credit_amount;
        this.formSelectedName = this.prize.name;
      } else {
        this.formSelectedPrizeType = this.selectedPrizeType;
      }
    },
    onImageSelected(files) {
      this.formSelectedImageFile = files[0];
      this.onPrizeChange();
    },
    onPrizeChange() {
      const updatedPrize = {
        ...this.prize,
        contest_prize_type: this.formSelectedPrizeType,
        image: this.formSelectedImageFile,
        description: this.formSelectedDescription,
        credits: this.formSelectedCredits,
        name: this.formSelectedName,
      };

      this.onChange(updatedPrize);
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-form-prize {
  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    padding: 15px;
    background-color: white;
    color: $atmo-purple--dark;
    border-radius: 5px;
    margin: 5px 0;
    cursor: pointer;
  }

  input::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
    color: white;
  }

  textarea,
  textarea::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
    color: white;
  }

  .text-field {
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, .4);
    padding: .5rem .5rem .5rem .5rem;
    color: white;
  }

  .dropdown-group {
    background: $atmo-purple--medium-dark;
    color: white;
    border: none;
    border-radius: 5px;
    padding: .5rem;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
  }

  .atmo-upload-card {
    width: 5rem;
  }

  input.name {
    margin-bottom: .5rem;
  }

  .dropdown-field {
    font-weight: 500;
  }

  .multiselect {
    margin-bottom: 1rem;
  }

  &__body {
    margin: 3px 0;

    &--merch {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      p {
        margin-top: .5rem;
      }
    }

    &--credits {
      input {
        width: 100%;
      }
    }

    &--other {
      textarea {
        width: 100%;
      }
    }
  }

  &__merch-description {
    margin-left: 4px;
    font-size: 1em;
  }
}
</style>
