<template>
  <div class="atmo-events-tickets">
    <div>
      <h2 class="atmo-page-header">
        Tickets
      </h2>
    </div>
    <atmo-back-link></atmo-back-link>
    <div class="atmo-events-tickets__atmo-events-tickets-wrap">
      <div class="atmo-events-tickets-wrap__tickets-container">
        <perfect-scrollbar class="tickets-container__tickets-list" data-cy="atmo-tickets-list">
          <event-ticket v-for="ticket in event.atmo_event_tickets" :key="ticket.id" :ticket="ticket" :event="event"
            @ticket-quantity-change="handleTicketQuantityChange" />
        </perfect-scrollbar>
        <div class="tickets-container__ticket-summary">
          <div class="ticket-summary__quantity-total-container">
            <div class="quantity-total-container__quantity-wrap">
              <div>Quantity</div>
              <div>{{ this.eventData.totalQuantity || 0 }}</div>
            </div>
            <div class="quantity-total-container__total-wrap">
              <div>Total</div>
              <div>{{ this.eventData.totalPrice ? returnCurrencyFormat(this.eventData.totalPrice) : "$0.00" }}</div>
            </div>
          </div>
          <div class="ticket-summary__buttons-container">
            <div>
              <router-link :to="`/events/payment`" class="confirm-button">
                Pay now
              </router-link>
            </div>
            <div>
              <router-link :to="`/events/`" class="back-button">
                Back to events
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import EventTicket from "@/components/atmo-events/event-ticket.vue";
import AtmoBackLink from '@/components/atmo-back-link.vue';
import { formatPriceAsCurrency } from '@/helpers/utilityFunctions';
import {
  callEventsShow
} from '@/helpers/axiosCalls';

export default {
  components: {
    EventTicket,
    AtmoBackLink,
  },
  data() {
    return {
      event: {},
      eventData: {
        atmo_event_tickets: []
      }
    };
  },
  computed: {
    // ...mapState("events", {
    //   event: state => state.currentEvent,
    // }),
  },
  created() {
    this.getEvent()
  },
  methods: {
    // ...mapActions("events", ["setEvent"]),
    addQuantityToTickets(ticketArray) {
      const ticketsWithQuantity = ticketArray.map(ticket => {
        return { ...ticket, quantity: 0 };
      });

      return ticketsWithQuantity;
    },
    getEvent() {
      callEventsShow(this.$route.params.eventId)
        .then(response => {
          const updatedEvent = {
            ...response,
            atmo_event_tickets: this.addQuantityToTickets(response.atmo_event_tickets)
          };
          this.event = updatedEvent;
        })
        .catch(error => {
          console.error(error);
        });
    },
    returnCurrencyFormat(price) {
      return formatPriceAsCurrency(price)
    },
    handleTicketQuantityChange(updatedTicket) {
      this.eventData = this.event;

      // Find the index of the updated ticket in the event.atmo_event_tickets array
      const index = this.eventData.atmo_event_tickets.findIndex(ticket => ticket.id === updatedTicket.id);

      if (index !== -1) {
        // Update the quantity of the ticket
        this.$set(this.eventData.atmo_event_tickets, index, updatedTicket);

        // Recalculate total quantity and total price for all atmo_event_tickets
        this.eventData.totalQuantity = this.calculateTotalQuantity();
        this.eventData.totalPrice = this.calculateTotalPrice();
      }

      // console.log(this.eventData.totalQuantity);
      // console.log(this.eventData.totalPrice);
    },
    calculateTotalQuantity() {
      // Calculate the total quantity by summing up the quantity of all atmo_event_tickets
      return this.event.atmo_event_tickets.reduce((total, ticket) => total + ticket.quantity, 0);
    },
    calculateTotalPrice() {
      // Calculate the total price by summing up the price * quantity of all atmo_event_tickets
      return this.event.atmo_event_tickets.reduce((total, ticket) => total + (ticket.price * ticket.quantity), 0);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.atmo-events-tickets {
  max-width: 1300px;
  margin: 0 auto;

  .atmo-events-tickets__atmo-events-tickets-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .atmo-events-tickets-wrap__tickets-container {
    display: grid;
    grid-template-columns: minmax(40rem, 1fr) 25rem;
    gap: 2rem;
  }

  .tickets-container__ticket-summary {
    .ticket-summary__quantity-total-container {
      background-color: rgba($atmo-purple--extra-dark, 0.3);
      backdrop-filter: blur(5px);
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 600;
      padding: 1rem;
      border-radius: 5px;
      margin-bottom: 1rem;
    }

    .quantity-total-container__quantity-wrap {
      padding: 1rem;
      border-bottom: 1px solid white;
      display: flex;
      justify-content: space-between;
    }

    .quantity-total-container__total-wrap {
      padding: 1rem;
      display: flex;
      justify-content: space-between;
    }

    .ticket-summary__buttons-container {
      display: flex;
      flex-direction: row-reverse;
      gap: 1rem;

      .back-button {
        color: white;
        display: inline-block;
        background-color: $atmo-purple--medium-dark;
        padding: .8rem;
        border-radius: 5px;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 500;
      }

      .confirm-button {
        background-color: white;
        color: $atmo-purple--medium-dark;
        display: inline-block;
        padding: .8rem;
        border-radius: 5px;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }
}

.event-ticket__gutter {
  border-radius: 5px;
}

.event-tickets {
  &__price-overview {
    background-color: rgba($atmo-purple--extra-dark, 0.3);
    backdrop-filter: blur(5px);
  }

  &__quantity {
    color: white;
  }
}
</style>