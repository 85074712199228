<template>
  <div class="atmo-contests-edit">
    <h2 class="atmo-page-header atmo-contests-new__header">
      Edit Contest
    </h2>
    <div class="atmo-contests-new__main-wrap">
      <atmo-back-link></atmo-back-link>
      <div class="main-wrap__main">
        <atmo-loading v-if="isLoading" />
        <form v-else @submit.prevent="editContest()" class="atmo-form main-wrap__form">
          <div class="main-wrap__main-row">
            <div class="main-wrap__column image-file-types">
              <div class="field-wrap">
                <label for="contestImage">Contest Image</label>
                <div>
                  <atmo-media-uploader :existingMediaUrl="changedFields.image.url" componentId="image-upload"
                    mediaType="image" @selected-image-upload="handleUploadedImage" height="11rem" width="13rem"/>
                </div>
              </div>
              <div class="field-wrap">
                <label>Accepted File Types</label>
                <div class="atmo-form__checkbox-group" name="file-types">
                  <label class="file-type-label" v-for="fileType in fileTypes" :key="fileType.id">
                    {{ fileType.name }}
                    <input type="checkbox" :id="fileType.id" :checked="isFileTypeChecked(fileType)"
                      @change="updateChecked(fileType)">
                  </label>
                </div>
              </div>
            </div>
            <div class="main-wrap__column main-wrap__main-column">
              <div class="field-wrap">
                <label for="contestTitle">Contest title</label>
                <input class="text-field" id="contestTitle" v-model="changedFields.name"
                  :aria-invalid="submitAttempted && !formSelectedTitle" type="text"
                  placeholder="Design our next t-shirt and win free tickets" data-cy="contest-title" />
              </div>
              <div class="field-wrap">
                <label for="contestDescription">Contest description</label>
                <textarea class="text-field" id="contestDescription" rows="4" no-resize
                  :aria-invalid="submitAttempted && !formSelectedDescription" placeholder="Enter contest description"
                  data-cy="contest-description" v-model="changedFields.description"></textarea>
              </div>
              <div class="field-wrap">
                <label for="contestDuration">Contest duration (From - To)</label>
                <div class="datepicker-trigger atmo-datepicker">
                  <input id="datepicker-trigger" class="main-wrap__datepicker-trigger" type="text"
                    placeholder="Select dates"
                    :aria-invalid="submitAttempted && (!formSelectedStartDate || !formSelectedEndDate)"
                    :value="formSelectedDates" />
                  <airbnb-style-datepicker :trigger-element-id="'datepicker-trigger'" :mode="'range'"
                    :fullscreen-mobile="true" :date-one="formSelectedStartDate" :date-two="formSelectedEndDate"
                    :show-shortcuts-menu-trigger="false" data-cy="contest-duration"
                    @date-one-selected="val => { formSelectedStartDate = val }"
                    @date-two-selected="val => { formSelectedEndDate = val }" />
                </div>
              </div>
            </div>
            <div class="main-wrap__side-column">
              <div class="field-wrap">
                <div>
                  <div class="prize-header-container">
                    <label class="prize-header" for="prizes">Prizes</label>
                    <div class="create-prize-button" @click="createPrize()">+</div>
                  </div>
                  <perfect-scrollbar>
                    <!-- Render existing prizes -->
                    <div v-for="(prize, index) in formSelectedPrizes" :key="index">
                      <form-prize :index="index" :prize="prize" :prize-types="prizeTypes"
                        :selected-prize-type="defaultPrizeType" :submit-attempted="submitAttempted"
                        :expanded="expandedPrizeIndex === index" :on-header-click="() => onPrizeHeaderClick(index)"
                        :on-change="(properties) => onPrizeChange(index, properties)"
                        :edit-mode="prize.hasOwnProperty('id')" />
                      <div class="delete-prize-button" @click="removePrize(index)">Delete Prize</div>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
          </div>
          <div class="main-wrap__footer">
            <button type="submit" class="main-wrap__submit-button">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  callContestsShow,
  callContestsUpdate,
  callContestPrizeTypesIndex,
  callFileTypesIndex,
  callContestFileTypesCreate,
  callContestFileTypesDelete,
  callContestPrizesIndex,
  callContestPrizesCreate,
  callContestPrizesUpdate,
  callContestPrizesDelete,
} from '@/helpers/axiosCalls';
import AtmoLoading from '@/components/atmo-loading';
import AtmoBackLink from '@/components/atmo-back-link';
import AtmoMediaUploader from '@/components/atmo-media-uploader';
import FormPrize from './form-prize';
import format from 'date-fns/format';
import {
  isDeepEqual,
  deepCopy
} from '@/helpers/utilityFunctions';

export default {
  title: ' - New Contest',

  components: {
    AtmoLoading,
    AtmoBackLink,
    AtmoMediaUploader,
    FormPrize,
  },

  data: function () {
    return {
      isLoading: false,
      prizeTypes: null,
      fileTypes: [],
      defaultPrizeType: null,
      submitAttempted: false,
      dateFormat: 'MMM D, YYYY',
      formSelectedImageFile: null,
      formSelectedFileTypes: [],
      formSelectedTitle: null,
      formSelectedDescription: null,
      formSelectedStartDate: null,
      formSelectedEndDate: null,
      formSelectedPrizes: [],
      expandedPrizeIndex: 0,
      contest: {},
      editedContest: {},
      isChecked: true,
      contestPrizes: [],
      changedFields: {}
    }
  },

  computed: {
    checkboxStates() {
      return this.contest.contest_file_types.reduce((obj, contestFileType) => {
        obj[contestFileType.file_type.id] = true;
        return obj;
      }, {});
    },
    artistProfileId() {
      return this.$store.state.currentUserProfileId;
    },
    formSelectedDates() {
      return this.formatDates(
        this.formSelectedStartDate,
        this.formSelectedEndDate
      );
    }
  },

  created() {
    this.getContest();
    this.getPrizeTypes();
    this.getFileTypes();
    this.getContestPrizes();
  },

  methods: {
    createPrize() {
      this.formSelectedPrizes.push({})
    },
    removePrize(index) {
      console.log("this.contestPrizes", this.contestPrizes);
      console.log("this.formSelectedPrizes", this.formSelectedPrizes);
      this.formSelectedPrizes.splice(index, 1);
      console.log("this.contestPrizes", this.contestPrizes);
      console.log("this.formSelectedPrizes", this.formSelectedPrizes);
    },
    async getContestPrizes() {
      try {
        const response = await callContestPrizesIndex(this.$route.params.contestId);
        // this.contestPrizes = response.slice(); // Create a copy of response array
        // this.formSelectedPrizes = response.slice(); // Create a copy of response array

        this.contestPrizes = response.sort((a, b) => {
          // Convert strings to Date objects for comparison
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          // Sort by ascending order of created_at date
          return dateA - dateB;
        });
        this.formSelectedPrizes = [...this.contestPrizes];
      } catch (error) {
        console.error(error);
      }
    },
    isFileTypeChecked(fileType) {
      return this.checkboxStates[fileType.id];
    },
    updateChecked(fileType) {
      const index = this.formSelectedFileTypes.findIndex(
        (contestFileType) => contestFileType.file_type.id === fileType.id
      );

      if (index === -1) {
        // If not found, add it to the array
        this.formSelectedFileTypes.push({
          file_type_id: fileType.id,
          file_type: fileType,
        });
      } else {
        // If found, remove it from the array
        this.formSelectedFileTypes.splice(index, 1);
      }
    },
    populateDatepickerWithContestDates(start_date, end_date) {
      // Format the dates as needed
      const formattedStartDate = new Date(start_date).toISOString().split('T')[0];
      const formattedEndDate = new Date(end_date).toISOString().split('T')[0];

      // Update the formSelectedStartDate and formSelectedEndDate
      this.formSelectedStartDate = formattedStartDate;
      this.formSelectedEndDate = formattedEndDate;
    },
    getContest() {
      callContestsShow(this.$route.params.contestId)
        .then(response => {
          this.contest = deepCopy(response);
          this.editedContest = deepCopy(response);
          this.populateDatepickerWithContestDates(this.contest.start_date, this.contest.end_date)
          this.changedFields = { ...this.contest };
          this.formSelectedFileTypes = this.contest.contest_file_types.slice();
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getPrizeTypes() {
      try {
        const response = await callContestPrizeTypesIndex();
        this.prizeTypes = response;
        this.defaultPrizeType = response.find((prizeType) => {
          return prizeType.name === 'Merch';
        })
      } catch (error) {
        console.error(error);
      }
    },

    async getFileTypes() {
      try {
        const response = await callFileTypesIndex();
        this.fileTypes = response.slice();
      } catch (error) {
        console.error(error);
      }
    },
    handleUploadedImage(value) {
      this.formSelectedImageFile = value;
    },
    formatDates(startDate, endDate) {
      let formattedDates = ''
      if (startDate) {
        formattedDates = format(startDate, this.dateFormat)
      }
      if (endDate) {
        formattedDates += ' - ' + format(endDate, this.dateFormat)
      }

      return formattedDates
    },
    onPrizeHeaderClick(index) {
      if (this.expandedPrizeIndex === index) {
        this.expandedPrizeIndex = null;
      } else {
        this.expandedPrizeIndex = index;
      }
    },
    onPrizeChange(index, prize) {
      this.formSelectedPrizes[index] = prize;
    },
    async synchronizePrizes(existingPrizes, updatedPrizes) {
      for (const existingPrize of existingPrizes) {
        const foundIndex = updatedPrizes.findIndex(updatedPrize => updatedPrize.id === existingPrize.id);

        if (foundIndex === -1) {
          console.log("DELETE")
          await callContestPrizesDelete(this.$route.params.contestId, existingPrize.id);
        }
      }

      for (const updatedPrize of updatedPrizes) {
        const existingPrize = existingPrizes.find(existingPrize => existingPrize.id === updatedPrize.id);

        const formData = new FormData();
        formData.append('contest_prize_type_id', updatedPrize.contest_prize_type.id);
        formData.append('name', updatedPrize.name);

        if (updatedPrize.contest_prize_type.name === 'Merch') {
          formData.append('merch_description', updatedPrize.description);
          formData.append('merch_image', updatedPrize.image);
        } else if (updatedPrize.contest_prize_type.name === 'Credits') {
          formData.append('credit_amount', updatedPrize.credits);
        } else {
          formData.append('other_description', updatedPrize.description);
          formData.append('other_image', updatedPrize.image);
        }

        if (!Object.prototype.hasOwnProperty.call(updatedPrize, 'id')) {
          console.log("CREATE")
          await callContestPrizesCreate(this.$route.params.contestId, formData);
        } else if (!existingPrize || !this.attributesAreEqual(existingPrize, updatedPrize)) {
          console.log("UPDATE")
          await callContestPrizesUpdate(this.$route.params.contestId, updatedPrize.id, formData);
        } else {
          console.warn('Skipping update for prize with no changes:', updatedPrize);
        }
      }

      this.$router.push({ name: 'contests.index' });
    },
    attributesAreEqual(prize1, prize2) {
      return JSON.stringify(prize1) === JSON.stringify(prize2);
    },
    async synchronizeFileTypes() {
      for (const selectedFileType of this.formSelectedFileTypes) {
        const foundIndex = this.contest.contest_file_types.findIndex(fileType => fileType.file_type.id === selectedFileType.id);

        if (foundIndex === -1) {
          await callContestFileTypesCreate({ 
            contest_id: this.contest.id,
            file_type_id: selectedFileType.file_type_id
          })
        }
      }

      for (const fileType of this.contest.contest_file_types) {
        const foundIndex = this.formSelectedFileTypes.findIndex(selectedFileType => selectedFileType.id === fileType.file_type.id);

        if (foundIndex === -1) {
          await callContestFileTypesDelete(fileType.id);
        }
      }
    },
    async editContest() {
      const formData = new FormData();

      if (this.formSelectedImageFile) {
        formData.append('image', this.formSelectedImageFile);
      }

      if (this.formSelectedStartDate) {
        formData.append('start_date', this.formSelectedStartDate);
      }

      if (this.formSelectedEndDate){
        formData.append('end_date', this.formSelectedEndDate);
      }

      for (const key in this.changedFields) {
        if (Object.prototype.hasOwnProperty.call(this.changedFields, key)) {
          if (!this.isEqual(this.changedFields[key], this.contest[key])) {
            console.log("HERE", this.changedFields[key])
            formData.append(key, this.changedFields[key]);
          }
        }
      }

      try {
        this.isLoading = true;
        await callContestsUpdate(this.$route.params.contestId, formData);
        await this.synchronizeFileTypes();
        await this.synchronizePrizes(this.contestPrizes, this.formSelectedPrizes);
        this.isLoading = false;
        this.$router.push({ name: 'contests.show', params: { contestId: this.contest.id } });
      } catch (error) {
        this.isLoading = false;
        console.error(error);
        this.$notify({ group: 'vue-app', type: 'error', title: 'Error submitting contest' });
      }
    },
    isEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-contests-edit {
  display: flex;
  flex-direction: column;
  // max-width: 50rem;

  .prize-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .prize-header {
      margin-bottom: 0 !important;
    }

    .create-prize-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 100%;
      background-color: white;
      color: $atmo-purple--extra-dark;
      font-size: 1rem;
      cursor: pointer;
    }
  }

  .delete-prize-button {
    cursor: pointer;
  }

  //https://github.com/mercs600/vue2-perfect-scrollbar
  .ps {
    max-height: 20rem;
  }

  textarea,
  textarea::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: white;
  }

  input::placeholder {
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: rgba(255, 255, 255, .4) !important;
  }

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: '';
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;

      &::before {
        opacity: 1;
      }

      ~label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .field-wrap {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    label {
      text-transform: uppercase;
      margin-bottom: .5rem;
      font-size: .9rem;
      font-weight: 500;
    }

    .text-field {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, .4);
      padding: .5rem .5rem .5rem .5rem;
      color: white;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, .4);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, .4);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, .4);
    }

    .dropdown-group {
      background: $atmo-purple--medium-dark;
      color: white;
      text-transform: uppercase;
      border: none;
      border-radius: 5px;
      padding: .5rem;
      font-weight: 500rem;
      cursor: pointer;
    }

    .dropdown-field {}

    .radio-group {
      display: flex;
      padding: .6rem 0rem .6rem 0rem;
    }

    .radio-field-wrap {
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .radio-field {
      margin-right: .5rem;
      cursor: pointer;
    }

    .radio-field-label {
      margin: 2px 0px 0px 0px;
    }
  }

  .atmo-contests-new__main-wrap {
    display: flex;
    flex-direction: column;
    margin: 0 75px;
    height: 100%;
    max-width: 100vw;
  }

  .atmo-form__checkbox-group {
    display: flex;
    flex-direction: column;
    padding: .8rem;
  }

  .file-type-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $atmo-purple--light;
    cursor: pointer;
    text-transform: unset !important;
    font-weight: 400 !important;
    padding: .2rem .4rem .2rem .4rem;
  }

  .field-label {
    font-weight: 500;
    margin-bottom: .8rem;
  }

  #numberOfWinners {
    label {
      margin: 0;
    }
  }

  .main-wrap__header {
    margin: 35px 0;
  }

  .main-wrap__breadcrumbs {
    align-self: flex-start;
    margin-bottom: 15px;
  }

  .main-wrap__main-row {
    display: flex;
    flex-wrap: wrap;
  }

  .main-wrap__column {
    display: flex;
    flex-direction: column;
  }

  .image-file-types {
    min-width: 11rem;
  }

  .main-wrap__main-column {
    flex: 1;
    margin: 0 20px;
  }

  #datepicker-trigger {
    border: 1px solid rgba(255, 255, 255, .4);
    text-transform: unset;
    cursor: pointer;
  }

  #datepicker-trigger.atmo-contests-new__datepicker-trigger {
    width: 100%;
    background-color: $atmo-purple--medium-dark;
    color: white;

    &::placeholder {
      color: white;
    }
  }

  .main-wrap__side-column {
    width: 16rem;
  }

  .number-of-winners-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      display: flex;
      align-items: center;
      gap: .5rem;
    }
  }

  .main-wrap__footer {
    display: flex;
  }

  .main-wrap__submit-button {
    margin-left: auto;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: .5rem 2rem .5rem 2rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }
}
</style>