<template>
  <div class="atmo-store">
    <div class="atmo-store__heading-wrap">
      <div class="heading-wrap__link-wrapper" v-if="$store.state.currentUserId == $route.params.userId">
        <router-link :to="{ name: 'products.new' }" class="link-wrapper__link">
          <span>Create Product</span>
          <img class="add-product-icon" src="@/assets/images/icons/add.png" alt="Create new">
        </router-link>
      </div>
      <h2 class="atmo-page-header atmo-store__header">
        {{ $route.params.userId ? `${user.name}'s Store` : 'Atmo Store' }}
      </h2>
    </div>
    <section class="atmo-store__body">
      <aside class="atmo-store__sidebar">
        <router-link :to="{ name: 'cart' }" class="atmo-store__section--cart atmo-store__section">
          <div class="atmo-store__section-header">
            <img src="@/assets/images/icons/store/cart.png" class="atmo-store__section-header-icon">
            <span>Cart</span>
            <div v-if="cartCount > 0" class="atmo-store__cart-count">
              {{ cartCount }}
            </div>
          </div>
          <div v-if="cartCount > 0" class="atmo-store__section-content">
            Total Price:
            <span class="atmo-store__price">
              {{ returnCurrencyFormat(cartCost) }}
            </span>
          </div>
          <div v-if="cartCount <= 0" class="atmo-store__section-content atmo-store__section-content--empty-state">
            No items in cart.
          </div>
        </router-link>
        <input v-if="$route.name === 'products.index'" class="atmo-input atmo-input--search atmo-store__search-input"
          type="text" v-model="productQuery" placeholder="Search for item">
        <div v-if="$route.name === 'products.index'" class="atmo-store__section--categories atmo-store__section">
          <div class="atmo-store__section-header">
            <img src="@/assets/images/icons/store/category.png" class="atmo-store__section-header-icon">
            <span>Categories</span>
          </div>
          <div class="atmo-store__section-content">
            <perfect-scrollbar>
              <div class="atmo-store__category-select">
                <a href="#" class="atmo-store__category-select-option"
                  :class="{ 'atmo-store__category-select-option--active': selectedCategoryId === null }" type="radio"
                  name="All" @click="selectCategory(null)">
                  All
                </a>
                <a v-for="productCategory in categories" :key="productCategory.id" href="#"
                  class="atmo-store__category-select-option"
                  :class="{ 'atmo-store__category-select-option--active': productCategory.id === selectedCategoryId }"
                  type="radio" name="productCategory.name" @click="selectCategory(productCategory.id)">
                  {{ productCategory.name }}
                </a>
                <div v-if="categories.length === 0">No categories available</div> <!-- Add this line for debugging -->
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <div v-if="$route.name !== 'products.index'" class="atmo-store__toggle-link">
          <router-link to="/products">
            Back to Store
          </router-link>
        </div>
      </aside>
      <router-view :products="filteredProducts" />
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { formatPriceAsCurrency } from '@/helpers/utilityFunctions';
import { callUsersShow } from '@/helpers/axiosCalls';

export default {
  data() {
    return {
      productQuery: '',
      user: null,
    };
  },
  computed: {
    ...mapState('products', ['cart', 'cartCost', 'selectedCategoryId', 'categories', 'products']),
    ...mapGetters('products', ['filteredProducts', 'cartCount']),
  },
  async created() {
    this.getCartFromStorage();
    this.updateCartCost();
    await this.fetchCategories(); // Ensure categories are fetched before rendering
    await this.fetchProducts(); // Ensure products are fetched before proceeding

    if (this.$route.params.userId) {
      await this.fetchUser(this.$route.params.userId); // Ensure user data is fetched
      await this.fetchProducts({ user_id: this.$route.params.userId }); // Ensure products for the user are fetched
    }
    console.log('Categories in State:', this.categories); // Debugging line to verify categories
    console.log('Products in State:', this.products); // Debugging line to verify products
  },
  methods: {
    ...mapMutations('products', [
      'addToCart',
      'removeFromCart',
      'removeGroupFromCart',
      'updateCartCost',
      'getCartFromStorage',
      'setSelectedCategoryId'
    ]),
    ...mapActions('products', [
      'fetchCategories',
      'fetchProducts'
    ]),
    async fetchUser(userId) {
      try {
        const response = await callUsersShow(userId);
        this.user = response;
      } catch (error) {
        console.error(error);
      }
    },
    returnCurrencyFormat(number) {
      return formatPriceAsCurrency(number);
    },
    selectCategory(id) {
      this.setSelectedCategoryId(id);
      this.fetchProducts();
    }
  }
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.atmo-store {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  &__heading-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    .heading-wrap__link-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      margin-top: 1rem;
    }

    .link-wrapper__link {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .add-product-icon {
      margin-left: .3rem;
      height: 1.2rem;
    }
  }

  &__header-container {
    max-height: 300px;
  }

  &__header {
    margin: 35px 0;
    width: 100%;
  }

  &__body {
    display: flex;
    // justify-content: center;
    width: 100%;
    flex-basis: 550px;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 130px;
  }

  &__section {
    border: 1px solid $atmo-purple--light;
    padding: 7px 10px;
    border-radius: 5px;

    &[ng-href]:hover {
      box-shadow: 0 0 3px $atmo-purple--medium;
      color: white;
    }
  }

  &__section-header {
    display: flex;
    align-items: baseline;
    font-size: 1.1em;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__section-header-icon {
    height: 15px;
    margin-right: 8px;
  }

  &__cart-count {
    background-color: $atmo-pink--medium;
    margin: 0 7px;
    font-size: 0.7em;
    font-weight: 300;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    padding: 8px;
  }

  &__section-content {
    font-size: 0.8em;
    font-weight: 400;

    &--empty-state {
      color: $atmo-gray--medium;
      font-style: italic;
    }
  }

  &__toggle-link {
    align-self: center;
    text-transform: uppercase;
    font-size: 1em;
    margin-top: 10px;

    a {
      color: white;
    }
  }

  &__price {
    color: $atmo-blue--medium;
  }

  &__search-input {
    margin: 13px 0;
  }

  &__category-list {
    flex-grow: 1;
  }

  &__category-select {
    display: flex;
    flex-direction: column;
    font-size: 1em;
    font-weight: 300;
  }

  &__category-select-option {
    margin-bottom: 10px;
    font-size: 1.1em;

    &--active {
      color: white;
      font-weight: 400;
    }
  }

  &__child-view {
    flex-grow: 1;
  }

  &__menu {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    grid-auto-rows: 225px;
    // max-width: 900px;
    grid-gap: 20px;
    margin: 0 20px;
    flex-grow: 1;
  }

  &__menu-item-footer {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(-269deg,
        rgba($atmo-blue--medium, 0.39) 1%,
        rgba($atmo-pink--medium, 0.39) 100%);
    border-radius: 0 0 5px 5px;
    // box-shadow: 1px 0 0 0 rgba(64,54,93,0.15), 0 1px 0 0 rgba(64,54,93,0.54);
  }

  &__menu-item-footer-main {
    display: flex;
    padding: 8px;
  }

  &__menu-item-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: white;
  }

  &__menu-item-price {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $atmo-purple--dark;
    font-size: 1.2em;
    font-weight: 500;
  }

  &__menu-item-title {
    padding-bottom: 5px;
  }

  &__menu-item-category {
    color: $atmo-gray--light;
    font-size: .7em;
  }

  &__menu-item-description-container {
    height: auto;
    max-height: 0;
    overflow: hidden;

    transition: max-height, border-top, 0.2s ease-in;
  }

  &__menu-item-description {
    color: white;
    font-size: 0.7em;
    padding: 8px;
    border-top: 1px solid rgba(black, 0.1);
  }

  // menu item hover state
  &__menu-item:hover {
    // box-shadow: 1px 0 0 0 rgba(64, 54, 93, 0.15), 6px 9px 14px 0px rgba(64, 54, 93, 0.54);

    .atmo-store__menu-item-footer {
      background-image: linear-gradient(-269deg,
          $atmo-blue--medium 1%,
          $atmo-pink--medium 100%);
    }

    .atmo-store__menu-item-description-container {
      max-height: 600px;
    }

    .atmo-store__menu-item-title {
      color: black;
    }

    .atmo-store__menu-item-category {
      color: $atmo-gray--dark;
    }

    .atmo-store__menu-item-price {
      color: white;
    }
  }
}
</style>
