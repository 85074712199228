<template>
  <perfect-scrollbar>
    <div class="genres-moods__genres-moods-grid">
      <router-link
        v-for="(item, index) in genresMoods"
        :key="index"
        :to="item.type === 'genre' 
              ? { name: 'genres.show', params: { genreId: item.id } } 
              : { name: 'moods.show', params: { moodId: item.id } }"
        class="genres-moods-grid__genre-mood-card"
        v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${item.image.url})` }"
      >
        <div class="genre-mood-card__title-wrap">{{ item.name }}</div>
      </router-link>
    </div>
  </perfect-scrollbar>
</template>

<script>
import PaginationMixin from '@/mixins/pagination';

export default {
  mixins: [PaginationMixin],
  props: {
    genresMoods: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.genres-moods__genres-moods-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  grid-gap: 1rem;
  margin-top: 10px;
  height: 100%;
  position: relative;
  margin-bottom: 1rem;
  width: 100%;

  .genres-moods-grid__genre-mood-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
  }

  .genre-mood-card__title-wrap {
    font-weight: 800;
    font-size: 1.3rem;
  }
}
</style>
