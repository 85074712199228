import { Model } from 'vue-mc';

const defaultImageUrl = '@/assets/images/default_pic.jpg';
const getDefaultImages = () => ({
  image: {
    url: defaultImageUrl,
    thumb: { url: defaultImageUrl }
  }
});

export default class User extends Model {
  defaults() {
    return {
      user_images: [getDefaultImages()]
    }
  }
  get imageUrl() {
    // return (this.images[0] || getDefaultImages()).image.url;
    if (this.images) {
      return (this.images[0] || getDefaultImages()).image.url;
    } else {
      return null;
    }
  }
  get thumbImageUrl() {
    // return (this.images[0] || getDefaultImages()).image.thumb.url;
    if (this.images) {
      return (this.images[0] || getDefaultImages()).image.thumb.url;
    } else {
      return null;
    }
  }
  get profilePath() {
    const route = this.profile_type === 'ArtistProfile' ? 'artist_profiles' : 'user_profiles';
    return `/${route}/${this.profile_id}`;
  }
}
