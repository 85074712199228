<template>
  <div>
    <FriendList :userProfileId="user.profile_id" :profileType="user.profile_type" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FriendList from '@/components/profiles/friend-list';

export default {
  components: { FriendList },
  computed: {
    ...mapState('profiles', ['user']),
  },
  created() {
    // Fetch the profile if not already loaded
    if (!this.user || this.user.id !== this.profileId) {
      this.$store.dispatch('profiles/fetchProfile', { profileType: this.profileType, profileId: this.profileId });
    }
  }
};
</script>
