<template>
  <div>
    <h1>Cards On File</h1>
    <div
      v-for="source in sources"
      :key="source.id"
    >
      <p>Card ID: {{ source.id }}</p>
      <p>Type: {{ source.brand }}</p>
      <p>Last 4: {{ source.last4 }}</p>
      <p>Expiration Month: {{ source.exp_month }}</p>
      <p>Expiration Year: {{ source.exp_year }}</p>
      <br>
      <button
        v-if="customer.default_source !== source.id"
        @click="updateDefaultCard(source.id);"
      >
        Make Default
      </button>
      <strong>
        <p
          v-if="customer.default_source === source.id"
          style="color:green;"
        >
          Default Card
        </p>
      </strong>
      <br>
      <br>
    </div>
    <router-link :to="{ name: 'subscriptions.cards.new', params: { userProfileId: user.profile_id }}">
      Add Card
    </router-link>
  </div>
</template>

<script>
  import Axios from 'axios'

  export default {
    data: function(){
      return {
        user: {},
        userProfile: {},
        customerId: "",
        customer: {},
        sources: [],
        authorizationToken: "Bearer sk_test_R87ol4y4QdQVp354Dwyy45pi"
      }
    },
    created(){
      Axios.get(`/api/v1/users/${this.$store.state.currentUserId}`)
        .then(response => {
          this.user = response.data;
          this.customerId = this.user.customer_id;
          Axios.get(`https://api.stripe.com/v1/customers/${this.customerId}`, {
            headers: {
              Authorization: this.authorizationToken
            }
          })
            .then(response => {
              this.customer = response.data;
            });
          Axios.get(`https://api.stripe.com/v1/customers/${this.customerId}/sources`, {
            headers: {
              Authorization: this.authorizationToken
            }
          })
            .then(response2 => {
              this.sources = response2.data.data;
            })
        });
    // Axios.get('/api/v1/user_profiles/' + this.user.profile_id).then(response3 => (this.userProfile = response3.data));
    },
    methods: {

      getStripeCustomer (userCustomerId) {
        Axios.get(`https://api.stripe.com/v1/customers/${userCustomerId}`, {
          headers: {
            Authorization: this.authorizationToken
          }
        })
          .then(response => {
            this.customer = response.data;
          });
      },

      updateDefaultCard (cardId) {
        Axios({
          method: 'post',
          url: `https://api.stripe.com/v1/customers/${this.customerId}`,
          params: {
            default_source: cardId
          },
          headers: {
            Authorization: this.authorizationToken
          }
        })
          .then((response) => {
            console.log(response);
            this.getStripeCustomer(this.customerId);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }
</script>
